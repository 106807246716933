export enum SceneNames {
    // Core Gameplay Scenes
    Login = 'Login',
    ForgotPassword = 'ForgotPassword',
    Register = 'Register',
    Boot = 'Boot',
    CharacterClassSelection = 'CharacterClassSelection',
    ServerControlledGame = 'ServerControlledGame',
    CharacterSelection= 'CharacterSelection',

    // Conflict-Related Scenes
    ServerControlledConflict = 'ServerControlledConflict',
    ServerControlledConflictUI = 'ServerControlledConflictUI',

    // UI Scenes
    ServerControlledGameUI = 'ServerControlledGameUI',
    GamePad = 'GamePad',
    Keyboard = 'Keyboard',

    // Special Events or States
    Disconnection = 'Disconnection',
    CharacterName = 'CharacterName',
    CharacterCustomization = 'CharacterCustomization',

    BlackScreen = 'BlackScreen',
}
