export enum ServerSocketEvents {
    // Connection
    Login = 'login',
    Register = 'register',
    Connection = 'connection',
    RequestPasswordReset = 'requestPasswordReset',
    RequestCharacterSelectionSceneDetails = 'requestCharacterSelectionSceneDetails',
    RequestCharacterCreation = 'requestCharacterCreation',
    DeleteCharacter = 'deleteCharacter',
    RequestCharacterLoad = 'requestCharacterLoad',

    // Conflict Actions
    AttemptConflictAbilityMainSelect = 'attemptConflictAbilityMainSelect',
    AttemptConflictAbilitySelection = 'attemptConflictAbilitySelection',
    AttemptConflictAbilitySelectionConfirm = 'attemptConflictAbilitySelectionConfirm',
    AttemptConflictAction = 'attemptConflictAction',
    AttemptConflictAttack = 'attemptConflictAttack',
    AttemptConflictCancel = 'attemptConflictCancel',
    AttemptConflictCancelArmedAction = 'attemptConflictCancelArmedAction',
    AttemptConflictFlee = 'attemptConflictFlee',
    AttemptConflictInventoryItemMainSelect = 'attemptConflictInventoryItemMainSelect',
    AttemptConflictInventoryItemSelection = 'attemptConflictInventoryItemSelection',
    AttemptConflictInventoryItemSelectionConfirm = 'attemptConflictInventoryItemSelectionConfirm',
    AttemptToggleConflictBindMode = 'attemptToggleConflictBindMode',
    AttemptSelectHotkeyForBind = 'attemptSelectHotkeyForBind',
    AttemptConflictActionSelectionConfirmViaHotkey = 'attemptConflictActionSelectionConfirmViaHotkey',
    AttemptConflictActionSelectionForBind = 'attemptConflictActionSelectionForBind',
    AttemptSwitchConflictInventoryMode = 'attemptSwitchConflictInventoryMode',
    AttemptSelectConflictEquipmentItem = 'attemptSelectConflictEquipmentItem',

    // Player Actions
    AttemptInteract = 'attemptInteract',
    AttemptToggleCharacterSheet = 'attemptToggleCharacterSheet',
    AttemptToggleAbilityMenu = 'attemptToggleAbilityMenu',
    AttemptSelectFieldAbility = 'attemptSelectFieldAbility',
    AttemptArmFieldAbility = 'attemptArmFieldAbility',
    AttemptUseFieldAbilityOnTarget = 'attemptUseFieldAbilityOnTarget',
    AttemptToggleInventoryMenu = 'attemptToggleFieldInventoryMenu',
    AttemptSelectFieldInventoryItem = 'attemptSelectFieldInventoryItem',
    AttemptSelectFieldEquipmentItem = 'attemptSelectFieldEquipmentItem',
    AttemptArmOrEquipFieldInventoryItem = 'attemptArmOrEquipFieldInventoryItem',
    AttemptUnequipFieldEquipmentItem = 'attemptUnequipFieldEquipmentItem',
    AttemptUseFieldInventoryItemOnTarget = 'attemptUseFieldInventoryItemOnTarget',
    AttemptDropFieldInventoryItem = 'attemptDropFieldInventoryItem',
    AttemptSwitchFieldInventoryMode = 'attemptSwitchFieldInventoryMode',
    AttemptSelectFieldEquipmentItemResponse = 'attemptSelectFieldEquipmentItemResponse',

    // Targeting, Duel, and Trading
    AttemptClearTarget = 'attemptClearTarget',
    AttemptDuel = 'attemptDuel',
    AttemptTarget = 'attemptTarget',
    DuelAcceptDecline = 'duelAcceptDecline',
    AttemptTrade = 'attemptTrade',
    AttemptSelectInventoryItemForTrade = 'attemptSelectInventoryItemForTrade',
    AttemptSelectOfferedClientItemByIndex = 'attemptSelectOfferedClientItemByIndex',
    AttemptSelectOfferedRemoteItemByIndex = 'attemptSelectOfferedRemoteItemByIndex',
    AttemptOfferSelectedInventoryItemForTrade = 'attemptOfferSelectedInventoryItemForTrade',
    AttemptRescindOfferedItem = 'attemptRescindOfferedItem',
    AttemptOfferGoldForTrade = 'attemptOfferGoldForTrade',
    AttemptAcceptTradeOffer = 'attemptAcceptTradeOffer',
    AttemptRevertTradeOfferToPending = 'attemptRevertTradeOfferToPending',
    AttemptCancelTradeOffer = 'attemptCancelTradeOffer',

    // Party and Invitations
    AttemptInvite = 'attemptInvite',
    AttemptLeaveParty = 'attemptLeaveParty',
    AttemptTogglePartyOrderMenu = 'attemptTogglePartyOrderMenu',
    AttemptPartyReorderSubmit = 'attemptPartyReorderSubmit',
    PartyInviteAcceptDecline = 'partyInviteAcceptDecline',

    // UI and Player Movement
    CursorMovement = 'cursorMovement',
    CursorTurn = 'cursorTurn',
    ClientGameSceneInitialized = 'clientGameSceneInitialized',
    InitialPlayerNPCAndMapDataInitialized = 'initialPlayerNPCAndMapDataInitialized',

    // Messaging and Naming
    ChatMessage = 'chatMessage',
    SendNewCharacterDetails = 'sendNewCharacterDetails',

    // NPC Events
    NPCDialogueOptionIndexSelected = 'npcDialogueOptionIndexSelected',
    AttemptSelectNPCMerchantInventoryItemForPurchase = 'attemptSelectNPCMerchantInventoryItemForPurchase',
    AttemptSelectPlayerInventoryItemForSale = 'attemptSelectPlayerInventoryItemForSale',
    AttemptBuyNPCMerchantInventoryItem = 'attemptBuyNPCMerchantInventoryItem',
    AttemptSellNPCMerchantInventoryItem = 'attemptSellNPCMerchantInventoryItem',
    AttemptSwitchMerchantMenuToSellMode = 'attemptSwitchMerchantMenuToSellMode',
    AttemptSwitchMerchantMenuToBuyMode = 'attemptSwitchMerchantMenuToBuyMode',

    // Interactable Events
    AttemptDismissInteractableMessage = 'attemptDismissInteractableMessage',
}
