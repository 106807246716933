import {Socket} from 'socket.io-client';
import {SpriteSheet, UIImageKey} from '../../../../types/assets/AssetKeys';
import {ClientSocketEvents} from '../../../../types/events/ClientSocketEvents';
import {Direction} from '../../../../types/physics/Direction';
import SocketManager from '../../classes/NetworkingAndChat/SocketManager';
import NonContainerUIActionButton from '../../classes/UserInterface/ActionButtons/NonContainerUIActionButton';
import ResizableFrame from '../../classes/UserInterface/UtilityComponents/ResizableFrame';
import {hairColors} from '../../data/hairColors';
import {heroWeaponColor} from '../../data/heroWeaponColor';
import {primaryColors} from '../../data/primaryColors';
import {secondaryColors} from '../../data/secondaryColors';
import {skinTones} from '../../data/skinTones';
import {tertiaryColor} from '../../data/tertiaryColor';
import {mainGameFont} from '../../GameConfig';
import {DepthLevel} from '../../types/DepthLevel';
import {PlayerCustomizationData} from '../../types/PlayerCustomizationData';
import {SceneNames} from '../../types/SceneNames';

export default class CharacterClassSelectionScene extends Phaser.Scene {
    private acceptButton: NonContainerUIActionButton;
    private accumulatedTime: number;
    private backgroundRectangle: Phaser.GameObjects.Rectangle;
    private classDescriptionFrame: ResizableFrame;
    private classDescriptions: string[] = [
        'Forged in defiance of the Aetherspawn\'s onslaught, the Runeblades emerged. Wielding enchanted arms of the Starborne, resonating with ancient power, they stand as steadfast guardians in a world where the very essence of life is under siege.',
        'Once revered as pioneers of the arcane, Aethermancers now shoulder the heavy mantle of their legacy, channeling their formidable powers to rectify the havoc wrought by their own kind and restore a world teetering on the edge of the abyss.',
        'Lifeweavers, revered in the realm, hold the power to tilt the balance from death back to life. In an age enshrouded in despair, their sacred arts of renewal serve as a beacon of hope, promising to heal a world ravaged by the Aether\'s relentless chaos.'
    ];
    private classDescriptionText: Phaser.GameObjects.Text;
    private classNames: string[] = ['Runeblade', 'Aethermancer', 'Lifeweaver'];
    private classText: Phaser.GameObjects.Text;
    private classTitleFrame: ResizableFrame;
    private commandFrame: ResizableFrame;
    private selectedClassIndex: number;
    private currentFrame: 0 | 1;
    private facingDirection: Direction = Direction.DOWN;
    private frameRate: number = 2;
    private heroDisplay: Phaser.GameObjects.Sprite;
    private heroFemaleHairDisplay: Phaser.GameObjects.Sprite;
    private heroMaleHairDisplay: Phaser.GameObjects.Sprite;
    private heroPrimaryClothesDisplay: Phaser.GameObjects.Sprite;
    private heroAethermancerWeaponBottomDisplay: Phaser.GameObjects.Sprite;
    private heroAethermancerWeaponTopDisplay: Phaser.GameObjects.Sprite;
    private heroLifeweaverWeaponBottomDisplay: Phaser.GameObjects.Sprite;
    private heroLifeweaverWeaponTopDisplay: Phaser.GameObjects.Sprite;
    private heroRunebladeWeaponBottomDisplay: Phaser.GameObjects.Sprite;
    private heroRunebladeWeaponTopDisplay: Phaser.GameObjects.Sprite;
    private heroSecondaryClothesDisplay: Phaser.GameObjects.Sprite;
    private heroTertiaryClothesDisplay: Phaser.GameObjects.Sprite;
    private leftArrowButton: Phaser.GameObjects.Image;
    private rightArrowButton: Phaser.GameObjects.Image;
    private rightSideOptionsFrame: ResizableFrame;
    private selectedPrimaryColorIndex: number;
    private selectedSecondaryColorIndex: number;
    private selectedSkinToneIndex: number;
    private selectedHairColorIndex: number;
    private selectedGenderIndex: number;
    private socket: Socket;
    private disconnectionSceneRunning: boolean;
    private rejectButton: NonContainerUIActionButton;

    public constructor() {
        super(SceneNames.CharacterClassSelection);
        this.socket = SocketManager.getInstance().socket;
    }

    public create(data?: PlayerCustomizationData): void {
        this.selectedGenderIndex = data?.selectedGenderIndex ?? 0;
        this.selectedClassIndex = data?.selectedClassIndex ?? 0;
        this.selectedSkinToneIndex = data?.selectedSkinToneIndex ?? 0;
        this.selectedHairColorIndex = data?.selectedHairColorIndex ?? 0;
        this.selectedPrimaryColorIndex = data?.selectedPrimaryColorIndex ?? 0;
        this.selectedSecondaryColorIndex = data?.selectedSecondaryColorIndex ?? 0;
        this.accumulatedTime = data?.accumulatedTime ?? 0;
        this.currentFrame = data?.currentFrame ?? 0;
        this.facingDirection = Direction.DOWN;

        this.backgroundRectangle = this.add.rectangle(
            0,
            0,
            this.scale.width,
            this.scale.height,
            0xbcbcbc
        )
            .setOrigin(0, 0);

        this.commandFrame = new ResizableFrame(
            this,
            345,
            665,
            645, // Replace with the desired width
            75 // Replace with the desired height
        );

        this.rightSideOptionsFrame = new ResizableFrame(
            this,
            790,
            442,
            200,
            120
        );

        this.acceptButton = new NonContainerUIActionButton(
            this,
            720,
            415,
            UIImageKey.CheckButton,
            UIImageKey.CheckButton,
            'Accept',
            () => this.acceptClass() // Call acceptCustomization when the button is clicked
        );
        this.acceptButton.setDepth(DepthLevel.UI_PRIMARY_GRAPHICS_SUB);

        this.rejectButton = new NonContainerUIActionButton(
            this,
            720,
            465,
            UIImageKey.CrossButton,
            UIImageKey.CrossButton,
            'Reject',
            () => this.returnToCharacterSelection()
        );

        this.heroRunebladeWeaponBottomDisplay = this.add.sprite(335, 175, SpriteSheet.RunebladeWeaponBottom)
            .setScale(3)
            .setTint(parseInt(heroWeaponColor))
            .setVisible(false);

        this.heroAethermancerWeaponBottomDisplay = this.add.sprite(335, 175, SpriteSheet.AethermancerWeaponBottom)
            .setScale(3)
            .setTint(parseInt(heroWeaponColor))
            .setVisible(false);

        this.heroLifeweaverWeaponBottomDisplay = this.add.sprite(335, 175, SpriteSheet.LifeweaverWeaponBottom)
            .setScale(3)
            .setTint(parseInt(heroWeaponColor))
            .setVisible(false);

        switch (this.selectedClassIndex) {
            case 0:
                this.heroRunebladeWeaponBottomDisplay.setVisible(true);
                break;
            case 1:
                this.heroAethermancerWeaponBottomDisplay.setVisible(true);
                break;
            case 2:
                this.heroLifeweaverWeaponBottomDisplay.setVisible(true);
                break;
        }

        this.heroDisplay = this.add.sprite(335, 175, SpriteSheet.HeroBaseSprite)
            .setScale(3)
            .setTint(parseInt(skinTones[this.selectedSkinToneIndex]));
        this.heroPrimaryClothesDisplay = this.add.sprite(335, 175, SpriteSheet.HeroClothesPrimary)
            .setScale(3)
            .setTint(parseInt(primaryColors[this.selectedPrimaryColorIndex]));

        this.heroSecondaryClothesDisplay = this.add.sprite(335, 175, SpriteSheet.HeroClothesSecondary)
            .setScale(3)
            .setTint(parseInt(secondaryColors[this.selectedSecondaryColorIndex]));

        this.heroTertiaryClothesDisplay = this.add.sprite(335, 175, SpriteSheet.HeroClothesTertiary)
            .setScale(3)
            .setTint(parseInt(tertiaryColor));

        this.heroMaleHairDisplay = this.add.sprite(335, 175, SpriteSheet.HeroHairMale)
            .setScale(3)
            .setTint(parseInt(hairColors[this.selectedHairColorIndex]))
            .setVisible(this.selectedGenderIndex === 0);

        this.heroFemaleHairDisplay = this.add.sprite(335, 175, SpriteSheet.HeroHairFemale)
            .setScale(3)
            .setTint(parseInt(hairColors[this.selectedHairColorIndex]))
            .setVisible(this.selectedGenderIndex === 1);

        this.heroRunebladeWeaponTopDisplay = this.add.sprite(335, 175, SpriteSheet.RunebladeWeaponTop)
            .setScale(3)
            .setTint(parseInt(heroWeaponColor))
            .setVisible(false);

        this.heroAethermancerWeaponTopDisplay = this.add.sprite(335, 175, SpriteSheet.AethermancerWeaponTop)
            .setScale(3)
            .setTint(parseInt(heroWeaponColor))
            .setVisible(false);

        this.heroLifeweaverWeaponTopDisplay = this.add.sprite(335, 175, SpriteSheet.LifeweaverWeaponTop)
            .setScale(3)
            .setTint(parseInt(heroWeaponColor))
            .setVisible(false);

        switch (this.selectedClassIndex) {
            case 0:
                this.heroRunebladeWeaponBottomDisplay.setVisible(true);
                this.heroRunebladeWeaponTopDisplay.setVisible(true);
                break;
            case 1:
                this.heroAethermancerWeaponBottomDisplay.setVisible(true);
                this.heroAethermancerWeaponTopDisplay.setVisible(true);
                break;
            case 2:
                this.heroLifeweaverWeaponBottomDisplay.setVisible(true);
                this.heroLifeweaverWeaponTopDisplay.setVisible(true);
                break;
        }

        this.updateSpritesFrames();

        this.add.text(
            65,
            625,
            'Choose thy class.',
            {
                fontSize: '70px',
                color: '#fff',
                fontFamily: mainGameFont
            }
        )
            .setDepth(DepthLevel.UI_PRIMARY_TEXT);

        this.classTitleFrame = new ResizableFrame(
            this,
            345,
            305, // Adjust the Y position to place it below the skin tone frame
            395,
            75
        );

        this.classText = this.add.text(
            345,
            305,
            this.classNames[this.selectedClassIndex],
            {
                fontSize: '70px',
                color: '#fff',
                fontFamily: mainGameFont
            }
        )
            .setOrigin(0.5, 0.5)
            .setDepth(DepthLevel.UI_PRIMARY_TEXT);

        this.leftArrowButton = this.add.image(
            345 - 230,
            305,
            UIImageKey.LeftArrowButton
        )
            .setScale(2)
            .setInteractive();

        this.rightArrowButton = this.add.image(
            345 + 230,
            305,
            UIImageKey.RightArrowButton
        )
            .setScale(2)
            .setInteractive();

        this.leftArrowButton.on('pointerdown', () => this.changeClass('left'));
        this.rightArrowButton.on('pointerdown', () => this.changeClass('right'));

        this.classDescriptionFrame = new ResizableFrame(
            this,
            345,
            485,
            645,
            235
        );

        this.classDescriptionText = this.add.text(
            30,
            367,
            this.classDescriptions[this.selectedClassIndex],
            {
                fontSize: '36px',
                color: '#fff',
                fontFamily: mainGameFont,
                wordWrap: {
                    width: 645, // wrap width
                    useAdvancedWrap: true
                },
                metrics: {
                    fontSize: 38,
                    ascent: 31,
                    descent: 7
                }
            }
        )
            .setDepth(DepthLevel.UI_PRIMARY_TEXT);
        this.input.keyboard!.on('keydown-ENTER', () => {
            this.acceptClass();
        });
        this.input.keyboard!.on('keydown-LEFT', () => {
            this.changeClass('left');
        });
        this.input.keyboard!.on('keydown-RIGHT', () => {
            this.changeClass('right');
        });
        this.setupSocketListeners();
    }

    private setupSocketListeners(): void {
        this.socket.on(ClientSocketEvents.ServerShutdown, (response: { reason: string }) => {
            console.log('Server shutdown:', response);
            const reason = response.reason;
            if (this.disconnectionSceneRunning) {
                return; // If the scene is already running, skip the rest
            }
            this.scene.pause();

            // Disconnect the socket
            this.socket.disconnect();

            // Set the flag to true
            this.disconnectionSceneRunning = true;

            // Start a new scene
            this.scene.run(SceneNames.Disconnection, {reason: reason});
        });
    }

    private tearDownListeners(): void {
        this.socket.off(ClientSocketEvents.ServerShutdown);
    }

    public update(time: number, delta: number): void {
        this.accumulatedTime += delta;

        if (this.accumulatedTime >= 1000 / this.frameRate) {
            this.accumulatedTime -= 1000 / this.frameRate;
            this.currentFrame = this.currentFrame === 0 ? 1 : 0;
            this.updateSpritesFrames();
        }
    }

    public updateSpriteFrame(sprite: Phaser.GameObjects.Sprite): void {
        if (!sprite) {
            return; // Return early if sprite doesn't exist
        }

        let frameBase: number;
        // Use this.facingDirection since it's a property on the scene
        switch (this.facingDirection) {
            case Direction.DOWN:
                frameBase = 0; // Assuming downward frames are 0 and 1
                break;
            case Direction.UP:
                frameBase = 6; // Assuming upward frames are 6 and 7
                break;
            case Direction.LEFT:
                frameBase = 2; // Assuming leftward frames are 2 and 3
                break;
            case Direction.RIGHT:
                frameBase = 4; // Assuming rightward frames are 4 and 5
                break;
            default:
                frameBase = 0; // Default to downward frames
                break;
        }
        sprite.setFrame(frameBase + this.currentFrame);
    }

    private returnToCharacterSelection(): void {
        console.log('Returning To Character Selection Scene');
        this.tearDownListeners();
        this.scene.start(SceneNames.CharacterSelection);
    }

    private acceptClass(): void {
        console.log('Accepting class');
        const customizationData: PlayerCustomizationData = {
            selectedSkinToneIndex: this.selectedSkinToneIndex,
            selectedHairColorIndex: this.selectedHairColorIndex,
            selectedPrimaryColorIndex: this.selectedPrimaryColorIndex,
            selectedSecondaryColorIndex: this.selectedSecondaryColorIndex,
            selectedClassIndex: this.selectedClassIndex,
            selectedGenderIndex: this.selectedGenderIndex,
            accumulatedTime: this.accumulatedTime,
            currentFrame: this.currentFrame
        };
        this.tearDownListeners();
        this.scene.start(SceneNames.CharacterCustomization, customizationData);
    }

    private updateSpritesFrames(): void {
        // Update the frame for each part of the hero sprite
        this.updateSpriteFrame(this.heroDisplay);
        this.updateSpriteFrame(this.heroMaleHairDisplay);
        this.updateSpriteFrame(this.heroPrimaryClothesDisplay);
        this.updateSpriteFrame(this.heroSecondaryClothesDisplay);
        this.updateSpriteFrame(this.heroTertiaryClothesDisplay);
        this.updateSpriteFrame(this.heroRunebladeWeaponBottomDisplay);
        this.updateSpriteFrame(this.heroRunebladeWeaponTopDisplay);
        this.updateSpriteFrame(this.heroAethermancerWeaponBottomDisplay);
        this.updateSpriteFrame(this.heroAethermancerWeaponTopDisplay);
        this.updateSpriteFrame(this.heroLifeweaverWeaponBottomDisplay);
        this.updateSpriteFrame(this.heroLifeweaverWeaponTopDisplay);
    }

    private changeClass(direction: 'left' | 'right'): void {
        console.log(`Changing class in direction: ${direction}`);

        // Check the direction and update the current class index accordingly.
        if (direction === 'left') {
            this.selectedClassIndex = this.selectedClassIndex === 0 ? this.classNames.length - 1 : this.selectedClassIndex - 1;
            console.log(`New class index after moving left: ${this.selectedClassIndex}`);
        } else { // direction is 'right'
            this.selectedClassIndex = this.selectedClassIndex === this.classNames.length - 1 ? 0 : this.selectedClassIndex + 1;
            console.log(`New class index after moving right: ${this.selectedClassIndex}`);
        }

        // Update the UI with the new class information.
        this.classText.setText(this.classNames[this.selectedClassIndex]);
        this.classDescriptionText.setText(this.classDescriptions[this.selectedClassIndex]);
        console.log(`Class selected: ${this.classNames[this.selectedClassIndex]}`);
        console.log(`Class description: ${this.classDescriptions[this.selectedClassIndex]}`);

        // Hide all weapons first before showing the relevant ones.
        this.heroRunebladeWeaponBottomDisplay.setVisible(false);
        this.heroAethermancerWeaponBottomDisplay.setVisible(false);
        this.heroLifeweaverWeaponBottomDisplay.setVisible(false);
        this.heroRunebladeWeaponTopDisplay.setVisible(false);
        this.heroAethermancerWeaponTopDisplay.setVisible(false);
        this.heroLifeweaverWeaponTopDisplay.setVisible(false);
        console.log('All weapons hidden.');

        // Show the weapons for the selected class.
        switch (this.classNames[this.selectedClassIndex]) {
            case 'Runeblade':
                this.heroRunebladeWeaponBottomDisplay.setVisible(true);
                this.heroRunebladeWeaponTopDisplay.setVisible(true);
                console.log('Runeblade weapons displayed.');
                break;
            case 'Aethermancer':
                this.heroAethermancerWeaponBottomDisplay.setVisible(true);
                this.heroAethermancerWeaponTopDisplay.setVisible(true);
                console.log('Aethermancer weapons displayed.');
                break;
            case 'Lifeweaver':
                this.heroLifeweaverWeaponBottomDisplay.setVisible(true);
                this.heroLifeweaverWeaponTopDisplay.setVisible(true);
                console.log('Lifeweaver weapons displayed.');
                break;
        }
    }
}
