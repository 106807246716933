import ServerControlledGameScene from '../scenes/ServerControlledGameplayScenes/ServerControlledGameScene';

/**
 * Hides the game pad scene if it is active.
 * @param gameScene - The game scene instance that potentially contains the gamePadScene.
 * @returns void
 */
export function hideGamePadSceneIfNeeded(gameScene: ServerControlledGameScene): void {
    console.log('[hideGamePadSceneIfNeeded] Checking if gamePadScene exists and is active.');

    // Check if the gamePadScene exists
    if (gameScene.gamePadScene) {
        console.log(`[hideGamePadSceneIfNeeded] gamePadScene exists. Current visibility status: ${gameScene.gamePadScene.cameras.main.visible}`);

        gameScene.gamePadScene.resetJoyStickState();
        // Hide the game pad scene and log the action
        gameScene.gamePadScene.cameras.main.setVisible(false);
        console.log(`[hideGamePadSceneIfNeeded] gamePadScene visibility after hiding: ${gameScene.gamePadScene.cameras.main.visible}`);

        console.log('[hideGamePadSceneIfNeeded] Hid gamePadScene.');
    } else {
        console.log('[hideGamePadSceneIfNeeded] No gamePadScene available to hide.');
    }
}
