import {DialogueNode} from '../dialogue/DialogueTypes';
import {FailureState} from '../mechanics/FailureState';
import {SuccessState} from '../mechanics/SuccessState';
import {NPCVariants} from '../npcInfo/INPCBase';

export enum PurchaseAttemptResponseType {
    PurchaseComplete = 'PurchaseComplete',
    InsufficientFunds = 'InsufficientFunds',
    InsufficientSpace = 'InsufficientSpace', // New error type for insufficient inventory space
    OtherFailure = 'OtherFailure',
}

// Interface for a completed purchase interaction
export interface PurchaseCompleteSuccess extends SuccessState {
    type: PurchaseAttemptResponseType.PurchaseComplete;
    npcId: string;
    npcType: NPCVariants.Merchant;
    npcName: string;
    dialogue: DialogueNode; // Dialogue after a successful purchase
}

// Interface for the specific failure due to insufficient funds
export interface InsufficientFundsFailure extends FailureState {
    type: PurchaseAttemptResponseType.InsufficientFunds;
    npcId: string;
    npcType: NPCVariants.Merchant;
    npcName: string;
    dialogue: DialogueNode; // Dialogue for the insufficient funds scenario
}

// Interface for failure due to insufficient inventory space
export interface InsufficientSpaceFailure extends FailureState {
    type: PurchaseAttemptResponseType.InsufficientSpace;
    npcId: string;
    npcType: NPCVariants.Merchant;
    npcName: string;
    dialogue: DialogueNode; // Dialogue for the insufficient space scenario
}

// Interface for generic or other specific types of failures
export interface OtherFailure extends FailureState {
    type: PurchaseAttemptResponseType.OtherFailure;
    reason: string; // Reason for the failure
}

// Union type for all response scenarios
export type AttemptConfirmNPCMerchantInventoryItemPurchaseResponse =
    | PurchaseCompleteSuccess
    | InsufficientFundsFailure
    | InsufficientSpaceFailure // Include the new failure type
    | OtherFailure;
