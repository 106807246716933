import {Socket} from 'socket.io-client';
import {UIImageKey} from '../../../../types/assets/AssetKeys';
import {mainGameFont} from '../../GameConfig';
import ServerControlledGameUIScene from '../../scenes/ServerControlledUIScenes/ServerControlledGameUIScene';
import {DepthLevel} from '../../types/DepthLevel';
import {FieldMenuType} from '../../types/FieldMenuType';
import {GameFieldActionMenu} from '../UserInterface/MenusAndBars/FieldElements/GameFieldActionMenu';
import ResizableFrame from '../UserInterface/UtilityComponents/ResizableFrame';

export default class DismissibleNotification extends GameFieldActionMenu {
    public shown: boolean = false;
    public menuTag = FieldMenuType.DismissibleNotification;
    public relatedMenusToDismiss: Set<FieldMenuType> = new Set([
        FieldMenuType.AbilityMenu,
        FieldMenuType.CharacterSheetMenu,
        FieldMenuType.TargetMenu,
        FieldMenuType.DuelNotification,
        FieldMenuType.InviteNotification,
        FieldMenuType.PartyOrderMenu,
        FieldMenuType.CommonerNPCMenu,
        FieldMenuType.InnkeeperNPCMenu,
        FieldMenuType.MerchantNPCMenu,
        FieldMenuType.MessageElement,
        FieldMenuType.InventoryMenu,
        FieldMenuType.TradeMenu,
        FieldMenuType.InteractableMessageDisplay,

        FieldMenuType.InteractionMenu,

        // Any other menu that should be dismissed when AbilityMenu is active
    ]);

    public canCoincideWithGamePad = true;

    public notificationFrame: ResizableFrame;
    public closeNotificationButton: Phaser.GameObjects.Image;
    public notificationText: Phaser.GameObjects.Text;

    public constructor(public scene: ServerControlledGameUIScene, private socket: Socket, initialMessage: string) {
        super();
        const centerX = this.scene.cameras.main.centerX;
        const centerY = this.scene.cameras.main.centerY + 144;

        // Define frame dimensions
        const frameWidth = 600;
        const frameHeight = 150; // Increased height
        this.notificationFrame = new ResizableFrame(this.scene, centerX, centerY, frameWidth, frameHeight);

        // Create the close button at (0, 0)
        this.closeNotificationButton = this.scene.add.image(0, 0, UIImageKey.CrossIcon).setInteractive().setDepth(DepthLevel.UI_PRIMARY_GRAPHICS_SUB).setScale(1.5);

        this.notificationText = this.scene.add.text(centerX - 300, centerY - 75, initialMessage, { // Adjusted text position for increased height
            fontFamily: mainGameFont,
            fontSize: '54px',
            color: '#ffffff',
            wordWrap: {
                width: 575,
                useAdvancedWrap: true
            }
        }).setDepth(DepthLevel.UI_PRIMARY_TEXT);
        this.notificationText.setLineSpacing(-15);
        this.notificationText.setOrigin(0); // center the text

        // Calculate the desired position of the close button
        const closeButtonX = centerX + frameWidth / 2 - this.closeNotificationButton.displayWidth / 2 - 5; // Move 2 pixels to the left
        const closeButtonY = centerY - frameHeight / 2 + this.closeNotificationButton.displayHeight / 2 + 5; // Move 2 pixels down

        // Set the position of the close button
        this.closeNotificationButton.setPosition(closeButtonX, closeButtonY);

        // Register click event for the close button
        this.closeNotificationButton.on('pointerdown', () => this.dismiss());
        this.closeNotificationButton.setVisible(false);

        // Initially hide the notification
        this.hideNotification();
    }

    public activate(message: string): void {
        this.shown = true;
        console.log('[DismissibleNotification.activate] Activating Dismissible Notification Menu with message:', message);
        this.dismissRelatedMenus();
        this.notificationText.setText(message);
        this.notificationFrame.showFrame();
        this.notificationText.setVisible(true);
        this.closeNotificationButton.setVisible(true);
        console.log('[DismissibleNotification.activate] Exiting method.');
    }

    public emitMenuDismissalRequest(): void{
        this.dismiss();
    }

    public emitMenuActivationRequest(params?: {message: string}): void {
        if (params) {
            this.activate(params.message);
        }
    }

    public dismiss(): void {
        console.log('[DismissibleNotification.dismiss] Dismissing Dismissible Notification Menu');

        // console.log('[DismissibleNotification.dismiss] Starting gamePadScene if needed.');
        // showGamePadSceneIfNeeded(this.scene.serverControlledGameScene);

        this.hideNotification();

        this.activateInteractionMenuIfNeeded();
        this.shown = false;
        console.log('[DismissibleNotification.dismiss] Exiting method.');
    }

    private hideNotification(): void {
        this.notificationFrame.hideFrame();
        this.notificationText.setVisible(false);
        this.closeNotificationButton.setVisible(false);
    }
}
