import {SpriteSheet} from '../assets/AssetKeys';
import {DialogueNode} from '../dialogue/DialogueTypes';
import {MapName} from '../mapData/MapName';
import {Coord} from '../mechanics/Coord';
import {Direction} from '../physics/Direction';

export enum MovementType {
    Stationary,
    Patrol,
}

export enum NPCVariants {
    Commoner = 'Commoner',
    Merchant = 'Merchant',
    Innkeeper = 'Innkeeper',
    QuestGiver = 'QuestGiver',
}

export enum RelationshipStatus {
    Friendly,
    Neutral,
    Unfriendly,
}

export interface INPCBase {
    id: string;
    spriteKey: SpriteSheet;
    name: string;
    map: MapName;
    type: NPCVariants;
    startingPosition: Coord;
    startingFacingDirection: Direction;
    movementType: MovementType;
    movementPath?: Coord[];
    relationshipStatus: RelationshipStatus;
    dialogues: DialogueNode[];
    interactionRange: 1 | 2;
}
