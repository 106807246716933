import {Socket} from 'socket.io-client';
import {ServerSocketEvents} from '../../../../../../types/events/ServerSocketEvents';
import {ResourceType} from '../../../../../../types/mechanics/ResourceType';
import {CharacterSheetData} from '../../../../../../types/playerInfo/CharacterSheetData';
import {mainGameFont} from '../../../../GameConfig';
import ServerControlledGameUIScene from '../../../../scenes/ServerControlledUIScenes/ServerControlledGameUIScene';
import {DepthLevel} from '../../../../types/DepthLevel';
import {FieldMenuType} from '../../../../types/FieldMenuType';
import {adjustFontSizeByPixel} from '../../../../utils/phaserTextUtils';
import ResizableFrame from '../../UtilityComponents/ResizableFrame';
import {GameFieldActionMenu} from './GameFieldActionMenu';

export default class CharacterSheetMenu extends GameFieldActionMenu {
    public shown: boolean = false;
    public relatedMenusToDismiss: Set<FieldMenuType> = new Set([
        FieldMenuType.AbilityMenu,
        FieldMenuType.TargetMenu,
        FieldMenuType.DuelNotification,
        FieldMenuType.DismissibleNotification,
        FieldMenuType.InviteNotification,
        FieldMenuType.PartyOrderMenu,
        FieldMenuType.CommonerNPCMenu,
        FieldMenuType.InnkeeperNPCMenu,
        FieldMenuType.MerchantNPCMenu,
        FieldMenuType.MessageElement,
        FieldMenuType.InventoryMenu,
        FieldMenuType.TradeMenu,
        FieldMenuType.InteractableMessageDisplay,

        FieldMenuType.InteractionMenu,
        // Any other menu that should be dismissed when AbilityMenu is active
    ]);
    public canCoincideWithGamePad = true;

    public menuTag = FieldMenuType.CharacterSheetMenu;
    private characterSheetFrame: ResizableFrame;
    private characterNameFrame: ResizableFrame;
    private characterNameText: Phaser.GameObjects.Text;
    private classText: Phaser.GameObjects.Text;
    private levelText: Phaser.GameObjects.Text;
    private hitPointText: Phaser.GameObjects.Text;
    private resourceText: Phaser.GameObjects.Text;
    private strengthText: Phaser.GameObjects.Text;
    private agilityText: Phaser.GameObjects.Text;
    private vitalityText: Phaser.GameObjects.Text;
    private intellectText: Phaser.GameObjects.Text;
    private luckText: Phaser.GameObjects.Text;
    private defenseText: Phaser.GameObjects.Text;
    private tillNextLevelText: Phaser.GameObjects.Text;

    public constructor(public scene: ServerControlledGameUIScene, private socket: Socket) {
        super();
        console.log('CharacterSheetMenu constructor called');
        this.characterSheetFrame = new ResizableFrame(this.scene, 720, 392, 371, 409);
        this.characterSheetFrame.hideFrame();
        this.characterNameFrame = new ResizableFrame(this.scene, 720, 144, 371, 50);
        this.characterNameFrame.hideFrame();

        this.characterNameText = this.scene.add.text(538, 117, 'Test Player Name', {
            color: '#ffffff',
            align: 'left',
            fontFamily: mainGameFont,
        })
            .setResolution(3)
            .setFontSize(50)
            .setDepth(DepthLevel.UI_PRIMARY_TEXT)
            .setVisible(false);
        adjustFontSizeByPixel(this.characterNameText, 371, 50);

        this.classText = this.scene.add.text(538, 185, 'Class: Aethermancer', {
            fontSize: '48px',
            color: '#fff',
            fontFamily: mainGameFont
        })
            .setResolution(3)
            .setDepth(DepthLevel.UI_PRIMARY_TEXT)
            .setVisible(false);

        this.levelText = this.scene.add.text(538, 221, 'Level: 1', {
            fontSize: '48px',
            color: '#fff',
            fontFamily: mainGameFont
        })
            .setResolution(3)
            .setDepth(DepthLevel.UI_PRIMARY_TEXT)
            .setVisible(false);

        this.hitPointText = this.scene.add.text(538, 257, 'Hit Points: 100/100', {
            fontSize: '48px',
            color: '#fff',
            fontFamily: mainGameFont
        }).setResolution(3).setDepth(DepthLevel.UI_PRIMARY_TEXT)
            .setVisible(false);

        this.resourceText = this.scene.add.text(538, 293, 'Vim: N/A', {
            fontSize: '48px',
            color: '#fff',
            fontFamily: mainGameFont
        }).setResolution(3).setDepth(DepthLevel.UI_PRIMARY_TEXT)
            .setVisible(false);

        this.strengthText = this.scene.add.text(538, 329, 'Strength: 15', {
            fontSize: '48px',
            color: '#fff',
            fontFamily: mainGameFont
        }).setResolution(3).setDepth(DepthLevel.UI_PRIMARY_TEXT)
            .setVisible(false);

        this.agilityText = this.scene.add.text(538, 365, 'Agility: 12', {
            fontSize: '48px',
            color: '#fff',
            fontFamily: mainGameFont
        }).setResolution(3).setDepth(DepthLevel.UI_PRIMARY_TEXT)
            .setVisible(false);

        this.vitalityText = this.scene.add.text(538, 401, 'Vitality: 14', {
            fontSize: '48px',
            color: '#fff',
            fontFamily: mainGameFont
        }).setResolution(3).setDepth(DepthLevel.UI_PRIMARY_TEXT)
            .setVisible(false);

        this.intellectText = this.scene.add.text(538, 437, 'Intellect: 13', {
            fontSize: '48px',
            color: '#fff',
            fontFamily: mainGameFont
        }).setResolution(3).setDepth(DepthLevel.UI_PRIMARY_TEXT)
            .setVisible(false);

        this.luckText = this.scene.add.text(538, 473, 'Luck: 10', {
            fontSize: '48px',
            color: '#fff',
            fontFamily: mainGameFont
        }).setResolution(3).setDepth(DepthLevel.UI_PRIMARY_TEXT)
            .setVisible(false);

        this.defenseText = this.scene.add.text(538, 509, 'Defense: 20', {
            fontSize: '48px',
            color: '#fff',
            fontFamily: mainGameFont
        }).setResolution(3).setDepth(DepthLevel.UI_PRIMARY_TEXT)
            .setVisible(false);

        this.tillNextLevelText = this.scene.add.text(538, 545, 'Till Next Level: 500', {
            fontSize: '48px',
            color: '#fff',
            fontFamily: mainGameFont
        }).setResolution(3).setDepth(DepthLevel.UI_PRIMARY_TEXT)
            .setVisible(false);
    }

    public emitMenuDismissalRequest(): void {
        // Check if the player is currently processing a field item
        if (!this.scene.canProceedWithMenuAction()) {
            // If false, exit early. Logging is already handled within `canProceedWithMenuAction`.
            return;
        }

        console.log('[Character Sheet Menu] Preparing to close the character sheet');
        this.scene.waitingForServerResponse = true;
        this.socket.emit(ServerSocketEvents.AttemptToggleCharacterSheet, 'close');
    }

    public emitMenuActivationRequest(): void {
        if (!this.scene.canProceedWithMenuAction()) {
            // If false, exit early. Logging is already handled within `canProceedWithMenuAction`.
            return;
        }

        console.log('[CharacterSheetMenu.emitMenuActivationRequest] Preparing to open the character sheet');
        this.scene.waitingForServerResponse = true;
        this.socket.emit(ServerSocketEvents.AttemptToggleCharacterSheet, 'open');
    }

    public updateCharacterSheet(data: CharacterSheetData): void {
        this.characterNameText.setText(data.name);
        this.classText.setText(`Class: ${data.className}`);
        this.levelText.setText(`Level: ${data.level}`);
        this.hitPointText.setText(`Hit Points: ${data.currentHP}/${data.maxHP}`);

        // Check if the resourceType is Vim, if so, display "N/A" for the amount
        if (data.resourceType === ResourceType.Vim) { // Assuming "Vim" is a string, adjust based on actual enum or type definition
            this.resourceText.setText('Vim: N/A');
        } else {
            this.resourceText.setText(`${data.resourceType}: ${data.currentResource}/${data.maxResource}`);
        }

        this.strengthText.setText(`Strength: ${data.strength}`);
        this.agilityText.setText(`Agility: ${data.agility}`);
        this.vitalityText.setText(`Vitality: ${data.vitality}`);
        this.intellectText.setText(`Intellect: ${data.intellect}`);
        this.luckText.setText(`Luck: ${data.luck}`);
        this.defenseText.setText(`Defense: ${data.defense}`);

        // Check if experienceTillNextLevel is 0, if so, display "MAX"
        if (data.experienceTillNextLevel === 0) {
            this.tillNextLevelText.setText('Till Next Level: MAX');
        } else {
            this.tillNextLevelText.setText(`Till Next Level: ${data.experienceTillNextLevel}`);
        }
    }

    public activate(): void {
        this.shown = true;
        console.log('[CharacterSheetMenu.activate] Activating Character Sheet Menu');
        this.dismissRelatedMenus();
        // Show frames
        this.characterSheetFrame.showFrame();
        this.characterNameFrame.showFrame();

        // Set visibility of all text elements to true
        this.characterNameText.setVisible(true);
        this.classText.setVisible(true);
        this.levelText.setVisible(true);
        this.hitPointText.setVisible(true);
        this.resourceText.setVisible(true);
        this.strengthText.setVisible(true);
        this.agilityText.setVisible(true);
        this.vitalityText.setVisible(true);
        this.intellectText.setVisible(true);
        this.luckText.setVisible(true);
        this.defenseText.setVisible(true);
        this.tillNextLevelText.setVisible(true);
        console.log('[CharacterSheetMenu.activate] Exiting method.');
    }

    public dismiss(): void {
        console.log('[CharacterSheetMenu.dismiss] Dismissing Character Sheet Menu');
        // Hide frames
        this.characterSheetFrame.hideFrame();
        this.characterNameFrame.hideFrame();

        // Set visibility of all text elements to false
        this.characterNameText.setVisible(false);
        this.classText.setVisible(false);
        this.levelText.setVisible(false);
        this.hitPointText.setVisible(false);
        this.resourceText.setVisible(false);
        this.strengthText.setVisible(false);
        this.agilityText.setVisible(false);
        this.vitalityText.setVisible(false);
        this.intellectText.setVisible(false);
        this.luckText.setVisible(false);
        this.defenseText.setVisible(false);
        this.tillNextLevelText.setVisible(false);

        this.scene.gameActionBar.characterSheetButton.deselect();

        this.activateInteractionMenuIfNeeded();
        this.shown = false;
        console.log('[CharacterSheetMenu.dismiss] Exiting method.');
    }

}
