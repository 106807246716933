import {Socket} from 'socket.io-client';
import {
    AttemptConflictAbilityMainSelectResponseEvent
} from '../../../../types/conflict/AttemptConflictAbilityMainSelectResponseEvent';
import {
    AttemptConflictAbilitySelectionConfirmResponseEvent
} from '../../../../types/conflict/AttemptConflictAbilitySelectionConfirmResponseEvent';
import {
    AttemptConflictAbilitySelectionResponseEvent
} from '../../../../types/conflict/AttemptConflictAbilitySelectionResponseEvent';
import {
    AbilityConflictActionEvent,
    AttackConflictActionEvent,
    AttemptConflictActionEvent, BagOrQuestItemConflictActionEvent,
} from '../../../../types/conflict/AttemptConflictActionEvent';
import {AttemptConflictActionResponseEvent} from '../../../../types/conflict/AttemptConflictActionResponseEvent';
import {
    AttemptConflictInventoryItemMainSelectResponseEvent
} from '../../../../types/conflict/AttemptConflictInventoryItemMainSelectResponseEvent';
import {
    AttemptConflictInventoryItemSelectionConfirmResponseEvent
} from '../../../../types/conflict/AttemptConflictInventoryItemSelectionConfirmResponseEvent';
import {
    AttemptConflictInventoryItemSelectionResponseEvent
} from '../../../../types/conflict/AttemptConflictInventoryItemSelectionResponseEvent';
import {CancelActionEvent} from '../../../../types/conflict/CancelActionEvent';
import {ConflictActionType} from '../../../../types/conflict/ConflictActionType';
import {LevelUpEvent} from '../../../../types/conflict/LevelUpEvent';
import {NewRoundPayload} from '../../../../types/conflict/NewRoundPayload';
import {PlayerConflictMode} from '../../../../types/conflict/PlayerConflictMode';
import {PreTurnActionData} from '../../../../types/conflict/PreTurnActionData';
import {TargetTypeEnum} from '../../../../types/conflict/TargetTypeEnum';
import {ConflictTurnActionData} from '../../../../types/conflict/TurnActionData';
import {AttemptBindActionResponse} from '../../../../types/events/AttemptBindActionResponse';
import {AttemptHotkeyBindResponse} from '../../../../types/events/AttemptHotkeyBindResponse';
import {AttemptToggleConflictBindModeResponse} from '../../../../types/events/AttemptToggleConflictBindModeResponse';
import {ServerSocketEvents} from '../../../../types/events/ServerSocketEvents';
import {
    AttemptSelectConflictEquipmentItemResponseEvent
} from '../../../../types/inventory/AttemptSelectConflictEquipmentItemResponseEvent';
import {
    AttemptSwitchConflictInventoryModeResponse
} from '../../../../types/inventory/AttemptSwitchConflictInventoryModeResponseEvent';
import {EquipmentItemForDisplay} from '../../../../types/inventory/ItemForDisplay';
import {ItemType} from '../../../../types/items/ItemTypes';
import {CharacterProfession} from '../../../../types/mechanics/CharacterProfessionsEnum';
import {Gender} from '../../../../types/mechanics/Gender';
import {BaseInteractionState, InteractionState, ItemCategory} from '../../../../types/mechanics/InteractionState';
import {ResourceType} from '../../../../types/mechanics/ResourceType';
import {PlayerDataArray} from '../../../../types/playerInfo/PlayerDataArray';
import ServerControlledConflictScene from '../../scenes/ServerControlledGameplayScenes/ServerControlledConflictScene';
import ServerControlledGameScene from '../../scenes/ServerControlledGameplayScenes/ServerControlledGameScene';
import ServerControlledConflictUIScene from '../../scenes/ServerControlledUIScenes/ServerControlledConflictUIScene';
import {ConflictSceneData, OngoingConflictSceneData} from '../../types/ConflictSceneData';
import {DepthLevel} from '../../types/DepthLevel';
import {SceneNames} from '../../types/SceneNames';
import {ClientInteractionStateRegexHelper} from '../../utils/clientInteractionStateRegexHelper';
import {showGamePadSceneIfNeeded} from '../../utils/showGamePadSceneIfNeeded';
import SocketManager from '../NetworkingAndChat/SocketManager';

export default class ServerControlledConflictUISceneController {
    private gameScene: ServerControlledGameScene;
    private socket: Socket;
    public conflictUIScene: ServerControlledConflictUIScene;
    private conflictScene: ServerControlledConflictScene;
    private conflictState: ConflictSceneData;

    public constructor(scene: Phaser.Scene, gameScene: ServerControlledGameScene) {
        this.gameScene = gameScene;
        this.conflictUIScene = scene.scene.get(SceneNames.ServerControlledConflictUI) as ServerControlledConflictUIScene;
        this.conflictScene = scene.scene.get(SceneNames.ServerControlledConflict) as ServerControlledConflictScene;
        this.conflictScene.gameScene.setSceneLoaded('conflictUiSceneController');
    }

    public startConflict(conflictState: ConflictSceneData): void {
        console.log('[LOG] startConflict() method invoked with conflictState:', conflictState, 'and socket:', conflictState.socket);

        this.conflictUIScene.enemyManager.repositionEnemySprites();
        this.conflictUIScene.enemyManager.repositionEnemyBadges();

        const allyData = this.conflictScene.eventData.allies;
        // Loop through allyData to check each ally's ResourceType
        for (let i = 0; i < allyData.length; i++) {
            const ally = allyData[i];

            // If the ally's resource type is Vim, make the corresponding empty Vim icons visible
            if (ally.ResourceType === ResourceType.Vim) {
                // Assuming each ally's empty Vim icons are stored in this.conflictUIScene.allyManager.emptyVimIcons[i]
                for (let j = 0; j < this.conflictUIScene.allyManager.emptyVimIcons[i].length; j++) {
                    const emptyIcon = this.conflictUIScene.allyManager.emptyVimIcons[i][j];
                    emptyIcon.setVisible(true); // Make each empty Vim icon visible
                }
                for (let j = 0; j < this.conflictUIScene.allyManager.filledVimIcons[i].length; j++) {
                    const filledIcon = this.conflictUIScene.allyManager.filledVimIcons[i][j];
                    filledIcon.setVisible(false); // Make each empty Aether icon invisible
                }
            } else {
                // If the ally's resource type is not Vim, make the corresponding empty Vim icons invisible
                for (let j = 0; j < this.conflictUIScene.allyManager.emptyVimIcons[i].length; j++) {
                    const emptyIcon = this.conflictUIScene.allyManager.emptyVimIcons[i][j];
                    emptyIcon.setVisible(false); // Make each empty Vim icon invisible
                }

                for (let j = 0; j < this.conflictUIScene.allyManager.filledVimIcons[i].length; j++) {
                    const filledIcon = this.conflictUIScene.allyManager.filledVimIcons[i][j];
                    filledIcon.setVisible(false); // Make each empty Aether icon invisible
                }
            }
        }

        for (let i = 0; i < allyData.length; i++) {
            const allyComponent = this.conflictUIScene.allyManager.allyComponents[i];
            const allyIsAlive = allyComponent.isAlive;
            const allyClassName = allyComponent.className;
            const allyGender = allyComponent.gender;

            if (!allyIsAlive) {
                allyComponent.runebladeBottomWeaponSprite.setAngle(-90);
                allyComponent.aethermancerBottomWeaponSprite.setAngle(-90);
                allyComponent.lifeweaverBottomWeaponSprite.setAngle(-90);
                allyComponent.baseSprite.setAngle(-90);
                allyComponent.primaryClothingSprite.setAngle(-90);
                allyComponent.secondaryClothingSprite.setAngle(-90);
                allyComponent.tertiaryClothingSprite.setAngle(-90);
                allyComponent.maleHairSprite.setAngle(-90);
                allyComponent.femaleHairSprite.setAngle(-90);
                allyComponent.runebladeTopWeaponSprite.setAngle(-90);
                allyComponent.aethermancerTopWeaponSprite.setAngle(-90);
                allyComponent.lifeweaverTopWeaponSprite.setAngle(-90);
            } else {
                allyComponent.button.setVisible(true);
            }

            allyComponent.baseSprite.setVisible(true);
            allyComponent.primaryClothingSprite.setVisible(true);
            allyComponent.secondaryClothingSprite.setVisible(true);
            allyComponent.tertiaryClothingSprite.setVisible(true);

            switch (allyGender) {
                case Gender.Male:
                    allyComponent.maleHairSprite.setVisible(true);
                    break;
                case Gender.Female:
                    allyComponent.femaleHairSprite.setVisible(true);
                    break;
            }

            switch (allyClassName) {
                case CharacterProfession.Runeblade:
                    allyComponent.runebladeBottomWeaponSprite.setVisible(true);
                    allyComponent.runebladeTopWeaponSprite.setVisible(true);
                    break;
                case CharacterProfession.Aethermancer:
                    allyComponent.aethermancerBottomWeaponSprite.setVisible(true);
                    allyComponent.aethermancerTopWeaponSprite.setVisible(true);
                    break;
                case CharacterProfession.Lifeweaver:
                    allyComponent.lifeweaverBottomWeaponSprite.setVisible(true);
                    allyComponent.lifeweaverTopWeaponSprite.setVisible(true);
                    break;
            }

        }

        for (const enemyComponent of this.conflictUIScene.enemyManager.enemyComponents) {
            console.log(`[startConflict] enemy name: ${enemyComponent} enemyComponent.isAlive: ${enemyComponent.isAlive}`);
            const enemyIsAlive = enemyComponent.isAlive;
            const enemyClassName = enemyComponent.className;
            const enemyGender = enemyComponent.gender;
            const enemyType = enemyComponent.type;
            if (enemyIsAlive) {
                console.log('[startConflict] executing enemyIsAlive branch');

                // Before change
                console.log('Before baseSprite Visibility Change:', {
                    visibility: enemyComponent.baseSprite.visible,
                    depth: enemyComponent.baseSprite.depth,
                    scale: {x: enemyComponent.baseSprite.scaleX, y: enemyComponent.baseSprite.scaleY}, // Adjusted for scale properties
                    origin: {x: enemyComponent.baseSprite.originX, y: enemyComponent.baseSprite.originY}
                });

                enemyComponent.baseSprite.setVisible(true);

                // After change
                console.log('After baseSprite Visibility Change:', {
                    visibility: enemyComponent.baseSprite.visible,
                    depth: enemyComponent.baseSprite.depth,
                    scale: {x: enemyComponent.baseSprite.scaleX, y: enemyComponent.baseSprite.scaleY}, // Adjusted to show scale x and y
                    origin: {x: enemyComponent.baseSprite.originX, y: enemyComponent.baseSprite.originY}
                });
                enemyComponent.button.setVisible(true);
                if (enemyType === 'Player') {
                    if (enemyIsAlive) {
                        enemyComponent.primaryClothingSprite?.setVisible(true);
                        enemyComponent.secondaryClothingSprite?.setVisible(true);
                        enemyComponent.tertiaryClothingSprite?.setVisible(true);
                        switch (enemyGender) {
                            case Gender.Male:
                                enemyComponent.maleHairSprite?.setVisible(true);
                                break;
                            case Gender.Female:
                                enemyComponent.femaleHairSprite?.setVisible(true);
                                break;
                        }
                        switch (enemyClassName) {
                            case CharacterProfession.Runeblade:
                                enemyComponent.runebladeBottomWeaponSprite?.setVisible(true);
                                enemyComponent.runebladeTopWeaponSprite?.setVisible(true);
                                break;
                            case CharacterProfession.Aethermancer:
                                enemyComponent.aethermancerBottomWeaponSprite?.setVisible(true);
                                enemyComponent.aethermancerTopWeaponSprite?.setVisible(true);
                                break;
                            case CharacterProfession.Lifeweaver:
                                enemyComponent.lifeweaverBottomWeaponSprite?.setVisible(true);
                                enemyComponent.lifeweaverTopWeaponSprite?.setVisible(true);
                                break;
                        }
                    }
                }
            } else {
                console.log('[startConflict] executing !enemyIsAlive branch');
            }
        }

        this.socket = SocketManager.getInstance().socket;
        this.conflictState = conflictState;

        console.log('[LOG] Set conflictState and socket.');

        if (conflictState.isOngoingConflict) {
            // Log the ServerPlayerInteractionState for debugging purposes
            console.log(`[DEBUG] ServerPlayerInteractionState: ${conflictState.ongoingConflictState.ServerPlayerInteractionState}`);
            const ongoingState = conflictState as OngoingConflictSceneData;

            this.conflictScene.interactionState = ongoingState.ongoingConflictState.ServerPlayerInteractionState;

            // Show round timer frame and texts if not single player PVE and in main select state
            if (!this.conflictScene.isSinglePlayerPVE && ongoingState.ongoingConflictState.ConflictInteractionState === BaseInteractionState.MainSelect) {
                this.conflictUIScene.allyManager.allyArmedActionsFrame.showFrame();
                this.conflictUIScene.roundTimerFrame.showFrame();
                this.conflictUIScene.roundTimerLabelText.setVisible(true);

                // Set the countdown text based on the ongoing conflict state
                const countdownValue = ongoingState.ongoingConflictState.RoundTimer;
                this.conflictUIScene.roundTimerCountdownText.setText(`-${countdownValue.toString()}-`);
                this.conflictUIScene.roundTimerCountdownText.setVisible(true);

                // Populate the selected actions for the round from the ongoing conflict state data
                ongoingState.ongoingConflictState.PendingActionsInRound.forEach(action => {
                    let actionText = '';
                    if (action.actionType === ConflictActionType.Attack) {
                        actionText = `${action.actorIndex + 1}. ${action.actorName} will attack ${action.targetName}`;
                    } else if (action.actionType === ConflictActionType.Ability) {
                        actionText = `${action.actorIndex + 1}. ${action.actorName} will perform ${action.actionName} on ${action.targetName}`;
                    } else if (action.actionType === ConflictActionType.Item) {
                        actionText = `${action.actorIndex + 1}. ${action.actorName} will use ${action.actionName} on ${action.targetName}`;
                    } else if (action.actionType === ConflictActionType.Flee) {
                        actionText = `${action.actorIndex + 1}. ${action.actorName} will attempt to flee`;
                    }

                    this.conflictUIScene.allyManager.alliesArmedActionTexts[action.actorIndex].setText(actionText);
                    this.conflictUIScene.allyManager.alliesArmedActionTexts[action.actorIndex].setVisible(true);

                    console.log(`Action for player ${action.actorName} (Index ${action.actorIndex}): ${actionText}`);
                });

                // Check if 'self' is in the pending actions and update UI accordingly
                const selfIndex = this.conflictScene.allyTypes.findIndex(type => type === 'self');
                const selfAction = ongoingState.ongoingConflictState.PendingActionsInRound.find(action => action.actorIndex === selfIndex);

                if (this.conflictScene.interactionState === BaseInteractionState.SkipTurn) {
                    // Specific UI updates for SkipTurn state
                    this.conflictUIScene.messageElement.activate({
                        topMessage: 'Waiting for other combatants...',
                        updateTopMessage: true,
                        showTopMessage: true,
                        middleMessage: '',
                        updateMiddleMessage: false,
                        showMiddleMessage: false,
                        bottomMessage: '',
                        updateBottomMessage: false,
                        showBottomMessage: false
                    });
                } else if (selfAction) {
                    this.conflictUIScene.allyManager.alliesArmedActionCancelButtons[selfIndex].setVisible(true);
                    this.conflictUIScene.messageElement.activate({
                        topMessage: 'Waiting for other combatants...',
                        updateTopMessage: true,
                        showTopMessage: true,
                        middleMessage: '',
                        updateMiddleMessage: false,
                        showMiddleMessage: false,
                        bottomMessage: '',
                        updateBottomMessage: false,
                        showBottomMessage: false
                    });

                    // Call select method based on the action type
                    if (selfAction.actionType === ConflictActionType.Attack) {
                        this.conflictUIScene.primaryActionMenu.attackButton.select();
                    } else {
                        this.conflictUIScene.primaryActionMenu.abilityButton.select();
                    }
                } else {
                    this.conflictScene.commandString = 'Command?';
                    // this.conflictUIScene.commandMenu.activate(this.conflictScene.commandString);
                    this.conflictUIScene.commandMenu.activate({
                        topMessage: this.conflictScene.commandString,
                        updateTopMessage: true,
                        showTopMessage: true,
                        bottomMessage: '',
                        updateBottomMessage: true,
                        showBottomMessage: false,
                        iconLabel: '',
                        updateIcon: false,
                        showIcon: false,
                        selectIcon: false
                    });
                    this.conflictUIScene.hotkeyMenu.activate();
                }
            }
            // Additional logic for ongoing conflict
        } else {
            // Handle fresh conflict
            // this.conflictUIScene.conflictMessageFrame.showFrame();
            console.log('[LOG] Displayed message frame.');

            if (!this.conflictScene.isSinglePlayerPVE) {
                this.conflictUIScene.roundTimerFrame.showFrame();
                this.conflictUIScene.roundTimerLabelText.setVisible(true);
                console.log(`[startConflict] ConflictState.roundTimer: ${conflictState.roundTimer}`);
                const initialRoundTimerCountdownText = conflictState.roundTimer ? conflictState.roundTimer.toString() : '20';
                console.log(`[startConflict] initialRoundTimerCountdownText: ${initialRoundTimerCountdownText}`);
                this.conflictUIScene.roundTimerCountdownText.setText(`-${initialRoundTimerCountdownText}-`);
                this.conflictUIScene.roundTimerCountdownText.setVisible(true);
                this.conflictUIScene.allyManager.allyArmedActionsFrame.showFrame();
            }

            // Set the message based on the type and number of enemies
            let livingEnemiesCount = 0;
            const livingEnemies = [];
            for (const enemy of conflictState.enemies) {
                if (enemy.IsAlive) {
                    livingEnemiesCount++;
                    livingEnemies.push(enemy);
                }
            }
            if (livingEnemiesCount <= 1) {
                const enemyName = livingEnemies[0].Name;
                this.conflictUIScene.messageElement.activate({
                    topMessage: `${enemyName} approaches.`,
                    updateTopMessage: true,
                    showTopMessage: true,
                    middleMessage: '',
                    updateMiddleMessage: false,
                    showMiddleMessage: false,
                    bottomMessage: '',
                    updateBottomMessage: false,
                    showBottomMessage: false
                });
                console.log('[LOG] Single enemy approaches.');
            } else {
                this.conflictUIScene.messageElement.activate({
                    topMessage: 'Several enemies approach.',
                    updateTopMessage: true,
                    showTopMessage: true,
                    middleMessage: '',
                    updateMiddleMessage: false,
                    showMiddleMessage: false,
                    bottomMessage: '',
                    updateBottomMessage: false,
                    showBottomMessage: false
                });
                console.log('[LOG] Multiple enemies approach.');
            }

            console.log('[LOG] Message text set to visible.');
        }

        // Set target callbacks for UI buttons
        this.conflictUIScene.primaryActionMenu.attackButton.targetCallback = this.handleAttackButton.bind(this);
        this.conflictUIScene.primaryActionMenu.abilityButton.targetCallback = this.handleAbilityMainSelectButton.bind(this);
        this.conflictUIScene.primaryActionMenu.inventoryButton.targetCallback = this.handleInventoryMainSelectButton.bind(this);
        this.conflictUIScene.primaryActionMenu.bindButton.targetCallback = this.handleBindButton.bind(this);
        this.conflictUIScene.primaryActionMenu.fleeButton.targetCallback = this.handleFleeButton.bind(this);
        console.log('[LOG] Set targetCallbacks for UI buttons.');
    }

    public handleBindButton(): void {
        console.log('[handleBindButton] Bind button pressed.');
        if (!this.conflictUIScene.canProceedWithMenuAction()) {
            console.log('Cannot proceed with menu action. Exiting early.');
            return;
        }
        this.conflictUIScene.waitingForServerResponse = true;

        // Determine the toggle state based on the current mode
        const toggleBindOn = this.conflictScene.mode !== PlayerConflictMode.BindAction && this.conflictScene.mode !== PlayerConflictMode.BindHotkey;

        // Emit an event to toggle bind mode on the server with the intended state
        this.socket.emit(ServerSocketEvents.AttemptToggleConflictBindMode, {toggleBindOn});

        // Log the action for debugging purposes
        console.log(`[ServerControlledConflictUIScene] Bind mode toggle requested: ${toggleBindOn ? 'ON' : 'OFF'}`);
    }

    public handleAbilityMainSelectButton(): void {
        console.log('Ability button pressed');
        console.log('interactionState: ', this.conflictScene.interactionState);

        // Utilize InteractionStateRegexHelper to get the dynamic state regex based on required options
        const acceptableStatesRegex = ClientInteractionStateRegexHelper.getDynamicStateRegex({
            mainSelect: true,
            attack: true, // Added attack option
            abilityDetailPage: true,
            abilityTargeting: true,
            itemMainSelectBag: true,
            itemMainSelectQuest: true,
            itemMainSelectEquipment: true,
            bagItemDetail: true, // Using refined method names
            questItemDetail: true, // Using refined method names
            bagItemTargeting: true, // Using refined method names
            questItemTargeting: true, // Using refined method names
            equipmentItemDetail: true, // Including if checking for equipment details
            equipmentItemTargeting: true // Including if checking for equipment targeting
        });

        // Check if the interactionState matches one of the acceptable states
        if (!acceptableStatesRegex.test(this.conflictScene.interactionState)) {
            console.log('Interaction state does not match any acceptable states. Returning.');
            return;
        }

        console.log('Interaction state matches one of the acceptable states. Proceeding to emit attemptConflictAbilityMainSelect.');
        // Emit the 'attemptConflictAbilityMainSelect' event to the server
        this.conflictUIScene.abilityMenu.emitMenuActivationRequest();
    }

    public handleInventoryMainSelectButton(): void {
        console.log('Inventory button pressed');
        console.log('interactionState: ', this.conflictScene.interactionState);

        // Utilize InteractionStateRegexHelper to get the dynamic state regex based on required options
        const acceptableStatesRegex = ClientInteractionStateRegexHelper.getDynamicStateRegex({
            mainSelect: true,
            attack: true,
            abilityMainSelectPage: true,
            abilityDetailPage: true,
            abilityTargeting: true,
            bagItemDetail: true, // Using refined method names
            questItemDetail: true, // Using refined method names
            bagItemTargeting: true, // Using refined method names
            questItemTargeting: true, // Using refined method names
            equipmentItemDetail: true, // Including if checking for equipment details
            equipmentItemTargeting: true // Including if checking for equipment targeting
        });

        // Check if the interactionState matches one of the acceptable states
        if (!acceptableStatesRegex.test(this.conflictScene.interactionState)) {
            console.log('Interaction state does not match any acceptable states. Returning.');
            return;
        }

        console.log('Interaction state matches one of the acceptable states. Proceeding to emit attemptConflictInventoryMainSelect.');
        // Emit the 'attemptConflictInventoryMainSelect' event to the server
        this.conflictUIScene.inventoryMenu.emitMenuActivationRequest();
    }

    public handleFleeButton(): void {
        console.log('Flee button pressed');
        if (!this.conflictUIScene.canProceedWithMenuAction()) {
            console.log('Cannot proceed with menu action. Exiting early.');
            return;
        }
        console.log('interactionState: ', this.conflictScene.interactionState);

        // Utilize InteractionStateRegexHelper to get the dynamic state regex based on required options
        const acceptableStatesRegex = ClientInteractionStateRegexHelper.getDynamicStateRegex({
            mainSelect: true,
            attack: true,
            abilityMainSelectPage: true,
            abilityDetailPage: true,
            abilityTargeting: true,
            itemMainSelectBag: true,
            itemMainSelectQuest: true,
            itemMainSelectEquipment: true,
            bagItemDetail: true, // Using refined method names
            questItemDetail: true, // Using refined method names
            bagItemTargeting: true, // Using refined method names
            questItemTargeting: true, // Using refined method names
            equipmentItemDetail: true, // Including if checking for equipment details
            equipmentItemTargeting: true // Including if checking for equipment targeting
        });

        // Check if the interactionState matches one of the acceptable states
        if (!acceptableStatesRegex.test(this.conflictScene.interactionState)) {
            console.log('Interaction state does not match any acceptable states. Returning.');
            return;
        }

        this.conflictUIScene.waitingForServerResponse = true;
        // Emit the 'attemptConflictFlee' event to the server
        this.socket.emit(ServerSocketEvents.AttemptConflictFlee);
    }

    public handleAttackButton(): void {
        console.log('attack button pressed');
        console.log('interactionState: ', this.conflictScene.interactionState);

        // Utilize InteractionStateRegexHelper to get the dynamic state regex based on required options
        const dynamicStatesRegex = ClientInteractionStateRegexHelper.getDynamicStateRegex({
            mainSelect: true,
            abilityMainSelectPage: true,
            abilityDetailPage: true,
            abilityTargeting: true,
            itemMainSelectBag: true,
            itemMainSelectQuest: true,
            itemMainSelectEquipment: true,
            bagItemDetail: true, // Adjusted to use refined item detail handling
            questItemDetail: true, // Adjusted to use refined item detail handling
            bagItemTargeting: true, // Adjusted to use refined item targeting handling
            questItemTargeting: true, // Adjusted to use refined item targeting handling
            equipmentItemDetail: true, // Optionally include if equipment details are relevant here
            equipmentItemTargeting: true // Optionally include if equipment targeting is relevant here
        });

        // Check if the interactionState matches one of the dynamic states
        const isDynamicMatch = dynamicStatesRegex.test(this.conflictScene.interactionState);
        console.log('Dynamic interaction state match:', isDynamicMatch);

        if (!isDynamicMatch) {
            console.log('Interaction state does not match any of the dynamic states. Returning.');
            return;
        }

        console.log('Interaction state matches one of the dynamic states. Proceeding to emit attemptAttack.');
        // Emit the 'attemptAttack' event to the server
        this.conflictUIScene.attackMenu.emitMenuActivationRequest();
    }

    public handleCancelButton(): void {
        if (this.conflictScene.interactionState === BaseInteractionState.MainSelect) {
            return;
        }
        if (!this.conflictUIScene.canProceedWithMenuAction()) {
            console.log('Cannot proceed with menu action. Exiting early.');
            return;
        }
        this.conflictUIScene.waitingForServerResponse = true;
        // Logic to cancel the attack
        console.log('Cancel attack button pressed');
        this.socket.emit(ServerSocketEvents.AttemptConflictCancel);
    }

    public handleCancelPlayerArmedActionButton(): void {
        console.log('[handleCancelPlayerArmedActionButton] Cancel action button pressed. Emitting AttemptConflictCancelArmedAction event.');

        if (!this.conflictUIScene.canProceedWithMenuAction()) {
            console.log('Cannot proceed with menu action. Exiting early.');
            return;
        }
        this.conflictUIScene.waitingForServerResponse = true;
        this.socket.emit(ServerSocketEvents.AttemptConflictCancelArmedAction);

        console.log('[handleCancelPlayerArmedActionButton] AttemptConflictCancelArmedAction event emitted.');
    }

    public handleEnemyClick(targetIndex: number): void {
        if (!this.conflictUIScene.canProceedWithMenuAction()) {
            console.log('Cannot proceed with menu action. Exiting early.');
            return;
        }
        const {interactionState} = this.conflictScene;
        console.log(`Received enemy click event. Target Index: ${targetIndex}, Interaction State: ${interactionState}`);

        if (interactionState === BaseInteractionState.Attack) {
            console.log(`Attacking opponent at index ${targetIndex}, sending attack request to server`);

            this.conflictUIScene.waitingForServerResponse = true;
            const attackEvent: AttackConflictActionEvent = {
                action: ConflictActionType.Attack,
                targetIndex,
                targetType: TargetTypeEnum.Enemy
            };
            this.socket.emit(ServerSocketEvents.AttemptConflictAction, attackEvent);
            return;
        }

        // Return early if 'Enemy' is not in the interaction state
        if (!interactionState.includes('Enemy')) {
            console.log('Interaction state does not involve targeting an enemy');
            return;
        }

        const actionTypeMatch = interactionState.match(/(Ability|Item)(\d+)Targeting\w+(Page\d+|Bag|Quest|Equipment)/i);

        if (actionTypeMatch) {
            const actionType = actionTypeMatch[1];
            const actionIndex = parseInt(actionTypeMatch[2]);
            const additionalDetail = actionTypeMatch[3];

            console.log(`actionType: ${actionType}, actionIndex: ${actionIndex}, additionalDetail: ${additionalDetail}`);

            if (actionType.toLowerCase() === 'ability') {
                console.log(`Using ability at index ${actionIndex} on opponent at index ${targetIndex}, sending ability use request to server`);
                this.conflictUIScene.waitingForServerResponse = true;
                const abilityPageNumber = additionalDetail.startsWith('Page') ? parseInt(additionalDetail.replace('Page', '')) : undefined;

                const abilityEvent: AbilityConflictActionEvent = {
                    action: ConflictActionType.Ability,
                    abilityIndex: actionIndex,
                    targetIndex,
                    abilityPageNumber: abilityPageNumber as (1 | 2 | 3),
                    targetType: TargetTypeEnum.Enemy
                };
                console.log(`Sending ability event: ${JSON.stringify(abilityEvent, null, 2)}`);
                this.socket.emit(ServerSocketEvents.AttemptConflictAction, abilityEvent);
            } else if (actionType.toLowerCase() === 'item') {
                console.log(`Using item at index ${actionIndex} on opponent at index ${targetIndex}, sending item use request to server`);
                this.conflictUIScene.waitingForServerResponse = true;
                const itemEvent: BagOrQuestItemConflictActionEvent = {
                    action: ConflictActionType.Item,
                    itemIndex: actionIndex,
                    targetIndex,
                    itemCategory: additionalDetail as 'Bag' | 'Quest',
                    targetType: TargetTypeEnum.Enemy
                };
                console.log(`Sending item event: ${JSON.stringify(itemEvent, null, 2)}`);
                this.socket.emit(ServerSocketEvents.AttemptConflictAction, itemEvent);
            }
        } else {
            console.log('Failed to match actionTypeMatch or additional details');
        }
    }

    public handleInitializeConflictEngagement(interactionState: InteractionState): void {
        console.log(`[handleInitializeConflictEngagement] Entering method with interactionState: ${interactionState}`);
        this.conflictScene.interactionState = interactionState;

        if (interactionState === BaseInteractionState.MainSelect) {
            // Show the necessary UI element
            this.conflictScene.commandString = 'Command?';
            this.conflictUIScene.commandMenu.activate({
                topMessage: this.conflictScene.commandString,
                updateTopMessage: true,
                showTopMessage: true,
                bottomMessage: '',
                updateBottomMessage: true,
                showBottomMessage: false,
                iconLabel: '',
                updateIcon: false,
                showIcon: false,
                selectIcon: false
            });
            this.conflictUIScene.hotkeyMenu.activate();
        } else if (interactionState === BaseInteractionState.SkipTurn) {
            this.conflictUIScene.messageElement.activate({
                topMessage: 'Waiting for other combatants...',
                updateTopMessage: true,
                showTopMessage: true,
                middleMessage: '',
                updateMiddleMessage: false,
                showMiddleMessage: false,
                bottomMessage: '',
                updateBottomMessage: false,
                showBottomMessage: false
            });
        }

        // get the event data
        const allyData = this.conflictScene.eventData.allies;
        for (let i = 0; i < allyData.length; i++) {
            const ally = allyData[i];
            if (ally.ResourceType === ResourceType.Vim) {
                console.log(`Vim resource type detected at index ${i}`);
                const currentVimAmount = ally.CurrentResource;

                // Update the visibility of filled Vim icons for this player
                for (let j = 0; j < this.conflictUIScene.allyManager.filledVimIcons[i].length; j++) {
                    const filledIcon = this.conflictUIScene.allyManager.filledVimIcons[i][j];
                    filledIcon.setVisible(j < currentVimAmount);
                }
            }
        }

    }

    public handleAttemptAttackResponse(data: { success: boolean }): void {
        this.conflictUIScene.waitingForServerResponse = false;

        if (data.success) {

            // Updating the interaction state to reflect the attack phase
            this.conflictScene.interactionState = BaseInteractionState.Attack;

            // Initial UI state adjustments for successful attack
            this.conflictUIScene.primaryActionMenu.deselectAllPrimaryActionButtons();

            this.conflictUIScene.primaryActionMenu.attackButton.select();

            this.conflictUIScene.attackMenu.activate();

            this.conflictUIScene.enemyManager.showBadgesForLivingEnemies();
            this.conflictUIScene.enemyManager.isTargetingModeActive = true;

            this.conflictUIScene.allyManager.hideAllAllyBadges();
            this.conflictUIScene.allyManager.isTargetingModeActive = false;

        } else {
            // Log failure if the attack action did not succeed
            console.log('[ServerControlledConflictUISceneController.handleAttemptAttackResponse] Attack action failed');
        }

    }

    public handleAttemptCancelResponse(data: { success: boolean }): void {
        this.conflictUIScene.waitingForServerResponse = false;

        if (data.success) {

            this.conflictUIScene.abilityMenu.dismiss();
            this.conflictUIScene.attackMenu.dismiss();
            this.conflictUIScene.inventoryMenu.dismiss();

            // Show the main select frames

            if (this.conflictScene.mode === PlayerConflictMode.Normal) {
                this.conflictScene.commandString = 'Command?';
            } else if (this.conflictScene.mode === PlayerConflictMode.BindAction) {
                this.conflictScene.commandString = 'Select an action to bind to the hotkey menu bar.';
            }
            this.conflictUIScene.commandMenu.activate({
                topMessage: this.conflictScene.commandString,
                updateTopMessage: true,
                showTopMessage: true,
                bottomMessage: '',
                updateBottomMessage: true,
                showBottomMessage: false,
                iconLabel: '',
                updateIcon: false,
                showIcon: false,
                selectIcon: false
            });

            this.conflictUIScene.hotkeyMenu.activate();

            // Reset the action buttons
            this.conflictUIScene.primaryActionMenu.deselectAllPrimaryActionButtons();

            this.conflictScene.interactionState = BaseInteractionState.MainSelect;
        }
    }

    public handleAttemptConflictActionResponse(data: AttemptConflictActionResponseEvent): void {
        this.conflictUIScene.waitingForServerResponse = false;

        if (data.success) {
            console.log(`Received 'attemptConflictActionResponse' event. Success: ${data.success}, Action: ${data.actionType}, Actor Name: ${data.actorName}, Actor Index: ${data.actorIndex}, Target Name: ${data.targetName}`);
            if (!this.conflictScene.isSinglePlayerPVE) {
                let armedActionText = '';
                if (data.actionType === ConflictActionType.Attack) {
                    // For the 'attack' action, modify the displayed text
                    armedActionText = `${data.actorIndex + 1}. ${data.actorName} will attack ${data.targetName}`;
                } else if (data.actionType === ConflictActionType.Ability) {
                    armedActionText = `${data.actorIndex + 1}. ${data.actorName} will perform ${data.actionName} on ${data.targetName}`;
                } else if (data.actionType === ConflictActionType.Item) {
                    armedActionText = `${data.actorIndex + 1}. ${data.actorName} will use ${data.actionName} on ${data.targetName}`;
                } else if (data.actionType === ConflictActionType.Flee) {
                    armedActionText = `${data.actorIndex + 1}. ${data.actorName} will attempt to flee`;
                }

                this.conflictUIScene.allyManager.alliesArmedActionTexts[data.actorIndex].setText(armedActionText);
                this.conflictUIScene.allyManager.alliesArmedActionTexts[data.actorIndex].setVisible(true);

                this.conflictUIScene.allyManager.alliesArmedActionCancelButtons[data.actorIndex].setVisible(true);
            }

            this.conflictUIScene.messageElement.activate({
                topMessage: 'Waiting for other combatants...',
                updateTopMessage: true,
                showTopMessage: true,
                middleMessage: '',
                updateMiddleMessage: false,
                showMiddleMessage: false,
                bottomMessage: '',
                updateBottomMessage: false,
                showBottomMessage: false
            });

            console.log('server has successfully processed the request to act.');
            this.conflictScene.interactionState = BaseInteractionState.ActionSelected;
        } else if (!data.success && data.redirectToMainSelect) {
            this.conflictScene.interactionState = BaseInteractionState.MainSelect;
            this.conflictUIScene.hotkeyMenu.activate();
            this.conflictUIScene.primaryActionMenu.deselectAllPrimaryActionButtons();

            if (data.message) {
                this.conflictUIScene.commandMenu.activate({
                    topMessage: this.conflictScene.commandString,
                    updateTopMessage: true,
                    showTopMessage: true,
                    bottomMessage: '',
                    updateBottomMessage: true,
                    showBottomMessage: false,
                    iconLabel: '',
                    updateIcon: false,
                    showIcon: false,
                    selectIcon: false
                });

            } else {
                this.conflictScene.commandString = 'Command?';
                this.conflictUIScene.commandMenu.activate({
                    topMessage: this.conflictScene.commandString,
                    updateTopMessage: true,
                    showTopMessage: true,
                    bottomMessage: '',
                    updateBottomMessage: true,
                    showBottomMessage: false,
                    iconLabel: '',
                    updateIcon: false,
                    showIcon: false,
                    selectIcon: false
                });
            }
        } else {
            console.log('server has failed in processing the request to act');
        }
    }

    public returnToGameScene(playerDataArray: PlayerDataArray, facingInteractable: boolean): void {
        console.log(`[returnToGameScene] Returning to the game scene with playerDataArray: ${JSON.stringify(playerDataArray, null, 2)}`);
        console.log(`[returnToGameScene] Facing interactable: ${facingInteractable}`);
        this.conflictScene.dismiss();

        // this.gameScene.scene.launch(SceneNames.ServerControlledGameUI, playerDataArray);
        this.gameScene.serverControlledGameUIScene.playerDataArray = playerDataArray;
        this.gameScene.serverControlledGameUIScene.gameActionBar.handleUpdatePartyMembers(playerDataArray);
        this.gameScene.serverControlledGameUIScene.waitingForServerResponse = false;

        if (facingInteractable) {
            console.log('Player is facing an interactable, showing interaction menu');
            // Trigger UI elements to show interaction options
            // this.gameScene.serverControlledGameUIScene.interactionMenu.interactionMenuCanBeShown = true;
            // this.gameScene.serverControlledGameUIScene.interactionMenu.activate();
            this.gameScene.gameSocketEventHandler.updateInteractionMenuHandler(true);
        }

        if (playerDataArray[0].socketId === this.socket.id) {
            // Resume the game pad scene
            console.log('Resuming the game pad scene');

            console.log('ServerControlledGameUISceneController.returnToGameScene - starting GamePadScene if needed');
            showGamePadSceneIfNeeded(this.gameScene);
        }

        this.conflictScene.showGameActionBarFrame();
    }

    public handleNewRound(newRoundPayload: NewRoundPayload): void {
        this.conflictUIScene.waitingForServerResponse = false;

        // Add any other necessary code to set up the new round
        newRoundPayload.resourceUpdates.forEach(update => {
            if (update.resourceType === ResourceType.Vim) {
                // Access the filled Vim icons for the team member specified by update.memberIndex
                const filledVimIconsForMember = this.conflictUIScene.allyManager.filledVimIcons[update.memberIndex];

                // Update visibility based on currentResourceAmount
                filledVimIconsForMember.forEach((icon, index) => {
                    icon.setVisible(index < update.currentResourceAmount);
                });
            }
        });

        // Log the value of isSinglePlayerPVE before the evaluation
        console.log('Before Evaluation: isSinglePlayerPVE = ', this.conflictScene.isSinglePlayerPVE);

        if (!this.conflictScene.isSinglePlayerPVE) {
            // Your existing code for handling multiplayer or PVP goes here

            // Log the result of the evaluation (should always be true if this block is entered)
            console.log('After Evaluation: The condition is ', !this.conflictScene.isSinglePlayerPVE);

            this.conflictUIScene.allyManager.allyArmedActionsFrame.showFrame();
            this.conflictUIScene.roundTimerFrame.showFrame();
            this.conflictUIScene.roundTimerCountdownText.setText('-20-');
            this.conflictUIScene.roundTimerCountdownText.setVisible(true);
            this.conflictUIScene.roundTimerLabelText.setVisible(true);
        }

        this.conflictUIScene.primaryActionMenu.deselectAllPrimaryActionButtons();

        this.conflictUIScene.attackMenu.dismiss();

        this.conflictScene.commandString = 'Command?';

        this.conflictUIScene.commandMenu.activate({
            topMessage: this.conflictScene.commandString,
            updateTopMessage: true,
            showTopMessage: true,
            bottomMessage: '',
            updateBottomMessage: true,
            showBottomMessage: false,
            iconLabel: '',
            updateIcon: false,
            showIcon: false,
            selectIcon: false
        });

        this.conflictUIScene.hotkeyMenu.activate();

        this.conflictUIScene.messageElement.dismiss();

        // Update the interaction state on the server
        this.conflictScene.interactionState = BaseInteractionState.MainSelect;

        // Add the necessary code to update the UI for the new round
    }

    public handleGameOver(): void {
        // Implement your game over logic here
        console.log('Game Over');
        this.conflictScene.interactionState = BaseInteractionState.Processing;
        if (!this.conflictScene.isSinglePlayerPVE) {
            this.conflictUIScene.allyManager.allyArmedActionsFrame.hideFrame();

            for (const playerArmedActionText of this.conflictUIScene.allyManager.alliesArmedActionTexts) {
                playerArmedActionText.setText('');
                playerArmedActionText.setVisible(false);
            }

            for (const armedActionCancelButton of this.conflictUIScene.allyManager.alliesArmedActionCancelButtons) {
                armedActionCancelButton.setVisible(false);
            }
            this.conflictUIScene.roundTimerFrame.hideFrame();
            this.conflictUIScene.roundTimerCountdownText.setText('');
            this.conflictUIScene.roundTimerCountdownText.setVisible(false);
            this.conflictUIScene.roundTimerLabelText.setVisible(false);
        }

        this.conflictUIScene.commandMenu.dismiss();
        this.conflictUIScene.hotkeyMenu.dismiss();

        this.conflictUIScene.abilityMenu.dismiss();

        this.conflictUIScene.attackMenu.dismiss();

        this.conflictUIScene.messageElement.activate({
            topMessage: 'Thou art defeated.',
            updateTopMessage: true,
            showTopMessage: true,
            middleMessage: '',
            updateMiddleMessage: false,
            showMiddleMessage: false,
            bottomMessage: '',
            updateBottomMessage: false,
            showBottomMessage: false
        });

        const fadeDuration = 2000; // adjust to the desired fade out duration
        const fadeInterval = 16; // approx 60fps
        const fadeSteps = fadeDuration / fadeInterval;

        const mainConflictCamera = this.conflictScene.cameras.main;
        const mainConflictUICamera = this.conflictUIScene.cameras.main;

        // Create a black rectangle that covers the whole game in both scenes
        this.conflictScene.conflictGameOverBlackRectangle = this.conflictScene.add.rectangle(mainConflictCamera.width / 2, mainConflictCamera.height / 2, mainConflictCamera.width, mainConflictCamera.height, 0x000000);
        this.conflictUIScene.conflictUIGameOverBlackRectangle = this.conflictUIScene.add.rectangle(mainConflictUICamera.width / 2, mainConflictUICamera.height / 2, mainConflictUICamera.width, mainConflictUICamera.height, 0x000000);

        this.conflictScene.conflictGameOverBlackRectangle.setAlpha(0); // Start fully transparent
        this.conflictUIScene.conflictUIGameOverBlackRectangle.setAlpha(0); // Start fully transparent

        this.conflictScene.conflictGameOverBlackRectangle.setDepth(DepthLevel.FULL_COVER); // Set a high depth to ensure the rectangle is on top
        this.conflictUIScene.conflictUIGameOverBlackRectangle.setDepth(DepthLevel.FULL_COVER); // Set a high depth to ensure the rectangle is on top

        // Set up the fade effect using setInterval
        let step = 0;
        const fadeIntervalId = setInterval(() => {
            // Calculate the new alpha value for this step
            const newAlpha = step / fadeSteps;

            // Increase the alpha for the fade rectangle
            this.conflictScene.conflictGameOverBlackRectangle.setAlpha(newAlpha);
            this.conflictUIScene.conflictUIGameOverBlackRectangle.setAlpha(newAlpha);

            step++;
            if (step >= fadeSteps) {
                // Stop the interval after reaching the total steps
                clearInterval(fadeIntervalId);
            }
        }, fadeInterval);
    }

    public handleRespawn(playerDataArray: PlayerDataArray): void {

        // Stop the conflict scene
        console.log('dismissing the conflict scene');

        this.conflictScene.dismiss();

        // this.gameScene.scene.launch(SceneNames.ServerControlledGameUI, playerDataArray);
        this.gameScene.serverControlledGameUIScene.playerDataArray = playerDataArray;
        this.gameScene.serverControlledGameUIScene.gameActionBar.handleUpdatePartyMembers(playerDataArray);
        this.gameScene.serverControlledGameUIScene.waitingForServerResponse = false;

        if (playerDataArray[0].socketId === this.socket.id) {
            // Resume the game pad scene
            console.log('resuming the game pad scene');

            console.log('ServerControlledGameUISceneController.handleRespawn - starting GamePadScene if needed');
            showGamePadSceneIfNeeded(this.gameScene);
        }

        // this.conflictScene.removeListeners();
        this.conflictScene.showGameActionBarFrame();
    }

    public handleVictory(): void {
        // Similar to the battle controller
        // Add the necessary code here
        console.log('Victory!');
        this.conflictScene.interactionState = BaseInteractionState.Processing;
        if (!this.conflictScene.isSinglePlayerPVE) {
            this.conflictUIScene.allyManager.allyArmedActionsFrame.hideFrame();

            for (const playerArmedActionText of this.conflictUIScene.allyManager.alliesArmedActionTexts) {
                playerArmedActionText.setText('');
                playerArmedActionText.setVisible(false);
            }

            for (const armedActionCancelButton of this.conflictUIScene.allyManager.alliesArmedActionCancelButtons) {
                armedActionCancelButton.setVisible(false);
            }
            this.conflictUIScene.roundTimerFrame.hideFrame();
            this.conflictUIScene.roundTimerCountdownText.setText('');
            this.conflictUIScene.roundTimerCountdownText.setVisible(false);
            this.conflictUIScene.roundTimerLabelText.setVisible(false);
        }

        this.conflictUIScene.commandMenu.dismiss();
        this.conflictUIScene.attackMenu.dismiss();
        this.conflictUIScene.hotkeyMenu.dismiss();
        this.conflictUIScene.abilityMenu.dismiss();

        this.conflictUIScene.messageElement.activate({
            topMessage: 'Thine enemies are defeated.',
            updateTopMessage: true,
            showTopMessage: true,
            middleMessage: '',
            updateMiddleMessage: false,
            showMiddleMessage: false,
            bottomMessage: '',
            updateBottomMessage: false,
            showBottomMessage: false
        });
    }

    public handleMainTurnAction(data: ConflictTurnActionData): void {
        console.log('Received a turn action from the server at:');
        const now = new Date();
        const timeString = now.toLocaleTimeString('en-US', {hour12: false, hour: 'numeric', minute: 'numeric', second: 'numeric'});
        const milliseconds = now.getMilliseconds();
        console.log(`${timeString}.${milliseconds.toString().padStart(3, '0')}`);

        console.log({data});
        this.conflictScene.interactionState = BaseInteractionState.Processing;

        if (!this.conflictScene.isSinglePlayerPVE) {
            this.conflictUIScene.allyManager.allyArmedActionsFrame.hideFrame();

            for (const playerArmedActionText of this.conflictUIScene.allyManager.alliesArmedActionTexts) {
                playerArmedActionText.setText('');
                playerArmedActionText.setVisible(false);
            }

            for (const armedActionCancelButton of this.conflictUIScene.allyManager.alliesArmedActionCancelButtons) {
                armedActionCancelButton.setVisible(false);
            }
            this.conflictUIScene.roundTimerFrame.hideFrame();
            this.conflictUIScene.roundTimerCountdownText.setText('');
            this.conflictUIScene.roundTimerCountdownText.setVisible(false);
            this.conflictUIScene.roundTimerLabelText.setVisible(false);
        }

        console.log('[handleMainTurnAction] [handleMainTurnAction] Method start');

        // Log before the if condition
        console.log('[handleMainTurnAction] [Before if] Checking abilityIdentifier presence');
        console.log(`[handleMainTurnAction] [Before if] data.abilityIdentifier: ${data.actionSpecifics.identifier}`);

        if (data.actionSpecifics.identifier) {
            console.log('[handleMainTurnAction] [Inside if] abilityIdentifier found, processing...');

            let actionTemplate = '';

            if (data.evaded) {
                actionTemplate = data.dodgeActionTextTemplate;
                console.log(`[handleMainTurnAction] [Action evaded] Using dodgeActionTextTemplate: ${actionTemplate}`);
            } else {
                actionTemplate = data.useActionTextTemplate;
                console.log(`[handleMainTurnAction] [Action not evaded] Using useActionTextTemplate: ${actionTemplate}`);
            }

            // Formatting hpDelta with BBCode
            let hpDeltaFormatted;
            if (data.target.hpDelta < 0) {
                hpDeltaFormatted = `[color=#ff6666]${Math.abs(data.target.hpDelta)}[/color]`; // Lighter Red
                console.log(`[handleMainTurnAction] [hpDeltaFormatted] Damage: ${hpDeltaFormatted}`);
            } else if (data.target.hpDelta > 0) {
                hpDeltaFormatted = `[color=#66ff66]${Math.abs(data.target.hpDelta)}[/color]`; // Lighter Green
                console.log(`[handleMainTurnAction] [hpDeltaFormatted] Heal: ${hpDeltaFormatted}`);
            } else {
                hpDeltaFormatted = `${Math.abs(data.target.hpDelta)}`; // Default formatting (no color)
                console.log(`[handleMainTurnAction] [hpDeltaFormatted] No change: ${hpDeltaFormatted}`);
            }

            let resourceDeltaFormatted;
            if (data.target.resourceDelta > 0) {
                resourceDeltaFormatted = `[color=#66ff66]${Math.abs(data.target.resourceDelta)}[/color]`; // Lighter Green
                console.log(`[handleMainTurnAction] [resourceDeltaFormatted] Resource gain: ${resourceDeltaFormatted}`);
            } else if (data.target.resourceDelta < 0) {
                resourceDeltaFormatted = `[color=#ff6666]${Math.abs(data.target.resourceDelta)}[/color]`; // Lighter Red
                console.log(`[handleMainTurnAction] [resourceDeltaFormatted] Resource loss: ${resourceDeltaFormatted}`);
            } else {
                resourceDeltaFormatted = `${Math.abs(data.target.resourceDelta)}`; // Default formatting (no color)
                console.log(`[handleMainTurnAction] [resourceDeltaFormatted] No change: ${resourceDeltaFormatted}`);
            }

            const actionText = actionTemplate
                .replace(/\$\{actionTarget}/g, data.target.name)
                .replace(/\$\{hpDelta}/g, hpDeltaFormatted)
                .replace(/\$\{resourceDelta}/g, resourceDeltaFormatted);
            console.log(`[handleMainTurnAction] [Generated actionText] ${actionText}`);

            this.conflictUIScene.messageElement.activate({
                topMessage: '',
                updateTopMessage: false,
                showTopMessage: true,
                middleMessage: actionText,
                updateMiddleMessage: true,
                showMiddleMessage: true,
                bottomMessage: '',
                updateBottomMessage: false,
                showBottomMessage: false
            });

            // Gather all sprites related to the target
            const targetComponents = data.targetIsAlly
                ? this.conflictUIScene.allyManager.allyComponents[data.target.index]
                : this.conflictUIScene.enemyManager.enemyComponents[data.target.index];
            const targetSprites = [targetComponents.baseSprite];
            if (targetComponents.maleHairSprite) {
                targetSprites.push(targetComponents.maleHairSprite);
            }
            if (targetComponents.femaleHairSprite) {
                targetSprites.push(targetComponents.femaleHairSprite);
            }
            if (targetComponents.primaryClothingSprite) {
                targetSprites.push(targetComponents.primaryClothingSprite);
            }
            if (targetComponents.secondaryClothingSprite) {
                targetSprites.push(targetComponents.secondaryClothingSprite);
            }
            if (targetComponents.tertiaryClothingSprite) {
                targetSprites.push(targetComponents.tertiaryClothingSprite);
            }
            if (targetComponents.runebladeBottomWeaponSprite) {
                targetSprites.push(targetComponents.runebladeBottomWeaponSprite);
            }
            if (targetComponents.aethermancerBottomWeaponSprite) {
                targetSprites.push(targetComponents.aethermancerBottomWeaponSprite);
            }
            if (targetComponents.lifeweaverBottomWeaponSprite) {
                targetSprites.push(targetComponents.lifeweaverBottomWeaponSprite);
            }
            if (targetComponents.runebladeTopWeaponSprite) {
                targetSprites.push(targetComponents.runebladeTopWeaponSprite);
            }
            if (targetComponents.aethermancerTopWeaponSprite) {
                targetSprites.push(targetComponents.aethermancerTopWeaponSprite);
            }
            if (targetComponents.lifeweaverTopWeaponSprite) {
                targetSprites.push(targetComponents.lifeweaverTopWeaponSprite);
            }

            // If the target is alive, add blink effect. Otherwise, hide the sprites and button.
            if (!data.evaded && data.target.hpDelta < 0) {
                this.addBlinkEffect(targetSprites);
            }

            // Update the HP text if the target is an ally and took damage.
            if (data.targetIsAlly && data.target.hpDelta !== 0) {
                const hpText = this.conflictUIScene.allyManager.allyHPTexts[data.target.index];
                const currentText = hpText.text;
                const maxHP = currentText.split('/')[1];
                hpText.setText(`HP: ${data.target.currentHP}/${maxHP}`);
            }

            if (data.targetIsAlly && data.target.resourceDelta !== 0 && data.target.resourceType === ResourceType.MP) {
                const resourceText = this.conflictUIScene.allyManager.allyResourceTexts[data.target.index];
                const currentText = resourceText.text;
                const maxResource = currentText.split('/')[1];
                resourceText.setText(`MP: ${data.target.currentResource}/${maxResource}`);

            }

            if (data.actorIsAlly && data.actor.resourceDelta !== 0) {
                if (data.actor.resourceType === ResourceType.MP) {
                    const mpText = this.conflictUIScene.allyManager.allyResourceTexts[data.actor.index];
                    const currentText = mpText.text;
                    const maxMP = currentText.split('/')[1];
                    mpText.setText(`MP: ${data.actor.currentResource}/${maxMP}`);
                } else if (data.actor.resourceType === ResourceType.Vim) {
                    const currentVimAmount = data.actor.currentResource;
                    for (let i = 0; i < this.conflictUIScene.allyManager.filledVimIcons[data.actor.index].length; i++) {
                        const filledIcon = this.conflictUIScene.allyManager.filledVimIcons[data.actor.index][i];
                        filledIcon.setVisible(i < currentVimAmount);
                    }
                }
            }
        }
    }

    public handleAttemptCancelArmedActionResponse(data: { success: boolean, actorIndex: number }): void {
        console.log('--- Start of handleAttemptCancelArmedActionResponse Logging ---');
        this.conflictUIScene.waitingForServerResponse = false;

        console.log(`Received data: success = ${data.success}, actorIndex = ${data.actorIndex}`);
        console.log(`Current isSinglePlayerPVE: ${this.conflictScene.isSinglePlayerPVE}`);

        if (data.success) {
            console.log('Entering "success" branch...');

            if (!this.conflictScene.isSinglePlayerPVE) {
                console.log('Not a single-player PVE game, proceeding with UI updates...');

                this.conflictUIScene.allyManager.alliesArmedActionTexts[data.actorIndex].setText('');
                this.conflictUIScene.allyManager.alliesArmedActionTexts[data.actorIndex].setVisible(false);
                this.conflictUIScene.allyManager.alliesArmedActionCancelButtons[data.actorIndex].setVisible(false);
                console.log('Cleared and hid player action text and cancel button.');
            }
            this.conflictUIScene.primaryActionMenu.deselectAllPrimaryActionButtons();
            console.log('Deselected the attack button.');

            this.conflictScene.commandString = 'Command?';
            this.conflictUIScene.commandMenu.activate({
                topMessage: this.conflictScene.commandString,
                updateTopMessage: true,
                showTopMessage: true,
                bottomMessage: '',
                updateBottomMessage: true,
                showBottomMessage: false,
                iconLabel: '',
                updateIcon: false,
                showIcon: false,
                selectIcon: false
            });

            console.log('Displayed the command menu frame and text.');

            this.conflictUIScene.hotkeyMenu.activate();
            console.log('Displayed the hotkey menu frame.');

            this.conflictScene.interactionState = BaseInteractionState.MainSelect;
            console.log('Set interaction state to MainSelect.');

        } else {
            console.log('Entering "failure" branch...');
            console.log('Attempt to cancel selected action has failed.');
        }

        console.log('--- End of handleAttemptCancelArmedActionResponse Logging ---');
    }

    public handleRoundTimer(remainingTime: number): void {
        this.conflictUIScene.roundTimerCountdownText.setText(`-${remainingTime}-`);
    }

    public handleAllyActionUpdate(data: AttemptConflictActionResponseEvent): void {
        console.log('handleAllyActionUpdate fired. Input Data:', JSON.stringify(data, null, 2));

        if (data.success) {

            if (!this.conflictScene.isSinglePlayerPVE) {

                let armedActionText = '';
                if (data.actionType === ConflictActionType.Attack) {
                    // Handle attack action
                    armedActionText = `${data.actorIndex + 1}. ${data.actorName} will attack ${data.targetName}`;
                    console.log(`Selected action is 'attack'. Selected Action Text: ${armedActionText}`);
                } else if (data.actionType === ConflictActionType.Ability) {
                    // Handle other actions
                    armedActionText = `${data.actorIndex + 1}. ${data.actorName} will perform ${data.actionName} on ${data.targetName}`;
                    console.log(`Selected action is '${data.actionType}'. Selected Action Text: ${armedActionText}`);
                } else if (data.actionType === ConflictActionType.Item) {
                    // Handle item action
                    armedActionText = `${data.actorIndex + 1}. ${data.actorName} will use ${data.actionName} on ${data.targetName}`;
                    console.log(`Selected action is 'item'. Selected Action Text: ${armedActionText}`);
                } else if (data.actionType === ConflictActionType.Flee) {
                    // Handle flee action
                    armedActionText = `${data.actorIndex + 1}. ${data.actorName} will attempt to flee`;
                    console.log(`Selected action is 'flee'. Selected Action Text: ${armedActionText}`);
                }

                this.conflictUIScene.allyManager.alliesArmedActionTexts[data.actorIndex].setText(armedActionText);
                this.conflictUIScene.allyManager.alliesArmedActionTexts[data.actorIndex].setVisible(true);

                console.log(`Updating player selected action text for player ${data.actorName} (Index ${data.actorIndex}). New text is: ${armedActionText}`);
                console.log(`Ally ${data.actorIndex + 1} (${data.actorName}) has successfully chosen to ${data.actionType} ${data.targetName}`);
            }

        } else {
            console.log('Server has failed in processing an ally\'s request to act. Input Data: ', JSON.stringify(data, null, 2));
        }
    }

    public handleAllyActionCancelUpdate(data: { actorIndex: number }): void {
        console.log(`Received 'allyActionCancelUpdate' event. Actor Index: ${data.actorIndex}`);
        if (!this.conflictScene.isSinglePlayerPVE) {

            this.conflictUIScene.allyManager.alliesArmedActionTexts[data.actorIndex].setText('');
            this.conflictUIScene.allyManager.alliesArmedActionTexts[data.actorIndex].setVisible(false);
            this.conflictUIScene.allyManager.alliesArmedActionCancelButtons[data.actorIndex].setVisible(false);

            console.log(`Ally ${data.actorIndex + 1} has cancelled their action`);
        }

    }

    public handleNewRoundSkipTurn(newRoundPayload: NewRoundPayload): void {
        this.conflictUIScene.waitingForServerResponse = false;

        newRoundPayload.resourceUpdates.forEach(update => {
            if (update.resourceType === ResourceType.Vim) {
                // Access the filled Vim icons for the team member specified by update.memberIndex
                const filledVimIconsForMember = this.conflictUIScene.allyManager.filledVimIcons[update.memberIndex];

                // Update visibility based on currentResourceAmount
                filledVimIconsForMember.forEach((icon, index) => {
                    icon.setVisible(index < update.currentResourceAmount);
                });
            }
        });

        // Upconflict the interaction state on the server
        this.conflictScene.interactionState = BaseInteractionState.SkipTurn;

        if (!this.conflictScene.isSinglePlayerPVE) {
            this.conflictUIScene.allyManager.allyArmedActionsFrame.showFrame();
            this.conflictUIScene.roundTimerFrame.showFrame();
            this.conflictUIScene.roundTimerCountdownText.setText('-20-');
            this.conflictUIScene.roundTimerCountdownText.setVisible(true);
            this.conflictUIScene.roundTimerLabelText.setVisible(true);
        }

        this.conflictUIScene.primaryActionMenu.deselectAllPrimaryActionButtons();

        this.conflictUIScene.messageElement.activate({
            topMessage: 'Waiting for other combatants...',
            updateTopMessage: true,
            showTopMessage: true,
            middleMessage: '',
            updateMiddleMessage: false,
            showMiddleMessage: false,
            bottomMessage: '',
            updateBottomMessage: false,
            showBottomMessage: false
        });
    }

    public handleAllyCancelAction(data: CancelActionEvent): void {
        console.log(`Received 'allyCancelAction' event. Success: ${data.success}, Player Index: ${data.actorIndex}`);

        if (data.success) {
            if (!this.conflictScene.isSinglePlayerPVE) {
                // Remove the cancelled action's text and hide the cancel button for the corresponding player
                this.conflictUIScene.allyManager.alliesArmedActionTexts[data.actorIndex].setText('');
                this.conflictUIScene.allyManager.alliesArmedActionTexts[data.actorIndex].setVisible(false);
                this.conflictUIScene.allyManager.alliesArmedActionCancelButtons[data.actorIndex].setVisible(false);

                console.log('An ally has cancelled their selected action');
            }
        } else {
            console.log('Received an unsuccessful "allyCancelAction" event');
        }
    }

    public handleConflictVictoryGoldReward(data: { gold: number }): void {
        this.conflictUIScene.messageElement.activate({
            topMessage: `${data.gold} gold pieces acquired.`,
            updateTopMessage: true,
            showTopMessage: true,
            middleMessage: '',
            updateMiddleMessage: false,
            showMiddleMessage: false,
            bottomMessage: '',
            updateBottomMessage: false,
            showBottomMessage: false
        });
    }

    public handlePvPGoldTaken(data: { goldTaken: number }): void {
        this.conflictUIScene.messageElement.activate({
            topMessage: `${data.goldTaken} gold pieces taken.`,
            updateTopMessage: true,
            showTopMessage: true,
            middleMessage: '',
            updateMiddleMessage: false,
            showMiddleMessage: false,
            bottomMessage: '',
            updateBottomMessage: false,
            showBottomMessage: false
        });
    }

    public handleConflictVictoryExperienceReward(data: { experience: number }): void {
        this.conflictUIScene.messageElement.activate({
            topMessage: '',
            updateTopMessage: false,
            showTopMessage: true,
            middleMessage: `${data.experience} experience points attained.`,
            updateMiddleMessage: true,
            showMiddleMessage: true,
            bottomMessage: '',
            updateBottomMessage: false,
            showBottomMessage: false
        });
    }

    public handleLevelUP(data: LevelUpEvent): void {
        console.log(`Received LevelUp data: ${JSON.stringify(data, null, 2)}`); // Log the received data
        this.conflictUIScene.messageElement.activate({
            topMessage: `${data.name} has reached level ${data.newLevel}!`,
            updateTopMessage: true,
            showTopMessage: true,
            middleMessage: '',
            updateMiddleMessage: false,
            showMiddleMessage: false,
            bottomMessage: '',
            updateBottomMessage: false,
            showBottomMessage: false
        });

        this.conflictUIScene.allyManager.allyHPTexts[data.partyMemberIndex].setText(`HP: ${data.currentHP}/${data.newMaxHP}`);
        if (data.resourceType === ResourceType.MP) {
            this.conflictUIScene.allyManager.allyResourceTexts[data.partyMemberIndex].setText(`MP: ${data.currentResource}/${data.newMaxResource}`);
        }
    }

    public handleAllyClick(index: number): void {
        console.log(`Ally index ${index} clicked`);
        if (!this.conflictUIScene.canProceedWithMenuAction()) {
            console.log('Cannot proceed with menu action. Exiting early.');
            return;
        }
        console.log(`Interaction state: ${this.conflictScene.interactionState}`);

        if (!this.conflictScene.allyTypes || index >= this.conflictScene.allyTypes.length) {
            console.log('Error: allyTypes array not initialized or index out of bounds');
            return;
        }

        const allyType = this.conflictScene.allyTypes[index];
        console.log(`The clicked ally is of type: ${allyType}`);

        const {interactionState} = this.conflictScene;

        if (!((allyType === 'self' && interactionState.includes('Self')) ||
            (allyType === 'ally' && interactionState.includes('Ally')))) {
            console.log(`Invalid interaction state for interacting with ${allyType}`);
            return;
        }

        const actionIndexOrSlotMatch = interactionState.match(/(Ability|Item)(\d+|weapon|bodyarmor|offhand|helmet)/i);

        if (!actionIndexOrSlotMatch) {
            console.log('[handleAllyClick] Failed to match action index in interaction state');
            return;
        } else {
            console.log('[handleAllyClick] Match found:', actionIndexOrSlotMatch);
        }

        const actionType = actionIndexOrSlotMatch[1];
        let actionIndexOrSlot;

        // Try to convert the matched string to a number
        const parsedIndex = parseInt(actionIndexOrSlotMatch[2], 10);

        // Check if the parsedIndex is a number or NaN
        if (isNaN(parsedIndex)) {
            console.log('[handleAllyClick] Matched string is not a number, using as string:', actionIndexOrSlotMatch[2]);
            actionIndexOrSlot = actionIndexOrSlotMatch[2];
        } else {
            console.log('[handleAllyClick] Matched string is a number, parsed value:', parsedIndex);
            actionIndexOrSlot = parsedIndex;
        }

        console.log('[handleAllyClick] Final actionType:', actionType);
        console.log('[handleAllyClick] Final actionIndexOrSlot:', actionIndexOrSlot);

        const targetType = allyType === 'self' ? TargetTypeEnum.Self : TargetTypeEnum.Ally;

        let abilityPageNumber: AbilityConflictActionEvent['abilityPageNumber'] | null = null;
        // let itemCategory: BagOrQuestItemConflictActionEvent['itemCategory'] | null = null;
        let itemCategory: ItemCategory | null = null;

        if (actionType === 'Ability') {
            const abilityDetailMatch = interactionState.match(/Ability\d+Targeting(?:Enemy|Ally|Self)+Page(\d+)/i);
            if (abilityDetailMatch) {
                const pageNumber = parseInt(abilityDetailMatch[1], 10);
                if (pageNumber >= 1 && pageNumber <= 3) {
                    abilityPageNumber = pageNumber as AbilityConflictActionEvent['abilityPageNumber'];
                }
            }
        } else { // 'Item'
            console.log('Processing as Item...');
            const regexForItemDetails = /Item(\d+|weapon|bodyarmor|offhand|helmet)Targeting(?:Enemy|Ally|Self)+(Bag|Quest|Equipment)/i;
            console.log(`Using regex for item details: ${regexForItemDetails}`);

            const itemDetailMatch = interactionState.match(regexForItemDetails);
            console.log(`Regex match result: ${itemDetailMatch}`);

            if (itemDetailMatch) {
                console.log('Item detail match found, evaluating categories...');
                const validCategories = ['Bag', 'Quest', 'Equipment'];
                console.log(`Valid item categories: ${validCategories.join(', ')}`);

                if (validCategories.includes(itemDetailMatch[2])) { // Update index to [2] because [1] might be a type now
                    itemCategory = itemDetailMatch[2] as ItemCategory;
                    console.log(`Item category set to: ${itemCategory}`);
                } else {
                    console.log('Matched item category is not valid.');
                }
            } else {
                console.log('No match found for item details in interaction state.');
            }
        }

        if (actionType === 'Ability' && abilityPageNumber === null) {
            console.log('Failed to extract ability page number from interaction state');
            console.log(`actionType: ${actionType}, abilityPageNumber: ${abilityPageNumber}`);
            return;
        } else if (actionType === 'Item' && itemCategory === null) {
            console.log('Failed to extract item category from interaction state');
            console.log(`actionType: ${actionType}, itemCategory: ${itemCategory}`);
            return;
        }

        console.log(`Using ${actionType.toLowerCase()} at index ${actionIndexOrSlot} on ${allyType} at index ${index}, sending request to server`);

        // Construct the event object with extracted details
        let event: AttemptConflictActionEvent;
        if (actionType === 'Ability' && abilityPageNumber !== null) {
            event = {
                action: ConflictActionType.Ability,
                abilityIndex: actionIndexOrSlot as number,
                targetIndex: index,
                targetType: targetType,
                abilityPageNumber: abilityPageNumber,
            };
        } else if (actionType === 'Item' && itemCategory !== null) {
            if (itemCategory === 'Bag' || itemCategory === 'Quest') {
                event = {
                    action: ConflictActionType.Item,
                    itemIndex: actionIndexOrSlot as number,
                    targetIndex: index,
                    targetType: targetType,
                    itemCategory: itemCategory,
                };
            } else if (itemCategory === 'Equipment') {
                event = {
                    action: ConflictActionType.Item,
                    slotType: actionIndexOrSlot as Exclude<ItemType, ItemType.Consumable>,
                    targetIndex: index,
                    targetType: targetType,
                    itemCategory: itemCategory,
                };
            }
        } else {
            console.error('Unhandled action type or detail extraction error');
            return;
        }

        this.conflictUIScene.waitingForServerResponse = true;

        console.log(`Sending event: ${JSON.stringify(event!, null, 2)}`);
        this.socket.emit(ServerSocketEvents.AttemptConflictAction, event!);
    }

    public handleAttemptConflictAbilityMainSelectResponse(data: AttemptConflictAbilityMainSelectResponseEvent): void {
        // Log incoming data for debugging
        this.conflictUIScene.waitingForServerResponse = false;

        console.log('Received handleAttemptConflictAbilityMainSelectResponse:', JSON.stringify(data, null, 2));

        if (data.success) {
            console.log('Ability selection succeeded, updating UI and interaction states.');

            // Log specific UI updates
            console.log('Deselecting all primary action buttons.');
            this.conflictUIScene.primaryActionMenu.deselectAllPrimaryActionButtons();

            console.log('Selecting ability button.');
            this.conflictUIScene.primaryActionMenu.abilityButton.select();

            console.log('Activating ability menu with received skills:', data.skills);
            this.conflictUIScene.abilityMenu.activate(data.skills);

            // Log interaction state updates
            this.conflictScene.interactionState = 'AbilityMainSelectPage1';
            console.log(`Interaction state set to ${this.conflictScene.interactionState}`);

            if (this.conflictScene.mode === PlayerConflictMode.BindHotkey) {
                console.log('Current mode is BindHotkey, switching to BindAction.');
                this.conflictScene.mode = PlayerConflictMode.BindAction;
                this.conflictUIScene.hotkeyMenu.toggleHotkeyBindMode(false);
            }
            console.log(`Interaction state set to ${this.conflictScene.interactionState}`);

        } else {
        // Log the failure scenario including any error message or feedback needed
            console.log('Ability selection failed. Reason: data.success was not truthy.');
        }
    }

    public handleAttemptConflictAbilitySelectionResponse(data: AttemptConflictAbilitySelectionResponseEvent): void {
        console.log('Received attemptConflictAbilitySelectionResponse event');
        this.conflictUIScene.waitingForServerResponse = false;

        console.log(JSON.stringify(data, null, 2));
        if (data.success) {
            // Update the interaction state first before populating detail view
            console.log(`Setting interaction state to 'Ability${data.abilityIndex}DetailPage${data.abilityPageNumber}'`);
            this.conflictScene.interactionState = `Ability${data.abilityIndex}DetailPage${data.abilityPageNumber}` as InteractionState;

            // Now call populateDetailView with the necessary data
            this.conflictUIScene.abilityMenu.populateDetailView({
                abilityIndex: data.abilityIndex,
                abilityName: data.abilityName,
                abilityDescription: data.abilityDescription,
                abilityResourceCost: data.abilityResourceCost,
                abilityResourceType: data.abilityResourceType,
            });
            this.conflictUIScene.abilityMenu.showDetailView();

        } else {
            console.log('Ability selection failed');
            // Implement your failure logic here
        }
    }

    public handleAttemptConflictInventoryItemSelectionResponse(data: AttemptConflictInventoryItemSelectionResponseEvent): void {
        console.log(`[ServerControlledConflictUISceneController.handleAttemptConflictInventoryItemSelectionResponse] Received data: ${JSON.stringify(data, null, 2)}`);
        this.conflictUIScene.waitingForServerResponse = false;

        if (data.success) {
            console.log(`[ServerControlledConflictUISceneController.handleAttemptConflictInventoryItemSelectionResponse] Success: ${data.success}`);

            this.conflictScene.interactionState = `Item${data.inventoryItemIndex}Detail${data.inventoryItemCategory}` as InteractionState;

            console.log(`[ServerControlledConflictUISceneController.handleAttemptConflictInventoryItemSelectionResponse] Interaction state set to ${this.conflictScene.interactionState}`);

            this.conflictUIScene.inventoryMenu.populateDetailView({
                inventoryItemIndex: data.inventoryItemIndex,
                inventoryItemName: data.inventoryItemName,
                inventoryItemDescription: data.inventoryItemDescription,
                inventoryItemCategory: data.inventoryItemCategory,
                inventoryItemType: data.inventoryItemType,
                inventoryItemClasses: data.inventoryItemClasses,
                inventoryItemMinimumLevel: data.inventoryItemMinimumLevel,
                inventoryItemStats: data.inventoryItemStats,
                inventoryItemBindingType: data.inventoryItemBindingType,
            });
            this.conflictUIScene.inventoryMenu.showDetailView();

        } else {
            console.log(`[ServerControlledConflictUISceneController.handleAttemptConflictInventoryItemSelectionResponse] Failure: ${data.success}`);
        }
    }

    public handleAttemptConflictAbilitySelectionConfirmResponse(data: AttemptConflictAbilitySelectionConfirmResponseEvent): void {
        console.log('[handleAttemptConflictAbilitySelectionConfirmResponse] Method entry');
        this.conflictUIScene.waitingForServerResponse = false;

        if ('success' in data && data.success) {
            console.log('Received attemptConflictAbilitySelectionConfirmResponse event');
            console.log(JSON.stringify(data, null, 2));
            // Determine the correct targeting state
            let targetingStateSuffix: string;
            if (Array.isArray(data.abilityValidTargets)) {
                targetingStateSuffix = data.abilityValidTargets.join('');
            } else {
                targetingStateSuffix = data.abilityValidTargets;
            }

            const targetingState = `Ability${data.abilityIndex}Targeting${targetingStateSuffix}Page${data.abilityPageNumber}` as InteractionState;
            // Log the interaction state before updating
            console.log(`[handleAttemptConflictAbilitySelectionConfirmResponse] Updating interaction state to: ${targetingState}`);
            this.conflictScene.interactionState = targetingState;

            this.conflictUIScene.commandMenu.dismiss();
            this.conflictUIScene.hotkeyMenu.dismiss();

            this.conflictUIScene.primaryActionMenu.abilityButton.select();

            this.conflictUIScene.abilityMenu.populateArmedView(data);
            this.conflictUIScene.abilityMenu.showArmedView();

            if (
                data.abilityValidTargets.includes(TargetTypeEnum.Enemy) ||
                data.abilityValidTargets === TargetTypeEnum.Enemy
            ) {
                this.conflictUIScene.enemyManager.showBadgesForLivingEnemies();
                this.conflictUIScene.enemyManager.isTargetingModeActive = true;

                this.conflictUIScene.allyManager.hideAllAllyBadges();
                this.conflictUIScene.allyManager.isTargetingModeActive = false;
            } else if (
                data.abilityValidTargets.includes(TargetTypeEnum.Ally) ||
                data.abilityValidTargets === TargetTypeEnum.Ally
            ) {
                this.conflictUIScene.allyManager.showBadgesForAllAllies();
                this.conflictUIScene.allyManager.isTargetingModeActive = true;

                this.conflictUIScene.enemyManager.hideAllEnemyBadges();
                this.conflictUIScene.enemyManager.isTargetingModeActive = false;
            }
        } else {
            console.error('Attempt to arm field ability failed:', JSON.stringify(data, null, 2));
            if (data.redirectToMainSelect) {
                console.log('Redirecting to MainSelect due to failure');

                // Log the interaction state before updating
                console.log('[handleAttemptConflictAbilitySelectionConfirmResponse] Updating interaction state to BaseInteractionState.MainSelect');

                this.conflictScene.interactionState = BaseInteractionState.MainSelect;
                this.conflictUIScene.hotkeyMenu.activate();
                this.conflictUIScene.primaryActionMenu.deselectAllPrimaryActionButtons();

                this.conflictUIScene.commandMenu.activate({
                    topMessage: data.failureMessage,
                    updateTopMessage: true,
                    showTopMessage: true,
                    bottomMessage: '',
                    updateBottomMessage: true,
                    showBottomMessage: false,
                    iconLabel: '',
                    updateIcon: false,
                    showIcon: false,
                    selectIcon: false
                });
            }
            // Optionally hide views that were meant to be transitioned from
            // Reset the armed state and update the UI as necessary
            this.conflictUIScene.abilityMenu.abilityIsArmed = false;
        }

    }

    private addBlinkEffect(sprites: Phaser.GameObjects.Sprite[]): void {
        const blinkInterval = 83; // milliseconds, rounded from 83.33
        const numberOfBlinks = 6;
        const totalIntervals = numberOfBlinks * 2; // Total intervals (off and on for each blink)
        let blinkCounter = 0;

        const blinkIntervalId = setInterval(() => {
            sprites.forEach(sprite => {
                sprite.setAlpha(blinkCounter % 2 === 0 ? 0 : 1);
            });
            blinkCounter++;

            if (blinkCounter >= totalIntervals) {
                clearInterval(blinkIntervalId);
                sprites.forEach(sprite => sprite.setAlpha(1));
            }
        }, blinkInterval);
    }

    public handlePreTurnAction(data: PreTurnActionData): void {
        console.log('Received a pre-turn action from the server at:');
        const now = new Date();
        const timeString = now.toLocaleTimeString('en-US', {hour12: false, hour: 'numeric', minute: 'numeric', second: 'numeric'});
        const milliseconds = now.getMilliseconds();
        console.log(`${timeString}.${milliseconds.toString().padStart(3, '0')}`);

        console.log({data});
        this.conflictScene.interactionState = BaseInteractionState.Processing;

        this.conflictScene.mode = PlayerConflictMode.Normal;
        this.conflictUIScene.primaryActionMenu.bindButton.deselect();

        if (!this.conflictScene.isSinglePlayerPVE) {
            this.conflictUIScene.allyManager.allyArmedActionsFrame.hideFrame();

            for (const playerArmedActionText of this.conflictUIScene.allyManager.alliesArmedActionTexts) {
                playerArmedActionText.setText('');
                playerArmedActionText.setVisible(false);
            }

            for (const armedActionCancelButton of this.conflictUIScene.allyManager.alliesArmedActionCancelButtons) {
                armedActionCancelButton.setVisible(false);
            }
            this.conflictUIScene.roundTimerFrame.hideFrame();
            this.conflictUIScene.roundTimerCountdownText.setText('');
            this.conflictUIScene.roundTimerCountdownText.setVisible(false);
            this.conflictUIScene.roundTimerLabelText.setVisible(false);
        }

        this.conflictUIScene.commandMenu.dismiss();

        this.conflictUIScene.attackMenu.dismiss();
        this.conflictUIScene.hotkeyMenu.dismiss();

        this.conflictUIScene.abilityMenu.dismiss();

        this.conflictUIScene.messageElement.activate({
            topMessage: data.actionText,
            updateTopMessage: true,
            showTopMessage: true,
            middleMessage: '',
            updateMiddleMessage: false,
            showMiddleMessage: false,
            bottomMessage: '',
            updateBottomMessage: false,
            showBottomMessage: false
        });

    }

    public handlePostTurnAction(data: ConflictTurnActionData): void {
        console.log('Received a post-turn action from the server at:');
        const now = new Date();
        const timeString = now.toLocaleTimeString('en-US', {hour12: false, hour: 'numeric', minute: 'numeric', second: 'numeric'});
        const milliseconds = now.getMilliseconds();
        console.log(`${timeString}.${milliseconds.toString().padStart(3, '0')}`);

        console.log({data});
        this.conflictScene.interactionState = BaseInteractionState.Processing;

        if (!this.conflictScene.isSinglePlayerPVE) {
            this.conflictUIScene.allyManager.allyArmedActionsFrame.hideFrame();

            for (const playerArmedActionText of this.conflictUIScene.allyManager.alliesArmedActionTexts) {
                playerArmedActionText.setText('');
                playerArmedActionText.setVisible(false);
            }

            for (const armedActionCancelButton of this.conflictUIScene.allyManager.alliesArmedActionCancelButtons) {
                armedActionCancelButton.setVisible(false);
            }
            this.conflictUIScene.roundTimerFrame.hideFrame();
            this.conflictUIScene.roundTimerCountdownText.setText('');
            this.conflictUIScene.roundTimerCountdownText.setVisible(false);
            this.conflictUIScene.roundTimerLabelText.setVisible(false);
        }

        this.conflictUIScene.commandMenu.dismiss();

        this.conflictUIScene.attackMenu.dismiss();
        this.conflictUIScene.hotkeyMenu.dismiss();

        this.conflictUIScene.abilityMenu.dismiss();

        // Gather all sprites related to the target
        const targetComponents = data.targetIsAlly
            ? this.conflictUIScene.allyManager.allyComponents[data.target.index]
            : this.conflictUIScene.enemyManager.enemyComponents[data.target.index];
        const targetSprites = [targetComponents.baseSprite];
        if (targetComponents.maleHairSprite) {
            targetSprites.push(targetComponents.maleHairSprite);
        }
        if (targetComponents.femaleHairSprite) {
            targetSprites.push(targetComponents.femaleHairSprite);
        }
        if (targetComponents.primaryClothingSprite) {
            targetSprites.push(targetComponents.primaryClothingSprite);
        }
        if (targetComponents.secondaryClothingSprite) {
            targetSprites.push(targetComponents.secondaryClothingSprite);
        }
        if (targetComponents.tertiaryClothingSprite) {
            targetSprites.push(targetComponents.tertiaryClothingSprite);
        }
        if (targetComponents.runebladeBottomWeaponSprite) {
            targetSprites.push(targetComponents.runebladeBottomWeaponSprite);
        }
        if (targetComponents.aethermancerBottomWeaponSprite) {
            targetSprites.push(targetComponents.aethermancerBottomWeaponSprite);
        }
        if (targetComponents.lifeweaverBottomWeaponSprite) {
            targetSprites.push(targetComponents.lifeweaverBottomWeaponSprite);
        }
        if (targetComponents.runebladeTopWeaponSprite) {
            targetSprites.push(targetComponents.runebladeTopWeaponSprite);
        }
        if (targetComponents.aethermancerTopWeaponSprite) {
            targetSprites.push(targetComponents.aethermancerTopWeaponSprite);
        }
        if (targetComponents.lifeweaverTopWeaponSprite) {
            targetSprites.push(targetComponents.lifeweaverTopWeaponSprite);
        }

        // If the target is alive, add blink effect. Otherwise, hide the sprites and button.
        if (!data.target.isAlive) {

            // this.conflictUIScene.topMessageText.setText(`${data.target.name} hath [color=#ff6666]perished[/color].`);
            // this.conflictUIScene.topMessageText.setVisible(true);
            this.conflictUIScene.messageElement.activate({
                topMessage: `${data.target.name} hath [color=#ff6666]perished[/color].`,
                updateTopMessage: true,
                showTopMessage: true,
                middleMessage: '',
                updateMiddleMessage: false,
                showMiddleMessage: false,
                bottomMessage: '',
                updateBottomMessage: false,
                showBottomMessage: false
            });

            if (data.targetIsAlly) {
                // Rotate all ally sprites 90 degrees to the left
                targetSprites.forEach(sprite => sprite.setAngle(-90));
                targetComponents.isAlive = false;
                if (data.target.index === 0) {
                    this.conflictUIScene.primaryActionMenu.fleeButton.hideActionButton();
                }
            } else {
                // Make all non-ally sprites invisible
                targetSprites.forEach(sprite => sprite.setVisible(false));
                targetComponents.isAlive = false;

                // Only reposition if the target was not an ally.
                this.conflictUIScene.enemyManager.repositionEnemySprites();
                this.conflictUIScene.enemyManager.repositionEnemyBadges();
            }

            targetComponents.button.setVisible(false); // Make sure the button is also invisible.
        }
    }

    public handleCriticalEffect(): void {
        // this.conflictUIScene.topMessageText.setText('A critical effect!');
        // this.conflictUIScene.topMessageText.setVisible(true);
        this.conflictUIScene.messageElement.activate({
            topMessage: 'A critical effect!',
            updateTopMessage: true,
            showTopMessage: true,
            middleMessage: '',
            updateMiddleMessage: false,
            showMiddleMessage: false,
            bottomMessage: '',
            updateBottomMessage: false,
            showBottomMessage: false
        });
    }

    public handlePreTurnBuffTrigger(data: string): void {
        this.conflictUIScene.messageElement.activate({
            topMessage: data,
            updateTopMessage: true,
            showTopMessage: true,
            middleMessage: '',
            updateMiddleMessage: false,
            showMiddleMessage: false,
            bottomMessage: '',
            updateBottomMessage: false,
            showBottomMessage: false
        });
    }

    public handleAttemptFleeSuccess(): void {
        this.conflictUIScene.waitingForServerResponse = false;

        this.conflictUIScene.primaryActionMenu.attackButton.deselect();
        this.conflictUIScene.primaryActionMenu.abilityButton.deselect();
        this.conflictUIScene.primaryActionMenu.inventoryButton.deselect();
        this.conflictUIScene.primaryActionMenu.fleeButton.select();
    }

    public handleAttemptConflictInventoryItemMainSelectResponse(data: AttemptConflictInventoryItemMainSelectResponseEvent): void {
        console.log(`[handleAttemptConflictInventoryItemMainSelectResponse] Received data: ${JSON.stringify(data, null, 2)}`);
        this.conflictUIScene.waitingForServerResponse = false;

        if (data.success) {
            console.log('[handleAttemptConflictInventoryItemMainSelectResponse] Success: Inventory menu open operation succeeded');
            this.conflictUIScene.primaryActionMenu.deselectAllPrimaryActionButtons();

            this.conflictUIScene.primaryActionMenu.inventoryButton.select();

            this.conflictUIScene.inventoryMenu.inventoryItemSecondaryCategoryButton.deselect();
            this.conflictUIScene.inventoryMenu.mode = 'inventory';
            this.conflictUIScene.inventoryMenu.activate({itemArray: data.items});

            this.conflictScene.interactionState = 'ItemMainSelectBag';
            console.log(`[handleAttemptConflictInventoryItemMainSelectResponse] Interaction state set to ${this.conflictScene.interactionState}`);

            if (this.conflictScene.mode === PlayerConflictMode.BindHotkey) {
                console.log('[handleAttemptConflictInventoryItemMainSelectResponse] Switching to BindAction mode');
                this.conflictScene.mode = PlayerConflictMode.BindAction;
                this.conflictUIScene.hotkeyMenu.toggleHotkeyBindMode(false);
            }
        } else {
            console.log('[handleAttemptConflictInventoryItemMainSelectResponse] Failure: Inventory menu open operation failed');
        }
    }

    public handleAttemptSelectConflictEquipmentItemResponse(data: AttemptSelectConflictEquipmentItemResponseEvent): void {
        console.log(`[handleAttemptSelectConflictEquipmentItemResponse] Received data: ${JSON.stringify(data, null, 2)}`);
        if (data.success) {
            console.log('[handleAttemptSelectConflictEquipmentItemResponse] Success: Equipment item selection succeeded');
            this.conflictScene.interactionState = `Item${data.equipmentItemType}DetailEquipment`;
            // select the equipment item
            this.conflictUIScene.inventoryMenu.equipmentButtonInfos.forEach((buttonInfo, key) => {
                if (buttonInfo.exists && key === data.equipmentItemType) {
                    buttonInfo.button.select();
                    buttonInfo.selected = true;
                } else {
                    buttonInfo.button.deselect();
                    buttonInfo.selected = false;
                }
            });
            this.conflictUIScene.inventoryMenu.populateDetailViewForEquippedItems(
                data
            );
            this.conflictUIScene.inventoryMenu.showDetailView();
            this.conflictUIScene.waitingForServerResponse = false;

        } else {
            console.log('[handleAttemptSelectConflictEquipmentItemResponse] Failure: Equipment item selection failed');
        }
    }

    public handleAttemptSwitchConflictInventoryModeResponse(data: AttemptSwitchConflictInventoryModeResponse): void {
        console.log(`[handleAttemptSwitchConflictInventoryModeResponse] Received data: ${JSON.stringify(data, null, 2)}`);
        this.conflictUIScene.waitingForServerResponse = false;

        if (data.success) {
            if ('mode' in data) { // Checking if 'mode' property exists in data
                if (data.mode === 'inventory') {
                    console.log('[handleAttemptSwitchConflictInventoryModeResponse] Success: Inventory mode switch succeeded');
                    // Handle inventory specifics, e.g., update UI with new inventory items
                    console.log(`[handleAttemptSwitchConflictInventoryModeResponse] Updated inventory: ${JSON.stringify(data.inventory, null, 2)}`);
                    this.conflictUIScene.inventoryMenu.mode = data.mode;
                    this.conflictScene.interactionState = 'ItemMainSelectBag';
                    this.conflictUIScene.inventoryMenu.inventoryItemMainCategoryButton.select();
                    this.conflictUIScene.inventoryMenu.inventoryItemSecondaryCategoryButton.deselect();
                    this.conflictUIScene.inventoryMenu.inventoryItemMainListButtonInfos.forEach((buttonInfo) => {
                        // clear it
                        buttonInfo.exists = false;
                        buttonInfo.selected = false;
                        buttonInfo.button.deselect();
                        buttonInfo.button.hideActionButton();
                    });
                    this.conflictUIScene.inventoryMenu.equipmentButtonInfos.forEach((buttonInfo) => {
                        // clear it
                        buttonInfo.exists = false;
                        buttonInfo.selected = false;
                        buttonInfo.button.deselect();
                        buttonInfo.button.hideActionButton();
                    });
                    this.conflictUIScene.inventoryMenu.headText.setVisible(false);
                    this.conflictUIScene.inventoryMenu.bodyText.setVisible(false);
                    this.conflictUIScene.inventoryMenu.mainHandText.setVisible(false);
                    this.conflictUIScene.inventoryMenu.offHandText.setVisible(false);

                    this.conflictUIScene.inventoryMenu.updateMainInventoryList({inventory: data.inventory});
                } else if (data.mode === 'equipment') {
                    console.log('[handleAttemptSwitchConflictInventoryModeResponse] Success: Equipment mode switch succeeded');
                    // Handle equipment specifics, e.g., update UI with new equipment details
                    console.log(`[handleAttemptSwitchConflictInventoryModeResponse] Updated equipment: ${JSON.stringify(data.equipment, null, 2)}`);
                    this.conflictUIScene.inventoryMenu.mode = data.mode;
                    this.conflictScene.interactionState = 'ItemMainSelectEquipment';
                    this.conflictUIScene.inventoryMenu.inventoryItemMainCategoryButton.deselect();
                    this.conflictUIScene.inventoryMenu.inventoryItemSecondaryCategoryButton.select();
                    this.conflictUIScene.inventoryMenu.inventoryItemMainListButtonInfos.forEach((buttonInfo) => {
                        // clear it
                        buttonInfo.exists = false;
                        buttonInfo.selected = false;
                        buttonInfo.button.deselect();
                        buttonInfo.button.hideActionButton();
                    });
                    this.conflictUIScene.inventoryMenu.equipmentButtonInfos.forEach((buttonInfo) => {
                        // clear it
                        buttonInfo.exists = false;
                        buttonInfo.selected = false;
                        buttonInfo.button.deselect();
                        buttonInfo.button.hideActionButton();
                    });

                    this.conflictUIScene.inventoryMenu.hideDetailView();

                    this.conflictUIScene.inventoryMenu.headText.setVisible(true);
                    this.conflictUIScene.inventoryMenu.bodyText.setVisible(true);
                    this.conflictUIScene.inventoryMenu.mainHandText.setVisible(true);
                    this.conflictUIScene.inventoryMenu.offHandText.setVisible(true);

                    // Assuming `inventoryOrEquipment` is cast to the correct type
                    const equipment = data.equipment as {
                        helmet?: EquipmentItemForDisplay;
                        bodyArmor?: EquipmentItemForDisplay;
                        offHand?: EquipmentItemForDisplay;
                        weapon?: EquipmentItemForDisplay;
                    };

                    // Set and show equipment buttons using the correct keys
                    if (equipment.helmet) {
                        const helmetInfo = this.conflictUIScene.inventoryMenu.equipmentButtonInfos.get(ItemType.Helmet);
                        if (helmetInfo) {
                            this.conflictUIScene.inventoryMenu.updateButtonInfo(helmetInfo, equipment.helmet);
                        }
                    }
                    if (equipment.bodyArmor) {
                        const bodyArmorInfo = this.conflictUIScene.inventoryMenu.equipmentButtonInfos.get(ItemType.BodyArmor);
                        if (bodyArmorInfo) {
                            this.conflictUIScene.inventoryMenu.updateButtonInfo(bodyArmorInfo, equipment.bodyArmor);
                        }
                    }
                    if (equipment.offHand) {
                        const offHandInfo = this.conflictUIScene.inventoryMenu.equipmentButtonInfos.get(ItemType.OffHand);
                        if (offHandInfo) {
                            this.conflictUIScene.inventoryMenu.updateButtonInfo(offHandInfo, equipment.offHand);
                        }
                    }
                    if (equipment.weapon) {
                        const weaponInfo = this.conflictUIScene.inventoryMenu.equipmentButtonInfos.get(ItemType.Weapon);
                        if (weaponInfo) {
                            this.conflictUIScene.inventoryMenu.updateButtonInfo(weaponInfo, equipment.weapon);
                        }
                    }
                }
            } else {
                // This block should theoretically never be hit since all success cases should have a 'mode'
                console.log('[handleAttemptSwitchConflictInventoryModeResponse] Success: Mode not specified');
            }
        } else {
            console.log('[handleAttemptSwitchConflictInventoryModeResponse] Failure: Inventory mode switch failed');
        }
    }

    public handleAttemptConflictInventoryItemSelectionConfirmResponse(data: AttemptConflictInventoryItemSelectionConfirmResponseEvent): void {
        console.log(`[handleAttemptConflictInventoryItemSelectionConfirmResponse] Received data: ${JSON.stringify(data, null, 2)}`);
        this.conflictUIScene.waitingForServerResponse = false;

        if (data.success) {
            console.log('[handleAttemptConflictInventoryItemSelectionConfirmResponse] Success: Inventory item selection confirmed');
            let targetingStateSuffix: string;
            if (Array.isArray(data.inventoryItemValidTargets)) {
                targetingStateSuffix = data.inventoryItemValidTargets.join('');
            } else {
                targetingStateSuffix = data.inventoryItemValidTargets;
            }
            let targetingState: InteractionState;
            if (data.inventoryItemCategory === 'Bag' || data.inventoryItemCategory === 'Quest') {
                targetingState = `Item${data.inventoryItemIndex}Targeting${targetingStateSuffix}${data.inventoryItemCategory}` as InteractionState;
                console.log(`[handleAttemptConflictInventoryItemSelectionConfirmResponse] Updating interaction state to ${targetingState}`);
                this.conflictScene.interactionState = targetingState;
            } else if (data.inventoryItemCategory === 'Equipment') {
                targetingState = `Item${data.inventoryItemType}Targeting${targetingStateSuffix}Equipment` as InteractionState;
                console.log(`[handleAttemptConflictInventoryItemSelectionConfirmResponse] Updating interaction state to ${targetingState}`);
                this.conflictScene.interactionState = targetingState;
            }

            this.conflictUIScene.commandMenu.dismiss();
            this.conflictUIScene.hotkeyMenu.dismiss();

            this.conflictUIScene.primaryActionMenu.inventoryButton.select();

            // todo: i think this method, populateArmedView needs to be fixed to support equipment items
            this.conflictUIScene.inventoryMenu.populateArmedView(data);
            this.conflictUIScene.inventoryMenu.showArmedView();

            if (
                data.inventoryItemValidTargets.includes(TargetTypeEnum.Enemy) ||
                data.inventoryItemValidTargets === TargetTypeEnum.Enemy
            ) {
                this.conflictUIScene.enemyManager.showBadgesForLivingEnemies();
                this.conflictUIScene.enemyManager.isTargetingModeActive = true;

                this.conflictUIScene.allyManager.hideAllAllyBadges();
                this.conflictUIScene.allyManager.isTargetingModeActive = false;
            } else if (
                data.inventoryItemValidTargets.includes(TargetTypeEnum.Ally) ||
                data.inventoryItemValidTargets === TargetTypeEnum.Ally
            ) {
                this.conflictUIScene.allyManager.showBadgesForAllAllies();
                this.conflictUIScene.allyManager.isTargetingModeActive = true;

                this.conflictUIScene.enemyManager.hideAllEnemyBadges();
                this.conflictUIScene.enemyManager.isTargetingModeActive = false;
            }

        } else {
            console.log('[handleAttemptConflictInventoryItemSelectionConfirmResponse] Failure: Inventory item selection failed');
            if (data.redirectToMainSelect) {
                console.log('Redirecting to MainSelect due to failure');

                // Log the interaction state before updating
                console.log('[handleAttemptConflictAbilitySelectionConfirmResponse] Updating interaction state to BaseInteractionState.MainSelect');

                this.conflictScene.interactionState = BaseInteractionState.MainSelect;
                this.conflictUIScene.hotkeyMenu.activate();
                this.conflictUIScene.primaryActionMenu.deselectAllPrimaryActionButtons();

                this.conflictUIScene.commandMenu.activate({
                    topMessage: data.failureMessage,
                    updateTopMessage: true,
                    showTopMessage: true,
                    bottomMessage: '',
                    updateBottomMessage: true,
                    showBottomMessage: false,
                    iconLabel: '',
                    updateIcon: false,
                    showIcon: false,
                    selectIcon: false
                });
            }
        }
    }

    public handleAttemptToggleConflictBindModeResponse(data: AttemptToggleConflictBindModeResponse): void {
        console.log('[handleAttemptToggleConflictBindModeResponse] Received response:', JSON.stringify(data));
        this.conflictUIScene.waitingForServerResponse = false;

        if (data.success) {
            console.log(`[handleAttemptToggleConflictBindModeResponse] Toggle success. New mode: ${data.newMode}`);

            if (data.newMode === PlayerConflictMode.BindAction) {
                console.log('[handleAttemptToggleConflictBindModeResponse] Switching to BindAction mode.');

                this.conflictScene.mode = PlayerConflictMode.BindAction;

                // Dismissing all potentially conflicting menus
                this.conflictUIScene.abilityMenu.dismiss();
                console.log('[handleAttemptToggleConflictBindModeResponse] Ability menu dismissed.');
                this.conflictUIScene.attackMenu.dismiss();
                console.log('[handleAttemptToggleConflictBindModeResponse] Attack menu dismissed.');
                this.conflictUIScene.inventoryMenu.dismiss();
                console.log('[handleAttemptToggleConflictBindModeResponse] Inventory menu dismissed.');

                // Deselect all primary action buttons and select the bind button
                this.conflictUIScene.primaryActionMenu.deselectAllPrimaryActionButtons();
                console.log('[handleAttemptToggleConflictBindModeResponse] All primary action buttons deselected.');
                this.conflictUIScene.primaryActionMenu.bindButton.select();
                console.log('[handleAttemptToggleConflictBindModeResponse] Bind button selected.');

                // Setting command string for the command menu
                this.conflictScene.commandString = 'Select an action to bind to the hotkey menu bar.';
                console.log(`[handleAttemptToggleConflictBindModeResponse] Command string set: ${this.conflictScene.commandString}`);
                this.conflictUIScene.commandMenu.activate({
                    topMessage: this.conflictScene.commandString,
                    updateTopMessage: true,
                    showTopMessage: true,
                    bottomMessage: '',
                    updateBottomMessage: true,
                    showBottomMessage: false,
                    iconLabel: '',
                    updateIcon: false,
                    showIcon: false,
                    selectIcon: false
                });
                console.log('[handleAttemptToggleConflictBindModeResponse] Command menu activated with new settings.');
                this.conflictUIScene.hotkeyMenu.activate();
                console.log('[handleAttemptToggleConflictBindModeResponse] Hotkey menu activated.');
            } else if (data.newMode === PlayerConflictMode.Normal) {
                console.log('[handleAttemptToggleConflictBindModeResponse] Switching to Normal mode.');

                this.conflictScene.mode = PlayerConflictMode.Normal;

                this.conflictUIScene.hotkeyMenu.toggleHotkeyBindMode(false);

                // Resetting command string to default
                this.conflictScene.commandString = 'Command?';
                console.log('[handleAttemptToggleConflictBindModeResponse] Command string reset to default.');
                this.conflictUIScene.commandMenu.activate({
                    topMessage: this.conflictScene.commandString,
                    updateTopMessage: true,
                    showTopMessage: true,
                    bottomMessage: '',
                    updateBottomMessage: true,
                    showBottomMessage: false,
                    iconLabel: '',
                    updateIcon: false,
                    showIcon: false,
                    selectIcon: false
                });

                this.conflictUIScene.hotkeyMenu.activate();

                // Deselect the bind button
                this.conflictUIScene.primaryActionMenu.bindButton.deselect();
                this.conflictUIScene.primaryActionMenu.abilityButton.deselect();
                this.conflictUIScene.primaryActionMenu.inventoryButton.deselect();
                console.log('[handleAttemptToggleConflictBindModeResponse] Bind button deselected.');
            }
            // set interaction state to main select
            this.conflictScene.interactionState = BaseInteractionState.MainSelect;
        } else {
            console.error(`[handleAttemptToggleConflictBindModeResponse] Failed to toggle conflict bind mode: ${data.message}`);
        }
    }

    public handleAttemptBindActionResponse(data: AttemptBindActionResponse): void {
        console.log(`[handleAttemptBindActionResponse] Received data: ${JSON.stringify(data, null, 2)}`);
        this.conflictUIScene.waitingForServerResponse = false;
        if (data.success) {

            this.conflictScene.mode = PlayerConflictMode.BindHotkey;

            this.conflictScene.selectedBindActionType = data.actionType;
            this.conflictScene.selectedBindActionIdentifier = data.actionIdentifier;

            this.conflictUIScene.abilityMenu.dismiss();
            this.conflictUIScene.attackMenu.dismiss();
            this.conflictUIScene.inventoryMenu.dismiss();

            this.conflictUIScene.primaryActionMenu.deselectAllPrimaryActionButtons();

            this.conflictUIScene.primaryActionMenu.bindButton.select();

            this.conflictUIScene.hotkeyMenu.activate();

            this.conflictUIScene.commandMenu.activate({
                topMessage: '',
                updateTopMessage: true,
                showTopMessage: false,
                bottomMessage: 'Select a hotkey to bind the action to.',
                updateBottomMessage: true,
                showBottomMessage: true,
                iconLabel: data.actionName,
                updateIcon: true,
                showIcon: true,
                iconKey: data.actionKey,
                iconActiveKey: data.actionActiveKey,
                selectIcon: false
            });

            this.conflictUIScene.hotkeyMenu.toggleHotkeyBindMode(true);
        }
    }

    public handleAttemptHotkeyBindResponse(data: AttemptHotkeyBindResponse): void {
        console.log(`[handleAttemptHotkeyBindResponse] Received data: ${JSON.stringify(data, null, 2)}`);
        this.conflictUIScene.waitingForServerResponse = false;

        if (data.success) {
            this.conflictScene.interactionState = BaseInteractionState.MainSelect;
            this.conflictScene.mode = PlayerConflictMode.Normal;

            this.conflictScene.commandString = 'Command?';
            // this.conflictUIScene.commandMenu.topCommandText.setText(this.conflictScene.commandString);
            this.conflictUIScene.commandMenu.activate({
                bottomMessage: '',
                iconLabel: '',
                selectIcon: false,
                showBottomMessage: false,
                showIcon: false,
                showTopMessage: true,
                topMessage: this.conflictScene.commandString,
                updateBottomMessage: true,
                updateIcon: false,
                updateTopMessage: true

            });

            this.conflictUIScene.primaryActionMenu.bindButton.deselect();

            this.conflictUIScene.hotkeyMenu.toggleHotkeyBindMode(false);
            this.conflictUIScene.hotkeyMenu.populateHotkeyMenu(data.hotkeyAssignments);
        }
    }
}
