import {Socket} from 'socket.io-client';
import {SpriteSheet, UIImageKey} from '../../../../../../types/assets/AssetKeys';
import {ServerSocketEvents} from '../../../../../../types/events/ServerSocketEvents';
import {CharacterProfession} from '../../../../../../types/mechanics/CharacterProfessionsEnum';
import {Gender} from '../../../../../../types/mechanics/Gender';
import {PlayerDataArray} from '../../../../../../types/playerInfo/PlayerDataArray';
import {heroWeaponColor} from '../../../../data/heroWeaponColor';
import {tertiaryColor} from '../../../../data/tertiaryColor';
import {mainGameFont} from '../../../../GameConfig';
import ServerControlledGameUIScene from '../../../../scenes/ServerControlledUIScenes/ServerControlledGameUIScene';
import {DepthLevel} from '../../../../types/DepthLevel';
import NonContainerUIActionButton from '../../ActionButtons/NonContainerUIActionButton';
import ResizableFrame from '../../UtilityComponents/ResizableFrame';

export default class GameActionBar {
    private partyLeaderLeavePartyButton: NonContainerUIActionButton;
    private partyReorderButton: NonContainerUIActionButton;
    public actionFrame: ResizableFrame;
    public leavePartyButton: NonContainerUIActionButton;
    public abilityMenuButton: NonContainerUIActionButton;
    public characterSheetButton: NonContainerUIActionButton;
    public inventoryButton: NonContainerUIActionButton;
    public playerComponents: {
        bodySprite: Phaser.GameObjects.Sprite,
        maleHairSprite: Phaser.GameObjects.Sprite,
        femaleHairSprite: Phaser.GameObjects.Sprite,
        primaryClothingSprite: Phaser.GameObjects.Sprite,
        secondaryClothingSprite: Phaser.GameObjects.Sprite,
        tertiaryClothingSprite: Phaser.GameObjects.Sprite,
        runebladeWeaponBottomSprite: Phaser.GameObjects.Sprite,
        runebladeWeaponTopSprite: Phaser.GameObjects.Sprite,
        aethermancerWeaponBottomSprite: Phaser.GameObjects.Sprite,
        aethermancerWeaponTopSprite: Phaser.GameObjects.Sprite,
        lifeweaverWeaponBottomSprite: Phaser.GameObjects.Sprite,
        lifeweaverWeaponTopSprite: Phaser.GameObjects.Sprite,
        button: Phaser.GameObjects.Rectangle,
        hpText: Phaser.GameObjects.Text,
        resourceText: Phaser.GameObjects.Text,
        socketId: string
    }[] = [];

    public constructor(public scene: ServerControlledGameUIScene, private socket: Socket) {
        console.log('GameActionBar constructor called');
        const centerX = this.scene.cameras.main.centerX;
        this.actionFrame = new ResizableFrame(this.scene, centerX, 650, 835, 64);

        // Initialize player components for the party members, all set to invisible
        for (let i = 0; i < 3; i++) {
            const buttonX = 68 + i * 215;
            const runebladeWeaponBottom = this.scene.add.sprite(buttonX, 650, SpriteSheet.RunebladeWeaponBottom).setDepth(DepthLevel.UI_PRIMARY_GRAPHICS_SUB).setVisible(false);
            const aethermancerWeaponBottom = this.scene.add.sprite(buttonX, 650, SpriteSheet.AethermancerWeaponBottom).setDepth(DepthLevel.UI_PRIMARY_GRAPHICS_SUB).setVisible(false);
            const lifeweaverWeaponBottom = this.scene.add.sprite(buttonX, 650, SpriteSheet.LifeweaverWeaponBottom).setDepth(DepthLevel.UI_PRIMARY_GRAPHICS_SUB).setVisible(false);
            const bodySprite = this.scene.add.sprite(buttonX, 650, SpriteSheet.HeroBaseSprite).setDepth(DepthLevel.UI_PRIMARY_GRAPHICS_SUB).setVisible(false);
            const primaryClothingSprite = this.scene.add.sprite(buttonX, 650, SpriteSheet.HeroClothesPrimary).setDepth(DepthLevel.UI_PRIMARY_GRAPHICS_SUB).setVisible(false);
            const secondaryClothingSprite = this.scene.add.sprite(buttonX, 650, SpriteSheet.HeroClothesSecondary).setDepth(DepthLevel.UI_PRIMARY_GRAPHICS_SUB).setVisible(false);
            const tertiaryClothingSprite = this.scene.add.sprite(buttonX, 650, SpriteSheet.HeroClothesTertiary).setDepth(DepthLevel.UI_PRIMARY_GRAPHICS_SUB).setVisible(false);
            const maleHairSprite = this.scene.add.sprite(buttonX, 650, SpriteSheet.HeroHairMale).setDepth(DepthLevel.UI_PRIMARY_GRAPHICS_SUB).setVisible(false);
            const femaleHairSprite = this.scene.add.sprite(buttonX, 650, SpriteSheet.HeroHairFemale).setDepth(DepthLevel.UI_PRIMARY_GRAPHICS_SUB).setVisible(false);
            const runebladeWeaponTopSprite = this.scene.add.sprite(buttonX, 650, SpriteSheet.RunebladeWeaponTop).setDepth(DepthLevel.UI_PRIMARY_GRAPHICS_SUB).setVisible(false);
            const aehtermancerWeaponTopSprite = this.scene.add.sprite(buttonX, 650, SpriteSheet.AethermancerWeaponTop).setDepth(DepthLevel.UI_PRIMARY_GRAPHICS_SUB).setVisible(false);
            const lifeweaverWeaponTopSprite = this.scene.add.sprite(buttonX, 650, SpriteSheet.LifeweaverWeaponTop).setDepth(DepthLevel.UI_PRIMARY_GRAPHICS_SUB).setVisible(false);
            const invisibleButton = this.scene.add.rectangle(buttonX, 650, bodySprite.width, bodySprite.height, 0xFF0000, 0).setInteractive().setDepth(DepthLevel.UI_PRIMARY_OVERLAY).setVisible(false);
            const hpText = this.scene.add.text(buttonX + 27, 620, 'HP: 0/0', {
                fontSize: '32px', color: '#ffffff', fontFamily: mainGameFont
            }).setStroke('#000000', 2).setResolution(3).setDepth(DepthLevel.UI_PRIMARY_TEXT).setVisible(false);
            const resourceText = this.scene.add.text(buttonX + 27, 648, 'MP: 0/0', {
                fontSize: '32px', color: '#ffffff', fontFamily: mainGameFont
            }).setStroke('#000000', 2).setResolution(3).setDepth(DepthLevel.UI_PRIMARY_TEXT).setVisible(false);

            // Placeholder for the socket ID which should be assigned later
            const socketId = '';

            invisibleButton.on('pointerdown', () => {
                console.log(`Attempting to target player ${i + 1}, with socket ID ${socketId}`);
                if (this.scene.abilityMenu.abilityIsArmed) {
                    // Preparing params for an armed ability
                    const params = {
                        abilityIndex: this.scene.abilityMenu.armedAbilityIndex,
                        abilityPageNumber: this.scene.abilityMenu.armedAbilityPageNumber,
                        targetSocketId: socketId
                    };
                    console.log(`[GameActionBar - PlayerComponent Invisible Button Callback] Preparing to emit AttemptUseFieldAbilityOnTarget with data: ${JSON.stringify(params, null, 2)}`);
                    this.socket.emit(ServerSocketEvents.AttemptUseFieldAbilityOnTarget, params);
                } else if (this.scene.inventoryMenu.inventoryItemIsArmed) {
                    // Preparing params for an armed inventory item
                    const params = {
                        inventoryItemIndex: this.scene.inventoryMenu.armedInventoryItemIndex,
                        targetSocketId: socketId
                    };
                    console.log(`[GameActionBar - PlayerComponent Invisible Button Callback] Preparing to emit AttemptUseFieldInventoryItemOnTarget with data: ${JSON.stringify(params, null, 2)}`);
                    this.socket.emit(ServerSocketEvents.AttemptUseFieldInventoryItemOnTarget, params);
                } else {
                    // Preparing params for targeting
                    console.log(`Preparing to target ${socketId} via emitMenuActivationRequest`);
                    this.scene.targetMenu.emitMenuActivationRequest({socketId});
                }
            });

            // Push the components into the array, all invisible
            this.playerComponents.push({
                bodySprite: bodySprite,
                maleHairSprite: maleHairSprite,
                femaleHairSprite: femaleHairSprite,
                primaryClothingSprite: primaryClothingSprite,
                secondaryClothingSprite: secondaryClothingSprite,
                tertiaryClothingSprite: tertiaryClothingSprite,
                runebladeWeaponBottomSprite: runebladeWeaponBottom,
                runebladeWeaponTopSprite: runebladeWeaponTopSprite,
                aethermancerWeaponBottomSprite: aethermancerWeaponBottom,
                aethermancerWeaponTopSprite: aehtermancerWeaponTopSprite,
                lifeweaverWeaponBottomSprite: lifeweaverWeaponBottom,
                lifeweaverWeaponTopSprite: lifeweaverWeaponTopSprite,
                button: invisibleButton,
                hpText: hpText,
                resourceText: resourceText,
                socketId: socketId
            });
        }

        // Initialize the leavePartyButton
        this.leavePartyButton = new NonContainerUIActionButton(
            this.scene,
            693,
            650,
            UIImageKey.CrossButton,
            UIImageKey.CrossButton,
            '',
            () => {
                console.log('Attempting to leave the party');
                if (
                    this.scene.serverControlledGameScene.player.processingFieldAction
                ) {
                    return;
                }
                this.socket.emit(ServerSocketEvents.AttemptLeaveParty);
            }
        );
        this.leavePartyButton.hideActionButton();
        this.leavePartyButton.setDepth(DepthLevel.UI_PRIMARY_GRAPHICS_SUB);

        this.abilityMenuButton = new NonContainerUIActionButton(
            this.scene,
            793,
            650,
            UIImageKey.BookButton,
            UIImageKey.BookButtonActive,
            '',
            () => {
                this.handleAbilityButtonPressed();
            }
        );

        this.inventoryButton = new NonContainerUIActionButton(
            this.scene,
            843,
            650,
            UIImageKey.BagButton,
            UIImageKey.BagButtonActive,
            '',
            () => {
                console.log('Attempting to toggle the inventory');
                this.handleInventoryButtonPressed();
            }
        );
        // this.inventoryButton.hideActionButton();

        this.characterSheetButton = new NonContainerUIActionButton(
            this.scene,
            743,
            650,
            UIImageKey.PageButton,
            UIImageKey.PageButtonActive,
            '',
            this.handleCharacterSheetButtonPressed.bind(this) // Updated to reference the new method
        );

        // Initialize the partyLeaderLeavePartyButton
        this.partyLeaderLeavePartyButton = new NonContainerUIActionButton(
            this.scene,
            693,
            639,
            UIImageKey.ExitButton,
            UIImageKey.ExitButton,
            '',
            () => {
                console.log('Party leader is attempting to leave the party.');
                if (
                    this.scene.serverControlledGameScene.player.processingFieldAction
                ) {
                    return;
                }
                this.scene.partyOrderMenu.dismiss();
                this.socket.emit(ServerSocketEvents.AttemptLeaveParty);
            }
        );
        this.partyLeaderLeavePartyButton.hideActionButton();
        this.partyLeaderLeavePartyButton.setDepth(DepthLevel.UI_PRIMARY_GRAPHICS_SUB);

        // Initialize the partyReorderButton
        this.partyReorderButton = new NonContainerUIActionButton(
            this.scene,
            693,
            661,
            UIImageKey.ReorderButton,
            UIImageKey.ReorderButton,
            '',
            () => {
                console.log('Attempting to toggle the party order menu');
                // this.scene.partyOrderMenu.emitMenuActivationRequest();
                if (this.scene.partyOrderMenu.shown) {
                    this.scene.partyOrderMenu.emitMenuDismissalRequest();
                } else {
                    this.scene.partyOrderMenu.emitMenuActivationRequest();
                }
            }
        );
        this.partyReorderButton.hideActionButton();
        this.partyReorderButton.setDepth(DepthLevel.UI_PRIMARY_GRAPHICS_SUB);
    }

    private handleCharacterSheetButtonPressed(): void {
        if (this.scene.characterSheetMenu.shown) {
            this.scene.characterSheetMenu.emitMenuDismissalRequest();
        } else {
            this.scene.characterSheetMenu.emitMenuActivationRequest();
        }
    }

    private handleAbilityButtonPressed(): void {
        console.log('Attempting to open the field ability menu');
        if (this.scene.abilityMenu.shown) {
            this.scene.abilityMenu.emitMenuDismissalRequest();
        } else {
            this.scene.abilityMenu.emitMenuActivationRequest();
        }
    }

    private handleInventoryButtonPressed(): void {
        console.log('Attempting to toggle the inventory');
        // this.scene.inventoryMenu.emitMenuActivationRequest();
        if (this.scene.inventoryMenu.shown) {
            this.scene.inventoryMenu.emitMenuDismissalRequest();
        } else {
            this.scene.inventoryMenu.emitMenuActivationRequest();
        }
    }

    public handleUpdatePartyMembers(partyMembers: PlayerDataArray): void {
        console.log(`[GameActionBar.handleUpdatePartyMembers] partyMembers: ${JSON.stringify(partyMembers, null, 2)}`);
        // First, hide all player components as a reset
        this.playerComponents.forEach(component => {
            component.bodySprite.setVisible(false);
            component.maleHairSprite.setVisible(false);
            component.femaleHairSprite.setVisible(false);
            component.primaryClothingSprite.setVisible(false);
            component.secondaryClothingSprite.setVisible(false);
            component.tertiaryClothingSprite.setVisible(false);
            component.runebladeWeaponBottomSprite.setVisible(false);
            component.runebladeWeaponTopSprite.setVisible(false);
            component.aethermancerWeaponBottomSprite.setVisible(false);
            component.aethermancerWeaponTopSprite.setVisible(false);
            component.lifeweaverWeaponBottomSprite.setVisible(false);
            component.lifeweaverWeaponTopSprite.setVisible(false);
            component.button.setVisible(false);
            component.hpText.setVisible(false);
            component.resourceText.setVisible(false);
        });

        // Update and make visible the components for existing party members
        partyMembers.forEach((member, index) => {
            if (index < this.playerComponents.length) {
                const playerComponent = this.playerComponents[index];
                playerComponent.bodySprite.setTint(Number(member.skinColor)).setVisible(true);

                switch (member.gender) {
                    case Gender.Male:
                        playerComponent.maleHairSprite.setTint(Number(member.hairColor)).setVisible(true);
                        break;
                    case Gender.Female:
                        playerComponent.femaleHairSprite.setTint(Number(member.hairColor)).setVisible(true);
                        break;
                    default:
                        playerComponent.maleHairSprite.setTint(Number(member.hairColor)).setVisible(true);
                        break;
                }

                playerComponent.primaryClothingSprite.setTint(Number(member.primaryColor)).setVisible(true);
                playerComponent.secondaryClothingSprite.setTint(Number(member.secondaryColor)).setVisible(true);
                playerComponent.tertiaryClothingSprite.setTint(Number(tertiaryColor)).setVisible(true);

                switch (member.className) {
                    case CharacterProfession.Runeblade:
                        playerComponent.runebladeWeaponBottomSprite.setTint(Number(heroWeaponColor)).setVisible(true);
                        playerComponent.runebladeWeaponTopSprite.setTint(Number(heroWeaponColor)).setVisible(true);
                        break;
                    case CharacterProfession.Aethermancer:
                        playerComponent.aethermancerWeaponBottomSprite.setTint(Number(heroWeaponColor)).setVisible(true);
                        playerComponent.aethermancerWeaponTopSprite.setTint(Number(heroWeaponColor)).setVisible(true);
                        break;
                    case CharacterProfession.Lifeweaver:
                        playerComponent.lifeweaverWeaponBottomSprite.setTint(Number(heroWeaponColor)).setVisible(true);
                        playerComponent.lifeweaverWeaponTopSprite.setTint(Number(heroWeaponColor)).setVisible(true);
                        break;
                    default:
                        playerComponent.runebladeWeaponBottomSprite.setTint(Number(heroWeaponColor)).setVisible(true);
                        playerComponent.runebladeWeaponTopSprite.setTint(Number(heroWeaponColor)).setVisible(true);
                        break;
                }

                playerComponent.button.setVisible(true);
                playerComponent.hpText.setText(`HP: ${member.currentHP}/${member.maxHP}`).setVisible(true);

                if (member.displayResourceInField) {
                    playerComponent.resourceText.setText(`${member.resourceType}: ${member.currentResource}/${member.maxResource}`).setVisible(true);
                }

                playerComponent.socketId = member.socketId; // Update the socket ID

                // Update event listeners for the interactive buttons
                playerComponent.button.off('pointerdown').on('pointerdown', () => {
                    console.log(`Attempting to target player ${index + 1}, with socket ID ${playerComponent.socketId}`);
                    if (this.scene.abilityMenu.abilityIsArmed) {
                        // Keep the ability logic as is, since it's not part of the refactoring request
                        console.log('Ability is armed. Attempting to USE ability on target player.');
                        const data = {
                            abilityIndex: this.scene.abilityMenu.armedAbilityIndex,
                            abilityPageNumber: this.scene.abilityMenu.armedAbilityPageNumber,
                            targetSocketId: playerComponent.socketId
                        };
                        console.log(`[GameActionBar - PlayerComponent Button Callback] Emitting AttemptUseFieldAbilityOnTarget with data: ${JSON.stringify(data, null, 2)}`);
                        this.socket.emit(ServerSocketEvents.AttemptUseFieldAbilityOnTarget, data);
                    } else if (this.scene.inventoryMenu.inventoryItemIsArmed) {
                        // Preparing params for an armed inventory item
                        const params = {
                            inventoryItemIndex: this.scene.inventoryMenu.armedInventoryItemIndex,
                            targetSocketId: playerComponent.socketId
                        };
                        console.log(`[GameActionBar - PlayerComponent Invisible Button Callback] Preparing to emit AttemptUseFieldInventoryItemOnTarget with data: ${JSON.stringify(params, null, 2)}`);
                        this.socket.emit(ServerSocketEvents.AttemptUseFieldInventoryItemOnTarget, params);
                    } else {
                        // Refactored to use emitMenuActivationRequest for targeting
                        console.log(`Preparing to target ${playerComponent.socketId} via emitMenuActivationRequest`);
                        this.scene.targetMenu.emitMenuActivationRequest({socketId: playerComponent.socketId});
                    }
                }).setInteractive();

            }
        });

        // Update the visibility of UI elements based on party size and leadership
        const partySize = partyMembers.length;
        const isLeader = partySize > 0 && this.socket.id === partyMembers[0].socketId;

        // The leave party button is visible only if there are more than one party members and the player is not the leader
        if (partySize > 1 && !isLeader) {
            this.leavePartyButton.showActionButton();
        } else {
            this.leavePartyButton.hideActionButton();
        }

        // The leader-specific buttons are visible only if the player is the leader and there are more than one party members
        const showLeaderButtons = partySize > 1 && isLeader;
        if (showLeaderButtons) {
            this.partyLeaderLeavePartyButton.showActionButton();
            this.partyReorderButton.showActionButton();
        } else {
            this.partyLeaderLeavePartyButton.hideActionButton();
            this.partyReorderButton.hideActionButton();
        }

    }

    public hideGameActionBarFrame(): void {
        this.actionFrame.hideFrame();
    }

    public showGameActionBarFrame(): void {
        this.actionFrame.showFrame();
    }
}
