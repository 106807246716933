export enum TargetRelationshipType {
    TARGETER_SOLO_TARGETING_SELF = 'targeter_solo_targeting_self',
    TARGETER_SOLO_TARGETING_SOLO = 'targeter_solo_targeting_solo',
    TARGETER_SOLO_TARGETING_PARTY_LEADER = 'targeter_solo_targeting_party_leader',
    TARGETER_SOLO_TARGETING_PARTY_FOLLOWER = 'targeter_solo_targeting_party_follower',
    TARGETER_PARTY_LEADER_TARGETING_SELF = 'targeter_party_leader_targeting_self',
    TARGETER_PARTY_LEADER_TARGETING_SOLO = 'targeter_party_leader_targeting_solo',
    TARGETER_PARTY_LEADER_TARGETING_PARTY_LEADER_DIFF_PARTY = 'targeter_party_leader_targeting_party_leader_diff_party',
    TARGETER_PARTY_LEADER_TARGETING_PARTY_FOLLOWER_SAME_PARTY = 'targeter_party_leader_targeting_party_follower_same_party',
    TARGETER_PARTY_LEADER_TARGETING_PARTY_FOLLOWER_DIFF_PARTY = 'targeter_party_leader_targeting_party_follower_diff_party',
    TARGETER_PARTY_FOLLOWER_TARGETING_SELF = 'targeter_party_follower_targeting_self',
    TARGETER_PARTY_FOLLOWER_TARGETING_SOLO = 'targeter_party_follower_targeting_solo',
    TARGETER_PARTY_FOLLOWER_TARGETING_PARTY_LEADER_SAME_PARTY = 'targeter_party_follower_targeting_party_leader_same_party',
    TARGETER_PARTY_FOLLOWER_TARGETING_PARTY_LEADER_DIFF_PARTY = 'targeter_party_follower_targeting_party_leader_diff_party',
    TARGETER_PARTY_FOLLOWER_TARGETING_PARTY_FOLLOWER_SAME_PARTY = 'targeter_party_follower_targeting_party_follower_same_party',
    TARGETER_PARTY_FOLLOWER_TARGETING_PARTY_FOLLOWER_DIFF_PARTY = 'targeter_party_follower_targeting_party_follower_diff_party',
    UNDEFINED = 'undefined',
}
