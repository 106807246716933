import {Socket} from 'socket.io-client';
import {UIImageKey} from '../../../../../../types/assets/AssetKeys';
import {ServerSocketEvents} from '../../../../../../types/events/ServerSocketEvents';
import {mainGameFont} from '../../../../GameConfig';
import ServerControlledGameUIScene from '../../../../scenes/ServerControlledUIScenes/ServerControlledGameUIScene';
import {DepthLevel} from '../../../../types/DepthLevel';
import {FieldMenuType} from '../../../../types/FieldMenuType';
import ResizableFrame from '../../UtilityComponents/ResizableFrame';
import {GameFieldActionMenu} from './GameFieldActionMenu';

export default class InteractableMessageDisplay extends GameFieldActionMenu {
    public shown: boolean = false;
    public menuTag = FieldMenuType.InteractableMessageDisplay;
    public relatedMenusToDismiss: Set<FieldMenuType> = new Set([
        FieldMenuType.AbilityMenu,
        FieldMenuType.CharacterSheetMenu,
        FieldMenuType.TargetMenu,
        FieldMenuType.DismissibleNotification,
        FieldMenuType.DuelNotification,
        FieldMenuType.InviteNotification,
        FieldMenuType.PartyOrderMenu,
        FieldMenuType.CommonerNPCMenu,
        FieldMenuType.InnkeeperNPCMenu,
        FieldMenuType.MerchantNPCMenu,
        FieldMenuType.MessageElement,
        FieldMenuType.InventoryMenu,
        FieldMenuType.TradeMenu,

        FieldMenuType.InteractionMenu,

    ]);
    public canCoincideWithGamePad = false;

    public messageFrame: ResizableFrame;
    public messageText: Phaser.GameObjects.Text;
    public closeButton: Phaser.GameObjects.Image;

    public constructor(public scene: ServerControlledGameUIScene, private socket: Socket, initialMessage: string) {
        super();
        const centerX = this.scene.cameras.main.centerX;
        const centerYWithOffset = this.scene.cameras.main.centerY + 125;

        // Define frame dimensions
        const frameWidth = 617;
        const frameHeight = 221;

        this.messageFrame = new ResizableFrame(this.scene, centerX, centerYWithOffset, frameWidth, frameHeight);
        this.messageFrame.hideFrame();

        // Create dialogue text
        this.messageText = this.scene.add.text(centerX - 306, centerYWithOffset - 110, initialMessage, { // Positioned to center within frame
            fontFamily: mainGameFont,
            fontSize: '36px',
            color: '#ffffff',
            wordWrap: {
                width: 590, // Match width of the frame less some padding
                useAdvancedWrap: true
            },
            metrics: {
                fontSize: 36,
                ascent: 31,
                descent: 7
            }
        }).setDepth(DepthLevel.UI_PRIMARY_TEXT).setResolution(3).setOrigin(0, 0);

        // Create and position the close button
        this.closeButton = this.scene.add.image(centerX + 278, centerYWithOffset - 107, UIImageKey.CrossIcon)
            .setInteractive()
            .setDepth(DepthLevel.UI_PRIMARY_GRAPHICS_SUB)
            .setScale(1.5)
            .setOrigin(0, 0);

        this.closeButton.on('pointerdown', () => this.emitMenuDismissalRequest());

        // Initially hide the message
        this.hideMessage();
    }

    public activate(message: string): void {
        this.shown = true;
        this.dismissRelatedMenus();
        this.messageText.setText(message);
        this.messageFrame.showFrame();
        this.messageText.setVisible(true);
        this.closeButton.setVisible(true);
    }

    public dismiss(): void {
        this.scene.serverControlledGameScene.player.isInteractingWithInteractable = false;
        this.hideMessage();
        this.shown = false;
    }

    public emitMenuDismissalRequest(): void {
        // this.dismiss();
        console.log(`[InteractableMessageDisplay.emitMenuDismissalRequest] Emitting ${ServerSocketEvents.AttemptDismissInteractableMessage} event.`);
        this.socket.emit(ServerSocketEvents.AttemptDismissInteractableMessage);
    }

    public emitMenuActivationRequest(params?: { message: string }): void {
        if (params) {
            this.activate(params.message);
        }
    }

    private hideMessage(): void {
        this.messageFrame.hideFrame();
        this.messageText.setVisible(false);
        this.closeButton.setVisible(false);
    }

    private showMessage(): void {
        this.messageFrame.showFrame();
        this.messageText.setVisible(true);
        this.closeButton.setVisible(true); // Ensure button is visible with the message
    }
}
