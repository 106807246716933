import {Scene} from 'phaser';
import {Socket} from 'socket.io-client';
import {PlayerDataArray} from '../../../../types/playerInfo/PlayerDataArray';
import SocketManager from '../../classes/NetworkingAndChat/SocketManager';
import DismissibleNotification from '../../classes/Notifications/DismissibleNotification';
import DuelNotification from '../../classes/Notifications/DuelNotification';
import PartyInviteNotification from '../../classes/Notifications/PartyInviteNotification';
import CharacterSheetMenu from '../../classes/UserInterface/MenusAndBars/FieldElements/CharacterSheetMenu';
import CommonerNPCMenuElements from '../../classes/UserInterface/MenusAndBars/FieldElements/CommonerNPCMenuElements';
import {FieldAbilityMenu} from '../../classes/UserInterface/MenusAndBars/FieldElements/FieldAbilityMenu';
import {FieldInventoryMenu} from '../../classes/UserInterface/MenusAndBars/FieldElements/FieldInventoryMenu';
import {FieldMessageElement} from '../../classes/UserInterface/MenusAndBars/FieldElements/FieldMessageElement';
import GameActionBar from '../../classes/UserInterface/MenusAndBars/FieldElements/GameActionBar';
import {GameFieldActionMenu} from '../../classes/UserInterface/MenusAndBars/FieldElements/GameFieldActionMenu';
import {InnkeeperNPCMenuElements} from '../../classes/UserInterface/MenusAndBars/FieldElements/InnkeeperNPCMenuElements';
import InteractableMessageDisplay from '../../classes/UserInterface/MenusAndBars/FieldElements/InteractableMessageDisplay';
import InteractionMenu from '../../classes/UserInterface/MenusAndBars/FieldElements/InteractionMenu';
import {MerchantNPCMenuElements} from '../../classes/UserInterface/MenusAndBars/FieldElements/MerchantNPCMenuElements';
import PartyOrderMenu from '../../classes/UserInterface/MenusAndBars/FieldElements/PartyOrderMenu';
import TargetMenu from '../../classes/UserInterface/MenusAndBars/FieldElements/TargetMenu';
import {TradeMenu} from '../../classes/UserInterface/MenusAndBars/FieldElements/TradeMenu';
import {DepthLevel} from '../../types/DepthLevel';
import {SceneNames} from '../../types/SceneNames';
import ServerControlledGameScene from '../ServerControlledGameplayScenes/ServerControlledGameScene';

export default class ServerControlledGameUIScene extends Scene {

    public socket: Socket;
    public targetMenu: TargetMenu;
    public duelNotification: DuelNotification;
    public dismissibleNotification: DismissibleNotification;

    public serverControlledGameScene: ServerControlledGameScene;
    public inviteNotification: PartyInviteNotification;
    public gameActionBar: GameActionBar;
    public playerDataArray: PlayerDataArray | null = null;

    public partyOrderMenu: PartyOrderMenu;
    public commonerNPCMenu: CommonerNPCMenuElements;
    public innkeeperNPCMenu: InnkeeperNPCMenuElements;
    public merchantNPCMenu: MerchantNPCMenuElements;
    public screenCoverGraphics: Phaser.GameObjects.Graphics;
    public interactionMenu: InteractionMenu;
    public interactableMessageDisplay: InteractableMessageDisplay;

    public characterSheetMenu: CharacterSheetMenu;
    public abilityMenu: FieldAbilityMenu;
    public messageElement: FieldMessageElement;
    public inventoryMenu: FieldInventoryMenu;

    public waitingForServerResponse: boolean = false;

    public menus: GameFieldActionMenu[] = [];
    public tradeMenu: TradeMenu;
    public playerInNPCSequence: boolean = false;

    public constructor() {
        super(SceneNames.ServerControlledGameUI);
        console.log('ServerControlledGameUIScene constructor called');
    }

    public init(data: PlayerDataArray | null): void {
        console.log('ServerControlledGameUIScene.init called with data:', JSON.stringify(data, null, 2));
        if (Array.isArray(data)) {
            this.playerDataArray = data;
        } else {
            console.log('ServerControlledGameUIScene.init called without array data');
            this.playerDataArray = null;
        }
    }

    public create(): void {
        console.log('ServerControlledGameUIScene.create called');

        this.socket = SocketManager.getInstance().socket;

        this.waitingForServerResponse = false;

        this.serverControlledGameScene = this.scene.get(SceneNames.ServerControlledGame) as ServerControlledGameScene;

        this.targetMenu = new TargetMenu(this, this.socket);
        this.menus.push(this.targetMenu);

        this.duelNotification = new DuelNotification(this, this.socket, '');
        this.menus.push(this.duelNotification);

        this.dismissibleNotification = new DismissibleNotification(this, this.socket, 'Placeholder message');
        this.menus.push(this.dismissibleNotification);

        this.interactableMessageDisplay = new InteractableMessageDisplay(this, this.socket, 'Placeholder message');
        this.menus.push(this.interactableMessageDisplay);

        this.inviteNotification = new PartyInviteNotification(this, this.socket, 'Placeholder message');
        this.menus.push(this.inviteNotification);

        this.partyOrderMenu = new PartyOrderMenu(this, this.socket);
        this.menus.push(this.partyOrderMenu);

        this.commonerNPCMenu = new CommonerNPCMenuElements(this, this.socket);
        this.menus.push(this.commonerNPCMenu);

        this.innkeeperNPCMenu = new InnkeeperNPCMenuElements(this);
        this.menus.push(this.innkeeperNPCMenu);

        this.merchantNPCMenu = new MerchantNPCMenuElements(this);
        this.menus.push(this.merchantNPCMenu);

        this.interactionMenu = new InteractionMenu(this, this.socket);
        this.menus.push(this.interactionMenu);

        this.characterSheetMenu = new CharacterSheetMenu(this, this.socket);
        this.menus.push(this.characterSheetMenu);

        this.abilityMenu = new FieldAbilityMenu(this, this.socket);
        this.menus.push(this.abilityMenu);

        this.inventoryMenu = new FieldInventoryMenu(this, this.socket);
        this.menus.push(this.inventoryMenu);

        this.tradeMenu = new TradeMenu(this, this.socket);
        this.menus.push(this.tradeMenu);

        console.log('ServerControlledGameUIScene.create: creating GameActionBar');
        this.gameActionBar = new GameActionBar(this, this.socket);

        this.messageElement = new FieldMessageElement(this);

        // check if playerData was passed when launching the scene
        if (this.playerDataArray !== null) {
            // use handleUpdatePartyMembers to set the initial HP text and tint for each player
            this.gameActionBar.handleUpdatePartyMembers(this.playerDataArray);
        }

        this.screenCoverGraphics = this.add.graphics();
        this.screenCoverGraphics.setDepth(DepthLevel.FULL_COVER);

        this.serverControlledGameScene.setSceneLoaded('gameUiScene');
    }

    public canProceedWithMenuAction(): boolean {
        if (this.serverControlledGameScene.player.processingFieldAction) {
            console.log('[Scene] Player is processing a field action. Exiting early.');
            return false;
        }

        if (this.waitingForServerResponse) {
            console.log('[Scene] Player is waiting for a server response. Exiting early.');
            return false;
        }

        return true; // Conditions are met, action can proceed
    }

    public dismissAllMenus(): void {
        this.targetMenu.dismiss();
        this.duelNotification.dismiss();
        this.dismissibleNotification.dismiss();
        this.inviteNotification.dismiss();
        this.partyOrderMenu.dismiss();
        this.commonerNPCMenu.dismiss();
        this.merchantNPCMenu.dismiss();
        this.innkeeperNPCMenu.dismiss();
        this.interactionMenu.dismiss();
        this.characterSheetMenu.dismiss();
        this.abilityMenu.dismiss();
        // Any additional menu or UI component that can be dismissed should be included here.
    }

}
