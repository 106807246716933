import Phaser from 'phaser';
import {Socket} from 'socket.io-client';
import {Images} from '../../../../types/assets/AssetKeys';
import {ClientSocketEvents} from '../../../../types/events/ClientSocketEvents';
import {LoginResponseEvent} from '../../../../types/events/LoginResponseEvent';
import {ServerSocketEvents} from '../../../../types/events/ServerSocketEvents';
import SocketManager from '../../classes/NetworkingAndChat/SocketManager';
import {mainGameFont} from '../../GameConfig';
import {SceneNames} from '../../types/SceneNames';
import DOMElement = Phaser.GameObjects.DOMElement;

export default class LoginScene extends Phaser.Scene {
    private backgroundRectangle: Phaser.GameObjects.Rectangle;
    private socket: Socket;
    private loginForm: Phaser.GameObjects.DOMElement;
    private errorMessage: HTMLParagraphElement;
    private titleText: Phaser.GameObjects.Text;
    private titleImage: Phaser.GameObjects.Image;

    public constructor() {
        super(SceneNames.Login);
        this.socket = SocketManager.getInstance().socket;
    }

    private setupSocketListeners(): void {
        console.log('Setting up listeners for LoginScene');
        // Set up the socket event listener for login response
        this.socket.on(ClientSocketEvents.LoginResponse, (response: LoginResponseEvent) => {
            this.errorMessage.textContent = '';
            console.log('Login response:', response);
            if (response.success) {
                console.log('Login successful.');
                // Start the Character Selection Scene
                this.tearDownListeners();
                this.scene.start(SceneNames.Boot);
            } else if (!response.success && response.error) {
                // Handle login failure
                console.error(response.error);
                this.errorMessage.textContent = response.error;
            }
        });

        this.socket.on(ClientSocketEvents.ServerShutdown, (response: { reason: string }) => {
            console.log('Server shutdown:', response);
            this.errorMessage.textContent = response.reason;
            this.scene.pause();

            // Disconnect the socket
            this.socket.disconnect();

            // Disable the form and all links
            this.disableLoginForm();
        });
    }

    private disableLoginForm(): void {
        const formElement = this.loginForm.node as HTMLFormElement;
        const inputs = formElement.querySelectorAll('input');
        const buttons = formElement.querySelectorAll('button');
        const links = formElement.querySelectorAll('a');
        const spans = formElement.querySelectorAll('span');

        spans.forEach(span => {
            span.style.color = '#999'; // Change text color to indicate disabled state
            span.style.pointerEvents = 'none'; // Disable pointer events
            span.style.cursor = 'not-allowed'; // Change cursor to indicate not clickable
        });
        inputs.forEach(input => input.disabled = true);
        buttons.forEach(button => {
            button.setAttribute('disabled', 'true'); // Disable the button
            button.style.pointerEvents = 'none'; // Disable pointer events
            button.style.backgroundColor = '#999'; // Change background color to indicate disabled state
            button.style.color = '#666'; // Change text color to indicate disabled state
            button.style.cursor = 'not-allowed'; // Change cursor to indicate not clickable
        });
        links.forEach(link => {
            link.setAttribute('disabled', 'disabled'); // Add disabled attribute
            link.style.pointerEvents = 'none'; // Disable click events
            link.style.color = '#999'; // Change text color to indicate disabled state
            link.style.textDecoration = 'none'; // Remove underline
            link.style.cursor = 'not-allowed'; // Change cursor to indicate not clickable
        });

        // Remove the pointerdown event listener
        this.loginForm.removeListener('pointerdown');
    }

    public preload(): void {
        this.load.html('loginform', 'assets/html/loginform.html');
        this.load.image(Images.TitleScreenBackground, 'assets/images/titleScreenBackgrounds/titleScreenBackground.png');
    }

    public create(): void {
        this.backgroundRectangle = this.add.rectangle(
            0,
            0,
            this.scale.width,
            this.scale.height,
            0xbcbcbc
        ).setOrigin(0, 0);

        this.titleImage = this.add.image(this.scale.width / 2, this.scale.height / 2, Images.TitleScreenBackground);
        this.titleImage.displayHeight = this.scale.height;
        this.titleImage.displayWidth = this.scale.width;

        this.titleText = this.add.text(this.scale.width / 2, 100, 'Afterlife Online', {
            fontSize: '128px',
            color: '#fff',
            fontFamily: mainGameFont
        })
            .setStroke('#000000', 6)
            .setOrigin(0.5);

        this.loginForm = this.add.dom(this.scale.width / 2, this.scale.height / 2).createFromCache('loginform') as DOMElement;
        this.errorMessage = this.loginForm.getChildByID('errorMessage') as HTMLParagraphElement;
        const formElement = this.loginForm.node as HTMLFormElement;
        formElement.onsubmit = (event: Event): void => {
            event.preventDefault();
            const username = (this.loginForm.getChildByID('usernameField') as HTMLInputElement).value;
            const password = (this.loginForm.getChildByID('passwordField') as HTMLInputElement).value;
            this.login(username, password);
        };

        this.loginForm.addListener('pointerdown');
        this.loginForm.on('pointerdown', this.handlePointerDown);

        this.scene.bringToTop(SceneNames.Login);
        this.setupSocketListeners();
    }

    private handlePointerDown = (event: Event): void => {
        const target = event.target as HTMLElement;
        if (target.id === 'registerLink') {
            this.tearDownListeners();
            this.scene.start(SceneNames.Register);
        } else if (target.id === 'forgotPasswordLink') {
            this.tearDownListeners();
            this.scene.start(SceneNames.ForgotPassword);
        }
    };

    private login(username: string, password: string): void {
        console.log('Logging in...');
        this.socket.emit(ServerSocketEvents.Login, {username, password});
        // No need to attach a new listener here, it's already set up in setupSocketListeners
    }
    public tearDownListeners(): void {
        console.log('Tearing down listeners for LoginScene');
        this.socket.off(ClientSocketEvents.LoginResponse);
        this.socket.off(ClientSocketEvents.ServerShutdown);
    }
}
