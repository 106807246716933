import numberToWords from 'number-to-words';
import {Socket} from 'socket.io-client';
import {UIImageKey} from '../../../../../../types/assets/AssetKeys';
import {SkillPreviewInfo} from '../../../../../../types/conflict/AttemptConflictAbilityMainSelectResponseEvent';
import {
    AttemptConflictAbilitySelectionConfirmResponseEvent
} from '../../../../../../types/conflict/AttemptConflictAbilitySelectionConfirmResponseEvent';
import {AttemptConflictAbilitySelectionEvent} from '../../../../../../types/conflict/AttemptConflictAbilitySelectionEvent';
import {ServerSocketEvents} from '../../../../../../types/events/ServerSocketEvents';
import {BaseInteractionState} from '../../../../../../types/mechanics/InteractionState';
import {ResourceType} from '../../../../../../types/mechanics/ResourceType';
import {mainGameFont} from '../../../../GameConfig';
import ServerControlledConflictUIScene from '../../../../scenes/ServerControlledUIScenes/ServerControlledConflictUIScene';
import {ConflictMenuType} from '../../../../types/ConflictMenuType';
import {DepthLevel} from '../../../../types/DepthLevel';
import SocketManager from '../../../NetworkingAndChat/SocketManager';
import NonContainerUIActionButton from '../../ActionButtons/NonContainerUIActionButton';
import ResizableFrame from '../../UtilityComponents/ResizableFrame';
import {GameConflictActionMenu} from './GameConflictActionMenu';

export class ConflictAbilityMenu extends GameConflictActionMenu{ // Menu properties
    public readonly menuTag: ConflictMenuType = ConflictMenuType.AbilityMenu;
    public shown: boolean = false;
    public abilityIsArmed: boolean = false;
    public relatedMenusToDismiss: Set<ConflictMenuType> = new Set([
        ConflictMenuType.AttackMenu,
        ConflictMenuType.InventoryMenu,
        ConflictMenuType.HotkeyMenu,
        ConflictMenuType.ConflictMessageElement,
        ConflictMenuType.ConflictCommandMenu,
    ]);

    // State indexes
    public armedAbilityIndex: number;
    public armedAbilityPageNumber: number;
    public selectedAbilityIndex: number;
    public selectedAbilityPageNumber: number;

    // Frames
    public abilityArmedCancelFrame: ResizableFrame;
    public abilityArmedCommandFrame: ResizableFrame;
    public abilityArmedDetailFrame: ResizableFrame;
    public abilityCategoryFrame: ResizableFrame;
    public abilityDetailFrame: ResizableFrame;
    public abilityMainListCancelFrame: ResizableFrame;
    public abilityMainListFrame: ResizableFrame;

    // Texts
    public abilityArmedCommandText: Phaser.GameObjects.Text;
    public abilityDetailText: Phaser.GameObjects.Text;
    public abilityResourceCostText: Phaser.GameObjects.Text;

    // Buttons
    public abilityArmedCancelButton: NonContainerUIActionButton;
    public abilityDetailCancelButton: NonContainerUIActionButton;
    public abilityDetailConfirmButton: NonContainerUIActionButton;
    public abilityMainListCancelButton: NonContainerUIActionButton;
    public abilityPageOneButton: NonContainerUIActionButton;

    // Icons
    public abilityArmedDetailIcon: NonContainerUIActionButton;

    // Lists
    public abilityMainListButtonInfos: {
        button: NonContainerUIActionButton;
        exists: boolean;
        selected: boolean;
    }[] = [];

    // Networking
    private socket: Socket;

    public constructor(scene: ServerControlledConflictUIScene) {
        super();
        this.scene = scene;
        this.socket = SocketManager.getInstance().socket;
        // Setup UI components during construction
        this.setupFrames();
        this.setupButtons();
        this.setupTexts();
    }

    private setupFrames(): void {
        console.log('Abilitymenu constructor called');
        this.abilityMainListFrame = new ResizableFrame(this.scene, 720, 392, 371, 409);
        this.abilityMainListFrame.hideFrame();

        this.abilityCategoryFrame = new ResizableFrame(this.scene, 381, 516, 276, 160);
        this.abilityCategoryFrame.hideFrame();

        this.abilityDetailFrame = new ResizableFrame(this.scene, 263, 266, 512, 308, 2);
        this.abilityDetailFrame.hideFrame();

        this.abilityMainListCancelFrame = new ResizableFrame(this.scene, 419, 370, 200, 100);
        this.abilityMainListCancelFrame.hideFrame();

        this.abilityArmedCancelFrame = new ResizableFrame(this.scene, 805, 486, 200, 100);
        this.abilityArmedCancelFrame.hideFrame();

        this.abilityArmedDetailFrame = new ResizableFrame(this.scene, 465, 466, 445, 60);
        this.abilityArmedDetailFrame.hideFrame();

        this.abilityArmedCommandFrame = new ResizableFrame(this.scene, 465, 554, 445, 84);
        this.abilityArmedCommandFrame.hideFrame();

    }

    private setupButtons(): void {
        this.abilityArmedCancelButton = new NonContainerUIActionButton(
            this.scene,
            730,
            465,
            UIImageKey.CrossButton,
            UIImageKey.CrossButton,
            'Cancel',
            this.handleCancelButton.bind(this) // Replace with the actual armed cancel function
        );
        this.abilityArmedCancelButton.setDepth(DepthLevel.UI_PRIMARY_GRAPHICS_SUB);
        this.abilityArmedCancelButton.hideActionButton();

        this.abilityPageOneButton = new NonContainerUIActionButton(
            this.scene,
            266,
            465,
            UIImageKey.PageButton,
            UIImageKey.PageButtonActive,
            'Page 1',
            this.onPageOne.bind(this) // Replace with the actual page one function

        );
        this.abilityPageOneButton.hideActionButton();

        this.abilityMainListCancelButton = new NonContainerUIActionButton(
            this.scene,
            344,
            349,
            UIImageKey.CrossButton,
            UIImageKey.CrossButton,
            'Cancel',
            this.handleCancelButton.bind(this) // Replace with the actual main list cancel function

        );
        this.abilityMainListCancelButton.hideActionButton();

        this.initializeAbilityButtonInfos();

        this.abilityDetailConfirmButton = new NonContainerUIActionButton(
            this.scene,
            35,
            392,
            UIImageKey.CheckButton,
            UIImageKey.CheckButton,
            'Use',
            this.onDetailConfirm.bind(this) // Replace with the actual detail confirm function

        );
        this.abilityDetailConfirmButton.setDepth(DepthLevel.UI_SECONDARY_GRAPHICS_SUB);
        this.abilityDetailConfirmButton.hideActionButton();

        this.abilityDetailCancelButton = new NonContainerUIActionButton(
            this.scene,
            185,
            392,
            UIImageKey.CrossButton,
            UIImageKey.CrossButton,
            'Cancel',
            this.handleCancelButton.bind(this) // Replace with the actual detail cancel function

        );
        this.abilityDetailCancelButton.setDepth(DepthLevel.UI_SECONDARY_GRAPHICS_SUB);
        this.abilityDetailCancelButton.hideActionButton();

        this.abilityArmedDetailIcon = new NonContainerUIActionButton(
            this.scene,
            266,
            465,
            UIImageKey.CheckButton,
            UIImageKey.CheckButton,
            'Nothing',
            () => {
            }
        );
        this.abilityArmedDetailIcon.setDepth(DepthLevel.UI_PRIMARY_GRAPHICS_SUB);
        this.abilityArmedDetailIcon.hideActionButton();
    }

    private initializeAbilityButtonInfos(): void {
        // Check if the initialization has already been done to prevent duplication
        if (this.abilityMainListButtonInfos.length === 0) {
            for (let i = 0; i < 8; i++) {
                const abilityButtonInfo = {
                    button: this.createAbilityButton(i),
                    exists: false, // Initialize as false; will be updated based on actual abilities
                    selected: false // Initialize as false; will be updated based on actual abilities
                };
                this.abilityMainListButtonInfos.push(abilityButtonInfo);
            }
        } else {
            // Optionally, log a message or handle cases where reinitialization might be intended but needs to be controlled
            console.log('Ability buttons have already been initialized.');
        }
    }

    private createAbilityButton(index: number): NonContainerUIActionButton {
        const numberWord = numberToWords.toWords(index + 1);
        const capitalizedWord = numberWord.charAt(0).toUpperCase() + numberWord.slice(1);
        const label = `Ability ${capitalizedWord}`;

        const abilityButton = new NonContainerUIActionButton(
            this.scene,
            564, // X position
            216 + (index * 50), // Y position, incrementing for each button
            UIImageKey.BagButton, // The image key for the button
            UIImageKey.BagButtonActive, // The image key when the button is active or selected
            label,
            this.onAbilityButtonClicked.bind(this, index) // Binding the click event to a handler
        );

        abilityButton.hideActionButton(); // Initially hiding the button
        abilityButton.setDepth(DepthLevel.UI_PRIMARY_GRAPHICS_SUB); // Setting the visual depth level

        return abilityButton;
    }

    // Placeholder function for the button click event handler
    private onAbilityButtonClicked(index: number): void {
        // Logic to handle ability button click
        console.log(`Ability button ${index + 1} clicked`);
        // Update the abilityMainListButtonInfos state as needed
    }

    // Placeholder functions for button callbacks
    private onArmedCancel(): void {
        // Logic when armed cancel is triggered
    }

    private onDetailCancel(): void {
        // Logic when detail cancel is triggered
    }

    private onDetailConfirm(): void {
        // Logic when detail confirm is triggered
    }

    private onMainListCancel(): void {
        // Logic when main list cancel is triggered
    }

    private onPageOne(): void {
        // Logic when page one is selected
    }

    private setupTexts(): void {
        this.abilityDetailText = this.scene.add.text(
            15,
            110,
            '',
            {
                fontSize: '45px',
                color: '#fff',
                fontFamily: mainGameFont,
                wordWrap: {
                    width: 500,
                    useAdvancedWrap: true
                },
                metrics: {
                    ascent: 38,
                    descent: 10,
                    fontSize: 48
                }
            }
        );
        this.abilityDetailText.setLineSpacing(-16);
        this.abilityDetailText.setResolution(3);
        this.abilityDetailText.setDepth(DepthLevel.UI_SECONDARY_TEXT);
        this.abilityDetailText.setVisible(false);

        this.abilityResourceCostText = this.scene.add.text(
            15,
            320,
            '',
            {
                fontSize: '45px',
                color: '#fff',
                fontFamily: mainGameFont,
            }
        );
        this.abilityResourceCostText.setResolution(3);
        this.abilityResourceCostText.setDepth(DepthLevel.UI_SECONDARY_TEXT);
        this.abilityResourceCostText.setVisible(false);

        this.abilityArmedCommandText = this.scene.add.text(
            244,
            510,
            'Choose A Target',
            {
                fontSize: '50px',
                color: '#fff',
                fontFamily: mainGameFont,
            }
        );
        this.abilityArmedCommandText.setDepth(DepthLevel.UI_PRIMARY_TEXT);
        this.abilityArmedCommandText.setResolution(3);
        this.abilityArmedCommandText.setVisible(false);

    }

    public activate(abilities: SkillPreviewInfo[]): void {
        this.shown = true;
        console.log('[AbilityMenu] Activating Ability Menu with abilities:', abilities);
        this.dismissRelatedMenus();

        // Hide all enemy badges and disable targeting mode
        this.scene.enemyManager.hideAllEnemyBadges();
        this.scene.enemyManager.isTargetingModeActive = false;

        // Hide all ally badges and disable targeting mode
        this.scene.allyManager.hideAllAllyBadges();
        this.scene.allyManager.isTargetingModeActive = false;

        this.populateInitialView(abilities); // Set up the buttons based on abilities
        this.showInitialView(); // Then show the initial view components

        // Activate the game action bar logic
        console.log('[AbilityMenu] Exiting activate method.');
    }

    public dismiss(): void {
        console.log('[AbilityMenu.dismiss] Dismissing Ability Menu.');

        // Section: Hide Frames
        this.abilityMainListFrame.hideFrame();
        this.abilityMainListCancelFrame.hideFrame();
        this.abilityCategoryFrame.hideFrame();
        this.abilityDetailFrame.hideFrame();
        this.abilityArmedCancelFrame.hideFrame();
        this.abilityArmedDetailFrame.hideFrame();
        this.abilityArmedCommandFrame.hideFrame();

        // Section: Process and Hide Buttons
        for (let index = 0; index < this.abilityMainListButtonInfos.length; index++) {
            const abilityButtonInfo = this.abilityMainListButtonInfos[index];
            abilityButtonInfo.button.hideActionButton();
            abilityButtonInfo.selected = false;
        }
        this.abilityMainListCancelButton.hideActionButton();
        this.abilityPageOneButton.hideActionButton();
        this.abilityDetailCancelButton.hideActionButton();
        this.abilityDetailConfirmButton.hideActionButton();
        this.abilityArmedCancelButton.hideActionButton();
        this.abilityArmedDetailIcon.hideActionButton();

        // Section: Hide Texts
        this.abilityDetailText.setVisible(false);
        this.abilityResourceCostText.setVisible(false);
        this.abilityArmedCommandText.setVisible(false);

        this.abilityIsArmed = false;

        // Additional Actions
        this.shown = false;
        console.log('[AbilityMenu.dismiss] Exiting method.');
    }

    public emitMenuActivationRequest(): void {
        // Logic to emit menu activation request
        // Emit the 'attemptConflictAbilityMainSelect' event to the server
        if (!this.scene.canProceedWithMenuAction()) {
            console.log('Cannot proceed with menu action. Exiting early.');
            return;
        }
        this.scene.waitingForServerResponse = true;
        this.socket.emit(ServerSocketEvents.AttemptConflictAbilityMainSelect);
    }

    public emitMenuDismissalRequest(): void {
        // Logic to emit menu dismissal request
        if (this.scene.conflictScene.interactionState === BaseInteractionState.MainSelect) {
            return;
        }
        if (!this.scene.canProceedWithMenuAction()) {
            console.log('Cannot proceed with menu action. Exiting early.');
            return;
        }
        this.scene.waitingForServerResponse = true;
        // Logic to cancel the attack
        console.log('Cancel attack button pressed');
        this.socket.emit(ServerSocketEvents.AttemptConflictCancel);

    }

    private showInitialView(): void {
        // Show the main list frame, category frame, page one button, etc.
        this.abilityMainListFrame.showFrame();
        this.abilityCategoryFrame.showFrame();
        this.abilityMainListCancelFrame.showFrame();
        this.abilityPageOneButton.showActionButton();
        this.abilityMainListCancelButton.showActionButton();

        this.abilityPageOneButton.select();

        // Loop through and show only the existing ability buttons
        this.abilityMainListButtonInfos.forEach(buttonInfo => {
            if (buttonInfo.exists) {
                buttonInfo.button.showActionButton();
            } else {
                buttonInfo.button.hideActionButton();
            }
        });
    }

    private populateInitialView(abilities: SkillPreviewInfo[]): void {
        // Ensure all buttons are reset to not exist before updating based on new abilities
        this.abilityMainListButtonInfos.forEach(info => {
            info.exists = false;
            info.button.hideActionButton();
        });

        // Update buttons based on the abilities provided
        abilities.forEach((ability, index) => {
            if (index < this.abilityMainListButtonInfos.length) {
                const buttonInfo = this.abilityMainListButtonInfos[index];
                buttonInfo.exists = true;
                buttonInfo.button.changeButtonImage(ability.key, ability.activeKey);
                buttonInfo.button.changeButtonText(ability.name);
                buttonInfo.button.deselect();
                buttonInfo.button.showActionButton();

                // Update button target callback to emit event when ability is selected
                buttonInfo.button.targetCallback = (): void => {
                    console.log(`[populateInitialView -> targetCallback] Ability button at index ${index} pressed`);
                    if (!this.scene.canProceedWithMenuAction()) {
                        console.log('Cannot proceed with menu action. Exiting early.');
                        return;
                    }
                    const currentInteractionState = this.scene.conflictScene.interactionState;
                    console.log(`[populateInitialView -> targetCallback] Current interaction state: ${currentInteractionState}`);

                    // Extract the page number if the current state is 'AbilityMainSelectPageX'
                    const mainSelectMatch = currentInteractionState.match(/AbilityMainSelect(Page\d+)/);
                    // Determine the current page number, defaulting to 1 if the specific page number is not found.
                    const currentPageNumber = mainSelectMatch && mainSelectMatch[1] ? parseInt(mainSelectMatch[1].replace('Page', '')) : 1;

                    console.log(`[populateInitialView -> targetCallback] Page number extracted from current interaction state: ${currentPageNumber}`);

                    // Extract the index of the currently selected ability if the state is 'AbilityXDetailPageX'
                    const detailMatch = currentInteractionState.match(/Ability(\d+)Detail(Page\d+)/);
                    const indexOfCurrentlySelectedAbility = detailMatch ? parseInt(detailMatch[1]) : -1;

                    // Log the results of extraction for both page number and currently selected ability's index.
                    console.log(`[populateInitialView -> targetCallback] Extracted details - currentPageNumber: ${currentPageNumber}, indexOfCurrentlySelectedAbility: ${indexOfCurrentlySelectedAbility}`);

                    // Check and log if the same ability button is pressed as is already selected.
                    if (indexOfCurrentlySelectedAbility !== -1 && index === indexOfCurrentlySelectedAbility) {
                        console.log(`[populateInitialView -> targetCallback] Ability at index ${index} is already selected. No further action taken.`);
                        return; // Early return if the same ability is already selected
                    }

                    // Proceed if the current state is appropriate for ability selection
                    if (currentInteractionState.startsWith('AbilityMainSelect') || indexOfCurrentlySelectedAbility !== -1) {
                        console.log('[populateInitialView -> targetCallback] Current state allows for ability selection. Preparing to emit \'AttemptConflictAbilitySelection\' event.');
                        const attemptConflictAbilitySelectionEvent: AttemptConflictAbilitySelectionEvent = {
                            abilityIndex: index,
                            abilityPageNumber: currentPageNumber as (1 | 2 | 3) // Casting to ensure type safety for the page number.
                        };
                        console.log(`[populateInitialView -> targetCallback] Emitting 'AttemptConflictAbilitySelection' with event data: ${JSON.stringify(attemptConflictAbilitySelectionEvent, null, 2)}`);
                        this.scene.waitingForServerResponse = true;
                        this.socket.emit(ServerSocketEvents.AttemptConflictAbilitySelection, attemptConflictAbilitySelectionEvent);
                    } else {
                        console.log(`[populateInitialView -> targetCallback] Current interaction state '${currentInteractionState}' does not permit ability selection. Event not emitted.`);
                    }
                };
            }
        });
    }

    public handleCancelButton(): void {
        if (this.scene.conflictScene.interactionState === BaseInteractionState.MainSelect) {
            return;
        }
        if (!this.scene.canProceedWithMenuAction()) {
            console.log('Cannot proceed with menu action. Exiting early.');
            return;
        }
        this.scene.waitingForServerResponse = true;
        console.log('Cancel abiliby button pressed');
        this.socket.emit(ServerSocketEvents.AttemptConflictCancel);
    }

    public hideInitialView(): void {
        // Hide the components specific to the initial view
        this.abilityMainListFrame.hideFrame();
        this.abilityCategoryFrame.hideFrame();
        this.abilityMainListCancelFrame.hideFrame();
        this.abilityPageOneButton.hideActionButton();
        this.abilityMainListCancelButton.hideActionButton();
        // Hide all ability buttons, regardless of their existing state
        this.abilityMainListButtonInfos.forEach(buttonInfo => buttonInfo.button.hideActionButton());
    }

    public populateDetailView(data: {
        abilityIndex: number;
        abilityName: string;
        abilityDescription: string;
        abilityResourceCost: number;
        abilityResourceType: ResourceType | undefined;
    }): void {
        // Select and mark the selected ability
        this.abilityMainListButtonInfos.forEach((buttonInfo, index) => {
            if (index === data.abilityIndex) {
                buttonInfo.button.select();
                buttonInfo.selected = true;
            } else {
                buttonInfo.button.deselect();
                buttonInfo.selected = false;
            }
        });

        // Populate the text fields and store the selected ability information
        this.abilityDetailText.setText(data.abilityDescription);
        const resourceTypeText = data.abilityResourceType ? ` ${data.abilityResourceType}` : '';
        this.abilityResourceCostText.setText(`Cost: ${data.abilityResourceCost}${resourceTypeText}`);
        this.selectedAbilityIndex = data.abilityIndex;
        this.selectedAbilityPageNumber = 1; // selection is always from the first page

        // Setup actions for the confirm button
        this.abilityDetailConfirmButton.targetCallback = (): void => {
            console.log('Ability selection confirm button pressed');
            if (!this.scene.canProceedWithMenuAction()) {
                console.log('Cannot proceed with menu action. Exiting early.');
                return;
            }
            this.scene.waitingForServerResponse = true;

            // Emit the 'attemptConflictAbilitySelectionConfirm' event with the current ability index and page number
            const attemptConflictAbilitySelectionConfirmEvent = {
                abilityIndex: data.abilityIndex,
                abilityPageNumber: this.selectedAbilityPageNumber
            };
            console.log(`Sending ability selection confirm event: ${JSON.stringify(attemptConflictAbilitySelectionConfirmEvent, null, 2)}`);
            this.socket.emit(ServerSocketEvents.AttemptConflictAbilitySelectionConfirm, attemptConflictAbilitySelectionConfirmEvent);
        };

        // Setup actions for the cancel button
        this.abilityDetailCancelButton.targetCallback = this.handleCancelButton.bind(this);
    }

    public showDetailView(): void {
        // Show the detail frame, ability text, resource cost text, and detail action buttons
        this.abilityDetailFrame.showFrame();
        this.abilityDetailText.setVisible(true);
        this.abilityResourceCostText.setVisible(true);
        this.abilityDetailConfirmButton.showActionButton();
        this.abilityDetailCancelButton.showActionButton();

        // Hide the components from the initial view as needed
        this.abilityMainListCancelFrame.hideFrame();
    }

    public hideDetailView(): void {
        // Hide the components specific to the detail view
        this.abilityDetailFrame.hideFrame();
        this.abilityDetailText.setVisible(false);
        this.abilityResourceCostText.setVisible(false);
        this.abilityDetailConfirmButton.hideActionButton();
        this.abilityDetailCancelButton.hideActionButton();
    }

    public populateArmedView(data: AttemptConflictAbilitySelectionConfirmResponseEvent): void {
        // Check if the response is a success state before proceeding
        if ('abilityIndex' in data) {
            // Set the text and images for the armed ability
            this.abilityArmedCommandText.setText('Choose A Target'); // Confirmatory text for the next action
            this.abilityArmedDetailIcon.changeButtonImage(data.abilityKey, data.abilityActiveKey); // Updates the icon for the armed ability
            this.abilityArmedDetailIcon.select();
            this.abilityArmedDetailIcon.changeButtonText(data.abilityName); // Sets the name for the armed ability

            this.abilityArmedCancelButton.targetCallback = this.handleCancelButton.bind(this); // Set the cancel button action

            // Update internal state to reflect the armed status
            this.abilityIsArmed = true;
            this.armedAbilityIndex = data.abilityIndex;
            this.armedAbilityPageNumber = data.abilityPageNumber;
        }/* else {
            console.error('Attempt to arm field ability failed with message:', data.redirectToMainSelect ? data.failureMessage : 'No message provided');
            // Handle failure state, such as showing an error message or logging
        }*/
    }

    public showArmedView(): void {
        // Hide components from the initial and/or detail view as needed
        this.hideInitialView();
        this.hideDetailView();

        // Show the components specific to the armed view
        this.abilityArmedCancelFrame.showFrame();
        this.abilityArmedCancelButton.showActionButton();
        this.abilityArmedDetailFrame.showFrame();
        this.abilityArmedDetailIcon.showActionButton();
        this.abilityArmedCommandFrame.showFrame();
        this.abilityArmedCommandText.setVisible(true);
    }

    public hideArmedView(): void {
        // Hide the components specific to the armed view
        this.abilityArmedCancelFrame.hideFrame();
        this.abilityArmedCancelButton.hideActionButton();
        this.abilityArmedDetailFrame.hideFrame();
        this.abilityArmedDetailIcon.hideActionButton();
        this.abilityArmedCommandFrame.hideFrame();
        this.abilityArmedCommandText.setVisible(false);
    }
    // public createAbilityButton(index: number): NonContainerUIActionButton {
    // Logic to create an ability button
    // return new NonContainerUIActionButton(/* parameters */);
    // }

    // ... Implement all other methods from the screenshot
}
