import {OperatingSystem} from '../../types/OperatingSystem';

export class OperatingSystemInfo {
    private static instance: OperatingSystemInfo;
    private _operatingSystem: OperatingSystem;

    private constructor() {}

    public static getInstance(): OperatingSystemInfo {
        if (!OperatingSystemInfo.instance) {
            OperatingSystemInfo.instance = new OperatingSystemInfo();
        }
        return OperatingSystemInfo.instance;
    }

    public get operatingSystem(): OperatingSystem {
        return this._operatingSystem;
    }

    public set operatingSystem(value: OperatingSystem) {
        this._operatingSystem = value;
    }
}
