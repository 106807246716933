import numberToWords from 'number-to-words';
import {Socket} from 'socket.io-client';
import {AttemptArmFieldAbilityResponseEvent} from '../../../../../../types/abilities/AttemptArmFieldAbilityResponseEvent';
import {UIImageKey} from '../../../../../../types/assets/AssetKeys';
import {SkillPreviewInfo} from '../../../../../../types/conflict/AttemptConflictAbilityMainSelectResponseEvent';
import {ServerSocketEvents} from '../../../../../../types/events/ServerSocketEvents';
import {ResourceType} from '../../../../../../types/mechanics/ResourceType';
import {mainGameFont} from '../../../../GameConfig';
import ServerControlledGameUIScene from '../../../../scenes/ServerControlledUIScenes/ServerControlledGameUIScene';
import {DepthLevel} from '../../../../types/DepthLevel';
import {FieldMenuType} from '../../../../types/FieldMenuType';
import NonContainerUIActionButton from '../../ActionButtons/NonContainerUIActionButton';
import ResizableFrame from '../../UtilityComponents/ResizableFrame';
import {GameFieldActionMenu} from './GameFieldActionMenu';

export class FieldAbilityMenu extends GameFieldActionMenu {
    public menuTag: FieldMenuType = FieldMenuType.AbilityMenu;
    public relatedMenusToDismiss: Set<FieldMenuType> = new Set([
        FieldMenuType.CharacterSheetMenu,
        FieldMenuType.TargetMenu,
        FieldMenuType.DuelNotification,
        FieldMenuType.DismissibleNotification,
        FieldMenuType.InviteNotification,
        FieldMenuType.PartyOrderMenu,
        FieldMenuType.CommonerNPCMenu,
        FieldMenuType.InnkeeperNPCMenu,
        FieldMenuType.MerchantNPCMenu,
        FieldMenuType.MessageElement,
        FieldMenuType.InventoryMenu,
        FieldMenuType.TradeMenu,
        FieldMenuType.InteractableMessageDisplay,

        FieldMenuType.InteractionMenu,

        // Any other menu that should be dismissed when AbilityMenu is active
    ]);
    public canCoincideWithGamePad = true;

    public shown: boolean = false;
    public abilityArmedCancelButton: NonContainerUIActionButton;
    public abilityArmedCancelFrame: ResizableFrame;
    public abilityArmedCommandFrame: ResizableFrame;
    public abilityArmedCommandText: Phaser.GameObjects.Text;
    public abilityArmedDetailFrame: ResizableFrame;
    public abilityArmedDetailIcon: NonContainerUIActionButton;
    public abilityCategoryFrame: ResizableFrame;
    public abilityDetailCancelButton: NonContainerUIActionButton;
    public abilityDetailConfirmButton: NonContainerUIActionButton;
    public abilityDetailFrame: ResizableFrame;
    public abilityDetailText: Phaser.GameObjects.Text;
    public abilityIsArmed: boolean = false;
    public abilityMainListButtonInfos: {
        button: NonContainerUIActionButton;
        exists: boolean;
        selected: boolean
    }[] = [];
    public abilityMainListCancelButton: NonContainerUIActionButton;
    public abilityMainListCancelFrame: ResizableFrame;
    public abilityMainListFrame: ResizableFrame;
    public abilityPageOneButton: NonContainerUIActionButton;
    public abilityResourceCostText: Phaser.GameObjects.Text;
    public armedAbilityIndex: number;
    public armedAbilityPageNumber: number;
    public selectedAbilityIndex: number;
    public selectedAbilityPageNumber: number;

    public constructor(public scene: ServerControlledGameUIScene, private socket: Socket) {
        super();
        console.log('AbilityMenu constructor called');
        this.setupFrames();
        this.setupButtons();
        this.setupTexts();
    }

    public emitMenuDismissalRequest(): void {
        // Check if the player is currently processing a field ability
        if (!this.scene.canProceedWithMenuAction()) {
            // If false, exit early. Logging is already handled within `canProceedWithMenuAction`.
            return;
        }

        console.log('[AbilityMenu.emitMenuDismissalRequest] Preparing to emit menu close event');

        // Emitting the event to close the ability menu
        console.log('[AbilityMenu] Emitting event to close the ability menu');
        this.socket.emit(ServerSocketEvents.AttemptToggleAbilityMenu, 'close');
    }

    public emitMenuActivationRequest(): void {
        // Check if the player is currently processing a field ability
        if (!this.scene.canProceedWithMenuAction()) {
            // If false, exit early. Logging is already handled within `canProceedWithMenuAction`.
            return;
        }

        console.log('[AbilityMenu.emitMenuActivationRequest] Preparing to emit menu open event');

        // Directly setting action to "open" as the method is now only for opening the menu
        const action = 'open';

        console.log('[AbilityMenu] Emitting event to open the ability menu');
        this.scene.waitingForServerResponse = true;
        this.socket.emit(ServerSocketEvents.AttemptToggleAbilityMenu, action);
    }

    // Method to update the view based on available abilities
    private populateInitialView(abilities: SkillPreviewInfo[]): void {
        // Ensure all buttons are reset to not exist before updating based on new abilities
        this.abilityMainListButtonInfos.forEach(info => {
            info.exists = false;
            info.button.hideActionButton();
        });

        // Update buttons based on the abilities provided
        abilities.forEach((ability, index) => {
            if (index < this.abilityMainListButtonInfos.length) {
                const buttonInfo = this.abilityMainListButtonInfos[index];
                buttonInfo.exists = true;
                buttonInfo.button.changeButtonImage(ability.key, ability.activeKey);
                buttonInfo.button.changeButtonText(ability.name);
                buttonInfo.button.deselect();
                buttonInfo.button.showActionButton();

                // Update button target callback to emit event when ability is selected
                buttonInfo.button.targetCallback = (): void => {
                    const eventData = {abilityIndex: index, pageIndex: 0}; // Assuming pageIndex is always 0 for this setup
                    this.socket.emit(ServerSocketEvents.AttemptSelectFieldAbility, eventData);
                };
            }
        });
    }

    // Method to show the initial view components
    private showInitialView(): void {
        // Show the main list frame, category frame, page one button, etc.
        this.abilityMainListFrame.showFrame();
        this.abilityCategoryFrame.showFrame();
        this.abilityMainListCancelFrame.showFrame();
        this.abilityPageOneButton.showActionButton();
        this.abilityMainListCancelButton.showActionButton();

        this.abilityPageOneButton.select();

        // Loop through and show only the existing ability buttons
        this.abilityMainListButtonInfos.forEach(buttonInfo => {
            if (buttonInfo.exists) {
                buttonInfo.button.showActionButton();
            } else {
                buttonInfo.button.hideActionButton();
            }
        });
    }

    public populateDetailView(data: {
        abilityIndex: number;
        abilityName: string;
        abilityDescription: string;
        abilityResourceCost: number;
        abilityResourceType: ResourceType | undefined;
    }): void {
        // Select and mark the selected ability
        this.abilityMainListButtonInfos.forEach((buttonInfo, index) => {
            if (index === data.abilityIndex) {
                buttonInfo.button.select();
                buttonInfo.selected = true;
            } else {
                buttonInfo.button.deselect();
                buttonInfo.selected = false;
            }
        });

        // Populate the text fields and store the selected ability information
        this.abilityDetailText.setText(data.abilityDescription);
        const resourceTypeText = data.abilityResourceType ? ` ${data.abilityResourceType}` : '';
        this.abilityResourceCostText.setText(`Cost: ${data.abilityResourceCost}${resourceTypeText}`);
        this.selectedAbilityIndex = data.abilityIndex;
        this.selectedAbilityPageNumber = 0; // Assuming initial selection is always from the first page
    }

    // Method to hide the initial view
    public hideInitialView(): void {
        // Hide the components specific to the initial view
        this.abilityMainListFrame.hideFrame();
        this.abilityCategoryFrame.hideFrame();
        this.abilityMainListCancelFrame.hideFrame();
        this.abilityPageOneButton.hideActionButton();
        this.abilityMainListCancelButton.hideActionButton();
        // Hide all ability buttons, regardless of their existing state
        this.abilityMainListButtonInfos.forEach(buttonInfo => buttonInfo.button.hideActionButton());
    }

    // Method to show the detail view
    public showDetailView(): void {
        // Show the detail frame, ability text, resource cost text, and detail action buttons
        this.abilityDetailFrame.showFrame();
        this.abilityDetailText.setVisible(true);
        this.abilityResourceCostText.setVisible(true);
        this.abilityDetailConfirmButton.showActionButton();
        this.abilityDetailCancelButton.showActionButton();

        // Hide the components from the initial view as needed
        this.abilityMainListCancelFrame.hideFrame();
        this.abilityMainListCancelButton.hideActionButton();
    }

    // Method to hide the detail view
    public hideDetailView(): void {
        // Hide the components specific to the detail view
        this.abilityDetailFrame.hideFrame();
        this.abilityDetailText.setVisible(false);
        this.abilityResourceCostText.setVisible(false);
        this.abilityDetailConfirmButton.hideActionButton();
        this.abilityDetailCancelButton.hideActionButton();
    }

    public populateArmedView(data: AttemptArmFieldAbilityResponseEvent): void {
        // Check if the response is a success state before proceeding
        if ('abilityIndex' in data) {
            // Set the text and images for the armed ability
            this.abilityArmedCommandText.setText('Choose A Target'); // Confirmatory text for the next action
            this.abilityArmedDetailIcon.changeButtonImage(data.abilityKey, data.abilityActiveKey); // Updates the icon for the armed ability
            this.abilityArmedDetailIcon.select();
            this.abilityArmedDetailIcon.changeButtonText(data.abilityName); // Sets the name for the armed ability

            // Update internal state to reflect the armed status
            this.abilityIsArmed = true;
            this.armedAbilityIndex = data.abilityIndex;
            this.armedAbilityPageNumber = data.pageIndex;
        } else {
            console.error('Attempt to arm field ability failed with message:', data.message);
            // Handle failure state, such as showing an error message or logging
        }
    }

    // Method to show the armed view, hiding previous views as needed
    public showArmedView(): void {
        // Hide components from the initial and/or detail view as needed
        this.hideInitialView();
        this.hideDetailView();

        // Show the components specific to the armed view
        this.abilityArmedCancelFrame.showFrame();
        this.abilityArmedCancelButton.showActionButton();
        this.abilityArmedDetailFrame.showFrame();
        this.abilityArmedDetailIcon.showActionButton();
        this.abilityArmedCommandFrame.showFrame();
        this.abilityArmedCommandText.setVisible(true);
    }

    // Method to hide the armed view
    public hideArmedView(): void {
        // Hide the components specific to the armed view
        this.abilityArmedCancelFrame.hideFrame();
        this.abilityArmedCancelButton.hideActionButton();
        this.abilityArmedDetailFrame.hideFrame();
        this.abilityArmedDetailIcon.hideActionButton();
        this.abilityArmedCommandFrame.hideFrame();
        this.abilityArmedCommandText.setVisible(false);
    }

    private initializeAbilityButtonInfos(): void {
        // Check if the initialization has already been done to prevent duplication
        if (this.abilityMainListButtonInfos.length === 0) {
            for (let i = 0; i < 8; i++) {
                const abilityButtonInfo = {
                    button: this.createAbilityButton(i),
                    exists: false, // Initialize as false; will be updated based on actual abilities
                    selected: false // Initialize as false; will be updated based on actual abilities
                };
                this.abilityMainListButtonInfos.push(abilityButtonInfo);
            }
        } else {
            // Optionally, log a message or handle cases where reinitialization might be intended but needs to be controlled
            console.log('Ability buttons have already been initialized.');
        }
    }

    private createAbilityButton(index: number): NonContainerUIActionButton {
        const numberWord = numberToWords.toWords(index + 1);
        const capitalizedWord = numberWord.charAt(0).toUpperCase() + numberWord.slice(1);
        const label = `Ability ${capitalizedWord}`;

        const abilityButton = new NonContainerUIActionButton(
            this.scene,
            564,
            216 + (index * 50),
            UIImageKey.BagButton,
            UIImageKey.BagButtonActive,
            label,
            () => {
                // Button click logic...
            }
        );

        abilityButton.hideActionButton();
        abilityButton.setDepth(DepthLevel.UI_PRIMARY_GRAPHICS_SUB);

        return abilityButton;
    }

    private setupFrames(): void {
        console.log('Abilitymenu constructor called');
        this.abilityMainListFrame = new ResizableFrame(this.scene, 720, 392, 371, 409);
        this.abilityMainListFrame.hideFrame();

        this.abilityCategoryFrame = new ResizableFrame(this.scene, 381, 516, 276, 160);
        this.abilityCategoryFrame.hideFrame();

        this.abilityDetailFrame = new ResizableFrame(this.scene, 263, 266, 512, 308, 2);
        this.abilityDetailFrame.hideFrame();

        this.abilityMainListCancelFrame = new ResizableFrame(this.scene, 419, 370, 200, 100);
        this.abilityMainListCancelFrame.hideFrame();

        this.abilityArmedCancelFrame = new ResizableFrame(this.scene, 805, 486, 200, 100);
        this.abilityArmedCancelFrame.hideFrame();

        this.abilityArmedDetailFrame = new ResizableFrame(this.scene, 465, 466, 445, 60);
        this.abilityArmedDetailFrame.hideFrame();

        this.abilityArmedCommandFrame = new ResizableFrame(this.scene, 465, 554, 445, 84);
        this.abilityArmedCommandFrame.hideFrame();

    }

    private setupButtons(): void {
        this.abilityArmedCancelButton = new NonContainerUIActionButton(
            this.scene,
            730,
            465,
            UIImageKey.CrossButton,
            UIImageKey.CrossButton,
            'Cancel',
            () => {
                console.log('Secondary cancel button clicked');
                this.emitMenuDismissalRequest();
            }
        );
        this.abilityArmedCancelButton.setDepth(DepthLevel.UI_PRIMARY_GRAPHICS_SUB);
        this.abilityArmedCancelButton.hideActionButton();

        this.abilityPageOneButton = new NonContainerUIActionButton(
            this.scene,
            266,
            465,
            UIImageKey.PageButton,
            UIImageKey.PageButtonActive,
            'Page 1',
            () => {
                console.log('Page 1 button clicked');
            }
        );
        this.abilityPageOneButton.hideActionButton();

        this.abilityMainListCancelButton = new NonContainerUIActionButton(
            this.scene,
            344,
            349,
            UIImageKey.CrossButton,
            UIImageKey.CrossButton,
            'Cancel',
            () => {
                this.emitMenuDismissalRequest();
            }
        );
        this.abilityMainListCancelButton.hideActionButton();

        this.initializeAbilityButtonInfos();

        this.abilityDetailConfirmButton = new NonContainerUIActionButton(
            this.scene,
            35,
            392,
            UIImageKey.CheckButton,
            UIImageKey.CheckButton,
            'Use',
            () => {
                console.log('[AbilityMenu] Confirm button clicked.');
                const eventData = {
                    abilityIndex: this.selectedAbilityIndex,
                    pageIndex: this.selectedAbilityPageNumber
                };
                console.log(`[AbilityMenu] Emitting event with data: ${JSON.stringify(eventData, null, 2)}`);
                this.socket.emit(ServerSocketEvents.AttemptArmFieldAbility, eventData); // Ensure this event name matches your server-side expectations
            }
        );
        this.abilityDetailConfirmButton.setDepth(DepthLevel.UI_SECONDARY_GRAPHICS_SUB);
        this.abilityDetailConfirmButton.hideActionButton();

        this.abilityDetailCancelButton = new NonContainerUIActionButton(
            this.scene,
            185,
            392,
            UIImageKey.CrossButton,
            UIImageKey.CrossButton,
            'Cancel',
            () => {
                this.emitMenuDismissalRequest();
            }
        );
        this.abilityDetailCancelButton.setDepth(DepthLevel.UI_SECONDARY_GRAPHICS_SUB);
        this.abilityDetailCancelButton.hideActionButton();

        this.abilityArmedDetailIcon = new NonContainerUIActionButton(
            this.scene,
            266,
            465,
            UIImageKey.CheckButton,
            UIImageKey.CheckButton,
            'Nothing',
            () => {
            }
        );
        this.abilityArmedDetailIcon.setDepth(DepthLevel.UI_PRIMARY_GRAPHICS_SUB);
        this.abilityArmedDetailIcon.hideActionButton();
    }

    private setupTexts(): void {
        this.abilityDetailText = this.scene.add.text(
            15,
            110,
            '',
            {
                fontSize: '45px',
                color: '#fff',
                fontFamily: mainGameFont,
                wordWrap: {
                    width: 500,
                    useAdvancedWrap: true
                },
                metrics: {
                    ascent: 38,
                    descent: 10,
                    fontSize: 48
                }
            }
        );
        this.abilityDetailText.setLineSpacing(-16);
        this.abilityDetailText.setResolution(3);
        this.abilityDetailText.setDepth(DepthLevel.UI_SECONDARY_TEXT);
        this.abilityDetailText.setVisible(false);

        this.abilityResourceCostText = this.scene.add.text(
            15,
            320,
            '',
            {
                fontSize: '45px',
                color: '#fff',
                fontFamily: mainGameFont,
            }
        );
        this.abilityResourceCostText.setResolution(3);
        this.abilityResourceCostText.setDepth(DepthLevel.UI_SECONDARY_TEXT);
        this.abilityResourceCostText.setVisible(false);

        this.abilityArmedCommandText = this.scene.add.text(
            244,
            510,
            'Choose A Target',
            {
                fontSize: '50px',
                color: '#fff',
                fontFamily: mainGameFont,
            }
        );
        this.abilityArmedCommandText.setDepth(DepthLevel.UI_PRIMARY_TEXT);
        this.abilityArmedCommandText.setResolution(3);
        this.abilityArmedCommandText.setVisible(false);

    }

    // Activate method updated to use the new methods
    public activate(abilities: SkillPreviewInfo[]): void {
        this.shown = true;
        console.log('[AbilityMenu] Activating Ability Menu with abilities:', abilities);
        this.dismissRelatedMenus();

        this.populateInitialView(abilities); // Set up the buttons based on abilities
        this.showInitialView(); // Then show the initial view components

        // Activate the game action bar logic
        this.scene.gameActionBar.abilityMenuButton.select();
        console.log('[AbilityMenu] Exiting activate method.');
    }

    public dismiss(): void {
        console.log('[AbilityMenu.dismiss] Dismissing Ability Menu.');

        // Section: Hide Frames
        this.abilityMainListFrame.hideFrame();
        this.abilityMainListCancelFrame.hideFrame();
        this.abilityCategoryFrame.hideFrame();
        this.abilityDetailFrame.hideFrame();
        this.abilityArmedCancelFrame.hideFrame();
        this.abilityArmedDetailFrame.hideFrame();
        this.abilityArmedCommandFrame.hideFrame();

        // Section: Process and Hide Buttons
        for (let index = 0; index < this.abilityMainListButtonInfos.length; index++) {
            const abilityButtonInfo = this.abilityMainListButtonInfos[index];
            abilityButtonInfo.button.hideActionButton();
            abilityButtonInfo.selected = false;
        }
        this.abilityMainListCancelButton.hideActionButton();
        this.abilityPageOneButton.hideActionButton();
        this.abilityDetailCancelButton.hideActionButton();
        this.abilityDetailConfirmButton.hideActionButton();
        this.abilityArmedCancelButton.hideActionButton();
        this.abilityArmedDetailIcon.hideActionButton();

        // Section: Hide Texts
        this.abilityDetailText.setVisible(false);
        this.abilityResourceCostText.setVisible(false);
        this.abilityArmedCommandText.setVisible(false);

        this.abilityIsArmed = false;
        this.scene.gameActionBar.abilityMenuButton.deselect();

        // Additional Actions
        this.activateInteractionMenuIfNeeded();
        this.shown = false;
        console.log('[AbilityMenu.dismiss] Exiting method.');
    }

}
