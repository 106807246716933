import ServerControlledGameScene from '../scenes/ServerControlledGameplayScenes/ServerControlledGameScene';

/**
 * Shows the game pad scene if conditions are met and no conflicting menus are shown.
 * @param gameScene - The current game scene instance.
 * @returns void
 */
export function showGamePadSceneIfNeeded(gameScene: ServerControlledGameScene): void {
    console.log('[showGamePadSceneIfNeeded] Checking game pad visibility conditions.');

    // First check if the game pad scene exists
    if (!gameScene.gamePadScene) {
        console.log('[showGamePadSceneIfNeeded] No game pad scene available.');
        return;
    }

    // Check if the player is a party follower
    if (gameScene.player.isPartyFollower) {
        console.log('[showGamePadSceneIfNeeded] Player is a party follower, game pad scene not shown.');
        return;
    }

    // Check for any visible menu that cannot coincide with the game pad
    const menus = gameScene.serverControlledGameUIScene.menus;
    for (const menu of menus) {
        const blocking = menu.shown && !menu.canCoincideWithGamePad;
        console.log(`[showGamePadSceneIfNeeded] Menu status - ID: ${menu.menuTag}, isShown: ${menu.shown}, canCoincideWithGamePad: ${menu.canCoincideWithGamePad}, blocking: ${blocking}`);
        if (blocking) {
            console.log('[showGamePadSceneIfNeeded] Game pad scene not shown due to a blocking menu.');
            return; // Exit early if a blocking menu is found
        }
    }

    gameScene.gamePadScene.resetJoyStickState();
    // If all conditions are met, show the game pad scene
    gameScene.gamePadScene.cameras.main.setVisible(true);
    console.log('[showGamePadSceneIfNeeded] Game pad scene shown.');
}
