import Phaser from 'phaser';
import {
    ClientFullPlayerData,
    ClientMonsterData,
    ClientTruncatedPlayerData
} from '../../../../types/conflict/ClientConflictInitState';
import {AllyManager} from '../../classes/CharactersAndPlayers/AllyManager';
import {EnemyManager} from '../../classes/CharactersAndPlayers/EnemyManager';
import ServerControlledConflictUISceneController
    from '../../classes/GameplayAndControl/ServerControlledConflictUISceneController';
import ChatManager from '../../classes/NetworkingAndChat/ChatManager';
import {AttackMenu} from '../../classes/UserInterface/MenusAndBars/ConflictElements/AttackMenu';
import {ConflictAbilityMenu} from '../../classes/UserInterface/MenusAndBars/ConflictElements/ConflictAbilityMenu';
import {ConflictCommandMenu} from '../../classes/UserInterface/MenusAndBars/ConflictElements/ConflictCommandMenu';
import {ConflictHotkeyMenu} from '../../classes/UserInterface/MenusAndBars/ConflictElements/ConflictHotkeyMenu';
import {ConflictInventoryMenu} from '../../classes/UserInterface/MenusAndBars/ConflictElements/ConflictInventoryMenu';
import {ConflictMessageElement} from '../../classes/UserInterface/MenusAndBars/ConflictElements/ConflictMessageElement';
import {ConflictPrimaryActionMenu} from '../../classes/UserInterface/MenusAndBars/ConflictElements/ConflictPrimaryActionMenu';
import {GameConflictActionMenu} from '../../classes/UserInterface/MenusAndBars/ConflictElements/GameConflictActionMenu';
import ResizableFrame from '../../classes/UserInterface/UtilityComponents/ResizableFrame';
import {mainGameFont} from '../../GameConfig';
import {ConflictSceneData} from '../../types/ConflictSceneData';
import {DepthLevel} from '../../types/DepthLevel';
import {SceneNames} from '../../types/SceneNames';
import ServerControlledConflictScene from '../ServerControlledGameplayScenes/ServerControlledConflictScene';

export default class ServerControlledConflictUIScene extends Phaser.Scene {
    public controller: ServerControlledConflictUISceneController;
    public roundTimerCountdownText: Phaser.GameObjects.Text;
    public roundTimerFrame: ResizableFrame;
    public roundTimerLabelText: Phaser.GameObjects.Text;

    public conflictScene: ServerControlledConflictScene;
    public menus: GameConflictActionMenu[] = [];
    public attackMenu: AttackMenu;
    public primaryActionMenu: ConflictPrimaryActionMenu;
    public hotkeyMenu: ConflictHotkeyMenu;
    public abilityMenu: ConflictAbilityMenu;
    public commandMenu: ConflictCommandMenu;
    public messageElement: ConflictMessageElement;
    public inventoryMenu: ConflictInventoryMenu;
    public conflictUIGameOverBlackRectangle: Phaser.GameObjects.Rectangle;
    public enemyManager: EnemyManager;
    public allyManager: AllyManager;
    private phaserKeys: { [key: string]: Phaser.Input.Keyboard.Key } = {};

    public waitingForServerResponse: boolean = false;

    public keyCodes = {
        ONE: Phaser.Input.Keyboard.KeyCodes.ONE,
        TWO: Phaser.Input.Keyboard.KeyCodes.TWO,
        THREE: Phaser.Input.Keyboard.KeyCodes.THREE,
        FOUR: Phaser.Input.Keyboard.KeyCodes.FOUR,
        FIVE: Phaser.Input.Keyboard.KeyCodes.FIVE,
        SIX: Phaser.Input.Keyboard.KeyCodes.SIX,
        SEVEN: Phaser.Input.Keyboard.KeyCodes.SEVEN,
        EIGHT: Phaser.Input.Keyboard.KeyCodes.EIGHT,
        NINE: Phaser.Input.Keyboard.KeyCodes.NINE,
        ZERO: Phaser.Input.Keyboard.KeyCodes.ZERO,
        MINUS: Phaser.Input.Keyboard.KeyCodes.MINUS,
        EQUAL: Phaser.Input.Keyboard.KeyCodes.PLUS, // Adjust if there's a more appropriate key code
    };

    public constructor() {
        super(SceneNames.ServerControlledConflictUI);
    }

    public create(): void {
        console.log('[ServerControlledConflictUIScene.create] Starting to create the conflict UI scene.');

        // logic to be done at GAME START
        // step one: get a reference to the conflict scene
        this.conflictScene = this.scene.get(SceneNames.ServerControlledConflict) as ServerControlledConflictScene;

        // step two: instantiate the primary action menu (the menu that shows attack,
        // ability, inventory, and flee buttons) and add it to the menus array
        this.primaryActionMenu = new ConflictPrimaryActionMenu(this);
        this.menus.push(this.primaryActionMenu);

        // step three: instantiate the attack menu and add it to the menus array
        this.attackMenu = new AttackMenu(this);
        this.menus.push(this.attackMenu);

        // step four: instantiate the hotkey menu and add it to the menus array
        this.hotkeyMenu = new ConflictHotkeyMenu(this);
        this.menus.push(this.hotkeyMenu);

        // step five: instantiate the ability menu and add it to the menus array
        this.abilityMenu = new ConflictAbilityMenu(this);
        this.menus.push(this.abilityMenu);

        // step six: instantiate the command menu and add it to the menus array
        this.commandMenu = new ConflictCommandMenu(this);
        this.menus.push(this.commandMenu);

        // step seven: instantiate the message element and add it to the menus array
        this.messageElement = new ConflictMessageElement(this);
        this.menus.push(this.messageElement);

        // step eight: instantiate the inventory menu and add it to the menus array
        this.inventoryMenu = new ConflictInventoryMenu(this);
        this.menus.push(this.inventoryMenu);

        // step nine: create the frames
        this.createFrames();

        // step ten: create the texts
        this.createTexts();

        // step eleven: instantiate the ally manager
        this.allyManager = new AllyManager(this);

        // step twelve: instantiate the enemy manager
        this.enemyManager = new EnemyManager(this);

        // step thirteen: initialize chat input listeners
        this.initializeChatInputListeners();

        // step fourteen: set the camera to not visible
        this.cameras.main.setVisible(false);

        // step fifteen: set the conflict UI scene to loaded in the game scene
        this.conflictScene.gameScene.setSceneLoaded('conflictUiScene');
    }

    public activate(data: ConflictSceneData): void {
        // logic to be done at CONFLICT START

        // Step one: clear the game over black rectangle if it exists
        if (this.conflictUIGameOverBlackRectangle) {
            this.conflictUIGameOverBlackRectangle.destroy();
            console.log('[LOG] Destroyed the game over black rectangle.');
        }

        // step two: activate the primary action menu
        this.primaryActionMenu.activate();

        // step three: activate the hotkey menu
        this.hotkeyMenu.activate();

        // step four: activate show player frames according to the number of allies
        //  in the event data
        this.allyManager.updateAllyFramesVisibility(data);

        // step five: populate the texts with the player data
        this.populateTexts(data);

        // step six: populate the ally texts with the player data
        this.allyManager.populateAllyTexts(data);

        // step seven: populate the player component sprites with the player data
        this.allyManager.populateAllyComponentSprites(data);

        // step eight: create the enemy sprites
        this.enemyManager.initializeAndPositionEnemySprites(data);

        // step nine: populate the hotkey menu with the hotkey assignments
        this.hotkeyMenu.populateHotkeyMenu(data.hotkeyAssignments);

        // step ten: bring the conflict UI scene to the top
        this.scene.bringToTop(SceneNames.ServerControlledConflictUI);

        // step eleven: call the start conflict method on the controller
        this.controller.startConflict(data);

        // step twelve: make the scene's camera visible
        this.cameras.main.setVisible(true);

        // step thirteen: set up the phaser key listeners
        this.setupPhaserKeyListeners();

    }

    private setupPhaserKeyListeners(): void {
        // First, ensure any existing listeners are removed to prevent duplicates
        this.removePhaserKeyListeners();

        this.phaserKeys = {};

        Object.entries(this.keyCodes).forEach(([name, keyCode]) => {
            this.addKeyListener(name, keyCode);
        });
    }

    private keyNameToSlotNumber(keyName: string): number | undefined {
        // Define a mapping from key names to slot numbers
        const keyToSlotMapping: { [key: string]: number } = {
            ONE: 1, TWO: 2, THREE: 3, FOUR: 4, FIVE: 5,
            SIX: 6, SEVEN: 7, EIGHT: 8, NINE: 9, ZERO: 10,
            MINUS: 11, EQUAL: 12
        };

        return keyToSlotMapping[keyName];
    }

    private addKeyListener(name: string, keyCode: number): void {
        this.phaserKeys[name] = this.input.keyboard!.addKey(keyCode);
        this.phaserKeys[name].on('down', () => {
            // Checking if the currently focused element is an input or textarea
            if (document.activeElement === ChatManager.chatInput) {
                console.log('[ServerControlledConflictUIScene.addKeyListener] Chat input is focused, skipping Phaser handling.');
                return;
            }

            if (Phaser.Input.Keyboard.JustDown(this.phaserKeys[name])) {
                console.log(`[ServerControlledConflictUIScene.addKeyListener] Conflict hotkey menu key pressed: ${name}`);
                // Convert key name to slot number and handle the button press
                const pressedHotkeyNumber = this.keyNameToSlotNumber(name);
                console.log(`[ServerControlledConflictUIScene.addKeyListener] hotkey number pressed ${pressedHotkeyNumber}`);
                console.log(`[ServerControlledConflictUIScene.addKeyListener] Hotkey menu shown: ${this.hotkeyMenu.shown}`);
                if (pressedHotkeyNumber !== undefined && this.hotkeyMenu.shown) {
                    console.log('[ServerControlledConflictUIScene.addKeyListener] pressed hotkey is not undefined and hotkey menu is shown');
                    console.log(`[ServerControlledConflictUIScene.addKeyListener] calling this.hotkeyMenu.handleButtonPress() with pressed hotkey number ${pressedHotkeyNumber}`);
                    this.hotkeyMenu.handleButtonPress(pressedHotkeyNumber);
                } else if (this.enemyManager.isTargetingModeActive && pressedHotkeyNumber !== undefined) {
                    console.log('[ServerControlledConflictUIScene.addKeyListener] this.enemyManager.isTargetingModeActive is truthy and pressedHotkeyNumber is not undefined');
                    // Get the count of living enemies
                    console.log('[ServerControlledConflictUIScene.addKeyListener] filtering enemy components by the ones that are alive and getting the length of that array');
                    const livingEnemiesCount = this.enemyManager.enemyComponents.filter(enemy => enemy.isAlive).length;
                    console.log(`[ServerControlledConflictUIScene.addKeyListener] livingEnemiesCount: ${livingEnemiesCount}`);

                    // Check if the slot number is within the range of living enemies
                    if (pressedHotkeyNumber <= livingEnemiesCount) {
                        console.log(`[ServerControlledConflictUIScene.addKeyListener] pressedHotkeyNumber ${pressedHotkeyNumber}, livingEnemiesCount: ${livingEnemiesCount}`);
                        this.enemyManager.handleEnemySelect(pressedHotkeyNumber);
                    } else {
                        console.log(`Invalid slot number: ${pressedHotkeyNumber}. No living enemy at this slot.`);
                    }
                } else if (this.allyManager.isTargetingModeActive && pressedHotkeyNumber !== undefined) {
                    // Get the count of allies that exist (regardless of whether they are alive or not)
                    const existingAlliesCount = this.allyManager.allyComponents.filter(ally => ally.exists).length;

                    // Check if the slot number is within the range of existing allies
                    if (pressedHotkeyNumber <= existingAlliesCount) {
                        this.allyManager.handleAllySelect(pressedHotkeyNumber);
                    } else {
                        console.log(`Invalid slot number: ${pressedHotkeyNumber}. No ally exists at this slot.`);
                    }
                }

            }
        });
    }

    public removePhaserKeyListeners(): void {
        for (const key of Object.values(this.phaserKeys)) {
            if (key) {
                this.input.keyboard!.removeKey(key);
            }
        }
        this.phaserKeys = {};
    }

    private initializeChatInputListeners(): void {
        // Assuming ChatManager.chatInput is already a reference to your chat input element
        ChatManager.chatInput.addEventListener('focus', this.disableKeyCaptures.bind(this));
        ChatManager.chatInput.addEventListener('blur', this.enableKeyCaptures.bind(this));
    }

    private disableKeyCaptures(): void {
        Object.values(this.keyCodes).forEach((keyCode) => {
            this.input.keyboard!.removeCapture(keyCode);
        });
        console.log('Key captures disabled for chat input focus.');
    }

    private enableKeyCaptures(): void {
        Object.values(this.keyCodes).forEach((keyCode) => {
            this.input.keyboard!.addCapture(keyCode);
        });
        console.log('Key captures re-enabled after chat input blur.');
    }

    public setController(controller: ServerControlledConflictUISceneController): void {
        console.log('[ServerControlledConflictUIScene.setController] Setting the controller for the conflict UI scene.');
        this.controller = controller;
    }

    private createFrames(): void {
        if (!this.conflictScene.isSinglePlayerPVE) {
            this.roundTimerFrame = new ResizableFrame(this, 101, 46, 190, 80);
            this.roundTimerFrame.hideFrame();
            // this.allyArmedActionsFrame = new ResizableFrame(this, 558, 91, 693, 170);
            // this.allyArmedActionsFrame.hideFrame();
        }
    }

    private createTexts(): void {
        // Round timer label text, always created
        this.roundTimerLabelText = this.add.text(9, 5, 'Round Timer', {
            fontSize: '40px',
            color: '#fff',
            fontFamily: mainGameFont
        })
            .setResolution(3)
            .setDepth(DepthLevel.UI_PRIMARY_TEXT)
            .setVisible(false); // Initially hide text

        // Round timer countdown text, always created
        this.roundTimerCountdownText = this.add.text(102, 33, '', {
            fontSize: '55px',
            color: '#fff',
            fontFamily: mainGameFont
        })
            .setResolution(3)
            .setOrigin(0.5, 0)
            .setDepth(DepthLevel.UI_PRIMARY_TEXT)
            .setVisible(false); // Initially hide text
    }

    private populateTexts(param: ConflictSceneData): void {
        // Round timer texts update with visibility conditioned on the game mode
        if (!this.conflictScene.isSinglePlayerPVE) {
            const initialRoundTimerCountdownText = param.roundTimer ? param.roundTimer.toString() : '20';
            this.roundTimerCountdownText.setText(`-${initialRoundTimerCountdownText}-`).setVisible(true);
            this.roundTimerLabelText.setVisible(true);
        } else {
            // If it is single-player PVE, ensure these texts remain hidden
            this.roundTimerCountdownText.setVisible(false);
            this.roundTimerLabelText.setVisible(false);
        }
    }

    public isTruncatedPlayerData(obj: ClientFullPlayerData | ClientTruncatedPlayerData | ClientMonsterData): obj is ClientTruncatedPlayerData {
        return obj.Type === 'TruncatedPlayer';
    }

    public canProceedWithMenuAction(): boolean {

        if (this.waitingForServerResponse) {
            console.log('[Scene] Player is waiting for a server response. Exiting early.');
            return false;
        }

        return true; // Conditions are met, action can proceed
    }
}
