import numberToWords from 'number-to-words';
import BBCodeText from 'phaser3-rex-plugins/plugins/bbcodetext';
import {Socket} from 'socket.io-client';
import {UIImageKey} from '../../../../../../types/assets/AssetKeys';
import {ServerSocketEvents} from '../../../../../../types/events/ServerSocketEvents';
import {ItemType} from '../../../../../../types/items/ItemTypes';
import {CharacterProfession} from '../../../../../../types/mechanics/CharacterProfessionsEnum';
import {statAbbreviations} from '../../../../../../types/mechanics/StatAbbreviations';
import {
    UpdateRemoteTradePartnerOfferedItemsEvent
} from '../../../../../../types/trade/UpdateRemoteTradePartnerOfferedItemsEvent';
import {mainGameFont} from '../../../../GameConfig';
import ServerControlledGameUIScene from '../../../../scenes/ServerControlledUIScenes/ServerControlledGameUIScene';
import {DepthLevel} from '../../../../types/DepthLevel';
import {FieldMenuType} from '../../../../types/FieldMenuType';
import {adjustFontSizeByPixel} from '../../../../utils/phaserTextUtils';
import NonContainerUIActionButton from '../../ActionButtons/NonContainerUIActionButton';
import ResizableFrame from '../../UtilityComponents/ResizableFrame';
import {GameFieldActionMenu} from './GameFieldActionMenu';

export class TradeMenu extends GameFieldActionMenu {
    public readonly menuTag: FieldMenuType = FieldMenuType.TradeMenu;
    public relatedMenusToDismiss: Set<FieldMenuType> = new Set([
        FieldMenuType.CharacterSheetMenu,
        FieldMenuType.TargetMenu,
        FieldMenuType.DuelNotification,
        FieldMenuType.DismissibleNotification,
        FieldMenuType.InviteNotification,
        FieldMenuType.PartyOrderMenu,
        FieldMenuType.CommonerNPCMenu,
        FieldMenuType.InnkeeperNPCMenu,
        FieldMenuType.MerchantNPCMenu,
        FieldMenuType.MessageElement,
        FieldMenuType.AbilityMenu,
        FieldMenuType.InventoryMenu,
        FieldMenuType.InteractableMessageDisplay,

        FieldMenuType.InteractionMenu
    ]);
    public canCoincideWithGamePad = false;

    public inventoryMainListButtonInfos: {
        button: NonContainerUIActionButton;
        exists: boolean;
        selected: boolean
    }[] = [];

    private inventoryMainListAndGoldCalculatorFrame: ResizableFrame;
    private inventoryGoldFrame: ResizableFrame;
    private coinIcon: Phaser.GameObjects.Image;
    private goldText: Phaser.GameObjects.Text;
    private tradeCategoryFrame: ResizableFrame;
    private bagButton: NonContainerUIActionButton;
    private goldButton: NonContainerUIActionButton;
    private tradeItemDetailAndCommandFrame: ResizableFrame;
    private tradeOffersFrame: ResizableFrame;
    private primaryTradeOfferButton: NonContainerUIActionButton;
    private secondaryTradeOfferButton: NonContainerUIActionButton;
    private clientPlayerNameText: Phaser.GameObjects.Text;
    private tradeOffersVerticalDivider: Phaser.GameObjects.Graphics;
    private remotePlayerNameText: Phaser.GameObjects.Text;
    public clientPlayerOfferedItemButtons: NonContainerUIActionButton[] = [];
    private clientPlayerOfferedGoldText: Phaser.GameObjects.Text;
    public clientPlayerTradeStatusText: BBCodeText;
    private remotePlayerOfferedItemButtons: NonContainerUIActionButton[] = [];
    private remotePlayerOfferedGoldText: Phaser.GameObjects.Text;
    public remotePlayerTradeStatusText: BBCodeText;
    public commandText: Phaser.GameObjects.Text;
    // public itemDetailText: Phaser.GameObjects.Text;
    public offerAndRescindSelectedClientPlayerItemButton: NonContainerUIActionButton;
    private selectedClientPlayerItemIndex: number;
    private selectedRemotePlayerItemIndex: number;
    private currentMode: 'bag' | 'gold' = 'bag'; // Default to 'bag'
    private clientMaxGold: number;
    private onesDigitText: Phaser.GameObjects.Text;
    private tensDigitText: Phaser.GameObjects.Text;
    private hundredsDigitText: Phaser.GameObjects.Text;
    private thousandsDigitText: Phaser.GameObjects.Text;
    private tenThousandsDigitText: Phaser.GameObjects.Text;
    private hundredThousandsDigitText: Phaser.GameObjects.Text;
    private digitTextYPositions: Map<string, number> = new Map([
        ['ones', 220],
        ['tens', 270], // Increased by 50 from 220
        ['hundreds', 320], // Increased by 50 from 270
        ['thousands', 370], // Increased by 50 from 320
        ['tenThousands', 420], // Increased by 50 from 370
        ['hundredThousands', 470] // Increased by 50 from 420
    ]);

    // Properties for the decrement buttons
    private onesDecrementButton: NonContainerUIActionButton;
    private tensDecrementButton: NonContainerUIActionButton;
    private hundredsDecrementButton: NonContainerUIActionButton;
    private thousandsDecrementButton: NonContainerUIActionButton;
    private tenThousandsDecrementButton: NonContainerUIActionButton;
    private hundredThousandsDecrementButton: NonContainerUIActionButton;

    // Properties for increment buttons
    private onesIncrementButton: NonContainerUIActionButton;
    private tensIncrementButton: NonContainerUIActionButton;
    private hundredsIncrementButton: NonContainerUIActionButton;
    private thousandsIncrementButton: NonContainerUIActionButton;
    private tenThousandsIncrementButton: NonContainerUIActionButton;
    private hundredThousandsIncrementButton: NonContainerUIActionButton;

    public goldOfferAmount: number = 0; // Holds the current offer amount
    public goldOfferText: Phaser.GameObjects.Text;
    private offerGoldButton: NonContainerUIActionButton;

    private clientTradeStatus: 'Pending' | 'Accepted' = 'Pending';

    public defaultCommandText = 'You\'re in the Trade Menu.\n- Select "Bag" to view items you can offer for trade.\n- Select "Gold" to set an amount of gold to offer.\n- Both players\' trade offers appear above.\n- Click "Accept" to confirm the trade or "Cancel" to exit.';
    public remoteItemDetailShown: boolean;

    public inventoryDetailNameText: Phaser.GameObjects.Text;
    public inventoryDetailTypeText: Phaser.GameObjects.Text;
    public inventoryDetailDescriptionText: Phaser.GameObjects.Text;
    public inventoryDetailMinimumLevelText: Phaser.GameObjects.Text;
    public bindingStatusText: Phaser.GameObjects.Text;
    public classesText: Phaser.GameObjects.Text;
    private stat1Label: Phaser.GameObjects.Text;
    private stat1Value: Phaser.GameObjects.Text;
    private stat2Label: Phaser.GameObjects.Text;
    private stat2Value: Phaser.GameObjects.Text;
    private stat3Label: Phaser.GameObjects.Text;
    private stat3Value: Phaser.GameObjects.Text;
    private stat4Label: Phaser.GameObjects.Text;
    private stat4Value: Phaser.GameObjects.Text;
    private stat5Label: Phaser.GameObjects.Text;
    private stat5Value: Phaser.GameObjects.Text;
    private stat6Label: Phaser.GameObjects.Text;
    private stat6Value: Phaser.GameObjects.Text;
    private statLabels: Phaser.GameObjects.Text[];
    private statValues: Phaser.GameObjects.Text[];
    private statsMap: Map<string, number> = new Map();
    public inventoryVerticalDivider: Phaser.GameObjects.Graphics;

    public constructor(public scene: ServerControlledGameUIScene, private socket: Socket) {
        super();
        console.log('TradeMenu created');
        this.setupFrames();
        this.setupIcons();
        this.setupTexts();
        this.setupButtons();
        this.setupGraphics();
    }

    public activate(params: {
        items: {
            name: string,
            key: UIImageKey,
            activeKey: UIImageKey
        }[],
        gold: number,
        clientPlayerName: string,
        remotePlayerName: string
    }): void {
        console.log(`[TradeMenu.activate] Activating TradeMenu with params: ${JSON.stringify(params, null, 2)}.`);
        this.dismissRelatedMenus();
        this.populateInitialView(params);
        this.showInitialView();
        this.shown = true;
    }

    public dismiss(): void {
        console.log('[TradeMenu.dismiss] Dismissing TradeMenu.');

        // Hide all frames
        this.inventoryMainListAndGoldCalculatorFrame.hideFrame();
        this.inventoryGoldFrame.hideFrame();
        this.tradeCategoryFrame.hideFrame();
        this.tradeItemDetailAndCommandFrame.hideFrame();
        this.tradeOffersFrame.hideFrame();

        // Hide all action buttons
        this.bagButton.hideActionButton();
        this.goldButton.hideActionButton();
        this.primaryTradeOfferButton.hideActionButton();
        this.secondaryTradeOfferButton.hideActionButton();
        this.offerAndRescindSelectedClientPlayerItemButton.hideActionButton();
        this.onesDecrementButton.hideActionButton();
        this.onesIncrementButton.hideActionButton();
        this.tensDecrementButton.hideActionButton();
        this.tensIncrementButton.hideActionButton();
        this.hundredsDecrementButton.hideActionButton();
        this.hundredsIncrementButton.hideActionButton();
        this.thousandsDecrementButton.hideActionButton();
        this.thousandsIncrementButton.hideActionButton();
        this.tenThousandsDecrementButton.hideActionButton();
        this.tenThousandsIncrementButton.hideActionButton();
        this.hundredThousandsDecrementButton.hideActionButton();
        this.hundredThousandsIncrementButton.hideActionButton();
        this.offerGoldButton.hideActionButton();

        // Hide all client and remote player offered item buttons
        this.clientPlayerOfferedItemButtons.forEach(button => button.hideActionButton());
        this.remotePlayerOfferedItemButtons.forEach(button => button.hideActionButton());

        // hide all the main inventory list buttons
        this.inventoryMainListButtonInfos.forEach(buttonInfo => {
            buttonInfo.button.hideActionButton();
        });

        // Hide all texts
        this.coinIcon.setVisible(false);
        this.goldText.setVisible(false);
        this.clientPlayerNameText.setVisible(false);
        this.remotePlayerNameText.setVisible(false);
        this.clientPlayerOfferedGoldText.setVisible(false);
        this.remotePlayerOfferedGoldText.setVisible(false);
        this.clientPlayerTradeStatusText.setVisible(false);
        this.remotePlayerTradeStatusText.setVisible(false);
        this.commandText.setVisible(false);
        // this.itemDetailText.setVisible(false);
        this.inventoryDetailNameText.setVisible(false);
        this.inventoryDetailTypeText.setVisible(false);
        this.inventoryDetailDescriptionText.setVisible(false);
        this.inventoryDetailMinimumLevelText.setVisible(false);
        this.bindingStatusText.setVisible(false);
        this.classesText.setVisible(false);
        this.inventoryVerticalDivider.setVisible(false);
        this.hideStats();
        this.onesDigitText.setVisible(false);
        this.tensDigitText.setVisible(false);
        this.hundredsDigitText.setVisible(false);
        this.thousandsDigitText.setVisible(false);
        this.tenThousandsDigitText.setVisible(false);
        this.hundredThousandsDigitText.setVisible(false);
        this.goldOfferText.setVisible(false);

        this.remoteItemDetailShown = false;

        // Hide graphics
        this.tradeOffersVerticalDivider.setVisible(false);

        // reset variable props
        this.goldOfferAmount = 0;
        this.clientTradeStatus = 'Pending';
        this.selectedClientPlayerItemIndex = -1;
        this.selectedRemotePlayerItemIndex = -1;

        this.activateInteractionMenuIfNeeded();
        this.shown = false;
    }

    public emitMenuActivationRequest(params: {
        items: {
            name: string,
            key: UIImageKey,
            activeKey: UIImageKey
        }[],
        gold: number,
        clientPlayerName: string,
        remotePlayerName: string
    }): void {
        console.log(`[TradeMenu.emitMenuActivationRequest] entering TradeMenu activation request with params: ${JSON.stringify(params, null, 2)}.`);
        this.activate(params);
    }

    public emitMenuDismissalRequest(): void {
        console.log('[TradeMenu.emitMenuDismissalRequest] entering TradeMenu dismissal request.');
        this.dismiss();
    }

    private setupFrames(): void {
        console.log('[TradeMenu.setupFrames] Setting up TradeMenu frames.');
        this.inventoryMainListAndGoldCalculatorFrame = new ResizableFrame(this.scene, 720, 392, 371, 409);
        this.inventoryMainListAndGoldCalculatorFrame.hideFrame();

        this.inventoryGoldFrame = new ResizableFrame(this.scene, 720, 144, 371, 50);
        this.inventoryGoldFrame.hideFrame();

        this.tradeCategoryFrame = new ResizableFrame(this.scene, 720, 70, 371, 64);
        this.tradeCategoryFrame.hideFrame();

        // Detail View Frame
        this.tradeItemDetailAndCommandFrame = new ResizableFrame(this.scene, 263, 442, 512, 308, 2);
        this.tradeItemDetailAndCommandFrame.hideFrame();

        this.tradeOffersFrame = new ResizableFrame(this.scene, 263, 154, 512, 231, 2);
        this.tradeOffersFrame.hideFrame();

    }

    private setupButtons(): void {
        console.log('[TradeMenu.setupButtons] Setting up TradeMenu buttons.');
        this.initializeInventoryButtonInfos();

        // Create 'Bag' button
        this.bagButton = new NonContainerUIActionButton(
            this.scene,
            564,
            70,
            UIImageKey.BagButton,
            UIImageKey.BagButtonActive,
            'Bag',
            () => {
                console.log('[TradeMenu.setupButtons] Clicked on bag button.');
                // Button click logic...
                if (this.currentMode === 'bag') {
                    console.log('[TradeMenu.setupButtons] Already in bag mode. Exiting.');
                    return;
                }
                console.log('[TradeMenu.setupButtons] Switching to bag mode.');
                this.switchToBagMode();
            }
        );
        this.bagButton.hideActionButton();
        this.bagButton.setDepth(DepthLevel.UI_PRIMARY_GRAPHICS_SUB);

        // Create 'Gold' button
        this.goldButton = new NonContainerUIActionButton(
            this.scene,
            720,
            70,
            UIImageKey.CoinButton,
            UIImageKey.CoinButtonActive,
            'Gold',
            () => {
                console.log('[TradeMenu.setupButtons] Clicked on gold button.');
                // Button click logic...
                if (this.currentMode === 'gold') {
                    console.log('[TradeMenu.setupButtons] Already in gold mode. Exiting.');
                    return;
                }
                console.log('[TradeMenu.setupButtons] Switching to gold mode.');
                this.switchToGoldMode();
            }
        );
        this.goldButton.hideActionButton();
        this.goldButton.setDepth(DepthLevel.UI_PRIMARY_GRAPHICS_SUB);

        this.primaryTradeOfferButton = new NonContainerUIActionButton(
            this.scene,
            35,
            244,
            UIImageKey.CheckButton,
            UIImageKey.CheckButton,
            'Accept',
            () => {
                // Button click logic...
                console.log('[TradeMenu.setupButtons] Clicked on primary trade offer button.');
                if (this.clientTradeStatus === 'Accepted') {
                    console.log('[TradeMenu.setupButtons] Resetting trade offer status to Pending after a previous change.');
                    this.socket.emit(ServerSocketEvents.AttemptRevertTradeOfferToPending);

                } else if (this.clientTradeStatus === 'Pending') {
                    console.log('[TradeMenu.setupButtons] Accepting trade offer.');
                    this.socket.emit(ServerSocketEvents.AttemptAcceptTradeOffer);
                }
            }
        );
        this.primaryTradeOfferButton.setDepth(DepthLevel.UI_SECONDARY_GRAPHICS_SUB);
        this.primaryTradeOfferButton.hideActionButton();

        this.secondaryTradeOfferButton = new NonContainerUIActionButton(
            this.scene,
            335,
            244,
            UIImageKey.CrossButton,
            UIImageKey.CrossButton,
            'Cancel',
            () => {
                // Button click logic...
                console.log('[TradeMenu.setupButtons] Clicked on secondary trade offer button.');

                // emit event to cancel trade offer
                this.socket.emit(ServerSocketEvents.AttemptCancelTradeOffer);
            }
        );
        this.secondaryTradeOfferButton.setDepth(DepthLevel.UI_SECONDARY_GRAPHICS_SUB);
        this.secondaryTradeOfferButton.hideActionButton();

        this.setupClientPlayerOfferedItems();
        this.setupRemotePlayerOfferedItems();

        this.offerAndRescindSelectedClientPlayerItemButton = new NonContainerUIActionButton(
            this.scene,
            35,
            568,
            UIImageKey.CheckButton,
            UIImageKey.CheckButton,
            'Offer',
            () => {
                // Button click logic...
                console.log('[TradeMenu.setupButtons] Clicked on offer selected client player item button.');
                console.log(`[TradeMenu.setupButtons] Selected client player item index: ${this.selectedClientPlayerItemIndex}.`);
                const eventData = {selectedInventoryItemForTradeIndex: this.selectedClientPlayerItemIndex};
                this.socket.emit(ServerSocketEvents.AttemptOfferSelectedInventoryItemForTrade, eventData);
            }
        );
        this.offerAndRescindSelectedClientPlayerItemButton.setDepth(DepthLevel.UI_SECONDARY_GRAPHICS_SUB);
        this.offerAndRescindSelectedClientPlayerItemButton.hideActionButton();

        const onesTextWidth = this.onesDigitText.width;
        const onesDecrementButtonX = this.onesDigitText.x - onesTextWidth / 2 - 30; // Left of the text
        const onesIncrementButtonX = this.onesDigitText.x + onesTextWidth / 2 + 30; // Right of the text
        const onesButtonY = this.digitTextYPositions.get('ones')!;

        this.onesDecrementButton = new NonContainerUIActionButton(
            this.scene,
            onesDecrementButtonX,
            onesButtonY - 3,
            UIImageKey.MinusButton,
            UIImageKey.MinusButton,
            '',
            () => {
                console.log('[TradeMenu.setupButtons] Clicked on ones decrement button.');
                this.updateGoldAmount(-1);
            }
        );
        this.onesIncrementButton = new NonContainerUIActionButton(
            this.scene,
            onesIncrementButtonX,
            onesButtonY - 3,
            UIImageKey.PlusButton,
            UIImageKey.PlusButton,
            '',
            () => {
                console.log('[TradeMenu.setupButtons] Clicked on ones increment button.');
                this.updateGoldAmount(1);
            }
        );
        this.onesDecrementButton.hideActionButton();
        this.onesIncrementButton.hideActionButton();
        this.onesDecrementButton.setDepth(DepthLevel.UI_PRIMARY_GRAPHICS_SUB);
        this.onesIncrementButton.setDepth(DepthLevel.UI_PRIMARY_GRAPHICS_SUB);

        // Setup for 'Tens' Buttons
        const tensTextWidth = this.tensDigitText.width;
        const tensDecrementButtonX = this.tensDigitText.x - tensTextWidth / 2 - 30; // Left of the text
        const tensIncrementButtonX = this.tensDigitText.x + tensTextWidth / 2 + 30; // Right of the text
        const tensButtonY = this.digitTextYPositions.get('tens')!;

        this.tensDecrementButton = new NonContainerUIActionButton(
            this.scene,
            tensDecrementButtonX,
            tensButtonY - 3,
            UIImageKey.MinusButton,
            UIImageKey.MinusButton,
            '',
            () => {
                console.log('[TradeMenu.setupButtons] Clicked on tens decrement button.');
                this.updateGoldAmount(-10);
            }
        );
        this.tensIncrementButton = new NonContainerUIActionButton(
            this.scene,
            tensIncrementButtonX,
            tensButtonY - 3,
            UIImageKey.PlusButton,
            UIImageKey.PlusButton,
            '',
            () => {
                console.log('[TradeMenu.setupButtons] Clicked on tens increment button.');
                this.updateGoldAmount(10);
            }
        );
        this.tensDecrementButton.hideActionButton();
        this.tensIncrementButton.hideActionButton();
        this.tensDecrementButton.setDepth(DepthLevel.UI_PRIMARY_GRAPHICS_SUB);
        this.tensIncrementButton.setDepth(DepthLevel.UI_PRIMARY_GRAPHICS_SUB);

        // Setup for 'Hundreds' Buttons
        const hundredsTextWidth = this.hundredsDigitText.width;
        const hundredsDecrementButtonX = this.hundredsDigitText.x - hundredsTextWidth / 2 - 30;
        const hundredsIncrementButtonX = this.hundredsDigitText.x + hundredsTextWidth / 2 + 30;
        const hundredsButtonY = this.digitTextYPositions.get('hundreds')!;

        this.hundredsDecrementButton = new NonContainerUIActionButton(
            this.scene,
            hundredsDecrementButtonX,
            hundredsButtonY - 3,
            UIImageKey.MinusButton,
            UIImageKey.MinusButton,
            '',
            () => {
                console.log('[TradeMenu.setupButtons] Clicked on hundreds decrement button.');
                this.updateGoldAmount(-100);
            }
        );
        this.hundredsIncrementButton = new NonContainerUIActionButton(
            this.scene,
            hundredsIncrementButtonX,
            hundredsButtonY - 3,
            UIImageKey.PlusButton,
            UIImageKey.PlusButton,
            '',
            () => {
                console.log('[TradeMenu.setupButtons] Clicked on hundreds increment button.');
                this.updateGoldAmount(100);
            }
        );
        this.hundredsDecrementButton.hideActionButton();
        this.hundredsIncrementButton.hideActionButton();
        this.hundredsDecrementButton.setDepth(DepthLevel.UI_PRIMARY_GRAPHICS_SUB);
        this.hundredsIncrementButton.setDepth(DepthLevel.UI_PRIMARY_GRAPHICS_SUB);

        // Setup for 'Thousands' Buttons
        const thousandsTextWidth = this.thousandsDigitText.width;
        const thousandsDecrementButtonX = this.thousandsDigitText.x - thousandsTextWidth / 2 - 30; // Left of the text
        const thousandsIncrementButtonX = this.thousandsDigitText.x + thousandsTextWidth / 2 + 30; // Right of the text
        const thousandsButtonY = this.digitTextYPositions.get('thousands')!;

        this.thousandsDecrementButton = new NonContainerUIActionButton(
            this.scene,
            thousandsDecrementButtonX,
            thousandsButtonY - 3,
            UIImageKey.MinusButton,
            UIImageKey.MinusButton,
            '',
            () => {
                console.log('[TradeMenu.setupButtons] Clicked on thousands decrement button.');
                this.updateGoldAmount(-1000);
            }
        );
        this.thousandsIncrementButton = new NonContainerUIActionButton(
            this.scene,
            thousandsIncrementButtonX,
            thousandsButtonY - 3,
            UIImageKey.PlusButton,
            UIImageKey.PlusButton,
            '',
            () => {
                console.log('[TradeMenu.setupButtons] Clicked on thousands increment button.');
                this.updateGoldAmount(1000);
            }
        );
        this.thousandsDecrementButton.hideActionButton();
        this.thousandsIncrementButton.hideActionButton();
        this.thousandsDecrementButton.setDepth(DepthLevel.UI_PRIMARY_GRAPHICS_SUB);
        this.thousandsIncrementButton.setDepth(DepthLevel.UI_PRIMARY_GRAPHICS_SUB);

        // Setup for 'Ten Thousands' Buttons
        const tenThousandsTextWidth = this.tenThousandsDigitText.width;
        const tenThousandsDecrementButtonX = this.tenThousandsDigitText.x - tenThousandsTextWidth / 2 - 30; // Left of the text
        const tenThousandsIncrementButtonX = this.tenThousandsDigitText.x + tenThousandsTextWidth / 2 + 30; // Right of the text
        const tenThousandsButtonY = this.digitTextYPositions.get('tenThousands')!;

        this.tenThousandsDecrementButton = new NonContainerUIActionButton(
            this.scene,
            tenThousandsDecrementButtonX,
            tenThousandsButtonY - 3,
            UIImageKey.MinusButton,
            UIImageKey.MinusButton,
            '',
            () => {
                console.log('[TradeMenu.setupButtons] Clicked on ten thousands decrement button.');
                this.updateGoldAmount(-10000);
            }
        );
        this.tenThousandsIncrementButton = new NonContainerUIActionButton(
            this.scene,
            tenThousandsIncrementButtonX,
            tenThousandsButtonY - 3,
            UIImageKey.PlusButton,
            UIImageKey.PlusButton,
            '',
            () => {
                console.log('[TradeMenu.setupButtons] Clicked on ten thousands increment button.');
                this.updateGoldAmount(10000);
            }
        );
        this.tenThousandsDecrementButton.hideActionButton();
        this.tenThousandsIncrementButton.hideActionButton();
        this.tenThousandsDecrementButton.setDepth(DepthLevel.UI_PRIMARY_GRAPHICS_SUB);
        this.tenThousandsIncrementButton.setDepth(DepthLevel.UI_PRIMARY_GRAPHICS_SUB);

        // Setup for 'Hundred Thousands' Buttons
        const hundredThousandsTextWidth = this.hundredThousandsDigitText.width;
        const hundredThousandsDecrementButtonX = this.hundredThousandsDigitText.x - hundredThousandsTextWidth / 2 - 30; // Left of the text
        const hundredThousandsIncrementButtonX = this.hundredThousandsDigitText.x + hundredThousandsTextWidth / 2 + 30; // Right of the text
        const hundredThousandsButtonY = this.digitTextYPositions.get('hundredThousands')!;

        this.hundredThousandsDecrementButton = new NonContainerUIActionButton(
            this.scene,
            hundredThousandsDecrementButtonX,
            hundredThousandsButtonY - 3,
            UIImageKey.MinusButton,
            UIImageKey.MinusButton,
            '',
            () => {
                console.log('[TradeMenu.setupButtons] Clicked on hundred thousands decrement button.');
                this.updateGoldAmount(-100000);
            }
        );
        this.hundredThousandsIncrementButton = new NonContainerUIActionButton(
            this.scene,
            hundredThousandsIncrementButtonX,
            hundredThousandsButtonY - 3,
            UIImageKey.PlusButton,
            UIImageKey.PlusButton,
            '',
            () => {
                console.log('[TradeMenu.setupButtons] Clicked on hundred thousands increment button.');
                this.updateGoldAmount(100000);
            }
        );
        this.hundredThousandsDecrementButton.hideActionButton();
        this.hundredThousandsIncrementButton.hideActionButton();
        this.hundredThousandsDecrementButton.setDepth(DepthLevel.UI_PRIMARY_GRAPHICS_SUB);
        this.hundredThousandsIncrementButton.setDepth(DepthLevel.UI_PRIMARY_GRAPHICS_SUB);

        this.offerGoldButton = new NonContainerUIActionButton(
            this.scene,
            670,
            565,
            UIImageKey.CheckButton,
            UIImageKey.CheckButton,
            'Offer',
            () => {
                console.log('[TradeMenu.setupButtons] Clicked on offer gold button.');
                console.log(`[TradeMenu.setupButtons] Offering gold amount: ${this.goldOfferAmount}.`);
                const eventData = {goldAmount: this.goldOfferAmount};
                this.socket.emit(ServerSocketEvents.AttemptOfferGoldForTrade, eventData);
            });
        this.offerGoldButton.hideActionButton();
        this.offerGoldButton.setDepth(DepthLevel.UI_SECONDARY_GRAPHICS_SUB);
    }

    private setupClientPlayerOfferedItems(): void {
        console.log('[TradeMenu.setupClientPlayerOfferedItems] Setting up client player offered items.');

        // Positions for a 4x2 grid under the client player text
        const startX = 43;
        const startY = 93;
        const buttonWidth = 50;
        const buttonHeight = 50;
        const horizontalSpacing = 10;
        const verticalSpacing = 0.5;

        // Create and position the buttons
        for (let i = 0; i < 8; i++) {
            const x = startX + (i % 4) * (buttonWidth + horizontalSpacing);
            const y = startY + Math.floor(i / 4) * (buttonHeight + verticalSpacing);

            const button = new NonContainerUIActionButton(
                this.scene,
                x,
                y,
                UIImageKey.EmptyButton,
                UIImageKey.EmptyButton,
                '',
                () => {
                    // Button click logic...
                    console.log(`[TradeMenu.setupClientPlayerOfferedItems] Clicked on client player offered item button with index: ${i}.`);
                }
            );
            button.hideActionButton();
            button.setDepth(DepthLevel.UI_SECONDARY_GRAPHICS_SUB);
            this.clientPlayerOfferedItemButtons.push(button);
        }
    }

    private setupRemotePlayerOfferedItems(): void {
        console.log('[TradeMenu.setupRemotePlayerOfferedItems] Setting up remote player offered items.');

        // Positions for a 4x2 grid under the remote player text
        const startX = 301; // Adjusted to align with the remote player text
        const startY = 93;
        const buttonWidth = 50;
        const buttonHeight = 50;
        const horizontalSpacing = 10;
        const verticalSpacing = 0.5;

        // Create and position the buttons
        for (let i = 0; i < 8; i++) {
            const x = startX + (i % 4) * (buttonWidth + horizontalSpacing);
            const y = startY + Math.floor(i / 4) * (buttonHeight + verticalSpacing);

            const button = new NonContainerUIActionButton(
                this.scene,
                x,
                y,
                UIImageKey.EmptyButton,
                UIImageKey.EmptyButton,
                '',
                () => {
                    // Button click logic...
                    console.log(`[TradeMenu.setupRemotePlayerOfferedItems] Clicked on remote player offered item button with index: ${i}.`);
                }
            );
            button.hideActionButton();
            button.setDepth(DepthLevel.UI_SECONDARY_GRAPHICS_SUB);
            this.remotePlayerOfferedItemButtons.push(button);
        }
    }

    private createInventoryItemButton(index: number): NonContainerUIActionButton {
        const numberWord = numberToWords.toWords(index + 1);
        const capitalizedWord = numberWord.charAt(0).toUpperCase() + numberWord.slice(1);
        const label = `Item ${capitalizedWord}`;

        const inventoryItemButton = new NonContainerUIActionButton(
            this.scene,
            564,
            216 + (index * 50),
            UIImageKey.BagButton,
            UIImageKey.BagButtonActive,
            label,
            () => {
                // Button click logic...
            }
        );

        inventoryItemButton.hideActionButton();
        inventoryItemButton.setDepth(DepthLevel.UI_PRIMARY_GRAPHICS_SUB);

        return inventoryItemButton;
    }

    private initializeInventoryButtonInfos(): void {
        // Check if the initialization has already been done to prevent duplication
        if (this.inventoryMainListButtonInfos.length === 0) {
            for (let i = 0; i < 8; i++) {
                const abilityButtonInfo = {
                    button: this.createInventoryItemButton(i),
                    exists: false, // Initialize as false; will be updated based on actual abilities
                    selected: false // Initialize as false; will be updated based on actual abilities
                };
                this.inventoryMainListButtonInfos.push(abilityButtonInfo);
            }
        } else {
            // Optionally, log a message or handle cases where reinitialization might be intended but needs to be controlled
            console.log('Ability buttons have already been initialized.');
        }
    }

    private setupTexts(): void {
        console.log('[TradeMenu.setupTexts] Setting up TradeMenu texts.');
        this.goldText = this.scene.add.text(584, 120, '0 gp', {
            color: '#ffffff', align: 'left', fontFamily: mainGameFont
        })
            .setResolution(3)
            .setFontSize(50)
            .setVisible(false);
        this.goldText.setDepth(DepthLevel.UI_PRIMARY_TEXT);

        this.clientPlayerNameText = this.scene.add.text(10, 54, 'Client Player', {
            color: '#ffffff', align: 'left', fontFamily: mainGameFont
        })
            .setResolution(3)
            .setFontSize(40) // Reduced from 50 to 40
            .setDepth(DepthLevel.UI_SECONDARY_TEXT)
            .setOrigin(0, 0.5)
            .setVisible(false);
        adjustFontSizeByPixel(this.clientPlayerNameText, 250, 40); // Updated the last parameter to 40

        this.remotePlayerNameText = this.scene.add.text(268, 54, 'Remote Player', {
            color: '#ffffff', align: 'left', fontFamily: mainGameFont
        })
            .setResolution(3)
            .setFontSize(40) // Reduced from 50 to 40
            .setDepth(DepthLevel.UI_SECONDARY_TEXT)
            .setOrigin(0, 0.5)
            .setVisible(false);
        adjustFontSizeByPixel(this.remotePlayerNameText, 250, 40); // Updated the last parameter to 40

        this.clientPlayerOfferedGoldText = this.scene.add.text(10, 178, '0 gp', {
            color: '#ffffff', align: 'left', fontFamily: mainGameFont
        })
            .setResolution(3)
            .setFontSize(40) // Reduced from 50 to 40
            .setDepth(DepthLevel.UI_SECONDARY_TEXT)
            .setOrigin(0, 0.5)
            .setVisible(false);

        this.remotePlayerOfferedGoldText = this.scene.add.text(268, 178, '0 gp', {
            color: '#ffffff', align: 'left', fontFamily: mainGameFont
        })
            .setResolution(3)
            .setFontSize(40)
            .setDepth(DepthLevel.UI_SECONDARY_TEXT)
            .setOrigin(0, 0.5)
            .setVisible(false);

        this.clientPlayerTradeStatusText = new BBCodeText(
            this.scene, 10, 207, 'Status: [color=#ff6666]Pending[/color]', {
                color: '#ffffff',
                fontFamily: mainGameFont,
                fontSize: '40px', // Set the font size directly
                delimiters: '[]' // Add this line
            }
        );
        this.scene.add.existing(this.clientPlayerTradeStatusText);
        this.clientPlayerTradeStatusText.setResolution(3);
        this.clientPlayerTradeStatusText.setDepth(DepthLevel.UI_SECONDARY_TEXT);
        this.clientPlayerTradeStatusText.setOrigin(0, 0.5);
        this.clientPlayerTradeStatusText.setVisible(false);

        this.remotePlayerTradeStatusText = new BBCodeText(
            this.scene, 268, 207, 'Status: [color=#ff6666]Pending[/color]', {
                color: '#ffffff',
                fontFamily: mainGameFont,
                fontSize: '40px',
                delimiters: '[]'
            }
        );
        this.scene.add.existing(this.remotePlayerTradeStatusText);
        this.remotePlayerTradeStatusText.setResolution(3);
        this.remotePlayerTradeStatusText.setDepth(DepthLevel.UI_SECONDARY_TEXT);
        this.remotePlayerTradeStatusText.setOrigin(0, 0.5);
        this.remotePlayerTradeStatusText.setVisible(false);

        this.commandText = this.scene.add.text(
            15,
            286,
            '',
            {
                fontSize: '45px',
                color: '#fff',
                fontFamily: mainGameFont,
                wordWrap: {
                    width: 500,
                    useAdvancedWrap: true
                },
                metrics: {
                    ascent: 38,
                    descent: 10,
                    fontSize: 48
                }
            }
        );
        this.commandText.setLineSpacing(-16);
        this.commandText.setResolution(3);
        this.commandText.setDepth(DepthLevel.UI_SECONDARY_TEXT);
        this.commandText.setVisible(false);

        // this.itemDetailText = this.scene.add.text(
        //     15,
        //     286,
        //     '',
        //     {
        //         fontSize: '45px',
        //         color: '#fff',
        //         fontFamily: mainGameFont,
        //         wordWrap: {
        //             width: 500,
        //             useAdvancedWrap: true
        //         },
        //         metrics: {
        //             ascent: 38,
        //             descent: 10,
        //             fontSize: 48
        //         }
        //     }
        // );
        // this.itemDetailText.setLineSpacing(-16);
        // this.itemDetailText.setResolution(3);
        // this.itemDetailText.setDepth(DepthLevel.UI_SECONDARY_TEXT);
        // this.itemDetailText.setVisible(false);

        this.inventoryDetailNameText = this.scene.add.text(
            15,
            286, // Positioned above the detail text
            'Item Name', // Placeholder for the item name
            {
                fontSize: '36px', // Slightly smaller font size
                color: '#fff',
                fontFamily: mainGameFont,
            }
        )
            .setResolution(3)
            .setDepth(DepthLevel.UI_SECONDARY_TEXT)
            .setVisible(false);

        this.inventoryDetailTypeText = this.scene.add.text(
            15,
            313, // Increased y-coordinate to move the text down
            'Weapon', // Initial text content
            {
                fontSize: '36px', // Reduced font size
                color: '#fff',
                fontFamily: mainGameFont,
            }
        )
            .setResolution(3)
            .setDepth(DepthLevel.UI_SECONDARY_TEXT)
            .setVisible(false);

        this.inventoryDetailDescriptionText = this.scene.add.text(
            15,
            346, // Increased y-coordinate to move the text down
            '',
            {
                fontSize: '36px', // Reduced font size
                color: '#fff',
                fontFamily: mainGameFont,
                wordWrap: {
                    width: 310,
                    useAdvancedWrap: true
                },
                metrics: {
                    ascent: 25, // Adjusted to match the new font size
                    descent: 7, // Adjusted to match the new font size
                    fontSize: 32 // Adjusted to match the new font size
                }
            }
        )
            .setLineSpacing(-10) // Adjust line spacing as needed
            .setResolution(3)
            .setDepth(DepthLevel.UI_SECONDARY_TEXT)
            .setVisible(false);

        const rightColumnTextTopLeftCornerX = 329; // X coordinate for the right column text
        const rightColumnTextTopLeftCornerY = 286; // Y coordinate for the right column text

        this.inventoryDetailMinimumLevelText = this.scene.add.text(
            rightColumnTextTopLeftCornerX, // X coordinate (adjust as needed)
            rightColumnTextTopLeftCornerY, // Y coordinate (adjust as needed)
            'Level 1', // Initial text content
            {
                fontSize: '36px',
                color: '#fff',
                fontFamily: mainGameFont,
                align: 'left', // Aligns text to the right
            }
        )
            .setOrigin(0, 0) // Aligns text to the top-right
            .setResolution(3)
            .setDepth(DepthLevel.UI_SECONDARY_TEXT)
            .setVisible(false);

        const bindingStatusTextX = rightColumnTextTopLeftCornerX;
        const bindingStatusTextY = rightColumnTextTopLeftCornerY + 26;
        // Create the top line text
        this.bindingStatusText = this.scene.add.text(
            bindingStatusTextX,
            bindingStatusTextY,
            // 'Soulbound',
            'Bind on pickup',
            {
                fontSize: '36px', // Half the size
                color: '#fff',
                fontFamily: mainGameFont,
                align: 'left'
            }
        )
            .setOrigin(0, 0) // Aligns text to the top-right
            .setResolution(3)
            .setDepth(DepthLevel.UI_SECONDARY_TEXT)
            .setVisible(false);

        this.classesText = this.scene.add.text(
            rightColumnTextTopLeftCornerX,
            rightColumnTextTopLeftCornerY + 54,
            'Runeblade\nAethermancer\nLifeweaver',
            {
                fontSize: '36px',
                color: '#fff',
                fontFamily: mainGameFont,
                align: 'left'
            }
        )
            .setOrigin(0, 0)
            .setResolution(3)
            .setDepth(DepthLevel.UI_SECONDARY_TEXT)
            .setLineSpacing(-10)
            .setVisible(false);

        // First row
        let position = this.calculateStatPositions(0);
        this.stat1Label = this.scene.add.text(
            position.x, position.y,
            'STR:', // Strength
            {color: '#fff', fontFamily: mainGameFont, fontSize: '35px'}
        ).setResolution(3).setDepth(DepthLevel.UI_SECONDARY_TEXT).setVisible(false);

        this.stat1Value = this.scene.add.text(
            position.x, position.y + 20,
            '0', // Value for Strength
            {color: '#fff', fontFamily: mainGameFont, fontSize: '35px'}
        ).setResolution(3).setDepth(DepthLevel.UI_SECONDARY_TEXT).setVisible(false);

        position = this.calculateStatPositions(1);
        this.stat2Label = this.scene.add.text(
            position.x, position.y,
            'AGI:', // Agility
            {color: '#fff', fontFamily: mainGameFont, fontSize: '35px'}
        ).setResolution(3).setDepth(DepthLevel.UI_SECONDARY_TEXT).setVisible(false);

        this.stat2Value = this.scene.add.text(
            position.x, position.y + 20,
            '0', // Value for Agility
            {color: '#fff', fontFamily: mainGameFont, fontSize: '35px'}
        ).setResolution(3).setDepth(DepthLevel.UI_SECONDARY_TEXT).setVisible(false);

        position = this.calculateStatPositions(2);
        this.stat3Label = this.scene.add.text(
            position.x, position.y,
            'VIT:', // Vitality
            {color: '#fff', fontFamily: mainGameFont, fontSize: '35px'}
        ).setResolution(3).setDepth(DepthLevel.UI_SECONDARY_TEXT).setVisible(false);

        this.stat3Value = this.scene.add.text(
            position.x, position.y + 20,
            '0', // Value for Vitality
            {color: '#fff', fontFamily: mainGameFont, fontSize: '35px'}
        ).setResolution(3).setDepth(DepthLevel.UI_SECONDARY_TEXT).setVisible(false);

        // Second row
        position = this.calculateStatPositions(3);
        this.stat4Label = this.scene.add.text(
            position.x, position.y,
            'INT:', // Intellect
            {color: '#fff', fontFamily: mainGameFont, fontSize: '35px'}
        ).setResolution(3).setDepth(DepthLevel.UI_SECONDARY_TEXT).setVisible(false);

        this.stat4Value = this.scene.add.text(
            position.x, position.y + 20,
            '0', // Value for Intellect
            {color: '#fff', fontFamily: mainGameFont, fontSize: '35px'}
        ).setResolution(3).setDepth(DepthLevel.UI_SECONDARY_TEXT).setVisible(false);

        position = this.calculateStatPositions(4);
        this.stat5Label = this.scene.add.text(
            position.x, position.y,
            'LUK:', // Luck
            {color: '#fff', fontFamily: mainGameFont, fontSize: '35px'}
        ).setResolution(3).setDepth(DepthLevel.UI_SECONDARY_TEXT).setVisible(false);

        this.stat5Value = this.scene.add.text(
            position.x, position.y + 20,
            '0', // Value for Luck
            {color: '#fff', fontFamily: mainGameFont, fontSize: '35px'}
        ).setResolution(3).setDepth(DepthLevel.UI_SECONDARY_TEXT).setVisible(false);

        position = this.calculateStatPositions(5);
        this.stat6Label = this.scene.add.text(
            position.x, position.y,
            'DEF:', // Defense
            {color: '#fff', fontFamily: mainGameFont, fontSize: '35px'}
        ).setResolution(3).setDepth(DepthLevel.UI_SECONDARY_TEXT).setVisible(false);

        this.stat6Value = this.scene.add.text(
            position.x, position.y + 20,
            '0', // Value for Defense
            {color: '#fff', fontFamily: mainGameFont, fontSize: '35px'}
        ).setResolution(3).setDepth(DepthLevel.UI_SECONDARY_TEXT).setVisible(false);

        this.statLabels = [
            this.stat1Label,
            this.stat2Label,
            this.stat3Label,
            this.stat4Label,
            this.stat5Label,
            this.stat6Label
        ];
        this.statValues = [
            this.stat1Value,
            this.stat2Value,
            this.stat3Value,
            this.stat4Value,
            this.stat5Value,
            this.stat6Value
        ];

        this.remoteItemDetailShown = false;

        this.onesDigitText = this.scene.add.text(720, this.digitTextYPositions.get('ones')!, '1', {
            color: '#ffffff', align: 'center', fontFamily: mainGameFont
        })
            .setResolution(3)
            .setFontSize(50)
            .setVisible(false)
            .setOrigin(0.5, 0.5)
            .setDepth(DepthLevel.UI_PRIMARY_TEXT);

        this.tensDigitText = this.scene.add.text(720, this.digitTextYPositions.get('tens')!, '10', {
            color: '#ffffff', align: 'center', fontFamily: mainGameFont
        })
            .setResolution(3)
            .setFontSize(50)
            .setVisible(false)
            .setOrigin(0.5, 0.5)
            .setDepth(DepthLevel.UI_PRIMARY_TEXT);

        this.hundredsDigitText = this.scene.add.text(720, this.digitTextYPositions.get('hundreds')!, '100', {
            color: '#ffffff', align: 'center', fontFamily: mainGameFont
        })
            .setResolution(3)
            .setFontSize(50)
            .setVisible(false)
            .setOrigin(0.5, 0.5)
            .setDepth(DepthLevel.UI_PRIMARY_TEXT);

        this.thousandsDigitText = this.scene.add.text(720, this.digitTextYPositions.get('thousands')!, '1,000', {
            color: '#ffffff', align: 'center', fontFamily: mainGameFont
        })
            .setResolution(3)
            .setFontSize(50)
            .setVisible(false)
            .setOrigin(0.5, 0.5)
            .setDepth(DepthLevel.UI_PRIMARY_TEXT);

        this.tenThousandsDigitText = this.scene.add.text(720, this.digitTextYPositions.get('tenThousands')!, '10,000', {
            color: '#ffffff', align: 'center', fontFamily: mainGameFont
        })
            .setResolution(3)
            .setFontSize(50)
            .setVisible(false)
            .setOrigin(0.5, 0.5)
            .setDepth(DepthLevel.UI_PRIMARY_TEXT);

        this.hundredThousandsDigitText = this.scene.add.text(720, this.digitTextYPositions.get('hundredThousands')!, '100,000', {
            color: '#ffffff', align: 'center', fontFamily: mainGameFont
        })
            .setResolution(3)
            .setFontSize(50)
            .setVisible(false)
            .setOrigin(0.5, 0.5)
            .setDepth(DepthLevel.UI_PRIMARY_TEXT);

        this.goldOfferText = this.scene.add.text(720, 487, `${this.goldOfferAmount.toLocaleString()} gp`, {
            color: '#ffffff', align: 'center', fontFamily: mainGameFont
        })
            .setResolution(3)
            .setFontSize(50) // Adjust the font size as needed
            .setVisible(false)
            .setOrigin(0.5, 0) // Center align the text horizontally
            .setDepth(DepthLevel.UI_PRIMARY_TEXT);
    }

    private calculateStatPositions(index: number): { x: number, y: number } {
        // Constants for layout configuration
        const columnWidth = 100; // Width of each stat column
        const rowHeight = 45; // Height between rows of stats

        // Calculate the starting Y position based on the description text's bottom
        const startY = this.inventoryDetailDescriptionText.y + this.inventoryDetailDescriptionText.height - 10; // Additional 10 pixels for spacing

        // Calculate positions based on column and row determined by index
        const x = 15 + (index % 3) * columnWidth; // Calculate X position within the row
        const y = startY + Math.floor(index / 3) * rowHeight; // Calculate Y position for each row

        return {x, y};
    }

    public showInitialView(): void {
        this.inventoryMainListAndGoldCalculatorFrame.showFrame();
        this.inventoryGoldFrame.showFrame();
        this.tradeCategoryFrame.showFrame();
        this.tradeOffersFrame.showFrame();
        this.tradeItemDetailAndCommandFrame.showFrame();

        this.coinIcon.setVisible(true);
        this.goldText.setVisible(true);
        this.bagButton.showActionButton();
        this.bagButton.select();
        this.goldButton.showActionButton();
        this.primaryTradeOfferButton.showActionButton();
        this.secondaryTradeOfferButton.showActionButton();
        this.clientPlayerNameText.setVisible(true);
        this.remotePlayerNameText.setVisible(true);
        this.clientPlayerOfferedGoldText.setVisible(true);
        this.remotePlayerOfferedGoldText.setVisible(true);
        this.clientPlayerTradeStatusText.setVisible(true);
        this.remotePlayerTradeStatusText.setVisible(true);
        this.tradeOffersVerticalDivider.setVisible(true);
        this.clientPlayerOfferedItemButtons.forEach(button => button.showActionButton());
        this.remotePlayerOfferedItemButtons.forEach(button => button.showActionButton());
        this.commandText.setText(this.defaultCommandText);

        this.commandText.setVisible(true);

    }

    private populateInitialView(params: {
        items: {
            name: string,
            key: UIImageKey,
            activeKey: UIImageKey
        }[],
        gold: number,
        clientPlayerName: string,
        remotePlayerName: string
    }): void {
        this.clientMaxGold = params.gold;
        this.clientPlayerNameText.setText(params.clientPlayerName);
        adjustFontSizeByPixel(this.clientPlayerNameText, 250, 40);
        this.remotePlayerNameText.setText(params.remotePlayerName);
        adjustFontSizeByPixel(this.remotePlayerNameText, 250, 40);
        console.log(`[TradeMenu.populateInitialView] Populating TradeMenu with params: ${JSON.stringify(params, null, 2)}.`);
        this.inventoryMainListButtonInfos.forEach(info => {
            info.exists = false;
            info.button.hideActionButton();
            info.selected = false;
            info.button.deselect();
            info.button.targetCallback = (): void => {
                return;
            };
        });

        params.items.forEach((inventoryItem, index) => {
            if (index < this.inventoryMainListButtonInfos.length) {
                const buttonInfo = this.inventoryMainListButtonInfos[index];
                buttonInfo.exists = true;
                buttonInfo.button.changeButtonImage(inventoryItem.key, inventoryItem.activeKey);
                buttonInfo.button.changeButtonText(inventoryItem.name);
                buttonInfo.button.deselect();
                buttonInfo.button.showActionButton();

                buttonInfo.button.targetCallback = (): void => {
                    console.log(`[TradeMenu.populateInitialView] Clicked on inventory item button with index: ${index}.`);
                    const eventData = {selectedInventoryItemForTradeIndex: index};
                    this.socket.emit(ServerSocketEvents.AttemptSelectInventoryItemForTrade, eventData);
                };
            }
        });
        this.goldText.setText(`${params.gold.toLocaleString()} gp`);

        this.clientPlayerOfferedItemButtons.forEach(button => {
            button.changeButtonImage(UIImageKey.EmptyButton, UIImageKey.EmptyButton);
            button.deselect();
            button.targetCallback = (): void => {};
        });

        this.remotePlayerOfferedItemButtons.forEach(button => {
            button.changeButtonImage(UIImageKey.EmptyButton, UIImageKey.EmptyButton);
            button.deselect();
            button.targetCallback = (): void => {};
        });

        this.clientPlayerOfferedGoldText.setText('0 gp');
        this.remotePlayerOfferedGoldText.setText('0 gp');

        this.clientPlayerTradeStatusText.setText('Status: [color=#ff6666]Pending[/color]');
        this.remotePlayerTradeStatusText.setText('Status: [color=#ff6666]Pending[/color]');

        this.primaryTradeOfferButton.changeButtonImage(UIImageKey.CheckButton, UIImageKey.CheckButton);
        this.primaryTradeOfferButton.changeButtonText('Accept');

        this.goldButton.deselect();
    }

    private setupIcons(): void {
        this.coinIcon = this.scene.add.image(563, 145, UIImageKey.CoinButton)
            .setScale(2);
        this.coinIcon.setDepth(DepthLevel.UI_PRIMARY_GRAPHICS_SUB);
        this.coinIcon.setVisible(false);
    }

    private setupGraphics(): void {
        // Create a new Graphics object and add it to the scene
        this.tradeOffersVerticalDivider = this.scene.add.graphics();

        // Set the style properties for the line
        this.tradeOffersVerticalDivider.lineStyle(2, 0xffffff, 1); // Width, color, and alpha

        // Draw the line
        this.tradeOffersVerticalDivider.lineBetween(263, 42, 263, 220); // startX, startY, endX, endY

        // Set the depth of the line
        this.tradeOffersVerticalDivider.setDepth(DepthLevel.UI_SECONDARY_OVERLAY);

        // Initially hide the line
        this.tradeOffersVerticalDivider.setVisible(false);

        // Create a new Graphics object and add it to the scene
        this.inventoryVerticalDivider = this.scene.add.graphics();

        // Set the style properties for the line
        this.inventoryVerticalDivider.lineStyle(2, 0xffffff, 1); // Width, color, and alpha

        // Draw the line
        this.inventoryVerticalDivider.lineBetween(321, 295, 321, 525); // startX, startY, endX, endY

        // Set the depth of the line
        this.inventoryVerticalDivider.setDepth(DepthLevel.UI_SECONDARY_OVERLAY);

        // Initially hide the line
        this.inventoryVerticalDivider.setVisible(false);
    }

    public populateDetailView(param: {
        inventoryItemName: string;
        inventoryItemType: ItemType;
        inventoryItemDescription: string;
        inventoryItemMinimumLevel?: number;
        inventoryItemBindingStatus: string;
        inventoryItemClasses?: CharacterProfession[];
        inventoryItemStats?: {
            strength?: number;
            agility?: number;
            vitality?: number;
            intellect?: number;
            luck?: number;
            defense?: number;
        }
        inventoryItemIndex: number;
        alreadyOffered: boolean;
        offeredSlotIndex: number;
        isClientItem: boolean; // Added to determine the source of the inventory item
    }): void {
        console.log(`[TradeMenu.populateDetailView] Populating TradeMenu detail view with param: ${JSON.stringify(param, null, 2)}.`);
        // logic shared for client / remote items
        this.commandText.setVisible(false);
        // this.itemDetailText.setText(param.inventoryItemDescription);
        // this.itemDetailText.setVisible(true);
        this.inventoryDetailNameText.setText(param.inventoryItemName);
        this.inventoryDetailNameText.setVisible(true);
        this.inventoryDetailTypeText.setText(param.inventoryItemType.charAt(0).toUpperCase() + param.inventoryItemType.slice(1));
        this.inventoryDetailTypeText.setVisible(true);
        this.inventoryDetailDescriptionText.setText(param.inventoryItemDescription);
        this.inventoryDetailDescriptionText.setVisible(true);
        this.inventoryVerticalDivider.setVisible(true);
        this.inventoryDetailMinimumLevelText.setText(`Level ${param.inventoryItemMinimumLevel ?? 1}`);
        this.inventoryDetailMinimumLevelText.setVisible(true);
        this.bindingStatusText.setText(param.inventoryItemBindingStatus.charAt(0).toUpperCase() + param.inventoryItemBindingStatus.slice(1));
        this.bindingStatusText.setVisible(true);
        if (param.inventoryItemClasses && param.inventoryItemClasses.length > 0) {
            this.classesText.setText(param.inventoryItemClasses.join('\n'));
            this.classesText.setVisible(true);
        } else {
            this.classesText.setText('');
            this.classesText.setVisible(false);
        }
        if (param.inventoryItemStats) {
            this.populateStats(param.inventoryItemStats);
            this.showStats();
        } else {
            this.depopulateStats();
            this.hideStats();
        }

        this.inventoryMainListButtonInfos.forEach(info => {
            info.button.deselect();
            info.selected = false;
        });
        this.clientPlayerOfferedItemButtons.forEach(button => {
            button.deselect();
        });
        this.remotePlayerOfferedItemButtons.forEach(button => {
            button.deselect();
        });

        // client item only logic
        if (param.isClientItem) {

            this.inventoryMainListButtonInfos[param.inventoryItemIndex].button.select();
            this.inventoryMainListButtonInfos[param.inventoryItemIndex].selected = true;

            this.selectedClientPlayerItemIndex = param.inventoryItemIndex;

            if (param.alreadyOffered) {
                this.clientPlayerOfferedItemButtons.forEach(button => {
                    button.deselect();
                });
                this.clientPlayerOfferedItemButtons[param.offeredSlotIndex].select();
                this.offerAndRescindSelectedClientPlayerItemButton.changeButtonText('Rescind');
                this.offerAndRescindSelectedClientPlayerItemButton.changeButtonImage(UIImageKey.CrossButton, UIImageKey.CrossButton);
                this.offerAndRescindSelectedClientPlayerItemButton.targetCallback = (): void => {
                    console.log('[TradeMenu.populateDetailView] Clicked on rescind selected client player item button.');
                    const eventData = {offeredSlotIndex: param.offeredSlotIndex};
                    console.log(`[TradeMenu.populateDetailView] Rescinding selected client player item with offered slot index: ${param.offeredSlotIndex}.`);
                    this.socket.emit(ServerSocketEvents.AttemptRescindOfferedItem, eventData);
                };
            } else {
                this.offerAndRescindSelectedClientPlayerItemButton.changeButtonText('Offer');
                this.offerAndRescindSelectedClientPlayerItemButton.changeButtonImage(UIImageKey.CheckButton, UIImageKey.CheckButton);
                this.offerAndRescindSelectedClientPlayerItemButton.targetCallback = (): void => {
                    console.log('[TradeMenu.populateDetailView] Clicked on offer selected client player item button.');
                    console.log(`[TradeMenu.populateDetailView] Selected client player item index: ${this.selectedClientPlayerItemIndex}.`);
                    const eventData = {selectedInventoryItemForTradeIndex: this.selectedClientPlayerItemIndex};
                    this.socket.emit(ServerSocketEvents.AttemptOfferSelectedInventoryItemForTrade, eventData);
                };
            }
            this.offerAndRescindSelectedClientPlayerItemButton.showActionButton();
            this.remoteItemDetailShown = false;
        }
        // remote item only logic
        if (!param.isClientItem) {
            this.remotePlayerOfferedItemButtons[param.offeredSlotIndex].select();
            this.offerAndRescindSelectedClientPlayerItemButton.hideActionButton();
            this.selectedRemotePlayerItemIndex = param.offeredSlotIndex;
            this.remoteItemDetailShown = true;
        }
    }

    private populateStats(stats: {[key: string]: number}): void {
        console.log('[populateStats] Starting population of stats:', JSON.stringify(stats, null, 2));
        this.statsMap = new Map(Object.entries(stats));

        for (let i = 0; i < this.statLabels.length; i++) {
            const statLabel = this.statLabels[i];
            const statValue = this.statValues[i];
            if (i < Object.keys(stats).length) {
                const statName = Object.keys(stats)[i];
                const statAbbreviation = statAbbreviations.get(statName) || statName;
                const statValueString = stats[statName].toString();

                // Use the new method to calculate position
                const {x, y} = this.calculateStatPositions(i);

                // Set positions and text for labels and values
                statLabel.setX(x).setY(y).setText(`${statAbbreviation}:`);
                statValue.setX(x).setY(y + 20).setText(statValueString); // Position value right below the label

                console.log(`[populateStats] Set ${statAbbreviation} to ${statValueString}`, JSON.stringify({statName, statValue: stats[statName]}, null, 2));
            } else {
                // Clear text and reposition off-screen or make invisible if not needed
                statLabel.setText('').setVisible(false);
                statValue.setText('').setVisible(false);
                console.log(`[populateStats] Cleared label and value at index ${i}`);
            }
        }
    }

    private depopulateStats(): void {
        console.log('[depopulateStats] Clearing all stat texts.');
        // Iterate through all stat labels and values to clear them
        this.statLabels.forEach((label) => {
            label.setText(''); // Clear the text and make the label invisible
        });
        this.statValues.forEach((value) => {
            value.setText(''); // Clear the text and make the value invisible
        });
    }

    private showStats(): void {
        console.log('[showStats] Updating visibility of stat labels and values', JSON.stringify(Array.from(this.statsMap.entries()), null, 2));
        const statCount = this.statsMap.size; // Determine the number of stats provided from the Map
        for (let i = 0; i < this.statLabels.length; i++) {
            if (i < statCount) {
                this.statLabels[i].setVisible(true); // Only make visible up to the number of provided stats
                this.statValues[i].setVisible(true);
                console.log(`[showStats] Made visible label and value at index ${i}`);
            } else {
                this.statLabels[i].setVisible(false); // Hide all other labels and values
                this.statValues[i].setVisible(false);
                console.log(`[showStats] Hid label and value at index ${i}`);
            }
        }
    }

    public hideStats(): void {
        for (let i = 0; i < this.statLabels.length; i++) {
            this.statLabels[i].setVisible(false);
            this.statValues[i].setVisible(false);
        }
    }

    public showDetailView(): void {
        console.log('[TradeMenu.showDetailView] Showing TradeMenu detail view.');
    }

    public updateClientPlayerOfferedItems(offeredItems: UpdateRemoteTradePartnerOfferedItemsEvent): void {
        console.log(`[TradeMenu.updateClientPlayerOfferedItems] Updating client player offered items with items: ${JSON.stringify(offeredItems, null, 2)}.`);

        // Hide all existing item buttons initially to reset the view
        this.clientPlayerOfferedItemButtons.forEach(button => {
            button.changeButtonImage(UIImageKey.EmptyButton, UIImageKey.EmptyButton);
            button.targetCallback = (): void => {};
        });

        // Iterate over the offered items and update the corresponding buttons
        offeredItems.forEach((item, index) => {
            if (index < this.clientPlayerOfferedItemButtons.length) {
                const button = this.clientPlayerOfferedItemButtons[index];
                button.changeButtonImage(item.key, item.activeKey); // Update the button images to reflect the new item image
                button.changeButtonText(item.name); // Set the button text to the new item name

                // Set up a new target callback for this button, potentially to allow for item manipulation
                button.targetCallback = (): void => {
                    console.log(`[TradeMenu.updateClientPlayerOfferedItems] Client Offered item button clicked for item at index ${index}: ${item.name}`);
                    const eventData = {offeredSlotIndex: index};
                    this.socket.emit(ServerSocketEvents.AttemptSelectOfferedClientItemByIndex, eventData);
                };
            }
        });

        // Log the completion of the update
        console.log('[TradeMenu.updateClientPlayerOfferedItems] Client player offered items updated successfully.');
    }

    public updateRemotePlayerOfferedItems(offeredItems: UpdateRemoteTradePartnerOfferedItemsEvent): void {
        console.log(`[TradeMenu.updateRemotePlayerOfferedItems] Updating remote player offered items with items: ${JSON.stringify(offeredItems, null, 2)}.`);

        // Hide all existing item buttons initially to reset the view
        this.remotePlayerOfferedItemButtons.forEach(button => {
            button.changeButtonImage(UIImageKey.EmptyButton, UIImageKey.EmptyButton);
            button.targetCallback = (): void => {};
        });

        // Iterate over the offered items and update the corresponding buttons
        offeredItems.forEach((item, index) => {
            if (index < this.remotePlayerOfferedItemButtons.length) {
                const button = this.remotePlayerOfferedItemButtons[index];
                button.changeButtonImage(item.key, item.activeKey); // Update the button images to reflect the new item image
                button.changeButtonText(item.name); // Set the button text to the new item name

                // Set up a new target callback for this button, potentially to allow for item manipulation
                button.targetCallback = (): void => {
                    console.log(`[TradeMenu.updateRemotePlayerOfferedItems] Remote Offered item button clicked for item at index ${index}: ${item.name}`);
                    // Emit an event or call another function here as needed, e.g., to unoffer the item
                    const eventData = {offeredSlotIndex: index};
                    this.socket.emit(ServerSocketEvents.AttemptSelectOfferedRemoteItemByIndex, eventData);
                };
            }
        });

        // Log the completion of the update
        console.log('[TradeMenu.updateRemotePlayerOfferedItems] Remote player offered items updated successfully.');
    }

    private switchToGoldMode(): void {
        console.log('[TradeMenu.switchToGoldMode] Switching to gold mode.');
        this.currentMode = 'gold';
        this.bagButton.deselect();
        this.goldButton.select();
        this.inventoryMainListButtonInfos.forEach(info => {
            info.button.deselect();
            info.selected = false;
            info.button.hideActionButton();
        });
        this.clientPlayerOfferedItemButtons.forEach(button => {
            button.deselect();
        });
        this.remotePlayerOfferedItemButtons.forEach(button => {
            button.deselect();
        });
        this.selectedClientPlayerItemIndex = -1;
        this.offerAndRescindSelectedClientPlayerItemButton.hideActionButton();
        // this.itemDetailText.setVisible(false);
        this.inventoryDetailNameText.setVisible(false);
        this.inventoryDetailTypeText.setVisible(false);
        this.inventoryDetailDescriptionText.setVisible(false);
        this.inventoryDetailMinimumLevelText.setVisible(false);
        this.bindingStatusText.setVisible(false);
        this.classesText.setVisible(false);
        this.hideStats();
        this.inventoryVerticalDivider.setVisible(false);

        this.remoteItemDetailShown = false;
        this.commandText.setText(this.defaultCommandText);
        this.commandText.setVisible(true);
        this.onesDigitText.setVisible(true);
        this.tensDigitText.setVisible(true);
        this.hundredsDigitText.setVisible(true);
        this.thousandsDigitText.setVisible(true);
        this.tenThousandsDigitText.setVisible(true);
        this.hundredThousandsDigitText.setVisible(true);

        this.onesDecrementButton.showActionButton();
        this.tensDecrementButton.showActionButton();
        this.hundredsDecrementButton.showActionButton();
        this.thousandsDecrementButton.showActionButton();
        this.tenThousandsDecrementButton.showActionButton();
        this.hundredThousandsDecrementButton.showActionButton();

        this.onesIncrementButton.showActionButton();
        this.tensIncrementButton.showActionButton();
        this.hundredsIncrementButton.showActionButton();
        this.thousandsIncrementButton.showActionButton();
        this.tenThousandsIncrementButton.showActionButton();
        this.hundredThousandsIncrementButton.showActionButton();

        this.goldOfferText.setVisible(true);
        this.offerGoldButton.showActionButton();
    }

    private switchToBagMode(): void {
        console.log('[TradeMenu.switchToBagMode] Switching to bag mode.');
        this.currentMode = 'bag';
        this.goldButton.deselect();
        this.bagButton.select();
        this.inventoryMainListButtonInfos.forEach(info => {
            if (info.exists) {
                info.button.showActionButton();
            }
        });
        this.onesDigitText.setVisible(false);
        this.tensDigitText.setVisible(false);
        this.hundredsDigitText.setVisible(false);
        this.thousandsDigitText.setVisible(false);
        this.tenThousandsDigitText.setVisible(false);
        this.hundredThousandsDigitText.setVisible(false);

        this.onesDecrementButton.hideActionButton();
        this.tensDecrementButton.hideActionButton();
        this.hundredsDecrementButton.hideActionButton();
        this.thousandsDecrementButton.hideActionButton();
        this.tenThousandsDecrementButton.hideActionButton();
        this.hundredThousandsDecrementButton.hideActionButton();

        this.onesIncrementButton.hideActionButton();
        this.tensIncrementButton.hideActionButton();
        this.hundredsIncrementButton.hideActionButton();
        this.thousandsIncrementButton.hideActionButton();
        this.tenThousandsIncrementButton.hideActionButton();
        this.hundredThousandsIncrementButton.hideActionButton();

        this.goldOfferText.setVisible(false);
        this.offerGoldButton.hideActionButton();

        this.commandText.setText(this.defaultCommandText);
    }

    /**
     * Updates the gold offer amount and ensures it doesn't fall below zero or exceed the maximum allowed.
     * @param {number} change - The amount to add or subtract from the goldOfferAmount.
     */
    public updateGoldAmount(change: number): void {
        let newAmount = this.goldOfferAmount + change;
        if (newAmount < 0) {
            newAmount = 0; // Ensure the amount doesn't drop below zero
        } else if (newAmount > this.clientMaxGold) {
            newAmount = this.clientMaxGold; // Ensure the amount doesn't exceed the maximum gold allowed
        }
        this.goldOfferAmount = newAmount;
        this.goldOfferText.setText(`${this.goldOfferAmount.toLocaleString()} gp`); // Update the text display with commas for thousands
    }

    /**
     * Updates the displayed gold amount that the client player has offered in the trade.
     * @param offeredGold The amount of gold the client player has offered.
     */
    public updateClientPlayerOfferedGold(offeredGold: number): void {
        console.log(`[updateClientPlayerOfferedGold] Updating client player offered gold to: ${offeredGold}`);
        this.clientPlayerOfferedGoldText.setText(`${offeredGold.toLocaleString()} gp`); // Update the text to show the new amount
    }

    /**
     * Updates the displayed gold amount that the remote player has offered in the trade.
     * @param offeredGold The amount of gold the remote player has offered.
     */
    public updateRemotePlayerOfferedGold(offeredGold: number): void {
        console.log(`[updateRemotePlayerOfferedGold] Updating remote player offered gold to: ${offeredGold}`);
        this.remotePlayerOfferedGoldText.setText(`${offeredGold.toLocaleString()} gp`); // Update the text to show the new amount
    }

    public setTradeStatus(playerType: 'client' | 'remote', status: 'Pending' | 'Accepted'): void {
        const colorCode = status === 'Accepted' ? '#66ff66' : '#ff6666';
        const statusText = `Status: [color=${colorCode}]${status}[/color]`;

        if (playerType === 'client') {
            this.clientPlayerTradeStatusText.setText(statusText);
            this.clientTradeStatus = status;
            if (status === 'Accepted') {
                this.primaryTradeOfferButton.changeButtonText('Modify');
                this.primaryTradeOfferButton.changeButtonImage(UIImageKey.PencilButton, UIImageKey.PencilButton);
            } else if (status === 'Pending') {
                this.primaryTradeOfferButton.changeButtonText('Accept');
                this.primaryTradeOfferButton.changeButtonImage(UIImageKey.CheckButton, UIImageKey.CheckButton);
            }
        } else {
            this.remotePlayerTradeStatusText.setText(statusText);
        }
    }

}
