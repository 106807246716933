import Phaser from 'phaser';
import {Socket} from 'socket.io-client';
import {SpriteSheet, UIImageKey} from '../../../../../../types/assets/AssetKeys';
import {ServerSocketEvents} from '../../../../../../types/events/ServerSocketEvents';
import {CharacterProfession} from '../../../../../../types/mechanics/CharacterProfessionsEnum';
import {Gender} from '../../../../../../types/mechanics/Gender';
import {PartyOrderDataArray} from '../../../../../../types/playerInfo/PartyOrderDataArray';
import {heroWeaponColor} from '../../../../data/heroWeaponColor';
import {tertiaryColor} from '../../../../data/tertiaryColor';
import {mainGameFont} from '../../../../GameConfig';
import ServerControlledGameUIScene from '../../../../scenes/ServerControlledUIScenes/ServerControlledGameUIScene';
import {DepthLevel} from '../../../../types/DepthLevel';
import {FieldMenuType} from '../../../../types/FieldMenuType';
import {PartyOrderMenuMemberComponentArray} from '../../../../types/PartyOrderMenuMemberComponentArray';
import {adjustFontSizeByPixel} from '../../../../utils/phaserTextUtils';
import NonContainerUIActionButton from '../../ActionButtons/NonContainerUIActionButton';
import ResizableFrame from '../../UtilityComponents/ResizableFrame';
import {GameFieldActionMenu} from './GameFieldActionMenu';

export default class PartyOrderMenu extends GameFieldActionMenu {
    public shown: boolean = false;

    public menuTag = FieldMenuType.PartyOrderMenu;
    public relatedMenusToDismiss: Set<FieldMenuType> = new Set([
        FieldMenuType.AbilityMenu,
        FieldMenuType.CharacterSheetMenu,
        FieldMenuType.TargetMenu,
        FieldMenuType.DuelNotification,
        FieldMenuType.DismissibleNotification,
        FieldMenuType.InviteNotification,
        FieldMenuType.CommonerNPCMenu,
        FieldMenuType.InnkeeperNPCMenu,
        FieldMenuType.MerchantNPCMenu,
        FieldMenuType.MessageElement,
        FieldMenuType.InventoryMenu,
        FieldMenuType.TradeMenu,
        FieldMenuType.InteractableMessageDisplay,

        FieldMenuType.InteractionMenu,
        // Any other menu that should be dismissed when AbilityMenu is active
    ]);

    public canCoincideWithGamePad = true;

    public hardcodedCoords: Map<number, PlayerCoords> = new Map([
        [0, { // Player One
            reorderDownButton: {x: 547, y: 464},
            numberText: {x: 574, y: 434},
            memberText: {x: 647, y: 434},
            heroSprite: {x: 599, y: 431}
        }],
        [1, { // Player Two
            reorderUpButton: {x: 547, y: 499},
            reorderDownButton: {x: 547, y: 519},
            numberText: {x: 574, y: 489},
            memberText: {x: 647, y: 489},
            heroSprite: {x: 599, y: 486}
        }],
        [2, { // Player 3
            reorderUpButton: {x: 547, y: 554},
            numberText: {x: 574, y: 544},
            memberText: {x: 647, y: 544},
            heroSprite: {x: 599, y: 541}
        }]
    ]);

    public partyOrderFrame: ResizableFrame;
    public partyOrderList: Phaser.GameObjects.Text;
    public scene: ServerControlledGameUIScene;
    private socket: Socket;
    private partyEntries: PartyOrderMenuMemberComponentArray= [];

    // Dummy Data for player colors, socketId, index, and names
    private playerDetails: PartyOrderDataArray = [
        {skinColor: '#ff0000', hairColor: '#ff0000', primaryColor: '#000000', secondaryColor: '#000000', socketId: '', index: 0, name: '', gender: Gender.Male, className: CharacterProfession.Runeblade},
        {skinColor: '#00ff00', hairColor: '#00ff00', primaryColor: '#000000', secondaryColor: '#000000', socketId: '', index: 1, name: '', gender: Gender.Male, className: CharacterProfession.Runeblade},
        {skinColor: '#0000ff', hairColor: '#0000ff', primaryColor: '#000000', secondaryColor: '#000000', socketId: '', index: 2, name: '', gender: Gender.Male, className: CharacterProfession.Runeblade}
    ];

    private cancelButton: NonContainerUIActionButton;
    private acceptButton: NonContainerUIActionButton;
    private partyOrderText: Phaser.GameObjects.Text;

    public constructor(scene: ServerControlledGameUIScene, socket: Socket) {
        super();
        this.scene = scene;
        this.socket = socket;
        // Define hardcoded constants for UI Component positions and dimensions
        const uiComponentCoords = {
            partyOrderFrame: {
                centerX: 699,
                centerY: 495,
                width: 350,
                height: 200
            },
            partyOrderText: {
                x: 529,
                y: 390
            },
            cancelButton: {
                x: 794,
                y: 418
            },
            acceptButton: {
                x: 844,
                y: 418
            }
        };

        // Initialize and Display the Resizable Frame using the constants
        this.partyOrderFrame = new ResizableFrame(
            this.scene,
            uiComponentCoords.partyOrderFrame.centerX,
            uiComponentCoords.partyOrderFrame.centerY,
            uiComponentCoords.partyOrderFrame.width,
            uiComponentCoords.partyOrderFrame.height
        );
        this.partyOrderFrame.hideFrame();

        // Using hardcoded values for text position
        this.partyOrderText = this.scene.add.text(
            uiComponentCoords.partyOrderText.x,
            uiComponentCoords.partyOrderText.y,
            'Party Order: ',
            {
                fontFamily: mainGameFont,
                fontSize: '42px',
                color: '#ffffff'
            }).setResolution(3).setDepth(DepthLevel.UI_PRIMARY_TEXT).setVisible(false);

        // Cancel Button using constants
        this.cancelButton = new NonContainerUIActionButton(
            this.scene,
            uiComponentCoords.cancelButton.x,
            uiComponentCoords.cancelButton.y,
            UIImageKey.CrossButton,
            UIImageKey.CrossButton,
            '',
            () => {
                console.log('Cancel button clicked.');
                this.dismiss();
                // Add action logic for cancel button here
            }
        );
        this.cancelButton.setDepth(DepthLevel.UI_PRIMARY_GRAPHICS_SUB);
        this.cancelButton.button.setVisible(false);

        // Accept Button using constants
        this.acceptButton = new NonContainerUIActionButton(
            this.scene,
            uiComponentCoords.acceptButton.x,
            uiComponentCoords.acceptButton.y,
            UIImageKey.CheckButton,
            UIImageKey.CheckButton,
            '',
            () => {
                console.log('Accept button clicked.');

                // Log the current state of partyEntries
                console.log('Current partyEntries state:');

                // Create an array to store socketIds and indexes for emission
                const partyDataForEmission: Array<{ socketId: string, index: number }> = [];

                for (const entry of this.partyEntries) {
                    console.log(`Index: ${entry.index}`);
                    console.log(`Name: ${entry.name}`);
                    console.log(`SocketId: ${entry.socketId}`);
                    console.log(`Color: ${entry.skinColor}`);
                    console.log(`Sprite Y-Position: ${entry.baseSprite.y}`);
                    console.log(`MemberText Y-Position: ${entry.memberText.y}`);
                    console.log('-----------------------------');

                    // Check if the socketId is present
                    if (entry.socketId) {
                        // Add socketId and index to the array for emission
                        partyDataForEmission.push({socketId: entry.socketId, index: entry.index});
                    }
                }

                // Emit 'attemptPartyReorderSubmit' with the array of socketIds and indexes
                this.socket.emit(ServerSocketEvents.AttemptPartyReorderSubmit, partyDataForEmission);

                // Add any additional action logic for accept button here
            }
        );
        this.acceptButton.setDepth(DepthLevel.UI_PRIMARY_GRAPHICS_SUB);
        this.acceptButton.button.setVisible(false);

        for (let i = 0; i < this.playerDetails.length; i++) {
            const playerDetail = this.playerDetails[i];

            // Retrieve coordinates using Map's get method
            const coords = this.hardcodedCoords.get(i);

            if (!coords) {
                continue;
            } // If no coordinates are found, skip the current iteration

            let reorderUpButton: NonContainerUIActionButton | undefined;
            let reorderDownButton: NonContainerUIActionButton | undefined;

            // Using the new function in your reorder button logic
            if (i === 1 || i === 2) {
                reorderUpButton = new NonContainerUIActionButton(this.scene, coords.reorderUpButton?.x || 0, coords.reorderUpButton?.y || 0, UIImageKey.ReorderUpButton, UIImageKey.ReorderUpButton, '', () => {
                    this.reorderPartyMember(i, 'up');
                });
                reorderUpButton.setDepth(DepthLevel.UI_PRIMARY_GRAPHICS_SUB);
                reorderUpButton.button.setVisible(false);
                console.log(`reorderUpButton Coords for member at index ${i} (${playerDetail.name}): x = ${coords.reorderUpButton?.x || 0}, y = ${coords.reorderUpButton?.y || 0}`);
            }

            if (i === 0 || i === 1) {
                reorderDownButton = new NonContainerUIActionButton(this.scene, coords.reorderDownButton?.x || 0, coords.reorderDownButton?.y || 0, UIImageKey.ReorderDownButton, UIImageKey.ReorderDownButton, '', () => {
                    this.reorderPartyMember(i, 'down');
                });
                reorderDownButton.setDepth(DepthLevel.UI_PRIMARY_GRAPHICS_SUB);
                reorderDownButton.button.setVisible(false);
                console.log(`reorderDownButton Coords for member at index ${i} (${playerDetail.name}): x = ${coords.reorderDownButton?.x || 0}, y = ${coords.reorderDownButton?.y || 0}`);
            }

            const numberText = this.scene.add.text(coords.numberText.x, coords.numberText.y, `${i + 1}:`, {
                fontFamily: mainGameFont,
                fontSize: '42px',
                color: '#ffffff'
            }).setResolution(3).setDepth(DepthLevel.UI_PRIMARY_TEXT).setVisible(false);
            console.log(`numberText Coords for member at index ${i} (${playerDetail.name}): x = ${coords.numberText.x}, y = ${coords.numberText.y}`);

            const memberText = this.scene.add.text(coords.memberText.x, coords.memberText.y, playerDetail.name, {
                fontFamily: mainGameFont,
                fontSize: '42px',
                color: '#ffffff'
            }).setResolution(3).setDepth(DepthLevel.UI_PRIMARY_TEXT).setVisible(false);

            console.log(`memberText Coords for member at index ${i} (${playerDetail.name}): x = ${coords.memberText.x}, y = ${coords.memberText.y}`);

            const runebladeWeaponBottomSprite = this.scene.add.sprite(coords.heroSprite.x, coords.heroSprite.y, SpriteSheet.RunebladeWeaponBottom)
                .setTint(parseInt(heroWeaponColor.replace('#', '0x')))
                .setDepth(DepthLevel.UI_PRIMARY_GRAPHICS_SUB)
                .setOrigin(0, 0)
                .setVisible(false);

            const aethermancerWeaponBottomSprite = this.scene.add.sprite(coords.heroSprite.x, coords.heroSprite.y, SpriteSheet.AethermancerWeaponBottom)
                .setTint(parseInt(heroWeaponColor.replace('#', '0x')))
                .setDepth(DepthLevel.UI_PRIMARY_GRAPHICS_SUB)
                .setOrigin(0, 0)
                .setVisible(false);

            const lifeweaverWeaponBottomSprite = this.scene.add.sprite(coords.heroSprite.x, coords.heroSprite.y, SpriteSheet.LifeweaverWeaponBottom)
                .setTint(parseInt(heroWeaponColor.replace('#', '0x')))
                .setDepth(DepthLevel.UI_PRIMARY_GRAPHICS_SUB)
                .setOrigin(0, 0)
                .setVisible(false);

            const baseSprite = this.scene.add.sprite(coords.heroSprite.x, coords.heroSprite.y, SpriteSheet.HeroBaseSprite)
                .setTint(parseInt(playerDetail.skinColor.replace('#', '0x')))
                .setDepth(DepthLevel.UI_PRIMARY_GRAPHICS_SUB)
                .setOrigin(0, 0)
                .setVisible(false);

            console.log(`heroSprite Coords for member at index ${i} (${playerDetail.name}): x = ${coords.heroSprite.x}, y = ${coords.heroSprite.y}`);
            const primaryClothingSprite = this.scene.add.sprite(coords.heroSprite.x, coords.heroSprite.y, SpriteSheet.HeroClothesPrimary)
                .setTint(parseInt(playerDetail.primaryColor.replace('#', '0x')))
                .setDepth(DepthLevel.UI_PRIMARY_GRAPHICS_SUB)
                .setOrigin(0, 0)
                .setVisible(false);

            const secondaryClothingSprite = this.scene.add.sprite(coords.heroSprite.x, coords.heroSprite.y, SpriteSheet.HeroClothesSecondary)
                .setTint(parseInt(playerDetail.secondaryColor.replace('#', '0x')))
                .setDepth(DepthLevel.UI_PRIMARY_GRAPHICS_SUB)
                .setOrigin(0, 0)
                .setVisible(false);

            const tertiaryClothingSprite = this.scene.add.sprite(coords.heroSprite.x, coords.heroSprite.y, SpriteSheet.HeroClothesTertiary)
                .setTint(parseInt(tertiaryColor.replace('#', '0x')))
                .setDepth(DepthLevel.UI_PRIMARY_GRAPHICS_SUB)
                .setOrigin(0, 0)
                .setVisible(false);

            const maleHairSprite = this.scene.add.sprite(coords.heroSprite.x, coords.heroSprite.y, SpriteSheet.HeroHairMale)
                .setTint(parseInt(playerDetail.hairColor.replace('#', '0x')))
                .setDepth(DepthLevel.UI_PRIMARY_GRAPHICS_SUB)
                .setOrigin(0, 0)
                .setVisible(false);

            const femaleHairSprite = this.scene.add.sprite(coords.heroSprite.x, coords.heroSprite.y, SpriteSheet.HeroHairFemale)
                .setTint(parseInt(playerDetail.hairColor.replace('#', '0x')))
                .setDepth(DepthLevel.UI_PRIMARY_GRAPHICS_SUB)
                .setOrigin(0, 0)
                .setVisible(false);

            const runebladeWeaponTopSprite = this.scene.add.sprite(coords.heroSprite.x, coords.heroSprite.y, SpriteSheet.RunebladeWeaponTop)
                .setTint(parseInt(heroWeaponColor.replace('#', '0x')))
                .setDepth(DepthLevel.UI_PRIMARY_GRAPHICS_SUB)
                .setOrigin(0, 0)
                .setVisible(false);

            const aethermancerWeaponTopSprite = this.scene.add.sprite(coords.heroSprite.x, coords.heroSprite.y, SpriteSheet.AethermancerWeaponTop)
                .setTint(parseInt(heroWeaponColor.replace('#', '0x')))
                .setDepth(DepthLevel.UI_PRIMARY_GRAPHICS_SUB)
                .setOrigin(0, 0)
                .setVisible(false);

            const lifeweaverWeaponTopSprite = this.scene.add.sprite(coords.heroSprite.x, coords.heroSprite.y, SpriteSheet.LifeweaverWeaponTop)
                .setTint(parseInt(heroWeaponColor.replace('#', '0x')))
                .setDepth(DepthLevel.UI_PRIMARY_GRAPHICS_SUB)
                .setOrigin(0, 0)
                .setVisible(false);

            this.partyEntries.push({
                numberText,
                memberText,
                baseSprite: baseSprite,
                maleHairSprite: maleHairSprite,
                femaleHairSprite: femaleHairSprite,
                primaryClothingSprite: primaryClothingSprite,
                secondaryClothingSprite: secondaryClothingSprite,
                tertiaryClothingSprite: tertiaryClothingSprite,
                weaponRunebladeBottomSprite: runebladeWeaponBottomSprite,
                weaponAethermancerBottomSprite: aethermancerWeaponBottomSprite,
                weaponLifeweaverBottomSprite: lifeweaverWeaponBottomSprite,
                weaponRunebladeTopSprite: runebladeWeaponTopSprite,
                weaponAethermancerTopSprite: aethermancerWeaponTopSprite,
                weaponLifeweaverTopSprite: lifeweaverWeaponTopSprite,
                reorderUpButton,
                reorderDownButton,
                skinColor: playerDetail.skinColor,
                hairColor: playerDetail.hairColor,
                primaryColor: playerDetail.primaryColor,
                secondaryColor: playerDetail.secondaryColor,
                gender: playerDetail.gender,
                className: playerDetail.className,
                socketId: playerDetail.socketId,
                index: playerDetail.index,
                name: playerDetail.name
            });

        }
    }

    public emitMenuDismissalRequest(): void {
        console.log('[PartyOrderMenu.emitMenuDismissalRequest] Entering method.');
        if (!this.scene.canProceedWithMenuAction()) {
            // If false, exit early. Logging is already handled within `canProceedWithMenuAction`.
            return;
        }

        console.log('[PartyOrderMenu.emitMenuDismissalRequest] Emitting \'attemptPartyOrderClose\'');
        this.scene.waitingForServerResponse = true;
        this.socket.emit(ServerSocketEvents.AttemptTogglePartyOrderMenu, {action: 'close'});
        // this.dismiss();
    }

    public emitMenuActivationRequest(params?: unknown): void {
        // Check if the player is currently processing a field ability
        if (!this.scene.canProceedWithMenuAction()) {
            // If false, exit early. Logging is already handled within `canProceedWithMenuAction`.
            return;
        }

        console.log(`[PartyOrderMenu.emitMenuActivationRequest] PartyOrderMenu: Emitting 'attemptPartyOrderOpen' with params: ${JSON.stringify(params, null, 2)}`);
        this.scene.waitingForServerResponse = true;
        // Explicitly specifying the action as 'open' when emitting the event
        this.socket.emit(ServerSocketEvents.AttemptTogglePartyOrderMenu, {action: 'open'});
    }

    public handleUpdatePartyMembers(partyMembers: PartyOrderDataArray): void {
        // Loop through partyMembers to update entries in partyEntries
        partyMembers.forEach((member, index) => {
            const currentEntry = this.partyEntries[index];
            if (currentEntry) {
                // Update existing party member's details
                currentEntry.numberText.setText(`${index + 1}:`);
                currentEntry.memberText.setText(member.name);

                currentEntry.memberText.setFontSize('42px');
                adjustFontSizeByPixel(currentEntry.memberText, 230, 42);

                currentEntry.baseSprite.setTint(parseInt(member.skinColor.replace('#', '0x')));

                switch (member.gender) {
                    case Gender.Male:
                        currentEntry.maleHairSprite.setTint(parseInt(member.hairColor.replace('#', '0x')));
                        break;
                    case Gender.Female:
                        currentEntry.femaleHairSprite.setTint(parseInt(member.hairColor.replace('#', '0x')));
                        break;
                    default:
                        currentEntry.maleHairSprite.setTint(parseInt(member.hairColor.replace('#', '0x')));
                        break;
                }

                currentEntry.primaryClothingSprite.setTint(parseInt(member.primaryColor.replace('#', '0x')));
                currentEntry.secondaryClothingSprite.setTint(parseInt(member.secondaryColor.replace('#', '0x')));
                currentEntry.tertiaryClothingSprite.setTint(parseInt(tertiaryColor.replace('#', '0x')));

                switch (member.className) {
                    case CharacterProfession.Runeblade:
                        currentEntry.weaponRunebladeBottomSprite.setTint(parseInt(heroWeaponColor.replace('#', '0x')));
                        currentEntry.weaponRunebladeTopSprite.setTint(parseInt(heroWeaponColor.replace('#', '0x')));
                        break;
                    case CharacterProfession.Aethermancer:
                        currentEntry.weaponAethermancerBottomSprite.setTint(parseInt(heroWeaponColor.replace('#', '0x')));
                        currentEntry.weaponAethermancerTopSprite.setTint(parseInt(heroWeaponColor.replace('#', '0x')));
                        break;
                    case CharacterProfession.Lifeweaver:
                        currentEntry.weaponLifeweaverBottomSprite.setTint(parseInt(heroWeaponColor.replace('#', '0x')));
                        currentEntry.weaponLifeweaverTopSprite.setTint(parseInt(heroWeaponColor.replace('#', '0x')));
                        break;

                }
                // Updating the socketId, color, index, and name properties as well
                currentEntry.socketId = member.socketId;
                currentEntry.skinColor = member.skinColor;
                currentEntry.hairColor = member.hairColor;
                currentEntry.index = member.index;
                currentEntry.name = member.name;
                currentEntry.gender = member.gender;
                currentEntry.className = member.className;
            }
        });

        // Reset old data for entries not in the updated list
        for (let i = partyMembers.length; i < this.partyEntries.length; i++) {
            const currentEntry = this.partyEntries[i];
            currentEntry.numberText.setText('');
            currentEntry.memberText.setText('');
            currentEntry.baseSprite.clearTint();
            currentEntry.maleHairSprite.clearTint();
            currentEntry.femaleHairSprite.clearTint();
            currentEntry.primaryClothingSprite.clearTint();
            currentEntry.secondaryClothingSprite.clearTint();
            currentEntry.tertiaryClothingSprite.clearTint();
            currentEntry.weaponRunebladeBottomSprite.clearTint();
            currentEntry.weaponAethermancerBottomSprite.clearTint();
            currentEntry.weaponLifeweaverBottomSprite.clearTint();
            currentEntry.weaponRunebladeTopSprite.clearTint();
            currentEntry.weaponAethermancerTopSprite.clearTint();
            currentEntry.weaponLifeweaverTopSprite.clearTint();
            currentEntry.socketId = '';
            currentEntry.skinColor = '';
            currentEntry.hairColor = '';
            currentEntry.primaryColor = '';
            currentEntry.secondaryColor = '';
            currentEntry.gender = Gender.Male;
            currentEntry.className = CharacterProfession.Runeblade;
            currentEntry.index = -1; // or any other default value you use for uninitialized index
            currentEntry.name = '';
        }

        // Update the playerDetails with new data
        this.playerDetails = partyMembers;
    }

    public activate(): void {
        this.shown = true;
        console.log('[PartyOrderMenu.activate] Entering method. Activating Party Order Menu');
        this.dismissRelatedMenus();
        this.partyOrderFrame.showFrame(); // Assuming ResizableFrame has a showFrame method
        this.partyOrderText.setVisible(true);
        this.acceptButton.button.setVisible(true);
        this.cancelButton.button.setVisible(true);

        // Check party size
        const partySize = this.playerDetails.length;

        // Show only the entries for which there are party members
        this.playerDetails.forEach((detail, index) => {
            const currentEntry = this.partyEntries[index];
            if (currentEntry) {
                currentEntry.numberText.setVisible(true);
                currentEntry.memberText.setVisible(true);
                currentEntry.baseSprite.setVisible(true);
                switch (currentEntry.gender) {
                    case Gender.Male:
                        currentEntry.maleHairSprite.setVisible(true);
                        break;
                    case Gender.Female:
                        currentEntry.femaleHairSprite.setVisible(true);
                        break;
                    default:
                        currentEntry.maleHairSprite.setVisible(true);
                        break;
                }
                currentEntry.primaryClothingSprite.setVisible(true);
                currentEntry.secondaryClothingSprite.setVisible(true);
                currentEntry.tertiaryClothingSprite.setVisible(true);

                switch (currentEntry.className) {
                    case CharacterProfession.Runeblade:
                        currentEntry.weaponRunebladeBottomSprite.setVisible(true);
                        currentEntry.weaponRunebladeTopSprite.setVisible(true);
                        break;
                    case CharacterProfession.Aethermancer:
                        currentEntry.weaponAethermancerBottomSprite.setVisible(true);
                        currentEntry.weaponAethermancerTopSprite.setVisible(true);
                        break;
                    case CharacterProfession.Lifeweaver:
                        currentEntry.weaponLifeweaverBottomSprite.setVisible(true);
                        currentEntry.weaponLifeweaverTopSprite.setVisible(true);
                        break;
                    default:
                        currentEntry.weaponRunebladeBottomSprite.setVisible(true);
                        currentEntry.weaponRunebladeTopSprite.setVisible(true);
                        break;
                }

                // Adjust reorder button visibility based on party size
                switch (partySize) {
                    case 2:
                        if (currentEntry.reorderUpButton) {
                            currentEntry.reorderUpButton.button.setVisible(index !== 0); // Hide for the first member
                        }
                        if (currentEntry.reorderDownButton) {
                            currentEntry.reorderDownButton.button.setVisible(index !== 1); // Hide for the second member
                        }
                        break;
                    case 3:
                        if (currentEntry.reorderUpButton) {
                            currentEntry.reorderUpButton.button.setVisible(true);
                        }
                        if (currentEntry.reorderDownButton) {
                            currentEntry.reorderDownButton.button.setVisible(true);
                        }
                        break;
                }
            }
        });
        console.log('[PartyOrderMenu.activate] Exiting method.');
    }

    public dismiss(): void {
        console.log('[PartyOrderMenu.dismiss] Entering method. Dismissing Party Order Menu');
        this.partyOrderFrame.hideFrame(); // Assuming ResizableFrame has a hideFrame method
        this.partyOrderText.setVisible(false);
        this.acceptButton.button.setVisible(false);
        this.cancelButton.button.setVisible(false);

        this.partyEntries.forEach(entry => {
            entry.numberText.setVisible(false);
            entry.memberText.setVisible(false);
            entry.baseSprite.setVisible(false);
            entry.maleHairSprite.setVisible(false);
            entry.femaleHairSprite.setVisible(false);
            entry.primaryClothingSprite.setVisible(false);
            entry.secondaryClothingSprite.setVisible(false);
            entry.tertiaryClothingSprite.setVisible(false);
            entry.weaponRunebladeBottomSprite.setVisible(false);
            entry.weaponAethermancerBottomSprite.setVisible(false);
            entry.weaponLifeweaverBottomSprite.setVisible(false);
            entry.weaponRunebladeTopSprite.setVisible(false);
            entry.weaponAethermancerTopSprite.setVisible(false);
            entry.weaponLifeweaverTopSprite.setVisible(false);
            if (entry.reorderUpButton) {
                entry.reorderUpButton.button.setVisible(false);
            }
            if (entry.reorderDownButton) {
                entry.reorderDownButton.button.setVisible(false);
            }
        });

        this.activateInteractionMenuIfNeeded();
        this.shown = false;
        console.log('[PartyOrderMenu.dismiss] Exiting method.');
    }

    private swapGameObjectPositions(gameObjectA: Phaser.GameObjects.Sprite | Phaser.GameObjects.Text, gameObjectB: Phaser.GameObjects.Sprite | Phaser.GameObjects.Text, coordA: number, coordB: number): void {
        gameObjectA.setY(coordB);
        gameObjectB.setY(coordA);
    }

    // New function to handle the reordering logic
    private reorderPartyMember(currentIndex: number, direction: 'up' | 'down'): void {
        const swapIndex = direction === 'up' ? currentIndex - 1 : currentIndex + 1;

        console.log(`Trying to move member at index ${currentIndex} (${this.partyEntries[currentIndex].name}) ${direction}.`);

        const currentEntry = this.partyEntries[currentIndex];
        const swapEntry = this.partyEntries[swapIndex];

        // Correctly retrieving coordinates from the Map
        const currentCoords = this.hardcodedCoords.get(currentIndex);
        const swapCoords = this.hardcodedCoords.get(swapIndex);

        if (!currentCoords || !swapCoords) {
            console.error('Coordinates not found for one or both indices, cancelling the swap.');
            return;
        }

        // Utilize the helper method to swap positions
        this.swapGameObjectPositions(currentEntry.baseSprite, swapEntry.baseSprite, currentCoords.heroSprite.y, swapCoords.heroSprite.y);
        this.swapGameObjectPositions(currentEntry.maleHairSprite, swapEntry.maleHairSprite, currentCoords.heroSprite.y, swapCoords.heroSprite.y);
        this.swapGameObjectPositions(currentEntry.femaleHairSprite, swapEntry.femaleHairSprite, currentCoords.heroSprite.y, swapCoords.heroSprite.y);
        this.swapGameObjectPositions(currentEntry.primaryClothingSprite, swapEntry.primaryClothingSprite, currentCoords.heroSprite.y, swapCoords.heroSprite.y);
        this.swapGameObjectPositions(currentEntry.secondaryClothingSprite, swapEntry.secondaryClothingSprite, currentCoords.heroSprite.y, swapCoords.heroSprite.y);
        this.swapGameObjectPositions(currentEntry.tertiaryClothingSprite, swapEntry.tertiaryClothingSprite, currentCoords.heroSprite.y, swapCoords.heroSprite.y);
        this.swapGameObjectPositions(currentEntry.weaponRunebladeBottomSprite, swapEntry.weaponRunebladeBottomSprite, currentCoords.heroSprite.y, swapCoords.heroSprite.y);
        this.swapGameObjectPositions(currentEntry.weaponRunebladeTopSprite, swapEntry.weaponRunebladeTopSprite, currentCoords.heroSprite.y, swapCoords.heroSprite.y);
        this.swapGameObjectPositions(currentEntry.weaponAethermancerBottomSprite, swapEntry.weaponAethermancerBottomSprite, currentCoords.heroSprite.y, swapCoords.heroSprite.y);
        this.swapGameObjectPositions(currentEntry.weaponAethermancerTopSprite, swapEntry.weaponAethermancerTopSprite, currentCoords.heroSprite.y, swapCoords.heroSprite.y);
        this.swapGameObjectPositions(currentEntry.weaponLifeweaverBottomSprite, swapEntry.weaponLifeweaverBottomSprite, currentCoords.heroSprite.y, swapCoords.heroSprite.y);
        this.swapGameObjectPositions(currentEntry.weaponLifeweaverTopSprite, swapEntry.weaponLifeweaverTopSprite, currentCoords.heroSprite.y, swapCoords.heroSprite.y);
        this.swapGameObjectPositions(currentEntry.memberText, swapEntry.memberText, currentCoords.memberText.y, swapCoords.memberText.y);

        // Swap the 'index' properties of the two partyEntries
        [currentEntry.index, swapEntry.index] = [swapIndex, currentIndex];

        // Swap their positions in the partyEntries array
        [this.partyEntries[currentIndex], this.partyEntries[swapIndex]] = [this.partyEntries[swapIndex], this.partyEntries[currentIndex]];
    }

    // ... additional methods or logic specific to PartyOrderMenu.
}

type PlayerCoords = {
    reorderUpButton?: { x: number; y: number };
    reorderDownButton?: { x: number; y: number };
    numberText: { x: number; y: number };
    memberText: { x: number; y: number };
    heroSprite: { x: number; y: number };
}
