import {Socket} from 'socket.io-client';
import {UIImageKey} from '../../../../../../types/assets/AssetKeys';
import {DialogueNode, DialogueOption} from '../../../../../../types/dialogue/DialogueTypes';
import {ServerSocketEvents} from '../../../../../../types/events/ServerSocketEvents';
import {mainGameFont} from '../../../../GameConfig';
import ServerControlledGameUIScene from '../../../../scenes/ServerControlledUIScenes/ServerControlledGameUIScene';
import {DepthLevel} from '../../../../types/DepthLevel';
import {FieldMenuType} from '../../../../types/FieldMenuType';
import NonContainerUIActionButton from '../../ActionButtons/NonContainerUIActionButton';
import ResizableFrame from '../../UtilityComponents/ResizableFrame';
import {GameFieldActionMenu} from './GameFieldActionMenu';

export default class CommonerNPCMenuElements extends GameFieldActionMenu {
    public shown: boolean = false;
    public menuTag = FieldMenuType.CommonerNPCMenu;
    public relatedMenusToDismiss: Set<FieldMenuType> = new Set([
        FieldMenuType.AbilityMenu,
        FieldMenuType.CharacterSheetMenu,
        FieldMenuType.TargetMenu,
        FieldMenuType.DuelNotification,
        FieldMenuType.DismissibleNotification,
        FieldMenuType.InviteNotification,
        FieldMenuType.PartyOrderMenu,
        FieldMenuType.MessageElement,
        FieldMenuType.InventoryMenu,
        FieldMenuType.TradeMenu,
        FieldMenuType.InteractableMessageDisplay,

        FieldMenuType.InteractionMenu,
        // Any other menu that should be dismissed when AbilityMenu is active
    ]);
    public canCoincideWithGamePad = false;

    public dialogueFrame: ResizableFrame;
    public dialogueOptionsFrames: ResizableFrame[] = [];
    public dialogueText: Phaser.GameObjects.Text;
    public npcNameText: Phaser.GameObjects.Text;
    public optionButtons: NonContainerUIActionButton[] = []; // New member to keep track of buttons

    public constructor(public scene: ServerControlledGameUIScene, private socket: Socket) {
        super();
        const centerX = scene.cameras.main.centerX;
        const centerY = scene.cameras.main.centerY;

        const dialogueFrameCenterXWithOffset = centerX - 109;
        const dialogueFrameCenterYWithOffset = centerY + 125;

        const dialogueOptionFrameCenterXWithOffset = centerX + 321;

        // Create the main resizable frame
        this.dialogueFrame = new ResizableFrame(this.scene, dialogueFrameCenterXWithOffset, dialogueFrameCenterYWithOffset, 617, 221);
        this.dialogueFrame.hideFrame();

        // Pre-create multiple option frames
        const frameHeights = [64, 116, 168, 221]; // Updated Heights for 1, 2, 3, and 4 options

        const topYForDialogueFrame = dialogueFrameCenterYWithOffset - (221 / 2); // Top Y-coordinate for dialogue frame

        for (let i = 0; i < 4; i++) {
            const frameCenterY = topYForDialogueFrame + (frameHeights[i] / 2); // New center Y-coordinate for each option frame

            const frame = new ResizableFrame(
                this.scene,
                dialogueOptionFrameCenterXWithOffset,
                frameCenterY,
                194,
                frameHeights[i]
            );

            frame.hideFrame();
            this.dialogueOptionsFrames.push(frame);
        }

        // Create NPC name text
        this.npcNameText = this.scene.add.text(dialogueFrameCenterXWithOffset - 300, dialogueFrameCenterYWithOffset - 110, '', {
            fontFamily: mainGameFont,
            fontSize: '36px',
            color: '#ffffff'
        })
            .setDepth(DepthLevel.UI_PRIMARY_TEXT)
            .setResolution(3);

        // Create main dialogue text
        this.dialogueText = this.scene.add.text(dialogueFrameCenterXWithOffset - 300, dialogueFrameCenterYWithOffset - 78, '', {
            fontFamily: mainGameFont,
            fontSize: '36px',
            color: '#ffffff',
            wordWrap: {
                width: 610, // wrap width
                useAdvancedWrap: true
            },
            metrics: {
                fontSize: 38,
                ascent: 31,
                descent: 7
            }
        })
            .setDepth(DepthLevel.UI_PRIMARY_TEXT)
            .setResolution(3)
            .setLineSpacing(-10); // Negative value to reduce line spacing

        // const testText = this.scene.scene.add.text(0, 0, "metrics", {fontFamily: gameFont, fontSize: '34px'})
        const testMetrics2 = this.dialogueText.getTextMetrics();
        console.log('npc dialogue test metrics:');
        console.log({testMetrics2});

        // Pre-instantiate four option buttons
        let optionY = dialogueFrameCenterYWithOffset - 79;
        for (let i = 0; i < 4; i++) {
            const optionButton = new NonContainerUIActionButton(
                this.scene,
                dialogueOptionFrameCenterXWithOffset - 70, // X Position
                optionY, // Y Position
                UIImageKey.CheckButton, // Replace with your inactive texture key
                UIImageKey.CheckButton, // Replace with your active texture key
                'init', // Initially empty text
                () => {
                    // Emit an event to the server with the dialogue option ID
                    console.log('[CommonerNPCMenuElements option callback] entering callback');
                    console.log(`[CommonerNPCMenuElements option callback] index: ${i}`);
                    this.socket.emit(ServerSocketEvents.NPCDialogueOptionIndexSelected, {index: i});
                }
            );

            // Optionally set the button to hidden initially
            optionButton.hideActionButton();

            this.optionButtons.push(optionButton);
            optionY += 52;
        }
    }

    public emitMenuDismissalRequest(): void{
        this.dismiss();
    }

    public emitMenuActivationRequest(params?: {npcName: string, dialogue: string, options: DialogueOption[]}): void {
        // Check if the player is currently processing a field ability
        if (
            this.scene.serverControlledGameScene.player.processingFieldAction
        ) {
            console.log('[CommonerNpcMenuElements.emitMenuActivationRequest] Player is processing a field action. Exiting early.');
            return; // Exit early
        }

        console.log('[CommonerNpcMenuElements.emitMenuActivationRequest] Emitting attempt to open commoner npc menu event with params: ', params);
        if (params) {
            this.activate(params);
        }
    }

    // Method to show main frame, NPC name, dialogue text, and option buttons
    public activate(params: {npcName: string, dialogue: string, options: DialogueOption[]}): void {
        this.shown = true;
        console.log(`[CommonerNPCMenuElements.activate] entering method. Activating Commoner NPC Menu with params: , ${JSON.stringify(params, null, 2)}`);
        this.dismissRelatedMenus();
        this.dialogueFrame.showFrame();
        this.scene.serverControlledGameScene.player.inNPCDialogue = true;

        // Hide all option frames
        for (const frame of this.dialogueOptionsFrames) {
            frame.hideFrame();
        }

        // Determine the correct dialogue options frame based on the number of options
        const index = params.options.length - 1; // since index is zero-based and length is one-based
        if (index >= 0 && index < this.dialogueOptionsFrames.length) {
            this.dialogueOptionsFrames[index].showFrame();
        }

        // Set NPC name and dialogue text
        this.npcNameText.setText(`${params.npcName}:`).setVisible(true);
        this.dialogueText.setText(params.dialogue).setVisible(true);

        // Hide all option buttons initially
        for (const button of this.optionButtons) {
            button.hideActionButton();
        }

        // Update and display only the necessary option buttons
        for (let i = 0; i < params.options.length; i++) {
            this.optionButtons[i].changeButtonText(params.options[i].text);
            this.optionButtons[i].changeButtonImage(params.options[i].buttonKey, params.options[i].buttonKey);
            this.optionButtons[i].showActionButton();
        }
        console.log('[CommonerNPCMenuElements.activate] Exiting method.');
    }

    // Method to update the dialogue text and options based on a DialogueNode
    public updateDialogue(dialogueNode: DialogueNode): void {
        // Update the main dialogue text
        this.dialogueText.setText(dialogueNode.text);

        // Hide all option buttons initially
        for (const button of this.optionButtons) {
            button.hideActionButton();
        }

        // Update and display only the necessary option buttons
        for (let i = 0; i < dialogueNode.options.length; i++) {
            const option = dialogueNode.options[i];
            this.optionButtons[i].changeButtonText(option.text);
            this.optionButtons[i].changeButtonImage(option.buttonKey, option.buttonKey);
            this.optionButtons[i].showActionButton();
        }

        // Hide the unused frames
        for (const frame of this.dialogueOptionsFrames) {
            frame.hideFrame();
        }

        // Determine the correct dialogue options frame based on the number of new options
        const index = dialogueNode.options.length - 1; // since index is zero-based and length is one-based
        if (index >= 0 && index < this.dialogueOptionsFrames.length) {
            this.dialogueOptionsFrames[index].showFrame();
        }
    }

    // Method to hide main frame and text
    public dismiss(): void {
        console.log('[CommonerNPCMenuElements.dismiss] Entering method. Dismissing Commoner NPC Menu');

        // Set the state to indicate the dialogue has ended and hide all dialogue-related UI elements
        this.scene.serverControlledGameScene.player.inNPCDialogue = false;
        this.hideDialogueElements();

        // Additional state management
        this.activateInteractionMenuIfNeeded();
        this.shown = false;
        console.log('[CommonerNPCMenuElements.dismiss] Exiting method.');
    }

    public hideDialogueElements(): void {
    // Hide the main dialogue frame
        this.dialogueFrame.hideFrame();

        // Hide all dialogue option frames
        for (const frame of this.dialogueOptionsFrames) {
            frame.hideFrame();
        }

        // Hide texts
        this.dialogueText.setVisible(false);
        this.npcNameText.setVisible(false);

        // Hide all dialogue option buttons
        for (const optionButton of this.optionButtons) {
            optionButton.hideActionButton();
        }
    }

}
