import Phaser from 'phaser';
import {SpriteSheet, UIImageKey} from '../../../../types/assets/AssetKeys';
import {CharacterProfession} from '../../../../types/mechanics/CharacterProfessionsEnum';
import {Gender} from '../../../../types/mechanics/Gender';
import {heroWeaponColor} from '../../data/heroWeaponColor';
import {tertiaryColor} from '../../data/tertiaryColor';
import ServerControlledConflictUIScene from '../../scenes/ServerControlledUIScenes/ServerControlledConflictUIScene';
import {EnemyComponent} from '../../types/conflict/EnemyComponent';
import {ConflictSceneData} from '../../types/ConflictSceneData';
import {DepthLevel} from '../../types/DepthLevel';

export class EnemyManager {
    private scene: ServerControlledConflictUIScene;
    public enemyComponents: EnemyComponent[] = [];
    public enemyBadges: Phaser.GameObjects.Image[] = []; // Array to hold badge references
    public isTargetingModeActive: boolean = false; // Flag to track targeting mode state
    private badgeFlashTimeout: NodeJS.Timeout | null = null; // Store the interval ID here

    public constructor(scene: ServerControlledConflictUIScene) {
        this.scene = scene;

        this.createEnemyBadges();

        // Listen to scene events
        this.scene.events.on('shutdown', this.pauseFlashingBadges, this);
        this.scene.events.on('pause', this.pauseFlashingBadges, this);
    }

    private flashBadges(): void {
        const livingEnemies = this.enemyComponents.filter(component => component.isAlive);
        this.enemyBadges.forEach((badge, index) => {
            if (index < livingEnemies.length) {
                // Toggle visibility to make badge visible
                badge.setVisible(true);
            }
        });

        // Set the timeout for the 'on' state (visible for 1 second)
        this.badgeFlashTimeout = setTimeout(() => {
            this.enemyBadges.forEach((badge, index) => {
                if (index < livingEnemies.length) {
                    // Toggle visibility to make badge invisible
                    badge.setVisible(false);
                }
            });

            // Set another timeout for the 'off' state (invisible for 0.5 seconds)
            this.badgeFlashTimeout = setTimeout(() => {
                this.flashBadges(); // Recursively call to continue the flashing loop
            }, 500);
        }, 1000);
    }

    private startFlashingBadges(): void {
        if (this.badgeFlashTimeout !== null) {
            return;
        } // Prevent multiple flash loops
        this.flashBadges(); // Start the flashing loop
    }

    private pauseFlashingBadges(): void {
        if (this.badgeFlashTimeout !== null) {
            clearTimeout(this.badgeFlashTimeout);
            this.badgeFlashTimeout = null;
        }
    }

    private stopFlashingBadges(): void {
        this.pauseFlashingBadges();

        // Ensure all badges are visible when stopping the flash
        this.enemyBadges.forEach(badge => badge.setVisible(false));
    }

    public hideAllEnemyBadges(): void {
        this.stopFlashingBadges(); // Stop flashing when badges are hidden
        this.enemyBadges.forEach(badge => badge.setVisible(false));
    }

    public showBadgesForLivingEnemies(): void {
        console.log('[showBadgesForLivingEnemies] Showing badges for living enemies.');
        const livingEnemies = this.enemyComponents.filter(component => component.isAlive);
        this.enemyBadges.forEach((badge, index) => {
            badge.setVisible(index < livingEnemies.length);
        });
        this.startFlashingBadges(); // Start flashing only for visible badges
    }

    public createEnemyBadges(): void {
        // Create badge images and initially set them as invisible, with specified depth and scale
        this.enemyBadges.push(this.scene.add.image(0, 0, UIImageKey.BadgeOne).setVisible(false).setDepth(DepthLevel.NON_UI_GRAPHICS_TOP).setScale(2));
        this.enemyBadges.push(this.scene.add.image(0, 0, UIImageKey.BadgeTwo).setVisible(false).setDepth(DepthLevel.NON_UI_GRAPHICS_TOP).setScale(2));
        this.enemyBadges.push(this.scene.add.image(0, 0, UIImageKey.BadgeThree).setVisible(false).setDepth(DepthLevel.NON_UI_GRAPHICS_TOP).setScale(2));
        this.enemyBadges.push(this.scene.add.image(0, 0, UIImageKey.BadgeFour).setVisible(false).setDepth(DepthLevel.NON_UI_GRAPHICS_TOP).setScale(2));
        this.enemyBadges.push(this.scene.add.image(0, 0, UIImageKey.BadgeFive).setVisible(false).setDepth(DepthLevel.NON_UI_GRAPHICS_TOP).setScale(2));
        this.enemyBadges.push(this.scene.add.image(0, 0, UIImageKey.BadgeSix).setVisible(false).setDepth(DepthLevel.NON_UI_GRAPHICS_TOP).setScale(2));
        this.enemyBadges.push(this.scene.add.image(0, 0, UIImageKey.BadgeSeven).setVisible(false).setDepth(DepthLevel.NON_UI_GRAPHICS_TOP).setScale(2));
        this.enemyBadges.push(this.scene.add.image(0, 0, UIImageKey.BadgeEight).setVisible(false).setDepth(DepthLevel.NON_UI_GRAPHICS_TOP).setScale(2));
        this.enemyBadges.push(this.scene.add.image(0, 0, UIImageKey.BadgeNine).setVisible(false).setDepth(DepthLevel.NON_UI_GRAPHICS_TOP).setScale(2));
    }

    public initializeAndPositionEnemySprites(param: ConflictSceneData): void {
        this.clearEnemyComponentSprites();
        // Determine enemy X coordinates based on the number of enemies
        // const centerX = this.scene.cameras.main.width / 2;
        const enemyXCoords = this.calculateEnemyXCoords(param.enemies.length);

        // Create enemy components
        this.enemyComponents = param.enemies.map((enemy, index) => {
            console.log(`[initializeAndPositionEnemies] Creating enemy component for enemy ${index + 1}.`);
            console.log(`[initializeAndPositionEnemies] Setting enemyIsAlive to ${enemy.IsAlive} for ${enemy.Name}.`);
            const enemyIsAlive = enemy.IsAlive;
            let enemyGender: Gender | undefined = undefined;
            let enemyClassName: CharacterProfession | undefined = undefined;
            let baseSprite: Phaser.GameObjects.Sprite;
            let maleHairSprite;
            let femaleHairSprite;
            let primaryColorSprite;
            let secondaryColorSprite;
            let tertiaryColorSprite;
            let runeBladeBottomWeaponSprite;
            let aethermancerBottomWeaponSprite;
            let lifeweaverBottomWeaponSprite;
            let runeBladeTopWeaponSprite;
            let aethermancerTopWeaponSprite;
            let lifeweaverTopWeaponSprite;
            let enemyType: 'Monster' | 'Player' | undefined;
            const enemyYCoord = 380;
            if (this.scene.isTruncatedPlayerData(enemy)) {
                console.log(`[initializeAndPositionEnemies] Truncated player data detected for ${enemy.Name}.`);
                enemyType = 'Player';
                enemyGender = enemy.Gender;
                enemyClassName = enemy.ClassName;
                runeBladeBottomWeaponSprite = this.scene.add.sprite(enemyXCoords[index], enemyYCoord, SpriteSheet.RunebladeWeaponBottom)
                    .setScale(2)
                    .setOrigin(0.5, 1)
                    .setTint(Number(heroWeaponColor))
                    .setDepth(DepthLevel.NON_UI_GRAPHICS_MID)
                    .setVisible(false);
                aethermancerBottomWeaponSprite = this.scene.add.sprite(enemyXCoords[index], enemyYCoord, SpriteSheet.AethermancerWeaponBottom)
                    .setScale(2)
                    .setOrigin(0.5, 1)
                    .setTint(Number(heroWeaponColor))
                    .setDepth(DepthLevel.NON_UI_GRAPHICS_MID)
                    .setVisible(false);
                lifeweaverBottomWeaponSprite = this.scene.add.sprite(enemyXCoords[index], enemyYCoord, SpriteSheet.LifeweaverWeaponBottom)
                    .setScale(2)
                    .setOrigin(0.5, 1)
                    .setTint(Number(heroWeaponColor))
                    .setDepth(DepthLevel.NON_UI_GRAPHICS_MID)
                    .setVisible(false);

                baseSprite = this.scene.add.sprite(enemyXCoords[index], enemyYCoord, SpriteSheet.HeroBaseSprite)
                    .setScale(2)
                    .setOrigin(0.5, 1)
                    .setTint(Number(enemy.SkinColor))
                    .setDepth(DepthLevel.NON_UI_GRAPHICS_MID)
                    .setVisible(false);

                primaryColorSprite = this.scene.add.sprite(enemyXCoords[index], enemyYCoord, SpriteSheet.HeroClothesPrimary)
                    .setScale(2)
                    .setOrigin(0.5, 1)
                    .setTint(Number(enemy.PrimaryColor))
                    .setDepth(DepthLevel.NON_UI_GRAPHICS_MID)
                    .setVisible(false);

                secondaryColorSprite = this.scene.add.sprite(enemyXCoords[index], enemyYCoord, SpriteSheet.HeroClothesSecondary)
                    .setScale(2)
                    .setOrigin(0.5, 1)
                    .setTint(Number(enemy.SecondaryColor))
                    .setDepth(DepthLevel.NON_UI_GRAPHICS_MID)
                    .setVisible(false);

                tertiaryColorSprite = this.scene.add.sprite(enemyXCoords[index], enemyYCoord, SpriteSheet.HeroClothesTertiary)
                    .setScale(2)
                    .setOrigin(0.5, 1)
                    .setTint(Number(tertiaryColor))
                    .setDepth(DepthLevel.NON_UI_GRAPHICS_MID)
                    .setVisible(false);

                maleHairSprite = this.scene.add.sprite(enemyXCoords[index], enemyYCoord, SpriteSheet.HeroHairMale)
                    .setScale(2)
                    .setOrigin(0.5, 1)
                    .setTint(Number(enemy.HairColor))
                    .setDepth(DepthLevel.NON_UI_GRAPHICS_MID)
                    .setVisible(false);
                femaleHairSprite = this.scene.add.sprite(enemyXCoords[index], enemyYCoord, SpriteSheet.HeroHairFemale)
                    .setScale(2)
                    .setOrigin(0.5, 1)
                    .setTint(Number(enemy.HairColor))
                    .setDepth(DepthLevel.NON_UI_GRAPHICS_MID)
                    .setVisible(false);
                runeBladeTopWeaponSprite = this.scene.add.sprite(enemyXCoords[index], enemyYCoord, SpriteSheet.RunebladeWeaponTop)
                    .setScale(2)
                    .setOrigin(0.5, 1)
                    .setTint(Number(heroWeaponColor))
                    .setDepth(DepthLevel.NON_UI_GRAPHICS_MID)
                    .setVisible(false);
                aethermancerTopWeaponSprite = this.scene.add.sprite(enemyXCoords[index], enemyYCoord, SpriteSheet.AethermancerWeaponTop)
                    .setScale(2)
                    .setOrigin(0.5, 1)
                    .setTint(Number(heroWeaponColor))
                    .setDepth(DepthLevel.NON_UI_GRAPHICS_MID)
                    .setVisible(false);
                lifeweaverTopWeaponSprite = this.scene.add.sprite(enemyXCoords[index], enemyYCoord, SpriteSheet.LifeweaverWeaponTop)
                    .setScale(2)
                    .setOrigin(0.5, 1)
                    .setTint(Number(heroWeaponColor))
                    .setDepth(DepthLevel.NON_UI_GRAPHICS_MID)
                    .setVisible(false);
            } else {
                console.log(`[initializeAndPositionEnemies] Truncated player data not detected for ${enemy.Name}. (must be a monster)`);
                enemyType = 'Monster';
                // Before sprite configuration
                console.log('Before configuration:');
                console.log('Visibility:', baseSprite! ? baseSprite.visible : 'Sprite not created yet');
                console.log('Depth:', baseSprite! ? baseSprite.depth : 'Sprite not created yet');
                console.log(`creating sprite for ${enemy.Name} at x: ${enemyXCoords[index]}, y: ${enemyYCoord}`);

                // Sprite creation and configuration
                baseSprite = this.scene.add.sprite(enemyXCoords[index], enemyYCoord, enemy.Key)
                    .setScale(1.5)
                    .setOrigin(0.5, 1)
                    .setDepth(DepthLevel.NON_UI_GRAPHICS_MID)
                    .setVisible(false);

                // After sprite configuration
                console.log('After configuration:');
                console.log('Visibility:', baseSprite?.visible);
                console.log('Depth:', baseSprite?.depth);
                console.log(`does baseSprite exist? ${baseSprite ? 'yes' : 'no'}`);

            }

            const button = this.scene.add.rectangle(enemyXCoords[index], enemyYCoord, baseSprite.displayWidth, baseSprite.displayHeight, 0xFF0000, 0)
                .setOrigin(0.5, 1)
                .setInteractive()
                .setDepth(DepthLevel.NON_UI_GRAPHICS_TOP)
                .setVisible(false)
                .setAlpha(0.7);

            button.on('pointerdown', () => this.scene.controller.handleEnemyClick(index));

            return {
                name: enemy.Name,
                type: enemyType,
                isAlive: enemyIsAlive,
                className: enemyClassName,
                gender: enemyGender,
                baseSprite: baseSprite,
                maleHairSprite: maleHairSprite,
                femaleHairSprite: femaleHairSprite,
                primaryClothingSprite: primaryColorSprite,
                secondaryClothingSprite: secondaryColorSprite,
                tertiaryClothingSprite: tertiaryColorSprite,
                runebladeBottomWeaponSprite: runeBladeBottomWeaponSprite,
                aethermancerBottomWeaponSprite: aethermancerBottomWeaponSprite,
                lifeweaverBottomWeaponSprite: lifeweaverBottomWeaponSprite,
                runebladeTopWeaponSprite: runeBladeTopWeaponSprite,
                aethermancerTopWeaponSprite: aethermancerTopWeaponSprite,
                lifeweaverTopWeaponSprite: lifeweaverTopWeaponSprite,
                button: button
            };
        });
    }

    private clearEnemyComponentSprites(): void {
        // Loop through each enemy component and destroy all sprites
        this.enemyComponents.forEach(component => {
            component.baseSprite.destroy();
            component.maleHairSprite?.destroy(); // Optional chaining if the sprite might not exist
            component.femaleHairSprite?.destroy(); // Optional chaining if the sprite might not exist
            component.primaryClothingSprite?.destroy();
            component.secondaryClothingSprite?.destroy();
            component.tertiaryClothingSprite?.destroy(); // Optional chaining if the sprite might not exist
            component.runebladeBottomWeaponSprite?.destroy(); // Optional chaining if the sprite might not exist
            component.aethermancerBottomWeaponSprite?.destroy(); // Optional chaining if the sprite might not exist
            component.lifeweaverBottomWeaponSprite?.destroy(); // Optional chaining if the sprite might not exist
            component.runebladeTopWeaponSprite?.destroy(); // Optional chaining if the sprite might not exist
            component.aethermancerTopWeaponSprite?.destroy(); // Optional chaining if the sprite might not exist
            component.lifeweaverTopWeaponSprite?.destroy(); // Optional chaining if the sprite might not exist
            component.button.destroy();
        });

        // Clear the array of enemy components
        this.enemyComponents = [];
    }

    public repositionEnemySprites(): void {
        // Filter out the enemy components that are alive
        const aliveEnemyComponents = this.enemyComponents.filter(component => component.isAlive);

        if (aliveEnemyComponents.length === 0) {
            return;
        }

        // Determine new X coordinates based on the number of alive enemies
        const enemyXCoords = this.calculateEnemyXCoords(aliveEnemyComponents.length);

        // Reposition the base sprite of each alive enemy component
        aliveEnemyComponents.forEach((component, index) => {
            component.baseSprite.x = enemyXCoords[index];
            if (component.maleHairSprite) {
                component.maleHairSprite.x = enemyXCoords[index];
            }
            if (component.femaleHairSprite) {
                component.femaleHairSprite.x = enemyXCoords[index];
            }
            if (component.primaryClothingSprite) {
                component.primaryClothingSprite.x = enemyXCoords[index];
            }
            if (component.secondaryClothingSprite) {
                component.secondaryClothingSprite.x = enemyXCoords[index];
            }
            if (component.tertiaryClothingSprite) {
                component.tertiaryClothingSprite.x = enemyXCoords[index];
            }
            if (component.runebladeBottomWeaponSprite) {
                component.runebladeBottomWeaponSprite.x = enemyXCoords[index];
            }
            if (component.aethermancerBottomWeaponSprite) {
                component.aethermancerBottomWeaponSprite.x = enemyXCoords[index];
            }
            if (component.lifeweaverBottomWeaponSprite) {
                component.lifeweaverBottomWeaponSprite.x = enemyXCoords[index];
            }
            if (component.runebladeTopWeaponSprite) {
                component.runebladeTopWeaponSprite.x = enemyXCoords[index];
            }
            if (component.aethermancerTopWeaponSprite) {
                component.aethermancerTopWeaponSprite.x = enemyXCoords[index];
            }
            if (component.lifeweaverTopWeaponSprite) {
                component.lifeweaverTopWeaponSprite.x = enemyXCoords[index];
            }
            component.button.x = enemyXCoords[index];
        });
    }

    public repositionEnemyBadges(): void {
        console.log('[repositionEnemyBadges] Repositioning enemy badges based on the living enemies.');

        // Start by setting all badges to an initial position that keeps them out of view if not needed.
        this.enemyBadges.forEach((badge, index) => {
            badge.setPosition(0, 0);
            console.log(`[repositionEnemyBadges] Badge ${index + 1} set to initial out-of-view position.`);
        });

        // Filter out the enemy components that are alive to determine where to place enemyBadges.
        const aliveEnemyComponents = this.enemyComponents.filter(component => component.isAlive);
        console.log(`[repositionEnemyBadges] Found ${aliveEnemyComponents.length} living enemies.`);

        // Reposition each badge based on the number of alive enemies, placing a badge above each enemy.
        aliveEnemyComponents.forEach((component, index) => {
            if (index < this.enemyBadges.length) { // Check to ensure there's a badge available for the enemy
                const enemyBadge = this.enemyBadges[index];
                enemyBadge.setPosition(component.baseSprite.x, component.baseSprite.y + 10); // Adjust y-offset as needed
                console.log(`[repositionEnemyBadges] Badge ${index + 1} positioned above enemy ${component.name} at x: ${component.baseSprite.x}, y: ${component.baseSprite.y + 10}`);
            } else {
                console.log(`[repositionEnemyBadges] No badge available for enemy ${component.name} at index ${index + 1}.`);
            }
        });
    }

    private calculateEnemyXCoords(enemyCount: number): number[] {
        const centerX = this.scene.cameras.main.width / 2;
        let enemyXCoords: number[] = [];
        let offset: number;

        switch (enemyCount) {
            case 1:
                enemyXCoords = [centerX];
                break;
            case 2:
                offset = 150;
                enemyXCoords = [centerX - offset, centerX + offset];
                break;
            case 3:
                offset = 200;
                enemyXCoords = [centerX - offset, centerX, centerX + offset];
                break;
            case 4:
                offset = 150;
                enemyXCoords = [
                    centerX - 1.5 * offset,
                    centerX - 0.5 * offset,
                    centerX + 0.5 * offset,
                    centerX + 1.5 * offset,
                ];
                break;
            case 5:
                offset = 150;
                enemyXCoords = [
                    centerX - 2 * offset,
                    centerX - offset,
                    centerX,
                    centerX + offset,
                    centerX + 2 * offset,
                ];
                break;
            case 6:
                offset = 150;
                enemyXCoords = [
                    centerX - 2.5 * offset,
                    centerX - 1.5 * offset,
                    centerX - 0.5 * offset,
                    centerX + 0.5 * offset,
                    centerX + 1.5 * offset,
                    centerX + 2.5 * offset,
                ];
                break;
            default:
                throw new Error('Unsupported number of enemies for repositioning');
        }

        return enemyXCoords;
    }

    public handleEnemySelect(visibleEnemyNumber: number): void {
        console.log(`[handleEnemySelect] visibleEnemyNumber: ${visibleEnemyNumber}.`);
        console.log(`[handleEnemySelect] calling this.scene.controller.handleEnemyClick() with visible enemy number - 1: (${visibleEnemyNumber - 1})`);
        let deadEnemiesLeftOfSelectedEnemyCount = 0;
        for (let i = 0; i < visibleEnemyNumber; i++) {
            if (!this.enemyComponents[i].isAlive) {
                deadEnemiesLeftOfSelectedEnemyCount++;
            }
        }

        this.scene.controller.handleEnemyClick(visibleEnemyNumber - 1 + deadEnemiesLeftOfSelectedEnemyCount);
    }
}
