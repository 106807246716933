import numberToWords from 'number-to-words';
import {Socket} from 'socket.io-client';
import {UIImageKey} from '../../../../../../types/assets/AssetKeys';
import {ServerSocketEvents} from '../../../../../../types/events/ServerSocketEvents';
import {
    AttemptArmOrEquipFieldInventoryItemResponseEvent
} from '../../../../../../types/inventory/AttemptArmOrEquipFieldInventoryItemResponseEvent';
import {
    SuccessEquipmentItemState
} from '../../../../../../types/inventory/AttemptSelectFieldEquipmentItemResponseEvent';
import {EquipmentItemForDisplay, InventoryItemForDisplay} from '../../../../../../types/inventory/ItemForDisplay';
import {ItemBindingType, ItemType} from '../../../../../../types/items/ItemTypes';
// import {characterProfessionsArray} from '../../../../../../types/mechanics/CharacterProfessionsArray';
import {CharacterProfession} from '../../../../../../types/mechanics/CharacterProfessionsEnum';
import {statAbbreviations} from '../../../../../../types/mechanics/StatAbbreviations';
import {mainGameFont} from '../../../../GameConfig';
import ServerControlledGameUIScene from '../../../../scenes/ServerControlledUIScenes/ServerControlledGameUIScene';
import {DepthLevel} from '../../../../types/DepthLevel';
import {FieldMenuType} from '../../../../types/FieldMenuType';
import NonContainerUIActionButton from '../../ActionButtons/NonContainerUIActionButton';
import ResizableFrame from '../../UtilityComponents/ResizableFrame';
import {GameFieldActionMenu} from './GameFieldActionMenu';

export class FieldInventoryMenu extends GameFieldActionMenu {
    // Section 1: Class Properties - Menu Identification
    public readonly menuTag: FieldMenuType= FieldMenuType.InventoryMenu;
    public relatedMenusToDismiss: Set<FieldMenuType> = new Set([
        FieldMenuType.CharacterSheetMenu,
        FieldMenuType.TargetMenu,
        FieldMenuType.DuelNotification,
        FieldMenuType.DismissibleNotification,
        FieldMenuType.InviteNotification,
        FieldMenuType.PartyOrderMenu,
        FieldMenuType.CommonerNPCMenu,
        FieldMenuType.InnkeeperNPCMenu,
        FieldMenuType.MerchantNPCMenu,
        FieldMenuType.MessageElement,
        FieldMenuType.AbilityMenu,
        FieldMenuType.TradeMenu,
        FieldMenuType.InteractableMessageDisplay,

        FieldMenuType.InteractionMenu,
    ]);
    public canCoincideWithGamePad = true;

    // Section 2: UI Components
    // Subsection 2.1: Shared Frames
    private inventoryGoldFrame: ResizableFrame;

    // Subsection 2.2: Initial View Components
    private inventoryMainListFrame: ResizableFrame;
    private inventoryCategoryFrame: ResizableFrame;
    private inventoryMainListCancelFrame: ResizableFrame;
    private inventoryMainCategoryButton: NonContainerUIActionButton;
    private inventorySecondaryCategoryButton: NonContainerUIActionButton;
    private inventoryMainListCancelButton: NonContainerUIActionButton;
    public inventoryMainListButtonInfos: {
        button: NonContainerUIActionButton;
        exists: boolean;
        selected: boolean
    }[] = [];
    public equipmentButtonInfos: Map<ItemType.Helmet | ItemType.BodyArmor | ItemType.Weapon | ItemType.OffHand, {
        button: NonContainerUIActionButton;
        exists: boolean;
        selected: boolean;
    }> = new Map();
    private coinIcon: Phaser.GameObjects.Image;
    private soulboundIcon: Phaser.GameObjects.Image;
    private goldText: Phaser.GameObjects.Text;

    // Subsection 2.3: Detail View Components
    private inventoryDetailFrame: ResizableFrame;
    private inventoryDetailConfirmButton: NonContainerUIActionButton;
    private inventoryDetailCancelButton: NonContainerUIActionButton;
    private inventoryDetailDropButton: NonContainerUIActionButton;
    private inventoryDetailNameText: Phaser.GameObjects.Text;
    private inventoryDetailTypeText: Phaser.GameObjects.Text;
    private inventoryDetailDescriptionText: Phaser.GameObjects.Text;
    private inventoryDetailMinimumLevelText: Phaser.GameObjects.Text;
    private bindingStatusText: Phaser.GameObjects.Text;
    private inventoryVerticalDivider: Phaser.GameObjects.Graphics;
    private classesText: Phaser.GameObjects.Text;
    private inventoryActionFrame: ResizableFrame;

    private stat1Label: Phaser.GameObjects.Text;
    private stat1Value: Phaser.GameObjects.Text;
    private stat2Label: Phaser.GameObjects.Text;
    private stat2Value: Phaser.GameObjects.Text;
    private stat3Label: Phaser.GameObjects.Text;
    private stat3Value: Phaser.GameObjects.Text;
    private stat4Label: Phaser.GameObjects.Text;
    private stat4Value: Phaser.GameObjects.Text;
    private stat5Label: Phaser.GameObjects.Text;
    private stat5Value: Phaser.GameObjects.Text;
    private stat6Label: Phaser.GameObjects.Text;
    private stat6Value: Phaser.GameObjects.Text;

    private statLabels: Phaser.GameObjects.Text[];
    private statValues: Phaser.GameObjects.Text[];

    // Subsection 2.4: Armed View Components
    private inventoryArmedCancelFrame: ResizableFrame;
    private inventoryArmedDetailFrame: ResizableFrame;
    private inventoryArmedCommandFrame: ResizableFrame;
    private inventoryArmedCancelButton: NonContainerUIActionButton;
    private inventoryArmedDetailIcon: NonContainerUIActionButton;
    private inventoryArmedCommandText: Phaser.GameObjects.Text;

    // Section 3: Inventory State Management
    private selectedInventoryIndex: number;
    private selectedInventoryPageNumber: number;
    public inventoryItemIsArmed: boolean = false;
    public armedInventoryItemIndex: number;
    // add a prop for whether the selected item can be used or equipped
    public selectedInventoryItemCanBeUsedEquippedOrUnequipped: boolean;
    private headText: Phaser.GameObjects.Text;
    private bodyText: Phaser.GameObjects.Text;
    private mainHandText: Phaser.GameObjects.Text;
    private offHandText: Phaser.GameObjects.Text;
    private mode: 'inventory' | 'equipment' = 'inventory';
    private selectedEquipmentType: Exclude<ItemType, ItemType.Consumable> | undefined;
    private equippedItemSelected: boolean = false;
    private statsMap: Map<string, number> = new Map();

    public constructor(public scene: ServerControlledGameUIScene, private socket: Socket) {
        super();
        console.log('InventoryMenu constructor called');
        this.setupFrames();
        this.setupButtons();
        this.setupTexts();
        this.setupIcons();
        this.setupGraphics();
    }

    public activate(inventoryData: {itemArray: {name: string; key: UIImageKey; activeKey: UIImageKey}[], gold: number}): void {
        this.shown = true;
        console.log('[InventoryMenu.activate] Entering method. Activating inventory menu.');
        console.log(`[InventoryMenu.activate] Inventory data: ${JSON.stringify(inventoryData, null, 2)}`);
        this.dismissRelatedMenus();

        this.populateInitialView(inventoryData.itemArray, inventoryData.gold); // Populate the inventory list with items
        this.showInitialView(); // Display the inventory UI components

        // Logic to highlight or select the inventory button on the game action bar
        this.scene.gameActionBar.inventoryButton.select();
        console.log('[InventoryMenu.activate] Exiting method.');
    }

    // Method to show the initial view components
    private showInitialView(): void {
        // Show the main list frame, category frame, page one button, etc.
        this.inventoryMainListFrame.showFrame();
        this.inventoryCategoryFrame.showFrame();
        this.inventoryGoldFrame.showFrame();
        this.inventoryMainListCancelFrame.showFrame();
        this.inventoryMainCategoryButton.showActionButton();
        this.inventorySecondaryCategoryButton.showActionButton();
        this.inventoryMainListCancelButton.showActionButton();
        this.goldText.setVisible(true);
        this.coinIcon.setVisible(true);

        this.inventoryMainCategoryButton.select();
        this.inventorySecondaryCategoryButton.deselect();

        // Loop through and show only the existing inventory buttons
        this.inventoryMainListButtonInfos.forEach(buttonInfo => {
            if (buttonInfo.exists) {
                buttonInfo.button.showActionButton();
            } else {
                buttonInfo.button.hideActionButton();
            }
        });
    }

    private populateInitialView(items: {name: string, key: UIImageKey, activeKey: UIImageKey}[], gold: number): void {
        console.log(`[InventoryMenu.populateInitialView] Populating initial view with items: ${JSON.stringify(items, null, 2)}`);
        console.log(`[InventoryMenu.populateInitialView] Gold: ${gold}`);
        this.mode = 'inventory';
        // Ensure all buttons are reset to not exist before updating based on new abilities
        this.inventoryMainListButtonInfos.forEach(info => {
            info.exists = false;
            info.button.hideActionButton();
        });

        // Update buttons based on the abilities provided
        items.forEach((inventoryItem, index) => {
            if (index < this.inventoryMainListButtonInfos.length) {
                const buttonInfo = this.inventoryMainListButtonInfos[index];
                buttonInfo.exists = true;
                buttonInfo.button.changeButtonImage(inventoryItem.key, inventoryItem.activeKey);
                buttonInfo.button.changeButtonText(inventoryItem.name);
                buttonInfo.button.deselect();
                buttonInfo.button.showActionButton();

                // Update button target callback to emit event when inventory item is selected
                buttonInfo.button.targetCallback = (): void => {
                    console.log(`[inventory item callback] Item ${index} clicked.`);
                    const eventData = {inventoryItemIndex: index}; // Assuming pageIndex is always 0 for this setup
                    this.socket.emit(ServerSocketEvents.AttemptSelectFieldInventoryItem, eventData);
                };
            }
        });

        this.goldText.setText(`${gold.toLocaleString()} gp`);
    }

    public populateDetailViewForEquippedItems(data: SuccessEquipmentItemState): void {
        console.log(`[InventoryDetailElements.populateDetailViewForEquippedItems] Populating detail view with data: ${JSON.stringify(data, null, 2)}`);
        // Select the selected equipment button and deselect the others
        this.equipmentButtonInfos.forEach((buttonInfo, type) => {
            if (type === data.equipmentItemType) {
                buttonInfo.button.select();
                buttonInfo.selected = true;
            } else {
                buttonInfo.button.deselect();
                buttonInfo.selected = false;
            }
        });

        // Set the completed text to the inventory detail text field
        this.inventoryDetailNameText.setText(data.equipmentItemName);
        this.inventoryDetailDescriptionText.setText(data.equipmentItemDescription);

        this.bindingStatusText.setText(data.equipmentItemBindingType.charAt(0).toUpperCase() + data.equipmentItemBindingType.slice(1));

        // Check if the classes array exists and is not empty
        if (data.equipmentItemClasses && data.equipmentItemClasses.length > 0) {
            console.log('[InventoryDetailElements.populateDetailViewForEquippedItems] classes found, length is greater than 0');
            // Join the class names with newline characters for formatting
            const classesTextContent = data.equipmentItemClasses.join('\n');
            this.classesText.setText(classesTextContent);
        } else {
            console.log('[InventoryDetailElements.populateDetailViewForEquippedItems] classes not found or length is 0');
            // If there are no classes, set the text to empty and hide it
            this.classesText.setText('');
        }

        if (data.equipmentItemStats) {
            this.populateStats(data.equipmentItemStats);
        } else {
            this.depopulateStats();
        }

        // the first letter needs to be capitalized
        this.inventoryDetailTypeText.setText(data.equipmentItemType.charAt(0).toUpperCase() + data.equipmentItemType.slice(1));

        this.inventoryDetailConfirmButton.changeButtonText('Unequip');
        this.inventoryDetailConfirmButton.changeButtonImage(UIImageKey.CrossButton, UIImageKey.CrossButton);

        this.selectedEquipmentType = data.equipmentItemType;

        this.equippedItemSelected = true;

        this.selectedInventoryItemCanBeUsedEquippedOrUnequipped = true;
    }

    public populateDetailViewForInventoryItem(data: {
        inventoryItemIndex: number;
        inventoryItemName: string;
        inventoryItemDescription: string;
        type: ItemType;
        stats?: {
            strength?: number;
            agility?: number;
            vitality?: number;
            intellect?: number;
            luck?: number;
            defense?: number;
        };
        classes?: CharacterProfession[];
        minimumLevel?: number;
        canBeUsedOrEquipped: boolean;
        bindingType: ItemBindingType;
    }): void {
        console.log(`[InventoryDetailElements.populateDetailView] Populating detail view with data: ${JSON.stringify(data, null, 2)}`);
        // Select and mark the selected inventory item button
        this.inventoryMainListButtonInfos.forEach((buttonInfo, index) => {
            if (index === data.inventoryItemIndex) {
                buttonInfo.button.select();
                buttonInfo.selected = true;
            } else {
                buttonInfo.button.deselect();
                buttonInfo.selected = false;
            }
        });
        // Construct the detail text based on item type and properties
        // let detailsText = data.inventoryItemDescription;

        this.selectedInventoryItemCanBeUsedEquippedOrUnequipped = data.canBeUsedOrEquipped;

        this.inventoryDetailNameText.setText(data.inventoryItemName);

        this.bindingStatusText.setText(data.bindingType.charAt(0).toUpperCase() + data.bindingType.slice(1));

        if (data.classes && data.classes.length > 0) {
            const classesTextContent = data.classes.join('\n');
            this.classesText.setText(classesTextContent);
        } else {
            this.classesText.setText('');
        }

        this.inventoryDetailTypeText.setText(data.type.charAt(0).toUpperCase() + data.type.slice(1));

        if (data.type === ItemType.Consumable) {
            // If consumable, simply display the description
            this.inventoryDetailConfirmButton.changeButtonText('Use');
        } else {
            this.inventoryDetailConfirmButton.changeButtonText('Equip');
            this.inventoryDetailConfirmButton.changeButtonImage(UIImageKey.CheckButton, UIImageKey.CheckButton);
            // Set the completed text to the inventory detail text field
        }

        this.inventoryDetailDescriptionText.setText(data.inventoryItemDescription);

        if (data.stats) {
            this.populateStats(data.stats);
        } else {
            this.depopulateStats();
        }

        this.selectedInventoryIndex = data.inventoryItemIndex;
        this.equippedItemSelected = false;
    }

    private populateStats(stats: {[key: string]: number}): void {
        console.log('[populateStats] Starting population of stats:', JSON.stringify(stats, null, 2));
        this.statsMap = new Map(Object.entries(stats));

        for (let i = 0; i < this.statLabels.length; i++) {
            const statLabel = this.statLabels[i];
            const statValue = this.statValues[i];
            if (i < Object.keys(stats).length) {
                const statName = Object.keys(stats)[i];
                const statAbbreviation = statAbbreviations.get(statName) || statName;
                const statValueString = stats[statName].toString();

                // Use the new method to calculate position
                const {x, y} = this.calculateStatPositions(i);

                // Set positions and text for labels and values
                statLabel.setX(x).setY(y).setText(`${statAbbreviation}:`);
                statValue.setX(x).setY(y + 20).setText(statValueString); // Position value right below the label

                console.log(`[populateStats] Set ${statAbbreviation} to ${statValueString}`, JSON.stringify({statName, statValue: stats[statName]}, null, 2));
            } else {
                // Clear text and reposition off-screen or make invisible if not needed
                statLabel.setText('').setVisible(false);
                statValue.setText('').setVisible(false);
                console.log(`[populateStats] Cleared label and value at index ${i}`);
            }
        }
    }

    public showDetailViewForInventoryItem(): void {
        // Show the detail frame, ability text, resource cost text, and detail action buttons
        this.inventoryDetailFrame.showFrame();
        this.inventoryActionFrame.showFrame();
        this.inventoryDetailNameText.setVisible(true);
        this.inventoryDetailTypeText.setVisible(true);

        this.inventoryDetailDescriptionText.setVisible(true);

        this.bindingStatusText.setVisible(true);
        this.classesText.setVisible(true);

        this.showStats();

        this.inventoryVerticalDivider.setVisible(true);

        this.inventoryDetailMinimumLevelText.setVisible(true);
        this.soulboundIcon.setVisible(true);
        if (this.selectedInventoryItemCanBeUsedEquippedOrUnequipped) {
            this.inventoryDetailConfirmButton.showActionButton();
        } else {
            this.inventoryDetailConfirmButton.hideActionButton();
        }
        this.inventoryDetailCancelButton.showActionButton();
        this.inventoryDetailDropButton.showActionButton();

        // Hide the components from the initial view as needed
        this.inventoryMainListCancelFrame.hideFrame();
        this.inventoryMainListCancelButton.hideActionButton();
    }

    public showDetailViewForEquippedItem(): void {
        // Show the detail frame, ability text, resource cost text, and detail action buttons
        this.inventoryDetailFrame.showFrame();
        this.inventoryActionFrame.showFrame();
        this.inventoryDetailNameText.setVisible(true);
        this.inventoryDetailTypeText.setVisible(true);
        this.inventoryDetailDescriptionText.setVisible(true);
        this.bindingStatusText.setVisible(true);
        this.classesText.setVisible(true);
        this.inventoryVerticalDivider.setVisible(true);
        this.inventoryDetailMinimumLevelText.setVisible(true);
        this.showStats();
        this.soulboundIcon.setVisible(true);
        if (this.selectedInventoryItemCanBeUsedEquippedOrUnequipped) {
            this.inventoryDetailConfirmButton.showActionButton();
        } else {
            this.inventoryDetailConfirmButton.hideActionButton();
        }
        this.inventoryDetailCancelButton.showActionButton();
        this.inventoryDetailDropButton.hideActionButton();

        // Hide the components from the initial view as needed
        this.inventoryMainListCancelFrame.hideFrame();
        this.inventoryMainListCancelButton.hideActionButton();
    }

    public dismiss(): void {
        console.log('[InventoryMenu.dismiss] Entering method Begin dismiss sequence.');

        // Flag settings
        this.inventoryItemIsArmed = false;

        // Section: Process and Hide Buttons
        for (let index = 0; index < this.inventoryMainListButtonInfos.length; index++) {
            const itemButtonInfo = this.inventoryMainListButtonInfos[index];
            itemButtonInfo.button.hideActionButton();
            itemButtonInfo.selected = false;
        }
        this.inventoryMainListCancelButton.hideActionButton();
        this.inventoryMainCategoryButton.hideActionButton();
        this.inventorySecondaryCategoryButton.hideActionButton();
        this.inventoryDetailConfirmButton.hideActionButton();
        this.inventoryDetailCancelButton.hideActionButton();
        this.inventoryDetailDropButton.hideActionButton();
        this.inventoryArmedCancelButton.hideActionButton();
        this.equipmentButtonInfos.forEach(buttonInfo => {
            buttonInfo.button.hideActionButton();
            buttonInfo.selected = false;
            buttonInfo.exists = false;
        });
        this.inventoryArmedDetailIcon.hideActionButton();

        // Section: Hide Icons
        this.coinIcon.setVisible(false);

        // Section: Hide Frames
        this.inventoryMainListFrame.hideFrame();
        this.inventoryCategoryFrame.hideFrame();
        this.inventoryDetailFrame.hideFrame();
        this.inventoryActionFrame.hideFrame();
        this.inventoryMainListCancelFrame.hideFrame();
        this.inventoryArmedCancelFrame.hideFrame();
        this.inventoryArmedDetailFrame.hideFrame();
        this.inventoryArmedCommandFrame.hideFrame();
        this.inventoryGoldFrame.hideFrame();

        // Section: Hide Texts
        this.inventoryDetailNameText.setVisible(false);
        this.inventoryDetailTypeText.setVisible(false);

        this.inventoryDetailDescriptionText.setVisible(false);

        this.hideStats();

        this.bindingStatusText.setVisible(false);
        this.classesText.setVisible(false);
        this.inventoryVerticalDivider.setVisible(false);
        this.inventoryDetailMinimumLevelText.setVisible(false);
        this.soulboundIcon.setVisible(false);
        this.inventoryArmedCommandText.setVisible(false);
        this.goldText.setVisible(false);
        this.headText.setVisible(false);
        this.bodyText.setVisible(false);
        this.mainHandText.setVisible(false);
        this.offHandText.setVisible(false);

        // Deselect UI Elements
        this.scene.gameActionBar.inventoryButton.deselect();

        // Activate other necessary UI components
        this.activateInteractionMenuIfNeeded();

        this.shown = false;
        console.log('[InventoryMenu.dismiss] Exiting method. End dismiss sequence.');
    }

    public emitMenuActivationRequest(): void {
        // Check if the player is currently processing a field item
        if (!this.scene.canProceedWithMenuAction()) {
            // If false, exit early. Logging is already handled within `canProceedWithMenuAction`.
            return;
        }

        console.log('[InventoryMenu.emitMenuActivationRequest] Preparing to emit menu open event');

        // Since this method is now only for opening the menu, we can hardcode the action to "open"
        const action = 'open';

        console.log('[InventoryMenu.emitMenuActivationRequest] Emitting event to open the item menu');
        this.scene.waitingForServerResponse = true;
        this.socket.emit(ServerSocketEvents.AttemptToggleInventoryMenu, action);
    }

    public emitMenuDismissalRequest(): void {
        // Check if the player is currently processing a field item
        if (!this.scene.canProceedWithMenuAction()) {
            // If false, exit early. Logging is already handled within `canProceedWithMenuAction`.
            return;
        }

        console.log('[InventoryMenu.emitMenuDismissalRequest] Preparing to emit menu close event');
        this.scene.waitingForServerResponse = true;
        this.socket.emit(ServerSocketEvents.AttemptToggleInventoryMenu, 'close');
    }

    private setupFrames(): void {
        console.log('FieldInventoryMenu.setupFrames() called');

        // Initial View Frames
        this.inventoryMainListFrame = new ResizableFrame(this.scene, 720, 392, 371, 409);
        this.inventoryMainListFrame.hideFrame();

        this.inventoryCategoryFrame = new ResizableFrame(this.scene, 381, 516, 276, 160);
        this.inventoryCategoryFrame.hideFrame();

        this.inventoryActionFrame = new ResizableFrame(this.scene, 117, 516, 220, 160);
        this.inventoryActionFrame.hideFrame();

        // This frame is related to both initial and other views for canceling actions
        this.inventoryMainListCancelFrame = new ResizableFrame(this.scene, 419, 370, 200, 100);
        this.inventoryMainListCancelFrame.hideFrame();

        // Detail View Frame
        this.inventoryDetailFrame = new ResizableFrame(this.scene, 263, 266, 512, 308, 2);
        this.inventoryDetailFrame.hideFrame();

        // Armed View Frames
        this.inventoryArmedCancelFrame = new ResizableFrame(this.scene, 805, 486, 200, 100);
        this.inventoryArmedCancelFrame.hideFrame();

        this.inventoryArmedDetailFrame = new ResizableFrame(this.scene, 465, 466, 445, 60);
        this.inventoryArmedDetailFrame.hideFrame();

        this.inventoryArmedCommandFrame = new ResizableFrame(this.scene, 465, 554, 445, 84);
        this.inventoryArmedCommandFrame.hideFrame();

        // Shared Frame (used in various views for displaying gold)
        this.inventoryGoldFrame = new ResizableFrame(this.scene, 720, 144, 371, 50);
        this.inventoryGoldFrame.hideFrame();
    }

    private setupButtons(): void {
        // Armed View Buttons
        this.inventoryArmedCancelButton = new NonContainerUIActionButton(
            this.scene,
            730,
            465,
            UIImageKey.CrossButton,
            UIImageKey.CrossButton,
            'Cancel',
            () => {
                console.log('Secondary cancel button clicked');
                this.emitMenuDismissalRequest();
            }
        );
        this.inventoryArmedCancelButton.setDepth(DepthLevel.UI_PRIMARY_GRAPHICS_SUB);
        this.inventoryArmedCancelButton.hideActionButton();

        this.inventoryArmedDetailIcon = new NonContainerUIActionButton(
            this.scene,
            266,
            465,
            UIImageKey.CheckButton,
            UIImageKey.CheckButton,
            'Nothing',
            () => {}
        );
        this.inventoryArmedDetailIcon.setDepth(DepthLevel.UI_PRIMARY_GRAPHICS_SUB);
        this.inventoryArmedDetailIcon.hideActionButton();

        // Initial View Buttons
        this.inventoryMainCategoryButton = new NonContainerUIActionButton(
            this.scene,
            266,
            465,
            UIImageKey.BagButton,
            UIImageKey.BagButtonActive,
            'Inventory',
            () => {
                console.log('Main inventory category button clicked');
                this.attemptSwitchToInventoryMode();
            }
        );
        this.inventoryMainCategoryButton.hideActionButton();

        this.inventorySecondaryCategoryButton = new NonContainerUIActionButton(
            this.scene,
            266,
            516,
            UIImageKey.ArmorButton,
            UIImageKey.ArmorButtonActive,
            'Equipment',
            () => {
                console.log('Secondary category button clicked');
                this.attemptSwitchToEquipmentMode();
            }
        );
        this.inventorySecondaryCategoryButton.hideActionButton();

        this.inventoryMainListCancelButton = new NonContainerUIActionButton(
            this.scene,
            344,
            349,
            UIImageKey.CrossButton,
            UIImageKey.CrossButton,
            'Cancel',
            () => {
                this.emitMenuDismissalRequest();
            }
        );
        this.inventoryMainListCancelButton.hideActionButton();

        // Detail View Buttons
        this.inventoryDetailConfirmButton = new NonContainerUIActionButton(
            this.scene,
            31,
            465,
            UIImageKey.CheckButton,
            UIImageKey.CheckButton,
            'Use',
            () => {
                console.log('[InventoryMenu] Confirm button clicked.');
                // if an inventory item is selected, emit an event to either arm or equip it, otherwise if an equipped item is selected, emit an event to unequip it
                if (!this.equippedItemSelected) {
                    const eventData = {
                        inventoryItemIndex: this.selectedInventoryIndex,
                        pageIndex: this.selectedInventoryPageNumber
                    };
                    console.log(`[InventoryMenu] Emitting AttemptArmFieldInventoryItem event with data: ${JSON.stringify(eventData, null, 2)}`);
                    this.socket.emit(ServerSocketEvents.AttemptArmOrEquipFieldInventoryItem, eventData);
                } else {
                    const eventData = {
                        equipmentItemSlot: this.selectedEquipmentType
                    };
                    console.log(`[InventoryMenu] Emitting AttemptUnequipFieldEquipmentItem event with data: ${JSON.stringify(eventData, null, 2)}`);
                    this.socket.emit(ServerSocketEvents.AttemptUnequipFieldEquipmentItem, eventData);
                }
            }
        );
        this.inventoryDetailConfirmButton.setDepth(DepthLevel.UI_SECONDARY_GRAPHICS_SUB);
        this.inventoryDetailConfirmButton.hideActionButton();

        this.inventoryDetailCancelButton = new NonContainerUIActionButton(
            this.scene,
            31,
            516,
            UIImageKey.CrossButton,
            UIImageKey.CrossButton,
            'Cancel',
            () => {
                this.emitMenuDismissalRequest();
            }
        );
        this.inventoryDetailCancelButton.setDepth(DepthLevel.UI_SECONDARY_GRAPHICS_SUB);
        this.inventoryDetailCancelButton.hideActionButton();

        this.inventoryDetailDropButton = new NonContainerUIActionButton(
            this.scene,
            31,
            567,
            UIImageKey.CrossButton,
            UIImageKey.CrossButton,
            'Drop',
            this.dropSelectedItem.bind(this) // Bind 'this' to ensure context is preserved
        );
        this.inventoryDetailDropButton.setDepth(DepthLevel.UI_SECONDARY_GRAPHICS_SUB);
        this.inventoryDetailDropButton.hideActionButton();

        // Initialize additional inventory button infos, if applicable
        this.initializeInventoryButtonInfos();

        this.initializeEquipmentButtonInfos();
    }

    public dropSelectedItem(): void {
        if (!this.equippedItemSelected) {
            const currentItem = this.inventoryMainListButtonInfos[this.selectedInventoryIndex];

            if (currentItem && currentItem.exists) {
                // Log the category (assuming category information is part of the inventory items)
                console.log(`Dropping item from category: ${'Bag'}`); // Static 'Bag' category used for example

                // Convert the slot number to words and log it
                const slotNumber = numberToWords.toWords(this.selectedInventoryIndex + 1);
                console.log(`Slot number: ${slotNumber.charAt(0).toUpperCase() + slotNumber.slice(1)}`);

                // Emit event to server
                const eventData = {
                    inventoryItemIndex: this.selectedInventoryIndex,
                    inventoryItemCategory: 'Bag'
                };
                this.socket.emit(ServerSocketEvents.AttemptDropFieldInventoryItem, eventData);
                console.log(`Dropping item: ${currentItem.button.text} at slot ${this.selectedInventoryIndex}`);

                // Additional code to handle the item drop functionality
            } else {
                console.log('No item selected or item does not exist.');
            }
        } else {
            // can't drop equipped items
        }
    }

    private setupTexts(): void {
        // Detail View Texts
        this.inventoryDetailNameText = this.scene.add.text(
            15,
            110, // Positioned above the detail text
            'Item Name', // Placeholder for the item name
            {
                fontSize: '36px', // Slightly smaller font size
                color: '#fff',
                fontFamily: mainGameFont,
            }
        )
            .setResolution(3)
            .setDepth(DepthLevel.UI_SECONDARY_TEXT)
            .setVisible(false);

        this.inventoryDetailTypeText = this.scene.add.text(
            15,
            137, // Increased y-coordinate to move the text down
            'Weapon', // Initial text content
            {
                fontSize: '36px', // Reduced font size
                color: '#fff',
                fontFamily: mainGameFont,
            }
        )
            .setResolution(3)
            .setDepth(DepthLevel.UI_SECONDARY_TEXT)
            .setVisible(false);

        this.inventoryDetailDescriptionText = this.scene.add.text(
            15,
            170, // Increased y-coordinate to move the text down
            '',
            {
                fontSize: '36px', // Reduced font size
                color: '#fff',
                fontFamily: mainGameFont,
                wordWrap: {
                    width: 310,
                    useAdvancedWrap: true
                },
                metrics: {
                    ascent: 25, // Adjusted to match the new font size
                    descent: 7, // Adjusted to match the new font size
                    fontSize: 32 // Adjusted to match the new font size
                }
            }
        )
            .setLineSpacing(-10) // Adjust line spacing as needed
            .setResolution(3)
            .setDepth(DepthLevel.UI_SECONDARY_TEXT)
            .setVisible(false);

        const rightColumnTextTopLeftCornerX = 329; // X coordinate for the right column text
        const rightColumnTextTopLeftCornerY = 110; // Y coordinate for the right column text

        this.inventoryDetailMinimumLevelText = this.scene.add.text(
            rightColumnTextTopLeftCornerX, // X coordinate (adjust as needed)
            rightColumnTextTopLeftCornerY, // Y coordinate (adjust as needed)
            'Level 1', // Initial text content
            {
                fontSize: '36px',
                color: '#fff',
                fontFamily: mainGameFont,
                align: 'left', // Aligns text to the right
            }
        )
            .setOrigin(0, 0) // Aligns text to the top-right
            .setResolution(3)
            .setDepth(DepthLevel.UI_SECONDARY_TEXT)
            .setVisible(false);

        // const soulboundIconX = this.inventoryDetailMinimumLevelText.x - 25 - this.inventoryDetailMinimumLevelText.width;
        // const soulboundIconY = this.inventoryDetailMinimumLevelText.y + 5;

        const bindingStatusTextX = rightColumnTextTopLeftCornerX;
        const bindingStatusTextY = rightColumnTextTopLeftCornerY + 26;

        // Create the top line text
        this.bindingStatusText = this.scene.add.text(
            bindingStatusTextX,
            bindingStatusTextY,
            // 'Soulbound',
            'Bind on pickup',
            {
                fontSize: '36px', // Half the size
                color: '#fff',
                fontFamily: mainGameFont,
                align: 'left'
            }
        )
            .setOrigin(0, 0) // Aligns text to the top-right
            .setResolution(3)
            .setDepth(DepthLevel.UI_SECONDARY_TEXT)
            .setVisible(false);

        this.classesText = this.scene.add.text(
            rightColumnTextTopLeftCornerX,
            rightColumnTextTopLeftCornerY + 54,
            'Runeblade\nAethermancer\nLifeweaver',
            {
                fontSize: '36px',
                color: '#fff',
                fontFamily: mainGameFont,
                align: 'left'
            }
        )
            .setOrigin(0, 0)
            .setResolution(3)
            .setDepth(DepthLevel.UI_SECONDARY_TEXT)
            .setLineSpacing(-10)
            .setVisible(false);

        // Armed View Texts
        this.inventoryArmedCommandText = this.scene.add.text(
            244,
            510,
            'Choose A Target',
            {
                fontSize: '50px',
                color: '#fff',
                fontFamily: mainGameFont,
            }
        );
        this.inventoryArmedCommandText.setDepth(DepthLevel.UI_PRIMARY_TEXT);
        this.inventoryArmedCommandText.setResolution(3);
        this.inventoryArmedCommandText.setVisible(false);

        // Shared Texts (used across multiple views)
        this.goldText = this.scene.add.text(584, 120, '0 gp', {
            color: '#ffffff', align: 'left', fontFamily: mainGameFont
        })
            .setResolution(3)
            .setFontSize(50)
            .setVisible(false);
        this.goldText.setDepth(DepthLevel.UI_PRIMARY_TEXT);

        this.headText = this.scene.add.text(540, 180, 'Head:', {
            fontSize: '50px',
            color: '#fff',
            fontFamily: mainGameFont
        })
            .setResolution(3)
            .setDepth(DepthLevel.UI_PRIMARY_TEXT)
            .setVisible(false);

        this.bodyText = this.scene.add.text(540, 275, 'Body:', {
            fontSize: '50px',
            color: '#fff',
            fontFamily: mainGameFont
        })
            .setResolution(3)
            .setDepth(DepthLevel.UI_PRIMARY_TEXT)
            .setVisible(false);

        this.mainHandText = this.scene.add.text(540, 370, 'Main Hand:', {
            fontSize: '50px',
            color: '#fff',
            fontFamily: mainGameFont
        })
            .setResolution(3)
            .setDepth(DepthLevel.UI_PRIMARY_TEXT)
            .setVisible(false);

        this.offHandText = this.scene.add.text(540, 465, 'Off Hand:', {
            fontSize: '50px',
            color: '#fff',
            fontFamily: mainGameFont
        })
            .setResolution(3)
            .setDepth(DepthLevel.UI_PRIMARY_TEXT)
            .setVisible(false);

        // First row
        let position = this.calculateStatPositions(0);
        this.stat1Label = this.scene.add.text(
            position.x, position.y,
            'STR:', // Strength
            {color: '#fff', fontFamily: mainGameFont, fontSize: '35px'}
        ).setResolution(3).setDepth(DepthLevel.UI_SECONDARY_TEXT).setVisible(false);

        this.stat1Value = this.scene.add.text(
            position.x, position.y + 20,
            '0', // Value for Strength
            {color: '#fff', fontFamily: mainGameFont, fontSize: '35px'}
        ).setResolution(3).setDepth(DepthLevel.UI_SECONDARY_TEXT).setVisible(false);

        position = this.calculateStatPositions(1);
        this.stat2Label = this.scene.add.text(
            position.x, position.y,
            'AGI:', // Agility
            {color: '#fff', fontFamily: mainGameFont, fontSize: '35px'}
        ).setResolution(3).setDepth(DepthLevel.UI_SECONDARY_TEXT).setVisible(false);

        this.stat2Value = this.scene.add.text(
            position.x, position.y + 20,
            '0', // Value for Agility
            {color: '#fff', fontFamily: mainGameFont, fontSize: '35px'}
        ).setResolution(3).setDepth(DepthLevel.UI_SECONDARY_TEXT).setVisible(false);

        position = this.calculateStatPositions(2);
        this.stat3Label = this.scene.add.text(
            position.x, position.y,
            'VIT:', // Vitality
            {color: '#fff', fontFamily: mainGameFont, fontSize: '35px'}
        ).setResolution(3).setDepth(DepthLevel.UI_SECONDARY_TEXT).setVisible(false);

        this.stat3Value = this.scene.add.text(
            position.x, position.y + 20,
            '0', // Value for Vitality
            {color: '#fff', fontFamily: mainGameFont, fontSize: '35px'}
        ).setResolution(3).setDepth(DepthLevel.UI_SECONDARY_TEXT).setVisible(false);

        // Second row
        position = this.calculateStatPositions(3);
        this.stat4Label = this.scene.add.text(
            position.x, position.y,
            'INT:', // Intellect
            {color: '#fff', fontFamily: mainGameFont, fontSize: '35px'}
        ).setResolution(3).setDepth(DepthLevel.UI_SECONDARY_TEXT).setVisible(false);

        this.stat4Value = this.scene.add.text(
            position.x, position.y + 20,
            '0', // Value for Intellect
            {color: '#fff', fontFamily: mainGameFont, fontSize: '35px'}
        ).setResolution(3).setDepth(DepthLevel.UI_SECONDARY_TEXT).setVisible(false);

        position = this.calculateStatPositions(4);
        this.stat5Label = this.scene.add.text(
            position.x, position.y,
            'LUK:', // Luck
            {color: '#fff', fontFamily: mainGameFont, fontSize: '35px'}
        ).setResolution(3).setDepth(DepthLevel.UI_SECONDARY_TEXT).setVisible(false);

        this.stat5Value = this.scene.add.text(
            position.x, position.y + 20,
            '0', // Value for Luck
            {color: '#fff', fontFamily: mainGameFont, fontSize: '35px'}
        ).setResolution(3).setDepth(DepthLevel.UI_SECONDARY_TEXT).setVisible(false);

        position = this.calculateStatPositions(5);
        this.stat6Label = this.scene.add.text(
            position.x, position.y,
            'DEF:', // Defense
            {color: '#fff', fontFamily: mainGameFont, fontSize: '35px'}
        ).setResolution(3).setDepth(DepthLevel.UI_SECONDARY_TEXT).setVisible(false);

        this.stat6Value = this.scene.add.text(
            position.x, position.y + 20,
            '0', // Value for Defense
            {color: '#fff', fontFamily: mainGameFont, fontSize: '35px'}
        ).setResolution(3).setDepth(DepthLevel.UI_SECONDARY_TEXT).setVisible(false);

        this.statLabels = [
            this.stat1Label,
            this.stat2Label,
            this.stat3Label,
            this.stat4Label,
            this.stat5Label,
            this.stat6Label
        ];
        this.statValues = [
            this.stat1Value,
            this.stat2Value,
            this.stat3Value,
            this.stat4Value,
            this.stat5Value,
            this.stat6Value
        ];
    }

    private depopulateStats(): void {
        console.log('[depopulateStats] Clearing all stat texts.');
        // Iterate through all stat labels and values to clear them
        this.statLabels.forEach((label) => {
            label.setText(''); // Clear the text and make the label invisible
        });
        this.statValues.forEach((value) => {
            value.setText(''); // Clear the text and make the value invisible
        });
    }

    private calculateStatPositions(index: number): { x: number, y: number } {
        // Constants for layout configuration
        const columnWidth = 100; // Width of each stat column
        const rowHeight = 45; // Height between rows of stats

        // Calculate the starting Y position based on the description text's bottom
        const startY = this.inventoryDetailDescriptionText.y + this.inventoryDetailDescriptionText.height - 10; // Additional 10 pixels for spacing

        // Calculate positions based on column and row determined by index
        const x = 15 + (index % 3) * columnWidth; // Calculate X position within the row
        const y = startY + Math.floor(index / 3) * rowHeight; // Calculate Y position for each row

        return {x, y};
    }

    private showStats(): void {
        console.log('[showStats] Updating visibility of stat labels and values', JSON.stringify(Array.from(this.statsMap.entries()), null, 2));
        const statCount = this.statsMap.size; // Determine the number of stats provided from the Map
        for (let i = 0; i < this.statLabels.length; i++) {
            if (i < statCount) {
                this.statLabels[i].setVisible(true); // Only make visible up to the number of provided stats
                this.statValues[i].setVisible(true);
                console.log(`[showStats] Made visible label and value at index ${i}`);
            } else {
                this.statLabels[i].setVisible(false); // Hide all other labels and values
                this.statValues[i].setVisible(false);
                console.log(`[showStats] Hid label and value at index ${i}`);
            }
        }
    }

    private hideStats(): void {
        for (let i = 0; i < this.statLabels.length; i++) {
            this.statLabels[i].setVisible(false);
            this.statValues[i].setVisible(false);
        }
    }

    private initializeEquipmentButtonInfos(): void {
        // Check if the initialization has already been done to prevent duplication
        if (this.equipmentButtonInfos.size === 0) {
            this.equipmentButtonInfos.set(ItemType.Helmet, {
                button: this.createEquipmentItemButton(ItemType.Helmet),
                exists: false,
                selected: false
            });
            this.equipmentButtonInfos.set(ItemType.BodyArmor, {
                button: this.createEquipmentItemButton(ItemType.BodyArmor),
                exists: false,
                selected: false
            });
            this.equipmentButtonInfos.set(ItemType.Weapon, {
                button: this.createEquipmentItemButton(ItemType.Weapon),
                exists: false,
                selected: false
            });
            this.equipmentButtonInfos.set(ItemType.OffHand, {
                button: this.createEquipmentItemButton(ItemType.OffHand),
                exists: false,
                selected: false
            });
        } else {
            console.log('Equipment buttons have already been initialized.');
        }
    }

    private createEquipmentItemButton(type: ItemType.Helmet | ItemType.BodyArmor | ItemType.Weapon | ItemType.OffHand): NonContainerUIActionButton {
        let yPos: number;

        switch (type) {
            case ItemType.Helmet:
                yPos = 250; // Y position for head equipment
                break;
            case ItemType.BodyArmor:
                yPos = 345; // Y position for body equipment
                break;
            case ItemType.Weapon:
                yPos = 440; // Y position for main hand equipment
                break;
            case ItemType.OffHand:
                yPos = 535; // Y position for offhand equipment
                break;
            default:
                yPos = 216; // Default position if none of the types match
        }

        const label = `Equipment ${type.charAt(0).toUpperCase() + type.slice(1)}`;
        const equipmentItemButton = new NonContainerUIActionButton(
            this.scene,
            564, // X position is the same for all
            yPos,
            UIImageKey.BagButton,
            UIImageKey.BagButtonActive,
            label,
            () => {
                console.log(`[Equipment item callback] ${type} equipment clicked.`);
                const eventData: {equipmentType: Exclude<ItemType, ItemType.Consumable>} = {
                    equipmentType: type
                };
                this.socket.emit(ServerSocketEvents.AttemptSelectFieldEquipmentItem, eventData);
            }
        );

        equipmentItemButton.hideActionButton();
        equipmentItemButton.setDepth(DepthLevel.UI_PRIMARY_GRAPHICS_SUB);

        return equipmentItemButton;
    }

    private initializeInventoryButtonInfos(): void {
        // Check if the initialization has already been done to prevent duplication
        if (this.inventoryMainListButtonInfos.length === 0) {
            for (let i = 0; i < 8; i++) {
                const abilityButtonInfo = {
                    button: this.createInventoryItemButton(i),
                    exists: false, // Initialize as false; will be updated based on actual abilities
                    selected: false // Initialize as false; will be updated based on actual abilities
                };
                this.inventoryMainListButtonInfos.push(abilityButtonInfo);
            }
        } else {
            // Optionally, log a message or handle cases where reinitialization might be intended but needs to be controlled
            console.log('Ability buttons have already been initialized.');
        }
    }

    private createInventoryItemButton(index: number): NonContainerUIActionButton {
        const numberWord = numberToWords.toWords(index + 1);
        const capitalizedWord = numberWord.charAt(0).toUpperCase() + numberWord.slice(1);
        const label = `Item ${capitalizedWord}`;

        const inventoryItemButton = new NonContainerUIActionButton(
            this.scene,
            564,
            216 + (index * 50),
            UIImageKey.BagButton,
            UIImageKey.BagButtonActive,
            label,
            () => {
                // Button click logic...
            }
        );

        inventoryItemButton.hideActionButton();
        inventoryItemButton.setDepth(DepthLevel.UI_PRIMARY_GRAPHICS_SUB);

        return inventoryItemButton;
    }

    public populateArmedView(data: AttemptArmOrEquipFieldInventoryItemResponseEvent): void {
        // Check if the response is a success state before proceeding
        if ('inventoryItemIndex' in data) {
            // Set the text and images for the armed ability
            this.inventoryArmedCommandText.setText('Choose A Target'); // Confirmatory text for the next action
            this.inventoryArmedDetailIcon.changeButtonImage(data.inventoryItemKey, data.inventoryItemActiveKey); // Updates the icon for the armed ability
            this.inventoryArmedDetailIcon.select();
            this.inventoryArmedDetailIcon.changeButtonText(data.inventoryItemName); // Sets the name for the armed ability

            // Update internal state to reflect the armed status
            this.inventoryItemIsArmed = true;
            this.armedInventoryItemIndex = data.inventoryItemIndex;
        } else {
            console.error('Attempt to arm field ability failed with message:', JSON.stringify(data, null, 2));
            // Handle failure state, such as showing an error message or logging
        }
    }

    // Method to show the armed view, hiding previous views as needed
    public showArmedView(): void {
        // Hide components from the initial and/or detail view as needed
        this.hideInitialView();
        this.hideDetailView();

        // Show the components specific to the armed view
        this.inventoryArmedCancelFrame.showFrame();
        this.inventoryArmedCancelButton.showActionButton();
        this.inventoryArmedDetailFrame.showFrame();
        this.inventoryArmedDetailIcon.showActionButton();
        this.inventoryArmedCommandFrame.showFrame();
        this.inventoryArmedCommandText.setVisible(true);
    }

    public hideInitialView(): void {
        // Hide the components specific to the initial view
        this.inventoryMainListFrame.hideFrame();
        this.inventoryCategoryFrame.hideFrame();
        this.inventoryMainListCancelFrame.hideFrame();
        this.inventoryMainCategoryButton.hideActionButton();
        this.inventorySecondaryCategoryButton.hideActionButton();
        this.inventoryMainListCancelButton.hideActionButton();
        this.inventoryGoldFrame.hideFrame();
        this.coinIcon.setVisible(false);
        this.goldText.setVisible(false);
        // Hide all inventory buttons, regardless of their existing state
        this.inventoryMainListButtonInfos.forEach(buttonInfo => buttonInfo.button.hideActionButton());
    }

    public hideDetailView(): void {
        // Hide the components specific to the detail view
        this.inventoryDetailFrame.hideFrame();
        this.inventoryActionFrame.hideFrame();

        this.inventoryDetailNameText.setVisible(false);
        this.inventoryDetailTypeText.setVisible(false);
        this.inventoryDetailDescriptionText.setVisible(false);
        this.bindingStatusText.setVisible(false);
        this.classesText.setVisible(false);
        this.inventoryVerticalDivider.setVisible(false);
        this.inventoryDetailMinimumLevelText.setVisible(false);
        this.soulboundIcon.setVisible(false);

        this.inventoryDetailConfirmButton.hideActionButton();
        this.inventoryDetailCancelButton.hideActionButton();
        this.inventoryDetailDropButton.hideActionButton();

        this.hideStats();
    }

    private setupIcons(): void {
        this.coinIcon = this.scene.add.image(563, 145, UIImageKey.CoinButton)
            .setScale(2);
        this.coinIcon.setDepth(DepthLevel.UI_PRIMARY_GRAPHICS_SUB);
        this.coinIcon.setVisible(false);

        const soulboundIconX = this.inventoryDetailMinimumLevelText.x - 25 - this.inventoryDetailMinimumLevelText.width;
        const soulboundIconY = this.inventoryDetailMinimumLevelText.y + 5;
        this.soulboundIcon = this.scene.add.image(soulboundIconX, soulboundIconY, UIImageKey.SoulboundIcon)
            .setOrigin(1, 0) // Aligns text to the top-right
            .setDepth(DepthLevel.UI_SECONDARY_GRAPHICS_SUB)
            .setScale(3)
            .setAlpha(0)
            .setVisible(false);
    }

    private setupGraphics(): void {
        // Create a new Graphics object and add it to the scene
        this.inventoryVerticalDivider = this.scene.add.graphics();

        // Set the style properties for the line
        this.inventoryVerticalDivider.lineStyle(2, 0xffffff, 1); // Width, color, and alpha

        // Draw the line
        this.inventoryVerticalDivider.lineBetween(321, 119, 321, 349); // startX, startY, endX, endY

        // Set the depth of the line
        this.inventoryVerticalDivider.setDepth(DepthLevel.UI_SECONDARY_OVERLAY);

        // Initially hide the line
        this.inventoryVerticalDivider.setVisible(false);
    }

    public updateMainInventoryList(data: {
        inventory: {
            name: string;
            key: UIImageKey;
            activeKey: UIImageKey;
        }[];
    }): void {
        // Clear the existing buttons
        this.inventoryMainListButtonInfos.forEach(buttonInfo => {
            buttonInfo.button.hideActionButton();
            buttonInfo.exists = false;
        });

        // Repopulate the buttons with the new inventory data
        data.inventory.forEach((item, index) => {
            if (index < this.inventoryMainListButtonInfos.length) {
                const buttonInfo = this.inventoryMainListButtonInfos[index];
                buttonInfo.exists = true;
                buttonInfo.button.changeButtonImage(item.key, item.activeKey);
                buttonInfo.button.changeButtonText(item.name);
                buttonInfo.button.showActionButton();

                // Set the new callback for selecting the inventory item
                buttonInfo.button.targetCallback = (): void => {
                    console.log(`[inventory item callback] Item ${index} clicked.`);
                    const eventData = {
                        inventoryItemIndex: index
                    };
                    this.socket.emit(ServerSocketEvents.AttemptSelectFieldInventoryItem, eventData);
                };
            }
        });

        this.hideDetailView();
    }

    public updateMainInventoryListAndGold(data: {
        inventory: {
            name: string;
            key: UIImageKey;
            activeKey: UIImageKey;
        }[];
        gold: number;
    }): void {
        // Clear the existing buttons
        this.inventoryMainListButtonInfos.forEach(buttonInfo => {
            buttonInfo.button.hideActionButton();
            buttonInfo.exists = false;
        });

        // Repopulate the buttons with the new inventory data
        data.inventory.forEach((item, index) => {
            if (index < this.inventoryMainListButtonInfos.length) {
                const buttonInfo = this.inventoryMainListButtonInfos[index];
                buttonInfo.exists = true;
                buttonInfo.button.changeButtonImage(item.key, item.activeKey);
                buttonInfo.button.changeButtonText(item.name);
                buttonInfo.button.showActionButton();

                // Set the new callback for selecting the inventory item
                buttonInfo.button.targetCallback = (): void => {
                    console.log(`[inventory item callback] Item ${index} clicked.`);
                    const eventData = {
                        inventoryItemIndex: index
                    };
                    this.socket.emit(ServerSocketEvents.AttemptSelectFieldInventoryItem, eventData);
                };
            }
        });

        // Update the gold display
        this.goldText.setText(`${data.gold.toLocaleString()} gp`);
        this.goldText.setVisible(true);

        this.hideDetailView();
    }

    private attemptSwitchToInventoryMode(): void {
        if (this.mode !== 'inventory') {
            console.log('Switching to inventory mode');
            this.socket.emit(ServerSocketEvents.AttemptSwitchFieldInventoryMode, {mode: 'inventory'});
        }
    }

    private attemptSwitchToEquipmentMode(): void {
        if (this.mode !== 'equipment') {
            console.log('Switching to equipment mode');
            this.socket.emit(ServerSocketEvents.AttemptSwitchFieldInventoryMode, {mode: 'equipment'});
        } else {
            console.log('Already in equipment mode');
        }
    }

    public switchToMode(
        mode: 'equipment' | 'inventory',
        inventoryOrEquipment: {
            helmet?: EquipmentItemForDisplay;
            bodyArmor?: EquipmentItemForDisplay;
            offHand?: EquipmentItemForDisplay;
            weapon?: EquipmentItemForDisplay;
        } | InventoryItemForDisplay[]
    ): void {
        console.log(`[FieldInventoryMenu.switchToMode] Switching to ${mode} mode with data: ${JSON.stringify(inventoryOrEquipment, null, 2)}`);

        if (mode === 'equipment') {
            this.mode = 'equipment';

            this.hideDetailView();

            // Hide inventory buttons and reset their states
            this.inventoryMainListButtonInfos.forEach(buttonInfo => {
                buttonInfo.button.hideActionButton();
                buttonInfo.exists = false;
                buttonInfo.selected = false;
            });
            this.inventoryMainCategoryButton.deselect();
            this.inventorySecondaryCategoryButton.select();

            // Set visibility for the equipment texts (adjust these as per your actual UI component names)
            this.headText.setVisible(true);
            this.bodyText.setVisible(true);
            this.mainHandText.setVisible(true);
            this.offHandText.setVisible(true);

            // Assuming `inventoryOrEquipment` is cast to the correct type
            const equipment = inventoryOrEquipment as {
                helmet?: EquipmentItemForDisplay;
                bodyArmor?: EquipmentItemForDisplay;
                offHand?: EquipmentItemForDisplay;
                weapon?: EquipmentItemForDisplay;
            };

            // Set and show equipment buttons using the correct keys
            if (equipment.helmet) {
                const helmetInfo = this.equipmentButtonInfos.get(ItemType.Helmet);
                if (helmetInfo) {
                    this.updateButtonInfo(helmetInfo, equipment.helmet);
                }
            }
            if (equipment.bodyArmor) {
                const bodyArmorInfo = this.equipmentButtonInfos.get(ItemType.BodyArmor);
                if (bodyArmorInfo) {
                    this.updateButtonInfo(bodyArmorInfo, equipment.bodyArmor);
                }
            }
            if (equipment.offHand) {
                const offHandInfo = this.equipmentButtonInfos.get(ItemType.OffHand);
                if (offHandInfo) {
                    this.updateButtonInfo(offHandInfo, equipment.offHand);
                }
            }
            if (equipment.weapon) {
                const weaponInfo = this.equipmentButtonInfos.get(ItemType.Weapon);
                if (weaponInfo) {
                    this.updateButtonInfo(weaponInfo, equipment.weapon);
                }
            }
        } else if (mode === 'inventory') {
            this.mode = 'inventory';
            // Hide equipment buttons and reset their states
            this.hideDetailView();

            this.inventorySecondaryCategoryButton.deselect();
            this.inventoryMainCategoryButton.select();
            //
            // // Hide equipment texts
            this.headText.setVisible(false);
            this.bodyText.setVisible(false);
            this.mainHandText.setVisible(false);
            this.offHandText.setVisible(false);

            this.equipmentButtonInfos.forEach((buttonInfo) => {
                buttonInfo.button.hideActionButton();
                buttonInfo.exists = false;
                buttonInfo.selected = false;
            });

            this.updateMainInventoryList({inventory: inventoryOrEquipment as InventoryItemForDisplay[]});

        }
        // Add logic for 'inventory' mode if needed
    }

    private updateButtonInfo(buttonInfo: { button: NonContainerUIActionButton; exists: boolean; selected: boolean }, item: EquipmentItemForDisplay): void {
        buttonInfo.button.changeButtonText(item.name); // Only display the item's name
        buttonInfo.button.changeButtonImage(item.key, item.activeKey);
        buttonInfo.button.showActionButton();
        buttonInfo.exists = true;
        buttonInfo.selected = false; // Reset or set based on your logic
    }

}
