import {io, Socket} from 'socket.io-client';

export default class SocketManager {
    private static instance: SocketManager;
    public socket: Socket;

    private constructor() {
        // Initialize the socket with reconnection disabled
        this.socket = io({
            reconnection: false // Disable reconnection attempts
        });
    }

    public static getInstance(): SocketManager {
        if (!SocketManager.instance) {
            SocketManager.instance = new SocketManager();
        }

        return SocketManager.instance;
    }
}
