import Phaser from 'phaser';
import {Socket} from 'socket.io-client';
import {Images} from '../../../../types/assets/AssetKeys';
import {ClientSocketEvents} from '../../../../types/events/ClientSocketEvents';
import {RequestPasswordResetResponseEvent} from '../../../../types/events/RequestPasswordResetResponseEvent';
import {ServerSocketEvents} from '../../../../types/events/ServerSocketEvents';
import SocketManager from '../../classes/NetworkingAndChat/SocketManager';
import {mainGameFont} from '../../GameConfig';
import {SceneNames} from '../../types/SceneNames';
import DOMElement = Phaser.GameObjects.DOMElement;

export default class ForgotPasswordScene extends Phaser.Scene {
    private backgroundRectangle: Phaser.GameObjects.Rectangle;
    private socket: Socket;
    private forgotPasswordForm: Phaser.GameObjects.DOMElement;
    private successMessage: HTMLParagraphElement;
    private errorMessage: HTMLParagraphElement;
    private titleText: Phaser.GameObjects.Text;
    private titleImage: Phaser.GameObjects.Image;

    public constructor() {
        super(SceneNames.ForgotPassword);
        this.socket = SocketManager.getInstance().socket;
    }
    private setupSocketListeners(): void { // Optionally, listen for a response from the server
        this.socket.on(ClientSocketEvents.RequestPasswordResetResponse, (response: RequestPasswordResetResponseEvent) => {
            this.successMessage.textContent = '';
            this.errorMessage.textContent = '';
            if (response.success) {
                this.successMessage.textContent = response.message;
            } else {
                this.errorMessage.textContent = response.error;
            }
        });

        this.socket.on(ClientSocketEvents.ServerShutdown, (response: { reason: string }) => {
            console.log('Server shutdown:', response);
            this.errorMessage.textContent = response.reason;
            this.scene.pause();

            // Disconnect the socket
            this.socket.disconnect();

            // Disable the forgot password form
            this.disableForgotPasswordForm();
        });
    }

    private disableForgotPasswordForm(): void {
        const formElement = this.forgotPasswordForm.node as HTMLFormElement;
        const inputs = formElement.querySelectorAll('input');
        const buttons = formElement.querySelectorAll('button');
        const links = formElement.querySelectorAll('a');
        const spans = formElement.querySelectorAll('span');

        spans.forEach(span => {
            span.style.color = '#999'; // Change text color to indicate disabled state
            span.style.pointerEvents = 'none'; // Disable pointer events
            span.style.cursor = 'not-allowed'; // Change cursor to indicate not clickable
        });
        inputs.forEach(input => input.disabled = true);
        buttons.forEach(button => {
            button.setAttribute('disabled', 'true'); // Disable the button
            button.style.pointerEvents = 'none'; // Disable pointer events
            button.style.backgroundColor = '#999'; // Change background color to indicate disabled state
            button.style.color = '#666'; // Change text color to indicate disabled state
            button.style.cursor = 'not-allowed'; // Change cursor to indicate not clickable
        });
        links.forEach(link => {
            link.setAttribute('disabled', 'disabled'); // Add disabled attribute
            link.style.pointerEvents = 'none'; // Disable click events
            link.style.color = '#999'; // Change text color to indicate disabled state
            link.style.textDecoration = 'none'; // Remove underline
            link.style.cursor = 'not-allowed'; // Change cursor to indicate not clickable
        });

        // Remove pointerdown event listener
        this.forgotPasswordForm.removeListener('pointerdown');
    }

    public preload(): void {
        this.load.html('forgotpasswordform', 'assets/html/forgotpasswordform.html');
    }

    public create(): void {
        this.backgroundRectangle = this.add.rectangle(
            0,
            0,
            this.scale.width,
            this.scale.height,
            0xbcbcbc
        ).setOrigin(0, 0);

        this.titleImage = this.add.image(this.scale.width / 2, this.scale.height / 2, Images.TitleScreenBackground);
        this.titleImage.displayHeight = this.scale.height;
        this.titleImage.displayWidth = this.scale.width;

        this.titleText = this.add.text(this.scale.width / 2, 100, 'Afterlife Online', {
            fontSize: '128px',
            color: '#fff',
            fontFamily: mainGameFont
        })
            .setStroke('#000000', 6)
            .setOrigin(0.5);

        this.forgotPasswordForm = this.add.dom(this.scale.width / 2, this.scale.height / 2).createFromCache('forgotpasswordform') as DOMElement;

        this.successMessage = this.forgotPasswordForm.getChildByID('successMessage') as HTMLParagraphElement;
        this.errorMessage = this.forgotPasswordForm.getChildByID('errorMessage') as HTMLParagraphElement;

        // Access the native form element
        const formElement = this.forgotPasswordForm.node as HTMLFormElement;
        formElement.onsubmit = (event: Event): void => {
            event.preventDefault();
            const userIdentifier = (this.forgotPasswordForm.getChildByID('userIdentifier') as HTMLInputElement).value;
            this.requestPasswordReset(userIdentifier);
        };

        this.forgotPasswordForm.addListener('pointerdown');
        this.forgotPasswordForm.on('pointerdown', (event: Event) => {
            const target = event.target as HTMLElement;
            if (target.id === 'loginLink') {
                this.tearDownListeners();
                this.scene.start(SceneNames.Login); // Switch to login scene
            }
            if (target.id === 'backToHomeLink') {
                // Logic to return to the home scene
                console.log('Returning to home scene');
            }
        });

        this.scene.bringToTop(SceneNames.ForgotPassword);
        this.setupSocketListeners();
    }

    private requestPasswordReset(userIdentifier: string): void {
        console.log('Requesting password reset for:', userIdentifier);

        // Access the form and message elements
        const errorMessage = this.forgotPasswordForm.getChildByID('errorMessage') as HTMLParagraphElement;

        // Check if the user identifier is empty
        if (!userIdentifier.trim()) {
            errorMessage.textContent = 'Please enter your username or email.';
            return;
        }

        // Send a password reset request via Socket.IO
        this.socket.emit(ServerSocketEvents.RequestPasswordReset, {userIdentifier});
    }

    public tearDownListeners(): void {
        this.socket.off(ClientSocketEvents.RequestPasswordResetResponse);
        this.socket.off(ClientSocketEvents.ServerShutdown);
    }
}
