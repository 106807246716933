import {Images} from '../../../../types/assets/AssetKeys';
import {Coord} from '../../../../types/mechanics/Coord';
import {DepthLevel} from '../../types/DepthLevel';

export default class DoorImage extends Phaser.GameObjects.Image {
    public position: Coord;
    public image: Images;
    private isOpen: boolean;

    public constructor(scene: Phaser.Scene, position: Coord, image: Images, isOpen: boolean) {
        super(scene, position.x, position.y, image);
        this.setOrigin(0.5, 1);
        this.setScale(3);
        this.setDepth(DepthLevel.DOOR);
        this.isOpen = isOpen;
        this.scene.add.existing(this);
        if (this.isOpen) {
            this.setVisible(false);
        } else {
            this.setVisible(true);
        }
    }
}
