export enum DepthLevel {
    // Splash Screen and General Backgrounds
    SPLASH_BACKGROUND = 5,

    // Ground tiles
    GROUND_FAR = 10,
    GROUND_MID = 20,
    GROUND_NEAR = 30,

    // Below entities
    UNDERFOOT_EFFECTS = 40,

    // Entities
    NPC = 50,
    DOOR = 52,
    SIGN = 53,
    NON_PARTY_PLAYERS = 55,
    PARTY_MEMBERS = 60,
    CLIENT_PLAYER = 70,

    // Target Rectangles for Selection
    TARGET_RECT_SUB = 75,
    TARGET_RECT_MID = 76,
    TARGET_RECT_TOP = 77,

    // Above entities
    FOREGROUND_OBJECTS = 80,
    ABOVE_FOOT_EFFECTS = 90,

    // Non-UI Graphics Layers
    NON_UI_GRAPHICS_SUB = 100,
    NON_UI_GRAPHICS_MID = 110,
    NON_UI_GRAPHICS_TOP = 120,

    // Primary UI Tier
    UI_PRIMARY_BASE_SUB = 130,
    UI_PRIMARY_BASE_MID = 140,
    UI_PRIMARY_BASE_TOP = 150,
    UI_PRIMARY_GRAPHICS_SUB = 160,
    UI_PRIMARY_GRAPHICS_MID = 170,
    UI_PRIMARY_GRAPHICS_TOP = 180,
    UI_PRIMARY_TEXT = 190,
    UI_PRIMARY_OVERLAY = 200,

    // Secondary UI Tier
    UI_SECONDARY_BASE_SUB = 210,
    UI_SECONDARY_BASE_MID = 220,
    UI_SECONDARY_BASE_TOP = 230,
    UI_SECONDARY_GRAPHICS_SUB = 240,
    UI_SECONDARY_GRAPHICS_MID = 250,
    UI_SECONDARY_GRAPHICS_TOP = 260,
    UI_SECONDARY_TEXT = 270,
    UI_SECONDARY_OVERLAY = 280,

    // Tertiary UI Tier
    UI_TERTIARY_BASE_SUB = 290,
    UI_TERTIARY_BASE_MID = 300,
    UI_TERTIARY_BASE_TOP = 310,
    UI_TERTIARY_GRAPHICS_SUB = 320,
    UI_TERTIARY_GRAPHICS_MID = 330,
    UI_TERTIARY_GRAPHICS_TOP = 340,
    UI_TERTIARY_TEXT = 350,
    UI_TERTIARY_OVERLAY = 360,

    // Quaternary UI Tier
    UI_QUATERNARY_BASE_SUB = 370,
    UI_QUATERNARY_BASE_MID = 380,
    UI_QUATERNARY_BASE_TOP = 390,
    UI_QUATERNARY_GRAPHICS_SUB = 400,
    UI_QUATERNARY_GRAPHICS_MID = 410,
    UI_QUATERNARY_GRAPHICS_TOP = 420,
    UI_QUATERNARY_TEXT = 430,
    UI_QUATERNARY_OVERLAY = 440,

    // Quinary UI Tier
    UI_QUINARY_BASE_SUB = 450,
    UI_QUINARY_BASE_MID = 460,
    UI_QUINARY_BASE_TOP = 470,
    UI_QUINARY_GRAPHICS_SUB = 480,
    UI_QUINARY_GRAPHICS_MID = 490,
    UI_QUINARY_GRAPHICS_TOP = 500,
    UI_QUINARY_TEXT = 510,
    UI_QUINARY_OVERLAY = 520,

    // Senary UI Tier
    UI_SENARY_BASE_SUB = 530,
    UI_SENARY_BASE_MID = 540,
    UI_SENARY_BASE_TOP = 550,
    UI_SENARY_GRAPHICS_SUB = 560,
    UI_SENARY_GRAPHICS_MID = 570,
    UI_SENARY_GRAPHICS_TOP = 580,
    UI_SENARY_TEXT = 590,
    UI_SENARY_OVERLAY = 600,

    // Septenary UI Tier
    UI_SEPTENARY_BASE_SUB = 610,
    UI_SEPTENARY_BASE_MID = 620,
    UI_SEPTENARY_BASE_TOP = 630,
    UI_SEPTENARY_GRAPHICS_SUB = 640,
    UI_SEPTENARY_GRAPHICS_MID = 650,
    UI_SEPTENARY_GRAPHICS_TOP = 660,
    UI_SEPTENARY_TEXT = 670,
    UI_SEPTENARY_OVERLAY = 680,

    // Octonary UI Tier
    UI_OCTONARY_BASE_SUB = 690,
    UI_OCTONARY_BASE_MID = 700,
    UI_OCTONARY_BASE_TOP = 710,
    UI_OCTONARY_GRAPHICS_SUB = 720,
    UI_OCTONARY_GRAPHICS_MID = 730,
    UI_OCTONARY_GRAPHICS_TOP = 740,
    UI_OCTONARY_TEXT = 750,
    UI_OCTONARY_OVERLAY = 760,

    // Nonary UI Tier
    UI_NONARY_BASE_SUB = 770,
    UI_NONARY_BASE_MID = 780,
    UI_NONARY_BASE_TOP = 790,
    UI_NONARY_GRAPHICS_SUB = 800,
    UI_NONARY_GRAPHICS_MID = 810,
    UI_NONARY_GRAPHICS_TOP = 820,
    UI_NONARY_TEXT = 830,
    UI_NONARY_OVERLAY = 840,

    // Denary UI Tier
    UI_DENARY_BASE_SUB = 850,
    UI_DENARY_BASE_MID = 860,
    UI_DENARY_BASE_TOP = 870,
    UI_DENARY_GRAPHICS_SUB = 880,
    UI_DENARY_GRAPHICS_MID = 890,
    UI_DENARY_GRAPHICS_TOP = 900,
    UI_DENARY_TEXT = 910,
    UI_DENARY_OVERLAY = 920,

    // Full cover for game overs and disconnects
    FULL_COVER = 1000,
}
