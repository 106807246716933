import Phaser from 'phaser';
import {UIImageKey} from '../../../../../../types/assets/AssetKeys';
import {mainGameFont} from '../../../../GameConfig';
import ServerControlledConflictUIScene from '../../../../scenes/ServerControlledUIScenes/ServerControlledConflictUIScene';
import {ConflictMenuType} from '../../../../types/ConflictMenuType';
import {DepthLevel} from '../../../../types/DepthLevel';
import NonContainerUIActionButton from '../../ActionButtons/NonContainerUIActionButton';
import ResizableFrame from '../../UtilityComponents/ResizableFrame';
import {GameConflictActionMenu} from './GameConflictActionMenu';

export class ConflictCommandMenu extends GameConflictActionMenu {
    public readonly menuTag: ConflictMenuType = ConflictMenuType.ConflictCommandMenu;
    public relatedMenusToDismiss: Set<ConflictMenuType> = new Set([
        ConflictMenuType.AttackMenu,
        ConflictMenuType.AbilityMenu,
        ConflictMenuType.InventoryMenu,
        ConflictMenuType.ConflictMessageElement,
    ]);

    public commandFrame: ResizableFrame;
    public topCommandText: Phaser.GameObjects.Text;
    private bottomCommandText: Phaser.GameObjects.Text;
    public commandIcon: NonContainerUIActionButton;
    private topCommandTextCoords = {x: 246, y: 440};
    private bottomCommandTextCoords = {x: 246, y: 490};

    public constructor(scene: ServerControlledConflictUIScene) {
        super();
        this.scene = scene;
        // Setup UI components during construction
        this.setupFrames();
        this.setupTexts();
        this.setupIcons();
    }

    private setupTexts(): void {
        // Setup text elements for the command menu
        console.log('Setting up texts for CommandMenu...');
        this.scene.conflictScene.commandString = 'Command?';
        this.topCommandText = this.scene.add.text(
            this.topCommandTextCoords.x,
            this.topCommandTextCoords.y,
            this.scene.conflictScene.commandString,
            {
                fontSize: '48px',
                color: '#fff',
                fontFamily: mainGameFont,
                wordWrap: {
                    width: 665, // Set the wrapping width to 500 pixels
                    useAdvancedWrap: true // Enable advanced word wrapping
                },
                metrics: {
                    ascent: 38, // Adjust as needed based on your specific font metrics
                    descent: 5,
                    fontSize: 48
                }
            }
        )
            .setLineSpacing(-12) // Reduce the line spacing for a more compact look
            .setResolution(3) // High resolution for clearer text
            .setOrigin(0) // Set the origin to the top left corner
            .setDepth(DepthLevel.UI_PRIMARY_TEXT) // Set the depth for UI layering
            .setVisible(false); // Initially set to not visible

        this.bottomCommandText = this.scene.add.text(
            this.bottomCommandTextCoords.x,
            this.bottomCommandTextCoords.y,
            '',
            {
                fontSize: '48px',
                color: '#fff',
                fontFamily: mainGameFont,
                wordWrap: {
                    width: 665, // Set the wrapping width to 500 pixels
                    useAdvancedWrap: true // Enable advanced word wrapping
                },
                metrics: {
                    ascent: 38, // Adjust as needed based on your specific font metrics
                    descent: 5,
                    fontSize: 48
                }
            }
        )
            .setLineSpacing(-12) // Reduce the line spacing for a more compact look
            .setResolution(3) // High resolution for clearer text
            .setOrigin(0) // Set the origin to the top left corner
            .setDepth(DepthLevel.UI_PRIMARY_TEXT) // Set the depth for UI layering
            .setVisible(false); // Initially set to not visible
    }

    private setupFrames(): void {
        console.log('Setting up frames for CommandMenu...');
        this.commandFrame = new ResizableFrame(this.scene, 574, 486, 662, 100);
        // this.commandFrame.setVisible(false);
        this.commandFrame.hideFrame();
    }

    public activate(params: {
        topMessage: string,
        updateTopMessage: boolean,
        showTopMessage: boolean,
        bottomMessage: string,
        updateBottomMessage: boolean,
        showBottomMessage: boolean,
        iconKey?: UIImageKey,
        iconActiveKey?: UIImageKey,
        iconLabel: string,
        updateIcon: boolean,
        showIcon: boolean,
        selectIcon: boolean // Mandatory property to select or deselect the icon
    }): void {
        console.log('[ConflictCommandMenu.activate] Activating ConflictCommandMenu.');

        // Dismiss related menus before making changes to the current menu
        this.dismissRelatedMenus();

        // Update and show the top command text if required
        if (params.updateTopMessage) {
            this.topCommandText.setText(params.topMessage);
        }
        this.topCommandText.setVisible(params.showTopMessage);

        // Update and show the bottom command text if required
        if (params.updateBottomMessage) {
            this.bottomCommandText.setText(params.bottomMessage);
        }
        this.bottomCommandText.setVisible(params.showBottomMessage);

        // Update the command icon configuration if needed
        if (params.updateIcon && params.showIcon) {
            if (params.iconKey && params.iconActiveKey) { // Ensure both keys are provided if updating the icon
                this.commandIcon.changeButtonImage(params.iconKey, params.iconActiveKey);
                this.commandIcon.changeButtonText(params.iconLabel);
                if (params.selectIcon) {
                    this.commandIcon.select(); // Assume select() method exists to handle selection
                } else {
                    this.commandIcon.deselect(); // Assume deselect() method exists to handle deselection
                }
            } else {
                console.error('Icon keys must be provided when updating icons.');
            }
        }

        // Set the visibility of the icon regardless of whether it was updated
        if (params.showIcon) {
            this.commandIcon.showActionButton();
        } else {
            this.commandIcon.hideActionButton();
        }

        // Ensure the command frame is visible
        this.commandFrame.showFrame();

        console.log('[ConflictCommandMenu.activate] ConflictCommandMenu activated.');
    }

    public dismiss(): void {
        console.log('[ConflictCommandMenu.dismiss] Dismissing ConflictCommandMenu.');

        console.log('[ConflictCommandMenu.dismiss] Setting command frame visibility to false.');
        // this.commandFrame.setVisible(false);
        this.commandFrame.hideFrame();

        console.log('[ConflictCommandMenu.dismiss] Setting command text visibility to false.');
        this.topCommandText.setVisible(false);

        this.bottomCommandText.setVisible(false);

        this.commandIcon.hideActionButton();

        console.log('[ConflictCommandMenu.dismiss] ConflictCommandMenu dismissed.');
    }

    public emitMenuActivationRequest(): void {
        this.activate({
            topMessage: this.scene.conflictScene.commandString,
            updateTopMessage: true,
            showTopMessage: true,
            bottomMessage: '',
            updateBottomMessage: true,
            showBottomMessage: false,
            iconLabel: '',
            updateIcon: false,
            showIcon: false,
            selectIcon: false
        });
    }

    public emitMenuDismissalRequest(): void {
        this.dismiss();
    }

    private setupIcons(): void {
        // Setup any icons or additional graphical elements for the attack menu
        console.log('Setting up icons for Conflict Command Menu...');
        this.commandIcon = new NonContainerUIActionButton(
            this.scene,
            266,
            465,
            UIImageKey.AttackButton,
            UIImageKey.AttackButtonActive,
            'Attack',
            () => {
            }
        );
        this.commandIcon.setDepth(DepthLevel.UI_PRIMARY_GRAPHICS_SUB);
        this.commandIcon.hideActionButton();
    }
}
