import converter from 'number-to-words';
import Phaser from 'phaser';
import {Socket} from 'socket.io-client';
import {SpriteSheet, UIImageKey} from '../../../../types/assets/AssetKeys';
import {CharacterCreationResponseEvent} from '../../../../types/events/CharacterCreationResponseEvent';
import {ClientSocketEvents} from '../../../../types/events/ClientSocketEvents';
import {RequestCharacterLoadResponseEvent} from '../../../../types/events/RequestCharacterLoadResponseEvent';
import {RequestCharacterSelectionSceneDetailsResponseEvent} from '../../../../types/events/RequestCharacterSelectionSceneDetailsResponseEvent';
import {ServerSocketEvents} from '../../../../types/events/ServerSocketEvents';
import {characterProfessionsArray} from '../../../../types/mechanics/CharacterProfessionsArray';
// import {CharacterProfession} from '../../../../types/mechanics/CharacterProfessionsEnum';
import {CharacterProfession} from '../../../../types/mechanics/CharacterProfessionsEnum';
import {Gender} from '../../../../types/mechanics/Gender';
import SocketManager from '../../classes/NetworkingAndChat/SocketManager';
import NonContainerUIActionButton from '../../classes/UserInterface/ActionButtons/NonContainerUIActionButton';
import ResizableFrame from '../../classes/UserInterface/UtilityComponents/ResizableFrame';
import {hairColors} from '../../data/hairColors';
import {heroWeaponColor} from '../../data/heroWeaponColor';
import {primaryColors} from '../../data/primaryColors';
import {secondaryColors} from '../../data/secondaryColors';
import {skinTones} from '../../data/skinTones';
import {tertiaryColor} from '../../data/tertiaryColor';
import {mainGameFont} from '../../GameConfig';
import {DepthLevel} from '../../types/DepthLevel';
import {SceneNames} from '../../types/SceneNames';

export default class CharacterSelectionScene extends Phaser.Scene {
    private backgroundRectangle: Phaser.GameObjects.Rectangle;
    private newCharacterButton: NonContainerUIActionButton;
    private newCharacterFrame: ResizableFrame;
    private socket: Socket;
    private disconnectionSceneRunning: boolean;
    private resizableFrames: ResizableFrame[] = []; // Array to store frames
    private frameCoordinates: { x: number, y: number }[] = []; // Array to store coordinates
    private nameTextObjects: Phaser.GameObjects.Text[] = [];
    private levelClassTextObjects: Phaser.GameObjects.Text[] = [];
    private characterComponents: {
        baseSprite: Phaser.GameObjects.Sprite,
        maleHairSprite: Phaser.GameObjects.Sprite,
        femaleHairSprite: Phaser.GameObjects.Sprite,
        primaryClothingSprite: Phaser.GameObjects.Sprite,
        secondaryClothingSprite: Phaser.GameObjects.Sprite,
        tertiaryClothingSprite: Phaser.GameObjects.Sprite,
        runebladeBottomWeaponSprite: Phaser.GameObjects.Sprite,
        runebladeTopWeaponSprite: Phaser.GameObjects.Sprite,
        aethermancerBottomWeaponSprite: Phaser.GameObjects.Sprite,
        aethermancerTopWeaponSprite: Phaser.GameObjects.Sprite,
        lifeweaverBottomWeaponSprite: Phaser.GameObjects.Sprite,
        lifeweaverTopWeaponSprite: Phaser.GameObjects.Sprite,
    }[] = [];
    private loadButtons: NonContainerUIActionButton[] = [];
    private deleteButtons: NonContainerUIActionButton[] = [];
    private frameWidth: number = 400;
    private frameHeight: number = 140;
    private xFrameSpacing: number = 25;
    private yFrameSpacing: number = 20;
    private startingYFrameOffset: number = 205;
    private characterDeleteConfirmationFrame: ResizableFrame;
    private characterDeleteConfirmationText: Phaser.GameObjects.Text;
    private characterDeleteConfirmationBackdrop: Phaser.GameObjects.Rectangle;
    private cancelDeleteButton: NonContainerUIActionButton;
    private confirmDeleteButton: NonContainerUIActionButton;
    private selectedIndexToDelete: number | null = null;
    private characterNames: string[] = [];
    private characterIds: string[] = [];
    private userIsSubscriber: boolean = false;
    private isModalVisible: boolean = false;
    private modalBackdrop: Phaser.GameObjects.Rectangle;
    private modalFrame: ResizableFrame; // Assuming ResizableFrame is a class you have defined
    private modalText: Phaser.GameObjects.Text;
    private okButton: NonContainerUIActionButton; // Assuming NonContainerUIActionButton is a class you have defined

    public constructor() {
        super(SceneNames.CharacterSelection);
        this.socket = SocketManager.getInstance().socket;
    }

    public create(): void {
        this.setupBackground();
        this.setupFrames();
        this.createTextLabels();
        this.setupNewCharacterButton();
        this.createCharacterSprites();
        this.createLoadButtons();
        this.createDeleteButtons();
        this.createCharacterDeleteConfirmationMenu();
        this.createModal();
        this.setupSocketListeners();
        this.requestCharacterSceneDetails();
    }

    private createModal(): void {
        // Create the backdrop
        this.modalBackdrop = this.add.rectangle(
            0, 0, this.scale.width, this.scale.height, 0x000000
        ).setOrigin(0, 0).setAlpha(0.5).setDepth(DepthLevel.UI_PRIMARY_OVERLAY).setVisible(false);

        // Create the frame
        this.modalFrame = new ResizableFrame(
            this,
            (this.scale.width / 2),
            (this.scale.height / 2),
            440, // Width of the frame
            200, // Height of the frame
            2 // Frame border thickness
        );
        this.modalFrame.hideFrame();

        // Create the text
        this.modalText = new Phaser.GameObjects.Text(
            this, (this.scale.width / 2), (this.scale.height / 2) - 101, 'This is the modal text', {
                fontFamily: mainGameFont,
                fontSize: '48px', // Updated font size
                metrics: {
                    ascent: 38, // Adjusted for new font size
                    descent: 9, // Adjusted for new font size
                    fontSize: 48 // Updated fontSize in metrics
                },
                color: '#ffffff',
                align: 'center',
                wordWrap: {
                    width: 440,
                    useAdvancedWrap: true
                }
            }
        );
        this.add.existing(this.modalText);
        this.modalText.setDepth(DepthLevel.UI_SECONDARY_TEXT);
        this.modalText.setLineSpacing(-12);
        this.modalText.setResolution(3);
        this.modalText.setOrigin(0.5, 0);
        this.modalText.setVisible(false);

        // Create the 'OK' button
        this.okButton = new NonContainerUIActionButton(
            this,
            (this.scale.width / 2),
            (this.scale.height / 2) + 70, // Position the button below the text
            UIImageKey.CheckButton, // Replace with your OK button image key
            UIImageKey.CheckButton, // Replace with your OK button hover image key
            'OK',
            () => this.hideModal() // Set method as callback
        );
        this.okButton.setDepth(DepthLevel.UI_SECONDARY_BASE_MID);
        this.okButton.hideActionButton();
    }

    private showModal(): void {
        this.isModalVisible = true;
        this.modalBackdrop.setVisible(true);
        this.modalFrame.showFrame();
        this.modalText.setVisible(true);
        this.okButton.showActionButton();
    }

    private hideModal(): void {
        this.isModalVisible = false;
        this.modalBackdrop.setVisible(false);
        this.modalFrame.hideFrame();
        this.modalText.setVisible(false);
        this.okButton.hideActionButton();
    }

    private createCharacterDeleteConfirmationMenu(): void {
        this.characterDeleteConfirmationBackdrop = this.add.rectangle(
            0, 0, this.scale.width, this.scale.height, 0x000000
        ).setOrigin(0, 0).setAlpha(0.5).setDepth(DepthLevel.UI_PRIMARY_OVERLAY).setVisible(false);
        this.characterDeleteConfirmationFrame = new ResizableFrame(
            this,
            (this.scale.width / 2),
            (this.scale.height / 2),
            440,
            200,
            2
        );
        this.characterDeleteConfirmationFrame.hideFrame();

        this.characterDeleteConfirmationText = new Phaser.GameObjects.Text(
            this, (this.scale.width / 2), (this.scale.height / 2) - 101, 'Are you sure you want to delete this character?', {
                fontFamily: mainGameFont,
                fontSize: '48px', // Updated font size
                metrics: {
                    ascent: 38, // Adjusted for new font size
                    descent: 9, // Adjusted for new font size
                    fontSize: 48 // Updated fontSize in metrics
                },
                color: '#ffffff',
                align: 'center',
                wordWrap: {
                    width: 440,
                    useAdvancedWrap: true
                }
            }
        );

        this.add.existing(this.characterDeleteConfirmationText);
        this.characterDeleteConfirmationText.setDepth(DepthLevel.UI_SECONDARY_TEXT);
        this.characterDeleteConfirmationText.setLineSpacing(-12);
        this.characterDeleteConfirmationText.setResolution(3);
        this.characterDeleteConfirmationText.setOrigin(0.5, 0);
        this.characterDeleteConfirmationText.setVisible(false);

        this.confirmDeleteButton = new NonContainerUIActionButton(
            this,
            (this.scale.width / 2) + 30,
            (this.scale.height / 2) + 65,
            UIImageKey.CheckButton,
            UIImageKey.CheckButton,
            'Confirm',
            () => this.onConfirmDelete() // Set method as callback
        );
        this.confirmDeleteButton.setDepth(DepthLevel.UI_SECONDARY_BASE_MID);
        this.confirmDeleteButton.hideActionButton();

        this.cancelDeleteButton = new NonContainerUIActionButton(
            this,
            (this.scale.width / 2) - 170,
            (this.scale.height / 2) + 65,
            UIImageKey.CrossButton,
            UIImageKey.CrossButton,
            'Cancel',
            () => this.onCancelDelete() // Set method as callback
        );
        this.cancelDeleteButton.setDepth(DepthLevel.UI_SECONDARY_BASE_MID);
        this.cancelDeleteButton.hideActionButton();
    }

    private onConfirmDelete(): void {
        if (this.selectedIndexToDelete === null) {
            console.log('No character selected for deletion.');
            return;
        } else if (this.isModalVisible) {
            console.log('Modal is visible, cannot confirm deletion of character.');
            return;
        }

        console.log('Confirm delete button clicked');

        // Retrieve the ID of the character to be deleted
        const characterIdToDelete = this.characterIds[this.selectedIndexToDelete];
        if (!characterIdToDelete) {
            console.log('Error: No character ID found for the selected index.');
            return;
        }

        // Send a request to the server to delete the character
        // Example: emit a signal to the server (adjust according to your server communication setup)
        this.socket.emit(ServerSocketEvents.DeleteCharacter, characterIdToDelete);

    }

    private onCancelDelete(): void {
        if (this.selectedIndexToDelete === null) {
            console.log('No character selected for deletion.');
            return;
        } else if (this.isModalVisible) {
            console.log('Modal is visible, cannot cancel deletion of character.');
            return;
        }

        console.log('Cancel delete button clicked');

        // Reset the selectedIndexToDelete
        this.selectedIndexToDelete = null;

        // Hide the delete confirmation elements
        this.hideDeleteConfirmationUI();

        // Additional logic for canceling the deletion, if any
    }

    private hideDeleteConfirmationUI(): void {
        this.characterDeleteConfirmationBackdrop.setVisible(false);
        this.characterDeleteConfirmationFrame.hideFrame();
        this.characterDeleteConfirmationText.setVisible(false);
        this.confirmDeleteButton.hideActionButton();
        this.cancelDeleteButton.hideActionButton();
    }

    private populateCharacterDetails(characterDetails: {
        id: string,
        name: string;
        class: CharacterProfession; // Assuming you have a type or enum for this
        skinToneColor: string;
        hairColor: string;
        primaryColor: string;
        secondaryColor: string;
        gender: Gender; // Assuming you have a type or enum for this
        level: number;
    }[]): void {
        console.log('Populating character details:', characterDetails);

        this.characterNames = [];
        this.characterIds = [];
        // Assuming characterDetails are sorted by some logical order
        characterDetails.forEach((details, index) => {
            if (index < this.characterComponents.length) {
                this.characterIds.push(details.id);
                // Add the name to the characterNames array
                this.characterNames.push(details.name);

                // Set the character sprite details
                const charComponents = this.characterComponents[index];
                charComponents.baseSprite.setVisible(true).setTint(Phaser.Display.Color.HexStringToColor(details.skinToneColor).color);
                if (details.gender === Gender.Male) {
                    charComponents.maleHairSprite.setVisible(true).setTint(Phaser.Display.Color.HexStringToColor(details.hairColor).color);
                } else {
                    charComponents.femaleHairSprite.setVisible(true).setTint(Phaser.Display.Color.HexStringToColor(details.hairColor).color);
                }
                charComponents.primaryClothingSprite.setVisible(true).setTint(Phaser.Display.Color.HexStringToColor(details.primaryColor).color);
                charComponents.secondaryClothingSprite.setVisible(true).setTint(Phaser.Display.Color.HexStringToColor(details.secondaryColor).color);
                charComponents.tertiaryClothingSprite.setVisible(true).setTint(Phaser.Display.Color.HexStringToColor(tertiaryColor).color);

                // Set the weapon sprite based on class, example for one class
                if (details.class === CharacterProfession.Runeblade) {
                    charComponents.runebladeBottomWeaponSprite.setVisible(true).setTint(Phaser.Display.Color.HexStringToColor(heroWeaponColor).color);
                    charComponents.runebladeTopWeaponSprite.setVisible(true).setTint(Phaser.Display.Color.HexStringToColor(heroWeaponColor).color);
                } else if (details.class === CharacterProfession.Aethermancer) {
                    charComponents.aethermancerBottomWeaponSprite.setVisible(true).setTint(Phaser.Display.Color.HexStringToColor(heroWeaponColor).color);
                    charComponents.aethermancerTopWeaponSprite.setVisible(true).setTint(Phaser.Display.Color.HexStringToColor(heroWeaponColor).color);
                } else if (details.class === CharacterProfession.Lifeweaver) {
                    charComponents.lifeweaverBottomWeaponSprite.setVisible(true).setTint(Phaser.Display.Color.HexStringToColor(heroWeaponColor).color);
                    charComponents.lifeweaverTopWeaponSprite.setVisible(true).setTint(Phaser.Display.Color.HexStringToColor(heroWeaponColor).color);
                }

                // Set the text for the name and class/level
                this.nameTextObjects[index].setText(details.name).setVisible(true);
                this.levelClassTextObjects[index].setText(`Level ${details.level} ${details.class}`).setVisible(true);

                // Show the frame and buttons for the character
                this.resizableFrames[index].showFrame();
                this.loadButtons[index].showActionButton();
                this.deleteButtons[index].showActionButton();
            }
        });

        // If there are less characters than frames, hide the remaining frames
        for (let i = characterDetails.length; i < this.resizableFrames.length; i++) {
            this.resizableFrames[i].hideFrame();
            this.loadButtons[i].hideActionButton();
            this.deleteButtons[i].hideActionButton();
        }
    }

    private clearCharacterDetails(): void {
        // Clear character names and IDs arrays
        this.characterNames = [];
        this.characterIds = [];

        // Hide and reset all character components, text objects, and buttons
        this.characterComponents.forEach(component => {
            component.baseSprite.setVisible(false);
            component.maleHairSprite.setVisible(false);
            component.femaleHairSprite.setVisible(false);
            component.primaryClothingSprite.setVisible(false);
            component.secondaryClothingSprite.setVisible(false);
            component.tertiaryClothingSprite.setVisible(false);
            component.runebladeBottomWeaponSprite.setVisible(false);
            component.runebladeTopWeaponSprite.setVisible(false);
            component.aethermancerBottomWeaponSprite.setVisible(false);
            component.aethermancerTopWeaponSprite.setVisible(false);
            component.lifeweaverBottomWeaponSprite.setVisible(false);
            component.lifeweaverTopWeaponSprite.setVisible(false);
        });

        // Hide and reset text objects for names and class/level
        this.nameTextObjects.forEach(textObject => {
            textObject.setText('').setVisible(false);
        });
        this.levelClassTextObjects.forEach(textObject => {
            textObject.setText('').setVisible(false);
        });

        // Hide all frames and buttons
        this.resizableFrames.forEach(frame => frame.hideFrame());
        this.loadButtons.forEach(button => button.hideActionButton());
        this.deleteButtons.forEach(button => button.hideActionButton());

        // Any additional cleanup as required
    }

    private requestCharacterSceneDetails(): void {
        // Request character details from the server
        // The userId should be retrieved from the client's session or similar
        this.socket.emit(ServerSocketEvents.RequestCharacterSelectionSceneDetails);
    }

    private createDeleteButtons(): void {
        for (let i = 0; i < this.frameCoordinates.length; i++) {
            const coord = this.frameCoordinates[i];
            const buttonX = coord.x - 93;
            const buttonY = coord.y + 33;

            // Create the delete button
            const deleteButton = new NonContainerUIActionButton(
                this,
                buttonX,
                buttonY,
                UIImageKey.CrossButton,
                UIImageKey.CrossButton,
                'Delete',
                () => this.onDeleteButtonClick(i) // Set method as callback
            );

            deleteButton.hideActionButton();
            this.deleteButtons[i] = deleteButton;
        }
    }

    private onDeleteButtonClick(index: number): void {
        if (this.selectedIndexToDelete !== null) {
            console.log('Delete operation already in progress.');
            return;
        } else if (this.isModalVisible) {
            console.log('Modal is visible, cannot delete character.');
            return;
        }

        console.log(`Delete button clicked for index: ${index}`);
        this.selectedIndexToDelete = index; // Set the selectedIndexToDelete

        console.log(`Character names: ${this.characterNames}`);
        console.log(`Character IDs: ${this.characterIds}`);

        // Update delete confirmation text with character's name
        const characterName = this.characterNames[index];
        const confirmationMessage = `Are you sure you want to delete ${characterName}?`;
        this.characterDeleteConfirmationText.setText(confirmationMessage);

        // Make the delete confirmation elements visible
        this.characterDeleteConfirmationBackdrop.setVisible(true);
        this.characterDeleteConfirmationFrame.showFrame();
        this.characterDeleteConfirmationText.setVisible(true);
        this.confirmDeleteButton.showActionButton();
        this.cancelDeleteButton.showActionButton();

        // Additional logic for delete operation
    }

    private createLoadButtons(): void {
        for (let i = 0; i < this.frameCoordinates.length; i++) {
            const coord = this.frameCoordinates[i];
            const buttonX = coord.x + 83; // Adjust X position as needed
            const buttonY = coord.y + 33; // Adjust Y position as needed

            // Create the load button
            const loadButton = new NonContainerUIActionButton(
                this,
                buttonX,
                buttonY,
                UIImageKey.CheckButton,
                UIImageKey.CheckButton,
                'Load',
                () => this.onLoadButtonClick(i) // Set method as callback
            );

            loadButton.hideActionButton();
            this.loadButtons[i] = loadButton;
        }
    }

    private onLoadButtonClick(index: number): void {
        if (this.selectedIndexToDelete !== null) {
            console.log('Pending delete operation, cannot load character.');
            return;
        } else if (this.isModalVisible) {
            console.log('Modal is visible, cannot load character.');
            return;
        }

        if (!this.userIsSubscriber && index >= 2) {
            console.log('User is not a subscriber and is trying to access more than two characters.');
            this.modalText.setText('You must have an active subscription to access more than two characters.');
            this.showModal();
            return;
        }

        console.log(`Load button clicked for index: ${index}`);
        console.log(`Loading character with ID: ${this.characterIds[index]}`);
        this.socket.emit(ServerSocketEvents.RequestCharacterLoad, this.characterIds[index]);
    }

    private createCharacterSprites(): void {
        const xOffset = -165; // Amount to move sprites to the left
        const yOffset = 30; // Amount to move sprites lower
        const scaleFactor = 1.5; // Scale factor for the sprites

        for (let i = 0; i < this.frameCoordinates.length; i++) {
            const coord = this.frameCoordinates[i];

            // Random colors
            const skinTone = skinTones[Math.floor(Math.random() * skinTones.length)];
            const hairColor = hairColors[Math.floor(Math.random() * hairColors.length)]; // Using primary colors for hair
            const primaryColor = primaryColors[Math.floor(Math.random() * primaryColors.length)];
            const secondaryColor = secondaryColors[Math.floor(Math.random() * secondaryColors.length)];

            // Adjust sprite positions
            const spriteX = coord.x + xOffset;
            const spriteY = coord.y + yOffset;

            // Create, position, scale and color the character's sprites
            const baseSprite = this.add.sprite(spriteX, spriteY, SpriteSheet.HeroBaseSprite).setScale(scaleFactor).setTint(Number(skinTone)).setDepth(DepthLevel.UI_PRIMARY_GRAPHICS_SUB).setVisible(false);
            const maleHairSprite = this.add.sprite(spriteX, spriteY, SpriteSheet.HeroHairMale).setScale(scaleFactor).setTint(Number(hairColor)).setDepth(DepthLevel.UI_PRIMARY_GRAPHICS_SUB).setVisible(false);
            const femaleHairSprite = this.add.sprite(spriteX, spriteY, SpriteSheet.HeroHairFemale).setScale(scaleFactor).setTint(Number(hairColor)).setVisible(false).setDepth(DepthLevel.UI_PRIMARY_GRAPHICS_SUB);
            const primaryClothingSprite = this.add.sprite(spriteX, spriteY, SpriteSheet.HeroClothesPrimary).setScale(scaleFactor).setTint(Number(primaryColor)).setDepth(DepthLevel.UI_PRIMARY_GRAPHICS_SUB).setVisible(false);
            const secondaryClothingSprite = this.add.sprite(spriteX, spriteY, SpriteSheet.HeroClothesSecondary).setScale(scaleFactor).setTint(Number(secondaryColor)).setDepth(DepthLevel.UI_PRIMARY_GRAPHICS_SUB).setVisible(false);
            const tertiaryClothingSprite = this.add.sprite(spriteX, spriteY, SpriteSheet.HeroClothesTertiary).setScale(scaleFactor).setTint(Number(tertiaryColor)).setDepth(DepthLevel.UI_PRIMARY_GRAPHICS_SUB).setVisible(false);

            // Weapon Sprites
            const runebladeBottomWeaponSprite = this.add.sprite(spriteX, spriteY, SpriteSheet.RunebladeWeaponBottom).setScale(scaleFactor).setVisible(false).setTint(Number(heroWeaponColor)).setDepth(DepthLevel.UI_PRIMARY_GRAPHICS_SUB);
            const runebladeTopWeaponSprite = this.add.sprite(spriteX, spriteY, SpriteSheet.RunebladeWeaponTop).setScale(scaleFactor).setVisible(false).setTint(Number(heroWeaponColor)).setDepth(DepthLevel.UI_PRIMARY_GRAPHICS_SUB);
            const aethermancerBottomWeaponSprite = this.add.sprite(spriteX, spriteY, SpriteSheet.AethermancerWeaponBottom).setScale(scaleFactor).setVisible(false).setTint(Number(heroWeaponColor)).setDepth(DepthLevel.UI_PRIMARY_GRAPHICS_SUB);
            const aethermancerTopWeaponSprite = this.add.sprite(spriteX, spriteY, SpriteSheet.AethermancerWeaponTop).setScale(scaleFactor).setVisible(false).setTint(Number(heroWeaponColor)).setDepth(DepthLevel.UI_PRIMARY_GRAPHICS_SUB);
            const lifeweaverBottomWeaponSprite = this.add.sprite(spriteX, spriteY, SpriteSheet.LifeweaverWeaponBottom).setScale(scaleFactor).setVisible(false).setTint(Number(heroWeaponColor)).setDepth(DepthLevel.UI_PRIMARY_GRAPHICS_SUB);
            const lifeweaverTopWeaponSprite = this.add.sprite(spriteX, spriteY, SpriteSheet.LifeweaverWeaponTop).setScale(scaleFactor).setVisible(false).setTint(Number(heroWeaponColor)).setDepth(DepthLevel.UI_PRIMARY_GRAPHICS_SUB);

            // Store the entire character component
            this.characterComponents[i] = {
                baseSprite,
                maleHairSprite,
                femaleHairSprite,
                primaryClothingSprite,
                secondaryClothingSprite,
                tertiaryClothingSprite,
                runebladeBottomWeaponSprite,
                runebladeTopWeaponSprite,
                aethermancerBottomWeaponSprite,
                aethermancerTopWeaponSprite,
                lifeweaverBottomWeaponSprite,
                lifeweaverTopWeaponSprite
            };
        }
    }

    private setupFrames(): void {
        const centerScreenY = this.scale.height / 2; // Center Y of the screen
        const centerScreenX = this.scale.width / 2; // Center X of the screen

        const startXFrameLeft = centerScreenX - this.frameWidth / 2 - this.xFrameSpacing;
        const startXFrameRight = centerScreenX + this.frameWidth / 2 + this.xFrameSpacing;

        // Loop to create frames in a 2x4 grid and store their coordinates
        for (let row = 0; row < 4; row++) {
            const yPosition = centerScreenY - this.startingYFrameOffset + row * (this.frameHeight + this.yFrameSpacing);
            this.frameCoordinates[row * 2] = {x: startXFrameLeft, y: yPosition};
            this.frameCoordinates[row * 2 + 1] = {x: startXFrameRight, y: yPosition};

            // Create and store the frame
            this.resizableFrames[row * 2] = new ResizableFrame(this, startXFrameLeft, yPosition, this.frameWidth, this.frameHeight);
            this.resizableFrames[row * 2].hideFrame();
            this.resizableFrames[row * 2 + 1] = new ResizableFrame(this, startXFrameRight, yPosition, this.frameWidth, this.frameHeight);
            this.resizableFrames[row * 2 + 1].hideFrame();
        }
    }

    private createText(x: number, y: number, label: string): Phaser.GameObjects.Text {
        return this.add.text(x, y, label, {
            fontSize: '40px',
            fontFamily: mainGameFont,
            color: '#FFFFFF',
            align: 'center'
        }).setOrigin(0, 0).setResolution(3).setDepth(DepthLevel.UI_PRIMARY_TEXT);
    }

    private setupNewCharacterButton(): void {
        this.newCharacterFrame = new ResizableFrame(
            this,
            (this.scale.width / 2),
            35,
            350,
            50
        );
        this.newCharacterFrame.hideFrame();
        this.newCharacterButton = new NonContainerUIActionButton(
            this,
            (this.scale.width / 2) - 130,
            35,
            UIImageKey.CheckButton,
            UIImageKey.CheckButton,
            'New Character',
            () => this.onNewCharacterButtonClick() // Set method as callback
        );
        this.newCharacterButton.hideActionButton();
    }

    private showNewCharacterButton(): void {
        this.newCharacterFrame.showFrame();
        this.newCharacterButton.showActionButton();
    }

    private onNewCharacterButtonClick(): void {
        if (this.selectedIndexToDelete !== null) {
            console.log('Pending delete operation, cannot create a new character.');
            return;
        } else if (this.isModalVisible) {
            console.log('Modal is visible, cannot create new character.');
            return;
        }

        if (!this.userIsSubscriber && this.characterIds.length >= 2) {
            // check if the user already has two characters. if they do, show a modal saying they need to be a
            //  subscriber to create more characters
            console.log('User is not a subscriber, cannot create a new character.');
            this.modalText.setText('You must be a subscriber to create more characters.');
            this.showModal();
            return;
        }

        this.socket.emit(ServerSocketEvents.RequestCharacterCreation);
    }

    private createTextLabels(): void {
        for (let i = 0; i < this.frameCoordinates.length; i++) {
            const coord = this.frameCoordinates[i];
            const numberWord = converter.toWords(i + 1);
            const capitalizedNumberWord = numberWord.charAt(0).toUpperCase() + numberWord.slice(1);

            // Randomly select a profession for each frame
            const randomProfessionIndex = Math.floor(Math.random() * characterProfessionsArray.length);
            const randomProfession = characterProfessionsArray[randomProfessionIndex];

            // Create labels for the frame
            const frameNameLabel = `Test Name ${capitalizedNumberWord}`;
            const frameLevelClassLabel = `Level XXX ${randomProfession}`;

            // Calculate the text's X and Y position for better readability
            const textX = coord.x - this.frameWidth / 2 + 2; // Adjust X position using class-level property
            const nameTextY = coord.y - this.frameHeight / 2; // Adjust Y position using class-level property
            const levelClassTextY = nameTextY + 35; // Adjust Y position for combined label

            // Create and store text objects for the name and level-class labels
            this.nameTextObjects[i] = this.createText(textX, nameTextY, frameNameLabel);
            this.nameTextObjects[i].setVisible(false);
            this.levelClassTextObjects[i] = this.createText(textX, levelClassTextY, frameLevelClassLabel);
            this.levelClassTextObjects[i].setVisible(false);
        }
    }

    private setupBackground(): void {
        this.backgroundRectangle = this.add.rectangle(
            0, 0, this.scale.width, this.scale.height, 0xbcbcbc
        ).setOrigin(0, 0);
    }

    private setupSocketListeners(): void {
        this.socket.on(ClientSocketEvents.ServerShutdown, (response: { reason: string }) => {
            console.log('Server shutdown:', response);
            const reason = response.reason;
            if (this.disconnectionSceneRunning) {
                return; // If the scene is already running, skip the rest
            }
            this.scene.pause();

            // Disconnect the socket
            this.socket.disconnect();

            // Set the flag to true
            this.disconnectionSceneRunning = true;

            // Start a new scene
            this.scene.run(SceneNames.Disconnection, {reason: reason});
        });

        // Set up listener for the response
        this.socket.on(ClientSocketEvents.RequestCharacterSelectionSceneDetailsResponse, (characterDetailsResponse: RequestCharacterSelectionSceneDetailsResponseEvent) => {
            // Here you should populate your scene with the character details
            if (!characterDetailsResponse.success) {
                console.error(characterDetailsResponse.error);
                return;
            }
            this.userIsSubscriber = characterDetailsResponse.isSubscriber;
            this.showNewCharacterButton();
            this.populateCharacterDetails(characterDetailsResponse.characterDetails);
        });
        this.socket.on(ClientSocketEvents.DeleteCharacterResponse, (deleteCharacterResponse: RequestCharacterSelectionSceneDetailsResponseEvent) => {
            // Here you should populate your scene with the character details
            if (!deleteCharacterResponse.success) {
                console.error(deleteCharacterResponse.error);
                return;
            }

            // Reset the selectedIndexToDelete
            this.selectedIndexToDelete = null;

            // Hide the delete confirmation elements
            this.hideDeleteConfirmationUI();

            this.clearCharacterDetails();
            this.populateCharacterDetails(deleteCharacterResponse.characterDetails);
        });
        this.socket.on(ClientSocketEvents.RequestCharacterCreationResponse, (requestCharacterCreationResponse: CharacterCreationResponseEvent) => {
            if (!requestCharacterCreationResponse.success) {
                console.error(requestCharacterCreationResponse.error);

                // Set the modal text to the error message and show the modal
                this.modalText.setText(requestCharacterCreationResponse.error);
                this.showModal();
                return;
            }
            this.tearDownListeners();
            this.scene.start(SceneNames.CharacterClassSelection);
        });
        this.socket.on(ClientSocketEvents.RequestCharacterLoadResponse, (requestCharacterLoadResponse: RequestCharacterLoadResponseEvent) => {

            // If the ServerControlledGame scene is already active, don't start it again
            if (this.scene.isActive(SceneNames.ServerControlledGame)) {
                console.warn('ServerControlledGame scene is already active. Not starting it again.');
                return;
            }

            if (!requestCharacterLoadResponse.success) {
                console.error(requestCharacterLoadResponse.error);

                // Set the modal text to the error message and show the modal
                this.modalText.setText(requestCharacterLoadResponse.error);
                this.showModal();
                return;
            }

            // Tear down listeners before transitioning to the new scene
            this.tearDownListeners();

            // Start the ServerControlledGame scene with the loaded character data
            this.scene.start(SceneNames.ServerControlledGame, {
                inCombat: requestCharacterLoadResponse.inCombat
            });
        });
    }

    private tearDownListeners(): void {
        this.socket.off(ClientSocketEvents.ServerShutdown);
        this.socket.off(ClientSocketEvents.RequestCharacterSelectionSceneDetailsResponse);
        this.socket.off(ClientSocketEvents.DeleteCharacterResponse);
        this.socket.off(ClientSocketEvents.RequestCharacterCreationResponse);
        this.socket.off(ClientSocketEvents.RequestCharacterLoadResponse);
    }
}
