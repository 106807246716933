// src/client/app/scenes/ServerControlledGameScene.ts

import {Vector2} from 'arcade-physics/lib/math/Vector2';
import {Socket} from 'socket.io-client';
import {ClientSocketEvents} from '../../../../types/events/ClientSocketEvents';
import {ServerSocketEvents} from '../../../../types/events/ServerSocketEvents';
import {Direction} from '../../../../types/physics/Direction';
import NPCSprite from '../../classes/CharactersAndPlayers/NPCSprite';
import PlayerSprite from '../../classes/CharactersAndPlayers/PlayerSprite';
import PlayerInputController from '../../classes/GameplayAndControl/PlayerInputController';
import ChatManager from '../../classes/NetworkingAndChat/ChatManager';
import GameSocketEventHandler from '../../classes/NetworkingAndChat/GameSocketEventHandler';
import SocketManager from '../../classes/NetworkingAndChat/SocketManager';
import {OperatingSystemInfo} from '../../classes/System/OperatingSystemInfo';
import {AnimatedTile} from '../../classes/TilesAndEnvironment/AnimatedTile';
import DoorImage from '../../classes/TilesAndEnvironment/DoorImage';
import SignImage from '../../classes/TilesAndEnvironment/SignImage';
import {OperatingSystem} from '../../types/OperatingSystem';
import {SceneNames} from '../../types/SceneNames';
import GamePadScene from '../InputMethods/GamePadScene';
import ServerControlledGameUIScene from '../ServerControlledUIScenes/ServerControlledGameUIScene';
import ServerControlledConflictScene from './ServerControlledConflictScene';

export default class ServerControlledGameScene extends Phaser.Scene {
    public playerInputController: PlayerInputController;
    public player: PlayerSprite;
    public gamePadScene: GamePadScene;
    public remotePlayers: Map<string, PlayerSprite> = new Map<string, PlayerSprite>();
    public currentTilemap: Phaser.Tilemaps.Tilemap;
    public lastPosition: Vector2 = new Vector2(0, 0);
    public currentFrame: number = 0;
    public serverControlledGameUIScene: ServerControlledGameUIScene;
    public npcs: Map<string, NPCSprite> = new Map();
    private socket: Socket;
    private chatManager: ChatManager;
    public gameSocketEventHandler: GameSocketEventHandler;
    private accumulatedTime: number = 0;
    private frameRate: number = 2; // 30 fps
    private scenesAndControllersLoaded = {
        gameScene: false,
        gameUiScene: false,
        conflictScene: false,
        conflictUiScene: false,
        conflictUiSceneController: false
    };

    public animatedTiles: AnimatedTile[] = [];

    private initData: { inCombat?: boolean } = {}; // Directly define the shape
    public chatFrameCounter: number = 1;
    public serverControlledConflictScene: ServerControlledConflictScene;

    public doors: DoorImage[] = [];
    public signs: SignImage[] = [];

    public constructor() {
        super(SceneNames.ServerControlledGame);
        this.socket = SocketManager.getInstance().socket;
        console.log('Socket initialized:', this.socket);
    }

    public init(data: { inCombat?: boolean } = {}): void {
        // Directly using the shape of the data in the parameter
        this.initData = data;
    }

    public preload(): void {
        if (this.sys.game.device.os.desktop) {
            OperatingSystemInfo.getInstance().operatingSystem = OperatingSystem.Desktop;
        }
        if (this.sys.game.device.os.android) {
            OperatingSystemInfo.getInstance().operatingSystem = OperatingSystem.Mobile;
        }
        if (this.sys.game.device.os.iOS) {
            OperatingSystemInfo.getInstance().operatingSystem = OperatingSystem.Mobile;
        }
        console.log('operating system:');
        console.log(OperatingSystemInfo.getInstance().operatingSystem);
    }

    public create(): void {

        console.log('Creating game scene...');

        if (OperatingSystemInfo.getInstance().operatingSystem === OperatingSystem.Mobile) {
            // Launching the game pad scene
            this.scene.launch(SceneNames.GamePad);
            this.gamePadScene = <GamePadScene>this.scene.get(SceneNames.GamePad);
        }

        this.playerInputController = new PlayerInputController(this, this.socket);
        this.input.keyboard!.on('keydown', this.playerInputController.handleKeyDown.bind(this.playerInputController));
        this.input.keyboard!.on('keyup', this.playerInputController.handleKeyUp.bind(this.playerInputController));

        this.gameSocketEventHandler = new GameSocketEventHandler(this, this.socket);

        // Listen to the shutdown event
        this.events.once('shutdown', this.shutdown, this);

        if (!this.chatManager) {
            this.chatManager = new ChatManager();
        }

        console.log('Game scene created successfully.');

        if (this.initData.inCombat) {
            this.scene.launch(SceneNames.BlackScreen);
        }

        this.scene.launch(SceneNames.ServerControlledGameUI);
        this.serverControlledGameUIScene = this.scene.get(SceneNames.ServerControlledGameUI) as ServerControlledGameUIScene;

        this.scene.launch(SceneNames.ServerControlledConflict);
        this.serverControlledConflictScene = this.scene.get(SceneNames.ServerControlledConflict) as ServerControlledConflictScene;

        // don't send this signal until all the scenes are finished loading
        //  i.e. game scene, game ui scene, conflict scene, and conflict ui scene
        // this.socket.emit(ServerSocketEvents.ClientGameSceneInitialized);

        this.setSceneLoaded('gameScene');
    }

    public setSceneLoaded(sceneName: 'gameScene' | 'gameUiScene' | 'conflictScene' | 'conflictUiScene' | 'conflictUiSceneController'): void {
        this.scenesAndControllersLoaded[sceneName] = true; // Set the specified scene as loaded
        this.checkAndEmitAllScenesLoaded(); // Check if all scenes are loaded
    }

    private checkAndEmitAllScenesLoaded(): void {
        const allLoaded = Object.values(this.scenesAndControllersLoaded).every(status => status);
        if (allLoaded) {
            this.socket.emit(ServerSocketEvents.ClientGameSceneInitialized);
        }
    }

    public update(time: number, delta: number): void {
        this.playerInputController.update();
        this.accumulatedTime += delta;

        if (this.accumulatedTime >= 1000 / this.frameRate) {
            this.accumulatedTime -= 1000 / this.frameRate;
            this.currentFrame = this.currentFrame === 0 ? 1 : 0;
            this.updateSpritesFrame();
            this.updateTargetFlash();
            this.updateAnimatedTiles();
        }
    }

    private updateAnimatedTiles(): void {
        this.animatedTiles.forEach(animatedTile => {
            const nextFrameIndex = animatedTile.getNextFrame(this.currentFrame);
            animatedTile.layer.putTileAt(nextFrameIndex, animatedTile.x, animatedTile.y);
        });
    }

    public updateTargetFlash(): void {
        // If player exists and is targeted
        if (this.player && this.player.targeted) {
            this.player.toggleBorderVisibility();
            return; // return early, no need to check further
        }

        // If player is not targeted or does not exist, check remote players
        // Update remote players' frames
        const entries = Array.from(this.remotePlayers.entries()); // Convert Map entries to an array
        for (let i = 0; i < entries.length; i++) {
            const remotePlayer = entries[i][1]; // Directly access the second element of each entry
            if (remotePlayer && remotePlayer.targeted) {
                remotePlayer.toggleBorderVisibility();
                break; // Immediately exits the loop upon finding the targeted player
            }
        }

    }

    public updateSpriteFrame(sprite: PlayerSprite | NPCSprite): void {
        if (!sprite) {
            return; // Return early if sprite doesn't exist
        }

        let frameBase: number;
        switch (sprite.facingDirection) {
            case Direction.DOWN:
                frameBase = 0; // Assuming downward frames are 0 and 1
                break;
            case Direction.UP:
                frameBase = 6; // Assuming rightward frames are 6 and 7
                break;
            case Direction.LEFT:
                frameBase = 2; // Assuming upward frames are 2 and 3
                break;
            case Direction.RIGHT:
                frameBase = 4; // Assuming leftward frames are 4 and 5
                break;
            default:
                frameBase = 0; // Default to downward frames
                break;
        }
        sprite.setFrame(frameBase + this.currentFrame);
    }

    private updateSpritesFrame(): void {
        // Update your player's frame
        if (this.player) {
            this.updateSpriteFrame(this.player);
        }

        // Update remote players' frames
        // Update remote players' frames
        this.remotePlayers.forEach((remotePlayer) => {
            if (remotePlayer) {
                this.updateSpriteFrame(remotePlayer);
            }
        });

        // Assuming 'this.npcs' is now a Map
        this.npcs.forEach((npc) => {
            if (npc) {
                this.updateSpriteFrame(npc); // Update the sprite frame for each npc
            }
        });

    }

    private shutdown(): void {
        console.log('beginning shutdown of all game scene listeners');
        // Remove all socket event handlers
        this.socket.off(ClientSocketEvents.InitialPlayerNPCAndMapData);
        this.socket.off(ClientSocketEvents.NewPlayer);
        this.socket.off(ClientSocketEvents.PlayerMoved);
        this.socket.off(ClientSocketEvents.PlayerDisconnected);
        this.socket.off(ClientSocketEvents.PlayerTurned);
        this.socket.off(ClientSocketEvents.InCombat);

        // Remove keyboard event handlers
        this.input.keyboard!.off('keydown', this.playerInputController.handleKeyDown);
        this.input.keyboard!.off('keyup', this.playerInputController.handleKeyUp);
        console.log('finished shutting down all game scene listeners');

    }
}
