import {ItemType} from '../../../types/items/ItemTypes';

export class ClientInteractionStateRegexHelper {

    // Returns regex for main selection state
    public static mainSelect(): string {
        return 'mainSelect';
    }

    // Returns regex for ability selection from a paginated list
    public static abilityMainSelectPage(): string {
        return 'AbilityMainSelectPage\\d+';
    }

    // Returns regex for viewing detailed info about an ability
    public static abilityDetailPage(): string {
        return 'Ability\\d+DetailPage\\d+';
    }

    // Returns regex for targeting with an ability, indicating target type
    public static abilityTargeting(): string {
        return 'Ability\\d+Targeting\\w+Page\\d+';
    }

    // Returns regex for item selection from the Bag category
    public static itemMainSelectBag(): string {
        return 'ItemMainSelectBag';
    }

    // Returns regex for item selection from the Quest category
    public static itemMainSelectQuest(): string {
        return 'ItemMainSelectQuest';
    }

    // Returns regex for item selection from the Equipment category
    public static itemMainSelectEquipment(): string {
        return 'ItemMainSelectEquipment';
    }

    // Returns regex for viewing details of a specific item within the Bag category
    public static bagItemDetail(): string {
        return 'Item\\d+DetailBag';
    }

    // Returns regex for viewing details of a specific item within the Quest category
    public static questItemDetail(): string {
        return 'Item\\d+DetailQuest';
    }

    // Returns regex for targeting with an item within the Bag category
    public static bagItemTargeting(): string {
        return 'Item\\d+Targeting(Enemy|Ally|Self){1,3}Bag';
    }

    // Returns regex for targeting with an item within the Quest category
    public static questItemTargeting(): string {
        return 'Item\\d+Targeting(Enemy|Ally|Self){1,3}Quest';
    }

    // Returns regex for viewing details of equipment items using enum labels
    public static equipmentItemDetail(): string {
        return `Item(${ItemType.Weapon}|${ItemType.BodyArmor}|${ItemType.OffHand}|${ItemType.Helmet})DetailEquipment`;
    }

    // Returns regex for targeting with equipment items using enum labels
    public static equipmentItemTargeting(): string {
        return `Item(${ItemType.Weapon}|${ItemType.BodyArmor}|${ItemType.OffHand}|${ItemType.Helmet})Targeting(Enemy|Ally|Self){1,3}Equipment`;
    }

    // Returns regex for the 'attack' state
    public static attack(): string {
        return 'attack';
    }

    // Constructs a dynamic regex based on provided options for state validation
    public static getDynamicStateRegex(options: {
        mainSelect?: boolean,
        attack?: boolean,
        abilityMainSelectPage?: boolean,
        abilityDetailPage?: boolean,
        abilityTargeting?: boolean,
        itemMainSelectBag?: boolean,
        itemMainSelectQuest?: boolean,
        itemMainSelectEquipment?: boolean,
        bagItemDetail?: boolean,
        questItemDetail?: boolean,
        bagItemTargeting?: boolean,
        questItemTargeting?: boolean,
        equipmentItemDetail?: boolean,
        equipmentItemTargeting?: boolean
    }): RegExp {
        const parts: string[] = [];
        if (options.mainSelect) {
            parts.push(this.mainSelect());
        }
        if (options.attack) {
            parts.push(this.attack());
        }
        if (options.abilityMainSelectPage) {
            parts.push(this.abilityMainSelectPage());
        }
        if (options.abilityDetailPage) {
            parts.push(this.abilityDetailPage());
        }
        if (options.abilityTargeting) {
            parts.push(this.abilityTargeting());
        }
        if (options.itemMainSelectBag) {
            parts.push(this.itemMainSelectBag());
        }
        if (options.itemMainSelectQuest) {
            parts.push(this.itemMainSelectQuest());
        }
        if (options.itemMainSelectEquipment) {
            parts.push(this.itemMainSelectEquipment());
        }
        if (options.bagItemDetail) {
            parts.push(this.bagItemDetail());
        }
        if (options.questItemDetail) {
            parts.push(this.questItemDetail());
        }
        if (options.bagItemTargeting) {
            parts.push(this.bagItemTargeting());
        }
        if (options.questItemTargeting) {
            parts.push(this.questItemTargeting());
        }
        if (options.equipmentItemDetail) {
            parts.push(this.equipmentItemDetail());
        }
        if (options.equipmentItemTargeting) {
            parts.push(this.equipmentItemTargeting());
        }

        return new RegExp('^(' + parts.join('|') + ')$', 'i');
    }
}
