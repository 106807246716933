import Phaser from 'phaser';
import ResizableFrame from '../../classes/UserInterface/UtilityComponents/ResizableFrame';
import {mainGameFont} from '../../GameConfig';
import {DepthLevel} from '../../types/DepthLevel';
import {SceneNames} from '../../types/SceneNames';

export default class DisconnectionScene extends Phaser.Scene {

    public constructor() {
        super(SceneNames.Disconnection);
    }

    // data parameter will contain the reason string sent by the server or any other disconnection reason
    public create(data: { reason: string }): void {
    // Semi-transparent black tint covering the entire screen
        this.add.rectangle(
            0, // x
            0, // y
            this.scale.width, // width
            this.scale.height, // height
            0x000000 // color
        )
            .setOrigin(0, 0)
            .setAlpha(0.5);

        // Define frame dimensions
        const frameWidth = this.scale.width / 3;
        const frameHeight = this.scale.height / 6;
        const centerX = this.scale.width / 2;
        const centerY = this.scale.height / 2;

        // Add ResizableFrame
        const resizableFrame = new ResizableFrame(
            this, // scene
            centerX, // x
            centerY, // y
            frameWidth, // width
            frameHeight // height
        );
        resizableFrame.showFrame();

        // Disconnection message
        this.add.text(
            centerX, // x
            centerY, // y
            data.reason,
            {
                fontSize: '50px',
                color: '#fff',
                fontFamily: mainGameFont,
                align: 'center',
                wordWrap: {
                    width: frameWidth,
                    useAdvancedWrap: true
                }
            }
        )
            .setOrigin(0.5, 0.5)
            .setDepth(DepthLevel.UI_PRIMARY_TEXT);
        this.scene.bringToTop(SceneNames.Disconnection); // Bring this scene to the top of the stack
    }
}
