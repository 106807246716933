// AssetKeys.ts

// ----- IMAGES -----

export enum Images {
    // Environment Images
    ShopBackground = 'shopbackground',
    CaveTiles = 'cavetiles',
    Tiles = 'tiles',
    OverworldTiles = 'overworldtiles',
    SallowfenTiles = 'sallowfentiles',
    ShopInterior = 'shopinterior',
    Shop2Interior = 'shop2interior',

    // Character Images
    DeadlyFlower = 'deadlyflower',
    SeedSpiker = 'seedspiker',
    SentientRock = 'sentientrock',
    ShadowKnight = 'shadowknight',
    Slime = 'slime',
    AngryMushroom = 'angrymushroom',
    Beholder = 'beholder',
    BlueDevil = 'bluedevil',
    GelatinousCube = 'gelatinouscube',
    GoblinCultist = 'goblincultist',
    GoblinKing = 'goblinking',
    GreenDevil = 'greendevil',
    WillOWisp = 'willowisp',

    // Backgrounds
    OverworldBackground = 'overworldbackground',
    SallowfenBackground = 'sallowfenbackground',
    SallowfenInnLevelOne = 'sallowfeninnlevelone',
    SallowfenInnLevelTwo = 'sallowfeninnleveltwo',
    InnBackground = 'innbackground',
    CaveBackground = 'cavebackground',

    TitleScreenBackground = 'titlescreenbackground',

    // Doors
    SimpleDoor1 = 'simpledoor1',

    // Signs
    SimpleSign1 = 'simplesign1',
}

// ----- SPRITESHEETS -----

export enum SpriteSheet {
    // Hero Sprites
    LifeweaverWeaponBottom = 'lifeweaverweaponbottom',
    LifeweaverWeaponTop = 'lifeweaverweapontop',
    AethermancerWeaponBottom = 'aethermancerweaponbottom',
    AethermancerWeaponTop = 'aethermancerweapontop',
    HeroHairFemale = 'herohairfemale',
    RunebladeWeaponBottom = 'runebladeweaponbottom',
    RunebladeWeaponTop = 'runebladeweapontop',
    HeroBaseSprite = 'herobasesprite',
    HeroClothesPrimary = 'heroclothesprimary',
    HeroClothesSecondary = 'heroclothessecondary',
    HeroClothesTertiary = 'heroclothestertiary',
    HeroHairMale = 'herohairmale',
    NPC2 = 'npc2',
    NPC3 = 'npc3',
    NPC4 = 'npc4',
    NPC5 = 'npc5',
}

// ----- TILEMAPS -----

export enum Tilemaps {
    // Game Worlds
    CaveOneRoomOne = 'caveoneroomone',
    CaveOneRoomTwo = 'caveoneroomtwo',
    CaveOneRoomThree = 'caveoneroomthree',
    CaveOneRoomFour = 'caveoneroomfour',
    Sallowfen = 'sallowfen',
    SallowfenInnLevelOne = 'sallowfeninnlevelone',
    SallowfenInnLevelTwo = 'sallowfeninnleveltwo',
    SallowfenShopOne = 'sallowfenshopone',
    SallowfenShopTwo = 'sallowfenshoptwo',
    Overworld = 'overworld',
    Town = 'town',
}

// ----- UI IMAGES -----

export enum UIImageKey {
    // Buttons

    AetherButton = 'aetherbutton',
    AetherButtonActive = 'aetherbuttonactive',
    AetherShieldButton = 'aethershieldbutton',
    AetherShieldButtonActive = 'aethershieldbuttonactive',
    ArmorButton = 'armorbutton',
    ArmorButtonActive = 'armorbuttonactive',
    AttackButton = 'attackbutton',
    AttackButtonActive = 'attackbuttonactive',
    BagButton = 'bagbutton',
    BagButtonActive = 'bagbuttonactive',
    BindButton = 'bindbutton',
    BindButtonActive = 'bindbuttonactive',
    BookButton = 'bookbutton',
    BookButtonActive = 'bookbuttonactive',
    CheckButton = 'checkbutton',
    ChitinousVestButton = 'chitinousvestbutton',
    ChitinousVestButtonActive = 'chitinousvestbuttonactive',
    CoinButton = 'coinbutton',
    CoinButtonActive = 'coinbuttonactive',
    CrossButton = 'crossbutton',
    CypressiumStaffButton = 'cypressiumstaffbutton',
    CypressiumStaffButtonActive = 'cypressiumstaffbuttonactive',
    DiceButton = 'dicebutton',
    EmptyButton = 'emptybutton',
    FadedArmbandButton = 'fadedarmbandbutton',
    FadedArmbandButtonActive = 'fadedarmbandbuttonactive',
    FemaleActiveButton = 'femaleactivebutton',
    FemaleButton = 'femalebutton',
    FleeButton = 'fleebutton',
    FleeButtonActive = 'fleebuttonactive',
    GaleniteBladeButton = 'galenitebladebutton',
    GaleniteBladeButtonActive = 'galenitebladebuttonactive',
    HealButton = 'healbutton',
    HealButtonActive = 'healbuttonactive',
    HealthPotionButton = 'healthpotionbutton',
    HealthPotionButtonActive = 'healthpotionbuttonactive',
    HiTekMonocleButton = 'hitekmonoclebutton',
    HiTekMonocleButtonActive = 'hitekmonoclebuttonactive',
    InviteButton = 'invitebutton',
    LeftArrowButton = 'leftarrowbutton',
    MaleActiveButton = 'maleactivebutton',
    MaleButton = 'malebutton',
    ManaPotionButton = 'manapotionbutton',
    ManaPotionButtonActive = 'manapotionbuttonactive',
    MinusButton = 'minusbutton',
    OaksteelShieldButton = 'oaksteelshieldbutton',
    OaksteelShieldButtonActive = 'oaksteelshieldbuttonactive',
    PageButton = 'pagebutton',
    PageButtonActive = 'pagebuttonactive',
    PencilButton = 'pencilbutton',
    PencilButtonActive = 'pencilbuttonactive',
    PlusButton = 'plusbutton',
    PowerStrikeButton = 'powerstrikebutton',
    PowerStrikeButtonActive = 'powerstrikebuttonactive',
    RightArrowButton = 'rightarrowbutton',
    ScalesButton = 'scalesbutton',
    ScalesButtonActive = 'scalesbuttonactive',
    ScrapCloakButton = 'scrapcloakbutton',
    ScrapCloakButtonActive = 'scrapcloakbuttonactive',
    SellButton = 'sellbutton',
    SellButtonActive = 'sellbuttonactive',
    ShieldButton = 'shieldbutton',
    ShieldButtonActive = 'shieldbuttonactive',
    SmiteButton = 'smitebutton',
    SmiteButtonActive = 'smitebuttonactive',
    SynthjuteTunicButton = 'synthjutetunicbutton',
    SynthjuteTunicButtonActive = 'synthjutetunicbuttonactive',

    // Icons
    CrossIcon = 'crossicon',
    EmptyCircleIcon = 'emptycircleicon',
    FilledCircleIcon = 'filledcircleicon',
    SoulboundIcon = 'soulboundicon',
    BindOnPickupIcon = 'bindonpickupicon',
    BindOnEquipIcon = 'bindonequipicon',

    // Number Badges
    BadgeOne = 'badgeone',
    BadgeTwo = 'badgetwo',
    BadgeThree = 'badgethree',
    BadgeFour = 'badgefour',
    BadgeFive = 'badgefive',
    BadgeSix = 'badgesix',
    BadgeSeven = 'badgeseven',
    BadgeEight = 'badgeeight',
    BadgeNine = 'badgenine',
    BadgeZero = 'badgezero',
    BadgeMinus = 'badgeminus',
    BadgeEquals = 'badgeequals',

    // Miscellaneous UI
    CharacterButton = 'characterButton',
    CharacterButtonActive = 'characterButtonActive',
    ExitButton = 'exitButton',
    ReorderButton = 'reorderButton',
    ReorderUpButton = 'reorderUpButton',
    ReorderDownButton = 'reorderDownButton',
}
