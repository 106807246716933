// src/client/app/classes/ChatManager.ts

import {ClientSocketEvents} from '../../../../types/events/ClientSocketEvents';
import {ServerSocketEvents} from '../../../../types/events/ServerSocketEvents';
import {OperatingSystem} from '../../types/OperatingSystem';
// import {adjustLayout} from '../../utils/adjustLayout';
import {OperatingSystemInfo} from '../System/OperatingSystemInfo';
import SocketManager from './SocketManager';

// Define a StyledTextSegment type
type StyledTextSegment = {
    text: string;
    color: string;
    fontWeight: string;
};

// Then define a StyledMessage as an array of StyledTextSegments
type StyledMessage = StyledTextSegment[];

export default class ChatManager {

    private socket;
    private chatWindow: HTMLDivElement;

    public constructor() {
        this.socket = SocketManager.getInstance().socket;

        this.initializeChatUI();
        this.initializeChatEvents();

        this.initializeMobileKeyboardChatEvents();
        // commenting out programmatic layout adjustment temporarily
        // adjustLayout();

    }

    private static _chatInput: HTMLInputElement;

    public static get chatInput(): HTMLInputElement {
        return this._chatInput;
    }

    private initializeMobileKeyboardChatEvents(): void {
        if (OperatingSystemInfo.getInstance().operatingSystem === OperatingSystem.Mobile) {
            ChatManager.chatInput.addEventListener('focus', () => {
                setTimeout(() => {
                    // Triggering a resize event
                    window.dispatchEvent(new Event('resize'));
                }, 300); // Delay to ensure keyboard is fully open
            });

            ChatManager.chatInput.addEventListener('blur', () => {
                setTimeout(() => {
                    // Triggering a resize event
                    window.dispatchEvent(new Event('resize'));
                }, 300); // Delay to cater for keyboard closing animation
            });
        }
    }

    private initializeChatUI(): void {
        // Get the chat elements
        const chatWrapper = document.querySelector('#chatWrapper') as HTMLDivElement;
        this.chatWindow = document.querySelector('#chatWindow') as HTMLDivElement;
        ChatManager._chatInput = document.querySelector('#chatInput') as HTMLInputElement;
        const chatContainer = document.querySelector('#chatContainer') as HTMLDivElement;
        const chatWindowWrapper = document.querySelector('#chatWindowWrapper') as HTMLDivElement;

        // Make the chat elements visible
        if (chatWrapper && this.chatWindow && ChatManager._chatInput && chatContainer && chatWindowWrapper) {
            chatWindowWrapper.classList.remove('hidden', 'invisible');
            chatContainer.classList.remove('hidden', 'invisible');
            chatWrapper.classList.remove('hidden', 'invisible');
            this.chatWindow.classList.remove('hidden', 'invisible');
            ChatManager._chatInput.style.visibility = '';
            ChatManager._chatInput.classList.remove('hidden', 'invisible');

            ChatManager._chatInput.addEventListener('keypress', (event) => {
                if (event.key === 'Enter') {
                    this.sendMessage();
                }
            });

            // Unfocus chat input when clicking on the canvas
            const canvas = document.querySelector('canvas');
            if (canvas) {
                canvas.addEventListener('pointerdown', () => {
                    if (document.activeElement === ChatManager._chatInput) {
                        ChatManager._chatInput.blur();
                    }
                });
            }
        }
    }

    private initializeChatEvents(): void {
        this.socket.on(ClientSocketEvents.ChatMessage, ({id, name, message}) => {
            this.displayMessage(id, name, message);
        });

        // Adding system message listener
        this.socket.on(ClientSocketEvents.SystemMessage, (message) => {
            this.displaySystemMessage(message);
        });

        this.socket.on(ClientSocketEvents.StyledMessage, (styledMessage: StyledMessage) => {
            this.displayStyledMessage(styledMessage);
        });

        this.socket.on(ClientSocketEvents.ClearChat, () => {
            this.chatWindow.innerHTML = '';
        });
    }

    private sendMessage(): void {
        const message = ChatManager._chatInput.value;
        if (message.trim() !== '') {
            this.socket.emit(ServerSocketEvents.ChatMessage, message);
            ChatManager._chatInput.value = '';
        }
    }

    private displayMessage(id: string, name: string, message: string): void {
        console.log(`Received message: ID=${id}, Name=${name}, Message=${message}`); // Log received message details

        const messageElement = document.createElement('p');
        messageElement.innerText = `${name}: ${message}`;

        console.log('Creating and appending message element...'); // Log before appending
        this.appendMessageAndHandleScroll(messageElement);
    }

    private displaySystemMessage(message: string): void {
        const messageElement = document.createElement('p');
        messageElement.innerText = `System: ${message}`;
        messageElement.style.color = 'red';
        messageElement.style.fontWeight = 'bold';
        this.appendMessageAndHandleScroll(messageElement);
    }

    private displayStyledMessage(styledMessage: StyledMessage): void {
        const messageElement = document.createElement('p');
        styledMessage.forEach(segment => {
            const span = document.createElement('span');
            span.style.color = segment.color;
            span.style.fontWeight = segment.fontWeight;
            // Apply white-space property to preserve formatting
            span.style.whiteSpace = 'pre-wrap';
            // Use .textContent instead of .innerHTML for better security and to preserve text formatting
            span.textContent = segment.text;
            messageElement.appendChild(span);
        });
        this.appendMessageAndHandleScroll(messageElement);
    }

    private appendMessageAndHandleScroll(messageElement: HTMLParagraphElement): void {
        const isScrolledToBottom = this.chatWindow.scrollHeight - this.chatWindow.clientHeight <= this.chatWindow.scrollTop + 1;

        this.chatWindow.appendChild(messageElement);

        // Log a verification message to confirm that the element has been appended and should be visible
        if (this.chatWindow.contains(messageElement)) {
            console.log('Message element successfully appended and should be visible in the browser.');
        } else {
            console.log('Failed to append the message element.');
        }

        if (isScrolledToBottom) {
            this.chatWindow.scrollTop = this.chatWindow.scrollHeight - this.chatWindow.clientHeight;
        }
    }

}
