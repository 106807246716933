import Phaser from 'phaser';
import {Socket} from 'socket.io-client';
import isEmail from 'validator/lib/isEmail';
import {Images} from '../../../../types/assets/AssetKeys';
import {ClientSocketEvents} from '../../../../types/events/ClientSocketEvents';
import {RegisterResponse} from '../../../../types/events/RegisterResponseEvent';
import {ServerSocketEvents} from '../../../../types/events/ServerSocketEvents';
import SocketManager from '../../classes/NetworkingAndChat/SocketManager';
import {mainGameFont} from '../../GameConfig';
import {SceneNames} from '../../types/SceneNames';
import DOMElement = Phaser.GameObjects.DOMElement;

export default class RegisterScene extends Phaser.Scene {
    private backgroundRectangle: Phaser.GameObjects.Rectangle;
    private socket: Socket;
    private registerForm: Phaser.GameObjects.DOMElement;
    private successMessage: HTMLParagraphElement;
    private errorMessage: HTMLParagraphElement;
    private titleText: Phaser.GameObjects.Text;
    private titleImage: Phaser.GameObjects.Image;

    public constructor() {
        super(SceneNames.Register);
        this.socket = SocketManager.getInstance().socket;
    }

    private setupSocketListeners(): void {
        this.socket.on(ClientSocketEvents.RegisterResponse, (response: RegisterResponse) => {
            if (response.success) {
                this.successMessage.textContent = response.message || '';
                this.errorMessage.textContent = '';
            } else {
                this.errorMessage.textContent = response.error || '';
                this.successMessage.textContent = '';
            }

            console.log('Register response:', response);
        });

        this.socket.on(ClientSocketEvents.ServerShutdown, (response: { reason: string }) => {
            console.log('Server shutdown:', response);
            this.errorMessage.textContent = response.reason;
            this.scene.pause();

            // Disconnect the socket
            this.socket.disconnect();

            // Disable the registration form
            this.disableRegisterForm();
        });
    }

    private disableRegisterForm(): void {
        const formElement = this.registerForm.node as HTMLFormElement;
        const inputs = formElement.querySelectorAll('input');
        const buttons = formElement.querySelectorAll('button');
        const links = formElement.querySelectorAll('a');
        const spans = formElement.querySelectorAll('span');

        spans.forEach(span => {
            span.style.color = '#999'; // Change text color to indicate disabled state
            span.style.pointerEvents = 'none'; // Disable pointer events
            span.style.cursor = 'not-allowed'; // Change cursor to indicate not clickable
        });
        inputs.forEach(input => input.disabled = true);
        buttons.forEach(button => {
            button.setAttribute('disabled', 'true'); // Disable the button
            button.style.pointerEvents = 'none'; // Disable pointer events
            button.style.backgroundColor = '#999'; // Change background color to indicate disabled state
            button.style.color = '#666'; // Change text color to indicate disabled state
            button.style.cursor = 'not-allowed'; // Change cursor to indicate not clickable
        });
        links.forEach(link => {
            link.setAttribute('disabled', 'disabled'); // Add disabled attribute
            link.style.pointerEvents = 'none'; // Disable click events
            link.style.color = '#999'; // Change text color to indicate disabled state
            link.style.textDecoration = 'none'; // Remove underline
            link.style.cursor = 'not-allowed'; // Change cursor to indicate not clickable
        });

        // Remove pointerdown event listener
        this.registerForm.removeListener('pointerdown');
    }

    public preload(): void {
        this.load.html('registerform', 'assets/html/registerform.html');
    }

    public create(): void {
        this.backgroundRectangle = this.add.rectangle(
            0,
            0,
            this.scale.width,
            this.scale.height,
            0xbcbcbc
        ).setOrigin(0, 0);

        this.titleImage = this.add.image(this.scale.width / 2, this.scale.height / 2, Images.TitleScreenBackground);
        this.titleImage.displayHeight = this.scale.height;
        this.titleImage.displayWidth = this.scale.width;

        this.titleText = this.add.text(this.scale.width / 2, 100, 'Afterlife Online', {
            fontSize: '128px',
            color: '#fff',
            fontFamily: mainGameFont
        })
            .setStroke('#000000', 6)
            .setOrigin(0.5);

        this.registerForm = this.add.dom(this.scale.width / 2, this.scale.height / 2).createFromCache('registerform') as DOMElement;
        this.successMessage = this.registerForm.getChildByID('successMessage') as HTMLParagraphElement;
        this.errorMessage = this.registerForm.getChildByID('errorMessage') as HTMLParagraphElement;

        // Set the origin to be in the horizontal center
        this.registerForm.setOrigin(0.5, 0.5);

        const formElement = this.registerForm.node as HTMLFormElement;
        formElement.onsubmit = (event: Event): void => {
            event.preventDefault();
            const username = (this.registerForm.getChildByID('usernameField') as HTMLInputElement).value;
            const email = (this.registerForm.getChildByID('emailField') as HTMLInputElement).value;
            const password = (this.registerForm.getChildByID('passwordField') as HTMLInputElement).value;
            const confirmPassword = (this.registerForm.getChildByID('confirmPasswordField') as HTMLInputElement).value;
            this.register(username, email, password, confirmPassword, this.registerForm);
        };

        this.registerForm.addListener('pointerdown');
        this.registerForm.on('pointerdown', this.handlePointerDown);

        this.scene.bringToTop(SceneNames.Register);
        this.setupSocketListeners();
    }

    private handlePointerDown = (event: Event): void => {
        const target = event.target as HTMLElement;
        if (target.id === 'loginLink') {
            this.tearDownListeners();
            this.scene.start(SceneNames.Login);
        }
    };
    private register(username: string, email: string, password: string, confirmPassword: string, form: DOMElement): void {
        const errorMessage = form.getChildByID('errorMessage') as HTMLParagraphElement;

        if (!isEmail(email)) {
            errorMessage.textContent = 'Invalid email address.';
            return;
        }
        if (username.length < 3 || username.length > 20) {
            errorMessage.textContent = 'Username must be between 3 and 20 characters.';
            return;
        }
        const allowedCharacters = /^[a-zA-Z0-9._-]+$/;
        if (!allowedCharacters.test(username)) {
            errorMessage.textContent = 'Username can only contain letters, numbers, dots (.), underscores (_), and hyphens (-).';
            return;
        }
        if (password.length < 8 || password.length > 64) {
            errorMessage.textContent = 'Password must be between 8 and 64 characters.';
            return;
        }
        if (password !== confirmPassword) {
            errorMessage.textContent = 'Passwords do not match.';
            return;
        }

        console.log('Registering:', username, email, password);

        // Emit registration event to the server
        this.socket.emit(ServerSocketEvents.Register, {username, email, password});
    }

    public tearDownListeners(): void {
        this.socket.off(ClientSocketEvents.RegisterResponse);
        this.socket.off(ClientSocketEvents.ServerShutdown);
    }
}
