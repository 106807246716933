// src/client/app/classes/SocketEventHandler.ts
import {Vector2} from 'arcade-physics/lib/math/Vector2';
import {Socket} from 'socket.io-client';
import {AttemptArmFieldAbilityResponseEvent} from '../../../../types/abilities/AttemptArmFieldAbilityResponseEvent';
import {
    AttemptFieldAbilitySelectionResponseEvent
} from '../../../../types/abilities/AttemptFieldAbilitySelectionResponseEvent';
import {SpriteSheet, UIImageKey} from '../../../../types/assets/AssetKeys';
import {
    ClientConflictInitState,
    ClientFullPlayerData,
    ClientMonsterData,
    ClientTeamOfFullPlayers,
    ClientTeamOfMonsters,
    ClientTeamOfTruncatedPlayers,
    ClientTruncatedPlayerData,
} from '../../../../types/conflict/ClientConflictInitState';
import {ClientOngoingConflictState} from '../../../../types/conflict/ClientOngoingConflictState';
import {DialogueNode} from '../../../../types/dialogue/DialogueTypes';
import {
    AttemptConfirmNPCMerchantInventoryItemPurchaseResponse,
    PurchaseAttemptResponseType
} from '../../../../types/events/AttemptConfirmNPCMerchantInventoryItemPurchaseResponse';
import {
    AttemptConfirmNPCMerchantInventoryItemSaleResponse
} from '../../../../types/events/AttemptConfirmNPCMerchantInventoryItemSaleResponse';
import {
    AttemptInteractResponseEvent,
    InteractableInteractionResponse,
    NPCInteractionResponse,
    TargetPlayerInteractionResponse
} from '../../../../types/events/AttemptInteractResponseEvent';
import {
    AttemptNPCMerchantInventoryItemSelectionForPurchaseResponseEvent
} from '../../../../types/events/AttemptNPCMerchantInventoryItemSelectionForPurchaseResponseEvent';
import {
    AttemptPlayerInventoryItemSelectionForSaleResponseEvent
} from '../../../../types/events/AttemptPlayerInventoryItemSelectionForSaleResponseEvent';
import {
    AttemptSwitchMerchantMenuToBuyModeResponseEvent
} from '../../../../types/events/AttemptSwitchMerchantMenuToBuyModeResponseEvent';
import {
    AttemptSwitchMerchantMenuToSellModeResponseEvent
} from '../../../../types/events/AttemptSwitchMerchantMenuToSellModeResponseEvent';
import {AttemptToggleAbilityMenuResponseEvent} from '../../../../types/events/AttemptToggleAbilityMenuResponseEvent';
import {
    AttemptToggleCharacterSheetResponseEvent
} from '../../../../types/events/AttemptToggleCharacterSheetResponseEvent';
import {
    AttemptToggleInventoryMenuResponseEvent
} from '../../../../types/events/AttemptToggleInventoryMenuResponseEvent';
import {
    AttemptTogglePartyOrderMenuResponseEvent
} from '../../../../types/events/AttemptTogglePartyOrderMenuResponseEvent';
import {ClientSideEnterNewMapData} from '../../../../types/events/ClientSideEnterNewMapEvent';
import {ClientSocketEvents} from '../../../../types/events/ClientSocketEvents';
import {DoorStateChangedData} from '../../../../types/events/DoorStateChangedData';
import {FieldMainTurnActionEventData} from '../../../../types/events/FieldMainTurnActionEventData';
import {InitialPlayerNPCAndMapDataEvent} from '../../../../types/events/InitialPlayerNPCAndMapDataEvent';
import {OpenMerchantNPCInventoryEvent} from '../../../../types/events/OpenMerchantNPCInventoryEvent';
import {OpenTradeMenuEvent} from '../../../../types/events/OpenTradeMenuEvent';
import {ServerSocketEvents} from '../../../../types/events/ServerSocketEvents';
import {ClientDoorData} from '../../../../types/interactables/ClientDoorData';
import {ClientSignData} from '../../../../types/interactables/ClientSignData';
import {
    AttemptArmOrEquipFieldInventoryItemResponseEvent
} from '../../../../types/inventory/AttemptArmOrEquipFieldInventoryItemResponseEvent';
import {
    AttemptFieldInventoryItemSelectionResponseEvent
} from '../../../../types/inventory/AttemptFieldInventoryItemSelectionResponseEvent';
import {
    AttemptSelectFieldEquipmentItemResponseEvent
} from '../../../../types/inventory/AttemptSelectFieldEquipmentItemResponseEvent';
import {
    AttemptSwitchFieldInventoryModeResponse
} from '../../../../types/inventory/AttemptSwitchFieldInventoryModeResponseEvent';
import {
    AttemptUnequipFieldEquipmentItemResponseEvent
} from '../../../../types/inventory/AttemptUnequipFieldEquipmentItemResponseEvent';
import {ItemType} from '../../../../types/items/ItemTypes';
import {ClientNPCInfo} from '../../../../types/npcInfo/ClientNPCInfo';
import {NPCVariants} from '../../../../types/npcInfo/INPCBase';
import {NPCInteractionPayload} from '../../../../types/npcInfo/NPCInteractionPayload';
import {Direction} from '../../../../types/physics/Direction';
import {PlayerMovementData} from '../../../../types/physics/PlayerMovementData';
import {ClientPlayerInfo} from '../../../../types/playerInfo/ClientPlayerInfo';
import {ClientPlayerRespawnInfo} from '../../../../types/playerInfo/ClientPlayerRespawnInfo';
import {MapEntryPlayerInfoArray} from '../../../../types/playerInfo/MapEntryPlayerInfoArray';
import {NewClientPlayerInfo} from '../../../../types/playerInfo/NewClientPlayerInfo';
import {PlayerDataArray} from '../../../../types/playerInfo/PlayerDataArray';
import {TargetPlayerResponse} from '../../../../types/playerInfo/TargetPlayerData';
import {AttemptAcceptTradeOfferResponseEvent} from '../../../../types/trade/AttemptAcceptTradeOfferResponseEvent';
import {AttemptOfferGoldForTradeResponseEvent} from '../../../../types/trade/AttemptOfferGoldForTradeResponseEvent';
import {
    AttemptOfferSelectedInventoryItemForTradeResponseEvent
} from '../../../../types/trade/AttemptOfferSelectedInventoryItemForTradeResponseEvent';
import {AttemptRescindOfferedItemResponseEvent} from '../../../../types/trade/AttemptRescindOfferedItemResponseEvent';
import {
    AttemptSelectClientInventoryItemForTradeResponseEvent
} from '../../../../types/trade/AttemptSelectClientInventoryItemForTradeResponseEvent';
import {
    AttemptSelectRemoteInventoryItemForTradeResponseEvent
} from '../../../../types/trade/AttemptSelectRemoteInventoryItemForTradeResponseEvent';
import {
    UpdateRemoteTradePartnerOfferedItemsEvent
} from '../../../../types/trade/UpdateRemoteTradePartnerOfferedItemsEvent';
import {mapInfo, MapTilesetInfo} from '../../data/mapInfo';
import {tileSize} from '../../GameConfig';
import ServerControlledGameScene from '../../scenes/ServerControlledGameplayScenes/ServerControlledGameScene';
import {ConflictSceneData} from '../../types/ConflictSceneData';
import {DepthLevel} from '../../types/DepthLevel';
import {InventoryItemDetails} from '../../types/InventoryItemDetails';
import {PlayerSpriteParameters} from '../../types/PlayerSpriteParameters';
import {SceneNames} from '../../types/SceneNames';
import {defaultDepths, layerNameToDepth} from '../../utils/depthMappings';
import {hideGamePadSceneIfNeeded} from '../../utils/hideGamePadSceneIfNeeded';
import {showGamePadSceneIfNeeded} from '../../utils/showGamePadSceneIfNeeded';
import NPCSprite from '../CharactersAndPlayers/NPCSprite';
import PlayerSprite from '../CharactersAndPlayers/PlayerSprite';
import {AnimatedTile} from '../TilesAndEnvironment/AnimatedTile';
import DoorImage from '../TilesAndEnvironment/DoorImage';
import SignImage from '../TilesAndEnvironment/SignImage';

export default class GameSocketEventHandler {
    private gameScene: ServerControlledGameScene;
    private socket: Socket;
    private disconnectionSceneRunning: boolean = false; // Add this flag

    public constructor(scene: ServerControlledGameScene, socket: Socket) {
        this.gameScene = scene;
        this.socket = socket;

        // ------------------------------
        // Section: Combat Events
        // ------------------------------
        this.socket.on(ClientSocketEvents.ConflictInitiated, this.handleConflictInitiated.bind(this));
        this.socket.on(ClientSocketEvents.OngoingConflictReinsertion, this.handleOngoingConflictReinsertion.bind(this));
        this.socket.on(ClientSocketEvents.ConflictInitiatedBroadcast, this.handleConflictInitiatedBroadcast.bind(this));
        this.socket.on(ClientSocketEvents.ConflictWinners, this.handleConflictWinners.bind(this));
        this.socket.on(ClientSocketEvents.ConflictLosers, this.handleConflictLosers.bind(this));
        this.socket.on(ClientSocketEvents.InCombat, this.handlePlayerInCombat.bind(this));

        // ------------------------------
        // Section: Player Events
        // ------------------------------
        this.socket.on(ClientSocketEvents.PlayerTurned, this.handlePlayerTurned.bind(this));
        this.socket.on(ClientSocketEvents.PlayerDisconnected, this.handlePlayerDisconnected.bind(this));
        this.socket.on(ClientSocketEvents.PlayerMoved, this.handlePlayerMoved.bind(this));
        this.socket.on(ClientSocketEvents.NewPlayer, this.handleNewPlayer.bind(this));
        this.socket.on(ClientSocketEvents.TargetPlayer, this.handleTargetPlayer.bind(this));
        this.socket.on(ClientSocketEvents.ClearTarget, this.handleClearTarget.bind(this));
        this.socket.once(ClientSocketEvents.InitialPlayerNPCAndMapData, this.initialPlayerNPCAndMapDataEventHandler.bind(this));
        this.socket.on(ClientSocketEvents.ChatBubble, this.handleChatBubble.bind(this));
        this.socket.on(ClientSocketEvents.AttemptToggleCharacterSheetResponse, this.handleToggleCharacterSheetResponse.bind(this));
        this.socket.on(ClientSocketEvents.AttemptToggleAbilityMenuResponse, this.handleToggleAbilityMenuResponse.bind(this));
        this.socket.on(ClientSocketEvents.AttemptSelectFieldAbilityResponse, this.handleAttemptSelectFieldAbilityResponse.bind(this));
        this.socket.on(ClientSocketEvents.AttemptArmFieldAbilityResponse, this.handleAttemptArmFieldAbilityResponse.bind(this));
        this.socket.on(ClientSocketEvents.AttemptUseFieldAbilityOnTargetResponse, this.handleAttemptUseFieldAbilityOnTargetResponse.bind(this));
        this.socket.on(ClientSocketEvents.AttemptSelectFieldInventoryItemResponse, this.handleAttemptSelectFieldInventoryItemResponse.bind(this));
        this.socket.on(ServerSocketEvents.AttemptSelectFieldEquipmentItemResponse, this.handleAttemptSelectFieldEquipmentItemResponse.bind(this));
        this.socket.on(ClientSocketEvents.AttemptArmOrEquipFieldInventoryItemResponse, this.handleAttemptArmOrEquipFieldInventoryItemResponse.bind(this));
        this.socket.on(ClientSocketEvents.AttemptUnequipFieldEquipmentItemResponse, this.handleAttemptUnequipFieldEquipmentItemResponse.bind(this));
        this.socket.on(ClientSocketEvents.AttemptDropFieldInventoryItemResponse, this.handleAttemptDropFieldInventoryItemResponse.bind(this));
        this.socket.on(ClientSocketEvents.AttemptUseFieldInventoryItemOnTargetResponse, this.handleAttemptUseFieldInventoryItemOnTargetResponse.bind(this));
        this.socket.on(ClientSocketEvents.FieldPreTurnAction, this.handleFieldPreTurnAction.bind(this));
        this.socket.on(ClientSocketEvents.FieldMainTurnAction, this.handleFieldMainTurnAction.bind(this));
        this.socket.on(ClientSocketEvents.FieldCriticalEffect, this.handleFieldCriticalEffect.bind(this));
        this.socket.on(ClientSocketEvents.FieldPostTurnAction, this.handleFieldPostTurnAction.bind(this));
        this.socket.on(ClientSocketEvents.AttemptToggleInventoryMenuResponse, this.handleToggleInventoryMenuResponse.bind(this));
        this.socket.on(ClientSocketEvents.AttemptSwitchFieldInventoryModeResponse, this.handleSwitchFieldInventoryModeResponse.bind(this));

        // ------------------------------
        // Section: NPC Events
        // ------------------------------
        this.socket.on(ClientSocketEvents.InteractWithNPC, this.interactWithNPCHandler.bind(this));
        this.socket.on(ClientSocketEvents.NPCFacingDirectionChanged, this.npcFacingDirectionChangedHandler.bind(this));

        // ------------------------------
        // Section: Dialogue Events
        // ------------------------------
        this.socket.on(ClientSocketEvents.TogglePlayerVisibility, this.togglePlayerVisibilityHandler.bind(this));
        this.socket.on(ClientSocketEvents.NPCDialogueOptionResponse, this.npcDialogueOptionResponseHandler.bind(this));
        this.socket.on(ClientSocketEvents.TerminateDialogue, this.terminateDialogueHandler.bind(this));
        this.socket.on(ClientSocketEvents.ScreenState, this.screenStateHandler.bind(this));
        this.socket.on(ClientSocketEvents.PlayersLeavingMapViaTeleport, this.handlePlayersLeavingMapViaTeleport.bind(this));
        this.socket.on(ClientSocketEvents.PlayersJoiningMapViaTeleport, this.handlePlayersJoiningMapViaTeleport.bind(this));
        this.socket.on(ClientSocketEvents.PlayersTeleportingWithinSameMap, this.handleTeleportationWithinMap.bind(this));
        this.socket.on(ClientSocketEvents.TeleportingPlayersNewMapData, this.handleTeleportingPlayersNewMapData.bind(this));
        this.socket.on(ClientSocketEvents.UpdateHP, this.handleUpdatePartyMembers.bind(this));
        this.socket.on(ClientSocketEvents.UpdateResource, this.handleUpdatePartyMembers.bind(this));
        this.socket.on(ClientSocketEvents.UpdateHPAndResource, this.handleUpdatePartyMembers.bind(this));
        this.socket.on(ClientSocketEvents.OpenMerchantNPCInventory, this.openMerchantNPCInventoryHandler.bind(this));
        this.socket.on(ClientSocketEvents.AttemptSelectNPCMerchantInventoryItemForPurchaseResponse, this.handleAttemptSelectNPCMerchantInventoryItemForPurchaseResponse.bind(this));
        this.socket.on(ClientSocketEvents.AttemptSelectPlayerInventoryItemForSaleResponse, this.handleAttemptSelectNPCMerchantInventoryItemForSaleResponse.bind(this));
        this.socket.on(ClientSocketEvents.AttemptPurchaseNPCMerchantInventoryItemResponse, this.handleAttemptPurchaseNPCMerchantInventoryItemResponse.bind(this));
        this.socket.on(ClientSocketEvents.AttemptSellNPCMerchantInventoryItemResponse, this.handleAttemptSellNPCMerchantInventoryItemResponse.bind(this));
        this.socket.on(ClientSocketEvents.AttemptSwitchMerchantMenuToSellModeResponse, this.handleAttemptSwitchMerchantMenuToSellModeResponse.bind(this));
        this.socket.on(ClientSocketEvents.AttemptSwitchMerchantMenuToBuyModeResponse, this.handleAttemptSwitchMerchantMenuToBuyModeResponse.bind(this));
        this.socket.on(ClientSocketEvents.CloseDialogue, this.handleCloseDialogue.bind(this));

        // ------------------------------
        // Section: Interaction Events
        // ------------------------------
        this.socket.on(ClientSocketEvents.AttemptInteractResponse, this.attemptInteractResponseHandler.bind(this));
        this.socket.on(ClientSocketEvents.UpdateInteractionMenu, this.updateInteractionMenuHandler.bind(this));

        // ------------------------------
        // Section: Duel Events
        // ------------------------------
        this.socket.on(ClientSocketEvents.DuelResponse, this.handleDuelResponse.bind(this));
        this.socket.on(ClientSocketEvents.DuelChallengeReceived, this.handleDuelChallengeReceived.bind(this));
        this.socket.on(ClientSocketEvents.DuelDeclined, this.handleDuelDeclined.bind(this));
        this.socket.on(ClientSocketEvents.DuelCancelled, this.handleDuelCancelled.bind(this));

        // ------------------------------
        // Section: Trade Events
        // ------------------------------
        this.socket.on(ClientSocketEvents.OpenTradeMenu, this.handleTradeResponse.bind(this));
        this.socket.on(ClientSocketEvents.AttemptSelectClientInventoryItemForTradeResponse, this.handleAttemptSelectClientInventoryItemForTradeResponse.bind(this));
        this.socket.on(ClientSocketEvents.AttemptSelectRemoteInventoryItemForTradeResponse, this.handleAttemptSelectRemoteInventoryItemForTradeResponse.bind(this));
        this.socket.on(ClientSocketEvents.AttemptOfferSelectedInventoryItemForTradeResponse, this.handleAttemptOfferSelectedInventoryItemForTradeResponse.bind(this));
        this.socket.on(ClientSocketEvents.AttemptRescindOfferedItemResponse, this.handleAttemptRescindOfferedItemResponse.bind(this));
        this.socket.on(ClientSocketEvents.UpdateRemoteTradePartnerOfferedItems, this.handleUpdateRemoteTradePartnerOfferedItems.bind(this));
        this.socket.on(ClientSocketEvents.AttemptOfferGoldForTradeResponse, this.handleAttemptOfferGoldForTradeResponse.bind(this));
        this.socket.on(ClientSocketEvents.UpdateRemoteTradePartnerGoldOffered, this.handleUpdateRemoteTradePartnerGoldOffered.bind(this));
        this.socket.on(ClientSocketEvents.AttemptAcceptTradeOfferResponse, this.handleAttemptAcceptTradeOfferResponse.bind(this));
        this.socket.on(ClientSocketEvents.UpdateRemoteTradePartnerTradeAccepted, this.handleUpdateRemoteTradePartnerTradeAccepted.bind(this));
        this.socket.on(ClientSocketEvents.TradeComplete, this.handleTradeComplete.bind(this));
        this.socket.on(ClientSocketEvents.AttemptRevertTradeOfferToPendingResponse, this.handleAttemptRevertTradeOfferToPendingResponse.bind(this));
        this.socket.on(ClientSocketEvents.UpdateRemoteTradeStatusToPending, this.handleUpdateRemoteTradeStatusToPending.bind(this));
        this.socket.on(ClientSocketEvents.AttemptCancelTradeOfferResponse, this.handleAttemptCancelTradeOfferResponse.bind(this));
        this.socket.on(ClientSocketEvents.CancelRemoteTradeOffer, this.handleCancelRemoteTradeOffer.bind(this));
        this.socket.on(ClientSocketEvents.TradeFailure, this.handleTradeFailure.bind(this));

        // ------------------------------
        // Section: Invitation Events
        // ------------------------------
        this.socket.on(ClientSocketEvents.InviteResponse, this.handleInviteResponse.bind(this));
        this.socket.on(ClientSocketEvents.InviteReceived, this.handleInviteReceived.bind(this));
        this.socket.on(ClientSocketEvents.InviteCancelled, this.handleInviteCancelled.bind(this));
        this.socket.on(ClientSocketEvents.InvitationDeclined, this.handleInvitationDeclined.bind(this));

        // ------------------------------
        // Section: Party Events
        // ------------------------------
        this.socket.on(ClientSocketEvents.PartyMoved, this.handlePartyMoved.bind(this));
        this.socket.on(ClientSocketEvents.PartyJoinSuccess, this.handlePartyJoinSuccess.bind(this));
        this.socket.on(ClientSocketEvents.PartyLeaveSuccess, this.handlePartyLeaveSuccess.bind(this));
        this.socket.on(ClientSocketEvents.PartyPromotion, this.handlePartyPromotion.bind(this));
        this.socket.on(ClientSocketEvents.UpdatePartyMembers, this.handleUpdatePartyMembers.bind(this));
        this.socket.on(ClientSocketEvents.AttemptTogglePartyOrderMenuResponse, this.handleAttemptTogglePartyOrderMenuResponse.bind(this));
        this.socket.on(ClientSocketEvents.PartyReorderSubmitSuccess, this.handlePartyReorderSubmitSuccess.bind(this));
        this.socket.on(ClientSocketEvents.HidePartyOrderMenu, this.handleHidePartyOrderMenu.bind(this));

        // ------------------------------
        // Section: Map and Level Events
        // ------------------------------
        this.socket.on(ClientSocketEvents.PlayersLeavingMap, this.playersLeavingMapHandler.bind(this));
        this.socket.on(ClientSocketEvents.EnterNewMap, this.enterNewMapHandler.bind(this));
        this.socket.on(ClientSocketEvents.PlayersEnteredLevel, this.playersEnteredLevelHandler.bind(this));

        // ------------------------------
        // Section: Connection Events
        // ------------------------------
        this.socket.on(ClientSocketEvents.ConnectError, this.handleConnectionError.bind(this));
        this.socket.on(ClientSocketEvents.ReconnectFailed, this.handleReconnectFailed.bind(this));
        this.socket.on(ClientSocketEvents.Disconnect, this.handleDisconnect.bind(this));

        // ------------------------------
        // Section: Server Events
        // ------------------------------
        this.socket.on(ClientSocketEvents.ServerShutdown, this.handleServerShutdown.bind(this));

        // ------------------------------
        // Section: Door Events
        // ------------------------------
        this.socket.on(ClientSocketEvents.DoorStateChanged, this.handleDoorStateChanged.bind(this));

        // ------------------------------
        // Section: Interactable Events
        this.socket.on(ClientSocketEvents.AttemptDismissInteractableMessageResponse, this.handleAttemptDismissInteractableMessageResponse.bind(this));
    }

    private handleAttemptSwitchMerchantMenuToBuyModeResponse(data: AttemptSwitchMerchantMenuToBuyModeResponseEvent): void {
        console.log(`[handleAttemptSwitchMerchantMenuToBuyModeResponse] Received response: ${JSON.stringify(data, null, 2)}`);
        if (data.success) {
            console.log('[handleAttemptSwitchMerchantMenuToBuyModeResponse] Attempt to switch merchant menu to buy mode succeeded.');
            // Now using the dedicated method for populating the inventory in buy mode.
            this.gameScene.serverControlledGameUIScene.merchantNPCMenu.populateInitialBuyInventoryView(data.merchantInventory, data.playerGold);

            // Assuming there is a method to adjust the UI for buy mode, such as showing the inventory view appropriately.
            // The `showInitialInventoryView` method might already handle this if it's capable of adjusting based on mode.
            // If it's already designed to do so, ensure it's called with the correct mode parameter; otherwise, you may need to adjust.
            this.gameScene.serverControlledGameUIScene.merchantNPCMenu.showInitialInventoryView('buy'); // Directly showing buy mode, might need adjustment if the method signature has changed.
        } else {
            console.error('[handleAttemptSwitchMerchantMenuToBuyModeResponse] Attempt to switch merchant menu to buy mode failed.');
            // Handle failure scenario, possibly by showing a failure dialogue or performing related UI updates
        }
        this.gameScene.serverControlledGameUIScene.waitingForServerResponse = false;
    }

    private handleAttemptSwitchMerchantMenuToSellModeResponse(data: AttemptSwitchMerchantMenuToSellModeResponseEvent): void {
        console.log(`[handleAttemptSwitchMerchantMenuToSellModeResponse] Received response: ${JSON.stringify(data, null, 2)}`);
        if (data.success) {
            console.log('[handleAttemptSwitchMerchantMenuToSellModeResponse] Attempt to switch merchant menu to sell mode succeeded.');
            // Now using the dedicated method for populating the inventory in sell mode.
            this.gameScene.serverControlledGameUIScene.merchantNPCMenu.populateInitialSellInventoryView(data.playerInventory, data.playerGold);

            // Assuming there is a method to adjust the UI for sell mode, such as showing the inventory view appropriately.
            // The `showInitialInventoryView` method might already handle this if it's capable of adjusting based on mode.
            // If it's already designed to do so, ensure it's called with the correct mode parameter; otherwise, you may need to adjust.
            this.gameScene.serverControlledGameUIScene.merchantNPCMenu.showInitialInventoryView('sell'); // Directly showing sell mode, might need adjustment if the method signature has changed.
        } else {
            console.error('[handleAttemptSwitchMerchantMenuToSellModeResponse] Attempt to switch merchant menu to sell mode failed.');
            // Handle failure scenario, possibly by showing a failure dialogue or performing related UI updates
        }
        this.gameScene.serverControlledGameUIScene.waitingForServerResponse = false;
    }

    private handleAttemptSellNPCMerchantInventoryItemResponse(data: AttemptConfirmNPCMerchantInventoryItemSaleResponse): void {
        console.log(`[handleAttemptSellNPCMerchantInventoryItemResponse] Received response: ${JSON.stringify(data, null, 2)}`);
        if (data.success) {
            console.log('[handleAttemptSellNPCMerchantInventoryItemResponse] Attempt to sell NPC merchant inventory item succeeded.');
            // Handle success scenario, possibly by showing a success dialogue or performing related UI updates
            // this.scene.serverControlledGameUIScene.merchantNPCMenu...;
            this.gameScene.serverControlledGameUIScene.merchantNPCMenu.hideInitialInventoryView();
            this.gameScene.serverControlledGameUIScene.merchantNPCMenu.hideDetailInventoryView();

            // Reset UI from previous states or interactions
            this.gameScene.serverControlledGameUIScene.commonerNPCMenu.activate({npcName: data.npcName, dialogue: data.dialogue.text, options: data.dialogue.options});
        } else {
            console.error('[handleAttemptSellNPCMerchantInventoryItemResponse] Attempt to sell NPC merchant inventory item failed.');
            // Handle failure scenario, possibly by showing a failure dialogue or performing related UI updates
        }
        this.gameScene.serverControlledGameUIScene.waitingForServerResponse = false;
    }

    private handleAttemptPurchaseNPCMerchantInventoryItemResponse(data: AttemptConfirmNPCMerchantInventoryItemPurchaseResponse): void {
        console.log(`[handleAttemptConfirmNPCMerchantInventoryItemResponse] Received response: ${JSON.stringify(data, null, 2)}`);
        if (data.success) {
            // Reset UI from previous states or interactions
            this.gameScene.serverControlledGameUIScene.merchantNPCMenu.hideInitialInventoryView();
            this.gameScene.serverControlledGameUIScene.merchantNPCMenu.hideDetailInventoryView();

            console.log('[handleAttemptConfirmNPCMerchantInventoryItemResponse] Attempt to confirm NPC merchant inventory item succeeded.');
            // Handle success scenario, possibly by showing a success dialogue or performing related UI updates
            this.gameScene.serverControlledGameUIScene.commonerNPCMenu.activate({npcName: data.npcName, dialogue: data.dialogue.text, options: data.dialogue.options});
        } else {
            console.error('[handleAttemptConfirmNPCMerchantInventoryItemResponse] Attempt to confirm NPC merchant inventory item failed.');

            if (data.type === PurchaseAttemptResponseType.InsufficientFunds || data.type === PurchaseAttemptResponseType.InsufficientSpace) {
                // For specific failure types like Insufficient Funds or Space, utilize the provided dialogue

                // Reset UI from previous states or interactions
                this.gameScene.serverControlledGameUIScene.merchantNPCMenu.hideInitialInventoryView();
                this.gameScene.serverControlledGameUIScene.merchantNPCMenu.hideDetailInventoryView();

                this.gameScene.serverControlledGameUIScene.commonerNPCMenu.activate({npcName: data.npcName, dialogue: data.dialogue.text, options: data.dialogue.options});

            } else if (data.type === PurchaseAttemptResponseType.OtherFailure) {
                // If it's an OtherFailure, log the error but don't update the UI with a default dialogue
                console.error(`[handleAttemptConfirmNPCMerchantInventoryItemResponse] Generic failure encountered: ${data.reason}`);
                // You might decide to handle this case differently, e.g., showing a notification to the player without changing the dialogue UI
            }
        }

        // Indicate that the client is no longer waiting for a server response, allowing further interactions
        this.gameScene.serverControlledGameUIScene.waitingForServerResponse = false;
    }

    private handleAttemptSelectNPCMerchantInventoryItemForPurchaseResponse(data: AttemptNPCMerchantInventoryItemSelectionForPurchaseResponseEvent): void {
        console.log(`[handleAttemptSelectNPCMerchantInventoryItemResponse] Received response: ${JSON.stringify(data, null, 2)}`);
        if (data.success) {
            console.log('[handleAttemptSelectNPCMerchantInventoryItemResponse] Attempt to select NPC merchant inventory item succeeded.');

            // Conditionally pass stats, classes, and minimumLevel only if they are part of the data type
            const detailData: InventoryItemDetails = {
                inventoryItemName: data.inventoryItemName,
                inventoryItemIndex: data.inventoryItemIndex,
                inventoryItemDescription: data.inventoryItemDescription,
                inventoryItemCostSalePrice: data.inventoryItemCost,
                inventoryItemType: data.inventoryItemType,
                inventoryItemStats: 'inventoryItemStats' in data ? data.inventoryItemStats : undefined,
                inventoryItemClasses: 'inventoryItemClasses' in data ? data.inventoryItemClasses : undefined,
                inventoryItemMinimumLevel: 'inventoryItemMinimumLevel' in data ? data.inventoryItemMinimumLevel : undefined,
                inventoryItemBindingType: data.inventoryItemBindingType
            };

            this.gameScene.serverControlledGameUIScene.merchantNPCMenu.populateBuyDetailView(detailData);
            this.gameScene.serverControlledGameUIScene.merchantNPCMenu.showDetailView();
        } else {
            console.error('[handleAttemptSelectNPCMerchantInventoryItemResponse] Attempt to select NPC merchant inventory item failed.');
        }
        this.gameScene.serverControlledGameUIScene.waitingForServerResponse = false;
    }

    private handleAttemptSelectNPCMerchantInventoryItemForSaleResponse(data: AttemptPlayerInventoryItemSelectionForSaleResponseEvent): void {
        console.log(`[handleAttemptSelectNPCMerchantInventoryItemForSaleResponse] Received response: ${JSON.stringify(data, null, 2)}`);
        if (data.success) {
            console.log('[handleAttemptSelectNPCMerchantInventoryItemForSaleResponse] Attempt to select NPC merchant inventory item for sale succeeded.');
            // this.scene.serverControlledGameUIScene.merchantNPCMenu...;
            this.gameScene.serverControlledGameUIScene.merchantNPCMenu.populateSellDetailView({
                inventoryItemIndex: data.inventoryItemIndex,
                inventoryItemDescription: data.inventoryItemDescription,
                inventoryItemCostSalePrice: data.inventoryItemSalePrice,
                inventoryItemName: data.inventoryItemName,
                inventoryItemType: data.inventoryItemType,
                inventoryItemStats: data.inventoryItemStats,
                inventoryItemClasses: data.inventoryItemClasses,
                inventoryItemMinimumLevel: data.inventoryItemMinimumLevel,
                inventoryItemBindingType: data.inventoryItemBindingType
            });
            this.gameScene.serverControlledGameUIScene.merchantNPCMenu.showDetailView();
            // Add any additional handling here
        } else {
            console.error('[handleAttemptSelectNPCMerchantInventoryItemForSaleResponse] Attempt to select NPC merchant inventory item for sale failed.');
            // Add any additional handling here
        }
        this.gameScene.serverControlledGameUIScene.waitingForServerResponse = false;
    }

    private openMerchantNPCInventoryHandler(data: OpenMerchantNPCInventoryEvent): void {
        console.log('Received openMerchantNPCInventory event:', data);
        if (data.success) {
            console.log('Opening merchant NPC inventory:', data.inventory);
            this.gameScene.serverControlledGameUIScene.commonerNPCMenu.hideDialogueElements();
            // this.scene.serverControlledGameUIScene.merchantNPCMenu.hideDialogueElements();
            this.gameScene.serverControlledGameUIScene.merchantNPCMenu.populateInitialBuyInventoryView(data.inventory, data.playerGold);
            this.gameScene.serverControlledGameUIScene.merchantNPCMenu.showInitialInventoryView();
            // this.scene.serverControlledGameUIScene.merchantNPCMenu.activate(data.inventory);
        } else {
            console.error('Error opening merchant NPC inventory:', data.errorMessage);
        }
    }

    public handleTeleportingPlayersNewMapData(data: ClientSideEnterNewMapData): void {

        console.log('[SocketEventHandler.handleTeleportingPlayersNewMapData] entering method');
        console.log(`[SocketEventHandler.handleTeleportingPlayersNewMapData] data: ${JSON.stringify(data, null, 2)}`);
        console.log('[SocketEventHandler.handleTeleportingPlayersNewMapData] setting this.scene.serverControlledGameUIScene.waitingForServerResponse to false');
        this.gameScene.serverControlledGameUIScene.interactionMenu.interactionMenuCanBeShown = false;
        console.log('[SocketEventHandler.handleTeleportingPlayersNewMapData] dismissing interaction menu');
        this.gameScene.serverControlledGameUIScene.interactionMenu.dismiss();

        const currentPlayerData = data.players.find(player => player.socketId === this.socket.id)!;

        // Filter out the current player from the data.players array
        const remotePlayersData = data.players.filter(player => player.socketId !== this.socket.id);

        // Despawn NPCs.
        if (this.gameScene.npcs) { // Confirm npcs is a Map with NPC IDs as keys
            // Use forEach to iterate over the Map
            this.gameScene.npcs.forEach((npc, npcId) => {
                if (npc) {
                    npc.setVisible(false); // Make the NPC invisible
                    npc.destroy(); // Destroy the NPC object
                    this.gameScene.npcs.delete(npcId); // Remove the NPC from the Map
                }
            });
        }

        // Clear out NPC memory
        this.gameScene.npcs = new Map<string, NPCSprite>();

        // Spawn NPCs for the new map
        for (const npcData of data.npcs) {
            this.spawnNPC(npcData);
        }

        // 1. Destroy all remote player sprites.
        this.gameScene.remotePlayers.forEach((playerSprite, playerId) => {
            playerSprite.setVisible(false);
            playerSprite.off('pointerdown');
            playerSprite.destroy();
            this.gameScene.remotePlayers.delete(playerId);
        });

        // 2. Clear out client remote player memory.
        // Assuming remotePlayers is a reference to the client's memory of remote players.
        this.gameScene.remotePlayers = new Map<string, PlayerSprite>();
        // Now use remotePlayersData for processing the other remote players
        remotePlayersData.forEach(mapEntryPlayerInfo => {
            // Spawn or initialize the remote player using player data.
            this.spawnRemotePlayer(mapEntryPlayerInfo);

            // Set the targeted, isTargetedForDuel, and isTargetedForInvite properties on remote players
            const remotePlayer = this.gameScene.remotePlayers.get(mapEntryPlayerInfo.socketId);
            if (remotePlayer) {

                if (!mapEntryPlayerInfo.visible) {
                    remotePlayer.setVisible(false);
                }

                remotePlayer.targeted = mapEntryPlayerInfo.targeted;
                remotePlayer.isTargetedForDuel = mapEntryPlayerInfo.targetedForDuel;
                remotePlayer.isTargetedForInvite = mapEntryPlayerInfo.targetedForInvite;
            }
        });

        // destroy all doors
        this.gameScene.doors.forEach(door => {
            door.destroy();
        });
        this.gameScene.doors = [];

        // spawn the new doors
        for (const door of data.doors) {
            console.log(`Spawning door with image: ${door.image}, x: ${door.position.x}, y: ${door.position.y}`);
            this.spawnDoor(door);
        }

        this.gameScene.signs.forEach(sign => {
            sign.destroy();
        });
        this.gameScene.signs = [];

        // spawn the new signs
        for (const sign of data.signs) {
            console.log(`Spawning sign with image: ${sign.image}, x: ${sign.position.x}, y: ${sign.position.y}`);
            this.spawnSign(sign);
        }

        // Assuming 'data' is an object that contains 'mapName'
        if (data.mapName) {
            this.initializeTilemapAndSetAnimations(data.mapName);
        } else {
            console.error('Map name is undefined in data');
        }

        this.gameScene.player.setPosition(
            currentPlayerData.position.x,
            currentPlayerData.position.y
        );
        this.gameScene.player.facingDirection = currentPlayerData.facingDirection;

        if (this.gameScene.player.borderVisible) {
            this.gameScene.player.updateBorder(); // Update the border at the new location
        }

        this.gameScene.updateSpriteFrame(this.gameScene.player);
    }

    // Handler for players leaving the current map
    public handlePlayersLeavingMapViaTeleport(data: { leavingSocketIds: string[] }): void {
        // Check if the client player is in the leaving socket IDs
        if (data.leavingSocketIds.includes(this.socket.id)) {
            // Teardown the current tilemap for the client player
            if (this.gameScene.currentTilemap) {
                for (const layer of this.gameScene.currentTilemap.layers) {
                    if (layer.tilemapLayer) {
                        layer.tilemapLayer.destroy();
                    }
                }
                this.gameScene.currentTilemap.destroy();
            }

            // Additional logic for client player leaving the map (if necessary)
        }

        // Iterate through each socket ID and handle remote players leaving the current map
        data.leavingSocketIds.forEach(socketId => {
            if (socketId !== this.socket.id) { // Skip the client player's socket ID
                const leavingPlayer = this.gameScene.remotePlayers.get(socketId);
                if (leavingPlayer) {
                    leavingPlayer.destroy(); // Destroy the player sprite
                    this.gameScene.remotePlayers.delete(socketId);
                }
            }
        });
    }

    // Handler for players joining the new map
    public handlePlayersJoiningMapViaTeleport(data: {
        newPlayersData: MapEntryPlayerInfoArray,
        visible: boolean
    }): void {
        // Iterate through each joining player's data
        data.newPlayersData.forEach(newPlayer => {
            // Spawn or initialize the remote player using player data
            this.spawnRemotePlayer(newPlayer);

            // Set the targeted, isTargetedForDuel, and isTargetedForInvite properties on remote players
            const remotePlayer = this.gameScene.remotePlayers.get(newPlayer.socketId);
            if (remotePlayer) {
                remotePlayer.targeted = newPlayer.targeted;
                remotePlayer.isTargetedForDuel = newPlayer.targetedForDuel;
                remotePlayer.isTargetedForInvite = newPlayer.targetedForInvite;
                // Set visibility of the player
                remotePlayer.setVisible(data.visible);
            }

        });

        // Additional logic (if necessary) for handling players joining
    }

    public handleTeleportationWithinMap(data: {
        x: number,
        y: number,
        socketIds: string[],
        facingDirection: Direction
    }): void {
        console.log('[handleTeleportationWithinMap] Received teleport event:', data);

        console.log('[handleTeleportationWithinMap] Setting interactionMenuCanBeShown to false.');
        this.gameScene.serverControlledGameUIScene.interactionMenu.interactionMenuCanBeShown = false;

        console.log('[handleTeleportationWithinMap] Dismissing interaction menu.');
        this.gameScene.serverControlledGameUIScene.interactionMenu.dismiss();

        // Iterate through each socket ID provided in the data
        for (const socketId of data.socketIds) {
            console.log(`[handleTeleportationWithinMap] Processing teleport for socketId: ${socketId}`);

            // Check if the current socket ID matches the client's socket ID
            if (socketId === this.socket.id) {
                console.log('[handleTeleportationWithinMap] Teleporting client\'s player.');

                // If it's the client's player, set their position and facing direction
                this.gameScene.player.setPosition(data.x, data.y);
                this.gameScene.player.facingDirection = data.facingDirection;

                console.log('[handleTeleportationWithinMap] Updated client player\'s position and direction.');

                // Update the player's sprite frame to reflect the new state
                this.gameScene.updateSpriteFrame(this.gameScene.player);
                console.log('[handleTeleportationWithinMap] Updated client player\'s sprite frame.');
            } else {
                console.log(`[handleTeleportationWithinMap] Checking for remote player with socketId: ${socketId}.`);

                // If it's not the client's player, find the corresponding remote player
                const remotePlayer = this.gameScene.remotePlayers.get(socketId);

                // If the remote player is found, update their position and facing direction
                if (remotePlayer) {
                    remotePlayer.setPosition(data.x, data.y);
                    remotePlayer.facingDirection = data.facingDirection;

                    console.log(`[handleTeleportationWithinMap] Updated remote player ${socketId}'s position and direction.`);

                    // Update the remote player's sprite frame as well
                    this.gameScene.updateSpriteFrame(remotePlayer);
                    console.log(`[handleTeleportationWithinMap] Updated remote player ${socketId}'s sprite frame.`);
                } else {
                    console.log(`[handleTeleportationWithinMap] No remote player found for socketId: ${socketId}.`);
                }
            }
        }

        console.log('[handleTeleportationWithinMap] Teleportation handling complete.');
    }

    public handleDuelChallengeReceived({challengerId, challengerName}: {
        challengerId: string,
        challengerName: string
    }): void {
        console.log(`You have been challenged to a duel by ${challengerName} (id: ${challengerId})`);

        this.gameScene.player.isInteractingWithInteractable = false;
        // todo: close the 'duel has been cancelled frame or whatever'
        this.gameScene.serverControlledGameUIScene.duelNotification.activate(`You have been challenged to a duel by ${challengerName}.`);

        console.log('[handleDuelChallengeReceived] Starting game pad scene if needed.');
        showGamePadSceneIfNeeded(this.gameScene);

    }

    public handleTradeResponse(data: OpenTradeMenuEvent): void {
        console.log('Received openTradeMenu event:', data);
        if (data.success) {
            console.log('trade menu successfully opening');
            console.log('[handleTradeResponse] stopping game pad scene if needed.');
            hideGamePadSceneIfNeeded(this.gameScene);
            this.gameScene.serverControlledGameUIScene.tradeMenu.activate({
                items: data.inventory,
                gold: data.gold,
                clientPlayerName: data.clientPlayerName,
                remotePlayerName: data.remotePlayerName
            });
        } else {
            // console.error('Error opening trade menu:', data.errorMessage);
            console.log('trade menu failed to open.');
        }
    }

    public handleAttemptSelectClientInventoryItemForTradeResponse(data: AttemptSelectClientInventoryItemForTradeResponseEvent): void {
        console.log(`[handleAttemptSelectInventoryItemForTradeResponse] Received response: ${JSON.stringify(data, null, 2)}`);
        if (data.success) {
            console.log('[handleAttemptSelectInventoryItemForTradeResponse] Attempt to select inventory item for trade succeeded.');
            this.gameScene.serverControlledGameUIScene.tradeMenu.populateDetailView({
                inventoryItemIndex: data.inventoryItemIndex,
                inventoryItemName: data.inventoryItemName,
                inventoryItemType: data.inventoryItemType,
                inventoryItemMinimumLevel: data.inventoryItemMinimumLevel,
                inventoryItemBindingStatus: data.inventoryItemBindingType,
                inventoryItemClasses: data.inventoryItemClasses,
                inventoryItemStats: data.inventoryItemStats,
                inventoryItemDescription: data.inventoryItemDescription,
                alreadyOffered: data.alreadyOffered,
                offeredSlotIndex: data.offeredSlotIndex,
                isClientItem: true
            });
            this.gameScene.serverControlledGameUIScene.tradeMenu.showDetailView();
            // if (data.alreadyOffered) {
            //     this.gameScene.serverControlledGameUIScene.tradeMenu.clientPlayerOfferedItemButtons[data.offeredSlotIndex].select();
            //     this.gameScene.serverControlledGameUIScene.tradeMenu.offerAndRescindSelectedClientPlayerItemButton.changeButtonText('Rescind');
            //     this.gameScene.serverControlledGameUIScene.tradeMenu.offerAndRescindSelectedClientPlayerItemButton.changeButtonImage(UIImageKey.CrossButton, UIImageKey.CrossButton);
            // }
            // Add any additional handling here
        } else {
            console.error('[handleAttemptSelectInventoryItemForTradeResponse] Attempt to select inventory item for trade failed.');
            // Add any additional handling here
        }
    }

    public handleAttemptSelectRemoteInventoryItemForTradeResponse(data: AttemptSelectRemoteInventoryItemForTradeResponseEvent): void {
        console.log(`[handleAttemptSelectRemoteInventoryItemForTradeResponse] Received response: ${JSON.stringify(data, null, 2)}`);
        if (data.success) {
            console.log('[handleAttemptSelectRemoteInventoryItemForTradeResponse] Attempt to select remote inventory item for trade succeeded.');
            this.gameScene.serverControlledGameUIScene.tradeMenu.populateDetailView({
                inventoryItemIndex: -1,
                inventoryItemName: data.inventoryItemName,
                inventoryItemType: data.inventoryItemType,
                inventoryItemDescription: data.inventoryItemDescription,
                inventoryItemMinimumLevel: data.inventoryItemMinimumLevel,
                inventoryItemBindingStatus: data.inventoryItemBindingType,
                inventoryItemClasses: data.inventoryItemClasses,
                inventoryItemStats: data.inventoryItemStats,
                alreadyOffered: true,
                offeredSlotIndex: data.offeredSlotIndex,
                isClientItem: false
            });
            this.gameScene.serverControlledGameUIScene.tradeMenu.showDetailView();
        } else {
            console.error('[handleAttemptSelectRemoteInventoryItemForTradeResponse] Attempt to select remote inventory item for trade failed.');
            // Add any additional handling here
        }
    }

    public handleAttemptOfferSelectedInventoryItemForTradeResponse(data: AttemptOfferSelectedInventoryItemForTradeResponseEvent): void {
        console.log(`[handleAttemptOfferSelectedInventoryItemForTradeResponse] Received response: ${JSON.stringify(data, null, 2)}`);
        if (data.success) {
            console.log('[handleAttemptOfferSelectedInventoryItemForTradeResponse] Attempt to offer selected inventory item for trade succeeded.');
            // Handle success scenario, possibly by showing a success dialogue or performing related UI updates
            this.gameScene.serverControlledGameUIScene.tradeMenu.updateClientPlayerOfferedItems(data.clientPlayerOfferedItems);
            // this.gameScene.serverControlledGameUIScene.tradeMenu.itemDetailText.setVisible(false);
            this.gameScene.serverControlledGameUIScene.tradeMenu.inventoryDetailNameText.setVisible(false);
            this.gameScene.serverControlledGameUIScene.tradeMenu.inventoryDetailTypeText.setVisible(false);
            this.gameScene.serverControlledGameUIScene.tradeMenu.inventoryDetailDescriptionText.setVisible(false);
            this.gameScene.serverControlledGameUIScene.tradeMenu.inventoryVerticalDivider.setVisible(false);
            this.gameScene.serverControlledGameUIScene.tradeMenu.inventoryDetailMinimumLevelText.setVisible(false);
            this.gameScene.serverControlledGameUIScene.tradeMenu.bindingStatusText.setVisible(false);
            this.gameScene.serverControlledGameUIScene.tradeMenu.classesText.setVisible(false);
            this.gameScene.serverControlledGameUIScene.tradeMenu.hideStats();
            this.gameScene.serverControlledGameUIScene.tradeMenu.remoteItemDetailShown = false;
            this.gameScene.serverControlledGameUIScene.tradeMenu.commandText.setText(this.gameScene.serverControlledGameUIScene.tradeMenu.defaultCommandText);
            this.gameScene.serverControlledGameUIScene.tradeMenu.commandText.setVisible(true);
            this.gameScene.serverControlledGameUIScene.tradeMenu.inventoryMainListButtonInfos.forEach(buttonInfo => {
                buttonInfo.button.deselect();
            });
            this.gameScene.serverControlledGameUIScene.tradeMenu.offerAndRescindSelectedClientPlayerItemButton.hideActionButton();
            this.gameScene.serverControlledGameUIScene.tradeMenu.setTradeStatus('client', 'Pending');
            this.gameScene.serverControlledGameUIScene.tradeMenu.setTradeStatus('remote', 'Pending');
        } else {
            console.error('[handleAttemptOfferSelectedInventoryItemForTradeResponse] Attempt to offer selected inventory item for trade failed.');
            // Handle failure scenario, possibly by showing a failure dialogue or performing related UI updates
        }
    }

    public handleAttemptOfferGoldForTradeResponse(data: AttemptOfferGoldForTradeResponseEvent): void {
        console.log(`[handleAttemptOfferGoldForTradeResponse] Received response: ${JSON.stringify(data, null, 2)}`);
        if (data.success) {
            console.log('[handleAttemptOfferGoldForTradeResponse] Attempt to offer gold for trade succeeded.');
            // Handle success scenario, possibly by showing a success dialogue or performing related UI updates
            this.gameScene.serverControlledGameUIScene.tradeMenu.updateClientPlayerOfferedGold(data.goldAmount);
            this.gameScene.serverControlledGameUIScene.tradeMenu.goldOfferAmount = 0;
            this.gameScene.serverControlledGameUIScene.tradeMenu.goldOfferText.setText('0 gp');
            this.gameScene.serverControlledGameUIScene.tradeMenu.setTradeStatus('client', 'Pending');
            this.gameScene.serverControlledGameUIScene.tradeMenu.setTradeStatus('remote', 'Pending');
        } else {
            console.error('[handleAttemptOfferGoldForTradeResponse] Attempt to offer gold for trade failed.');
            // Handle failure scenario, possibly by showing a failure dialogue or performing related UI updates
        }
    }

    public handleUpdateRemoteTradePartnerGoldOffered(data: number): void {
        console.log(`[handleUpdateRemoteTradePartnerGoldOffered] Received response: ${JSON.stringify(data, null, 2)}`);
        console.log('[handleUpdateRemoteTradePartnerGoldOffered] Update remote trade partner gold offered succeeded.');
        this.gameScene.serverControlledGameUIScene.tradeMenu.updateRemotePlayerOfferedGold(data);
        this.gameScene.serverControlledGameUIScene.tradeMenu.setTradeStatus('remote', 'Pending');
        this.gameScene.serverControlledGameUIScene.tradeMenu.setTradeStatus('client', 'Pending');
    }

    public handleAttemptAcceptTradeOfferResponse(data: AttemptAcceptTradeOfferResponseEvent): void {
        console.log(`[handleAttemptAcceptTradeOfferResponse] Received response: ${JSON.stringify(data, null, 2)}`);
        if (data.success) {
            console.log('[handleAttemptAcceptTradeOfferResponse] Attempt to accept trade offer succeeded.');
            this.gameScene.serverControlledGameUIScene.tradeMenu.setTradeStatus('client', 'Accepted');
        }
    }

    public handleUpdateRemoteTradePartnerTradeAccepted(): void {
        console.log('[handleUpdateRemoteTradePartnerTradeAccepted] Update remote trade partner trade accepted succeeded.');
        this.gameScene.serverControlledGameUIScene.tradeMenu.setTradeStatus('remote', 'Accepted');
    }

    public handleAttemptRevertTradeOfferToPendingResponse(data: {success: boolean}): void {
        if (data.success) {
            console.log('[handleAttemptRevertTradeOfferToPendingResponse] Attempt to revert trade offer to pending succeeded.');
            this.gameScene.serverControlledGameUIScene.tradeMenu.setTradeStatus('client', 'Pending');
        }
    }

    public handleUpdateRemoteTradeStatusToPending(): void {
        console.log('[handleUpdateRemoteTradeStatusToPending] Update remote trade status to pending succeeded.');
        this.gameScene.serverControlledGameUIScene.tradeMenu.setTradeStatus('remote', 'Pending');
    }

    public handleAttemptCancelTradeOfferResponse(data: {success: boolean}): void {
        console.log(`[handleAttemptCancelTradeOfferResponse] Received response: ${JSON.stringify(data, null, 2)}`);
        if (data.success) {
            console.log('[handleAttemptCancelTradeOfferResponse] Attempt to cancel trade offer succeeded.');
            // Handle success scenario, possibly by showing a success dialogue or performing related UI updates
            this.cancelTradeHandler();

        } else {
            console.error('[handleAttemptCancelTradeOfferResponse] Attempt to cancel trade offer failed.');
            // Handle failure scenario, possibly by showing a failure dialogue or performing related UI updates
        }
    }

    public handleCancelRemoteTradeOffer(): void {
        console.log('[handleCancelRemoteTradeOffer] Cancel remote trade offer succeeded.');
        this.cancelTradeHandler();
    }

    public cancelTradeHandler(): void {
        this.gameScene.serverControlledGameUIScene.tradeMenu.dismiss();
        this.gameScene.serverControlledGameUIScene.dismissibleNotification.activate('Trade cancelled.');
    }

    public handleTradeFailure(data: {reason: string}): void {
        this.gameScene.serverControlledGameUIScene.tradeMenu.dismiss();
        this.gameScene.serverControlledGameUIScene.dismissibleNotification.activate(`Trade failed: ${data.reason}`);
    }

    public handleAttemptRescindOfferedItemResponse(data: AttemptRescindOfferedItemResponseEvent): void {
        console.log(`[handleAttemptRescindOfferedItemResponse] Received response: ${JSON.stringify(data, null, 2)}`);
        if (data.success) {
            console.log('[handleAttemptRescindOfferedItemResponse] Attempt to rescind offered item succeeded.');
            // Handle success scenario, possibly by showing a success dialogue or performing related UI updates
            this.gameScene.serverControlledGameUIScene.tradeMenu.updateClientPlayerOfferedItems(data.clientPlayerOfferedItems);
            // this.gameScene.serverControlledGameUIScene.tradeMenu.itemDetailText.setVisible(false);
            this.gameScene.serverControlledGameUIScene.tradeMenu.inventoryDetailNameText.setVisible(false);
            this.gameScene.serverControlledGameUIScene.tradeMenu.inventoryDetailTypeText.setVisible(false);
            this.gameScene.serverControlledGameUIScene.tradeMenu.inventoryDetailDescriptionText.setVisible(false);
            this.gameScene.serverControlledGameUIScene.tradeMenu.inventoryVerticalDivider.setVisible(false);
            this.gameScene.serverControlledGameUIScene.tradeMenu.inventoryDetailMinimumLevelText.setVisible(false);
            this.gameScene.serverControlledGameUIScene.tradeMenu.bindingStatusText.setVisible(false);
            this.gameScene.serverControlledGameUIScene.tradeMenu.classesText.setVisible(false);
            this.gameScene.serverControlledGameUIScene.tradeMenu.hideStats();
            this.gameScene.serverControlledGameUIScene.tradeMenu.remoteItemDetailShown = false;
            this.gameScene.serverControlledGameUIScene.tradeMenu.commandText.setText(this.gameScene.serverControlledGameUIScene.tradeMenu.defaultCommandText);
            this.gameScene.serverControlledGameUIScene.tradeMenu.commandText.setVisible(true);
            this.gameScene.serverControlledGameUIScene.tradeMenu.inventoryMainListButtonInfos.forEach(buttonInfo => {
                buttonInfo.button.deselect();
            });
            this.gameScene.serverControlledGameUIScene.tradeMenu.offerAndRescindSelectedClientPlayerItemButton.hideActionButton();
            this.gameScene.serverControlledGameUIScene.tradeMenu.setTradeStatus('client', 'Pending');
            this.gameScene.serverControlledGameUIScene.tradeMenu.setTradeStatus('remote', 'Pending');
        } else {
            console.error('[handleAttemptRescindOfferedItemResponse] Attempt to rescind offered item failed.');
            // Handle failure scenario, possibly by showing a failure dialogue or performing related UI updates
        }
    }

    public handleUpdateRemoteTradePartnerOfferedItems(data: UpdateRemoteTradePartnerOfferedItemsEvent): void {
        console.log(`[handleUpdateRemoteTradePartnerOfferedItems] Received response: ${JSON.stringify(data, null, 2)}`);
        console.log('[handleUpdateRemoteTradePartnerOfferedItems] Update remote trade partner offered items succeeded.');
        this.gameScene.serverControlledGameUIScene.tradeMenu.updateRemotePlayerOfferedItems(data);
        this.gameScene.serverControlledGameUIScene.tradeMenu.setTradeStatus('remote', 'Pending');
        this.gameScene.serverControlledGameUIScene.tradeMenu.setTradeStatus('client', 'Pending');
        if (this.gameScene.serverControlledGameUIScene.tradeMenu.remoteItemDetailShown) {
            // this.gameScene.serverControlledGameUIScene.tradeMenu.itemDetailText.setVisible(false);
            this.gameScene.serverControlledGameUIScene.tradeMenu.inventoryDetailNameText.setVisible(false);
            this.gameScene.serverControlledGameUIScene.tradeMenu.inventoryDetailTypeText.setVisible(false);
            this.gameScene.serverControlledGameUIScene.tradeMenu.inventoryDetailDescriptionText.setVisible(false);
            this.gameScene.serverControlledGameUIScene.tradeMenu.inventoryVerticalDivider.setVisible(false);
            this.gameScene.serverControlledGameUIScene.tradeMenu.inventoryDetailMinimumLevelText.setVisible(false);
            this.gameScene.serverControlledGameUIScene.tradeMenu.bindingStatusText.setVisible(false);
            this.gameScene.serverControlledGameUIScene.tradeMenu.classesText.setVisible(false);
            this.gameScene.serverControlledGameUIScene.tradeMenu.hideStats();
            this.gameScene.serverControlledGameUIScene.tradeMenu.remoteItemDetailShown = false;
            this.gameScene.serverControlledGameUIScene.tradeMenu.commandText.setText(this.gameScene.serverControlledGameUIScene.tradeMenu.defaultCommandText);
            this.gameScene.serverControlledGameUIScene.tradeMenu.commandText.setVisible(true);
            this.gameScene.serverControlledGameUIScene.tradeMenu.inventoryMainListButtonInfos.forEach(buttonInfo => {
                buttonInfo.button.deselect();
            });
        }
    }

    public handleTradeComplete(): void {
        console.log('Received tradeComplete event');
        this.gameScene.serverControlledGameUIScene.dismissibleNotification.activate('Trade complete.');
        showGamePadSceneIfNeeded(this.gameScene);
    }

    public handleDuelResponse({
        success,
        action,
        targetId
    }: { success: boolean; action?: string; targetId?: string }): void {
        console.log('Received duelResponse event:', {success, action, targetId});

        if (success) {
            switch (action) {
                case 'duelChallengeIssued':
                    console.log('Duel challenge has been issued.');
                    if (targetId) {
                        const targetPlayer = this.gameScene.remotePlayers.get(targetId);
                        if (targetPlayer) {
                            targetPlayer.isTargetedForDuel = false;
                            targetPlayer.targeted = false;
                            this.gameScene.serverControlledGameUIScene.targetMenu.dismiss();
                        }
                    }
                    break;
                case 'duelArmed':
                    // Logic for handling when the player is armed for a duel.
                    // Maybe change some visual indicator to show that the player is ready to duel.
                    console.log('Player is armed for a duel.');
                    if (targetId) {
                        const targetPlayer = this.gameScene.remotePlayers.get(targetId);
                        if (targetPlayer) {
                            targetPlayer.isTargetedForDuel = true;
                            this.gameScene.serverControlledGameUIScene.targetMenu.dismiss();
                        }
                    }

                    break;
                default:
                    // Unexpected action value.
                    console.error('Unexpected action in duelResponse:', action);
                    break;
            }
        } else {
            // Duel could not be initiated for some reason.
            // Handle this case as appropriate for your game.
            console.log('Duel could not be initiated.');
        }
    }

    public handleClearTarget(): void {
        console.log('Received clearTarget event');
        this.gameScene.serverControlledGameUIScene.targetMenu.dismiss();
    }

    public handleTargetPlayer(data: TargetPlayerResponse): void {
        console.log('Received targetPlayer event:', data);
        this.gameScene.player.isInteractingWithInteractable = false;

        this.gameScene.serverControlledGameUIScene.targetMenu.activate(data);
        this.gameScene.serverControlledGameUIScene.waitingForServerResponse = false;
        showGamePadSceneIfNeeded(this.gameScene);
    }

    public handleNewPlayer(data: NewClientPlayerInfo): void {
        console.log('Received newPlayer event:', data);
        console.log('calling spawnRemotePlayer method.');
        this.spawnRemotePlayer(data);
    }

    public spawnRemotePlayer(playerInfo: NewClientPlayerInfo): void {
        console.log('Entering spawnRemotePlayer method.');
        console.log({clientPlayerInfo: playerInfo});

        // Check if the remote player already exists in the scene's remotePlayers map
        const existingRemotePlayer = this.gameScene.remotePlayers.get(playerInfo.socketId);
        if (existingRemotePlayer) {
            console.log(`Remote player with socket ID ${playerInfo.socketId} already exists. Exiting early.`);
            return; // Exit early since the player is already present
        }

        // Proceed with creating and adding a new remote player sprite
        const playerSpriteParameters: PlayerSpriteParameters = {
            scene: this.gameScene,
            x: playerInfo.position.x,
            y: playerInfo.position.y,
            primaryColorTexture: SpriteSheet.HeroClothesPrimary,
            secondaryColorTexture: SpriteSheet.HeroClothesSecondary,
            frame: 0,
            facingDirection: playerInfo.facingDirection,
            playerName: playerInfo.name,
            skinColor: Number(playerInfo.skinColor),
            hairColor: Number(playerInfo.hairColor),
            primaryColor: Number(playerInfo.primaryColor),
            secondaryColor: Number(playerInfo.secondaryColor),
            gender: playerInfo.gender,
            className: playerInfo.className,
            inConflict: playerInfo.inCombat,
            visible: playerInfo.visible
        };

        const remotePlayer = new PlayerSprite(playerSpriteParameters);
        remotePlayer.setDepth(DepthLevel.NON_PARTY_PLAYERS);
        remotePlayer.facingDirection = playerInfo.facingDirection;
        this.gameScene.updateSpriteFrame(remotePlayer);

        // Save a reference to the player's sprite in the remotePlayers map
        this.gameScene.remotePlayers.set(playerInfo.socketId, remotePlayer);

        // Make the remote player sprite interactive and handle click events
        remotePlayer.setInteractive();
        remotePlayer.on('pointerdown', this.playerClickedHandler.bind(this, playerInfo.socketId));
    }

    /**
     * Configures a tilemap by setting up its tileset, layer depths, and animations based on provided map key and tileset information.
     * This method is essential for initializing complex tilemap setups that require detailed configurations for both the visual structure
     * and dynamic content such as animations. It prepares the tilemap to be fully functional within the game scene, ensuring all graphical
     * elements are rendered correctly and are interactive.
     *
     * The method handles the following steps:
     * 1. Creates the tilemap object for the given map key.
     * 2. Adds the tileset to the tilemap using provided tileset information, ensuring all graphical tiles are loaded.
     * 3. Initializes a container to hold any animated tiles, preparing the system for dynamic graphical updates.
     * 4. Iterates over each layer in the tilemap, setting up layers only if they are marked visible. For each of these layers:
     *    - Configures the depth of the layer to ensure proper rendering order.
     *    - Iterates through each tile within the layer to configure animations, if available, based on tile data.
     *    - Registers animations for each tile, storing these in the animated tiles container for runtime processing.
     * 5. Performs an initial update to synchronize all animations to their starting frames, making the map ready for display.
     *
     * @param {string} mapKey - The key identifier for the map, used to retrieve the associated tileset information.
     * @param {MapTilesetInfo} currentMapTilesetInfo - Object containing necessary tileset information such as tilesetName and imageKey,
     *                                                 used to retrieve and setup the tileset within the tilemap.
     *
     * @returns {void} This method directly modifies the gameScene properties, specifically setting up the tilemap and its associated elements.
     *
     * Usage:
     * This method is intended to be used during the initialization phase of a map when detailed setup is required, typically when a new level or area is loaded.
     * It provides a thorough configuration of the tilemap, suitable for maps that need precise graphical adjustments and dynamic interactions.
     */
    private initializeTilemapWithLayerConfigurationAndAnimationSetup(mapKey: string, currentMapTilesetInfo: MapTilesetInfo): void {
        // Create a new tilemap in the game scene using the specified map key.
        // This step initializes the basic structure where all tiles, layers, and animations will be placed.
        this.gameScene.currentTilemap = this.gameScene.make.tilemap({key: mapKey});
        // Add the tileset to the newly created tilemap using details from currentMapTilesetInfo.
        // This includes the name of the tileset and the corresponding image resource, along with the tile dimensions.
        // tileSize specifies the height and width of each tile, ensuring that the graphics align correctly on the grid.
        const tileset = this.gameScene.currentTilemap.addTilesetImage(currentMapTilesetInfo.tilesetName, currentMapTilesetInfo.imageKey, tileSize, tileSize, 1, 2);

        // Initialize the container for storing animated tiles. This array will hold instances of AnimatedTile,
        // which are used to manage and update animations for specific tiles on the tilemap.
        this.gameScene.animatedTiles = [];
        // Check if the tileset was successfully added to the tilemap.
        // If the tileset is loaded and contains data about the tiles (e.g., animation sequences), proceed with further setup.
        if (tileset) {
            if (tileset.tileData) {
                // Safely assume tileData exists and cast it to the correct type.
                // This object contains detailed information about each tile's possible animations.
                const tileData = tileset.tileData as Record<string, { animation?: { tileid: number }[] }>;

                // Iterate over each layer in the current tilemap. The layers array contains all layers
                // configured in the tilemap, each potentially containing multiple tiles.
                this.gameScene.currentTilemap.layers.forEach((layerData, i) => {
                    // Process only visible layers to avoid unnecessary computations on hidden layers.
                    if (layerData.visible) {
                        // Create the actual layer in the game scene using the name from layerData and tileset info.
                        // This layer will now be able to display tiles and handle interactions.
                        const layer = this.gameScene.currentTilemap.createLayer(layerData.name, currentMapTilesetInfo.tilesetName, 0, 0);

                        // Determine and assign the depth for each layer based on its index or a default depth mapping.
                        // This ensures that layers appear in the correct order, with background elements behind the foreground.
                        const assignedDepth = layerNameToDepth[layerData.name] || defaultDepths[Math.min(i, defaultDepths.length - 1)];
                        // Set the depth of the layer to the calculated value to control its rendering order.
                        layer!.setDepth(assignedDepth);

                        // For each tile in the layer, check and set up animations if available.
                        layer!.forEachTile(tile => {
                            // Calculate a unique identifier string for the tile based on its index and the tileset's first global ID.
                            // This string is used to fetch specific animation data for the tile.
                            const tileIdString = (tile.index - tileset.firstgid).toString();
                            // Check if there are animations defined for this specific tile and if so, process them.
                            if (tileData[tileIdString] && tileData[tileIdString].animation) {
                                // Retrieve the animation frames for the tile and map each frame ID to its actual index in the tileset.
                                const tileAnimations = tileData[tileIdString].animation;
                                // Extract the tile IDs for each frame and adjust them by adding the tileset's first global ID.
                                const animationFrames = tileAnimations?.map(frame => frame.tileid + tileset.firstgid) || [];
                                // If there are one or more frames, create an AnimatedTile instance and add it to the animatedTiles array.
                                if (animationFrames.length > 0) {
                                    // Create a new AnimatedTile object and push it to the animatedTiles array.
                                    this.gameScene.animatedTiles.push(new AnimatedTile(tile.index, animationFrames, layer!, tile.x, tile.y));
                                }
                            }
                        });
                    }
                });
                // After all layers and tiles are processed, perform an initial update to synchronize all animated tiles.
                // This method call ensures that all animations are ready and set to their starting frames.
                this.initialUpdateAnimatedTiles();
            }
        }
        // Log the current state of animated tiles for debugging and verification purposes.
        console.log(`animatedTiles: ${JSON.stringify(this.gameScene.animatedTiles, null, 2)}`);
    }

    /**
     * Initializes the tilemap with detailed configurations for animations and layer depths for a given map key.
     * This method configures all graphical elements essential for interactive gameplay by setting up the tilemap,
     * initializing tileset configurations, assigning layer depths, setting up tile animations, and updating tiles to their initial frames.
     *
     * It manages the entire setup process from creating the tilemap to making it fully operational with animations,
     * ensuring the game environment is visually dynamic and ready for player interaction.
     *
     * @param {string} mapKey - The identifier for the map, used to fetch tileset information from the global mapInfo object.
     *                          This key should match a valid configuration that includes tileset names and image keys.
     *
     * @throws {Error} - If no tileset information is available for the provided mapKey, signaling a setup failure.
     *
     * Usage:
     * Invoke this method when loading a new map that requires a detailed graphical setup, typically at game start
     * or when transitioning to new game levels or areas that need advanced visual preparations.
     */
    private initializeTilemapAndSetAnimations(mapKey: string): void {
        // Retrieve tileset information from a global map information object using the provided map key.
        const currentMapTilesetInfo = mapInfo[mapKey];

        // Check if the tileset information is available. If not, log an error.
        if (currentMapTilesetInfo) {
            // Call another method to handle the initial tilemap setup including layer configurations and basic animations.
            this.initializeTilemapWithLayerConfigurationAndAnimationSetup(mapKey, currentMapTilesetInfo);

            // Iterate over each layer in the current tilemap to set their depth for rendering purposes.
            this.gameScene.currentTilemap.layers.forEach((layerData, i) => {
                // Only process layers that are marked as visible to avoid unnecessary computations.
                if (layerData.visible) {
                    // Retrieve the actual layer object from the game scene's current tilemap.
                    const layer = layerData.tilemapLayer;

                    // Calculate and assign a depth value to the layer based on its name or a fallback to default depths.
                    // This ensures that layers are rendered in the correct order.
                    const assignedDepth = layerNameToDepth[layerData.name] || defaultDepths[Math.min(i, defaultDepths.length - 1)];
                    layer.setDepth(assignedDepth);

                    // Log the processing of each layer for debugging and validation purposes.
                    console.log(`Processing layer: ${layerData.name}`);
                }
            });

            // Iterate over each animated tile to update its frame to the initial position.
            // This is crucial for ensuring that animations appear correctly when the map is first loaded.
            this.gameScene.animatedTiles.forEach(animatedTile => {
                // Retrieve the first frame index from the animated tile's frame sequence.
                const initialFrameIndex = animatedTile.frames[0]; // This is the starting frame for the animation.

                // Set the tile at the calculated initial frame index.
                // This step updates the visual display of the tile to begin at the start of its animation sequence.
                animatedTile.layer.putTileAt(initialFrameIndex, animatedTile.x, animatedTile.y);
            });

            // Log the total number of layers processed for further debugging and oversight.
            console.log(`this.scene.currentTilemap.layers.length: ${this.gameScene.currentTilemap.layers.length}`);

        } else {
            // If no tileset information is found for the given map key, log an error indicating a potential data issue.
            console.error(`Tileset info not found for map: ${mapKey}`);
        }
    }

    /**
     * Initializes the tilemap for a given map key by setting up the tileset, layers, and animations.
     * This method ensures that the graphical components of the map are correctly loaded and configured
     * for the game environment, focusing on a stable and standard initial setup without dynamic adaptations.
     *
     * This streamlined setup process includes creating the tilemap object, adding the designated tileset,
     * and configuring each layer's visibility and depth based on predefined settings. It also handles
     * animations for tiles, setting them up if data is available, and performing an initial update
     * to ensure all animations are ready for display when the map is loaded.
     *
     * @param {string} mapKey - The identifier for the map configuration to initialize. This key is used
     *                          to retrieve corresponding tileset information from the global mapInfo object.
     *
     * @throws {Error} If tileset information for the provided mapKey is not found, indicating a failure
     *                 in configuration or data retrieval which is critical for map setup.
     *
     * Usage:
     * Call this method during the game's initial loading phase or when transitioning to a new map level.
     * It provides a foundational setup that ensures graphical consistency and readiness for further
     * dynamic interactions within the game environment.
     */
    private initializeTilemap(mapKey: string): void {
        // Log the initiation of the tilemap creation process with the specific map key.
        // This helps in tracking which map is being loaded and when the process begins.
        console.log(`Creating tilemap with key: ${mapKey}`);

        // Retrieve the tileset information from a global mapInfo object using the provided map key.
        // mapInfo contains all the necessary details about various maps including their tilesets.
        const currentMapTilesetInfo = mapInfo[mapKey];

        // Check if the tileset information for the given map key was found.
        if (currentMapTilesetInfo) {
            // If tileset info is available, proceed to initialize the tilemap with additional configurations
            // for layer setups and animations. This method will handle the detailed setup of layers and animations
            // based on the specific requirements of the tileset and map configuration.
            this.initializeTilemapWithLayerConfigurationAndAnimationSetup(mapKey, currentMapTilesetInfo);
        } else {
            // If no tileset information is available for the provided map key, log an error.
            // This indicates a configuration issue or a missing entry in the mapInfo object,
            // which is critical for setting up the map properly.
            console.error(`Tileset info not found for map: ${mapKey}`);
        }
    }

    public spawnClientPlayer(clientPlayerInfo: ClientPlayerInfo): void {
        console.log('Entering spawnClientPlayer method.');
        // Log the clientPlayerInfo to check the data before using it
        console.log(`clientPlayerInfo: ${JSON.stringify(clientPlayerInfo, null, 2)}`);
        // Check the 'currentMap' value before creating the tilemap
        console.log(`Creating tilemap with key: ${clientPlayerInfo.currentMap}`);
        // this.gameScene.currentTilemap = this.gameScene.make.tilemap({key: clientPlayerInfo.currentMap});

        if (clientPlayerInfo.currentMap) {
            this.initializeTilemap(clientPlayerInfo.currentMap);
        } else {
            console.error('currentMap is undefined in clientPlayerInfo');
        }

        console.log(`this.scene.currentTilemap.layers.length: ${this.gameScene.currentTilemap.layers.length}`);

        const playerSpriteParameters: PlayerSpriteParameters = {
            scene: this.gameScene,
            x: clientPlayerInfo.position.x,
            y: clientPlayerInfo.position.y,
            primaryColorTexture: SpriteSheet.HeroClothesPrimary,
            secondaryColorTexture: SpriteSheet.HeroClothesSecondary,
            frame: 0,
            facingDirection: clientPlayerInfo.facingDirection,
            playerName: clientPlayerInfo.name,
            skinColor: Number(clientPlayerInfo.skinColor),
            hairColor: Number(clientPlayerInfo.hairColor),
            primaryColor: Number(clientPlayerInfo.primaryColor),
            secondaryColor: Number(clientPlayerInfo.secondaryColor),
            gender: clientPlayerInfo.gender,
            className: clientPlayerInfo.className,
            inConflict: clientPlayerInfo.inCombat,
            visible: clientPlayerInfo.visible
        };

        this.gameScene.player = new PlayerSprite(playerSpriteParameters);

        this.gameScene.player.setDepth(DepthLevel.CLIENT_PLAYER);

        this.gameScene.cameras.main.startFollow(this.gameScene.player);
        this.gameScene.player.facingDirection = Direction.DOWN;

        this.gameScene.player.setInteractive();

        this.gameScene.player.on('pointerdown', this.playerClickedHandler.bind(this, clientPlayerInfo.socketId));

        this.gameScene.lastPosition = new Vector2(clientPlayerInfo.position.x, clientPlayerInfo.position.y);

        // Call handleUpdatePartyMembers to update gameActionBar
        this.gameScene.serverControlledGameUIScene.gameActionBar.handleUpdatePartyMembers([{
            socketId: clientPlayerInfo.socketId,
            skinColor: clientPlayerInfo.skinColor,
            hairColor: clientPlayerInfo.hairColor,
            primaryColor: clientPlayerInfo.primaryColor,
            secondaryColor: clientPlayerInfo.secondaryColor,
            gender: clientPlayerInfo.gender,
            className: clientPlayerInfo.className,
            currentHP: clientPlayerInfo.currentHP!,
            maxHP: clientPlayerInfo.maxHP!,
            currentResource: clientPlayerInfo.currentResource,
            maxResource: clientPlayerInfo.maxResource,
            displayResourceInField: clientPlayerInfo.displayResourceInField,
            resourceType: clientPlayerInfo.resourceType
        }]);
    }

    public handlePartyPromotion(data: { newPartyLeaderId: string }): void {
        console.log('=== Handle Party Promotion Event ===');

        console.log(`Client Socket ID: ${this.socket.id}`);
        console.log(`New Party Leader ID: ${data.newPartyLeaderId}`);

        // Check if the client player is the new party leader
        if (this.socket.id === data.newPartyLeaderId) {
            console.log('Client is the new party leader.');

            console.log('Setting depths for remote players...');

            // Iterate over remote players to set their depth
            this.gameScene.remotePlayers.forEach((remotePlayer, id) => {
                console.log(`Setting depth for remotePlayer with ID ${id} to 2.`);
                remotePlayer.setDepth(DepthLevel.NON_PARTY_PLAYERS);
            });

            // Set isPartyFollower to false since this client is now the leader
            this.gameScene.player.isPartyFollower = false;

            console.log('[handlePartyPromotion] starting gamePadScene if needed');
            showGamePadSceneIfNeeded(this.gameScene);

        } else {
            console.log('Client is not the new party leader.');

            this.gameScene.player.isPartyFollower = true;

            console.log('[handlePartyPromotion] stopping gamePadScene if needed');
            hideGamePadSceneIfNeeded(this.gameScene);

            // If client player is not the new leader, adjust the depth of the new leader
            const newLeader = this.gameScene.remotePlayers.get(data.newPartyLeaderId);
            if (newLeader) {
                console.log(`Adjusting depth for new party leader with ID ${data.newPartyLeaderId} to 4.`);
                newLeader.setDepth(DepthLevel.PARTY_MEMBERS);
            } else {
                console.log(`New party leader with ID ${data.newPartyLeaderId} not found among remote players.`);
            }
        }

        console.log('=== End of Handle Party Promotion Event ===');
    }

    private attemptInteractResponseHandler(response: AttemptInteractResponseEvent): void {
        console.log(`Received attemptInteractResponse: ${JSON.stringify(response, null, 2)}`);
        if (response.success) {
            console.log('Interaction was successful');

            if (response.invitationDeclined) {
                console.log('An invitation was declined');
                this.gameScene.serverControlledGameUIScene.inviteNotification.dismiss();
            }

            if (response.duelDeclined) {
                console.log('A duel challenge was declined');
                this.gameScene.serverControlledGameUIScene.duelNotification.dismiss();
            }

            if (response.npcInteraction) {
                // Assert that response is of type NPCInteractionResponse
                const npcInteractionResponse = response as NPCInteractionResponse;
                const {
                    npcId,
                    npcType,
                    npcName,
                    initialDialogue,
                    roomPrice,
                    playerGold
                } = npcInteractionResponse.npcInteractionDetails;

                // setting inNPCDialogue will prevent the player from moving.
                console.log(`Successfully interacted with NPC: ${npcId}`);
                console.log(`NPC Type: ${npcType}`);
                console.log(`NPC Name: ${npcName}`);
                console.log(`Initial Dialogue: ${initialDialogue.text}`);

                console.log('[attemptInteractResponseHandler] inside npcInteraction branch - stopping gamePadScene if needed');
                hideGamePadSceneIfNeeded(this.gameScene);

                if (npcType === NPCVariants.Innkeeper) {
                    console.log(`Room Price: ${roomPrice}`);
                    this.gameScene.serverControlledGameUIScene.innkeeperNPCMenu.activate({
                        npcName,
                        dialogue: initialDialogue.text,
                        options: initialDialogue.options,

                        roomPrice: Number(roomPrice),
                        playerGold: playerGold ?? 0
                    });
                } else if (npcType === NPCVariants.Merchant) {
                    this.gameScene.serverControlledGameUIScene.merchantNPCMenu.activate({
                        npcName,
                        dialogue: initialDialogue.text,
                        options: initialDialogue.options,
                    });
                }

            }

            if (response.targetPlayer) {
                const playerResponse = response as TargetPlayerInteractionResponse;
                const targetPlayerData = {success: true, ...playerResponse.targetPlayerDetails};
                console.log('Targeted a player', playerResponse.targetPlayerDetails);
                this.handleTargetPlayer(targetPlayerData);
            }

            if (response.clearTarget) {
                console.log('Cleared current target');
                this.gameScene.serverControlledGameUIScene.targetMenu.dismiss();
            }

            if (response.terminateDialogue) {
                console.log('Terminated dialogue with an NPC');
                this.gameScene.serverControlledGameUIScene.commonerNPCMenu.dismiss();
                this.gameScene.serverControlledGameUIScene.innkeeperNPCMenu.dismiss();
                this.gameScene.serverControlledGameUIScene.merchantNPCMenu.dismiss();
                this.gameScene.player.inNPCDialogue = false;

                console.log('[attemptInteractResponseHandler] inside terminate dialogue branch - starting gamePadScene if needed');
                showGamePadSceneIfNeeded(this.gameScene);

            }

            if (response.interactableInteraction) {
                if (!response.closeInteractableInteraction) {
                    const signResponse = response as InteractableInteractionResponse;
                    console.log(`Read a sign with message: ${signResponse.text}`);
                    // Optionally display the sign's message in the game UI
                    this.gameScene.player.isInteractingWithInteractable = true;
                    this.gameScene.serverControlledGameUIScene.interactableMessageDisplay.activate(signResponse.text);
                    console.log('[attemptInteractResponseHandler] inside open interactableInteraction branch - stopping gamePadScene if needed');
                    hideGamePadSceneIfNeeded(this.gameScene);
                } else {
                    console.log('Closing sign interaction');
                    this.gameScene.player.isInteractingWithInteractable = false;
                    this.gameScene.serverControlledGameUIScene.interactableMessageDisplay.dismiss();
                    this.gameScene.serverControlledGameUIScene.interactionMenu.activate();
                    console.log('[attemptInteractResponseHandler] inside closeInteractableInteraction branch - starting gamePadScene if needed');
                    showGamePadSceneIfNeeded(this.gameScene);
                }
            }

        } else {
            console.log('Interaction failed');
        }
        this.gameScene.serverControlledGameUIScene.waitingForServerResponse = false;
    }

    private handleFieldPostTurnAction(): void {
        this.gameScene.serverControlledGameUIScene.messageElement.emitMenuDismissalRequest();
        this.gameScene.player.processingFieldAction = false;

        console.log('[handleFieldPostTurnAction] Field post-turn action completed.');
        showGamePadSceneIfNeeded(this.gameScene);

    }

    private handleFieldCriticalEffect(): void {
        console.log('[handleFieldCriticalEffect] A critical effect occurred.');
        this.gameScene.player.processingFieldAction = true; // Assuming a similar need to block actions during this time

        const criticalEffectText = 'A critical effect!'; // Specific message for a critical effect

        // Assuming your message system can handle these requests similarly to the pre-turn action
        this.gameScene.serverControlledGameUIScene.messageElement.emitMenuActivationRequest({
            topMessage: criticalEffectText,
            updateTopMessage: true,
            showTopMessage: true,
            middleMessage: '', // Intentionally left blank or modify as needed
            updateMiddleMessage: false, // Assuming no update to middle message here
            showMiddleMessage: false, // Assuming we don't want to show middle message here
            bottomMessage: '', // Intentionally left blank or modify as needed
            updateBottomMessage: false, // Assuming no update to bottom message here
            showBottomMessage: false // Assuming we don't want to show bottom message here
        });
    }

    private handleFieldMainTurnAction(data: FieldMainTurnActionEventData): void {
        console.log(`[handleFieldMainTurnAction] Received field main turn action: ${JSON.stringify(data, null, 2)}`);

        // Prepare the actionText with dynamic content
        let actionText = data.actionText;
        if (data.targetName) {
            actionText = actionText.replace('${actionTarget}', data.targetName);
        }
        if ('targetHpDelta' in data) {
            actionText = actionText.replace('${hpDelta}', `[color=#66ff66]${data.targetHpDelta.toString()}[/color]`);
        }
        if ('targetResourceDelta' in data) {
            actionText = actionText.replace('${resourceDelta}', `[color=#66ff66]${data.targetResourceDelta.toString()}[/color]`);
        }

        // Update only the middle message, keeping the top message as is, and ensuring both are visible
        this.gameScene.serverControlledGameUIScene.messageElement.activate({
            topMessage: '', // Empty string since we're not updating the top message
            updateTopMessage: false, // No update to the top message
            showTopMessage: true, // Keep the top message visible
            middleMessage: actionText, // Update the middle message with new action text
            updateMiddleMessage: true, // We want to update the middle message
            showMiddleMessage: true, // Ensure the middle message is visible
            bottomMessage: '', // Empty since we're not updating the bottom message
            updateBottomMessage: false, // No update to the bottom message
            showBottomMessage: false // Bottom message should not be visible
        });

        // Update player component UI as before
        const targetPlayerComponent = this.gameScene.serverControlledGameUIScene.gameActionBar.playerComponents.find(playerComponent => playerComponent.socketId === data.targetSocketId);
        if (targetPlayerComponent) {
            if ('targetHpDelta' in data) {
                targetPlayerComponent.hpText.setText(`HP: ${data.targetCurrentHP}/${data.targetMaxHP}`);
            }
            if ('targetResourceDelta' in data && 'targetResourceType' in data) {
                targetPlayerComponent.resourceText.setText(`${data.targetResourceType}: ${data.targetCurrentResource}/${data.targetMaxResource}`);
            }
        }

        // Optionally, handle actor's resource changes, if applicable
        const actingPlayerComponent = this.gameScene.serverControlledGameUIScene.gameActionBar.playerComponents.find(playerComponent => playerComponent.socketId === data.actorSocketId);
        if (actingPlayerComponent && 'actorCurrentResource' in data && 'actorResourceType' in data) {
            actingPlayerComponent.resourceText.setText(`${data.actorResourceType}: ${data.actorCurrentResource}/${data.actorMaxResource}`);
        }

    }

    private handleFieldPreTurnAction(data: {
        actorName: string;
        targetName: string;
        abilityIdentifier: string;
        abilityName: string;
        actionText: string;
    }): void {
        console.log(`[handleFieldPreTurnAction] Received field pre-turn action: ${JSON.stringify(data, null, 2)}`);
        this.gameScene.player.processingFieldAction = true;
        this.gameScene.player.isInteractingWithInteractable = false;

        // Replace actor and target placeholders in the action text
        let updatedActionText = data.actionText.replace('${actionUser}', data.actorName);
        if (data.targetName) {
            updatedActionText = updatedActionText.replace('${actionTarget}', data.targetName);
        }

        console.log('[handleFieldPreTurnAction] stopping gamePadScene if needed');
        hideGamePadSceneIfNeeded(this.gameScene);

        // Call activate with booleans indicating which messages to update
        this.gameScene.serverControlledGameUIScene.messageElement.emitMenuActivationRequest({
            topMessage: updatedActionText,
            updateTopMessage: true, // Always updating top message with action text
            showTopMessage: true, // Always showing top message
            middleMessage: '', // Intentionally clear or pass existing text to retain
            updateMiddleMessage: true, // Decided to update (clear or retain based on your logic)
            showMiddleMessage: false, // Decided to hide middle message
            bottomMessage: '', // Intentionally clear or pass existing text to retain
            updateBottomMessage: true, // Decided to update (clear or retain based on your logic)
            showBottomMessage: false // Decided to hide bottom message
        });
    }

    private handleAttemptArmFieldAbilityResponse(data: AttemptArmFieldAbilityResponseEvent): void {
        console.log('[handleAttemptArmFieldAbilityResponse] Received attempt arm field ability response:', data);

        if ('success' in data && data.success) {
            // Success: Populate and show the armed view with the relevant ability details
            // Assuming abilityMenu has methods for populating and showing the armed view
            this.gameScene.serverControlledGameUIScene.abilityMenu.populateArmedView(data);
            this.gameScene.serverControlledGameUIScene.abilityMenu.showArmedView();
        } else {
            // Failure: Log the failure, hide any unnecessary views, and display the error message
            console.error('Attempt to arm field ability failed:', data.message);
            // Optionally hide views that were meant to be transitioned from
            this.gameScene.serverControlledGameUIScene.abilityMenu.hideInitialView();
            this.gameScene.serverControlledGameUIScene.abilityMenu.hideDetailView();
            // Reset the armed state and update the UI as necessary
            this.gameScene.serverControlledGameUIScene.abilityMenu.abilityIsArmed = false;
            this.gameScene.serverControlledGameUIScene.gameActionBar.abilityMenuButton.deselect();
            this.gameScene.serverControlledGameUIScene.dismissibleNotification.activate(data.message);
        }
    }

    private handleAttemptSelectFieldAbilityResponse(data: AttemptFieldAbilitySelectionResponseEvent): void {
        if ('success' in data && data.success) {
            // Assuming data now contains the detailed info of the selected ability
            this.gameScene.serverControlledGameUIScene.abilityMenu.populateDetailView({
                abilityIndex: data.abilityIndex,
                abilityName: data.abilityName,
                abilityDescription: data.abilityDescription,
                abilityResourceCost: data.abilityResourceCost,
                abilityResourceType: data.abilityResourceType,
            });
            this.gameScene.serverControlledGameUIScene.abilityMenu.showDetailView();
        } else {
            console.error('Selection of ability failed, or data is incomplete.');
            // Handle failure or incomplete data case
        }
    }

    private handleAttemptSelectFieldInventoryItemResponse(data: AttemptFieldInventoryItemSelectionResponseEvent): void {
        if ('success' in data && data.success) {
            // Call populateDetailView with all required parameters
            this.gameScene.serverControlledGameUIScene.inventoryMenu.populateDetailViewForInventoryItem({
                inventoryItemIndex: data.inventoryItemIndex,
                inventoryItemName: data.inventoryItemName,
                inventoryItemDescription: data.inventoryItemDescription,
                type: data.type, // Make sure these additional fields are included in the response event
                stats: 'stats' in data ? data.stats : undefined,
                classes: 'classes' in data ? data.classes : undefined,
                minimumLevel: 'minimumLevel' in data ? data.minimumLevel : undefined,
                canBeUsedOrEquipped: data.canBeUsedOrEquipped,
                bindingType: data.bindingType,
            });
            this.gameScene.serverControlledGameUIScene.inventoryMenu.showDetailViewForInventoryItem();
        } else {
            console.error('Selection of inventory item failed, or data is incomplete.');
            // Handle failure or incomplete data case
        }
    }

    private handleAttemptSelectFieldEquipmentItemResponse(data: AttemptSelectFieldEquipmentItemResponseEvent): void {
        console.log('[handleAttemptSelectFieldEquipmentItemResponse] Received attempt select field equipment item response:', JSON.stringify(data, null, 2));
        if (data.success) {
            this.gameScene.serverControlledGameUIScene.inventoryMenu.populateDetailViewForEquippedItems(data);
            this.gameScene.serverControlledGameUIScene.inventoryMenu.showDetailViewForEquippedItem();
        }
    }

    private handleAttemptUseFieldAbilityOnTargetResponse(data: {success: boolean, message: string}): void {
        console.log(`[handleAttemptUseFieldAbilityOnTargetResponse] Received attempt use field ability on target response: ${JSON.stringify(data, null, 2)}`);
        if (!data.success) {
            console.error('Failed to use ability on target:', data.message);
            // Handle failure case
            this.gameScene.serverControlledGameUIScene.dismissibleNotification.activate(data.message);
        }
    }

    private handleAttemptUseFieldInventoryItemOnTargetResponse(data: {success: boolean, message: string}): void {
        console.log(`[handleAttemptUseFieldInventoryItemOnTargetResponse] Received attempt use field inventory item on target response: ${JSON.stringify(data, null, 2)}`);
        if (!data.success) {
            console.error('Failed to use inventory item on target:', data.message);
            // Handle failure case
            this.gameScene.serverControlledGameUIScene.dismissibleNotification.activate(data.message);
        }
    }

    private handleAttemptDropFieldInventoryItemResponse(data: {
        success: boolean;
        inventory: {
            name: string;
            key: UIImageKey;
            activeKey: UIImageKey;
        }[];
        gold: number;
    }): void {
        console.log(`[handleAttemptDropFieldInventoryItemResponse] Received attempt drop field inventory item response: ${JSON.stringify(data, null, 2)}`);
        if (data.success) {
            // Assuming inventoryMenu has a method for updating the inventory view
            this.gameScene.serverControlledGameUIScene.inventoryMenu.updateMainInventoryListAndGold(data);
        } else {
            console.error('Failed to drop inventory item:', data);
            // Handle failure case
        }
    }

    private handleAttemptUnequipFieldEquipmentItemResponse(data: AttemptUnequipFieldEquipmentItemResponseEvent): void {
        if (data.success) {
            let buttonToClear;
            switch (data.slotToClear) {
                case ItemType.Helmet:
                    buttonToClear = this.gameScene.serverControlledGameUIScene.inventoryMenu.equipmentButtonInfos.get(ItemType.Helmet);
                    break;
                case ItemType.BodyArmor:
                    buttonToClear = this.gameScene.serverControlledGameUIScene.inventoryMenu.equipmentButtonInfos.get(ItemType.BodyArmor);
                    break;
                case ItemType.Weapon:
                    buttonToClear = this.gameScene.serverControlledGameUIScene.inventoryMenu.equipmentButtonInfos.get(ItemType.Weapon);
                    break;
                case ItemType.OffHand:
                    buttonToClear = this.gameScene.serverControlledGameUIScene.inventoryMenu.equipmentButtonInfos.get(ItemType.OffHand);
                    break;
                default:
                    console.error('Invalid slot to clear:', data.slotToClear);
                    break;
            }
            if (buttonToClear) {
                buttonToClear.exists = false;
                buttonToClear.selected = false;
                buttonToClear.button.hideActionButton();
            }
            this.gameScene.serverControlledGameUIScene.inventoryMenu.hideDetailView();
        } else {
            if (data.dismissInventoryWithModal) {
                this.gameScene.serverControlledGameUIScene.inventoryMenu.dismiss();
                if (data.message) {
                    this.gameScene.serverControlledGameUIScene.dismissibleNotification.activate(data.message);
                }
            }
        }
    }

    private handleAttemptArmOrEquipFieldInventoryItemResponse(data: AttemptArmOrEquipFieldInventoryItemResponseEvent): void {
        console.log('[handleAttemptArmFieldInventoryItemResponse] Received attempt arm field inventory item response:', JSON.stringify(data, null, 2));

        if ('success' in data && data.success) {
            // Success: Populate and show the armed view with the relevant inventory item details
            // Assuming inventoryMenu has methods for populating and showing the armed view
            // this is for if they are arming the item
            if (data.action === 'arming') {
                this.gameScene.serverControlledGameUIScene.inventoryMenu.populateArmedView(data);
                this.gameScene.serverControlledGameUIScene.inventoryMenu.showArmedView();
            } else if (data.action === 'equipping') {
                // this is for if they are equipping the item
                console.log('[handleAttemptArmOrEquipFieldInventoryItemResponse] received equipping response');
                this.gameScene.serverControlledGameUIScene.inventoryMenu.updateMainInventoryList(data);
            }
        } else {
            // Failure: Log the failure, hide any unnecessary views, and display the error message
            console.error('Attempt to arm field inventory item failed:', data.message);
            // Optionally hide views that were meant to be transitioned from
            this.gameScene.serverControlledGameUIScene.inventoryMenu.hideInitialView();
            this.gameScene.serverControlledGameUIScene.inventoryMenu.hideDetailView();
            // Reset the armed state and update the UI as necessary
            this.gameScene.serverControlledGameUIScene.inventoryMenu.inventoryItemIsArmed = false;
            this.gameScene.serverControlledGameUIScene.gameActionBar.inventoryButton.deselect();
            this.gameScene.serverControlledGameUIScene.dismissibleNotification.activate(data.message);
        }
    }

    private handleToggleAbilityMenuResponse(attemptToggleAbilityMenuResponseEvent: AttemptToggleAbilityMenuResponseEvent): void {
        console.log('Received toggle ability menu response event:', JSON.stringify(attemptToggleAbilityMenuResponseEvent, null, 2));
        // Handle the response event here

        this.gameScene.player.isInteractingWithInteractable = false;

        // Ensure the event was successful before proceeding
        if (!('success' in attemptToggleAbilityMenuResponseEvent) || !attemptToggleAbilityMenuResponseEvent.success) {
            console.error('Toggle ability menu action failed.');
            this.gameScene.serverControlledGameUIScene.waitingForServerResponse = false;
            return;
        }

        switch (attemptToggleAbilityMenuResponseEvent.action) {
            case 'open':
                if ('fieldAbilityPreviewArray' in attemptToggleAbilityMenuResponseEvent) { // Additional check for type safety
                    // Close any open UI elements that might interfere with the ability menu

                    // Update and show the ability menu with the provided data
                    this.gameScene.serverControlledGameUIScene.abilityMenu.activate(attemptToggleAbilityMenuResponseEvent.fieldAbilityPreviewArray);

                    console.log('[handleToggleAbilityMenuResponse] within open case - starting gamePadScene if needed');
                    showGamePadSceneIfNeeded(this.gameScene);
                }
                break;
            case 'close':

                this.gameScene.serverControlledGameUIScene.abilityMenu.dismiss();
                this.gameScene.serverControlledGameUIScene.abilityMenu.abilityIsArmed = false; // Reset the armed state
                this.gameScene.serverControlledGameUIScene.abilityMenu.shown = false; // Assuming this controls the visibility of the entire menu
                this.gameScene.serverControlledGameUIScene.gameActionBar.abilityMenuButton.deselect();

                break;
            default:
                console.error('Unknown action received in toggle ability menu response.');
                break;
        }

        this.gameScene.serverControlledGameUIScene.waitingForServerResponse = false;
    }

    private handleToggleInventoryMenuResponse(attemptToggleInventoryMenuResponseEvent: AttemptToggleInventoryMenuResponseEvent): void {
        console.log('Received toggle inventory menu response event:', JSON.stringify(attemptToggleInventoryMenuResponseEvent, null, 2));
        // Handle the response event here

        this.gameScene.player.isInteractingWithInteractable = false;

        // Ensure the event was successful before proceeding
        if (!('success' in attemptToggleInventoryMenuResponseEvent) || !attemptToggleInventoryMenuResponseEvent.success) {
            console.error('Toggle inventory menu action failed.');
            this.gameScene.serverControlledGameUIScene.waitingForServerResponse = false;
            return;
        }

        switch (attemptToggleInventoryMenuResponseEvent.action) {
            case 'open':
                if ('inventory' in attemptToggleInventoryMenuResponseEvent) { // Additional check for type safety
                    // Close any open UI elements that might interfere with the inventory menu

                    // Update and show the inventory menu with the provided data
                    this.gameScene.serverControlledGameUIScene.inventoryMenu.activate({
                        itemArray: attemptToggleInventoryMenuResponseEvent.inventory,
                        gold: attemptToggleInventoryMenuResponseEvent.gold
                    });

                    console.log('[handleToggleInventoryMenuResponse] within open case - starting gamePadScene if needed');
                    showGamePadSceneIfNeeded(this.gameScene);

                }
                break;
            case 'close':

                this.gameScene.serverControlledGameUIScene.inventoryMenu.dismiss();

                break;
            default:
                console.error('Unknown action received in toggle inventory menu response.');
                break;
        }
        this.gameScene.serverControlledGameUIScene.waitingForServerResponse = false;
    }

    private handleSwitchFieldInventoryModeResponse(attemptSwitchFieldInventoryModeResponseEvent: AttemptSwitchFieldInventoryModeResponse): void {
        console.log(`[handleSwitchFieldInventoryModeResponse] Received switch field inventory mode response: ${JSON.stringify(attemptSwitchFieldInventoryModeResponseEvent, null, 2)}`);
        if (attemptSwitchFieldInventoryModeResponseEvent.success) {
            // Assuming the data is correctly structured
            if ('inventory' in attemptSwitchFieldInventoryModeResponseEvent) {
                // Update the inventory menu with the new mode and data
                this.gameScene.serverControlledGameUIScene.inventoryMenu.switchToMode(attemptSwitchFieldInventoryModeResponseEvent.mode, attemptSwitchFieldInventoryModeResponseEvent.inventory);
            } else if ('equipment' in attemptSwitchFieldInventoryModeResponseEvent) {
                // Update the inventory menu with the new mode and data
                this.gameScene.serverControlledGameUIScene.inventoryMenu.switchToMode(attemptSwitchFieldInventoryModeResponseEvent.mode, attemptSwitchFieldInventoryModeResponseEvent.equipment);
            }
        }
    }

    private handleToggleCharacterSheetResponse(attemptToggleCharacterSheetResponseEvent: AttemptToggleCharacterSheetResponseEvent): void {
        console.log('Received toggle character sheet response event:', JSON.stringify(attemptToggleCharacterSheetResponseEvent, null, 2));

        this.gameScene.player.isInteractingWithInteractable = false;

        // Ensure the event was successful before proceeding
        if (!('success' in attemptToggleCharacterSheetResponseEvent) || !attemptToggleCharacterSheetResponseEvent.success) {
            console.error('Toggle character sheet action failed.');
            this.gameScene.serverControlledGameUIScene.waitingForServerResponse = false;
            return;
        }

        switch (attemptToggleCharacterSheetResponseEvent.action) {
            case 'open':
                if ('name' in attemptToggleCharacterSheetResponseEvent) { // Additional check for type safety
                    // Close any open UI elements that might interfere with the character sheet

                    // Update and show the character sheet with the provided data
                    this.gameScene.serverControlledGameUIScene.characterSheetMenu.updateCharacterSheet(attemptToggleCharacterSheetResponseEvent);
                    this.gameScene.serverControlledGameUIScene.gameActionBar.characterSheetButton.select();
                    this.gameScene.serverControlledGameUIScene.characterSheetMenu.activate();

                    console.log('[handleToggleCharacterSheetResponse] within open case - starting gamePadScene if needed');
                    showGamePadSceneIfNeeded(this.gameScene);
                }
                break;
            case 'close':
                this.gameScene.serverControlledGameUIScene.gameActionBar.characterSheetButton.deselect();
                this.gameScene.serverControlledGameUIScene.characterSheetMenu.dismiss();
                break;
            default:
                console.error('Unknown action received in toggle character sheet response.');
                break;
        }
        this.gameScene.serverControlledGameUIScene.waitingForServerResponse = false;
    }

    private handleOngoingConflictReinsertion(data: ClientOngoingConflictState): void {
        console.log('Received ongoingConflictReinsertion event:', data);

        // Reset all players' borders and targeted status
        this.gameScene.player.hideBorder();
        this.gameScene.player.targeted = false;
        console.log('[LOG] Local player border and targeting reset.');

        let remotePlayersProcessed = 0;
        this.gameScene.remotePlayers.forEach((remotePlayer) => {
            if (remotePlayer) {
                remotePlayer.hideBorder();
                remotePlayer.targeted = false;
                remotePlayersProcessed++;
            }
        });

        console.log(`[LOG] Processed ${remotePlayersProcessed} remote players.`);

        // Identify Allies and Enemies based on socket id
        const clientSocketId = this.socket.id;

        const teamOneHasClient = data.TeamOne.some(player => player.SocketId === clientSocketId);

        let allyTeam: ClientTeamOfFullPlayers;
        let enemyTeam: ClientTeamOfTruncatedPlayers | ClientTeamOfMonsters;

        if (teamOneHasClient) {
            if (!this.isFullPlayersTeam(data.TeamOne)) {
                throw new Error('Team One is expected to be a full players team.');
            }
            allyTeam = data.TeamOne as ClientTeamOfFullPlayers; // Type assertion

            if (this.isTruncatedPlayersTeam(data.TeamTwo)) {
                enemyTeam = data.TeamTwo as ClientTeamOfTruncatedPlayers; // Type assertion
            } else if (this.isMonstersTeam(data.TeamTwo)) {
                enemyTeam = data.TeamTwo as ClientTeamOfMonsters; // Type assertion
            } else {
                throw new Error('Unable to determine the enemy team type in Team Two.');
            }
        } else {
            if (!this.isFullPlayersTeam(data.TeamTwo)) {
                throw new Error('Team Two is expected to be a full players team.');
            }
            allyTeam = data.TeamTwo as ClientTeamOfFullPlayers; // Type assertion

            if (this.isTruncatedPlayersTeam(data.TeamOne)) {
                enemyTeam = data.TeamOne as ClientTeamOfTruncatedPlayers; // Type assertion
            } else {
                throw new Error('Unable to determine the enemy team type in Team One.');
            }
        }

        const conflictSceneData: ConflictSceneData = {
            allies: allyTeam,
            enemies: enemyTeam,
            socket: this.socket,
            mapName: data.Map,
            isOngoingConflict: true,
            ongoingConflictState: data,
            hotkeyAssignments: data.HotkeyAssignments,
        };

        this.gameScene.serverControlledGameUIScene.menus.forEach(menu => menu.dismiss());

        this.gameScene.serverControlledConflictScene.activate(conflictSceneData);
        console.log('Activated ServerControlledConflict scene with ongoing conflict data.');
    }

    private handleCloseDialogue(): void {
        this.gameScene.serverControlledGameUIScene.playerInNPCSequence = true;
        this.gameScene.serverControlledGameUIScene.commonerNPCMenu.dismiss();
        this.gameScene.serverControlledGameUIScene.innkeeperNPCMenu.dismiss();
        this.gameScene.serverControlledGameUIScene.merchantNPCMenu.dismiss();
    }

    /**
     * Performs an initial update on all animated tiles within the game scene. This method is crucial for synchronizing
     * the animations to their correct starting frames based on the current frame of the game. It ensures that all
     * animated tiles display correctly from the onset of their appearance on the tilemap.
     *
     * Each animated tile is updated to reflect its next appropriate frame, calculated based on the current frame
     * count of the game. This synchronization is necessary to maintain visual consistency and accuracy, especially
     * after the tilemap has just been set up or when the game resumes from a paused state.
     *
     * @returns {void} This method does not return a value; instead, it directly modifies the properties of animated tiles
     *                 in the game scene, updating their displayed frame.
     *
     * Usage:
     * This method should be called immediately after initializing a tilemap with animations to ensure all animations
     * start correctly. It can also be used in any situation where animated tiles need to be reset or re-synchronized,
     * such as after loading new game levels or dynamically altering the tilemap's layout or visibility.
     */
    private initialUpdateAnimatedTiles(): void {
        // Iterate over each animated tile stored in the gameScene's animatedTiles array.
        // This array contains all tiles that have animations defined.
        this.gameScene.animatedTiles.forEach(animatedTile => {
            // Get the next frame index for the tile based on the current frame of the game.
            // This method calculates which frame should be displayed next, ensuring the animation is in sync with the game's timing.
            const nextFrameIndex = animatedTile.getNextFrame(this.gameScene.currentFrame);

            // Update the tile's displayed frame on the map.
            // This operation changes the visual appearance of the tile to the next frame in its animation sequence,
            // effectively updating the animation state to the correct frame.
            animatedTile.layer.putTileAt(nextFrameIndex, animatedTile.x, animatedTile.y);
        });
    }

    private handleHidePartyOrderMenu(): void {
        console.log('Received HidePartyOrderMenu event.');
        this.gameScene.serverControlledGameUIScene.partyOrderMenu.dismiss();
    }

    private handleChatBubble(data: { id: string, message: string }): void {
        console.log('Received chatBubble event:', data);
        // Use Object.hasOwnProperty.call to check for the property
        if (data.id === this.socket.id) {
            this.gameScene.player.showChatBubble(data.message);
        } else if (this.gameScene.remotePlayers.has(data.id)) {
            // If the message is from a known remote player
            const speaker = this.gameScene.remotePlayers.get(data.id);
            if (speaker) {
                speaker.showChatBubble(data.message); // Display the chat message
            }
        }
        // If the ID doesn't match the local or any remote players, don't show a bubble.
    }

    private initialPlayerNPCAndMapDataEventHandler(initData: InitialPlayerNPCAndMapDataEvent): void {
        console.log('Received initial data event. Beginning the process of spawning client player, other players, and NPCs.');
        console.log('initData:', JSON.stringify(initData, null, 2));

        // Spawn the client player first
        if (initData.players[this.socket.id]) {
            console.log(`Spawning client player with ID: ${this.socket.id}`);
            this.spawnClientPlayer(initData.players[this.socket.id]);
        } else {
            console.log(`No client player data found for socket ID: ${this.socket.id}`);
        }

        // Next, spawn other players
        console.log('Starting to spawn other players...');
        let otherPlayerCount = 0; // To track the number of other players spawned
        for (const playerId in initData.players) {
            if (playerId !== this.socket.id && !initData.players[playerId].inCombat) {
                console.log(`Spawning remote player with ID: ${playerId}`);
                this.spawnRemotePlayer(initData.players[playerId]);
                otherPlayerCount++;
            }
        }
        console.log(`Total other players spawned: ${otherPlayerCount}`);

        // Finally, spawn NPCs
        for (const npcId in initData.npcs) {
            this.spawnNPC(initData.npcs[npcId]);
        }

        for (const door of initData.doors) {
            console.log(`Spawning door with image: ${door.image}, x: ${door.position.x}, y: ${door.position.y}`);
            this.spawnDoor(door);
        }

        for (const sign of initData.signs) {
            this.spawnSign(sign);
        }

        if (initData.facingInteractable && !initData.inCombat) {
            // show the interaction menu
            this.updateInteractionMenuHandler(true);
        }

        console.log('All players and NPCs have been spawned. Signaling readiness to the server.');
        this.socket.emit(ServerSocketEvents.InitialPlayerNPCAndMapDataInitialized);
    }

    public updateInteractionMenuHandler(shouldShowMenu: boolean): void {
        console.log('[SocketEventHandler.updateInteractionMenuHandler] Entering method.');
        console.log(`[SocketEventHandler.updateInteractionMenuHandler] shouldShowMenu: ${shouldShowMenu}`);

        const scene = this.gameScene.serverControlledGameUIScene; // For easier reference

        console.log(`[SocketEventHandler.updateInteractionMenuHandler] Setting scene.interactionMenu.interactionMenuCanBeShown to ${shouldShowMenu}`);
        scene.interactionMenu.interactionMenuCanBeShown = shouldShowMenu;

        if (shouldShowMenu) {
            console.log('[SocketEventHandler.updateInteractionMenuHandler] Checking for blocking menus.');

            // Check if any menu that could block the interaction menu is currently shown
            const blockingMenus = scene.menus.filter(menu =>
                menu.shown && menu.relatedMenusToDismiss.has(scene.interactionMenu.menuTag)
            );

            const isBlocked = blockingMenus.length > 0;
            console.log(`[SocketEventHandler.updateInteractionMenuHandler] isBlocked: ${isBlocked}`);

            if (!isBlocked) {
                console.log('[SocketEventHandler.updateInteractionMenuHandler] No blocking menus detected. Showing interaction menu.');
                scene.interactionMenu.activate();
            } else {
                const blockingMenuTags = blockingMenus.map(menu => menu.menuTag).join(', ');
                console.log('[SocketEventHandler.updateInteractionMenuHandler] Interaction menu cannot be shown due to blocking menu(s).');
                console.log(`[SocketEventHandler.updateInteractionMenuHandler] Blocking menu tag(s): ${blockingMenuTags}`);
            }
        } else {
            console.log('[SocketEventHandler.updateInteractionMenuHandler] shouldShowMenu is false. Hiding interaction menu.');
            scene.interactionMenu.dismiss();
        }

        console.log('[SocketEventHandler.updateInteractionMenuHandler] Method execution complete.');
    }

    private togglePlayerVisibilityHandler(data: { visible: boolean, playerIds: string[] }): void {
        console.log('Received TogglePlayerVisibility event.');
        console.log('Visible:', data.visible);
        console.log('Player IDs:', data.playerIds);

        // Handle local player
        if (data.playerIds.includes(this.socket.id)) {
            this.gameScene.player.setVisible(data.visible);
        }

        // Handle remote players
        for (const playerId of data.playerIds) {
            const player = this.gameScene.remotePlayers.get(playerId);
            if (player) {
                player.setVisible(data.visible);
            }
        }
    }

    private screenStateHandler(data: { state: string }): void {
        console.log('Received ScreenState event.');
        console.log('ScreenState:', data.state);

        if (data.state === 'off') {
            // Set the screen to black.
            this.gameScene.serverControlledGameUIScene.screenCoverGraphics.fillStyle(0x000000, 1);
            this.gameScene.serverControlledGameUIScene.screenCoverGraphics.fillRect(0, 0, this.gameScene.serverControlledGameUIScene.scale.width, this.gameScene.serverControlledGameUIScene.scale.height);
        } else if (data.state === 'on') {
            // Set the screen to normal by clearing graphics.
            this.gameScene.serverControlledGameUIScene.screenCoverGraphics.clear();
        }
    }

    private terminateDialogueHandler(data: { success: boolean }): void {
        console.log('Received TerminateDialogue event.');
        if (data.success) {
            this.gameScene.serverControlledGameUIScene.playerInNPCSequence = false;
            this.gameScene.serverControlledGameUIScene.commonerNPCMenu.dismiss();
            this.gameScene.serverControlledGameUIScene.innkeeperNPCMenu.dismiss();
            this.gameScene.serverControlledGameUIScene.merchantNPCMenu.dismiss();
            this.gameScene.player.inNPCDialogue = false;

            console.log('[terminateDialogueHandler] inside success branch - starting gamePadScene if needed');
            showGamePadSceneIfNeeded(this.gameScene);

        }
    }

    private npcDialogueOptionResponseHandler(data: { success: boolean, dialogueNode: DialogueNode }): void {
        console.log('Received NPCDialogueOptionResponse event.');
        console.log('DialogueNode:', data.dialogueNode);
        if (data.success) {
            this.gameScene.serverControlledGameUIScene.commonerNPCMenu.updateDialogue(data.dialogueNode);

        }

    }

    private npcFacingDirectionChangedHandler(data: { npcId: string; newDirection: Direction }): void {
        // const turningNPC = this.gameScene.npcs[data.npcId];
        const turningNPC = this.gameScene.npcs.get(data.npcId);

        if (turningNPC) {
            turningNPC.facingDirection = data.newDirection;
            this.gameScene.updateSpriteFrame(turningNPC);
            console.log(`NPC with id ${data.npcId} is now facing ${data.newDirection}`);
        } else {
            console.warn(`Failed to find NPC with id ${data.npcId} for facing direction update.`);
        }
    }

    private interactWithNPCHandler(data: NPCInteractionPayload): void {
        console.log('Received NPC Interaction Data:', data);
        const {success, npcId, npcType, npcName, initialDialogue, roomPrice} = data;

        if (success) {

            // setting inNPCDialogue will prevent the player from moving.
            console.log(`Successfully interacted with NPC: ${npcId}`);
            console.log(`NPC Type: ${npcType}`);
            console.log(`NPC Name: ${npcName}`);
            console.log(`Initial Dialogue: ${initialDialogue.text}`);

            console.log('[interactWithNPCHandler] inside success branch - stopping gamePadScene if needed');
            hideGamePadSceneIfNeeded(this.gameScene);

            if (npcType === NPCVariants.Innkeeper) {
                console.log(`Room Price: ${roomPrice}`);
                this.gameScene.serverControlledGameUIScene.innkeeperNPCMenu.activate({
                    npcName,
                    dialogue: initialDialogue.text,
                    options: initialDialogue.options,
                    roomPrice: Number(roomPrice),
                    playerGold: data.playerGold ?? 0
                });
            } else if (npcType === NPCVariants.Merchant) {
                this.gameScene.serverControlledGameUIScene.merchantNPCMenu.activate({
                    npcName,
                    dialogue: initialDialogue.text,
                    options: initialDialogue.options,
                });
            }
        } else {
            console.log('Failed to interact with NPC.');
        }
    }

    private spawnDoor(door: ClientDoorData): void {
        console.log('Entering spawnDoor');
        // // Calculate the adjusted x-coordinate for the door based on the door's tile position.
        // // Multiply the door's tile x position by the tileSize to get the pixel x-coordinate,
        // // then adjust it based on the origin point set to 1 (right side of the tile).
        const adjustedX = (door.position.x * tileSize) + (tileSize / 2);
        console.log(`Adjusted door X: ${adjustedX}`);
        //
        // // Calculate the adjusted y-coordinate similarly by multiplying the tile y position by the tileSize,
        // // then adjust for half the tile size to align with the origin at the vertical center of the tile.
        const adjustedY = (door.position.y * tileSize) + tileSize;
        console.log(`Adjusted door Y: ${adjustedY}`);
        const doorImage = new DoorImage(
            this.gameScene,
            {
                x: adjustedX,
                y: adjustedY,
            },
            door.image,
            door.isOpen
        );

        // Add the newly created doorSprite to the game scene's doors array for management and rendering.
        this.gameScene.doors.push(doorImage);
    }

    private spawnSign(signData: ClientSignData): void {
        console.log('Entering spawnSign');

        // Calculate the adjusted x-coordinate for the sign based on the sign's tile position.
        const adjustedX = (signData.position.x * tileSize) + (tileSize / 2);
        console.log(`Adjusted sign X: ${adjustedX}`);

        // Calculate the adjusted y-coordinate similarly by multiplying the tile y position by the tileSize,
        // then adjust for the full tile size to align with the origin at the bottom of the tile.
        const adjustedY = (signData.position.y * tileSize) + tileSize;
        console.log(`Adjusted sign Y: ${adjustedY}`);

        const signImage = new SignImage(
            this.gameScene,
            {x: adjustedX, y: adjustedY},
            signData.image
        );

        // Add the newly created signSprite to the game scene's signs array for management and rendering.
        this.gameScene.signs.push(signImage);
        console.log(`Sign spawned at (${adjustedX}, ${adjustedY})`);
    }

    private handleAttemptDismissInteractableMessageResponse(): void {
        console.log('Received AttemptDismissInteractableMessageResponse event.');

        // Dismiss the interaction menu
        this.gameScene.serverControlledGameUIScene.interactableMessageDisplay.dismiss();
        this.gameScene.serverControlledGameUIScene.interactionMenu.activate();
        this.gameScene.player.isInteractingWithInteractable = false;
        showGamePadSceneIfNeeded(this.gameScene);
    }

    private spawnNPC(currentNPCsDatum: ClientNPCInfo): void {
        console.log('Entering spawnNPC');

        console.log(`Current NPC Datum: ${JSON.stringify(currentNPCsDatum, null, 2)}`);

        console.log(`Attempting to create an NPCSprite with id: ${currentNPCsDatum.id}`);

        const npc = new NPCSprite(
            this.gameScene,
            currentNPCsDatum.id,
            currentNPCsDatum.position.x,
            currentNPCsDatum.position.y,
            currentNPCsDatum.spriteKey,
            currentNPCsDatum.facingDirection as Direction, // assuming currentNPCsDatum has a 'facingDirection' property
        );

        console.log(`NPCSprite created successfully with id: ${npc.id}`); // assuming npc object has an 'id' property

        console.log(`Saving a reference to the NPCSprite with id: ${currentNPCsDatum.id}`);
        this.gameScene.npcs.set(currentNPCsDatum.id, npc); // Save a reference to the npc's sprite

        console.log(`NPC with id: ${currentNPCsDatum.id} successfully spawned.`);
    }

    private isFullPlayerData(obj: ClientFullPlayerData | ClientTruncatedPlayerData | ClientMonsterData): obj is ClientFullPlayerData {
        return obj.Type === 'FullPlayer';
    }

    private isFullPlayersTeam(team: ClientTeamOfFullPlayers | ClientTeamOfTruncatedPlayers | ClientTeamOfMonsters): team is ClientTeamOfFullPlayers {
        return (team as (ClientFullPlayerData | ClientTruncatedPlayerData | ClientMonsterData)[]).every(member => this.isFullPlayerData(member));
    }

    private isTruncatedPlayerData(obj: ClientFullPlayerData | ClientTruncatedPlayerData | ClientMonsterData): obj is ClientTruncatedPlayerData {
        return obj.Type === 'TruncatedPlayer';
    }

    private isTruncatedPlayersTeam(team: ClientTeamOfFullPlayers | ClientTeamOfTruncatedPlayers | ClientTeamOfMonsters): team is ClientTeamOfTruncatedPlayers {
        // Check the first element to determine the type of the whole team
        return (team as (ClientFullPlayerData | ClientTruncatedPlayerData | ClientMonsterData)[]).every(member => this.isTruncatedPlayerData(member));
    }

    private isMonsterData(obj: ClientFullPlayerData | ClientTruncatedPlayerData | ClientMonsterData): obj is ClientMonsterData {
        return obj.Type === 'Monster';
    }

    private isMonstersTeam(team: ClientTeamOfFullPlayers | ClientTeamOfTruncatedPlayers | ClientTeamOfMonsters): team is ClientTeamOfMonsters {
        // Check the first element to determine the type of the whole team
        // return 'Key' in team[0];
        return (team as (ClientFullPlayerData | ClientTruncatedPlayerData | ClientMonsterData)[]).every(member => this.isMonsterData(member));
    }

    private handleConflictInitiated(clientConflictInitStateData: ClientConflictInitState): void {
        console.log('[LOG] Conflict initiated event received:', clientConflictInitStateData);

        this.gameScene.player.isInteractingWithInteractable = false;

        // Reset all players' borders and targeted status
        this.gameScene.player.hideBorder();
        this.gameScene.player.targeted = false;
        console.log('[LOG] Local player border and targeting reset.');

        // Process remote players
        let remotePlayersProcessed = 0;
        this.gameScene.remotePlayers.forEach((remotePlayer) => {
            if (remotePlayer) {
                remotePlayer.hideBorder();
                remotePlayer.targeted = false;
                remotePlayersProcessed++;
            }
        });

        console.log(`[LOG] Processed ${remotePlayersProcessed} remote players.`);

        // Identify Allies and Enemies based on socket id
        const clientSocketId = this.socket.id;

        const teamOneHasClient = clientConflictInitStateData.TeamOne.some(player => player.SocketId === clientSocketId);

        let allyTeam: ClientTeamOfFullPlayers;
        let enemyPlayerTeam: ClientTeamOfTruncatedPlayers;
        let enemyMonsterTeam: ClientTeamOfMonsters;

        let conflictSceneData: ConflictSceneData;

        if (teamOneHasClient) {
            if (!this.isFullPlayersTeam(clientConflictInitStateData.TeamOne)) {
                throw new Error('Team One is expected to be a full players team.');
            }
            allyTeam = clientConflictInitStateData.TeamOne;
            if (this.isTruncatedPlayersTeam(clientConflictInitStateData.TeamTwo)) {
                enemyPlayerTeam = clientConflictInitStateData.TeamTwo;
                conflictSceneData = {
                    allies: allyTeam,
                    enemies: enemyPlayerTeam,
                    socket: this.socket,
                    mapName: clientConflictInitStateData.Map,
                    isOngoingConflict: false,
                    hotkeyAssignments: clientConflictInitStateData.HotkeyAssignments
                };
                if (clientConflictInitStateData.RoundTimer) {
                    conflictSceneData.roundTimer = clientConflictInitStateData.RoundTimer;
                }

                // this.scene.serverControlledGameUIScene.menus.forEach(menu => menu.dismiss());
                const menus = this.gameScene.serverControlledGameUIScene.menus;
                for (let i = 0; i < menus.length; i++) {
                    menus[i].dismiss();
                }

                this.gameScene.serverControlledConflictScene.activate(conflictSceneData);

            } else if (this.isMonstersTeam(clientConflictInitStateData.TeamTwo)) {
                enemyMonsterTeam = clientConflictInitStateData.TeamTwo;
                conflictSceneData = {
                    allies: allyTeam,
                    enemies: enemyMonsterTeam,
                    socket: this.socket,
                    mapName: clientConflictInitStateData.Map,
                    isOngoingConflict: false,
                    hotkeyAssignments: clientConflictInitStateData.HotkeyAssignments
                };
                if (clientConflictInitStateData.RoundTimer) {
                    conflictSceneData.roundTimer = clientConflictInitStateData.RoundTimer;
                }

                // this.scene.serverControlledGameUIScene.menus.forEach(menu => menu.dismiss());
                const menus = this.gameScene.serverControlledGameUIScene.menus;
                for (let i = 0; i < menus.length; i++) {
                    menus[i].dismiss();
                }

                this.gameScene.serverControlledConflictScene.activate(conflictSceneData);
            }
        } else {
            if (!this.isFullPlayersTeam(clientConflictInitStateData.TeamTwo)) {
                throw new Error('Team Two is expected to be a full players team.');
            }
            allyTeam = clientConflictInitStateData.TeamTwo;
            if (!this.isTruncatedPlayersTeam(clientConflictInitStateData.TeamOne)) {
                throw new Error('Team One is expected to be a truncated players team.');
            }
            enemyPlayerTeam = clientConflictInitStateData.TeamOne;
            conflictSceneData = {
                allies: allyTeam,
                enemies: enemyPlayerTeam,
                socket: this.socket,
                mapName: clientConflictInitStateData.Map,
                isOngoingConflict: false,
                hotkeyAssignments: clientConflictInitStateData.HotkeyAssignments
            };
            if (clientConflictInitStateData.RoundTimer) {
                conflictSceneData.roundTimer = clientConflictInitStateData.RoundTimer;
            }

            // this.scene.serverControlledGameUIScene.menus.forEach(menu => menu.dismiss());
            const menus = this.gameScene.serverControlledGameUIScene.menus;
            for (let i = 0; i < menus.length; i++) {
                menus[i].dismiss();
            }

            this.gameScene.serverControlledConflictScene.activate(conflictSceneData);
        }
    }

    private handleServerShutdown(data: { reason: string }): void {
        console.log(data.reason);
        this.handleGeneralDisconnection(data.reason);
    }

    private handleConnectionError(error: Error): void {
        console.error('Connection error:', error.message);
        this.handleGeneralDisconnection('Connection error.');
    }

    private handleReconnectFailed(): void {
        console.error('Reconnection attempts failed.');
        this.handleGeneralDisconnection('Failed to reconnect to the server.');
    }

    private handleDisconnect(reason: string): void {
        console.warn('Disconnected from the server:', reason);
        const message = (reason !== 'io server disconnect') ? 'Disconnected from server.' : reason;
        this.handleGeneralDisconnection(message);
    }

    private handleGeneralDisconnection(reason: string): void {
        if (this.disconnectionSceneRunning) {
            return; // If the scene is already running, skip the rest
        }

        // Freeze all active scenes
        this.gameScene.scene.manager.scenes.forEach(scene => {
            if (scene.scene.settings.active) {
                scene.scene.pause();
            }
        });

        // Disconnect the socket
        this.socket.disconnect();

        // Set the flag to true
        this.disconnectionSceneRunning = true;

        // Start a new scene
        this.gameScene.scene.run(SceneNames.Disconnection, {reason: reason});

        // Optionally, if you have a mechanism to leave the 'Disconnection' scene,
        // make sure to set this.disconnectionSceneRunning = false when you leave it.
    }

    private playersEnteredLevelHandler(incomingPlayers: MapEntryPlayerInfoArray): void {
        console.log('=== Players Entered Level ===');
        console.log(`Total incoming players: ${incomingPlayers.length}`);

        // Process the incoming player data
        incomingPlayers.forEach(player => {
            // Logging player details
            console.log('------ Player Details ------');
            console.log(`Name: ${player.name}`);
            console.log(`Socket ID: ${player.socketId}`);
            console.log(`Color: ${player.skinColor}`);
            console.log(`Facing Direction: ${player.facingDirection}`);
            console.log(`Position: x=${player.position.x}, y=${player.position.y}`);
            console.log(`Movement Direction: ${player.movementDirection}`);
            console.log(`Tile Position: x=${player.tilePos.x}, y=${player.tilePos.y}`);
            console.log(`In Combat: ${player.inCombat}`);
            console.log(`Targeted: ${player.targeted}`);
            console.log(`Targeted for Duel: ${player.targetedForDuel}`);
            console.log(`Targeted for Invite: ${player.targetedForInvite}`);
            console.log('----------------------------');

            this.spawnRemotePlayer(player);

            // Set the targeted, isTargetedForDuel, and isTargetedForInvite properties on remote players
            const remotePlayer = this.gameScene.remotePlayers.get(player.socketId);
            if (remotePlayer) {
                if (!player.visible) {
                    console.log(`Hiding player with socketId: ${player.socketId}`);
                    remotePlayer.setVisible(false);
                }
                remotePlayer.targeted = player.targeted;
                remotePlayer.isTargetedForDuel = player.targetedForDuel;
                remotePlayer.isTargetedForInvite = player.targetedForInvite;
            }
        });

        console.log('=== End of Players Entered Level ===');
    }

    private playerClickedHandler(socketId: string): void {
        console.log(`[PlayerClickedHandler] Player with socketId: ${socketId} clicked.`);
        if (this.gameScene.serverControlledGameUIScene.abilityMenu.abilityIsArmed) {
            console.log('Ability is armed. Attempting to USE ability on target player player.');
            const data = {
                abilityIndex: this.gameScene.serverControlledGameUIScene.abilityMenu.armedAbilityIndex,
                abilityPageNumber: this.gameScene.serverControlledGameUIScene.abilityMenu.armedAbilityPageNumber,
                targetSocketId: socketId
            };
            console.log(`[PlayerClickedHandler] Emitting AttemptUseFieldAbilityOnTarget with data: ${JSON.stringify(data, null, 2)}`);
            this.socket.emit(ServerSocketEvents.AttemptUseFieldAbilityOnTarget, data);
        } else if (this.gameScene.serverControlledGameUIScene.inventoryMenu.inventoryItemIsArmed) {
            // Preparing params for an armed inventory item
            const params = {
                inventoryItemIndex: this.gameScene.serverControlledGameUIScene.inventoryMenu.armedInventoryItemIndex,
                targetSocketId: socketId
            };
            console.log(`[GameActionBar - PlayerComponent Invisible Button Callback] Preparing to emit AttemptUseFieldInventoryItemOnTarget with data: ${JSON.stringify(params, null, 2)}`);
            this.socket.emit(ServerSocketEvents.AttemptUseFieldInventoryItemOnTarget, params);
        } else {
            console.log(`attempting to target ${socketId}`);
            this.gameScene.serverControlledGameUIScene.targetMenu.emitMenuActivationRequest({socketId: socketId});
        }
    }

    private enterNewMapHandler(clientSideEnterNewMapData: ClientSideEnterNewMapData): void {
        console.log('[EnterNewMapHandler] Received event with map data.');
        console.log(`[EnterNewMapHandler] Transitioning to map: '${clientSideEnterNewMapData.mapName}'.`);
        console.log('[EnterNewMapHandler] Map data:', JSON.stringify(clientSideEnterNewMapData, null, 2));

        // Log details for each player
        clientSideEnterNewMapData.players.forEach(player => {
            console.log(`[EnterNewMapHandler] Player Details - Name: ${player.name}, Position: (${player.position.x}, ${player.position.y}), Facing: ${player.facingDirection}`);
        });
        const currentPlayerData = clientSideEnterNewMapData.players.find(player => player.socketId === this.socket.id)!;

        // Filter out the current player from the data.players array
        const remotePlayersData = clientSideEnterNewMapData.players.filter(player => player.socketId !== this.socket.id);

        // Despawn NPCs.
        if (this.gameScene.npcs) { // Assuming npcs is now a Map with NPC IDs as keys
            // Use forEach to iterate over the Map
            this.gameScene.npcs.forEach((npc, npcId) => {
                if (npc) {
                    npc.setVisible(false); // Make the NPC invisible
                    // Add any NPC-specific event removal here if necessary
                    npc.destroy(); // Destroy the NPC object
                    this.gameScene.npcs.delete(npcId); // Properly remove the NPC from the Map
                }
            });
        }

        // Clear out NPC memory
        this.gameScene.npcs = new Map<string, NPCSprite>();

        // Spawn NPCs for the new map
        for (const npcData of clientSideEnterNewMapData.npcs) {
            this.spawnNPC(npcData);
        }

        // 1. Destroy all remote player sprites.
        this.gameScene.remotePlayers.forEach((playerSprite, playerId) => {
            playerSprite.setVisible(false);
            playerSprite.off('pointerdown');
            playerSprite.destroy();
            this.gameScene.remotePlayers.delete(playerId);
        });

        // 2. Clear out client remote player memory.
        // Assuming remotePlayers is a reference to the client's memory of remote players.
        this.gameScene.remotePlayers = new Map<string, PlayerSprite>();
        // Now use remotePlayersData for processing the other remote players
        remotePlayersData.forEach(remotePlayerDataEntry => {
            // Spawn or initialize the remote player using player data.
            if (!remotePlayerDataEntry.inCombat) {
                this.spawnRemotePlayer(remotePlayerDataEntry);

                // Set the targeted, isTargetedForDuel, and isTargetedForInvite properties on remote players
                const remotePlayer = this.gameScene.remotePlayers.get(remotePlayerDataEntry.socketId);
                if (remotePlayer) {
                    if (!remotePlayerDataEntry.visible) {
                        console.log(`[EnterNewMapHandler] Hiding player with socketId: ${remotePlayerDataEntry.socketId}`);
                        remotePlayer.setVisible(false);
                    }

                    remotePlayer.targeted = remotePlayerDataEntry.targeted;
                    remotePlayer.isTargetedForDuel = remotePlayerDataEntry.targetedForDuel;
                    remotePlayer.isTargetedForInvite = remotePlayerDataEntry.targetedForInvite;
                }
            }
        });

        // Teardown the current tilemap
        if (this.gameScene.currentTilemap) {
            for (const layer of this.gameScene.currentTilemap.layers) {
                if (layer.tilemapLayer) {
                    layer.tilemapLayer.destroy();
                }
            }
            this.gameScene.currentTilemap.destroy();
        }

        // destroy all doors
        this.gameScene.doors.forEach(door => {
            door.destroy();
        });
        this.gameScene.doors = [];

        // spawn the new doors
        for (const door of clientSideEnterNewMapData.doors) {
            console.log(`Spawning door with image: ${door.image}, x: ${door.position.x}, y: ${door.position.y}`);
            this.spawnDoor(door);
        }

        this.gameScene.signs.forEach(sign => {
            sign.destroy();
        });
        this.gameScene.signs = [];

        // spawn the new signs
        for (const sign of clientSideEnterNewMapData.signs) {
            console.log(`Spawning sign with image: ${sign.image}, x: ${sign.position.x}, y: ${sign.position.y}`);
            this.spawnSign(sign);
        }

        // Assuming 'data' is an object that contains 'mapName'
        if (clientSideEnterNewMapData.mapName) {
            this.initializeTilemapAndSetAnimations(clientSideEnterNewMapData.mapName);
        } else {
            console.error('Map name is undefined in data');
        }

        this.gameScene.player.setPosition(
            currentPlayerData.position.x,
            currentPlayerData.position.y
        );

        if (this.gameScene.playerInputController.pressedDirections.length === 0) {
            this.gameScene.player.facingDirection = currentPlayerData.facingDirection;
            this.socket.emit(ServerSocketEvents.CursorTurn, {direction: currentPlayerData.facingDirection});
        }

        if (this.gameScene.player.borderVisible) {
            this.gameScene.player.updateBorder(); // Update the border at the new location
        }

        // If you want to spawn a player/entity at the given coordinates,
        // you might need to add that logic here.
        this.gameScene.player.isSwitchingMaps = false;
        this.gameScene.updateSpriteFrame(this.gameScene.player);

        // log this.scene.playerInputController.pressedDirections
        console.log(`[enterNewMapHandler] this.scene.playerInputController.pressedDirections: ${this.gameScene.playerInputController.pressedDirections}`);
        console.log('[enterNewMapHandler] setting lastSentDirection to Direction.NONE');
        this.gameScene.playerInputController.lastSentDirection = Direction.NONE;
    }

    private playersLeavingMapHandler(playerLeavingIdArray: string[]): void {

        console.log(`Players leaving: ${playerLeavingIdArray.join(', ')}`);

        for (const playerLeavingId of playerLeavingIdArray) {

            const leavingPlayer = this.gameScene.remotePlayers.get(playerLeavingId);
            if (leavingPlayer) {
                leavingPlayer.destroy(); // This will also destroy all borders associated with the player
                this.gameScene.remotePlayers.delete(playerLeavingId);
            }

        }
    }

    private handlePartyReorderSubmitSuccess(success: boolean): void {
        if (success) {
            // Handle successful party reorder logic here
            console.log('Party reorder was successful!');
            this.gameScene.serverControlledGameUIScene.partyOrderMenu.dismiss();
        } else {
            // Handle failed party reorder logic here
            console.log('Party reorder failed.');
        }
    }

    private handleAttemptTogglePartyOrderMenuResponse(response: AttemptTogglePartyOrderMenuResponseEvent): void {

        this.gameScene.player.isInteractingWithInteractable = false;

        if (response.success) {
            console.log(`Party menu toggle action '${response.action}' was successful.`);
            if (response.action === 'open' && response.data) {
                // Handle the 'open' action: Update and activate the party order menu with received data
                console.log('Received party data from server:', response.data);
                this.gameScene.serverControlledGameUIScene.partyOrderMenu.handleUpdatePartyMembers(response.data);
                this.gameScene.serverControlledGameUIScene.partyOrderMenu.activate();
            } else if (response.action === 'close') {
                // Handle the 'close' action: Potentially deactivate or hide the party order menu
                console.log('Party order menu close request acknowledged by server.');
                // Add any needed logic to deactivate/hide the party order menu here
                this.gameScene.serverControlledGameUIScene.partyOrderMenu.dismiss();
            }
        } else {
            // Handle failure: Log an error or display an error message to the user
            console.error('Failed to toggle the party order menu due to server-side error.');
            // Add any logic needed to notify the user of the failure
        }
        this.gameScene.serverControlledGameUIScene.waitingForServerResponse = false;
    }

    private handleUpdatePartyMembers(partyMembers: PlayerDataArray): void {
        console.log(`[handleUpdatePartyMembers] partyMembers: ${JSON.stringify(partyMembers, null, 2)}`);

        this.gameScene.serverControlledGameUIScene.menus.forEach(menu => menu.dismiss());
        this.gameScene.serverControlledGameUIScene.gameActionBar.handleUpdatePartyMembers(partyMembers);

    }

    private handlePartyJoinSuccess(eventData: { partyLeaderId: string }): void {
        // Do whatever you need to do here
        console.log(`Successfully joined party led by ${eventData.partyLeaderId}`);

        console.log('[handlePartyJoinSuccess] starting game pad scene if needed');
        hideGamePadSceneIfNeeded(this.gameScene);

        this.gameScene.serverControlledGameUIScene.inviteNotification.dismiss();
        // setting the depth of the party leader higher that the player
        const partyLeader = this.gameScene.remotePlayers.get(eventData.partyLeaderId);
        if (partyLeader) {
            partyLeader.setDepth(DepthLevel.PARTY_MEMBERS);
        }

        // If this player is not the party leader, disable their direct control
        if (this.socket.id !== eventData.partyLeaderId) {
            this.gameScene.player.isPartyFollower = true;
        }
    }

    private handlePartyLeaveSuccess(): void {
        console.log('Successfully left party');

        // Loop through each remotePlayer in this.scene.remotePlayers
        this.gameScene.remotePlayers.forEach((remotePlayer) => {
            remotePlayer.setDepth(DepthLevel.NON_PARTY_PLAYERS);
        });

        if (this.gameScene.player.isPartyFollower) {
            // commented this out for testing purposes
            this.gameScene.player.isPartyFollower = false;
            console.log('[handlePartyLeaveSuccess] within party follower if statement - starting game pad scene if needed');
            showGamePadSceneIfNeeded(this.gameScene);
        }
    }

    private handleInviteReceived({inviterName}: { inviterName: string }): void {

        this.gameScene.player.isInteractingWithInteractable = false;
        this.gameScene.serverControlledGameUIScene.inviteNotification.activate(`You have been invited to join ${inviterName}'s party.`);

        console.log('[SocketEventHandler.handleInviteReceived] starting game pad scene if needed');
        showGamePadSceneIfNeeded(this.gameScene);

    }

    private handleInvitationDeclined(): void {
        // Code to close the invitation menu on the client side goes here.
        this.gameScene.serverControlledGameUIScene.inviteNotification.dismiss();
    }

    private handleInviteResponse({
        success,
        action,
        targetId
    }: { success: boolean; action?: string; targetId?: string }): void {
        if (success) {
            switch (action) {
                case 'inviteIssued':
                    if (targetId) {
                        const targetPlayer = this.gameScene.remotePlayers.get(targetId);
                        if (targetPlayer) {
                            targetPlayer.isTargetedForInvite = false;
                            targetPlayer.targeted = false;
                            this.gameScene.serverControlledGameUIScene.targetMenu.dismiss();
                        }
                    }
                    break;
                case 'inviteArmed':
                    if (targetId) {
                        const targetPlayer = this.gameScene.remotePlayers.get(targetId);
                        if (targetPlayer) {
                            targetPlayer.isTargetedForInvite = true;
                            this.gameScene.serverControlledGameUIScene.targetMenu.dismiss();
                        }
                    }

                    break;

                default:
                    console.error('unexpected action in inviteResponse:', action);
                    break;
            }
        } else {
            console.log('invite could not be initiated');
        }
    }

    private handleConflictWinners(data: { winners: ClientPlayerRespawnInfo[] }): void {
        console.log('Received conflictWinners event:', data);

        data.winners.forEach(winnerInfo => {
            const {
                socketId,
                position,
                facingDirection,
                skinColor,
                hairColor,
                primaryColor,
                secondaryColor,
                name,
                gender,
                className
            } = winnerInfo;

            if (socketId === this.socket.id) {
                // Current player logic remains the same
                this.gameScene.player.inConflict = false;
                this.gameScene.player.setVisible(true);
                this.gameScene.playerInputController.pressedDirections = [];
                this.gameScene.playerInputController.lastSentDirection = Direction.NONE;
                this.gameScene.player.setPosition(position.x, position.y);
                this.gameScene.player.facingDirection = facingDirection;
                this.gameScene.updateSpriteFrame(this.gameScene.player);

            } else {
                // Instantiate new remote player and add to the game
                const playerSpriteParameters: PlayerSpriteParameters = {
                    scene: this.gameScene,
                    x: position.x,
                    y: position.y,
                    primaryColorTexture: SpriteSheet.HeroClothesPrimary,
                    secondaryColorTexture: SpriteSheet.HeroClothesSecondary,
                    frame: 0,
                    facingDirection: facingDirection,
                    playerName: name,
                    skinColor: Number(skinColor),
                    hairColor: Number(hairColor),
                    primaryColor: Number(primaryColor),
                    secondaryColor: Number(secondaryColor),
                    gender: gender,
                    className: className,
                    inConflict: false,
                    visible: true,
                };
                const remotePlayer = new PlayerSprite(playerSpriteParameters);
                remotePlayer.setDepth(DepthLevel.NON_PARTY_PLAYERS);
                remotePlayer.facingDirection = facingDirection;
                this.gameScene.updateSpriteFrame(remotePlayer);

                this.gameScene.remotePlayers.set(socketId, remotePlayer);

                remotePlayer.setInteractive();
                remotePlayer.on('pointerdown', this.playerClickedHandler.bind(this, socketId));
            }
        });
    }

    private handleConflictLosers(data: { losers: ClientPlayerRespawnInfo[] }): void {
        console.log('Received conflictLosers event:', data);

        data.losers.forEach(loserInfo => {
            const {
                socketId,
                position,
                facingDirection,
                skinColor,
                hairColor,
                primaryColor,
                secondaryColor,
                name,
                gender,
                className
            } = loserInfo;

            if (socketId === this.socket.id) {
                // Logic for the current player
                this.gameScene.player.inConflict = false;
                this.gameScene.player.setVisible(true);
                this.gameScene.playerInputController.pressedDirections = [];
                this.gameScene.playerInputController.lastSentDirection = Direction.NONE;
                this.gameScene.player.facingDirection = facingDirection;
                this.gameScene.updateSpriteFrame(this.gameScene.player);
            } else {
                const existingRemotePlayer = this.gameScene.remotePlayers.get(socketId);
                if (existingRemotePlayer) {
                    return;
                }
                // Instantiate new remote loser player and add to the game
                const playerSpriteParameters: PlayerSpriteParameters = {
                    scene: this.gameScene,
                    x: position.x,
                    y: position.y,
                    primaryColorTexture: SpriteSheet.HeroClothesPrimary,
                    secondaryColorTexture: SpriteSheet.HeroClothesSecondary,
                    frame: 0,
                    facingDirection: facingDirection,
                    playerName: name,
                    skinColor: Number(skinColor),
                    hairColor: Number(hairColor),
                    primaryColor: Number(primaryColor),
                    secondaryColor: Number(secondaryColor),
                    gender: gender,
                    className: className,
                    inConflict: false,
                    visible: true,
                };

                const remotePlayer = new PlayerSprite(playerSpriteParameters);
                remotePlayer.setDepth(DepthLevel.NON_PARTY_PLAYERS);

                remotePlayer.facingDirection = facingDirection;
                this.gameScene.updateSpriteFrame(remotePlayer);

                this.gameScene.remotePlayers.set(socketId, remotePlayer);

                remotePlayer.setInteractive();
                remotePlayer.on('pointerdown', this.playerClickedHandler.bind(this, socketId));
            }
            // Update UI for the loser with the specified socket ID
        });
    }

    private handleInviteCancelled(data: string): void {
        this.gameScene.serverControlledGameUIScene.inviteNotification.dismiss();
        this.gameScene.serverControlledGameUIScene.dismissibleNotification.activate(data);
    }

    private handleDuelCancelled(data: string): void {
        console.log('Received duelCancelled event:', data);

        // Hide any existing duel notification
        this.gameScene.serverControlledGameUIScene.duelNotification.dismiss();

        // Show the dismissable notification with the received data
        this.gameScene.serverControlledGameUIScene.dismissibleNotification.activate(data);
    }

    private handleConflictInitiatedBroadcast(conflictParticipantIds: string[]): void {
        // Immediately destroy remote conflict participant IDs upon conflict initiation. This simplifies
        //  post-match cleanup by avoiding the need to track and remove these players from clients' remotePlayers arrays,
        //  especially when players could change maps during combat. After the battle, only re-instantiate victorious
        //  players on the original map for relevant clients. Losing team players need not be despawned as they are
        //  pre-emptively destroyed.

        console.log('Conflict initiated broadcast received:', conflictParticipantIds);

        // Check for undefined array
        if (!conflictParticipantIds) {
            console.error('Received undefined conflictParticipantIds.');
            return;
        }

        // Check if the current player is one of the conflict participants
        if (conflictParticipantIds.includes(this.socket.id)) {
            this.gameScene.player.inConflict = true;
            this.gameScene.player.setVisible(false);
        }

        // Check remote players
        conflictParticipantIds.forEach(playerId => {
            const remotePlayer = this.gameScene.remotePlayers.get(playerId);
            if (remotePlayer) {
                remotePlayer.destroy();
                this.gameScene.remotePlayers.delete(playerId);
            }
        });
    }

    private handleDuelDeclined(): void {
        console.log('Received duelDeclined event');

        // Dismiss the notification
        this.gameScene.serverControlledGameUIScene.duelNotification.dismiss();
    }

    private handlePlayerInCombat(eventData: { playerId: string, inCombat: boolean }[]): void {
        eventData.forEach((event) => {
            const isVisible = !event.inCombat;

            if (event.playerId === this.socket.id) {
                // Update the visibility of the client player
                this.gameScene.player.setVisible(isVisible);
                this.gameScene.player.inConflict = event.inCombat;
                if (isVisible === false) {
                    this.gameScene.player.borderVisible = false;
                    this.gameScene.player.hideBorder();
                    this.gameScene.player.targeted = false;
                }

            } else {
                // Update the visibility of a remote player
                const remotePlayer = this.gameScene.remotePlayers.get(event.playerId);
                if (remotePlayer) {
                    remotePlayer.setVisible(isVisible);
                    remotePlayer.inConflict = event.inCombat;
                    if (isVisible === false) {
                        remotePlayer.borderVisible = false;
                        remotePlayer.hideBorder();
                    }
                }
            }
        });
    }

    private handlePlayerTurned(eventData: { playerId: string, direction: Direction }): void {
        console.log(`[handlePlayerTurned] Received event data: ${JSON.stringify(eventData)}`);

        if (!eventData || !eventData.playerId) {
            console.log('[handlePlayerTurned] Invalid event data or player ID missing.');
            return;
        }
        if (eventData.playerId === this.socket.id) {
            console.log(`[handlePlayerTurned] Handling turn for client player with ID ${eventData.playerId}`);
            // Handle turn for the client player
            this.gameScene.player.facingDirection = eventData.direction;
            this.gameScene.updateSpriteFrame(this.gameScene.player);
        } else {
            console.log(`[handlePlayerTurned] Handling turn for remote player with ID ${eventData.playerId}`);
            // Handle turn for a remote player
            const remotePlayer = this.gameScene.remotePlayers.get(eventData.playerId);
            if (remotePlayer) {
                console.log('[handlePlayerTurned] Found remote player. Updating facing direction.');
                remotePlayer.facingDirection = eventData.direction;
                this.gameScene.updateSpriteFrame(remotePlayer);
            } else {
                console.log(`[handlePlayerTurned] Remote player with ID ${eventData.playerId} not found.`);
            }
        }
    }

    private handlePlayerDisconnected(socketId: string): void {
        const disconnectedPlayer = this.gameScene.remotePlayers.get(socketId);
        if (disconnectedPlayer) {
            disconnectedPlayer.destroy(); // This will also destroy all borders associated with the player
            this.gameScene.remotePlayers.delete(socketId);
        }
    }

    private handlePartyMoved(eventDataArray: Array<PlayerMovementData>): void {
        for (const eventData of eventDataArray) {
            const playerIsCurrentClient = eventData.playerId === this.socket.id;
            const player = playerIsCurrentClient ? this.gameScene.player : this.gameScene.remotePlayers.get(eventData.playerId);

            if (!player) {
                console.warn(`Player ${eventData.playerId} not found on this client.`);
                continue;
            }

            player.x = eventData.newPosition.x;
            player.y = eventData.newPosition.y;
            if (player.borderVisible) {
                player.updateBorder(); // Update the border at the new location
            }

            // Update chat frame position and size, and chat text position
            player.updateChatFramePositionAndSize();
            player.updateChatBubblePosition();

            if (eventData.facingDirection !== Direction.NONE) {
                player.facingDirection = eventData.facingDirection;
                this.gameScene.updateSpriteFrame(player); // Update sprite frame if direction is not 'none'
            }

            if (playerIsCurrentClient) {
                this.gameScene.lastPosition = new Vector2(player.x, player.y); // Update the last known position
            }
        }
    }

    private handlePlayerMoved(eventData: PlayerMovementData): void {
        const playerIsCurrentClient = eventData.playerId === this.socket.id;
        const player = playerIsCurrentClient ? this.gameScene.player : this.gameScene.remotePlayers.get(eventData.playerId);

        if (!player) {
            console.warn(`Player ${eventData.playerId} not found on this client.`);
            return;
        }

        player.x = eventData.newPosition.x;
        player.y = eventData.newPosition.y;
        if (player.borderVisible) {
            player.updateBorder(); // Update the border at the new location
        }

        player.updateChatFramePositionAndSize();
        player.updateChatBubblePosition();

        if (eventData.facingDirection !== Direction.NONE) {
            player.facingDirection = eventData.facingDirection;
            this.gameScene.updateSpriteFrame(player); // Update sprite frame if direction is not 'none'
        }

        // console.log(`[Exiting Method: handlePlayerMoved] - player id: ${eventData.playerId} // player facingDirection: ${eventData.facingDirection}`);
        if (playerIsCurrentClient) {
            this.gameScene.lastPosition = new Vector2(player.x, player.y); // Update the last known position
        }
    }

    private handleDoorStateChanged(data: DoorStateChangedData): void {
        console.log(`DoorStateChanged event received: ${JSON.stringify(data, null, 2)}`);
        const door = this.gameScene.doors[data.doorIndex];
        if (door) {
            console.log(`Door with index ${data.doorIndex} found. Updating state to ${data.isOpen ? 'open' : 'closed'}.`);
            door.setVisible(!data.isOpen);

            // Check if the player is facing the door
            if (data.playerIsFacingDoor) {
            // If the door is open, disable interaction menu
                if (data.isOpen) {
                    this.gameScene.serverControlledGameUIScene.interactionMenu.interactionMenuCanBeShown = false;
                    this.gameScene.serverControlledGameUIScene.interactionMenu.dismiss();
                } else if (!data.isOpen) {
                // If the door is closed, enable interaction menu
                    this.gameScene.serverControlledGameUIScene.interactionMenu.interactionMenuCanBeShown = true;
                    this.gameScene.serverControlledGameUIScene.interactionMenu.activate();
                }
            }

            // Additional check for player proximity and directionality
            if (data.playerIsOneTileAway && this.gameScene.playerInputController.pressedDirections.includes(data.doorRelativeDirection)) {
                this.gameScene.playerInputController.lastSentDirection = Direction.NONE;
            // Optionally reset or adjust player position or state based on the door interaction
            // Example: maybe stop the player from moving towards the door
            }
        } else {
            console.log(`Door with index ${data.doorIndex} not found.`);
        }
    }

}
