export const secondaryColors = [
    '0xffd700', // Gold
    '0xff4500', // Orange Red
    '0xdaa520', // Goldenrod
    '0xb03060', // Maroon
    '0x4682b4', // Steel Blue
    '0x2e8b57', // Sea Green
    '0x708090', // Slate Gray
    '0x483d8b', // Dark Slate Blue
];
