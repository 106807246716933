// src/client/app/classes/TilesAndEnvironment/AnimatedTile.ts

export class AnimatedTile {
    public animationId: number;
    public frames: number[];
    public layer: Phaser.Tilemaps.TilemapLayer;
    public x: number;
    public y: number;

    public constructor(animationId: number, animationFrames: number[], layer: Phaser.Tilemaps.TilemapLayer, x: number, y: number) {
        this.animationId = animationId;
        this.frames = animationFrames;
        this.layer = layer;
        this.x = x;
        this.y = y;
    }

    // Get the frame based on the currentFrame provided by the game scene
    public getNextFrame(currentFrame: number): number {
        return this.frames[currentFrame % this.frames.length];
    }
}
