import Phaser from 'phaser';
import {Socket} from 'socket.io-client';
import {SpriteSheet, UIImageKey} from '../../../../types/assets/AssetKeys';
import {ClientSocketEvents} from '../../../../types/events/ClientSocketEvents';
import {Direction} from '../../../../types/physics/Direction';
import SocketManager from '../../classes/NetworkingAndChat/SocketManager';
import NonContainerUIActionButton from '../../classes/UserInterface/ActionButtons/NonContainerUIActionButton';
import ResizableFrame from '../../classes/UserInterface/UtilityComponents/ResizableFrame';
import {hairColors} from '../../data/hairColors';
import {heroWeaponColor} from '../../data/heroWeaponColor';
import {primaryColors} from '../../data/primaryColors';
import {secondaryColors} from '../../data/secondaryColors';
import {skinTones} from '../../data/skinTones';
import {tertiaryColor} from '../../data/tertiaryColor';
import {mainGameFont} from '../../GameConfig';
import {DepthLevel} from '../../types/DepthLevel';
import {PlayerCustomizationData} from '../../types/PlayerCustomizationData';
import {SceneNames} from '../../types/SceneNames';

export default class CharacterCustomizationScene extends Phaser.Scene {
    private acceptButton: NonContainerUIActionButton;
    private accumulatedTime: number;
    private backgroundRectangle: Phaser.GameObjects.Rectangle;
    private commandFrame: ResizableFrame;
    private currentFrame: 0 | 1;
    private facingDirection: Direction;
    private frameRate: number = 2;
    private hairColorLabel: Phaser.GameObjects.Text;
    private hairColorPaletteFrame: ResizableFrame;
    private heroDisplay: Phaser.GameObjects.Sprite;
    private heroMaleHairDisplay: Phaser.GameObjects.Sprite;
    private heroFemaleHairDisplay: Phaser.GameObjects.Sprite;
    private heroPrimaryClothesDisplay: Phaser.GameObjects.Sprite;
    private heroSecondaryClothesDisplay: Phaser.GameObjects.Sprite;
    private heroTertiaryClothesDisplay: Phaser.GameObjects.Sprite;
    private heroRunebladeWeaponBottomDisplay: Phaser.GameObjects.Sprite;
    private heroRunebladeWeaponTopDisplay: Phaser.GameObjects.Sprite;
    private heroAethermancerWeaponBottomDisplay: Phaser.GameObjects.Sprite;
    private heroAethermancerWeaponTopDisplay: Phaser.GameObjects.Sprite;
    private heroLifeWeaverWeaponBottomDisplay: Phaser.GameObjects.Sprite;
    private heroLifeWeaverWeaponTopDisplay: Phaser.GameObjects.Sprite;
    private leftArrowButton: Phaser.GameObjects.Image;
    private primaryColorLabel: Phaser.GameObjects.Text;
    private primaryColorPaletteFrame: ResizableFrame;
    private rightArrowButton: Phaser.GameObjects.Image;
    private rightSideOptionsFrame: ResizableFrame;
    private secondaryColorLabel: Phaser.GameObjects.Text;
    private secondaryColorPaletteFrame: ResizableFrame;
    private selectedHairColorIndex: number; // Index of the currently selected hair color
    private selectedPrimaryColorIndex: number;
    private selectedSecondaryColorIndex: number;
    private selectedSkinToneIndex: number; // Index of the currently selected skin tone
    private skinToneLabel: Phaser.GameObjects.Text;
    private skinTonePaletteFrame: ResizableFrame;
    private genderFrame: ResizableFrame;
    private maleButton: NonContainerUIActionButton;
    private femaleButton: NonContainerUIActionButton;
    private selectedClassIndex: number;
    private selectedGenderIndex: number;
    private rejectButton: NonContainerUIActionButton;
    private diceButton: NonContainerUIActionButton;
    private diceFrame: ResizableFrame;
    private socket: Socket;
    private disconnectionSceneRunning: boolean;

    public constructor() {
        super(SceneNames.CharacterCustomization);
        this.socket = SocketManager.getInstance().socket;
    }

    public create(data: PlayerCustomizationData): void {
        console.log('PlayerCustomizationScene create called');

        // Now it's guaranteed that data is defined and has all properties
        this.selectedSkinToneIndex = data.selectedSkinToneIndex;
        this.selectedHairColorIndex = data.selectedHairColorIndex;
        this.selectedPrimaryColorIndex = data.selectedPrimaryColorIndex;
        this.selectedSecondaryColorIndex = data.selectedSecondaryColorIndex;
        this.selectedClassIndex = data.selectedClassIndex;
        this.selectedGenderIndex = data.selectedGenderIndex;
        this.accumulatedTime = data.accumulatedTime;
        this.currentFrame = data.currentFrame;

        this.facingDirection = Direction.DOWN;

        this.backgroundRectangle = this.add.rectangle(
            0,
            0,
            this.scale.width,
            this.scale.height,
            0xbcbcbc
        )
            .setOrigin(0, 0);
        this.commandFrame = new ResizableFrame(
            this,
            345,
            665,
            645, // Replace with the desired width
            75 // Replace with the desired height
        );

        this.genderFrame= new ResizableFrame(
            this,
            540,
            175,
            70,
            110
        );

        this.maleButton = new NonContainerUIActionButton(
            this,
            540,
            150,
            UIImageKey.MaleButton,
            UIImageKey.MaleActiveButton,
            '',
            () => {
                this.updateGender(0);
            }
        );
        this.maleButton.select();

        this.femaleButton = new NonContainerUIActionButton(
            this,
            540,
            200,
            UIImageKey.FemaleButton,
            UIImageKey.FemaleActiveButton,
            '',
            () => {
                this.updateGender(1);
            }
        );

        this.diceFrame = new ResizableFrame(
            this,
            130,
            175,
            70,
            70
        );

        this.diceButton = new NonContainerUIActionButton(
            this,
            130,
            175,
            UIImageKey.DiceButton,
            UIImageKey.DiceButton,
            '',
            () => {
                this.randomize();
            }
        );

        this.rightSideOptionsFrame = new ResizableFrame(
            this,
            790,
            442,
            200,
            120
        );

        this.acceptButton = new NonContainerUIActionButton(
            this,
            720,
            415,
            UIImageKey.CheckButton,
            UIImageKey.CheckButton,
            'Accept',
            () => this.acceptCustomization() // Call acceptCustomization when the button is clicked
        );
        this.acceptButton.setDepth(DepthLevel.UI_PRIMARY_GRAPHICS_SUB);

        this.rejectButton = new NonContainerUIActionButton(
            this,
            720,
            465,
            UIImageKey.CrossButton,
            UIImageKey.CrossButton,
            'Reject',
            () => this.rejectCustomization() // Call rejectCustomization when the button is clicked
        );

        this.input.keyboard!.on('keydown-ENTER', () => {
            this.acceptCustomization();
        });

        this.heroRunebladeWeaponBottomDisplay = this.add.sprite(335, 175, SpriteSheet.RunebladeWeaponBottom)
            .setScale(3)
            .setTint(parseInt(heroWeaponColor))
            .setVisible(false);
        this.heroAethermancerWeaponBottomDisplay = this.add.sprite(335, 175, SpriteSheet.AethermancerWeaponBottom)
            .setScale(3)
            .setTint(parseInt(heroWeaponColor))
            .setVisible(false);
        this.heroLifeWeaverWeaponBottomDisplay = this.add.sprite(335, 175, SpriteSheet.LifeweaverWeaponBottom)
            .setScale(3)
            .setTint(parseInt(heroWeaponColor))
            .setVisible(false);
        switch (this.selectedClassIndex) {
            case 0:
                this.heroRunebladeWeaponBottomDisplay.setVisible(true);
                break;
            case 1:
                this.heroAethermancerWeaponBottomDisplay.setVisible(true);
                break;
            case 2:
                this.heroLifeWeaverWeaponBottomDisplay.setVisible(true);
                break;
            default:
                break;
        }

        this.heroDisplay = this.add.sprite(335, 175, SpriteSheet.HeroBaseSprite)
            .setScale(3)
            .setTint(parseInt(skinTones[this.selectedSkinToneIndex]));
        this.heroPrimaryClothesDisplay = this.add.sprite(335, 175, SpriteSheet.HeroClothesPrimary)
            .setScale(3)
            .setTint(parseInt(primaryColors[this.selectedPrimaryColorIndex]));

        this.heroSecondaryClothesDisplay = this.add.sprite(335, 175, SpriteSheet.HeroClothesSecondary)
            .setScale(3)
            .setTint(parseInt(secondaryColors[this.selectedSecondaryColorIndex]));

        this.heroTertiaryClothesDisplay = this.add.sprite(335, 175, SpriteSheet.HeroClothesTertiary)
            .setScale(3)
            .setTint(parseInt(tertiaryColor));

        this.heroMaleHairDisplay = this.add.sprite(335, 175, SpriteSheet.HeroHairMale)
            .setScale(3)
            .setTint(parseInt(hairColors[this.selectedHairColorIndex]))
            .setVisible(false);

        this.heroFemaleHairDisplay = this.add.sprite(335, 175, SpriteSheet.HeroHairFemale)
            .setScale(3)
            .setTint(parseInt(hairColors[this.selectedHairColorIndex]))
            .setVisible(false);

        switch (this.selectedGenderIndex) {
            case 0:
                this.updateGender(0);
                break;
            case 1:
                this.updateGender(1);
                break;
            default:
                break;
        }

        this.heroRunebladeWeaponTopDisplay = this.add.sprite(335, 175, SpriteSheet.RunebladeWeaponTop)
            .setScale(3)
            .setTint(parseInt(heroWeaponColor))
            .setVisible(false);
        this.heroAethermancerWeaponTopDisplay = this.add.sprite(335, 175, SpriteSheet.AethermancerWeaponTop)
            .setScale(3)
            .setTint(parseInt(heroWeaponColor))
            .setVisible(false);
        this.heroLifeWeaverWeaponTopDisplay = this.add.sprite(335, 175, SpriteSheet.LifeweaverWeaponTop)
            .setScale(3)
            .setTint(parseInt(heroWeaponColor))
            .setVisible(false);
        switch (this.selectedClassIndex) {
            case 0:
                this.heroRunebladeWeaponTopDisplay.setVisible(true);
                break;
            case 1:
                this.heroAethermancerWeaponTopDisplay.setVisible(true);
                break;
            case 2:
                this.heroLifeWeaverWeaponTopDisplay.setVisible(true);
                break;
            default:
                break;
        }

        this.updateSpritesFrames();

        this.leftArrowButton = this.add.image(230, 175, UIImageKey.LeftArrowButton)
            .setScale(2)
            .setInteractive()
            .on('pointerdown', () => {
                // Rotate the facing direction counterclockwise when the left arrow is clicked
                this.rotateFacingDirection(false);
                // Update sprite frames to reflect the new direction
                this.updateSpritesFrames();
                console.log('Left arrow button clicked');
            });

        this.rightArrowButton = this.add.image(440, 175, UIImageKey.RightArrowButton)
            .setScale(2)
            .setInteractive()
            .on('pointerdown', () => {
                // Rotate the facing direction clockwise when the right arrow is clicked
                this.rotateFacingDirection(true);
                // Update sprite frames to reflect the new direction
                this.updateSpritesFrames();
                console.log('Right arrow button clicked');
            });

        this.add.text(
            65,
            625,
            'Choose thy visage.',
            {
                fontSize: '70px',
                color: '#fff',
                fontFamily: mainGameFont
            }
        )
            .setDepth(DepthLevel.UI_PRIMARY_TEXT);

        // Instantiate the skin tone palette frame
        this.skinTonePaletteFrame = new ResizableFrame(
            this,
            345,
            305,
            645,
            75
        );

        // Starting X position for the first skin tone rectangle
        let currentX = 322;

        // Create a rectangle for each skin tone
        skinTones.forEach((tone) => {
            this.add.rectangle(
                currentX,
                305, // Y position same as the palette frame
                37, // Width of each rectangle
                50, // Height of each rectangle
                parseInt(tone, 16) // Parse the hex string to a color value
            )
                .setVisible(true)
                .setDepth(DepthLevel.UI_PRIMARY_GRAPHICS_SUB)
                .setInteractive() // Make it clickable
                .on('pointerdown', () => this.updateSkinTone(skinTones.indexOf(tone))); // Update skin tone on click

            // Move to the next position with a 43px gap
            currentX += 43;
        });

        this.skinToneLabel = this.add.text(
            35,
            305,
            'Skin Tone',
            {
                fontSize: '70px',
                color: '#fff',
                fontFamily: mainGameFont
            }
        )

            .setOrigin(0, 0.5)
            .setDepth(DepthLevel.UI_PRIMARY_TEXT);

        // Instantiate the hair color palette frame below the skin tone frame
        this.hairColorPaletteFrame = new ResizableFrame(
            this,
            345,
            395, // Adjust the Y position to place it below the skin tone frame
            645,
            75
        );

        // Starting X position for the first hair color rectangle
        let hairColorX = 322;

        // Create a rectangle for each hair color
        hairColors.forEach((color, index) => {
            this.add.rectangle(
                hairColorX,
                395, // Y position adjusted for hair color palette
                37, // Width of each rectangle
                50, // Height of each rectangle
                parseInt(color, 16) // Parse the hex string to a color value
            )
                .setVisible(true)
                .setDepth(DepthLevel.UI_PRIMARY_GRAPHICS_SUB)
                .setInteractive() // Make it clickable
                .on('pointerdown', () => this.updateHairColor(index)); // Update hair color on click

            // Move to the next position with a 43px gap
            hairColorX += 43;
        });

        // Add a label for the hair color selection
        this.hairColorLabel = this.add.text(
            35,
            395, // Adjust the Y position to match the hair color palette
            'Hair Color',
            {
                fontSize: '70px',
                color: '#fff',
                fontFamily: mainGameFont
            }
        )
            .setOrigin(0, 0.5)
            .setDepth(DepthLevel.UI_PRIMARY_TEXT);

        // Instantiate the primary color palette frame below the skin tone frame
        this.primaryColorPaletteFrame = new ResizableFrame(
            this,
            345,
            485, // Adjust the Y position to place it below the skin tone frame
            645,
            75
        );

        // Starting X position for the first primary color rectangle
        let primaryColorX = 322;

        // Create a rectangle for each primary color
        primaryColors.forEach((color, index) => {
            this.add.rectangle(
                primaryColorX,
                485, // Y position adjusted for primary color palette
                37, // Width of each rectangle
                50, // Height of each rectangle
                parseInt(color, 16) // Parse the hex string to a color value
            )
                .setVisible(true)
                .setDepth(DepthLevel.UI_PRIMARY_GRAPHICS_SUB)
                .setInteractive() // Make it clickable
                .on('pointerdown', () => this.updatePrimaryColor(index)); // Update primary color on click

            // Move to the next position with a 43px gap
            primaryColorX += 43;
        });

        // Add a label for the primary color selection
        this.primaryColorLabel = this.add.text(
            35,
            485, // Adjust the Y position to match the primary color palette
            'Main Color',
            {
                fontSize: '70px',
                color: '#fff',
                fontFamily: mainGameFont
            }
        )
            .setOrigin(0, 0.5)
            .setDepth(DepthLevel.UI_PRIMARY_TEXT);

        // Instantiate the secondary color palette frame below the skin tone frame
        this.secondaryColorPaletteFrame = new ResizableFrame(
            this,
            345,
            575, // Adjust the Y position to place it below the skin tone frame
            645,
            75
        );

        // Starting X position for the first secondary color rectangle
        let secondaryColorX = 322;

        // Create a rectangle for each secondary color
        secondaryColors.forEach((color, index) => {
            this.add.rectangle(
                secondaryColorX,
                575, // Y position adjusted for secondary color palette
                37, // Width of each rectangle
                50, // Height of each rectangle
                parseInt(color, 16) // Parse the hex string to a color value
            )
                .setVisible(true)
                .setDepth(DepthLevel.UI_PRIMARY_GRAPHICS_SUB)
                .setInteractive() // Make it clickable
                .on('pointerdown', () => this.updateSecondaryColor(index)); // Update secondary color on click

            // Move to the next position with a 43px gap
            secondaryColorX += 43;
        });

        // Add a label for the secondary color selection
        this.secondaryColorLabel = this.add.text(
            35,
            575, // Adjust the Y position to match the secondary color palette
            'Alt. Color',
            {
                fontSize: '70px',
                color: '#fff',
                fontFamily: mainGameFont
            }
        )
            .setOrigin(0, 0.5)
            .setDepth(DepthLevel.UI_PRIMARY_TEXT);

        this.input.keyboard!.on('keydown-LEFT', () => {
            this.rotateFacingDirection(false);
            this.updateSpritesFrames();
        });
        this.input.keyboard!.on('keydown-RIGHT', () => {
            this.rotateFacingDirection(true);
            this.updateSpritesFrames();
        });
        this.input.keyboard!.on('keydown-ESC', () => {
            this.rejectCustomization();
        });
        this.input.keyboard!.on('keydown-UP', () => {
            this.updateGender(this.selectedGenderIndex === 0 ? 1 : 0);
        });
        this.input.keyboard!.on('keydown-DOWN', () => {
            this.updateGender(this.selectedGenderIndex === 0 ? 1 : 0);
        });
        this.setupSocketListeners();
    }

    private setupSocketListeners(): void {
        this.socket.on(ClientSocketEvents.ServerShutdown, (response: { reason: string }) => {
            console.log('Server shutdown:', response);
            const reason = response.reason;
            if (this.disconnectionSceneRunning) {
                return; // If the scene is already running, skip the rest
            }
            this.scene.pause();

            // Disconnect the socket
            this.socket.disconnect();

            // Set the flag to true
            this.disconnectionSceneRunning = true;

            // Start a new scene
            this.scene.run(SceneNames.Disconnection, {reason: reason});
        });
    }

    private tearDownListeners(): void {
        this.socket.off(ClientSocketEvents.ServerShutdown);
    }

    private updateSecondaryColor(newIndex: number): void {
        this.selectedSecondaryColorIndex = newIndex;
        this.heroSecondaryClothesDisplay.setTint(parseInt(secondaryColors[this.selectedSecondaryColorIndex]));
    }

    private updatePrimaryColor(newIndex: number): void {
        this.selectedPrimaryColorIndex = newIndex;
        this.heroPrimaryClothesDisplay.setTint(parseInt(primaryColors[this.selectedPrimaryColorIndex]));
    }

    private updateHairColor(newIndex: number): void {
        this.selectedHairColorIndex = newIndex;
        this.heroMaleHairDisplay.setTint(parseInt(hairColors[this.selectedHairColorIndex]));
        this.heroFemaleHairDisplay.setTint(parseInt(hairColors[this.selectedHairColorIndex]));
    }

    // Method to update the hero's skin tone
    private updateSkinTone(newIndex: number): void {
        this.selectedSkinToneIndex = newIndex;
        this.heroDisplay.setTint(parseInt(skinTones[this.selectedSkinToneIndex]));
    }

    public update(time: number, delta: number): void {
        this.accumulatedTime += delta;

        if (this.accumulatedTime >= 1000 / this.frameRate) {
            this.accumulatedTime -= 1000 / this.frameRate;
            this.currentFrame = this.currentFrame === 0 ? 1 : 0;
            this.updateSpritesFrames();
        }
    }

    private updateSpritesFrames(): void {
        // Update the frame for each part of the hero sprite
        this.updateSpriteFrame(this.heroDisplay);
        this.updateSpriteFrame(this.heroMaleHairDisplay);
        this.updateSpriteFrame(this.heroFemaleHairDisplay);
        this.updateSpriteFrame(this.heroPrimaryClothesDisplay);
        this.updateSpriteFrame(this.heroSecondaryClothesDisplay);
        this.updateSpriteFrame(this.heroTertiaryClothesDisplay);
        this.updateSpriteFrame(this.heroRunebladeWeaponBottomDisplay);
        this.updateSpriteFrame(this.heroRunebladeWeaponTopDisplay);
        this.updateSpriteFrame(this.heroAethermancerWeaponBottomDisplay);
        this.updateSpriteFrame(this.heroAethermancerWeaponTopDisplay);
        this.updateSpriteFrame(this.heroLifeWeaverWeaponBottomDisplay);
        this.updateSpriteFrame(this.heroLifeWeaverWeaponTopDisplay);
    }

    public updateSpriteFrame(sprite: Phaser.GameObjects.Sprite): void {
        if (!sprite) {
            return; // Return early if sprite doesn't exist
        }

        let frameBase: number;
        // Use this.facingDirection since it's a property on the scene
        switch (this.facingDirection) {
            case Direction.DOWN:
                frameBase = 0; // Assuming downward frames are 0 and 1
                break;
            case Direction.UP:
                frameBase = 6; // Assuming upward frames are 6 and 7
                break;
            case Direction.LEFT:
                frameBase = 2; // Assuming leftward frames are 2 and 3
                break;
            case Direction.RIGHT:
                frameBase = 4; // Assuming rightward frames are 4 and 5
                break;
            default:
                frameBase = 0; // Default to downward frames
                break;
        }
        sprite.setFrame(frameBase + this.currentFrame);
    }

    private rotateFacingDirection(clockwise: boolean): void {
        const directions = [Direction.UP, Direction.LEFT, Direction.DOWN, Direction.RIGHT];
        let currentDirectionIndex = directions.indexOf(this.facingDirection);
        if (clockwise) {
            currentDirectionIndex = (currentDirectionIndex + 1) % directions.length;
        } else {
            currentDirectionIndex = (currentDirectionIndex - 1 + directions.length) % directions.length;
        }
        this.facingDirection = directions[currentDirectionIndex];
    }

    private acceptCustomization(): void {
        const playerCustomizationData: PlayerCustomizationData = {
            selectedSkinToneIndex: this.selectedSkinToneIndex,
            selectedHairColorIndex: this.selectedHairColorIndex,
            selectedPrimaryColorIndex: this.selectedPrimaryColorIndex,
            selectedSecondaryColorIndex: this.selectedSecondaryColorIndex,
            selectedClassIndex: this.selectedClassIndex,
            selectedGenderIndex: this.selectedGenderIndex,
            accumulatedTime: this.accumulatedTime,
            currentFrame: this.currentFrame
        };
        this.tearDownListeners();
        this.scene.start(SceneNames.CharacterName, playerCustomizationData);
    }

    private updateGender(genderIndex: 0 | 1): void {
        if (genderIndex === 0) {
            console.log('updateGender called with Male genderIndex');
            this.selectedGenderIndex = 0;
            this.femaleButton.deselect();
            this.maleButton.select();
            this.heroFemaleHairDisplay.setVisible(false);
            this.heroMaleHairDisplay.setVisible(true);
        } else if (genderIndex === 1) {
            console.log('updateGender called with Female genderIndex');
            this.selectedGenderIndex = 1;
            this.maleButton.deselect();
            this.femaleButton.select();
            this.heroMaleHairDisplay.setVisible(false);
            this.heroFemaleHairDisplay.setVisible(true);
        }
    }

    private rejectCustomization(): void {
        const customizationData: PlayerCustomizationData = {
            selectedSkinToneIndex: this.selectedSkinToneIndex,
            selectedHairColorIndex: this.selectedHairColorIndex,
            selectedPrimaryColorIndex: this.selectedPrimaryColorIndex,
            selectedSecondaryColorIndex: this.selectedSecondaryColorIndex,
            selectedClassIndex: this.selectedClassIndex,
            selectedGenderIndex: this.selectedGenderIndex,
            accumulatedTime: this.accumulatedTime,
            currentFrame: this.currentFrame
        };

        this.tearDownListeners();
        this.scene.start(
            SceneNames.CharacterClassSelection,
            customizationData
        );
    }

    private randomize(): void {
        this.selectedSkinToneIndex = Math.floor(Math.random() * skinTones.length);
        this.selectedHairColorIndex = Math.floor(Math.random() * hairColors.length);
        this.selectedPrimaryColorIndex = Math.floor(Math.random() * primaryColors.length);
        this.selectedSecondaryColorIndex = Math.floor(Math.random() * secondaryColors.length);
        this.selectedGenderIndex = Math.floor(Math.random() * 2);
        this.heroDisplay.setTint(parseInt(skinTones[this.selectedSkinToneIndex]));
        this.heroMaleHairDisplay.setTint(parseInt(hairColors[this.selectedHairColorIndex]));
        this.heroFemaleHairDisplay.setTint(parseInt(hairColors[this.selectedHairColorIndex]));
        this.heroPrimaryClothesDisplay.setTint(parseInt(primaryColors[this.selectedPrimaryColorIndex]));
        this.heroSecondaryClothesDisplay.setTint(parseInt(secondaryColors[this.selectedSecondaryColorIndex]));
        this.heroTertiaryClothesDisplay.setTint(parseInt(tertiaryColor));
        switch (this.selectedGenderIndex) {
            case 0:
                this.updateGender(0);
                break;
            case 1:
                this.updateGender(1);
                break;
            default:
                break;
        }
        this.updateSpritesFrames();
    }
}
