// src/client/app/data/mapInfo.ts

import {Images} from '../../../types/assets/AssetKeys';

export interface MapTilesetInfo {
    tilesetName: string;
    imageKey: Images;
}

// Mapping of map names to their corresponding tileset names and image keys
export const mapInfo: { [mapName: string]: MapTilesetInfo } = {
    'town': {tilesetName: 'Basic Tiles', imageKey: Images.Tiles},
    'overworld': {tilesetName: 'overworld', imageKey: Images.OverworldTiles},
    'sallowfen': {tilesetName: 'sallowfen', imageKey: Images.SallowfenTiles},
    'sallowfeninnlevelone': {tilesetName: 'sallowfen_inn_level_1', imageKey: Images.SallowfenInnLevelOne},
    'sallowfeninnleveltwo': {tilesetName: 'inn_extruded', imageKey: Images.SallowfenInnLevelTwo},
    'sallowfenshopone': {tilesetName: 'shop_interior', imageKey: Images.ShopInterior},
    'sallowfenshoptwo': {tilesetName: 'shop_2_interior', imageKey: Images.Shop2Interior},
    'caveoneroomone': {tilesetName: 'cave_1_room_1', imageKey: Images.CaveTiles},
    'caveoneroomtwo': {tilesetName: 'cave_1_room_2', imageKey: Images.CaveTiles},
    'caveoneroomthree': {tilesetName: 'cave_1_room_3', imageKey: Images.CaveTiles},
    'caveoneroomfour': {tilesetName: 'cave_1_room_4', imageKey: Images.CaveTiles},
    // ... add other maps as needed
};

// You can add more properties or methods if needed
