import {UIImageKey} from '../../../../../types/assets/AssetKeys';
import {mainGameFont} from '../../../GameConfig';
import {DepthLevel} from '../../../types/DepthLevel';

export default class NonContainerUIActionButton {
    public scene: Phaser.Scene;
    public button: Phaser.GameObjects.Image;
    public buttonText: Phaser.GameObjects.Text;
    public invisibleButton: Phaser.GameObjects.Rectangle;

    public constructor(
        scene: Phaser.Scene,
        public x: number,
        public y: number,
        public key: UIImageKey,
        public activeKey: UIImageKey,
        public text: string,
        public targetCallback: () => void
    ) {
        this.scene = scene;
        this.createButton();
    }

    public deselect(): void {
        // Log the existence and key of the scene
        console.log(`Scene exists: ${this.scene !== undefined}, Scene key: ${this.scene ? this.scene.scene.key : 'N/A'}`);

        // log if sys exists
        console.log(`Scene.sys exists: ${(this.scene.sys !== undefined)}`);

        // Log the current state of 'this' object and 'this.key'
        console.log('deselect called', {key: this.key, activeKey: this.activeKey, text: this.text, x: this.x, y: this.y});

        // Check if this.button is nullish
        console.log(`this.button is ${this.button ? 'not nullish' : 'nullish'}`);

        // Log the texture key of the button before setting a new one.
        console.log('Current button texture key:', this.button?.texture.key);

        try {
            if (this.button) {
                this.button.setTexture(this.key);
            }
        } catch (error) {
            console.error('Error setting texture key:', error);
        }
    }

    public select(): void {
        if (this.button) {
            this.button.setTexture(this.activeKey);
        }
    }

    public showActionButton(): void {
        this.button.setVisible(true);
        this.buttonText?.setVisible(true);
        this.invisibleButton?.setVisible(true);
    }

    public hideActionButton(): void {
        this.button.setVisible(false);
        this.buttonText?.setVisible(false);
        this.invisibleButton?.setVisible(false);
    }

    public destroyUIActionButton(): void {
        this.button.destroy();
        this.buttonText?.destroy();
        this.invisibleButton?.destroy();
    }

    public changeButtonText(text: string): void {
        this.buttonText?.setText(text);
        this.invisibleButton?.setSize(this.buttonText.width, this.buttonText.height - 5);
    }

    public setDepth(depth: number): void {
        this.button.setDepth(depth);
        this.buttonText?.setDepth(depth);
        this.invisibleButton?.setDepth(depth);
    }

    public changeButtonImage(key: UIImageKey, activeKey: UIImageKey): void {
        this.key = key;
        this.activeKey = activeKey;
        if (this.button) {
            this.button.setTexture(this.key);
        }
    }

    private createButton(): void {
        this.button = this.scene.add.image(this.x, this.y, this.key);

        this.button.setInteractive();

        this.button.setScale(2);
        this.button.setDepth(DepthLevel.UI_PRIMARY_GRAPHICS_SUB); // Set hardcoded depth

        if (this.text) {
            this.buttonText = this.scene.add.text(
                this.x + 20,
                this.y - 25,
                this.text,
                {
                    fontSize: '50px',
                    color: '#fff',
                    fontFamily: mainGameFont
                }
            );
            this.buttonText.setResolution(3);
            this.buttonText.setInteractive();
            this.buttonText.setDepth(DepthLevel.UI_PRIMARY_TEXT); // Set hardcoded depth

            this.invisibleButton = this.scene.add.rectangle(
                this.buttonText.x,
                this.buttonText.y,
                this.buttonText.width,
                this.buttonText.height - 5,
                0xFF0000,
                0
            )
                .setOrigin(0, 0)
                .setInteractive()
                .setDepth(DepthLevel.UI_PRIMARY_OVERLAY) // Set hardcoded depth
                .on('pointerdown', () => {
                    this.targetCallback();
                });
        }

        this.button.on('pointerdown', () => {
            this.targetCallback();
        });
    }

}
