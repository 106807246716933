import {Socket} from 'socket.io-client';
import {SpriteSheet, UIImageKey} from '../../../../types/assets/AssetKeys';
import {ClientSocketEvents} from '../../../../types/events/ClientSocketEvents';
import {NewCharacterDetailsResponseEvent} from '../../../../types/events/NewCharacterDetailsResponseEvent';
import {ServerSocketEvents} from '../../../../types/events/ServerSocketEvents';
import {Direction} from '../../../../types/physics/Direction';
import {NewCharacterDetails} from '../../../../types/playerInfo/NewCharacterDetails';
import SocketManager from '../../classes/NetworkingAndChat/SocketManager';
import NonContainerUIActionButton from '../../classes/UserInterface/ActionButtons/NonContainerUIActionButton';
import ResizableFrame from '../../classes/UserInterface/UtilityComponents/ResizableFrame';
import {VirtualKeyboard} from '../../classes/VirtualKeyboard/VirtualKeyboard';
import {hairColors} from '../../data/hairColors';
import {heroWeaponColor} from '../../data/heroWeaponColor';
import {primaryColors} from '../../data/primaryColors';
import {secondaryColors} from '../../data/secondaryColors';
import {skinTones} from '../../data/skinTones';
import {tertiaryColor} from '../../data/tertiaryColor';
import {mainGameFont} from '../../GameConfig';
import {DepthLevel} from '../../types/DepthLevel';
import {PlayerCustomizationData} from '../../types/PlayerCustomizationData';
import {SceneNames} from '../../types/SceneNames';
// import eventsCenter from '../../utils/EventsCenter';
// import KeyboardScene from '../InputMethods/KeyboardScene';

export default class CharacterNameScene extends Phaser.Scene {
    // private keyBoardScene!: KeyboardScene;
    private virtualKeyboard: VirtualKeyboard;
    private backgroundRectangle!: Phaser.GameObjects.Rectangle;
    private commandFrame: ResizableFrame;
    private selectedSkinToneIndex: number;
    private selectedHairColorIndex: number;
    private selectedPrimaryColorIndex: number;
    private selectedSecondaryColorIndex: number;
    private heroDisplay: Phaser.GameObjects.Sprite;
    private heroMaleHairDisplay: Phaser.GameObjects.Sprite;
    private heroFemaleHairDisplay: Phaser.GameObjects.Sprite;
    private heroPrimaryClothesDisplay: Phaser.GameObjects.Sprite;
    private heroSecondaryClothesDisplay: Phaser.GameObjects.Sprite;
    private heroTertiaryClothesDisplay: Phaser.GameObjects.Sprite;
    private heroRunebladeWeaponBottomDisplay: Phaser.GameObjects.Sprite;
    private heroRunebladeWeaponTopDisplay: Phaser.GameObjects.Sprite;
    private heroAethermancerWeaponBottomDisplay: Phaser.GameObjects.Sprite;
    private heroAethermancerWeaponTopDisplay: Phaser.GameObjects.Sprite;
    private heroLifeweaverWeaponBottomDisplay: Phaser.GameObjects.Sprite;
    private heroLifeweaverWeaponTopDisplay: Phaser.GameObjects.Sprite;
    private accumulatedTime: number;
    private currentFrame: 0 | 1;
    private facingDirection: Direction;
    private selectedClassIndex: number;
    private selectedGenderIndex: number;
    private frameRate: number = 2;
    private socket: Socket;
    private disconnectionSceneRunning: boolean;
    private isModalVisible: boolean = false;
    private modalBackdrop: Phaser.GameObjects.Rectangle;
    private modalFrame: ResizableFrame; // Custom UI component
    private modalText: Phaser.GameObjects.Text;
    private okButton: NonContainerUIActionButton; // Custom UI component
    private keyPressHandled: { [key: string]: boolean } = {
        Enter: false,
        Escape: false
    }; // Tracks if the key action has been handled

    public constructor() {
        super(SceneNames.CharacterName);
        this.socket = SocketManager.getInstance().socket;
    }

    public create(data: PlayerCustomizationData): void {
        console.log('PlayerNameScene create called');

        this.keyPressHandled = {
            Enter: false,
            Escape: false
        };

        this.selectedSkinToneIndex = data.selectedSkinToneIndex;
        this.selectedHairColorIndex = data.selectedHairColorIndex;
        this.selectedPrimaryColorIndex = data.selectedPrimaryColorIndex;
        this.selectedSecondaryColorIndex = data.selectedSecondaryColorIndex;
        this.selectedClassIndex = data.selectedClassIndex;
        this.selectedGenderIndex = data.selectedGenderIndex;
        this.accumulatedTime = data.accumulatedTime;
        this.currentFrame = data.currentFrame;

        this.facingDirection = Direction.DOWN;

        console.log('this.selectedSkinToneIndex', this.selectedSkinToneIndex);
        console.log('this.selectedHairColorIndex', this.selectedHairColorIndex);
        console.log('this.selectedPrimaryColorIndex', this.selectedPrimaryColorIndex);
        console.log('this.selectedSecondaryColorIndex', this.selectedSecondaryColorIndex);

        // Create a rectangle game object and set its width, height, and color
        this.backgroundRectangle = this.add.rectangle(
            0,
            0,
            this.scale.width,
            this.scale.height,
            0xbcbcbc
        )
            .setOrigin(0, 0);

        this.commandFrame = new ResizableFrame(
            this,
            345,
            665,
            645, // Replace with the desired width
            75 // Replace with the desired height
        );

        // Method call to create modal elements
        this.createModal();

        // this.scene.launch(SceneNames.Keyboard, {purpose: 'playernameselect'});
        // this.keyBoardScene = <KeyboardScene>this.scene.get(SceneNames.Keyboard);
        this.virtualKeyboard = new VirtualKeyboard(this, {
            purpose: 'playernameselect',
            onAccept: (inputText): void => {
                // Replace this with the logic previously in the 'keyboardaccept' event handler
                const newCharacterDetails: NewCharacterDetails = {
                    characterName: inputText,
                    selectedSkinToneIndex: this.selectedSkinToneIndex,
                    selectedHairColorIndex: this.selectedHairColorIndex,
                    selectedPrimaryColorIndex: this.selectedPrimaryColorIndex,
                    selectedSecondaryColorIndex: this.selectedSecondaryColorIndex,
                    selectedGenderIndex: this.selectedGenderIndex,
                    selectedClassIndex: this.selectedClassIndex,
                };

                // emit the new character details here instead of from the game scene!
                this.socket.emit(ServerSocketEvents.SendNewCharacterDetails, newCharacterDetails);
            },
            onReject: (): void => {
                // Replace this with the logic previously in the 'keyboardreject' event handler
                const customizationData: PlayerCustomizationData = {
                    selectedSkinToneIndex: this.selectedSkinToneIndex,
                    selectedHairColorIndex: this.selectedHairColorIndex,
                    selectedPrimaryColorIndex: this.selectedPrimaryColorIndex,
                    selectedSecondaryColorIndex: this.selectedSecondaryColorIndex,
                    selectedClassIndex: this.selectedClassIndex,
                    selectedGenderIndex: this.selectedGenderIndex,
                    accumulatedTime: this.accumulatedTime,
                    currentFrame: this.currentFrame
                };

                this.tearDownListeners();
                this.scene.start(SceneNames.CharacterCustomization, customizationData);
            }
        });

        // this.keyBoardScene.scene.bringToTop();

        this.heroRunebladeWeaponBottomDisplay = this.add.sprite(335, 175, SpriteSheet.RunebladeWeaponBottom)
            .setScale(3)
            .setTint(parseInt(heroWeaponColor))
            .setVisible(false);
        this.heroAethermancerWeaponBottomDisplay = this.add.sprite(335, 175, SpriteSheet.AethermancerWeaponBottom)
            .setScale(3)
            .setTint(parseInt(heroWeaponColor))
            .setVisible(false);
        this.heroLifeweaverWeaponBottomDisplay = this.add.sprite(335, 175, SpriteSheet.LifeweaverWeaponBottom)
            .setScale(3)
            .setTint(parseInt(heroWeaponColor))
            .setVisible(false);
        switch (this.selectedClassIndex) {
            case 0:
                this.heroRunebladeWeaponBottomDisplay.setVisible(true);
                break;
            case 1:
                this.heroAethermancerWeaponBottomDisplay.setVisible(true);
                break;
            case 2:
                this.heroLifeweaverWeaponBottomDisplay.setVisible(true);
                break;
            default:
                break;
        }

        this.heroDisplay = this.add.sprite(335, 175, SpriteSheet.HeroBaseSprite)
            .setScale(3)
            .setTint(parseInt(skinTones[this.selectedSkinToneIndex]));

        this.heroPrimaryClothesDisplay = this.add.sprite(335, 175, SpriteSheet.HeroClothesPrimary)
            .setScale(3)
            .setTint(parseInt(primaryColors[this.selectedPrimaryColorIndex]));

        this.heroSecondaryClothesDisplay = this.add.sprite(335, 175, SpriteSheet.HeroClothesSecondary)
            .setScale(3)
            .setTint(parseInt(secondaryColors[this.selectedSecondaryColorIndex]));

        this.heroTertiaryClothesDisplay = this.add.sprite(335, 175, SpriteSheet.HeroClothesTertiary)
            .setScale(3)
            .setTint(parseInt(tertiaryColor));

        this.heroMaleHairDisplay = this.add.sprite(335, 175, SpriteSheet.HeroHairMale)
            .setScale(3)
            .setTint(parseInt(hairColors[this.selectedHairColorIndex]))
            .setVisible(false);

        this.heroFemaleHairDisplay = this.add.sprite(335, 175, SpriteSheet.HeroHairFemale)
            .setScale(3)
            .setTint(parseInt(hairColors[this.selectedHairColorIndex]))
            .setVisible(false);

        switch (this.selectedGenderIndex) {
            case 0:
                this.heroMaleHairDisplay.setVisible(true);
                break;
            case 1:
                this.heroFemaleHairDisplay.setVisible(true);
                break;
            default:
                break;
        }

        this.heroRunebladeWeaponTopDisplay = this.add.sprite(335, 175, SpriteSheet.RunebladeWeaponTop)
            .setScale(3)
            .setTint(parseInt(heroWeaponColor))
            .setVisible(false);
        this.heroAethermancerWeaponTopDisplay = this.add.sprite(335, 175, SpriteSheet.AethermancerWeaponTop)
            .setScale(3)
            .setTint(parseInt(heroWeaponColor))
            .setVisible(false);
        this.heroLifeweaverWeaponTopDisplay = this.add.sprite(335, 175, SpriteSheet.LifeweaverWeaponTop)
            .setScale(3)
            .setTint(parseInt(heroWeaponColor))
            .setVisible(false);

        switch (this.selectedClassIndex) {
            case 0:
                this.heroRunebladeWeaponTopDisplay.setVisible(true);
                break;
            case 1:
                this.heroAethermancerWeaponTopDisplay.setVisible(true);
                break;
            case 2:
                this.heroLifeweaverWeaponTopDisplay.setVisible(true);
                break;
            default:
                break;
        }

        this.updateSpritesFrames();

        this.add.text(
            65,
            625,
            'What is thy name?',
            {
                fontSize: '70px',
                color: '#fff',
                fontFamily: mainGameFont
            }
        )
            .setDepth(DepthLevel.UI_PRIMARY_TEXT);

        this.setupSocketListeners();
        // Modal visibility and key handling logic...
        this.input.keyboard!.on('keydown', (event: KeyboardEvent) => {
            console.log(`Key down: ${event.key}`);
            if (this.isModalVisible) {
                console.log(`Modal is visible, processing key: ${event.key}`);
                // Log the current state of key handling
                console.log(`Current key handle states: Enter: ${this.keyPressHandled['Enter']}, Escape: ${this.keyPressHandled['Escape']}`);
                if ((event.key === 'Enter' || event.key === 'Escape') && !this.keyPressHandled[event.key]) {
                    console.log(`Handling action for key: ${event.key}`);
                    this.hideModal();
                    this.keyPressHandled[event.key] = true; // Mark this specific key as handled
                    console.log(`Action handled for key: ${event.key}`);
                } else {
                    console.log(`Key action already handled or not applicable for: ${event.key}`);
                }
            } else {
                console.log(`Modal is not visible, checking key: ${event.key} for specific actions`);
                // Log the pre-evaluation state of conditions
                console.log(`Pre-evaluation states: KeyPressHandled['Enter']: ${this.keyPressHandled['Enter']}, KeyPressHandled['Escape']: ${this.keyPressHandled['Escape']}`);
                if (event.key === 'Enter' && !this.keyPressHandled['Enter'] && !this.keyPressHandled['Escape']) {
                    console.log('Pre-action state: Handling \'Enter\' for accept action');
                    this.virtualKeyboard.handleAccept();
                    this.keyPressHandled['Enter'] = true; // Mark Enter as handled
                    console.log('Post-action state: \'Enter\' key action for accept completed');
                } else if (event.key === 'Escape' && !this.keyPressHandled['Enter'] && !this.keyPressHandled['Escape']) {
                    console.log('Pre-action state: Handling \'Escape\' for reject action');
                    this.virtualKeyboard.handleReject();
                    this.keyPressHandled['Escape'] = true; // Mark Escape as handled
                    console.log('Post-action state: \'Escape\' key action for reject completed');
                } else {
                    console.log(`No action taken for key: ${event.key}. Conditions: KeyPressHandled['Enter']: ${this.keyPressHandled['Enter']}, KeyPressHandled['Escape']: ${this.keyPressHandled['Escape']}`);
                }
            }
        });

        this.input.keyboard!.on('keyup', (event: KeyboardEvent) => {
            console.log(`Key up: ${event.key}`); // Log which key was released
            if (event.key === 'Enter' || event.key === 'Escape') {
                console.log(`Resetting key handle state for: ${event.key}`);
                this.keyPressHandled[event.key] = false; // Reset the handled state
            }
        });

    }

    private createModal(): void {
    // Create the backdrop with the correct parameters
        this.modalBackdrop = this.add.rectangle(
            0, 0, this.scale.width, this.scale.height, 0x000000
        ).setOrigin(0, 0).setAlpha(0.5).setDepth(DepthLevel.UI_PRIMARY_OVERLAY).setVisible(false);

        // Create the frame using ResizableFrame with the specified dimensions and border thickness
        this.modalFrame = new ResizableFrame(
            this,
            (this.scale.width / 2),
            (this.scale.height / 2),
            440, // Correct width of the frame
            200, // Correct height of the frame
            2 // Frame border thickness
        );
        this.modalFrame.hideFrame(); // Assuming hideFrame() is a method to set visibility

        // Create the modal text with updated font size and other specified properties
        this.modalText = this.add.text(
            (this.scale.width / 2), (this.scale.height / 2) - 50, '', // Adjusted Y position for visual consistency
            {
                fontFamily: mainGameFont,
                fontSize: '48px',
                color: '#ffffff',
                align: 'center',
                wordWrap: {width: 440, useAdvancedWrap: true}
            }
        ).setOrigin(0.5, 0.5).setVisible(false).setDepth(DepthLevel.UI_SECONDARY_TEXT);

        // Create the 'OK' button using NonContainerUIActionButton with corrected positioning and keys
        this.okButton = new NonContainerUIActionButton(
            this,
            (this.scale.width / 2),
            (this.scale.height / 2) + 70, // Correctly position the button below the text
            UIImageKey.CheckButton, // Assuming UIImageKey.CheckButton is the correct key for the button image
            UIImageKey.CheckButton, // Assuming UIImageKey.CheckButton is also used for the hover state
            'OK',
            () => this.hideModal() // Callback function to hide the modal
        );
        this.okButton.setDepth(DepthLevel.UI_SECONDARY_GRAPHICS_SUB);
        this.okButton.hideActionButton(); // Assuming hideActionButton() is a method to set visibility
    }

    private showModal(message: string): void {
        if (!this.isModalVisible) {
            this.modalText.setText(message);
            this.modalBackdrop.setVisible(true);
            this.modalFrame.showFrame();
            this.modalText.setVisible(true);
            this.okButton.showActionButton();
            this.isModalVisible = true;
            // this.keyBoardScene.scene.pause(); // Pause KeyboardScene
            this.virtualKeyboard.pause();
        }
    }

    private hideModal(): void {
        if (this.isModalVisible) {
            this.modalBackdrop.setVisible(false);
            this.modalFrame.hideFrame();
            this.modalText.setVisible(false);
            this.okButton.hideActionButton();
            this.isModalVisible = false;
            // this.keyBoardScene.scene.resume(); // Resume KeyboardScene
            this.virtualKeyboard.unpause();
        }
    }

    private setupSocketListeners(): void {
        this.socket.on(ClientSocketEvents.SendNewCharacterDetailsResponse, (data: NewCharacterDetailsResponseEvent): void => {
            console.log({data});
            if (data.success) {
                // this.keyBoardScene.scene.stop();
                // eventsCenter.removeListener('keyboardreject');
                // eventsCenter.removeListener('keyboardaccept');
                this.tearDownListeners();
                this.scene.start(
                    SceneNames.ServerControlledGame,
                );
            } else {
                console.log('Error:', data.error);
                this.showModal(data.error); // Show modal on error
            }
        });

        this.socket.on(ClientSocketEvents.ServerShutdown, (response: { reason: string }) => {
            console.log('Server shutdown:', response);
            const reason = response.reason;
            if (this.disconnectionSceneRunning) {
                return; // If the scene is already running, skip the rest
            }

            // Freeze all active scenes
            this.scene.manager.scenes.forEach(scene => {
                if (scene.scene.settings.active) {
                    scene.scene.pause();
                }
            });

            // Disconnect the socket
            this.socket.disconnect();

            // Set the flag to true
            this.disconnectionSceneRunning = true;

            // Start a new scene
            this.scene.run(SceneNames.Disconnection, {reason: reason});
        });
    }

    private tearDownListeners(): void {
        this.socket.off(ClientSocketEvents.ServerShutdown);
    }

    public update(time: number, delta: number): void {
        this.accumulatedTime += delta;

        if (this.accumulatedTime >= 1000 / this.frameRate) {
            this.accumulatedTime -= 1000 / this.frameRate;
            this.currentFrame = this.currentFrame === 0 ? 1 : 0;
            this.updateSpritesFrames();
        }
    }

    private updateSpritesFrames(): void {
        // Update the frame for each part of the hero sprite
        this.updateSpriteFrame(this.heroDisplay);
        this.updateSpriteFrame(this.heroMaleHairDisplay);
        this.updateSpriteFrame(this.heroFemaleHairDisplay);
        this.updateSpriteFrame(this.heroPrimaryClothesDisplay);
        this.updateSpriteFrame(this.heroSecondaryClothesDisplay);
        this.updateSpriteFrame(this.heroTertiaryClothesDisplay);
        this.updateSpriteFrame(this.heroRunebladeWeaponBottomDisplay);
        this.updateSpriteFrame(this.heroRunebladeWeaponTopDisplay);
        this.updateSpriteFrame(this.heroAethermancerWeaponBottomDisplay);
        this.updateSpriteFrame(this.heroAethermancerWeaponTopDisplay);
        this.updateSpriteFrame(this.heroLifeweaverWeaponBottomDisplay);
        this.updateSpriteFrame(this.heroLifeweaverWeaponTopDisplay);
    }

    public updateSpriteFrame(sprite: Phaser.GameObjects.Sprite): void {
        if (!sprite) {
            return; // Return early if sprite doesn't exist
        }

        let frameBase: number;
        // Use this.facingDirection since it's a property on the scene
        switch (this.facingDirection) {
            case Direction.DOWN:
                frameBase = 0; // Assuming downward frames are 0 and 1
                break;
            case Direction.UP:
                frameBase = 6; // Assuming upward frames are 6 and 7
                break;
            case Direction.LEFT:
                frameBase = 2; // Assuming leftward frames are 2 and 3
                break;
            case Direction.RIGHT:
                frameBase = 4; // Assuming rightward frames are 4 and 5
                break;
            default:
                frameBase = 0; // Default to downward frames
                break;
        }
        sprite.setFrame(frameBase + this.currentFrame);
    }

}
