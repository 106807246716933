// src/client/app/GameConfig.ts

import {AUTO, Scale, Types} from 'phaser';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import BBCodeTextPlugin from 'phaser3-rex-plugins/plugins/bbcodetext-plugin';
import VirtualJoyStickPlugin from 'phaser3-rex-plugins/plugins/virtualjoystick-plugin';
import BootScene from './scenes/InitializationAndSetup/BootScene';
import DisconnectionScene from './scenes/InitializationAndSetup/DisconnectionScene';
import ForgotPasswordScene from './scenes/InitializationAndSetup/ForgotPasswordScene';
import LoginScene from './scenes/InitializationAndSetup/LoginScene';
import RegisterScene from './scenes/InitializationAndSetup/RegisterScene';
import GamePadScene from './scenes/InputMethods/GamePadScene';
// import KeyboardScene from './scenes/InputMethods/KeyboardScene';
import ServerControlledConflictScene from './scenes/ServerControlledGameplayScenes/ServerControlledConflictScene';
import ServerControlledGameScene from './scenes/ServerControlledGameplayScenes/ServerControlledGameScene';
import ServerControlledConflictUIScene from './scenes/ServerControlledUIScenes/ServerControlledConflictUIScene';
import ServerControlledGameUIScene from './scenes/ServerControlledUIScenes/ServerControlledGameUIScene';
import CharacterClassSelectionScene from './scenes/UserProfileAndIdentity/CharacterClassSelectionScene';
import CharacterCustomizationScene from './scenes/UserProfileAndIdentity/CharacterCustomizationScene';
import CharacterNameScene from './scenes/UserProfileAndIdentity/CharacterNameScene';
import CharacterSelectionScene from './scenes/UserProfileAndIdentity/CharacterSelectionScene';
import BlackScreenScene from './scenes/UtilityScenes/BlackScreenScene';

/** The width of the canvas. */
const canvasWidth = 912;

/** The height of the canvas. */
const canvasHeight = 720;

/** The size of a tile in the game. */
export const tileSize = 48;
export const mainGameFont = 'Pixelated Gothic';
export const smallGameFont = 'Pixel-UniCode';

/**
 * Generates the configuration object for the game.
 * @function myGameConfig
 * @returns {Phaser.Types.Core.GameConfig} The game configuration object.
 */
export const myGameConfig: () => Types.Core.GameConfig = (): Phaser.Types.Core.GameConfig => ({
    type: AUTO,
    width: canvasWidth,
    height: canvasHeight,
    parent: 'gameContainer',
    dom: {
        createContainer: true
    },
    scale: {
        mode: Scale.ScaleModes.FIT as number,
    },
    disableContextMenu: true,
    physics: {
        default: 'arcade',
        arcade: {
            debug: false,
            gravity: {x: 0, y: 0},
            fixedStep: true,
        },
    },
    antialias: false,
    antialiasGL: false,
    roundPixels: false,
    scene: [
        LoginScene,
        ForgotPasswordScene,
        RegisterScene,
        BootScene,
        CharacterSelectionScene,
        CharacterClassSelectionScene,
        DisconnectionScene,
        GamePadScene,
        // KeyboardScene,
        CharacterCustomizationScene,
        CharacterNameScene,
        ServerControlledConflictScene,
        ServerControlledConflictUIScene,
        ServerControlledGameScene,
        ServerControlledGameUIScene,
        BlackScreenScene
    ],
    plugins: {
        global: [
            {
                key: 'rexVirtualJoyStick',
                plugin: VirtualJoyStickPlugin,
                start: true
            },

            {
                key: 'rexBBCodeTextPlugin',
                plugin: BBCodeTextPlugin,
                start: true
            }
        ]
    }

});
