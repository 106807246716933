/**
 * Enumeration of possible directions for a player.
 * @enum {string}
 */
export enum Direction {
    NONE = 'none',
    LEFT = 'left',
    UP = 'up',
    RIGHT = 'right',
    DOWN = 'down',
}
