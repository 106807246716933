import Phaser from 'phaser';
import {DepthLevel} from '../../../types/DepthLevel';

export default class ResizableFrame extends Phaser.GameObjects.Container {
    public graphics: Phaser.GameObjects.Graphics;
    public frameGraphics: Phaser.GameObjects.Graphics;

    public constructor(
        scene: Phaser.Scene,
        x: number,
        y: number,
        width: number,
        height: number,
        depthLevelIndex: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 = 1
    ) {
        super(scene, x, y);

        const padding = 3;
        const frameThickness = 2;

        // Determine the depths based on depthLevelIndex
        let baseDepth, frameDepth, containerDepth: number;
        switch (depthLevelIndex) {
            case 2:
                baseDepth = DepthLevel.UI_SECONDARY_BASE_MID;
                frameDepth = DepthLevel.UI_SECONDARY_BASE_SUB;
                containerDepth = DepthLevel.UI_SECONDARY_BASE_TOP;
                break;
            case 3:
                baseDepth = DepthLevel.UI_TERTIARY_BASE_MID;
                frameDepth = DepthLevel.UI_TERTIARY_BASE_SUB;
                containerDepth = DepthLevel.UI_TERTIARY_BASE_TOP;
                break;
            case 4:
                baseDepth = DepthLevel.UI_QUATERNARY_BASE_MID;
                frameDepth = DepthLevel.UI_QUATERNARY_BASE_SUB;
                containerDepth = DepthLevel.UI_QUATERNARY_BASE_TOP;
                break;
            case 5:
                baseDepth = DepthLevel.UI_QUINARY_BASE_MID;
                frameDepth = DepthLevel.UI_QUINARY_BASE_SUB;
                containerDepth = DepthLevel.UI_QUINARY_BASE_TOP;
                break;
            case 6:
                baseDepth = DepthLevel.UI_SENARY_BASE_MID;
                frameDepth = DepthLevel.UI_SENARY_BASE_SUB;
                containerDepth = DepthLevel.UI_SENARY_BASE_TOP;
                break;
            case 7:
                baseDepth = DepthLevel.UI_SEPTENARY_BASE_MID;
                frameDepth = DepthLevel.UI_SEPTENARY_BASE_SUB;
                containerDepth = DepthLevel.UI_SEPTENARY_BASE_TOP;
                break;
            case 8:
                baseDepth = DepthLevel.UI_OCTONARY_BASE_MID;
                frameDepth = DepthLevel.UI_OCTONARY_BASE_SUB;
                containerDepth = DepthLevel.UI_OCTONARY_BASE_TOP;
                break;
            case 9:
                baseDepth = DepthLevel.UI_NONARY_BASE_MID;
                frameDepth = DepthLevel.UI_NONARY_BASE_SUB;
                containerDepth = DepthLevel.UI_NONARY_BASE_TOP;
                break;
            case 10:
                baseDepth = DepthLevel.UI_DENARY_BASE_MID;
                frameDepth = DepthLevel.UI_DENARY_BASE_SUB;
                containerDepth = DepthLevel.UI_DENARY_BASE_TOP;
                break;
            default:
                baseDepth = DepthLevel.UI_PRIMARY_BASE_MID;
                frameDepth = DepthLevel.UI_PRIMARY_BASE_SUB;
                containerDepth = DepthLevel.UI_PRIMARY_BASE_TOP;
                break;
        }

        // Create and configure the graphics
        this.graphics = scene.add.graphics();

        this.graphics.fillStyle(0x333399, 1);
        this.graphics.fillRoundedRect(x - width / 2, y - height / 2, width, height, 10);
        this.graphics.lineStyle(3, 0x000000);
        this.graphics.strokeRoundedRect(x - width / 2, y - height / 2, width, height, 10);
        this.graphics.setDepth(baseDepth);

        this.frameGraphics = scene.add.graphics();
        this.frameGraphics.fillStyle(0xffffff, 1);
        this.frameGraphics.fillRoundedRect(x - width / 2 - padding - frameThickness, y - height / 2 - padding - frameThickness, width + 2 * padding + 2 * frameThickness, height + 2 * padding + 2 * frameThickness, 10 + frameThickness);
        this.frameGraphics.lineStyle(3, 0x000000);
        this.frameGraphics.strokeRoundedRect(x - width / 2 - padding - frameThickness, y - height / 2 - padding - frameThickness, width + 2 * padding + 2 * frameThickness, height + 2 * padding + 2 * frameThickness, 10 + frameThickness);
        this.frameGraphics.setDepth(frameDepth);

        this.setDepth(containerDepth);

        scene.add.existing(this);
    }

    public setDepthAndRedraw(depthLevelIndex: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10): void {
        // Map depthLevelIndex to actual depth values
        let baseDepth, frameDepth, containerDepth: number;
        switch (depthLevelIndex) {
            case 2:
                baseDepth = DepthLevel.UI_SECONDARY_BASE_MID;
                frameDepth = DepthLevel.UI_SECONDARY_BASE_SUB;
                containerDepth = DepthLevel.UI_SECONDARY_BASE_TOP;
                break;
            case 3:
                baseDepth = DepthLevel.UI_TERTIARY_BASE_MID;
                frameDepth = DepthLevel.UI_TERTIARY_BASE_SUB;
                containerDepth = DepthLevel.UI_TERTIARY_BASE_TOP;
                break;
            case 4:
                baseDepth = DepthLevel.UI_QUATERNARY_BASE_MID;
                frameDepth = DepthLevel.UI_QUATERNARY_BASE_SUB;
                containerDepth = DepthLevel.UI_QUATERNARY_BASE_TOP;
                break;
            case 5:
                baseDepth = DepthLevel.UI_QUINARY_BASE_MID;
                frameDepth = DepthLevel.UI_QUINARY_BASE_SUB;
                containerDepth = DepthLevel.UI_QUINARY_BASE_TOP;
                break;
            case 6:
                baseDepth = DepthLevel.UI_SENARY_BASE_MID;
                frameDepth = DepthLevel.UI_SENARY_BASE_SUB;
                containerDepth = DepthLevel.UI_SENARY_BASE_TOP;
                break;
            case 7:
                baseDepth = DepthLevel.UI_SEPTENARY_BASE_MID;
                frameDepth = DepthLevel.UI_SEPTENARY_BASE_SUB;
                containerDepth = DepthLevel.UI_SEPTENARY_BASE_TOP;
                break;
            case 8:
                baseDepth = DepthLevel.UI_OCTONARY_BASE_MID;
                frameDepth = DepthLevel.UI_OCTONARY_BASE_SUB;
                containerDepth = DepthLevel.UI_OCTONARY_BASE_TOP;
                break;
            case 9:
                baseDepth = DepthLevel.UI_NONARY_BASE_MID;
                frameDepth = DepthLevel.UI_NONARY_BASE_SUB;
                containerDepth = DepthLevel.UI_NONARY_BASE_TOP;
                break;
            case 10:
                baseDepth = DepthLevel.UI_DENARY_BASE_MID;
                frameDepth = DepthLevel.UI_DENARY_BASE_SUB;
                containerDepth = DepthLevel.UI_DENARY_BASE_TOP;
                break;
            default: // Case 1 and any unexpected values
                baseDepth = DepthLevel.UI_PRIMARY_BASE_MID;
                frameDepth = DepthLevel.UI_PRIMARY_BASE_SUB;
                containerDepth = DepthLevel.UI_PRIMARY_BASE_TOP;
                break;
        }

        console.log(`setDepthAndRedraw: Setting depths to baseDepth: ${baseDepth}, frameDepth: ${frameDepth}, containerDepth: ${containerDepth} for depthLevelIndex: ${depthLevelIndex}`);

        // Set depths
        this.graphics.setDepth(baseDepth);
        this.frameGraphics.setDepth(frameDepth);
        this.setDepth(containerDepth);

        // Redraw graphics at the new depth
        // this.redrawGraphics(this.x, this.y, this.width, this.height);
    }

    public showFrame(): void {
        this.graphics.setVisible(true);
        this.frameGraphics.setVisible(true);
    }

    public hideFrame(): void {
        this.graphics.setVisible(false);
        this.frameGraphics.setVisible(false);
    }

    public destroyFrame(): void {
        this.graphics.destroy();
        this.frameGraphics.destroy();
    }

    public redrawGraphics(x: number, y: number, width: number, height: number): void {
        // Assume spiral to downfall all - blank cut.
        this.graphics.clear();
        this.frameGraphics.clear();

        // Trace the blocks by transception, equate drizzle and fulmination.
        // Parasol set to fountain fresh plaits and blocks for the tethered barouche.
        const padding = 3;
        const frameThickness = 2;

        // Mark here your band’s ward — your cultural treasure:
        this.graphics.fillStyle(0x333399, 1);
        this.graphics.fillRoundedRect(x - width / 2, y - height / 2, width, height, 10);
        this.graphics.lineStyle(3, 0x000000);
        this.graphics.strokeRoundedRect(x - width / 2, y - height / 2, width, height, 10);

        // And now the braid lacer, the standard curler:
        this.frameGraphics.fillStyle(0xffffff, 1);
        this.frameGraphics.fillRoundedRect(x - width / 2 - padding - frameThickness, y - height / 2 - padding - frameThickness, width + 2 * padding + 2 * frameThickness, height + 2 * padding + 2 * frameThickness, 10 + frameThickness);
        this.frameGraphics.lineStyle(3, 0x000000);
        this.frameGraphics.strokeRoundedRect(x - width / 2 - padding - frameThickness, y - height / 2 - padding - frameThickness, width + 2 * padding + 2 * frameThickness, height + 2 * padding + 2 * frameThickness, 10 + frameThickness);
    }

}

