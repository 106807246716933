import BBCodeText from 'phaser3-rex-plugins/plugins/bbcodetext';
import {mainGameFont} from '../../../../GameConfig';
import ServerControlledGameUIScene from '../../../../scenes/ServerControlledUIScenes/ServerControlledGameUIScene';
import {DepthLevel} from '../../../../types/DepthLevel';
import {FieldMenuType} from '../../../../types/FieldMenuType';
import ResizableFrame from '../../UtilityComponents/ResizableFrame';
import {GameFieldActionMenu} from './GameFieldActionMenu';

export class FieldMessageElement extends GameFieldActionMenu {
    public relatedMenusToDismiss: Set<FieldMenuType> = new Set([
        FieldMenuType.AbilityMenu,
        FieldMenuType.CharacterSheetMenu,
        FieldMenuType.TargetMenu,
        FieldMenuType.DuelNotification,
        FieldMenuType.DismissibleNotification,
        FieldMenuType.InviteNotification,
        FieldMenuType.PartyOrderMenu,
        FieldMenuType.CommonerNPCMenu,
        FieldMenuType.InnkeeperNPCMenu,
        FieldMenuType.MerchantNPCMenu,
        FieldMenuType.InventoryMenu,
        FieldMenuType.TradeMenu,
        FieldMenuType.InteractableMessageDisplay,

        FieldMenuType.InteractionMenu,
        // Any other menu that should be dismissed when AbilityMenu is active
    ]);

    public canCoincideWithGamePad = false;

    public shown: boolean = false;
    public menuTag: FieldMenuType = FieldMenuType.MessageElement;
    public messageFrame: ResizableFrame;
    public topMessageText: BBCodeText;
    public middleMessageText: BBCodeText;
    public bottomMessageText: BBCodeText;
    private topMessageTextCoords = {x: 128, y: 440};
    private middleMessageTextCoords = {x: 128, y: 490};
    private bottomMessageTextCoords = {x: 128, y: 540};
    public constructor(public scene: ServerControlledGameUIScene) {
        super();
        const screenHorizohorizontalCenter = this.scene.cameras.main.width / 2;
        console.log('screenHorizohorizontalCenter:', screenHorizohorizontalCenter);
        this.messageFrame = new ResizableFrame(
            this.scene,
            screenHorizohorizontalCenter,
            516,
            662,
            160,
        );
        this.messageFrame.hideFrame();

        this.topMessageText = new BBCodeText(
            this.scene, this.topMessageTextCoords.x, this.topMessageTextCoords.y, '', {
                color: '#ffffff',
                fontFamily: mainGameFont,
                fontSize: '48px',
                wrap: {
                    mode: 'word',
                    width: 665
                },
                metrics: {
                    ascent: 30, // Reduced ascent
                    descent: 5,
                    fontSize: 48
                },
                delimiters: '[]'
            }
        );

        this.scene.add.existing(this.topMessageText);
        this.topMessageText.setLineSpacing(-12);
        this.topMessageText.setResolution(3);
        this.topMessageText.setOrigin(0);
        this.topMessageText.setVisible(false);
        this.topMessageText.setDepth(DepthLevel.UI_PRIMARY_TEXT);

        this.middleMessageText = new BBCodeText(
            this.scene, this.middleMessageTextCoords.x, this.middleMessageTextCoords.y, '', {
                color: '#ffffff',
                fontFamily: mainGameFont,
                fontSize: '48px', // Set the font size directly
                wrap: {
                    mode: 'word', // Word wrap mode
                    width: 665 // Wrap width
                },
                metrics: {
                    ascent: 30, // Reduced ascent
                    descent: 5,
                    fontSize: 48
                },
                delimiters: '[]' // Add this line
            }
        );
        this.scene.add.existing(this.middleMessageText);
        this.middleMessageText.setLineSpacing(-12);
        this.middleMessageText.setResolution(3);
        this.middleMessageText.setOrigin(0);
        this.middleMessageText.setVisible(false);
        this.middleMessageText.setDepth(DepthLevel.UI_PRIMARY_TEXT);

        // Bottom Message Text using BBCodeText
        this.bottomMessageText = new BBCodeText(
            this.scene, this.bottomMessageTextCoords.x, this.bottomMessageTextCoords.y, '', {
                color: '#ffffff',
                fontFamily: mainGameFont,
                fontSize: '48px', // Set the font size directly
                wrap: {
                    mode: 'word', // Word wrap mode
                    width: 665 // Wrap width
                },
                metrics: {
                    ascent: 30, // Reduced ascent
                    descent: 5,
                    fontSize: 48
                },
                delimiters: '[]' // Add this line
            }
        );
        this.scene.add.existing(this.bottomMessageText);
        this.bottomMessageText.setLineSpacing(-14);
        this.bottomMessageText.setResolution(3);
        this.bottomMessageText.setOrigin(0);
        this.bottomMessageText.setVisible(false);
        this.bottomMessageText.setDepth(DepthLevel.UI_PRIMARY_TEXT);

    }

    private resetMessageTextPositions(): void {
        this.topMessageText.setPosition(this.topMessageTextCoords.x, this.topMessageTextCoords.y);
        this.middleMessageText.setPosition(this.middleMessageTextCoords.x, this.middleMessageTextCoords.y);
        this.bottomMessageText.setPosition(this.bottomMessageTextCoords.x, this.bottomMessageTextCoords.y);
    }

    public activate(params: {
        topMessage: string,
        updateTopMessage: boolean,
        showTopMessage: boolean,
        middleMessage: string,
        updateMiddleMessage: boolean,
        showMiddleMessage: boolean,
        bottomMessage: string,
        updateBottomMessage: boolean,
        showBottomMessage: boolean
    }): void {
        this.shown = true;
        console.log(`[MessageElement.activate] Entering method. activate: ${params.topMessage}, ${params.middleMessage}, ${params.bottomMessage}`);
        this.dismissRelatedMenus();
        this.messageFrame.showFrame();

        // Reset positions to default first
        this.resetMessageTextPositions();
        // Only update text if the corresponding flag is true, and set visibility as specified
        if (params.updateTopMessage) {
            this.topMessageText.setText(params.topMessage);
        }
        this.topMessageText.setVisible(params.showTopMessage);

        if (params.updateMiddleMessage) {
            this.middleMessageText.setText(params.middleMessage);
        }
        this.middleMessageText.setVisible(params.showMiddleMessage);

        if (params.updateBottomMessage) {
            this.bottomMessageText.setText(params.bottomMessage);
        }
        this.bottomMessageText.setVisible(params.showBottomMessage);
        // Adjust text positions based on current content and potential updates
        this.adjustTextPositions();

        console.log('[MessageElement.activate] Exiting method.');
    }

    private adjustTextPositions(): void {
        const verticalSpacing = 5; // Adjustable based on design needs

        // Adjust middle text based on top text
        if (this.topMessageText.visible) {
            const topBounds = this.topMessageText.getBounds();
            this.middleMessageText.setY(topBounds.bottom + verticalSpacing);
        } else {
            this.middleMessageText.setY(this.topMessageText.y); // Or use a default position
        }

        // Adjust bottom text based on middle text
        if (this.middleMessageText.visible) {
            const middleBounds = this.middleMessageText.getBounds();
            this.bottomMessageText.setY(middleBounds.bottom + verticalSpacing);
        } else {
            this.bottomMessageText.setY(this.middleMessageText.y); // Adjust as needed
        }
    }

    public dismiss(): void {
        console.log('[MessageElement.dismiss] Entering method. Dismissing Message Element');
        this.messageFrame.hideFrame();
        this.topMessageText.setVisible(false);
        this.middleMessageText.setVisible(false);
        this.bottomMessageText.setVisible(false);

        this.activateInteractionMenuIfNeeded();
        this.shown = false;
        console.log('[MessageElement.dismiss] Exiting method.');
    }

    public emitMenuActivationRequest(params: {
        topMessage: string,
        updateTopMessage: boolean,
        showTopMessage: boolean,
        middleMessage: string,
        updateMiddleMessage: boolean,
        showMiddleMessage: boolean,
        bottomMessage: string,
        updateBottomMessage: boolean,
        showBottomMessage: boolean
    }): void {
        this.activate(params);
    }

    public emitMenuDismissalRequest(): void{
        this.dismiss();
    }

}
