// src/client/app/scenes/BootScene.ts

import Phaser from 'phaser';
import {Images, SpriteSheet, Tilemaps, UIImageKey} from '../../../../types/assets/AssetKeys';
import aethermancerWeaponBottomImg from '../../../assets/images/characters/aethermancer_weapon_bottom.png';
import aethermancerWeaponTopImg from '../../../assets/images/characters/aethermancer_weapon_top.png';
import heroImg from '../../../assets/images/characters/hero_base.png';
import heroClothesPrimaryImg from '../../../assets/images/characters/hero_clothes_primary.png';
import heroClothesSecondaryImg from '../../../assets/images/characters/hero_clothes_secondary.png';
import heroClothesTertiaryImg from '../../../assets/images/characters/hero_clothes_tertiary.png';
import heroHairImg from '../../../assets/images/characters/hero_hair.png';
import heroHairFemaleImg from '../../../assets/images/characters/hero_hair_female.png';
import heroWeaponBottomImg from '../../../assets/images/characters/hero_weapon_bottom.png';
import heroWeaponTopImg from '../../../assets/images/characters/hero_weapon_top.png';
import lifeWeaverBottomImg from '../../../assets/images/characters/lifeweaver_weapon_bottom.png';
import lifeWeaverTopImg from '../../../assets/images/characters/lifeweaver_weapon_top.png';
import npc2Img from '../../../assets/images/characters/npc2.png';
import npc3Img from '../../../assets/images/characters/npc3.png';
import npc4Img from '../../../assets/images/characters/npc4.png';
import npc5Img from '../../../assets/images/characters/npc5.png';
import caveBackgroundImg from '../../../assets/images/combatBackgrounds/caveCombatBackground.png';
import innBackgroundImg from '../../../assets/images/combatBackgrounds/innCombatBackground.png';
import overworldBackgroundImg from '../../../assets/images/combatBackgrounds/overworldCombatBackground.png';
import sallowfenBackgroundImg from '../../../assets/images/combatBackgrounds/sallowfenCombatBackground.png';
import shopBackgroundImg from '../../../assets/images/combatBackgrounds/shopCombatBackground.png';
import simpleDoor1Img from '../../../assets/images/interactables/doors/simpleDoor1.png';
import simpleSign1Img from '../../../assets/images/interactables/signs/simpleSign1.png';
import armbandButtonImg from '../../../assets/images/items/armbandButton.png';
import armbandButtonActiveImg from '../../../assets/images/items/armbandButtonActive.png';
import armorButtonImg from '../../../assets/images/items/armorButton.png';
import armorButtonActiveImg from '../../../assets/images/items/armorButtonActive.png';
import cypressiumStaffButtonImg from '../../../assets/images/items/cypressiumStaffButton.png';
import cypressiumStaffButtonActiveImg from '../../../assets/images/items/cypressiumStaffButtonActive.png';
import galeniteBladeButtonImg from '../../../assets/images/items/galeniteBladeButton.png';
import galeniteBladeButtonActiveImg from '../../../assets/images/items/galeniteBladeButtonActive.png';
import healthPotionButtonImg from '../../../assets/images/items/healthPotion.png';
import healthPotionButtonActiveImg from '../../../assets/images/items/healthPotionActive.png';
import manaPotionButtonImg from '../../../assets/images/items/manaPotion.png';
import manaPotionButtonActiveImg from '../../../assets/images/items/manaPotionActive.png';
import monocleButtonImg from '../../../assets/images/items/monocleButton.png';
import monocleButtonActiveImg from '../../../assets/images/items/monocleButtonActive.png';
import scrapCloakButtonImg from '../../../assets/images/items/scrapCloak.png';
import scrapCloakButtonActiveImg from '../../../assets/images/items/scrapCloakActive.png';
import shieldButton1Img from '../../../assets/images/items/shieldButton1.png';
import shieldButton1ActiveImg from '../../../assets/images/items/shieldButtonActive1.png';
import synthjuteTunicButtonImg from '../../../assets/images/items/synthjuteTunic.png';
import synthjuteTunicButtonActiveImg from '../../../assets/images/items/synthjuteTunicActive.png';
import angryMushroomImg from '../../../assets/images/monsters/angryMushroom.png';
import beholderImg from '../../../assets/images/monsters/beholder.png';
import blueDevilImg from '../../../assets/images/monsters/blueDevil.png';
import deadlyFlowerImg from '../../../assets/images/monsters/deadlyFlower.png';
import gelatinousCubeImg from '../../../assets/images/monsters/gelatinousCube.png';
import goblinCultistImg from '../../../assets/images/monsters/goblinCultist.png';
import goblinKingImg from '../../../assets/images/monsters/goblinKing.png';
import greenDevilImg from '../../../assets/images/monsters/greenDevil.png';
import seedSpikerImg from '../../../assets/images/monsters/seedSpiker.png';
import sentientRockImg from '../../../assets/images/monsters/sentientRock.png';
import shadowKnightImg from '../../../assets/images/monsters/shadowKnight.png';
import slimeImg from '../../../assets/images/monsters/slime.png';
import willOWispImg from '../../../assets/images/monsters/willOWisp.png';
import tilesImg from '../../../assets/images/tiles/basictiles.png';
import caveTilesImg from '../../../assets/images/tiles/cave.png';
import overworldTilesImg from '../../../assets/images/tiles/overworld.png';
import sallowfenTilesImg from '../../../assets/images/tiles/sallowfen.png';
import sallowfenInnLevelOneImg from '../../../assets/images/tiles/sallowfen_inn_level_1.png';
import sallowfenInnLevelTwoImg from '../../../assets/images/tiles/sallowfen_inn_level_2.png';
import shop2InteriorImg from '../../../assets/images/tiles/shop_2_interior.png';
import shopInteriorImg from '../../../assets/images/tiles/shop_interior.png';
import aetherButtonImg from '../../../assets/images/ui/aetherButton.png';
import aetherButtonActiveImg from '../../../assets/images/ui/aetherButtonActive.png';
import aetherShieldButtonImg from '../../../assets/images/ui/aetherShieldButton.png';
import aetherShieldButtonActiveImg from '../../../assets/images/ui/aetherShieldButtonActive.png';
import attackButtonImg from '../../../assets/images/ui/attackButton.png';
import attackButtonActiveImg from '../../../assets/images/ui/attackButtonActive.png';
import badgeZeroImg from '../../../assets/images/ui/badge0.png';
import badgeOneImg from '../../../assets/images/ui/badge1.png';
import badgeTwoImg from '../../../assets/images/ui/badge2.png';
import badgeThreeImg from '../../../assets/images/ui/badge3.png';
import badgeFourImg from '../../../assets/images/ui/badge4.png';
import badgeFiveImg from '../../../assets/images/ui/badge5.png';
import badgeSixImg from '../../../assets/images/ui/badge6.png';
import badgeSevenImg from '../../../assets/images/ui/badge7.png';
import badgeEightImg from '../../../assets/images/ui/badge8.png';
import badgeNineImg from '../../../assets/images/ui/badge9.png';
import badgeEqualsImg from '../../../assets/images/ui/badgeEquals.png';
import badgeMinusImg from '../../../assets/images/ui/badgeMinus.png';
import bagButtonImg from '../../../assets/images/ui/bagButton.png';
import bagButtonActiveImg from '../../../assets/images/ui/bagButtonActive.png';
import bindButtonImg from '../../../assets/images/ui/bindButton.png';
import bindButtonActiveImg from '../../../assets/images/ui/bindButtonActive.png';
import bindOnEquipIconImg from '../../../assets/images/ui/bindOnEquipIcon.png';
import bindOnPickupIconImg from '../../../assets/images/ui/bindOnPickupIcon.png';
import bookButtonImg from '../../../assets/images/ui/bookButton.png';
import bookButtonActiveImg from '../../../assets/images/ui/bookButtonActive.png';
import characterButtonImg from '../../../assets/images/ui/characterButton.png';
import characterButtonActiveImg from '../../../assets/images/ui/characterButtonActive.png';
import checkButtonImg from '../../../assets/images/ui/checkButton.png';
import coinButtonImg from '../../../assets/images/ui/coinButton.png';
import coinButtonActiveImg from '../../../assets/images/ui/coinButtonActive.png';
import crossButtonImg from '../../../assets/images/ui/crossButton.png';
import crossIconImg from '../../../assets/images/ui/crossIcon.png';
import diceButtonImg from '../../../assets/images/ui/diceButton.png';
import emptyButtonImg from '../../../assets/images/ui/emptyButton.png';
import emptyCircleImg from '../../../assets/images/ui/emptyCircleIcon.png';
import exitButtonImg from '../../../assets/images/ui/exitButton.png';
import femaleButtonImg from '../../../assets/images/ui/female.png';
import femaleActiveButtonImg from '../../../assets/images/ui/femaleActive.png';
import filledCircleImg from '../../../assets/images/ui/filledCircleIcon.png';
import fleeButtonImg from '../../../assets/images/ui/fleeButton.png';
import fleeButtonActiveImg from '../../../assets/images/ui/fleeButtonActive.png';
import healButtonImg from '../../../assets/images/ui/healButton.png';
import healButtonActiveImg from '../../../assets/images/ui/healButtonActive.png';
import inviteButtonImg from '../../../assets/images/ui/inviteButton.png';
import leftArrowButtonImg from '../../../assets/images/ui/leftArrow.png';
import maleButtonImg from '../../../assets/images/ui/male.png';
import maleActiveButtonImg from '../../../assets/images/ui/maleActive.png';
import minusButtonImg from '../../../assets/images/ui/minusButton.png';
import pageButtonImg from '../../../assets/images/ui/pageButton.png';
import pageButtonActiveImg from '../../../assets/images/ui/pageButtonActive.png';
import pencilButtonImg from '../../../assets/images/ui/pencilButton.png';
import pencilButtonActiveImg from '../../../assets/images/ui/pencilButtonActive.png';
import plusButtonImg from '../../../assets/images/ui/plusButton.png';
import powerStrikeButtonImg from '../../../assets/images/ui/powerStrikeButton.png';
import powerStrikeButtonActiveImg from '../../../assets/images/ui/powerStrikeButtonActive.png';
import reorderButtonImg from '../../../assets/images/ui/reorderButton.png';
import reorderDownButtonImg from '../../../assets/images/ui/reorderDownButton.png';
import reorderUpButtonImg from '../../../assets/images/ui/reorderUpButton.png';
import rightArrowButtonImg from '../../../assets/images/ui/rightArrow.png';
import scalesButtonImg from '../../../assets/images/ui/scalesButton.png';
import scalesButtonActiveImg from '../../../assets/images/ui/scalesButtonActive.png';
import sellButtonImg from '../../../assets/images/ui/sellButton.png';
import sellButtonActiveImg from '../../../assets/images/ui/sellButtonActive.png';
import shieldButtonImg from '../../../assets/images/ui/shieldButton.png';
import shieldButtonActiveImg from '../../../assets/images/ui/shieldButtonActive.png';
import smiteButtonImg from '../../../assets/images/ui/smiteButton.png';
import smiteButtonActiveImg from '../../../assets/images/ui/smiteButtonActive.png';
import soulboundIconImg from '../../../assets/images/ui/soulboundIcon.png';
import caveOneRoomOneTilemap from '../../../assets/tilemaps/cave_1_room_1.json';
import caveOneRoomTwoTilemap from '../../../assets/tilemaps/cave_1_room_2.json';
import caveOneRoomThreeTilemap from '../../../assets/tilemaps/cave_1_room_3.json';
import caveOneRoomFourTilemap from '../../../assets/tilemaps/cave_1_room_4.json';
import overworldTilemap from '../../../assets/tilemaps/overworld.json';
import sallowfenTilemap from '../../../assets/tilemaps/sallowfen.json';
import sallowfenInnLevelOneTilemap from '../../../assets/tilemaps/sallowfen_inn_level_1.json';
import sallowfenInnLevelTwoTilemap from '../../../assets/tilemaps/sallowfen_inn_level_2.json';
import sallowfenShopOneTilemap from '../../../assets/tilemaps/sallowfen_shop_1.json';
import sallowfenShopTwoTilemap from '../../../assets/tilemaps/sallowfen_shop_2.json';
import townTilemap from '../../../assets/tilemaps/town.json';
import {mainGameFont, tileSize} from '../../GameConfig';
import {SceneNames} from '../../types/SceneNames';

/**
 * BootScene class extending BasePhaserScene
 */
export default class BootScene extends Phaser.Scene {
    /**
     * Creates a new BootScene instance.
     */
    public constructor() {
        super(SceneNames.Boot);
    }

    /**
     * Preloads assets for the game scene.
     */
    public preload(): void {

        const width = this.cameras.main.width;
        const height = this.cameras.main.height;

        const progressBox = this.add.graphics();
        const progressBar = this.add.graphics();
        progressBox.fillStyle(0x222222, 1);
        progressBox.fillRect((width / 2) - 160, 270, 320, 50);

        const loadingText = this.make.text({
            x: width / 2,
            y: height / 2,
            text: 'Loading...',
            style: {
                font: `50px ${mainGameFont}`,
                color: '#ffffff'
            },
        });
        loadingText.setOrigin(0.5, 0.5);
        loadingText.setResolution(3);

        const percentText = this.make.text({
            x: width / 2,
            y: height / 2 + 35,
            text: '0%',
            style: {
                font: `33px ${mainGameFont}`,
                color: '#ffffff'
            }
        });
        percentText.setOrigin(0.5, 0.5);
        percentText.setResolution(3);

        this.load.on('progress', function(value: number) {
            percentText.setText(parseInt(String(value * 100)) + '%');
            progressBar.clear();
            progressBar.fillStyle(0xDB4161, 1);
            progressBar.fillRect((width / 2) - 150, 280, 300 * value, 30);
        });

        this.load.on('complete', function() {
            progressBar.destroy();
            progressBox.destroy();
            loadingText.destroy();
            percentText.destroy();
        });

        // Load any assets needed for the game scene
        this.loadImages();
        this.loadTileMap();
        this.loadSpritesheets();
    }

    /**
     * Creates and initializes game scene.
     */
    public create(): void {
        // Perform any initialization needed for the game scene

        this.scene.start(SceneNames.CharacterSelection);
    }

    /**
     * Loads image assets.
     */
    private loadImages(): void {
        this.load.image(Images.Tiles, tilesImg);
        this.load.image(Images.OverworldTiles, overworldTilesImg);
        this.load.image(Images.CaveTiles, caveTilesImg);
        this.load.image(Images.SallowfenTiles, sallowfenTilesImg);
        this.load.image(Images.SallowfenInnLevelOne, sallowfenInnLevelOneImg);
        this.load.image(Images.SallowfenInnLevelTwo, sallowfenInnLevelTwoImg);
        this.load.image(Images.ShopInterior, shopInteriorImg);
        this.load.image(Images.Shop2Interior, shop2InteriorImg);

        this.load.image(Images.DeadlyFlower, deadlyFlowerImg);
        this.load.image(Images.SeedSpiker, seedSpikerImg);
        this.load.image(Images.SentientRock, sentientRockImg);
        this.load.image(Images.ShadowKnight, shadowKnightImg);
        this.load.image(Images.Slime, slimeImg);
        this.load.image(Images.AngryMushroom, angryMushroomImg);
        this.load.image(Images.Beholder, beholderImg);
        this.load.image(Images.BlueDevil, blueDevilImg);
        this.load.image(Images.GelatinousCube, gelatinousCubeImg);
        this.load.image(Images.GoblinCultist, goblinCultistImg);
        this.load.image(Images.GoblinKing, goblinKingImg);
        this.load.image(Images.GreenDevil, greenDevilImg);
        this.load.image(Images.WillOWisp, willOWispImg);

        this.load.image(UIImageKey.AetherButton, aetherButtonImg);
        this.load.image(UIImageKey.AetherButtonActive, aetherButtonActiveImg);
        this.load.image(UIImageKey.AetherShieldButton, aetherShieldButtonImg);
        this.load.image(UIImageKey.AetherShieldButtonActive, aetherShieldButtonActiveImg);
        this.load.image(UIImageKey.ArmorButton, armorButtonImg);
        this.load.image(UIImageKey.ArmorButtonActive, armorButtonActiveImg);
        this.load.image(UIImageKey.AttackButton, attackButtonImg);
        this.load.image(UIImageKey.AttackButtonActive, attackButtonActiveImg);
        this.load.image(UIImageKey.BagButton, bagButtonImg);
        this.load.image(UIImageKey.BagButtonActive, bagButtonActiveImg);
        this.load.image(UIImageKey.BindButton, bindButtonImg);
        this.load.image(UIImageKey.BindButtonActive, bindButtonActiveImg);
        this.load.image(UIImageKey.BookButton, bookButtonImg);
        this.load.image(UIImageKey.BookButtonActive, bookButtonActiveImg);
        this.load.image(UIImageKey.CharacterButton, characterButtonImg);
        this.load.image(UIImageKey.CharacterButtonActive, characterButtonActiveImg);
        this.load.image(UIImageKey.CheckButton, checkButtonImg);
        this.load.image(UIImageKey.CoinButton, coinButtonImg);
        this.load.image(UIImageKey.CoinButtonActive, coinButtonActiveImg);
        this.load.image(UIImageKey.CrossButton, crossButtonImg);
        this.load.image(UIImageKey.DiceButton, diceButtonImg);
        this.load.image(UIImageKey.EmptyButton, emptyButtonImg);
        this.load.image(UIImageKey.FemaleActiveButton, femaleActiveButtonImg);
        this.load.image(UIImageKey.FemaleButton, femaleButtonImg);
        this.load.image(UIImageKey.FleeButton, fleeButtonImg);
        this.load.image(UIImageKey.FleeButtonActive, fleeButtonActiveImg);
        this.load.image(UIImageKey.HealButton, healButtonImg);
        this.load.image(UIImageKey.HealButtonActive, healButtonActiveImg);
        this.load.image(UIImageKey.HealthPotionButton, healthPotionButtonImg);
        this.load.image(UIImageKey.HealthPotionButtonActive, healthPotionButtonActiveImg);
        this.load.image(UIImageKey.ManaPotionButton, manaPotionButtonImg);
        this.load.image(UIImageKey.ManaPotionButtonActive, manaPotionButtonActiveImg);
        this.load.image(UIImageKey.InviteButton, inviteButtonImg);
        this.load.image(UIImageKey.LeftArrowButton, leftArrowButtonImg);
        this.load.image(UIImageKey.MaleActiveButton, maleActiveButtonImg);
        this.load.image(UIImageKey.MinusButton, minusButtonImg);
        this.load.image(UIImageKey.MaleButton, maleButtonImg);
        this.load.image(UIImageKey.PageButton, pageButtonImg);
        this.load.image(UIImageKey.PageButtonActive, pageButtonActiveImg);
        this.load.image(UIImageKey.PencilButton, pencilButtonImg);
        this.load.image(UIImageKey.PencilButtonActive, pencilButtonActiveImg);
        this.load.image(UIImageKey.PlusButton, plusButtonImg);
        this.load.image(UIImageKey.PowerStrikeButton, powerStrikeButtonImg);
        this.load.image(UIImageKey.PowerStrikeButtonActive, powerStrikeButtonActiveImg);
        this.load.image(UIImageKey.RightArrowButton, rightArrowButtonImg);
        this.load.image(UIImageKey.ScalesButton, scalesButtonImg);
        this.load.image(UIImageKey.ScalesButtonActive, scalesButtonActiveImg);
        this.load.image(UIImageKey.SellButton, sellButtonImg);
        this.load.image(UIImageKey.SellButtonActive, sellButtonActiveImg);
        this.load.image(UIImageKey.ShieldButton, shieldButtonImg);
        this.load.image(UIImageKey.ShieldButtonActive, shieldButtonActiveImg);
        this.load.image(UIImageKey.SmiteButton, smiteButtonImg);
        this.load.image(UIImageKey.SmiteButtonActive, smiteButtonActiveImg);
        this.load.image(UIImageKey.CypressiumStaffButton, cypressiumStaffButtonImg);
        this.load.image(UIImageKey.CypressiumStaffButtonActive, cypressiumStaffButtonActiveImg);
        this.load.image(UIImageKey.GaleniteBladeButton, galeniteBladeButtonImg);
        this.load.image(UIImageKey.GaleniteBladeButtonActive, galeniteBladeButtonActiveImg);
        this.load.image(UIImageKey.FadedArmbandButton, armbandButtonImg);
        this.load.image(UIImageKey.FadedArmbandButtonActive, armbandButtonActiveImg);
        this.load.image(UIImageKey.ArmorButton, armorButtonImg);
        this.load.image(UIImageKey.ArmorButtonActive, armorButtonActiveImg);
        this.load.image(UIImageKey.HiTekMonocleButton, monocleButtonImg);
        this.load.image(UIImageKey.HiTekMonocleButtonActive, monocleButtonActiveImg);
        this.load.image(UIImageKey.ScrapCloakButton, scrapCloakButtonImg);
        this.load.image(UIImageKey.ScrapCloakButtonActive, scrapCloakButtonActiveImg);
        this.load.image(UIImageKey.OaksteelShieldButton, shieldButton1Img);
        this.load.image(UIImageKey.OaksteelShieldButtonActive, shieldButton1ActiveImg);
        this.load.image(UIImageKey.SynthjuteTunicButton, synthjuteTunicButtonImg);
        this.load.image(UIImageKey.SynthjuteTunicButtonActive, synthjuteTunicButtonActiveImg);

        this.load.image(UIImageKey.CrossIcon, crossIconImg);
        this.load.image(UIImageKey.SoulboundIcon, soulboundIconImg);
        this.load.image(UIImageKey.BindOnEquipIcon, bindOnEquipIconImg);
        this.load.image(UIImageKey.BindOnPickupIcon, bindOnPickupIconImg);
        this.load.image(UIImageKey.EmptyCircleIcon, emptyCircleImg);
        this.load.image(UIImageKey.FilledCircleIcon, filledCircleImg);

        this.load.image(UIImageKey.ExitButton, exitButtonImg);
        this.load.image(UIImageKey.ReorderButton, reorderButtonImg);
        this.load.image(UIImageKey.ReorderUpButton, reorderUpButtonImg);
        this.load.image(UIImageKey.ReorderDownButton, reorderDownButtonImg);

        this.load.image(Images.OverworldBackground, overworldBackgroundImg);
        this.load.image(Images.SallowfenBackground, sallowfenBackgroundImg);
        this.load.image(Images.InnBackground, innBackgroundImg);
        this.load.image(Images.CaveBackground, caveBackgroundImg);
        this.load.image(Images.ShopBackground, shopBackgroundImg);

        this.load.image(Images.SimpleDoor1, simpleDoor1Img);

        this.load.image(Images.SimpleSign1, simpleSign1Img);

        this.load.image(UIImageKey.BadgeOne, badgeOneImg);
        this.load.image(UIImageKey.BadgeTwo, badgeTwoImg);
        this.load.image(UIImageKey.BadgeThree, badgeThreeImg);
        this.load.image(UIImageKey.BadgeFour, badgeFourImg);
        this.load.image(UIImageKey.BadgeFive, badgeFiveImg);
        this.load.image(UIImageKey.BadgeSix, badgeSixImg);
        this.load.image(UIImageKey.BadgeSeven, badgeSevenImg);
        this.load.image(UIImageKey.BadgeEight, badgeEightImg);
        this.load.image(UIImageKey.BadgeNine, badgeNineImg);
        this.load.image(UIImageKey.BadgeZero, badgeZeroImg);
        this.load.image(UIImageKey.BadgeMinus, badgeMinusImg);
        this.load.image(UIImageKey.BadgeEquals, badgeEqualsImg);

    }

    /**
     * Loads spritesheet assets.
     */
    private loadSpritesheets(): void {
        this.load.spritesheet(SpriteSheet.HeroBaseSprite, heroImg, {
            frameWidth: tileSize,
            frameHeight: tileSize,
        });

        this.load.spritesheet(SpriteSheet.HeroHairMale, heroHairImg, {
            frameWidth: tileSize,
            frameHeight: tileSize,
        });

        this.load.spritesheet(SpriteSheet.HeroHairFemale, heroHairFemaleImg, {
            frameWidth: tileSize,
            frameHeight: tileSize,
        });

        this.load.spritesheet(SpriteSheet.HeroClothesPrimary, heroClothesPrimaryImg, {
            frameWidth: tileSize,
            frameHeight: tileSize,
        });

        this.load.spritesheet(SpriteSheet.HeroClothesSecondary, heroClothesSecondaryImg, {
            frameWidth: tileSize,
            frameHeight: tileSize,
        });

        this.load.spritesheet(SpriteSheet.HeroClothesTertiary, heroClothesTertiaryImg, {
            frameWidth: tileSize,
            frameHeight: tileSize,
        });

        this.load.spritesheet(SpriteSheet.RunebladeWeaponTop, heroWeaponTopImg, {
            frameWidth: tileSize,
            frameHeight: tileSize,
        });

        this.load.spritesheet(SpriteSheet.RunebladeWeaponBottom, heroWeaponBottomImg, {
            frameWidth: tileSize,
            frameHeight: tileSize,
        });

        this.load.spritesheet(SpriteSheet.AethermancerWeaponTop, aethermancerWeaponTopImg, {
            frameWidth: tileSize,
            frameHeight: tileSize,
        });

        this.load.spritesheet(SpriteSheet.AethermancerWeaponBottom, aethermancerWeaponBottomImg, {
            frameWidth: tileSize,
            frameHeight: tileSize,
        });

        this.load.spritesheet(SpriteSheet.LifeweaverWeaponTop, lifeWeaverTopImg, {
            frameWidth: tileSize,
            frameHeight: tileSize,
        });

        this.load.spritesheet(SpriteSheet.LifeweaverWeaponBottom, lifeWeaverBottomImg, {
            frameWidth: tileSize,
            frameHeight: tileSize,
        });

        this.load.spritesheet(SpriteSheet.NPC2, npc2Img, {
            frameWidth: tileSize,
            frameHeight: tileSize,
        });

        this.load.spritesheet(SpriteSheet.NPC3, npc3Img, {
            frameWidth: tileSize,
            frameHeight: tileSize,
        });

        this.load.spritesheet(SpriteSheet.NPC4, npc4Img, {
            frameWidth: tileSize,
            frameHeight: tileSize,
        });

        this.load.spritesheet(SpriteSheet.NPC5, npc5Img, {
            frameWidth: tileSize,
            frameHeight: tileSize,
        });
    }

    /**
     * Loads tilemap assets.
     */
    private loadTileMap(): void {
        // Load tile maps
        this.load.tilemapTiledJSON(Tilemaps.Overworld, overworldTilemap);
        this.load.tilemapTiledJSON(Tilemaps.CaveOneRoomOne, caveOneRoomOneTilemap);
        this.load.tilemapTiledJSON(Tilemaps.CaveOneRoomTwo, caveOneRoomTwoTilemap);
        this.load.tilemapTiledJSON(Tilemaps.CaveOneRoomThree, caveOneRoomThreeTilemap);
        this.load.tilemapTiledJSON(Tilemaps.CaveOneRoomFour, caveOneRoomFourTilemap);
        this.load.tilemapTiledJSON(Tilemaps.Town, townTilemap);
        this.load.tilemapTiledJSON(Tilemaps.Sallowfen, sallowfenTilemap);
        this.load.tilemapTiledJSON(Tilemaps.SallowfenInnLevelOne, sallowfenInnLevelOneTilemap);
        this.load.tilemapTiledJSON(Tilemaps.SallowfenInnLevelTwo, sallowfenInnLevelTwoTilemap);
        this.load.tilemapTiledJSON(Tilemaps.SallowfenShopOne, sallowfenShopOneTilemap);
        this.load.tilemapTiledJSON(Tilemaps.SallowfenShopTwo, sallowfenShopTwoTilemap);
    }
}
