import {Images} from '../../../../types/assets/AssetKeys';
import {Coord} from '../../../../types/mechanics/Coord';
import {DepthLevel} from '../../types/DepthLevel';

export default class SignImage extends Phaser.GameObjects.Image {
    public position: Coord;
    public image: Images;

    public constructor(scene: Phaser.Scene, position: Coord, image: Images) {
        super(scene, position.x, position.y, image);
        this.setOrigin(0.5, 1); // Centered at the bottom, adjust as needed for sign
        this.setScale(3); // Adjust scale as necessary
        this.setDepth(DepthLevel.SIGN); // Assuming SIGN is a defined depth level for signs
        this.scene.add.existing(this);
        // Optionally handle visibility or interactive properties here if needed
    }
}
