// src/types/items/ItemTypes.ts

import {UIImageKey} from '../assets/AssetKeys';
import {ConflictTargetLifeStatus} from '../conflict/ConflictTargetLifeStatus';
import {TargetTypeEnum} from '../conflict/TargetTypeEnum';
import {ActionEffect} from '../mechanics/ActionEffect';
import {CharacterProfession} from '../mechanics/CharacterProfessionsEnum';
import {ResourceType} from '../mechanics/ResourceType';

export enum ItemType {
    Consumable = 'consumable',
    Weapon = 'weapon',
    BodyArmor = 'bodyarmor',
    OffHand = 'offhand',
    Helmet = 'helmet',
}

export enum ItemId {
    HealthPotion = 'healthpotion',
    ManaPotion = 'manapotion',
    CypressiumStaff = 'cypressiumstaff',
    GaleniteBlade = 'galeniteblade',
    SynthjuteTunic = 'synthjutetunic',
    ScrapCloak = 'scrapcloak',
    ChitinousVest = 'chitinousvest',
    OaksteelShield = 'oaksteelshield',
    HiTekMonocle = 'hitekmonocle',
    FadedArmband = 'fadedarmband',
}

// New enum for item binding types
export enum ItemBindingType {
    Tradable = 'tradable',
    BindOnEquip = 'bindonequip',
    BindOnPickup = 'bindonpickup',
    Soulbound = 'soulbound'
}

export interface ItemInterface {
    uniqueId: ItemId;
    minimumLevel?: number;
    activeKey: UIImageKey;
    classes?: CharacterProfession[];
    sellPrice: number;
    description: string;
    hpChange?: number;
    resourceChange?: number;
    resourceChangeType?: ResourceType;
    key: UIImageKey;
    name: string;
    stats?: { // Define stats for equipable items
        strength?: number;
        agility?: number;
        vitality?: number;
        intellect?: number;
        luck?: number;
        defense?: number;
    };
    type: ItemType;
    preTurnUseTemplate: string;
    preTurnUseSelfTemplate: string;
    useTemplate: string;
    useSelfTemplate: string;
    dodgeTemplate: string;
    validTargets: TargetTypeEnum | TargetTypeEnum[];
    moveToTopOfInitiative: boolean;
    preRoundAction: boolean;
    autoRetarget: boolean;
    targetLifeStatus: ConflictTargetLifeStatus;
    effectType: ActionEffect;
    failTemplate: string;
    bindingType: ItemBindingType;
}

// export type ItemArray = ItemInterface[];

// Define the new Map type for storing items
export type ItemsMap = Map<ItemId, ItemInterface>;
