import {Socket} from 'socket.io-client';
import {UIImageKey} from '../../../../../../types/assets/AssetKeys';
import {PlayerConflictMode} from '../../../../../../types/conflict/PlayerConflictMode';
import ServerControlledConflictUIScene from '../../../../scenes/ServerControlledUIScenes/ServerControlledConflictUIScene';
import {ConflictMenuType} from '../../../../types/ConflictMenuType';
import {DepthLevel} from '../../../../types/DepthLevel';
import SocketManager from '../../../NetworkingAndChat/SocketManager';
import NonContainerUIActionButton from '../../ActionButtons/NonContainerUIActionButton';
import ResizableFrame from '../../UtilityComponents/ResizableFrame';
import {GameConflictActionMenu} from './GameConflictActionMenu';

export class ConflictPrimaryActionMenu extends GameConflictActionMenu {

    public readonly menuTag: ConflictMenuType = ConflictMenuType.PrimaryActionMenu;
    public relatedMenusToDismiss: Set<ConflictMenuType> = new Set();
    public attackButton: NonContainerUIActionButton;
    public abilityButton: NonContainerUIActionButton;
    public inventoryButton: NonContainerUIActionButton;
    public bindButton: NonContainerUIActionButton;
    public fleeButton: NonContainerUIActionButton;
    public actionMenuFrame: ResizableFrame;
    private socket: Socket;

    public constructor(scene: ServerControlledConflictUIScene) {
        super();
        this.scene = scene;
        this.socket = SocketManager.getInstance().socket;
        // Setup UI components during construction
        this.setupFrames();
        this.setupButtons();
    }

    private setupFrames(): void {
        this.actionMenuFrame = new ResizableFrame(this.scene, 117, 574, 219, 275);
        this.actionMenuFrame.hideFrame();
    }

    private setupButtons(): void {
        this.attackButton = new NonContainerUIActionButton(
            this.scene,
            30,
            465,
            UIImageKey.AttackButton,
            UIImageKey.AttackButtonActive,
            'Attack',
            () => {
            }
        );
        this.attackButton.setDepth(DepthLevel.UI_PRIMARY_GRAPHICS_SUB);
        this.attackButton.hideActionButton();

        this.abilityButton = new NonContainerUIActionButton(
            this.scene,
            30,
            515,
            UIImageKey.BookButton,
            UIImageKey.BookButtonActive,
            'Ability',
            () => {
            }
        );
        this.abilityButton.setDepth(DepthLevel.UI_PRIMARY_GRAPHICS_SUB);
        this.abilityButton.hideActionButton();

        // Inventory Button - added at the position where the Flee button used to be
        this.inventoryButton = new NonContainerUIActionButton(
            this.scene,
            30,
            565, // Position of the previously located Flee button
            UIImageKey.BagButton, // Assuming you have keys for the inventory button images
            UIImageKey.BagButtonActive,
            'Item',
            () => {
            }
        );
        this.inventoryButton.setDepth(DepthLevel.UI_PRIMARY_GRAPHICS_SUB);
        this.inventoryButton.hideActionButton();

        // Bind Button
        this.bindButton = new NonContainerUIActionButton(
            this.scene,
            30,
            615, // New position, shifted down by 50 units
            UIImageKey.BindButton,
            UIImageKey.BindButtonActive,
            'Bind',
            () => {
            }
        );
        this.bindButton.setDepth(DepthLevel.UI_PRIMARY_GRAPHICS_SUB);
        this.bindButton.hideActionButton();

        // Flee Button - moved down by 50 units from its original position
        this.fleeButton = new NonContainerUIActionButton(
            this.scene,
            30,
            665, // New position, shifted down by 50 units
            UIImageKey.FleeButton,
            UIImageKey.FleeButtonActive,
            'Flee',
            () => {
            }
        );
        this.fleeButton.setDepth(DepthLevel.UI_PRIMARY_GRAPHICS_SUB);
        this.fleeButton.hideActionButton();

    }

    public activate(): void {
        this.actionMenuFrame.showFrame();
        this.attackButton.showActionButton();
        this.inventoryButton.showActionButton();
        this.abilityButton.showActionButton();
        this.bindButton.showActionButton();
        this.fleeButton.showActionButton();
        this.deselectAllPrimaryActionButtons();
    }
    public dismiss(): void {
        this.actionMenuFrame.hideFrame();
        this.attackButton.hideActionButton();
        this.inventoryButton.hideActionButton();
        this.abilityButton.hideActionButton();
        this.bindButton.hideActionButton();
        this.fleeButton.hideActionButton();
    }
    public emitMenuActivationRequest(): void {
        this.activate();
    }
    public emitMenuDismissalRequest(): void {
        this.dismiss();
    }

    public deselectAllPrimaryActionButtons(): void {
        this.attackButton.deselect();
        this.inventoryButton.deselect();
        this.abilityButton.deselect();
        this.fleeButton.deselect();
        if (this.scene.conflictScene.mode === PlayerConflictMode.Normal) {
            this.bindButton.deselect();
        }
    }
}
