import {SceneNames} from '../../types/SceneNames';

export default class BlackScreenScene extends Phaser.Scene {
    public constructor() {
        super(SceneNames.BlackScreen);
    }

    public create(): void {
        // Fill the scene with a black rectangle covering the whole game area
        this.add.rectangle(0, 0, this.scale.width, this.scale.height, 0x000000).setOrigin(0, 0);
        this.scene.bringToTop();
    }
}
