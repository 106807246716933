import {OperatingSystemInfo} from '../classes/System/OperatingSystemInfo';
import {OperatingSystem} from '../types/OperatingSystem';

export function adjustLayout(showChatInput: boolean): void {
    // Get the elements
    const chatContainer = document.querySelector('#chatContainer') as HTMLDivElement;
    const chatWrapper = document.querySelector('#chatWrapper') as HTMLDivElement;
    const chatWindowWrapper = document.querySelector('#chatWindowWrapper') as HTMLDivElement;
    const chatWindow = document.querySelector('#chatWindow') as HTMLDivElement;
    const chatInput = document.querySelector('#chatInput') as HTMLInputElement;

    // Check if the input is focused
    if (
        document.activeElement === chatInput &&
        OperatingSystemInfo.getInstance().operatingSystem === OperatingSystem.Mobile
    ) {
        return; // Do nothing if the chat input is currently focused
    }

    // Temporarily set the chat elements to zero size
    chatContainer.style.flex = '0 0 0%';
    chatWrapper.style.flex = '0 0 0%';
    chatWindowWrapper.style.flex = '0 0 0%';
    chatWindow.style.flex = '0 0 0%';
    chatInput.style.flex = '0 0 0%';

    // Temporarily hide the input if showChatInput is false
    chatInput.classList.add('invisible');

    // Allow time for the game container to resize
    setTimeout(() => {
        // Restore the chat elements to their original size
        chatContainer.style.flex = '1 1 auto';
        chatWrapper.style.flex = '1 1 auto';
        chatWindowWrapper.style.flex = '1 1 auto';
        chatWindow.style.flex = '1 1 auto';
        chatInput.style.flex = 'none';

        // Show the input again if showChatInput is true
        if (showChatInput) {
            chatInput.classList.remove('invisible');
            chatInput.classList.add('visible');
        } else {
            chatInput.classList.remove('visible');
        }

        // Scroll to the bottom of the chat window
        chatWindow.scrollTop = chatWindow.scrollHeight - chatWindow.clientHeight;

    }, 1000);
}
