export enum MapName {
    // In-Game Maps
    Town = 'town',
    Overworld = 'overworld',
    Sallowfen = 'sallowfen',
    SallowfenInnLevelOne = 'sallowfeninnlevelone',
    SallowfenInnLevelTwo = 'sallowfeninnleveltwo',
    SallowfenShopOne = 'sallowfenshopone',
    SallowfenShopTwo = 'sallowfenshoptwo',
    CaveOneRoomOne = 'caveoneroomone',
    CaveOneRoomTwo = 'caveoneroomtwo',
    CaveOneRoomThree = 'caveoneroomthree',
    CaveOneRoomFour = 'caveoneroomfour',
}
