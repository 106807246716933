export const primaryColors = [
    '0xd2b48c', // Tan
    '0xc0362c', // Crimson
    '0x6a0dad', // Regal Purple
    '0x7b3f00', // Deep Amber
    '0x0e34a0', // Royal Blue
    '0x346856', // Moss Green
    '0x00563f', // Pine Green
    '0x8b0000', // Dark Red
];
