import {Socket} from 'socket.io-client';
import {AbilityIdentifier} from '../../../../types/abilities/AbilityIdentifier';
import {Images} from '../../../../types/assets/AssetKeys';
import {
    ClientFullPlayerData,
    ClientMonsterData,
    ClientTeamOfFullPlayers,
    ClientTeamOfMonsters,
    ClientTeamOfTruncatedPlayers,
    ClientTruncatedPlayerData
} from '../../../../types/conflict/ClientConflictInitState';
import {ConflictActionType} from '../../../../types/conflict/ConflictActionType';
import {PlayerConflictMode} from '../../../../types/conflict/PlayerConflictMode';
import {ItemId} from '../../../../types/items/ItemTypes';
import {MapName} from '../../../../types/mapData/MapName';
import {BaseInteractionState, InteractionState} from '../../../../types/mechanics/InteractionState';
import ServerControlledConflictUISceneController
    from '../../classes/GameplayAndControl/ServerControlledConflictUISceneController';
import ConflictSocketEventHandler from '../../classes/NetworkingAndChat/ConflictSocketEventHandler';
import SocketManager from '../../classes/NetworkingAndChat/SocketManager';
import {ConflictSceneData} from '../../types/ConflictSceneData';
import {DepthLevel} from '../../types/DepthLevel';
import {SceneNames} from '../../types/SceneNames';
import {hideGamePadSceneIfNeeded} from '../../utils/hideGamePadSceneIfNeeded';
import ServerControlledConflictUIScene from '../ServerControlledUIScenes/ServerControlledConflictUIScene';
import ServerControlledGameScene from './ServerControlledGameScene';

export default class ServerControlledConflictScene extends Phaser.Scene {
    public interactionState: InteractionState;
    public eventData: ConflictSceneData;
    public isSinglePlayerPVE: boolean;
    private background: Phaser.GameObjects.Image;
    private socket: Socket;
    public gameScene: ServerControlledGameScene;
    public conflictUISceneController: ServerControlledConflictUISceneController;
    public allyTypes: Array<'ally' | 'self'>;
    private conflictSocketEventHandler: ConflictSocketEventHandler;
    public conflictGameOverBlackRectangle: Phaser.GameObjects.Rectangle;
    private conflictUIScene: ServerControlledConflictUIScene;
    public mode: PlayerConflictMode = PlayerConflictMode.Normal;
    public commandString: string = 'Command?';
    public selectedBindActionType: ConflictActionType | null = null;
    public selectedBindActionIdentifier: AbilityIdentifier | ItemId | null = null;

    public constructor() {
        super(SceneNames.ServerControlledConflict);
    }

    public create(): void {
        console.log('[LOG] create() method invoked.');

        // Logic to be done at GAME start (Note: Data will not be passed in at GAME start, only at CONFLICT start)
        // Step one: Establish a reference to the game scene
        this.initializeGameScene();

        // Step two: Establish a reference to the socket
        this.socket = SocketManager.getInstance().socket;

        // Step three: Initialize the UI (Note: We will need to go into the UI scene and split its create method into game start and conflict start just like we are doing here)
        this.initializeUI();

        // Step four: Set up new listeners (Note: We aren't going to need to remove listeners anymore since we are setting these up at GAME start)
        // this.removeAndSetupListeners(); // Assuming you're setting up listeners without needing to remove old ones now
        this.conflictSocketEventHandler = new ConflictSocketEventHandler(this);

        // Step five: Initialize the background placeholder
        this.initializeBackgroundImagePlaceholder();

        // Step six: set the background color to a light gray
        this.cameras.main.setBackgroundColor('rgba(235, 235, 235, 1)');

        // Step seven: Hide the camera
        this.cameras.main.setVisible(false);

        // Step eight: Set the scene as loaded in the game scene
        this.gameScene.setSceneLoaded('conflictScene');
    }

    public activate(data: ConflictSceneData): void {
        console.log('[ServerControlledConflictScene.activate] Activating conflict scene.');

        // Log the value of `this` to inspect the scene instance
        console.log('[ServerControlledConflictScene.activate] Value of this:', this);

        // Step zero: clear the game over black rectangle if it exists
        if (this.conflictGameOverBlackRectangle) {
            this.conflictGameOverBlackRectangle.destroy();
            console.log('[LOG] Destroyed the game over black rectangle.');
        }

        // Step one: Set the scene to the top of the stack so it's visible
        this.scene.bringToTop(SceneNames.ServerControlledConflict);

        // Log if the scene is currently active
        console.log('[ServerControlledConflictScene.activate] Is ServerControlledConflict scene active:', this.scene.isActive(SceneNames.ServerControlledConflict));

        // Log the state of `this.cameras.main` to see if it's defined
        console.log('[ServerControlledConflictScene.activate] Value of this.cameras.main before setBackgroundColor:', this.cameras.main);

        console.log('[ServerControlledConflictScene.activate] Background color set to light gray.');

        // Step two: Set the interaction state to Init
        this.interactionState = BaseInteractionState.Init;
        console.log('[LOG] Set interactionState to Init.');

        // Step three: Determine whether the conflict is single-player PVE
        this.determineWhetherSinglePlayerPVE(data);

        // Step four: Initialize the event data
        this.initializeEventData(data);

        // Step five: Stop the gamePadScene
        console.log('[ServerControlledConflictScene.activate] Stopping gamePadScene.');
        this.stopGamePadScene();

        // Step six: Hide the game action bar frame (just in case)
        this.hideGameActionBarFrame();

        // Step seven: Populate the background with conflict-specific data
        this.populateBackgroundImage(data);

        // Step eight: Stop the black screen scene if it's running
        // This is needed to transition smoothly from the loading/black screen to the conflict scene
        if (this.scene.get(SceneNames.BlackScreen) && this.scene.isActive(SceneNames.BlackScreen)) {
            console.log('[LOG] Stopping the black screen scene.');
            this.scene.stop(SceneNames.BlackScreen);
        }

        // step nine: make the scene's camera visible
        this.cameras.main.setVisible(true);

        // Step ten: Activate the conflict scene UI
        this.conflictUISceneController.conflictUIScene.activate(data);

    }

    public dismiss(): void {
        console.log('[ServerControlledConflictScene.dismiss] Dismissing conflict scene.');

        this.conflictUIScene.removePhaserKeyListeners();

        this.cameras.main.setVisible(false);

        this.conflictUIScene.cameras.main.setVisible(false);

    }

    public clearCameraBackgroundColor(): void {
        this.cameras.main.setBackgroundColor('rgba(0, 0, 0, 0)');
    }

    private isMonsterData(obj: ClientFullPlayerData | ClientTruncatedPlayerData | ClientMonsterData): obj is ClientMonsterData {
        return obj.Type === 'Monster';
    }

    private isMonstersTeam(team: ClientTeamOfFullPlayers | ClientTeamOfTruncatedPlayers | ClientTeamOfMonsters): team is ClientTeamOfMonsters {
        // Check the first element to determine the type of the whole team
        // return 'Key' in team[0];
        return (team as (ClientFullPlayerData | ClientTruncatedPlayerData | ClientMonsterData)[]).every(member => this.isMonsterData(member));
    }

    public determineWhetherSinglePlayerPVE(data: ConflictSceneData): void {
        const {allies, enemies} = data;

        // Use the type guard to check the first enemy
        this.isSinglePlayerPVE = allies.length === 1 && this.isMonstersTeam(enemies);

        console.log('[LOG] Set isSinglePlayerPVE to:', this.isSinglePlayerPVE);
    }

    public initializeGameScene(): void {
        this.gameScene = this.scene.get(SceneNames.ServerControlledGame) as ServerControlledGameScene;
        console.log('[LOG] Fetched ServerControlledGame scene.');
    }

    public initializeEventData(data: ConflictSceneData): void {
        this.eventData = data;
        // Determine 'ally' or 'self' for each member in the allies array
        this.allyTypes = data.allies.map(ally => ally.SocketId === this.socket.id ? 'self' : 'ally');
        console.log('[LOG] Set eventData and determined ally types:', this.eventData, this.allyTypes);
    }

    public stopGamePadScene(): void {
        console.log('[ServerControlledConflictScene.stopGamePadScene] Stopping gamePadScene.');
        hideGamePadSceneIfNeeded(this.gameScene);
    }

    public initializeUI(): void {
        console.log('[ServerControlledConflictScene.initializeUI] Initializing conflictUISceneController.');
        console.log('[ServerControlledConflictScene.initializeUI] Running conflictUIScene.');
        this.scene.run(SceneNames.ServerControlledConflictUI, this.eventData);
        console.log('[ServerControlledConflictScene.initializeUI] Instantiating conflictUISceneController.');
        this.conflictUISceneController = new ServerControlledConflictUISceneController(this, this.gameScene);
        this.conflictUIScene = this.scene.get(SceneNames.ServerControlledConflictUI) as ServerControlledConflictUIScene;
        console.log('[ServerControlledConflictScene.initializeUI] Setting conflictUISceneController property on serverControlledConflictUI scene.');
        this.conflictUIScene.setController(this.conflictUISceneController);
        console.log('[LOG] Created and set new conflictUISceneController.');
    }

    public initializeBackgroundImagePlaceholder(): void {
        // Initialize with an empty or default image. Adjust as necessary.
        this.background = this.add.image(0, 0, Images.OverworldBackground)
            .setOrigin(0, 0);
        this.adjustBackgroundImageDisplay();
        console.log('[LOG] Background placeholder initialized.');
    }

    public populateBackgroundImage(data: ConflictSceneData): void {
        const {mapName} = data;
        const backgroundKey = this.determineBackgroundImageKey(mapName);
        // Update the texture of the already initialized background image.
        this.background.setTexture(backgroundKey);
        this.adjustBackgroundImageDisplay(); // Ensure display settings are reapplied in case of size differences.
        console.log('[LOG] Background image updated:', backgroundKey);
    }

    private adjustBackgroundImageDisplay(): void {
        this.background.displayWidth = this.sys.canvas.width;
        this.background.displayHeight = this.sys.canvas.height - 291;
        this.background.setVisible(true)
            .setDepth(DepthLevel.NON_UI_GRAPHICS_SUB);
    }

    private determineBackgroundImageKey(mapName: MapName): Images {
        switch (mapName) {
            case MapName.Overworld:
                return Images.OverworldBackground;
            case MapName.Sallowfen:
                return Images.SallowfenBackground;
            case MapName.SallowfenInnLevelOne:
            case MapName.SallowfenInnLevelTwo:
                return Images.InnBackground;
            case MapName.CaveOneRoomOne:
            case MapName.CaveOneRoomTwo:
            case MapName.CaveOneRoomThree:
            case MapName.CaveOneRoomFour:
                return Images.CaveBackground;
            case MapName.SallowfenShopOne:
                return Images.ShopBackground;
            default:
                // Assuming a default case is needed, possibly for error handling or a generic background
                console.error(`No background defined for mapName: ${mapName}`);
                return Images.OverworldBackground; // Ensure this default key exists or is handled appropriately
        }
    }

    public hideGameActionBarFrame(): void {
        this.gameScene.serverControlledGameUIScene.gameActionBar.hideGameActionBarFrame();
    }

    public showGameActionBarFrame(): void {
        this.gameScene.serverControlledGameUIScene.gameActionBar.showGameActionBarFrame();
    }
}
