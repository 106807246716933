import numberToWords from 'number-to-words';
import {Socket} from 'socket.io-client';
import {UIImageKey} from '../../../../../../types/assets/AssetKeys';
import {DialogueOption} from '../../../../../../types/dialogue/DialogueTypes';
import {
    AttemptBuyNPCMerchantInventoryItemEvent
} from '../../../../../../types/events/AttemptBuyNPCMerchantInventoryItemEvent';
import {ServerSocketEvents} from '../../../../../../types/events/ServerSocketEvents';
// import {ItemType} from '../../../../../../types/items/ItemTypes';
// import {characterProfessionsArray} from '../../../../../../types/mechanics/CharacterProfessionsArray';
import {statAbbreviations} from '../../../../../../types/mechanics/StatAbbreviations';
import {mainGameFont} from '../../../../GameConfig';
import ServerControlledGameUIScene from '../../../../scenes/ServerControlledUIScenes/ServerControlledGameUIScene';
import {DepthLevel} from '../../../../types/DepthLevel';
import {FieldMenuType} from '../../../../types/FieldMenuType';
import {InventoryItemDetails} from '../../../../types/InventoryItemDetails';
import SocketManager from '../../../NetworkingAndChat/SocketManager';
import NonContainerUIActionButton from '../../ActionButtons/NonContainerUIActionButton';
import ResizableFrame from '../../UtilityComponents/ResizableFrame';
import {GameFieldActionMenu} from './GameFieldActionMenu';

export class MerchantNPCMenuElements extends GameFieldActionMenu{
    public shown: boolean = false;
    public menuTag = FieldMenuType.MerchantNPCMenu;
    public relatedMenusToDismiss: Set<FieldMenuType> = new Set([
        FieldMenuType.AbilityMenu,
        FieldMenuType.CharacterSheetMenu,
        FieldMenuType.TargetMenu,
        FieldMenuType.DuelNotification,
        FieldMenuType.DismissibleNotification,
        FieldMenuType.InviteNotification,
        FieldMenuType.PartyOrderMenu,
        FieldMenuType.MessageElement,
        FieldMenuType.InventoryMenu,
        FieldMenuType.TradeMenu,
        FieldMenuType.InteractableMessageDisplay,

        FieldMenuType.InteractionMenu,
    ]);
    public canCoincideWithGamePad = false;

    // Section 2: UI Components
    // Subsection 2.1: Shared Frames
    private inventoryGoldFrame: ResizableFrame;

    // Subsection 2.2: Initial View Components
    private inventoryMainListFrame: ResizableFrame;
    private inventoryCategoryFrame: ResizableFrame;
    private inventoryMainListCancelFrame: ResizableFrame;
    private inventoryMainBuyCategoryButton: NonContainerUIActionButton;
    private inventorySecondarySellCategoryButton: NonContainerUIActionButton;
    private inventoryMainListCancelButton: NonContainerUIActionButton;
    public inventoryMainListButtonInfos: {
        button: NonContainerUIActionButton;
        exists: boolean;
        selected: boolean
    }[] = [];
    private coinIcon: Phaser.GameObjects.Image;
    private goldText: Phaser.GameObjects.Text;

    // Subsection 2.3: Detail View Components
    private inventoryDetailFrame: ResizableFrame;
    private inventoryDetailConfirmButton: NonContainerUIActionButton;
    private inventoryDetailCancelButton: NonContainerUIActionButton;
    // private inventoryDetailText: Phaser.GameObjects.Text;
    private inventoryDetailNameText: Phaser.GameObjects.Text;
    private inventoryDetailTypeText: Phaser.GameObjects.Text;
    private inventoryDetailDescriptionText: Phaser.GameObjects.Text;
    private inventoryDetailMinimumLevelText: Phaser.GameObjects.Text;
    private bindingStatusText: Phaser.GameObjects.Text;
    private inventoryVericalDivider: Phaser.GameObjects.Graphics;
    private classesText: Phaser.GameObjects.Text;

    private stat1Label: Phaser.GameObjects.Text;
    private stat1Value: Phaser.GameObjects.Text;
    private stat2Label: Phaser.GameObjects.Text;
    private stat2Value: Phaser.GameObjects.Text;
    private stat3Label: Phaser.GameObjects.Text;
    private stat3Value: Phaser.GameObjects.Text;
    private stat4Label: Phaser.GameObjects.Text;
    private stat4Value: Phaser.GameObjects.Text;
    private stat5Label: Phaser.GameObjects.Text;
    private stat5Value: Phaser.GameObjects.Text;
    private stat6Label: Phaser.GameObjects.Text;
    private stat6Value: Phaser.GameObjects.Text;

    private statLabels: Phaser.GameObjects.Text[];
    private statValues: Phaser.GameObjects.Text[];

    public inventoryCostSalePriceText: Phaser.GameObjects.Text;
    private inventoryActionFrame: ResizableFrame;

    private inventoryMode: 'buy' | 'sell' = 'buy';
    private statsMap: Map<string, number> = new Map();

    private socket: Socket;
    private selectedInventoryIndex: number;

    public constructor(scene: ServerControlledGameUIScene) {
        super();
        this.scene = scene;
        this.socket = SocketManager.getInstance().socket;

        // Organized method calls
        this.setupFrames();
        this.setupTexts();
        this.setupButtons(); // Placeholder call, assuming buttons setup is required
        this.setupIcons(); // Placeholder call, assuming icons setup is required
        this.setupGraphics();
    }

    private setupGraphics(): void {
        // Create a new Graphics object and add it to the scene
        this.inventoryVericalDivider = this.scene.add.graphics();

        // Set the style properties for the line
        this.inventoryVericalDivider.lineStyle(2, 0xffffff, 1); // Width, color, and alpha

        // Draw the line
        this.inventoryVericalDivider.lineBetween(321, 119, 321, 349); // startX, startY, endX, endY

        // Set the depth of the line
        this.inventoryVericalDivider.setDepth(DepthLevel.UI_SECONDARY_OVERLAY);

        // Initially hide the line
        this.inventoryVericalDivider.setVisible(false);
    }

    // Setup Frames
    private setupFrames(): void {

        // Initial View Frames
        this.inventoryMainListFrame = new ResizableFrame(this.scene, 720, 392, 371, 409);
        this.inventoryMainListFrame.hideFrame();

        this.inventoryCategoryFrame = new ResizableFrame(this.scene, 381, 516, 276, 160);
        this.inventoryCategoryFrame.hideFrame();

        this.inventoryActionFrame = new ResizableFrame(this.scene, 117, 516, 220, 160);
        this.inventoryActionFrame.hideFrame();

        // This frame is related to both initial and other views for canceling actions
        this.inventoryMainListCancelFrame = new ResizableFrame(this.scene, 419, 370, 200, 100);
        this.inventoryMainListCancelFrame.hideFrame();

        // Detail View Frame
        this.inventoryDetailFrame = new ResizableFrame(this.scene, 263, 266, 512, 308, 2);
        this.inventoryDetailFrame.hideFrame();

        // Shared Frame (used in various views for displaying gold)
        this.inventoryGoldFrame = new ResizableFrame(this.scene, 720, 144, 371, 50);
        this.inventoryGoldFrame.hideFrame();
    }

    // Setup Texts
    private setupTexts(): void {

        // Shared Texts (used across multiple views)
        this.goldText = this.scene.add.text(584, 120, '0 gp', {
            color: '#ffffff', align: 'left', fontFamily: mainGameFont
        })
            .setResolution(3)
            .setFontSize(50)
            .setVisible(false);
        this.goldText.setDepth(DepthLevel.UI_PRIMARY_TEXT);

        // Detail View Texts

        // this.inventoryDetailText = this.scene.add.text(
        //     15,
        //     110,
        //     '',
        //     {
        //         fontSize: '45px',
        //         color: '#fff',
        //         fontFamily: mainGameFont,
        //         wordWrap: {
        //             width: 500,
        //             useAdvancedWrap: true
        //         },
        //         metrics: {
        //             ascent: 38,
        //             descent: 10,
        //             fontSize: 48
        //         }
        //     }
        // );
        // this.inventoryDetailText.setLineSpacing(-16);
        // this.inventoryDetailText.setResolution(3);
        // this.inventoryDetailText.setDepth(DepthLevel.UI_SECONDARY_TEXT);
        // this.inventoryDetailText.setVisible(false);

        this.inventoryDetailNameText = this.scene.add.text(
            15,
            110, // Positioned above the detail text
            'Item Name', // Placeholder for the item name
            {
                fontSize: '36px', // Slightly smaller font size
                color: '#fff',
                fontFamily: mainGameFont,
            }
        )
            .setResolution(3)
            .setDepth(DepthLevel.UI_SECONDARY_TEXT)
            .setVisible(false);

        this.inventoryDetailTypeText = this.scene.add.text(
            15,
            137, // Increased y-coordinate to move the text down
            'Weapon', // Initial text content
            {
                fontSize: '36px', // Reduced font size
                color: '#fff',
                fontFamily: mainGameFont,
            }
        )
            .setResolution(3)
            .setDepth(DepthLevel.UI_SECONDARY_TEXT)
            .setVisible(false);

        this.inventoryDetailDescriptionText = this.scene.add.text(
            15,
            170, // Increased y-coordinate to move the text down
            '',
            {
                fontSize: '36px', // Reduced font size
                color: '#fff',
                fontFamily: mainGameFont,
                wordWrap: {
                    width: 310,
                    useAdvancedWrap: true
                },
                metrics: {
                    ascent: 25, // Adjusted to match the new font size
                    descent: 7, // Adjusted to match the new font size
                    fontSize: 32 // Adjusted to match the new font size
                }
            }
        )
            .setLineSpacing(-10) // Adjust line spacing as needed
            .setResolution(3)
            .setDepth(DepthLevel.UI_SECONDARY_TEXT)
            .setVisible(false);

        const rightColumnTextTopLeftCornerX = 329; // X coordinate for the right column text
        const rightColumnTextTopLeftCornerY = 110; // Y coordinate for the right column text

        this.inventoryDetailMinimumLevelText = this.scene.add.text(
            rightColumnTextTopLeftCornerX, // X coordinate (adjust as needed)
            rightColumnTextTopLeftCornerY, // Y coordinate (adjust as needed)
            'Level 1', // Initial text content
            {
                fontSize: '36px',
                color: '#fff',
                fontFamily: mainGameFont,
                align: 'left', // Aligns text to the right
            }
        )
            .setOrigin(0, 0) // Aligns text to the top-right
            .setResolution(3)
            .setDepth(DepthLevel.UI_SECONDARY_TEXT)
            .setVisible(false);

        // const soulboundIconX = this.inventoryDetailMinimumLevelText.x - 25 - this.inventoryDetailMinimumLevelText.width;
        // const soulboundIconY = this.inventoryDetailMinimumLevelText.y + 5;

        const bindingStatusTextX = rightColumnTextTopLeftCornerX;
        const bindingStatusTextY = rightColumnTextTopLeftCornerY + 26;

        // Create the top line text
        this.bindingStatusText = this.scene.add.text(
            bindingStatusTextX,
            bindingStatusTextY,
            // 'Soulbound',
            'Bind on pickup',
            {
                fontSize: '36px', // Half the size
                color: '#fff',
                fontFamily: mainGameFont,
                align: 'left'
            }
        )
            .setOrigin(0, 0) // Aligns text to the top-right
            .setResolution(3)
            .setDepth(DepthLevel.UI_SECONDARY_TEXT)
            .setVisible(false);

        this.classesText = this.scene.add.text(
            rightColumnTextTopLeftCornerX,
            rightColumnTextTopLeftCornerY + 54,
            'Runeblade\nAethermancer\nLifeweaver',
            {
                fontSize: '36px',
                color: '#fff',
                fontFamily: mainGameFont,
                align: 'left'
            }
        )
            .setOrigin(0, 0)
            .setResolution(3)
            .setDepth(DepthLevel.UI_SECONDARY_TEXT)
            .setLineSpacing(-10)
            .setVisible(false);

        this.inventoryCostSalePriceText = this.scene.add.text(
            15,
            374,
            '',
            {
                fontSize: '45px',
                color: '#fff',
                fontFamily: mainGameFont,
            }
        );
        this.inventoryCostSalePriceText.setResolution(3);
        this.inventoryCostSalePriceText.setDepth(DepthLevel.UI_SECONDARY_TEXT);
        this.inventoryCostSalePriceText.setVisible(false);

        // First row
        let position = this.calculateStatPositions(0);
        this.stat1Label = this.scene.add.text(
            position.x, position.y,
            'STR:', // Strength
            {color: '#fff', fontFamily: mainGameFont, fontSize: '35px'}
        ).setResolution(3).setDepth(DepthLevel.UI_SECONDARY_TEXT).setVisible(false);

        this.stat1Value = this.scene.add.text(
            position.x, position.y + 20,
            '0', // Value for Strength
            {color: '#fff', fontFamily: mainGameFont, fontSize: '35px'}
        ).setResolution(3).setDepth(DepthLevel.UI_SECONDARY_TEXT).setVisible(false);

        position = this.calculateStatPositions(1);
        this.stat2Label = this.scene.add.text(
            position.x, position.y,
            'AGI:', // Agility
            {color: '#fff', fontFamily: mainGameFont, fontSize: '35px'}
        ).setResolution(3).setDepth(DepthLevel.UI_SECONDARY_TEXT).setVisible(false);

        this.stat2Value = this.scene.add.text(
            position.x, position.y + 20,
            '0', // Value for Agility
            {color: '#fff', fontFamily: mainGameFont, fontSize: '35px'}
        ).setResolution(3).setDepth(DepthLevel.UI_SECONDARY_TEXT).setVisible(false);

        position = this.calculateStatPositions(2);
        this.stat3Label = this.scene.add.text(
            position.x, position.y,
            'VIT:', // Vitality
            {color: '#fff', fontFamily: mainGameFont, fontSize: '35px'}
        ).setResolution(3).setDepth(DepthLevel.UI_SECONDARY_TEXT).setVisible(false);

        this.stat3Value = this.scene.add.text(
            position.x, position.y + 20,
            '0', // Value for Vitality
            {color: '#fff', fontFamily: mainGameFont, fontSize: '35px'}
        ).setResolution(3).setDepth(DepthLevel.UI_SECONDARY_TEXT).setVisible(false);

        // Second row
        position = this.calculateStatPositions(3);
        this.stat4Label = this.scene.add.text(
            position.x, position.y,
            'INT:', // Intellect
            {color: '#fff', fontFamily: mainGameFont, fontSize: '35px'}
        ).setResolution(3).setDepth(DepthLevel.UI_SECONDARY_TEXT).setVisible(false);

        this.stat4Value = this.scene.add.text(
            position.x, position.y + 20,
            '0', // Value for Intellect
            {color: '#fff', fontFamily: mainGameFont, fontSize: '35px'}
        ).setResolution(3).setDepth(DepthLevel.UI_SECONDARY_TEXT).setVisible(false);

        position = this.calculateStatPositions(4);
        this.stat5Label = this.scene.add.text(
            position.x, position.y,
            'LUK:', // Luck
            {color: '#fff', fontFamily: mainGameFont, fontSize: '35px'}
        ).setResolution(3).setDepth(DepthLevel.UI_SECONDARY_TEXT).setVisible(false);

        this.stat5Value = this.scene.add.text(
            position.x, position.y + 20,
            '0', // Value for Luck
            {color: '#fff', fontFamily: mainGameFont, fontSize: '35px'}
        ).setResolution(3).setDepth(DepthLevel.UI_SECONDARY_TEXT).setVisible(false);

        position = this.calculateStatPositions(5);
        this.stat6Label = this.scene.add.text(
            position.x, position.y,
            'DEF:', // Defense
            {color: '#fff', fontFamily: mainGameFont, fontSize: '35px'}
        ).setResolution(3).setDepth(DepthLevel.UI_SECONDARY_TEXT).setVisible(false);

        this.stat6Value = this.scene.add.text(
            position.x, position.y + 20,
            '0', // Value for Defense
            {color: '#fff', fontFamily: mainGameFont, fontSize: '35px'}
        ).setResolution(3).setDepth(DepthLevel.UI_SECONDARY_TEXT).setVisible(false);

        this.statLabels = [
            this.stat1Label,
            this.stat2Label,
            this.stat3Label,
            this.stat4Label,
            this.stat5Label,
            this.stat6Label
        ];
        this.statValues = [
            this.stat1Value,
            this.stat2Value,
            this.stat3Value,
            this.stat4Value,
            this.stat5Value,
            this.stat6Value
        ];
    }

    // Setup Buttons
    private setupButtons(): void {
        // Button initialization logic goes here

        // Initial View Buttons
        this.inventoryMainBuyCategoryButton = new NonContainerUIActionButton(
            this.scene,
            266,
            465,
            UIImageKey.BagButton,
            UIImageKey.BagButtonActive,
            'Buy',
            () => {
                console.log('Main inventory category button clicked');
                this.attemptSwitchToBuyMode();
            }
        );
        this.inventoryMainBuyCategoryButton.hideActionButton();

        this.inventorySecondarySellCategoryButton = new NonContainerUIActionButton(
            this.scene,
            266,
            516,
            UIImageKey.SellButton,
            UIImageKey.SellButtonActive,
            'Sell',
            () => {
                console.log('Secondary inventory category button clicked');
                this.attemptSwitchToSellMode();
            }
        );

        this.inventorySecondarySellCategoryButton.hideActionButton();

        this.inventoryMainListCancelButton = new NonContainerUIActionButton(
            this.scene,
            344,
            349,
            UIImageKey.CrossButton,
            UIImageKey.CrossButton,
            'Cancel',
            () => {
                this.emitMenuDismissalRequest();
            }
        );
        this.inventoryMainListCancelButton.hideActionButton();

        // Initialize additional inventory button infos, if applicable
        this.initializeInventoryButtonInfos();

        // Detail View Buttons
        this.inventoryDetailConfirmButton = new NonContainerUIActionButton(
            this.scene,
            31,
            465,
            UIImageKey.CheckButton,
            UIImageKey.CheckButton,
            'Buy',
            this.confirmInventoryAction.bind(this) // Using .bind(this) to ensure the context of `this` is preserved
        );
        this.inventoryDetailConfirmButton.setDepth(DepthLevel.UI_SECONDARY_GRAPHICS_SUB);
        this.inventoryDetailConfirmButton.hideActionButton();

        this.inventoryDetailCancelButton = new NonContainerUIActionButton(
            this.scene,
            31,
            516,
            UIImageKey.CrossButton,
            UIImageKey.CrossButton,
            'Cancel',
            () => {
                this.emitMenuDismissalRequest();
            }
        );
        this.inventoryDetailCancelButton.setDepth(DepthLevel.UI_SECONDARY_GRAPHICS_SUB);
        this.inventoryDetailCancelButton.hideActionButton();
    }

    private confirmInventoryAction(): void {
        console.log('[MerchantNPCMenuElements.confirmInventoryAction] Confirm button clicked.');
        if (this.scene.waitingForServerResponse) {
            return;
        }
        this.scene.waitingForServerResponse = true;
        if (this.inventoryMode === 'buy') {
            const eventData: AttemptBuyNPCMerchantInventoryItemEvent = {
                inventoryItemIndex: this.selectedInventoryIndex,
            };
            console.log(`[MerchantNPCMenuElements.confirmInventoryAction] Emitting AttemptBuyNPCMerchantInventoryItem event with data: ${JSON.stringify(eventData, null, 2)}`);
            this.socket.emit(ServerSocketEvents.AttemptBuyNPCMerchantInventoryItem, eventData);
        } else if (this.inventoryMode === 'sell') {
            const eventData = {inventoryItemIndex: this.selectedInventoryIndex};
            console.log(`[MerchantNPCMenuElements.confirmInventoryAction] Emitting AttemptSellNPCMerchantInventoryItem event with data: ${JSON.stringify(eventData, null, 2)}`);
            this.socket.emit(ServerSocketEvents.AttemptSellNPCMerchantInventoryItem, eventData);
        }
    }

    private initializeInventoryButtonInfos(): void {
        // Check if the initialization has already been done to prevent duplication
        if (this.inventoryMainListButtonInfos.length === 0) {
            for (let i = 0; i < 8; i++) {
                const abilityButtonInfo = {
                    button: this.createInventoryItemButton(i),
                    exists: false, // Initialize as false; will be updated based on actual abilities
                    selected: false // Initialize as false; will be updated based on actual abilities
                };
                this.inventoryMainListButtonInfos.push(abilityButtonInfo);
            }
        } else {
            // Optionally, log a message or handle cases where reinitialization might be intended but needs to be controlled
            console.log('Ability buttons have already been initialized.');
        }
    }

    private createInventoryItemButton(index: number): NonContainerUIActionButton {
        const numberWord = numberToWords.toWords(index + 1);
        const capitalizedWord = numberWord.charAt(0).toUpperCase() + numberWord.slice(1);
        const label = `Item ${capitalizedWord}`;

        const inventoryItemButton = new NonContainerUIActionButton(
            this.scene,
            564,
            216 + (index * 50),
            UIImageKey.BagButton,
            UIImageKey.BagButtonActive,
            label,
            () => {
                // Button click logic...
            }
        );

        inventoryItemButton.hideActionButton();
        inventoryItemButton.setDepth(DepthLevel.UI_PRIMARY_GRAPHICS_SUB);

        return inventoryItemButton;
    }

    // Setup Icons
    private setupIcons(): void {
        this.coinIcon = this.scene.add.image(563, 145, UIImageKey.CoinButton)
            .setScale(2);
        this.coinIcon.setDepth(DepthLevel.UI_PRIMARY_GRAPHICS_SUB);
        this.coinIcon.setVisible(false);
    }

    public activate(params: {npcName: string, dialogue: string, options: DialogueOption[]}): void {
        console.log('[MerchantNPCMenuElements.activate] Entering method. Activating Innkeeper NPC Menu.');
        console.log(`[MerchantNPCMenuElements.activate] params: ${JSON.stringify(params, null, 2)}`);
        console.log('[MerchantNPCMenuElements.activate] setting this.shown to true');
        this.shown = true;
        this.dismissRelatedMenus();
        this.scene.serverControlledGameScene.player.inNPCDialogue = true;

        this.scene.commonerNPCMenu.activate({
            npcName: params.npcName,
            dialogue: params.dialogue,
            options: params.options,
        });

        // Show player's current gold (removed)
        console.log('[MerchantNPCMenuElements.activate] Exiting method.');
    }

    public dismiss(): void {
        console.log('[MerchantNPCMenuElements.dismiss] Entering method. Dismissing Innkeeper NPC Menu');
        this.scene.serverControlledGameScene.player.inNPCDialogue = false;

        this.scene.commonerNPCMenu.hideDialogueElements();
        // this.hideDialogueElements();

        this.hideInventoryElements();

        this.activateInteractionMenuIfNeeded();
        console.log('[MerchantNPCMenuElements.dismiss] setting this.shown to false');
        this.shown = false;
        console.log('[MerchantNPCMenuElements.dismiss] Exiting method.');
    }

    private showStats(): void {
        console.log('[showStats] Updating visibility of stat labels and values', JSON.stringify(Array.from(this.statsMap.entries()), null, 2));
        const statCount = this.statsMap.size; // Determine the number of stats provided from the Map
        for (let i = 0; i < this.statLabels.length; i++) {
            if (i < statCount) {
                this.statLabels[i].setVisible(true); // Only make visible up to the number of provided stats
                this.statValues[i].setVisible(true);
                console.log(`[showStats] Made visible label and value at index ${i}`);
            } else {
                this.statLabels[i].setVisible(false); // Hide all other labels and values
                this.statValues[i].setVisible(false);
                console.log(`[showStats] Hid label and value at index ${i}`);
            }
        }
    }

    private hideStats(): void {
        for (let i = 0; i < this.statLabels.length; i++) {
            this.statLabels[i].setVisible(false);
            this.statValues[i].setVisible(false);
        }
    }

    public hideDetailInventoryView(): void {
        this.inventoryDetailFrame.hideFrame();
        this.inventoryActionFrame.hideFrame();

        // this.inventoryDetailText.setVisible(false);
        this.inventoryDetailNameText.setVisible(false);
        this.inventoryDetailTypeText.setVisible(false);
        this.inventoryDetailDescriptionText.setVisible(false);
        this.inventoryDetailMinimumLevelText.setVisible(false);
        this.bindingStatusText.setVisible(false);
        this.inventoryVericalDivider.setVisible(false);
        this.classesText.setVisible(false);

        this.hideStats();

        this.inventoryCostSalePriceText.setVisible(false);
        this.inventoryDetailConfirmButton.hideActionButton();
        this.inventoryDetailCancelButton.hideActionButton();
    }

    private hideInventoryElements(): void {
        this.hideInitialInventoryView();
        this.hideDetailInventoryView();
    }

    public emitMenuActivationRequest(params: {npcName: string, dialogue: string, options: DialogueOption[]}): void {
        if (this.scene.serverControlledGameScene.player.processingFieldAction) {
            return;
        }
        if (params) {
            this.activate(params);
        }
    }

    public emitMenuDismissalRequest(): void{
        if (this.scene.waitingForServerResponse) {
            return;
        }
        this.scene.waitingForServerResponse = true;
        this.socket.emit(ServerSocketEvents.AttemptInteract);
    }

    public attemptSwitchToSellMode(): void {
        if (this.scene.waitingForServerResponse) {
            return;
        }
        this.scene.waitingForServerResponse = true;
        this.socket.emit(ServerSocketEvents.AttemptSwitchMerchantMenuToSellMode);
    }

    public attemptSwitchToBuyMode(): void {
        if (this.scene.waitingForServerResponse) {
            return;
        }
        this.scene.waitingForServerResponse = true;
        this.socket.emit(ServerSocketEvents.AttemptSwitchMerchantMenuToBuyMode);
    }

    public showInitialInventoryView(mode: 'buy' | 'sell' = 'buy'): void {
        console.log(`[MerchantNPCMenuElements.showInitialInventoryView] Showing initial inventory view in mode: ${mode}`);
        this.inventoryMode = mode;
        // Show the main list frame, category frame, page one button, etc.
        this.hideDetailInventoryView();
        this.inventoryMainListFrame.showFrame();
        this.inventoryCategoryFrame.showFrame();
        this.inventoryGoldFrame.showFrame();
        this.inventoryMainListCancelFrame.showFrame();
        this.inventoryMainBuyCategoryButton.showActionButton();
        this.inventorySecondarySellCategoryButton.showActionButton();
        this.inventoryMainListCancelButton.showActionButton();
        this.goldText.setVisible(true);
        this.coinIcon.setVisible(true);

        // Select the appropriate category button based on the mode
        if (mode === 'buy') {
            this.inventoryMainBuyCategoryButton.select();
            this.inventorySecondarySellCategoryButton.deselect();
        } else {
            this.inventorySecondarySellCategoryButton.select();
            this.inventoryMainBuyCategoryButton.deselect();
        }

        // Loop through and show only the existing inventory buttons
        this.inventoryMainListButtonInfos.forEach(buttonInfo => {
            if (buttonInfo.exists) {
                buttonInfo.button.showActionButton();
            } else {
                buttonInfo.button.hideActionButton();
            }
        });
    }

    public hideInitialInventoryView(): void {
        // Hide the components specific to the initial view
        this.inventoryMainListFrame.hideFrame();
        this.inventoryCategoryFrame.hideFrame();
        this.inventoryMainListCancelFrame.hideFrame();
        this.inventoryMainBuyCategoryButton.hideActionButton();
        this.inventorySecondarySellCategoryButton.hideActionButton();
        this.inventoryMainListCancelButton.hideActionButton();
        this.inventoryGoldFrame.hideFrame();
        this.coinIcon.setVisible(false);
        this.goldText.setVisible(false);
        // Hide all inventory buttons, regardless of their existing state
        this.inventoryMainListButtonInfos.forEach(buttonInfo => buttonInfo.button.hideActionButton());
    }

    public populateInitialBuyInventoryView(items: {name: string, key: UIImageKey, activeKey: UIImageKey}[], gold: number): void {
        console.log(`[MerchantNPCMenuElements.populateInitialView] Populating initial view with items: ${JSON.stringify(items, null, 2)}`);
        console.log(`[MerchantNPCMenuElements.populateInitialView] Gold: ${gold}`);
        // Ensure all buttons are reset to not exist before updating based on new abilities
        this.clearInventoryButtons();
        // Update buttons based on the abilities provided
        items.forEach((inventoryItem, index) => {
            if (index < this.inventoryMainListButtonInfos.length) {
                const buttonInfo = this.inventoryMainListButtonInfos[index];
                buttonInfo.exists = true;
                buttonInfo.button.changeButtonImage(inventoryItem.key, inventoryItem.activeKey);
                buttonInfo.button.changeButtonText(inventoryItem.name);
                buttonInfo.button.deselect();
                buttonInfo.button.showActionButton();

                // Update button target callback to emit event when inventory item is selected
                buttonInfo.button.targetCallback = (): void => {
                    if (this.scene.waitingForServerResponse) {
                        return;
                    }
                    this.scene.waitingForServerResponse = true;
                    console.log(`[inventory item callback] Item ${index} clicked.`);
                    const eventData = {inventoryItemIndex: index}; // Assuming pageIndex is always 0 for this setup
                    this.socket.emit(ServerSocketEvents.AttemptSelectNPCMerchantInventoryItemForPurchase, eventData);
                };
            }
        });

        this.goldText.setText(`${gold.toLocaleString()} gp`);
    }

    public populateInitialSellInventoryView(items: {name: string, key: UIImageKey, activeKey: UIImageKey}[], gold: number): void {
        console.log(`[MerchantNPCMenuElements.populateInitialSellInventoryView] Populating sell mode initial view with items: ${JSON.stringify(items, null, 2)}`);
        // Ensure all buttons are reset to not exist before updating based on new items
        this.clearInventoryButtons();

        // Update buttons based on the items provided for sell mode
        items.forEach((item, index) => {
            if (index < this.inventoryMainListButtonInfos.length) {
                const buttonInfo = this.inventoryMainListButtonInfos[index];
                buttonInfo.exists = true;
                buttonInfo.button.changeButtonImage(item.key, item.activeKey);
                buttonInfo.button.changeButtonText(item.name);
                buttonInfo.button.deselect();
                buttonInfo.button.showActionButton();

                // Set up the callback for when an inventory item is selected in sell mode
                buttonInfo.button.targetCallback = (): void => {
                    if (this.scene.waitingForServerResponse) {
                        return;
                    }
                    this.scene.waitingForServerResponse = true;
                    console.log(`[Sell inventory item callback] Item ${index} clicked.`);
                    const eventData = {inventoryItemIndex: index}; // Assuming pageIndex is always 0 for this setup
                    this.socket.emit(ServerSocketEvents.AttemptSelectPlayerInventoryItemForSale, eventData);
                };
            }
        });

        // No need to update gold text as it remains constant and is displayed elsewhere
        this.goldText.setText(`${gold.toLocaleString()} gp`);
    }

    private depopulateStats(): void {
        console.log('[depopulateStats] Clearing all stat texts.');
        // Iterate through all stat labels and values to clear them
        this.statLabels.forEach((label) => {
            label.setText(''); // Clear the text and make the label invisible
        });
        this.statValues.forEach((value) => {
            value.setText(''); // Clear the text and make the value invisible
        });
    }

    private calculateStatPositions(index: number): { x: number, y: number } {
        // Constants for layout configuration
        const columnWidth = 100; // Width of each stat column
        const rowHeight = 45; // Height between rows of stats

        // Calculate the starting Y position based on the description text's bottom
        const startY = this.inventoryDetailDescriptionText.y + this.inventoryDetailDescriptionText.height - 10; // Additional 10 pixels for spacing

        // Calculate positions based on column and row determined by index
        const x = 15 + (index % 3) * columnWidth; // Calculate X position within the row
        const y = startY + Math.floor(index / 3) * rowHeight; // Calculate Y position for each row

        return {x, y};
    }

    private populateStats(stats: {[key: string]: number}): void {
        console.log('[populateStats] Starting population of stats:', JSON.stringify(stats, null, 2));
        this.statsMap = new Map(Object.entries(stats));

        for (let i = 0; i < this.statLabels.length; i++) {
            const statLabel = this.statLabels[i];
            const statValue = this.statValues[i];
            if (i < Object.keys(stats).length) {
                const statName = Object.keys(stats)[i];
                const statAbbreviation = statAbbreviations.get(statName) || statName;
                const statValueString = stats[statName].toString();

                // Use the new method to calculate position
                const {x, y} = this.calculateStatPositions(i);

                // Set positions and text for labels and values
                statLabel.setX(x).setY(y).setText(`${statAbbreviation}:`);
                statValue.setX(x).setY(y + 20).setText(statValueString); // Position value right below the label

                console.log(`[populateStats] Set ${statAbbreviation} to ${statValueString}`, JSON.stringify({statName, statValue: stats[statName]}, null, 2));
            } else {
                // Clear text and reposition off-screen or make invisible if not needed
                statLabel.setText('').setVisible(false);
                statValue.setText('').setVisible(false);
                console.log(`[populateStats] Cleared label and value at index ${i}`);
            }
        }
    }

    public populateBuyDetailView(data: InventoryItemDetails): void {
        console.log(`[MerchantNPCMenuElements.populateBuyDetailView] Populating buy detail view with data: ${JSON.stringify(data, null, 2)}`);
        // Select and mark the selected inventory item button
        this.inventoryMainListButtonInfos.forEach((buttonInfo, index) => {
            if (index === data.inventoryItemIndex) {
                buttonInfo.button.select();
                buttonInfo.selected = true;
            } else {
                buttonInfo.button.deselect();
                buttonInfo.selected = false;
            }
        });

        this.inventoryDetailNameText.setText(data.inventoryItemName);

        this.bindingStatusText.setText(data.inventoryItemBindingType.charAt(0).toUpperCase() + data.inventoryItemBindingType.slice(1));

        if (data.inventoryItemClasses && data.inventoryItemClasses.length > 0) {
            const classesTextContent = data.inventoryItemClasses.join('\n');
            this.classesText.setText(classesTextContent);
        } else {
            this.classesText.setText('');
        }

        this.inventoryDetailTypeText.setText(data.inventoryItemType.charAt(0).toUpperCase() + data.inventoryItemType.slice(1));

        this.inventoryDetailDescriptionText.setText(data.inventoryItemDescription);

        if (data.inventoryItemStats) {
            this.populateStats(data.inventoryItemStats);
        } else {
            this.depopulateStats();
        }

        this.selectedInventoryIndex = data.inventoryItemIndex;
        this.inventoryCostSalePriceText.setText(`Cost: ${data.inventoryItemCostSalePrice.toLocaleString()} gp`);
        this.inventoryDetailConfirmButton.changeButtonText('Buy');
    }

    public populateSellDetailView(data: InventoryItemDetails): void {
        console.log(`[MerchantNPCMenuElements.populateSellDetailView] Populating sell detail view with data: ${JSON.stringify(data, null, 2)}`);
        // Select and mark the selected inventory item button
        this.inventoryMainListButtonInfos.forEach((buttonInfo, index) => {
            if (index === data.inventoryItemIndex) {
                buttonInfo.button.select();
                buttonInfo.selected = true;
            } else {
                buttonInfo.button.deselect();
                buttonInfo.selected = false;
            }
        });

        // Populate the text fields and store the selected inventory item information
        // this.inventoryDetailText.setText(data.inventoryItemDescription);

        this.inventoryDetailNameText.setText(data.inventoryItemName);
        this.inventoryDetailTypeText.setText(data.inventoryItemType.charAt(0).toUpperCase() + data.inventoryItemType.slice(1));
        this.inventoryDetailDescriptionText.setText(data.inventoryItemDescription);
        this.bindingStatusText.setText(data.inventoryItemBindingType.charAt(0).toUpperCase() + data.inventoryItemBindingType.slice(1));
        if (data.inventoryItemClasses && data.inventoryItemClasses.length > 0) {
            const classesTextContent = data.inventoryItemClasses.join('\n');
            this.classesText.setText(classesTextContent);
        } else {
            this.classesText.setText('');
        }
        if (data.inventoryItemStats) {
            this.populateStats(data.inventoryItemStats);
        } else {
            this.depopulateStats();
        }

        this.selectedInventoryIndex = data.inventoryItemIndex;
        this.inventoryCostSalePriceText.setText(`Sale Price: ${data.inventoryItemCostSalePrice.toLocaleString()} gp`); // Adjusted label for clarity in selling context
        this.inventoryDetailConfirmButton.changeButtonText('Sell');
    }

    public showDetailView(): void {
        // Show the detail frame, ability text, resource cost text, and detail action buttons
        this.inventoryDetailFrame.showFrame();
        this.inventoryActionFrame.showFrame();
        // this.inventoryDetailText.setVisible(true);
        this.inventoryDetailNameText.setVisible(true);
        this.inventoryDetailTypeText.setVisible(true);
        this.inventoryDetailDescriptionText.setVisible(true);
        this.inventoryDetailMinimumLevelText.setVisible(true);
        this.bindingStatusText.setVisible(true);
        this.inventoryVericalDivider.setVisible(true);
        this.classesText.setVisible(true);
        this.showStats();
        this.inventoryCostSalePriceText.setVisible(true);
        this.inventoryDetailConfirmButton.showActionButton();
        this.inventoryDetailCancelButton.showActionButton();

        // Hide the components from the initial view as needed
        this.inventoryMainListCancelFrame.hideFrame();
    }

    private clearInventoryButtons(): void {
        this.inventoryMainListButtonInfos.forEach(buttonInfo => {
            buttonInfo.exists = false; // Reset the existence flag
            buttonInfo.selected = false; // Reset the selected state
            buttonInfo.button.hideActionButton(); // Hide the button from the UI
        });
    }

}
