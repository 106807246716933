import {Socket} from 'socket.io-client';
import {SpriteSheet, UIImageKey} from '../../../../../../types/assets/AssetKeys';
import {ServerSocketEvents} from '../../../../../../types/events/ServerSocketEvents';
import {CharacterProfession} from '../../../../../../types/mechanics/CharacterProfessionsEnum';
import {Gender} from '../../../../../../types/mechanics/Gender';
import {ResourceType} from '../../../../../../types/mechanics/ResourceType';
import {TargetRelationshipType} from '../../../../../../types/mechanics/TargetRelationshipType';
import {TargetPlayerResponse} from '../../../../../../types/playerInfo/TargetPlayerData';
import {heroWeaponColor} from '../../../../data/heroWeaponColor';
import {tertiaryColor} from '../../../../data/tertiaryColor';
import {mainGameFont} from '../../../../GameConfig';
import ServerControlledGameUIScene from '../../../../scenes/ServerControlledUIScenes/ServerControlledGameUIScene';
import {DepthLevel} from '../../../../types/DepthLevel';
import {FieldMenuType} from '../../../../types/FieldMenuType';
import {adjustFontSizeByPixel} from '../../../../utils/phaserTextUtils';
import FramedUIActionButton from '../../ActionButtons/FramedUIActionButton';
import ResizableFrame from '../../UtilityComponents/ResizableFrame';
import {GameFieldActionMenu} from './GameFieldActionMenu';

export default class TargetMenu extends GameFieldActionMenu{
    public shown: boolean = false;

    public menuTag = FieldMenuType.TargetMenu;
    public relatedMenusToDismiss: Set<FieldMenuType> = new Set([
        FieldMenuType.AbilityMenu,
        FieldMenuType.CharacterSheetMenu,
        FieldMenuType.DuelNotification,
        FieldMenuType.DismissibleNotification,
        FieldMenuType.InviteNotification,
        FieldMenuType.PartyOrderMenu,
        FieldMenuType.CommonerNPCMenu,
        FieldMenuType.InnkeeperNPCMenu,
        FieldMenuType.MerchantNPCMenu,
        FieldMenuType.MessageElement,
        FieldMenuType.InventoryMenu,
        FieldMenuType.TradeMenu,
        FieldMenuType.InteractableMessageDisplay,

        // Any other menu that should be dismissed when AbilityMenu is active
    ]);
    public canCoincideWithGamePad = true;

    private closeTargetSummaryButton: Phaser.GameObjects.Image;
    private duelButton: FramedUIActionButton;
    private inviteButton: FramedUIActionButton;
    private targetAethermancerWeaponBottomSprite: Phaser.GameObjects.Sprite;
    private targetAethermancerWeaponTopSprite: Phaser.GameObjects.Sprite;
    private targetLifeweaverWeaponBottomSprite: Phaser.GameObjects.Sprite;
    private targetLifeweaverWeaponTopSprite: Phaser.GameObjects.Sprite;
    private targetPlayerBaseSprite: Phaser.GameObjects.Sprite;
    private targetPlayerFemaleHairSprite: Phaser.GameObjects.Sprite;
    private targetPlayerHPText: Phaser.GameObjects.Text;
    private targetPlayerResourceText: Phaser.GameObjects.Text;
    private targetPlayerLevelText: Phaser.GameObjects.Text;
    private targetPlayerMaleHairSprite: Phaser.GameObjects.Sprite;
    private targetPlayerName: Phaser.GameObjects.Text;
    private targetPrimaryClothingSprite: Phaser.GameObjects.Sprite;
    private targetRunebladeWeaponBottomSprite: Phaser.GameObjects.Sprite;
    private targetRunebladeWeaponTopSprite: Phaser.GameObjects.Sprite;
    private targetSecondaryClothingSprite: Phaser.GameObjects.Sprite;
    private targetSummaryFrame: ResizableFrame;
    private targetTertiaryClothingSprite: Phaser.GameObjects.Sprite;
    private tradeButton: FramedUIActionButton;

    public constructor(public scene: ServerControlledGameUIScene, private socket: Socket) {
        super();
        const centerX = this.scene.cameras.main.centerX;
        const centerY = this.scene.cameras.main.centerY;

        this.inviteButton = new FramedUIActionButton(
            this.scene,
            centerX - 96,
            centerY - 24,
            UIImageKey.InviteButton,
            UIImageKey.InviteButton,
            () => {
                console.log('invite button clicked');
                this.socket.emit(ServerSocketEvents.AttemptInvite);
            }
        );
        this.inviteButton.hideActionButton();

        this.duelButton = new FramedUIActionButton(
            this.scene,
            centerX + 96,
            centerY - 24,
            UIImageKey.AttackButton,
            UIImageKey.AttackButtonActive,
            () => {
                // function to be executed when the button is clicked
                console.log('Duel button clicked');
                this.socket.emit(ServerSocketEvents.AttemptDuel);
            }
        );
        this.duelButton.hideActionButton();

        this.tradeButton = new FramedUIActionButton(
            this.scene,
            centerX + 96,
            centerY - 24,
            UIImageKey.ScalesButton,
            UIImageKey.ScalesButtonActive,
            () => {
                console.log('Trade button clicked');
                this.socket.emit(ServerSocketEvents.AttemptTrade);
            }
        );
        this.tradeButton.hideActionButton();

        this.targetSummaryFrame = new ResizableFrame(this.scene, centerX, centerY + 96, 400, 100);
        this.targetSummaryFrame.hideFrame();
        // Create the close button at (0, 0)
        this.closeTargetSummaryButton = this.scene.add.image(0, 0, UIImageKey.CrossIcon).setInteractive().setDepth(DepthLevel.UI_PRIMARY_GRAPHICS_SUB).setScale(1.5);

        this.targetRunebladeWeaponBottomSprite = this.scene.add.sprite(centerX + 145, centerY + 72, SpriteSheet.RunebladeWeaponBottom);
        this.targetRunebladeWeaponBottomSprite.setDepth(DepthLevel.UI_PRIMARY_GRAPHICS_SUB);
        this.targetRunebladeWeaponBottomSprite.setVisible(false);

        this.targetAethermancerWeaponBottomSprite = this.scene.add.sprite(centerX + 145, centerY + 72, SpriteSheet.AethermancerWeaponBottom);
        this.targetAethermancerWeaponBottomSprite.setDepth(DepthLevel.UI_PRIMARY_GRAPHICS_SUB);
        this.targetAethermancerWeaponBottomSprite.setVisible(false);

        this.targetLifeweaverWeaponBottomSprite = this.scene.add.sprite(centerX + 145, centerY + 72, SpriteSheet.LifeweaverWeaponBottom);
        this.targetLifeweaverWeaponBottomSprite.setDepth(DepthLevel.UI_PRIMARY_GRAPHICS_SUB);
        this.targetLifeweaverWeaponBottomSprite.setVisible(false);

        this.targetPlayerBaseSprite = this.scene.add.sprite(centerX + 145, centerY + 72, SpriteSheet.HeroBaseSprite);
        this.targetPlayerBaseSprite.setDepth(DepthLevel.UI_PRIMARY_GRAPHICS_SUB);
        this.targetPlayerBaseSprite.setVisible(false);
        this.targetPrimaryClothingSprite = this.scene.add.sprite(centerX + 145, centerY + 72, SpriteSheet.HeroClothesPrimary);
        this.targetPrimaryClothingSprite.setDepth(DepthLevel.UI_PRIMARY_GRAPHICS_SUB);
        this.targetPrimaryClothingSprite.setVisible(false);

        this.targetSecondaryClothingSprite = this.scene.add.sprite(centerX + 145, centerY + 72, SpriteSheet.HeroClothesSecondary);
        this.targetSecondaryClothingSprite.setDepth(DepthLevel.UI_PRIMARY_GRAPHICS_SUB);
        this.targetSecondaryClothingSprite.setVisible(false);

        this.targetTertiaryClothingSprite = this.scene.add.sprite(centerX + 145, centerY + 72, SpriteSheet.HeroClothesTertiary);
        this.targetTertiaryClothingSprite.setDepth(DepthLevel.UI_PRIMARY_GRAPHICS_SUB);
        this.targetTertiaryClothingSprite.setVisible(false);

        this.targetPlayerMaleHairSprite = this.scene.add.sprite(centerX + 145, centerY + 72, SpriteSheet.HeroHairMale);
        this.targetPlayerMaleHairSprite.setDepth(DepthLevel.UI_PRIMARY_GRAPHICS_SUB);
        this.targetPlayerMaleHairSprite.setVisible(false);

        this.targetPlayerFemaleHairSprite = this.scene.add.sprite(centerX + 145, centerY + 72, SpriteSheet.HeroHairFemale);
        this.targetPlayerFemaleHairSprite.setDepth(DepthLevel.UI_PRIMARY_GRAPHICS_SUB);
        this.targetPlayerFemaleHairSprite.setVisible(false);

        this.targetRunebladeWeaponTopSprite = this.scene.add.sprite(centerX + 145, centerY + 72, SpriteSheet.RunebladeWeaponTop);
        this.targetRunebladeWeaponTopSprite.setDepth(DepthLevel.UI_PRIMARY_GRAPHICS_SUB);
        this.targetRunebladeWeaponTopSprite.setVisible(false);

        this.targetAethermancerWeaponTopSprite = this.scene.add.sprite(centerX + 145, centerY + 72, SpriteSheet.AethermancerWeaponTop);
        this.targetAethermancerWeaponTopSprite.setDepth(DepthLevel.UI_PRIMARY_GRAPHICS_SUB);
        this.targetAethermancerWeaponTopSprite.setVisible(false);

        this.targetLifeweaverWeaponTopSprite = this.scene.add.sprite(centerX + 145, centerY + 72, SpriteSheet.LifeweaverWeaponTop);
        this.targetLifeweaverWeaponTopSprite.setDepth(DepthLevel.UI_PRIMARY_GRAPHICS_SUB);
        this.targetLifeweaverWeaponTopSprite.setVisible(false);

        const playerName = 'Player Name'; // replace this with the actual player name
        this.targetPlayerName = this.scene.add.text(centerX - 190, centerY + 40, playerName, {fontFamily: mainGameFont, fontSize: '42px', color: '#ffffff'})
            .setResolution(3);
        this.targetPlayerName.setDepth(DepthLevel.UI_PRIMARY_TEXT);
        this.targetPlayerName.setVisible(false);

        this.targetPlayerLevelText = this.scene.add.text(centerX - 190, centerY + 77, 'Level 1 Soldier', {fontFamily: mainGameFont, fontSize: '36px', color: '#ffffff'})
            .setResolution(3).setDepth(DepthLevel.UI_PRIMARY_TEXT);
        this.targetPlayerLevelText.setVisible(false);

        this.targetPlayerHPText = this.scene.add.text(centerX - 190, centerY + 110, 'HP: 14/14', {fontFamily: mainGameFont, fontSize: '42px', color: '#ffffff'})
            .setResolution(3).setDepth(DepthLevel.UI_PRIMARY_TEXT);
        this.updateTargetPlayerHP(14, 14);
        this.targetPlayerHPText.setVisible(false);

        this.targetPlayerResourceText = this.scene.add.text(centerX, centerY + 110, 'MP: 14/14', {fontFamily: mainGameFont, fontSize: '42px', color: '#ffffff'})
            .setResolution(3).setDepth(DepthLevel.UI_PRIMARY_TEXT);
        this.targetPlayerResourceText.setVisible(false);

        // Define frame dimensions
        const frameWidth = 400;
        const frameHeight = 100;
        // Calculate the desired position of the close button
        const closeButtonX = centerX + frameWidth / 2 - this.closeTargetSummaryButton.displayWidth / 2 - 5; // Move 2 pixels to the left
        const closeButtonY = centerY + 96 - frameHeight / 2 + this.closeTargetSummaryButton.displayHeight / 2 + 5; // Move 2 pixels down

        // Set the position of the close button
        this.closeTargetSummaryButton.setPosition(closeButtonX, closeButtonY);

        console.log('Game scene width:', this.scene.cameras.main.width);
        console.log('Game scene height:', this.scene.cameras.main.height);
        console.log('Close button x:', this.closeTargetSummaryButton.x);
        console.log('Close button y:', this.closeTargetSummaryButton.y);

        // Register click event for the close image
        this.closeTargetSummaryButton.on('pointerdown', () => this.emitAttemptClearTargetEvent());
        this.closeTargetSummaryButton.setVisible(false);

    }

    public emitAttemptClearTargetEvent(): void {
        // Emit the 'attemptClearTarget' signal to the server
        this.socket.emit(ServerSocketEvents.AttemptClearTarget);
    }

    public updateTargetPlayerHP(currentHP: number, maxHP: number): void {
        this.targetPlayerHPText.setText(`HP: ${currentHP}/${maxHP}`);
    }

    public updateTargetPlayerResource(resourceType: ResourceType, currentResource: number, maxResource: number): void {
        if (resourceType === ResourceType.MP) {
            this.targetPlayerResourceText.setText(`MP: ${currentResource}/${maxResource}`);
        } else if (resourceType === ResourceType.Vim) {
            this.targetPlayerResourceText.setText('');
        }
    }

    /**
     * Activates targeting logic based on the provided response from a target player.
     * Handles the user interface updates, targeting validations, and response handling.
     * @param response The response object containing details about the target player.
     */
    public activate(response: TargetPlayerResponse): void {
        console.log(`[TargetMenu.activate] Received response: ${JSON.stringify(response)}`);

        // Early exit if the targeting response indicates failure.
        if (!response.success) {
            console.log('Unsuccessful targeting');
            return;
        }

        // Set UI state to shown and dismiss any other related menus.
        this.shown = true;
        this.dismissRelatedMenus();
        this.scene.partyOrderMenu.dismiss();
        this.scene.characterSheetMenu.dismiss();
        this.scene.gameActionBar.characterSheetButton.deselect();
        this.scene.gameActionBar.abilityMenuButton.deselect();
        this.scene.abilityMenu.dismiss();
        this.scene.abilityMenu.abilityIsArmed = false;
        this.scene.abilityMenu.shown = false;
        this.scene.dismissibleNotification.dismiss();

        // Deconstruct necessary properties from the successful response.
        const {
            targetSocketId,
            relationshipType,
            level,
            currentHP,
            maxHP,
            skinColor,
            hairColor,
            primaryColor,
            secondaryColor,
            name,
            gender,
            className,
            currentResource,
            maxResource,
            resourceType
        } = response;

        console.log('Received targetPlayer event:', response);

        // Validate the presence of a targetSocketId to proceed.
        if (!targetSocketId) {
            console.log('Target socket ID is not provided');
            return;
        }

        console.log(`Socket ID ${targetSocketId} successfully targeted.`);

        // Determine if the target is the local player or a remote player.
        const localPlayerSocketId = this.socket.id;
        let targetedPlayer;
        // Check if the targetSocketId matches the local player's socket ID
        if (targetSocketId === localPlayerSocketId) {
            targetedPlayer = this.scene.serverControlledGameScene.player;
        } else {
            // If not, try to find the player in the remote players
            targetedPlayer = this.scene.serverControlledGameScene.remotePlayers.get(targetSocketId);
        }

        // Exit if the targeted player is already targeted.
        if (targetedPlayer && targetedPlayer.targeted) {
            console.log('Player is already targeted');
            return;
        }

        // Reset all players' borders and targeted status
        this.scene.serverControlledGameScene.player.hideBorder();
        this.scene.serverControlledGameScene.player.targeted = false;
        // Iterate over each remote player in the game
        Array.from(this.scene.serverControlledGameScene.remotePlayers.values()).forEach(player => {
            player.hideBorder(); // Assuming hideBorder() is a method that modifies the player's border visibility
            player.targeted = false; // Reset the targeted state
        });

        // Early exit if no player corresponds to the provided socket ID.
        if (!targetedPlayer) {
            console.log('No player with this socket ID exists');
            return;
        }

        // Highlight and set the targeted player.
        if (targetedPlayer.visible) {
            targetedPlayer.showBorder();
        }
        targetedPlayer.targeted = true;

        // UI cleanup and preparation for new target information display.
        this.scene.duelNotification.dismiss();
        this.targetSummaryFrame.showFrame();
        this.closeTargetSummaryButton.setVisible(true);
        this.hideTargetPlayerSprites();

        // Update the targeted player's visual representation based on the provided details.
        this.targetPlayerBaseSprite.setTint(Number(skinColor));
        this.targetPlayerBaseSprite.setVisible(true);

        switch (gender) {
            case Gender.Male:
                this.targetPlayerMaleHairSprite.setTint(Number(hairColor));
                this.targetPlayerMaleHairSprite.setVisible(true);
                break;
            case Gender.Female:
                this.targetPlayerFemaleHairSprite.setTint(Number(hairColor));
                this.targetPlayerFemaleHairSprite.setVisible(true);
                break;
            default:
                this.targetPlayerMaleHairSprite.setTint(Number(hairColor));
                this.targetPlayerMaleHairSprite.setVisible(true);
                break;
        }

        // Adjust clothing and accessory colors based on response.
        this.targetPrimaryClothingSprite.setTint(Number(primaryColor));
        this.targetPrimaryClothingSprite.setVisible(true);
        this.targetSecondaryClothingSprite.setTint(Number(secondaryColor));
        this.targetSecondaryClothingSprite.setVisible(true);
        this.targetTertiaryClothingSprite.setTint(Number(tertiaryColor));
        this.targetTertiaryClothingSprite.setVisible(true);

        // Update weapon sprites for character classes.
        switch (className) {
            case CharacterProfession.Runeblade:
                this.targetRunebladeWeaponBottomSprite.setTint(Number(heroWeaponColor));
                this.targetRunebladeWeaponBottomSprite.setVisible(true);
                this.targetRunebladeWeaponTopSprite.setTint(Number(heroWeaponColor));
                this.targetRunebladeWeaponTopSprite.setVisible(true);
                break;
            case CharacterProfession.Aethermancer:
                this.targetAethermancerWeaponBottomSprite.setTint(Number(heroWeaponColor));
                this.targetAethermancerWeaponBottomSprite.setVisible(true);
                this.targetAethermancerWeaponTopSprite.setTint(Number(heroWeaponColor));
                this.targetAethermancerWeaponTopSprite.setVisible(true);
                break;
            case CharacterProfession.Lifeweaver:
                this.targetLifeweaverWeaponBottomSprite.setTint(Number(heroWeaponColor));
                this.targetLifeweaverWeaponBottomSprite.setVisible(true);
                this.targetLifeweaverWeaponTopSprite.setTint(Number(heroWeaponColor));
                this.targetLifeweaverWeaponTopSprite.setVisible(true);
                break;
            default:
                this.targetRunebladeWeaponBottomSprite.setTint(Number(heroWeaponColor));
                this.targetRunebladeWeaponBottomSprite.setVisible(true);
                this.targetRunebladeWeaponTopSprite.setTint(Number(heroWeaponColor));
                this.targetRunebladeWeaponTopSprite.setVisible(true);
                break;
        }

        // Display the targeted player's name and level information.
        this.targetPlayerName.setText(name);
        this.targetPlayerName.setFontSize('42px');
        adjustFontSizeByPixel(
            this.targetPlayerName,
            300,
            42
        );
        this.targetPlayerName.setVisible(true);

        this.targetPlayerLevelText.setText(`Level ${level} ${className}`);
        this.targetPlayerLevelText.setVisible(true);

        // Handle action buttons and display options based on the relationship type of the targeted player.
        switch (relationshipType) {
            // When the player is targeting themselves, hide action buttons and update health and resource displays.
            case TargetRelationshipType.TARGETER_SOLO_TARGETING_SELF:
            case TargetRelationshipType.TARGETER_PARTY_LEADER_TARGETING_SELF:
            case TargetRelationshipType.TARGETER_PARTY_FOLLOWER_TARGETING_SELF:
                this.duelButton.hideActionButton();
                this.tradeButton.hideActionButton();
                this.inviteButton.hideActionButton();
                this.updateTargetPlayerHP(currentHP!, maxHP!);
                this.targetPlayerHPText.setVisible(true);
                this.updateTargetPlayerResource(resourceType!, currentResource!, maxResource!);
                this.targetPlayerResourceText.setVisible(true);
                break;

            // For solo or party leader player targeting another solo player, show duel and invite action buttons.
            case TargetRelationshipType.TARGETER_SOLO_TARGETING_SOLO:
            case TargetRelationshipType.TARGETER_PARTY_LEADER_TARGETING_SOLO:
                this.duelButton.showActionButton();
                this.tradeButton.hideActionButton();
                this.inviteButton.showActionButton();
                this.targetPlayerHPText.setVisible(false);
                this.targetPlayerResourceText.setVisible(false);
                break;

            // When a player targets another player where a duel is applicable but a party invitation is not, show only duel actions.
            // This includes cases where a solo player targets a party leader or follower, or a party member targets someone from a different party,
            // as well as a party follower targeting a solo player or other party positions from different parties. These scenarios all suggest
            // competitive or adversarial interactions rather than cooperative, hence only the duel button is enabled.
            case TargetRelationshipType.TARGETER_SOLO_TARGETING_PARTY_LEADER:
            case TargetRelationshipType.TARGETER_SOLO_TARGETING_PARTY_FOLLOWER:
            case TargetRelationshipType.TARGETER_PARTY_LEADER_TARGETING_PARTY_LEADER_DIFF_PARTY:
            case TargetRelationshipType.TARGETER_PARTY_LEADER_TARGETING_PARTY_FOLLOWER_DIFF_PARTY:
            case TargetRelationshipType.TARGETER_PARTY_FOLLOWER_TARGETING_SOLO:
            case TargetRelationshipType.TARGETER_PARTY_FOLLOWER_TARGETING_PARTY_LEADER_DIFF_PARTY:
            case TargetRelationshipType.TARGETER_PARTY_FOLLOWER_TARGETING_PARTY_FOLLOWER_DIFF_PARTY:
                this.duelButton.showActionButton();
                this.tradeButton.hideActionButton();
                this.inviteButton.hideActionButton();
                this.targetPlayerHPText.setVisible(false);
                this.targetPlayerResourceText.setVisible(false);
                break;

            // For interactions within the same party, hide all action buttons except trade and update health and resources.
            case TargetRelationshipType.TARGETER_PARTY_LEADER_TARGETING_PARTY_FOLLOWER_SAME_PARTY:
            case TargetRelationshipType.TARGETER_PARTY_FOLLOWER_TARGETING_PARTY_LEADER_SAME_PARTY:
            case TargetRelationshipType.TARGETER_PARTY_FOLLOWER_TARGETING_PARTY_FOLLOWER_SAME_PARTY:
                this.duelButton.hideActionButton();
                this.inviteButton.hideActionButton();
                // leave the below line uncommented to show the trade button. we aren't ready to show the trade button
                //  until trade functionality is finished.
                this.tradeButton.showActionButton();
                this.updateTargetPlayerHP(currentHP!, maxHP!);
                this.targetPlayerHPText.setVisible(true);
                this.updateTargetPlayerResource(resourceType!, currentResource!, maxResource!);
                this.targetPlayerResourceText.setVisible(true);
                break;

            // Default case to hide all action buttons if none of the above conditions are met.
            default:
                this.duelButton.hideActionButton();
                this.tradeButton.hideActionButton();
                this.inviteButton.hideActionButton();
                this.targetPlayerHPText.setVisible(false);
                this.targetPlayerResourceText.setVisible(false);
                break;
        }

        console.log('[TargetMenu.activate] Exiting method.');
    }

    public dismiss(): void {
        console.log('[TargetMenu.dismiss] Entering method. Dismissing Target Menu');
        // Reset the local player's border and targeted status
        this.scene.serverControlledGameScene.player.hideBorder();
        this.scene.serverControlledGameScene.player.targeted = false;

        // Iterate over the remote players to reset their borders, targeted status, and specific flags
        this.scene.serverControlledGameScene.remotePlayers.forEach((remotePlayer) => {
            remotePlayer.hideBorder();
            remotePlayer.targeted = false;
            remotePlayer.isTargetedForDuel = false;
            remotePlayer.isTargetedForInvite = false;
        });

        // Hide UI components related to targeting and player interaction
        this.targetSummaryFrame.hideFrame();
        this.duelButton.hideActionButton();
        this.tradeButton.hideActionButton();
        this.inviteButton.hideActionButton();
        this.closeTargetSummaryButton.setVisible(false);
        this.targetPlayerBaseSprite.setVisible(false);
        this.targetPlayerMaleHairSprite.setVisible(false);
        this.targetPlayerFemaleHairSprite.setVisible(false);
        this.targetPrimaryClothingSprite.setVisible(false);
        this.targetSecondaryClothingSprite.setVisible(false);
        this.targetTertiaryClothingSprite.setVisible(false);
        this.targetRunebladeWeaponBottomSprite.setVisible(false);
        this.targetRunebladeWeaponTopSprite.setVisible(false);
        this.targetAethermancerWeaponBottomSprite.setVisible(false);
        this.targetAethermancerWeaponTopSprite.setVisible(false);
        this.targetLifeweaverWeaponBottomSprite.setVisible(false);
        this.targetLifeweaverWeaponTopSprite.setVisible(false);
        this.targetPlayerName.setVisible(false);
        this.targetPlayerLevelText.setVisible(false);
        // Ensure these additional elements are also hidden
        this.targetPlayerHPText.setVisible(false);
        this.targetPlayerResourceText.setVisible(false);

        this.shown = false; // Make sure to update the visibility status
        console.log('[TargetMenu.dismiss] Exiting method.');
    }

    public hideTargetPlayerSprites(): void {
        // Hide the base sprite of the player
        this.targetPlayerBaseSprite.setVisible(false);

        // Hide the hair sprites
        this.targetPlayerMaleHairSprite.setVisible(false);
        this.targetPlayerFemaleHairSprite.setVisible(false);

        // Hide the clothing sprites
        this.targetPrimaryClothingSprite.setVisible(false);
        this.targetSecondaryClothingSprite.setVisible(false);
        this.targetTertiaryClothingSprite.setVisible(false);

        // Hide the weapon sprites for each profession
        this.targetRunebladeWeaponBottomSprite.setVisible(false);
        this.targetRunebladeWeaponTopSprite.setVisible(false);
        this.targetAethermancerWeaponBottomSprite.setVisible(false);
        this.targetAethermancerWeaponTopSprite.setVisible(false);
        this.targetLifeweaverWeaponBottomSprite.setVisible(false);
        this.targetLifeweaverWeaponTopSprite.setVisible(false);

        // Any additional sprite components would also be set to invisible here
    }

    public emitMenuActivationRequest(params: {socketId: string}): void {
        // Check if the player is currently processing a field ability
        if (!this.scene.canProceedWithMenuAction()) {
            // If false, exit early. Logging is already handled within `canProceedWithMenuAction`.
            return;
        }

        console.log('[TargetMenu.emitMenuActivationRequest] Emitting attempt to open target menu event with params: ', params);
        this.scene.waitingForServerResponse = true;
        this.socket.emit(ServerSocketEvents.AttemptTarget, params.socketId);

    }

    public emitMenuDismissalRequest(): void {
        this.dismiss();
    }

}
