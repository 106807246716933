// src/client/app/classes/PlayerInputController.ts

import {Socket} from 'socket.io-client';
import {ServerSocketEvents} from '../../../../types/events/ServerSocketEvents';
import {Direction} from '../../../../types/physics/Direction';
import ServerControlledGameScene from '../../scenes/ServerControlledGameplayScenes/ServerControlledGameScene';
import ChatManager from '../NetworkingAndChat/ChatManager';

type KeyStates = {
    [key in Direction]: boolean;
};

export default class PlayerInputController {
    private socket: Socket;
    private scene: ServerControlledGameScene;
    private keyStates: KeyStates;
    public lastSentDirection: Direction;
    public pressedDirections: Direction[];
    private interactKeyDown: boolean;
    private shiftKeyDown: boolean;

    public constructor(scene: ServerControlledGameScene, socket: Socket) {
        this.scene = scene;
        this.socket = socket;
        this.keyStates = {
            [Direction.LEFT]: false,
            [Direction.UP]: false,
            [Direction.RIGHT]: false,
            [Direction.DOWN]: false,
            [Direction.NONE]: false,
        };
        this.lastSentDirection = Direction.NONE;
        this.pressedDirections = [];
        this.interactKeyDown = false;
        this.shiftKeyDown = false;

        // Add a window blur event listener to handle when the window loses focus
        window.addEventListener('blur', this.handleWindowBlur.bind(this));
    }

    private handleWindowBlur(): void {
        if (this.canPlayerTurnOrMove()) {
            // Reset all key states and pressed directions
            for (const direction in this.keyStates) {
                if (Object.prototype.hasOwnProperty.call(this.keyStates, direction)) {
                    this.keyStates[direction as Direction] = false;
                }
            }
            this.pressedDirections.length = 0;
            this.shiftKeyDown = false; // Ensure shift key state is also reset
            this.interactKeyDown = false; // Reset interact key state as well

            // Stop emitting events when the window loses focus
            this.lastSentDirection = Direction.NONE;
            this.socket.emit(ServerSocketEvents.CursorMovement, {direction: Direction.NONE});
        }
    }

    public handleKeyDown(event: KeyboardEvent): void {
        if (document.activeElement === ChatManager.chatInput) {
            return;
        }

        // Check if the SHIFT key is being pressed down
        if (event.key === 'Shift') {
            this.shiftKeyDown = true;
        }

        const direction = this.getDirectionFromEvent(event);
        if (direction !== Direction.NONE) {
            this.keyStates[direction] = true;

            // Send turn signal if SHIFT is held down and it is allowed to turn
            if (this.shiftKeyDown) {
                if (this.canPlayerTurnOrMove()) {
                    this.socket.emit(ServerSocketEvents.CursorTurn, {direction: direction});
                }
            } else {
                // Add to pressed directions if SHIFT is not held down
                if (!this.pressedDirections.includes(direction)) {
                    this.pressedDirections.push(direction);
                }
            }
        }

        // Handle the spacebar keydown for interaction
        if (event.key === ' ') {
            console.log('[PlayerInputController.handleKeyDown] Spacebar keydown event detected.');
            console.log(`[PlayerInputController.handleKeyDown] this.interactKeyDown: ${this.interactKeyDown}`);
            if (!this.interactKeyDown) {
                console.log('[PlayerInputController.handleKeyDown] this.interactKeyDown is false.');
                // Check if the player is currently processing a field ability
                if (!this.scene.serverControlledGameUIScene.canProceedWithMenuAction()) {
                    console.log('[PlayerInputController.handleKeyDown] this.scene.canProceedWithMenuAction() returned false. Exiting early.');
                    // If false, exit early. Logging is already handled within `canProceedWithMenuAction`.
                    return;
                }
                if (this.scene.player.isPartyFollower) {
                    console.log('[PlayerInputController.handleKeyDown] this.scene.player.isPartyFollower is true. Exiting early.');
                    // If the player is a party follower, they cannot interact with the environment
                    return;
                }

                console.log('[PlayerInputController.handleKeyDown] setting this.scene.waitingForServerResponse = true;');
                this.scene.serverControlledGameUIScene.waitingForServerResponse = true;
                console.log('[PlayerInputController.handleKeyDown] emitting ServerSocketEvents.AttemptInteract');
                this.socket.emit(ServerSocketEvents.AttemptInteract);

                console.log('[PlayerInputController.handleKeyDown] setting this.interactKeyDown = true');
                this.interactKeyDown = true;
            }
        }
    }

    public handleKeyUp(event: KeyboardEvent): void {
        if (document.activeElement === ChatManager.chatInput) {
            return;
        }

        if (event.key === 'Shift') {
            this.shiftKeyDown = false;
        }

        const direction = this.getDirectionFromEvent(event);
        if (direction !== Direction.NONE) {
            this.keyStates[direction] = false;
            const index = this.pressedDirections.indexOf(direction);
            if (index > -1) {
                this.pressedDirections.splice(index, 1);
            }
        }

        // Reset the spacebar keydown state for interaction
        if (event.key === ' ') {
            this.interactKeyDown = false;
        }
    }

    private canPlayerTurnOrMove(): boolean {
        return this.scene.player &&
            !this.scene.player.inConflict &&
            !this.scene.player.isPartyFollower &&
            !this.scene.player.isSwitchingMaps &&
            !this.scene.player.inNPCDialogue &&
            !this.scene.player.processingFieldAction &&
            !this.scene.serverControlledGameUIScene.waitingForServerResponse &&
            !this.scene.player.isInteractingWithInteractable;
    }

    public update(): void {
        // Use the new canPlayerTurnOrMove method to check if movement updates should be sent
        if (!this.canPlayerTurnOrMove()) {
            return;
        }
        const direction =
            this.pressedDirections.length > 0
                ? this.pressedDirections[this.pressedDirections.length - 1]
                : Direction.NONE;

        if (direction !== this.lastSentDirection) {
            console.log('direction !== this.lastSentDirection -> emitting cursorMovement from PlayerInputController.update method:');
            console.log(`direction: ${direction}`);
            console.log(`this.lastSentDirection: ${this.lastSentDirection}`);
            console.log({socketID: this.socket.id, direction});
            this.socket.emit(ServerSocketEvents.CursorMovement, {direction});
            this.lastSentDirection = direction;
        }
    }

    private getDirectionFromEvent(event: KeyboardEvent): Direction {
        const key = event.key.toLowerCase();
        switch (key) {
            case 'arrowleft':
            case 'a':
                return Direction.LEFT;
            case 'arrowup':
            case 'w':
                return Direction.UP;
            case 'arrowright':
            case 'd':
                return Direction.RIGHT;
            case 'arrowdown':
            case 's':
                return Direction.DOWN;
            default:
                return Direction.NONE;
        }
    }
}
