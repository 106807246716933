export enum FieldMenuType {
    CharacterSheetMenu = 'CharacterSheetMenu',
    AbilityMenu = 'AbilityMenu',
    TargetMenu = 'TargetMenu',
    DuelNotification = 'DuelNotification',
    DismissibleNotification = 'DismissibleNotification',
    InviteNotification = 'InviteNotification',
    PartyOrderMenu = 'PartyOrderMenu',
    CommonerNPCMenu = 'CommonerNPCMenu',
    InnkeeperNPCMenu = 'InnkeeperNPCMenu',
    InteractionMenu = 'InteractionMenu',
    MessageElement = 'MessageElement',
    InventoryMenu = 'InventoryMenu',
    MerchantNPCMenu = 'MerchantNPCMenu',
    TradeMenu = 'TradeMenu',
    InteractableMessageDisplay = 'InteractableMessageDisplay',
}
