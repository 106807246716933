import {UIImageKey} from '../../../../types/assets/AssetKeys';
import {mainGameFont} from '../../GameConfig';
import {DepthLevel} from '../../types/DepthLevel';
// import eventsCenter from '../../utils/EventsCenter';
import NonContainerUIActionButton from '../UserInterface/ActionButtons/NonContainerUIActionButton';
import ResizableFrame from '../UserInterface/UtilityComponents/ResizableFrame';

export class VirtualKeyboard {
    private scene: Phaser.Scene;
    public cursor!: Phaser.GameObjects.Rectangle;
    public capsLock!: boolean;
    public keyboardButtonDictionary!: { [key: string]: Phaser.GameObjects.Text };
    public keyboardInputTextArray!: Phaser.GameObjects.Text[];
    public keysList = [
        ['q', 'w', 'e', 'r', 't', 'y', 'u', 'i', 'o', 'p'],
        ['a', 's', 'd', 'f', 'g', 'h', 'j', 'k', 'l'],
        ['z', 'x', 'c', 'v', 'b', 'n', 'm']
    ];
    private acceptButton!: NonContainerUIActionButton;
    private capsLockButton!: Phaser.GameObjects.Text;
    private deleteButton!: Phaser.GameObjects.Text;
    private rejectButton!: NonContainerUIActionButton;
    private spaceButton!: Phaser.GameObjects.Text;
    private timer = 0;
    private keyboardFrame: ResizableFrame;
    private keyboardInputFrame: ResizableFrame;
    private rightSideOptionsFrame: ResizableFrame;
    private paused = false;
    private onAccept: (inputText: string) => void;
    private onReject: () => void;

    public constructor(scene: Phaser.Scene, data: {
        purpose: string,
        onAccept: (inputText: string) => void, // Accept callback
        onReject: () => void // Reject callback
    }) {
        this.scene = scene;
        this.onAccept = data.onAccept;
        this.onReject = data.onReject;

        this.keyboardInputTextArray = [];
        this.keyboardButtonDictionary = {};
        this.capsLock = false;

        this.keyboardFrame = new ResizableFrame(
            this.scene,
            345,
            494,
            645, // Replace with the desired width
            225 // Replace with the desired height
        );

        this.keyboardInputFrame = new ResizableFrame(
            this.scene,
            345,
            320,
            645,
            75
        );

        this.createKeyboardKeys();

        // create the cursor rectangle
        this.cursor = this.scene.add.rectangle(
            // position it at the location of the next letter
            65,
            320,
            // set its width and height to match the size of a letter
            37,
            50,
            // set its color to white
            0xbcbcbc
        )
            .setVisible(true)
            .setDepth(DepthLevel.UI_PRIMARY_GRAPHICS_SUB);

        this.createKeyboardInputText();

        this.rightSideOptionsFrame = new ResizableFrame(
            this.scene,
            790,
            442,
            200,
            120
        );

        this.acceptButton = new NonContainerUIActionButton(
            this.scene,
            720,
            415,
            UIImageKey.CheckButton,
            UIImageKey.CheckButton,
            'Accept',
            this.handleAccept.bind(this)
        );
        this.acceptButton.setDepth(DepthLevel.UI_PRIMARY_GRAPHICS_SUB);

        this.rejectButton = new NonContainerUIActionButton(
            this.scene,
            720,
            465,
            UIImageKey.CrossButton,
            UIImageKey.CrossButton,
            'Reject',
            this.handleReject.bind(this)
        );

        if (data.purpose === 'playernameselect') {
            this.rejectButton.showActionButton();
        }

        this.spaceButton = this.scene.add.text(
            250,
            530,
            'space',
            {
                fontSize: '70px',
                color: '#fff',
                fontFamily: mainGameFont
            }
        )
            .setInteractive()
            .on('pointerdown', () => {
                this.inputLetter(' ');
            })
            .setDepth(DepthLevel.UI_PRIMARY_TEXT);

        this.deleteButton = this.scene.add.text(
            580,
            480,
            'del',
            {
                fontSize: '70px',
                color: '#fff',
                fontFamily: mainGameFont
            }
        )
            .setInteractive()
            .on('pointerdown', () => {
                this.backspace();

            })
            .setDepth(DepthLevel.UI_PRIMARY_TEXT);

        this.capsLockButton = this.scene.add.text(
            545,
            530,
            'caps',
            {
                fontSize: '70px',
                color: '#fff',
                fontFamily: mainGameFont
            }
        )
            .setInteractive()
            .on('pointerdown', () => {
                this.capsLock = !this.capsLock;
                for (const keyboardButton of Object.values(this.keyboardButtonDictionary)) {
                    if (this.capsLock) {
                        keyboardButton.setText(keyboardButton.text.toUpperCase());

                    } else {
                        keyboardButton.setText(keyboardButton.text.toLowerCase());
                    }
                }
            })
            .setDepth(DepthLevel.UI_PRIMARY_TEXT);

        this.scene.input.keyboard!.on('keydown', (event: KeyboardEvent) => {
            if (this.paused) {
                return;
            }
            // Get the key that was pressed
            const key = event.key;
            // Check if the key is a letter
            if (key.length === 1 && key.match(/[a-z]/i)) {
                // Call the function that inputs the letter
                this.inputLetter(key);
            } else if (key === ' ') { // Check if the key is the space bar
                // Call the function that inputs a space
                this.inputLetter(' ');
            } else if (key === 'Backspace') {
                this.backspace();
            }/* else if (key === 'Enter') {
                // if (this.getFullStringFromInput().length === 0) {
                //     return;
                // }
                // eventsCenter.emit('keyboardaccept', [this.getFullStringFromInput()]);
                this.handleAccept();
            } else if (key === 'Escape') {
                // eventsCenter.emit('keyboardreject');
                // this.scene.stop();
                this.handleReject();
            }*/

        });

    }

    private createKeyboardKeys(): void {
        for (let rowIndex = 0; rowIndex < this.keysList.length; rowIndex++) {
            const row = this.keysList[rowIndex];
            for (let colIndex = 0; colIndex < row.length; colIndex++) {
                const char = row[colIndex];

                this.keyboardButtonDictionary[char] = this.scene.add.text(
                    62 + (60 * colIndex) + (16 * rowIndex),
                    413 + (55 * rowIndex),
                    char,
                    {
                        fontSize: '70px',
                        color: '#fff',
                        fontFamily: mainGameFont
                    })
                    .setOrigin(0.5, 0.5)
                    .setDepth(DepthLevel.UI_PRIMARY_TEXT);

                this.scene.add.rectangle(
                    62 + (60 * colIndex) + (16 * rowIndex),
                    413 + (55 * rowIndex),
                    55,
                    50,
                    0x000000,
                    0
                )
                    .setInteractive()
                    .on(
                        'pointerdown',
                        () => {
                            this.inputLetter(char);
                        }
                    )
                    .setDepth(DepthLevel.UI_PRIMARY_GRAPHICS_SUB);
            }
        }
    }

    private getFullStringFromInput(): string {
        let string = '';
        for (const inputCharacter of this.keyboardInputTextArray) {
            if (inputCharacter.text !== '') {
                string += inputCharacter.text;
            }
        }
        return string;
    }

    private setInputFromString(string: string): void {
        // setting input from string
        for (let i = 0; i < this.keyboardInputTextArray.length; i++) {
            this.keyboardInputTextArray[i].setText(string[i] ?? '');
        }
    }

    private inputLetter(char: string): void {
        if (this.paused) {
            return;
        }

        let adjustedChar = char;
        if (this.capsLock) {
            adjustedChar = char.toUpperCase();
        }

        // check that the string length is 14 and doesn't end with an underscore
        if (this.getFullStringFromInput().length === 14) {
            // cut the last letter off the string
            const stringMinusLastLetter = this.getFullStringFromInput().slice(0, -1);
            // set the input to the modified with the pressed character appended
            this.setInputFromString(stringMinusLastLetter + adjustedChar);
        } else { // string might be 14 characters long or might not end with an underscore
            // but not both
            // set the input text to...
            this.setInputFromString(
                // the input text without trailing underscores plus the pressed character
                this.getFullStringFromInput() + adjustedChar
            );
            if (this.getFullStringFromInput().length < 14) {
                this.cursor.setX(this.cursor.x + 43);
            }
        }

    }

    private backspace(): void {
        if (this.paused) {
            return;
        }

        const originalString = this.getFullStringFromInput();
        if (originalString.length === 0) {
            return;
        }
        const stringMinusLastLetter = originalString.slice(0, -1);
        this.setInputFromString(stringMinusLastLetter);
        if (stringMinusLastLetter.length === 13) {
            return;
        }
        this.cursor.setX(this.cursor.x - 43);
    }

    private createKeyboardInputText(): void {

        for (let i = 0; i < 14; i++) {
            this.keyboardInputTextArray.push(
                this.scene.add.text(
                    65 + (i * 43),
                    320,
                    '',
                    {
                        fontSize: '70px',
                        color: '#fff',
                        fontFamily: mainGameFont,
                    })
                    .setOrigin(0.5, 0.5)
                    .setDepth(DepthLevel.UI_PRIMARY_TEXT)
            );
        }
    }

    // Method to pause the keyboard
    public pause(): void {
        this.paused = true;

        // Disable callbacks for interactive elements without hiding them
        Object.values(this.keyboardButtonDictionary).forEach(button => {
            button.removeInteractive();
        });
        this.spaceButton.removeInteractive();
        this.deleteButton.removeInteractive();
        this.capsLockButton.removeInteractive();

    }

    // Method to unpause the keyboard
    public unpause(): void {
        console.log('[VirtualKeyboard,unpause()] Unpausing keyboard.');
        this.paused = false;

        // Re-enable interactivity and original callbacks
        Object.values(this.keyboardButtonDictionary).forEach(button => {
            button.setInteractive();
        });
        this.spaceButton.setInteractive();
        this.deleteButton.setInteractive();
        this.capsLockButton.setInteractive();

    }

    public handleAccept(): void {
        console.log(`Handling accept action. Current pause state: ${this.paused}`);
        if (this.paused) {
            console.log('Accept action ignored because virtual keyboard is paused.');
            return;
        }
        const fullString = this.getFullStringFromInput();
        console.log(`Full input string: '${fullString}'`);
        if (fullString.length > 0) {
            this.onAccept(fullString); // Directly invoke the accept callback
        }

    }

    public handleReject(): void {
        console.log(`Handling reject action. Current pause state: ${this.paused}`);
        if (this.paused) {
            console.log('Reject action ignored because virtual keyboard is paused.');
            return;
        }
        this.onReject(); // Directly invoke the reject callback
    }

}
