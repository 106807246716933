import Phaser from 'phaser';
import {SpriteSheet, UIImageKey} from '../../../../types/assets/AssetKeys';
import {CharacterProfession} from '../../../../types/mechanics/CharacterProfessionsEnum';
import {Gender} from '../../../../types/mechanics/Gender';
import {ResourceType} from '../../../../types/mechanics/ResourceType';
import {heroWeaponColor} from '../../data/heroWeaponColor';
import {tertiaryColor} from '../../data/tertiaryColor';
import {mainGameFont} from '../../GameConfig';
import ServerControlledConflictUIScene from '../../scenes/ServerControlledUIScenes/ServerControlledConflictUIScene';
import {AllyComponent} from '../../types/conflict/AllyComponent';
import {ConflictSceneData} from '../../types/ConflictSceneData';
import {DepthLevel} from '../../types/DepthLevel';
import {adjustFontSizeByPixel} from '../../utils/phaserTextUtils';
import ResizableFrame from '../UserInterface/UtilityComponents/ResizableFrame';

export class AllyManager {
    private scene: ServerControlledConflictUIScene;
    public allyComponents: AllyComponent[] = [];
    public emptyVimIcons: Phaser.GameObjects.Image[][];
    public filledVimIcons: Phaser.GameObjects.Image[][];
    public allyNameTexts: Phaser.GameObjects.Text[];
    public allyHPTexts: Phaser.GameObjects.Text[];
    public allyResourceTexts: Phaser.GameObjects.Text[];
    private allyMenuFrames: ResizableFrame[];
    public allyArmedActionsFrame: ResizableFrame;
    public alliesArmedActionTexts: Phaser.GameObjects.Text[];
    public alliesArmedActionCancelButtons: Phaser.GameObjects.Image[];
    public isTargetingModeActive: boolean = false;
    public allyBadges: Phaser.GameObjects.Image[] = [];
    private badgeFlashTimeout: NodeJS.Timeout | null = null;

    public constructor(scene: ServerControlledConflictUIScene) {
        this.scene = scene;

        this.createAllyFrames();
        this.createAllyButtons();
        this.createAllyComponentSprites();
        this.createAllyBadges();
        this.createAllyTexts();
        this.createAllyVimIcons();

        // Listen to scene events
        this.scene.events.on('shutdown', this.pauseFlashingBadges, this);
        this.scene.events.on('pause', this.pauseFlashingBadges, this);
    }

    private flashBadges(): void {
        // First, set badges to visible for allies that exist
        this.allyComponents.forEach((component, index) => {
            if (component.exists) {
                this.allyBadges[index].setVisible(true);
            }
        });

        // Set timeout for the 'on' state (visible for 1 second)
        this.badgeFlashTimeout = setTimeout(() => {
            // Then set badges to invisible
            this.allyComponents.forEach((component, index) => {
                if (component.exists) {
                    this.allyBadges[index].setVisible(false);
                }
            });

            // Set another timeout for the 'off' state (invisible for 0.5 seconds)
            this.badgeFlashTimeout = setTimeout(() => {
                this.flashBadges(); // Recursively call to continue the flashing loop
            }, 500);
        }, 1000);
    }

    private startFlashingBadges(): void {
        if (this.badgeFlashTimeout !== null) {
            return;
        } // Prevent multiple flash loops
        this.flashBadges(); // Start the flashing loop
    }

    private pauseFlashingBadges(): void {
        if (this.badgeFlashTimeout !== null) {
            clearTimeout(this.badgeFlashTimeout);
            this.badgeFlashTimeout = null;
        }
    }

    private stopFlashingBadges(): void {
        this.pauseFlashingBadges();

        // Ensure all badges are visible when stopping the flash
        this.allyBadges.forEach(badge => badge.setVisible(false));
    }

    public hideAllAllyBadges(): void {
        this.stopFlashingBadges(); // Stop flashing when badges are hidden
        this.allyBadges.forEach(badge => badge.setVisible(false));
    }

    public showBadgesForAllAllies(): void {
        console.log('[showBadgesForAllAllies] Showing badges for all allies.');
        this.allyComponents.forEach((component, index) => {
            this.allyBadges[index].setVisible(component.exists);
        });
        this.startFlashingBadges(); // Start flashing only for visible badges
    }

    public createAllyBadges(): void {
        const allyCoords = this.getAllyCoords();
        this.allyBadges.push(this.scene.add.image(allyCoords[0][0], allyCoords[0][1] + 24, UIImageKey.BadgeOne).setVisible(false).setDepth(DepthLevel.UI_PRIMARY_GRAPHICS_MID).setScale(2));
        this.allyBadges.push(this.scene.add.image(allyCoords[1][0], allyCoords[1][1] + 24, UIImageKey.BadgeTwo).setVisible(false).setDepth(DepthLevel.UI_PRIMARY_GRAPHICS_MID).setScale(2));
        this.allyBadges.push(this.scene.add.image(allyCoords[2][0], allyCoords[2][1] + 24, UIImageKey.BadgeThree).setVisible(false).setDepth(DepthLevel.UI_PRIMARY_GRAPHICS_MID).setScale(2));
    }

    public createAllyComponentSprites(): void {
        this.allyComponents = this.initializeAllyComponentSprites();
    }

    private getAllyCoords(): [number, number][] {
        return [[270, 675], [496, 675], [722, 675]];
    }

    private initializeAllyComponentSprites(): AllyComponent[] {
        const playerCoords: [number, number][] = this.getAllyCoords();
        return playerCoords.map((_, index) => this.setupAllySpritesByIndex(index, playerCoords));
    }

    private setupAllySpritesByIndex(index: number, playerCoords: [number, number][]): AllyComponent {
        const [x, y] = playerCoords[index]; // Use the predefined coordinates

        // Generic sprite creation without specific player data
        const createSprite = (spriteSheet: SpriteSheet): Phaser.GameObjects.Sprite => {
            return this.scene.add.sprite(x, y, spriteSheet)
                .setDepth(DepthLevel.UI_PRIMARY_GRAPHICS_SUB)
                .setVisible(false);
        }; // Initially invisible

        // Create all necessary sprites as placeholders
        const baseSprite = createSprite(SpriteSheet.HeroBaseSprite);
        const primaryColorSprite = createSprite(SpriteSheet.HeroClothesPrimary);
        const secondaryColorSprite = createSprite(SpriteSheet.HeroClothesSecondary);
        const tertiaryColorSprite = createSprite(SpriteSheet.HeroClothesTertiary);
        const maleHairSprite = createSprite(SpriteSheet.HeroHairMale);
        const femaleHairSprite = createSprite(SpriteSheet.HeroHairFemale);
        const runebladeBottomWeaponSprite = createSprite(SpriteSheet.RunebladeWeaponBottom);
        const aethermancerBottomWeaponSprite = createSprite(SpriteSheet.AethermancerWeaponBottom);
        const lifeweaverBottomWeaponSprite = createSprite(SpriteSheet.LifeweaverWeaponBottom);
        const runebladeTopWeaponSprite = createSprite(SpriteSheet.RunebladeWeaponTop);
        const aethermancerTopWeaponSprite = createSprite(SpriteSheet.AethermancerWeaponTop);
        const lifeweaverTopWeaponSprite = createSprite(SpriteSheet.LifeweaverWeaponTop);

        // Create an interactive area (e.g., a button) for each ally component
        const button = this.scene.add.rectangle(x, y, baseSprite.displayWidth, baseSprite.displayHeight, 0xFF0000, 0)
            .setInteractive()
            .setDepth(DepthLevel.UI_PRIMARY_OVERLAY)
            .setVisible(false); // Initially invisible

        // Placeholder callback for interaction, can be updated later
        button.on('pointerdown', () => this.scene.controller.handleAllyClick(index));

        // Return a placeholder AllyComponent object
        return {
            // Placeholder values, these will be updated with real data when available
            exists: false,
            name: '',
            isAlive: false,
            className: CharacterProfession.Runeblade, // Assuming CharacterProfession is an enum, use an appropriate placeholder
            gender: Gender.Male, // Assuming Gender is an enum, use an appropriate placeholder
            baseSprite,
            maleHairSprite,
            femaleHairSprite,
            primaryClothingSprite: primaryColorSprite,
            secondaryClothingSprite: secondaryColorSprite,
            tertiaryClothingSprite: tertiaryColorSprite,
            runebladeBottomWeaponSprite,
            aethermancerBottomWeaponSprite,
            lifeweaverBottomWeaponSprite,
            runebladeTopWeaponSprite,
            aethermancerTopWeaponSprite,
            lifeweaverTopWeaponSprite,
            button
        };
    }

    public populateAllyComponentSprites(param: ConflictSceneData): void {
        this.depopulateAllyComponentSprites();
        // Loop through the allyComponents to update them with the data from param.allies
        this.allyComponents.forEach((component, index) => {
            const playerData = param.allies[index];
            if (!playerData) {
                // Optionally log a debug message or handle this silently
                console.log(`No player data available for ally component at index: ${index}. This may be expected for smaller parties.`);
                component.exists = false;
                return; // Skip updating this component due to lack of data
            }

            // Update basic information
            component.exists = true;
            component.name = playerData.Name;
            component.isAlive = playerData.CurrentHP > 0;
            component.className = playerData.ClassName;
            component.gender = playerData.Gender;

            // Update sprite visibility and properties based on the player data
            // Note: The following implementations are pseudo-code. They need to be adjusted to your game's logic.
            component.baseSprite.setVisible(true).setTint(Number(playerData.SkinColor));
            component.primaryClothingSprite.setVisible(true).setTint(Number(playerData.PrimaryColor));
            component.secondaryClothingSprite.setVisible(true).setTint(Number(playerData.SecondaryColor));
            component.tertiaryClothingSprite.setVisible(true).setTint(Number(tertiaryColor)); // Assuming tertiaryColor is available globally or in context

            // Handle gender-specific hair sprites visibility
            component.maleHairSprite.setVisible(playerData.Gender === Gender.Male).setTint(Number(playerData.HairColor));
            component.femaleHairSprite.setVisible(playerData.Gender === Gender.Female).setTint(Number(playerData.HairColor));

            // Update weapon sprites based on the class and set visibility
            // This is a simplified example; your game might need more complex logic
            component.runebladeBottomWeaponSprite.setVisible(playerData.ClassName === CharacterProfession.Runeblade).setTint(Number(heroWeaponColor));
            component.runebladeTopWeaponSprite.setVisible(playerData.ClassName === CharacterProfession.Runeblade).setTint(Number(heroWeaponColor));
            component.aethermancerBottomWeaponSprite.setVisible(playerData.ClassName === CharacterProfession.Aethermancer).setTint(Number(heroWeaponColor));
            component.aethermancerTopWeaponSprite.setVisible(playerData.ClassName === CharacterProfession.Aethermancer).setTint(Number(heroWeaponColor));
            component.lifeweaverBottomWeaponSprite.setVisible(playerData.ClassName === CharacterProfession.Lifeweaver).setTint(Number(heroWeaponColor));
            component.lifeweaverTopWeaponSprite.setVisible(playerData.ClassName === CharacterProfession.Lifeweaver).setTint(Number(heroWeaponColor));

            // Update the interactive button visibility if needed
            component.button.setVisible(true); // Update as necessary for your game's logic
        });
    }

    private depopulateAllyComponentSprites(): void {
        // Loop through the allyComponents to hide all sprites, reset angles, and hide interactive elements
        this.allyComponents.forEach(component => {
            // Set all sprites to invisible and reset angle to 0 (upright position)
            component.exists = false;
            component.baseSprite.setVisible(false).setAngle(0);
            component.primaryClothingSprite.setVisible(false).setAngle(0);
            component.secondaryClothingSprite.setVisible(false).setAngle(0);
            component.tertiaryClothingSprite.setVisible(false).setAngle(0);
            component.maleHairSprite.setVisible(false).setAngle(0);
            component.femaleHairSprite.setVisible(false).setAngle(0);
            component.runebladeBottomWeaponSprite.setVisible(false).setAngle(0);
            component.runebladeTopWeaponSprite.setVisible(false).setAngle(0);
            component.aethermancerBottomWeaponSprite.setVisible(false).setAngle(0);
            component.aethermancerTopWeaponSprite.setVisible(false).setAngle(0);
            component.lifeweaverBottomWeaponSprite.setVisible(false).setAngle(0);
            component.lifeweaverTopWeaponSprite.setVisible(false).setAngle(0);

            // Hide the interactive button
            component.button.setVisible(false);
        });
    }

    public createAllyVimIcons(): void {
        console.log('[createVimIconPlaceholders] Creating Vim icon placeholders for UI');

        const iconOffsetX = 65; // Horizontal offset from the text to the first icon for closer positioning
        const iconSpacing = 20; // Adjusted spacing between icons to a more suitable value

        // Assume a fixed number of allies for placeholder creation
        const numberOfAllies = 3; // Adjust based on your game's maximum number of allies
        const vimIconsPerAlly = 5; // Assuming 5 vim icons per character

        this.emptyVimIcons = [];
        this.filledVimIcons = [];

        for (let index = 0; index < numberOfAllies; index++) {
            const baseX = 296 + index * 226 + iconOffsetX; // Base X position, aligned with the player resource texts
            const baseY = 690; // Base Y position, assuming all vim texts are aligned horizontally

            this.emptyVimIcons[index] = [];
            this.filledVimIcons[index] = [];

            for (let j = 0; j < vimIconsPerAlly; j++) {
                const xPosition = baseX + j * iconSpacing;
                const yPosition = baseY;

                // Placeholder creation for empty and filled Vim icons
                const emptyIcon = this.scene.add.image(xPosition, yPosition, UIImageKey.EmptyCircleIcon)
                    .setVisible(false) // Initially invisible
                    .setScale(2.5)
                    .setDepth(DepthLevel.UI_PRIMARY_GRAPHICS_SUB);
                this.emptyVimIcons[index].push(emptyIcon);

                const filledIcon = this.scene.add.image(xPosition, yPosition, UIImageKey.FilledCircleIcon)
                    .setVisible(false) // Initially invisible
                    .setScale(2.5)
                    .setDepth(DepthLevel.UI_PRIMARY_GRAPHICS_SUB);
                this.filledVimIcons[index].push(filledIcon);
            }
        }
    }

    public createAllyButtons(): void {
        this.alliesArmedActionCancelButtons = [];

        const cancelButtonPositions = [30, 86, 142]; // Y-values for players' armed action cancel buttons

        for (let i = 0; i < 3; i++) {
            console.log(`[createButtons] Setting up playersArmedActionCancelButton ${i + 1} at Y-position: ${cancelButtonPositions[i]}`);

            const button = this.scene.add.image(880, cancelButtonPositions[i], UIImageKey.CrossButton)
                .setScale(2);
            button.setInteractive();

            // Setting up handler for playersArmedActionCancelButton
            button.on('pointerdown', this.scene.controller.handleCancelPlayerArmedActionButton.bind(this.scene.controller));
            console.log(`[createButtons] Handler for playersArmedActionCancelButton ${i + 1} set up successfully.`);

            button.setDepth(DepthLevel.UI_PRIMARY_GRAPHICS_SUB);
            button.setVisible(false);

            this.alliesArmedActionCancelButtons.push(button);

            console.log(`[createButtons] playersArmedActionCancelButton ${i + 1} created and added to the array.`);
        }
    }

    public createAllyTexts(): void {

        this.alliesArmedActionTexts = [];

        const textPositions = [2, 58, 114]; // Y-values for text positions

        // Always create three players' armed actions texts
        for (let i = 0; i < 3; i++) {
            const text = new Phaser.GameObjects.Text(
                this.scene, 217, textPositions[i], `${i + 1}.`, {
                    fontFamily: mainGameFont,
                    fontSize: '39px',
                    metrics: {
                        ascent: 32,
                        descent: 7,
                        fontSize: 39
                    },
                    color: '#ffffff',
                    wordWrap: {
                        width: 575,
                        useAdvancedWrap: true
                    }
                }
            );

            this.scene.add.existing(text);
            text.setDepth(DepthLevel.UI_PRIMARY_TEXT);
            text.setLineSpacing(-12);
            text.setResolution(3);
            text.setVisible(false); // Initially hide text
            this.alliesArmedActionTexts.push(text);
        }

        // Always create placeholders for player name, HP, and resource texts for three players
        this.allyNameTexts = [];
        this.allyHPTexts = [];
        this.allyResourceTexts = [];
        for (let i = 0; i < 3; i++) {
            // Player name text placeholders
            const playerNameText = this.scene.add.text(245 + i * 226, 610, '', {
                fontSize: '45px',
                color: '#fff',
                fontFamily: mainGameFont
            }).setResolution(3)
                .setDepth(DepthLevel.UI_PRIMARY_TEXT)
                .setVisible(false); // Initially hide text
            this.allyNameTexts.push(playerNameText);

            // Player HP text placeholders
            const hpText = this.scene.add.text(296 + i * 226, 645, '', {
                fontSize: '34px',
                color: '#fff',
                fontFamily: mainGameFont
            })
                .setResolution(3)
                .setDepth(DepthLevel.UI_PRIMARY_TEXT)
                .setVisible(false); // Initially hide text
            this.allyHPTexts.push(hpText);

            // Player resource text placeholders
            const resourceText = this.scene.add.text(296 + i * 226, 673, '', {
                fontSize: '34px',
                color: '#fff',
                fontFamily: mainGameFont
            })
                .setResolution(3)
                .setDepth(DepthLevel.UI_PRIMARY_TEXT)
                .setVisible(false); // Initially hide text
            this.allyResourceTexts.push(resourceText);
        }
    }

    public populateAllyTexts(param: ConflictSceneData): void {
        // Populate player-specific texts
        param.allies.forEach((player, index) => {
            if (index < this.allyNameTexts.length) {
                // Player name text with font size adjustment
                const playerNameText = this.allyNameTexts[index];
                playerNameText.setText(player.Name);
                adjustFontSizeByPixel(playerNameText, 210, 45); // Ensure the name fits within the designated space
                playerNameText.setVisible(true);

                // Player HP text update
                this.allyHPTexts[index].setText(`HP: ${player.CurrentHP}/${player.MaxHP}`).setVisible(true);

                // Player resource text update, based on ResourceType
                let resourceTextContent = '';
                if (player.ResourceType === ResourceType.MP) {
                    resourceTextContent = `${ResourceType.MP}: ${player.CurrentResource}/${player.MaxResource}`;
                } else if (player.ResourceType === ResourceType.Vim) {
                    resourceTextContent = `${ResourceType.Vim}:`; // Assume additional logic here if needed
                }
                this.allyResourceTexts[index].setText(resourceTextContent).setVisible(true);
            }
        });

        // Ensure texts for non-existent allies remain hidden. also hide the vim icons for non-existent allies
        for (let i = param.allies.length; i < this.allyNameTexts.length; i++) {
            this.allyNameTexts[i].setVisible(false);
            this.allyHPTexts[i].setVisible(false);
            this.allyResourceTexts[i].setVisible(false);
            this.emptyVimIcons[i].forEach(icon => icon.setVisible(false));
            this.filledVimIcons[i].forEach(icon => icon.setVisible(false));
        }
    }

    public createAllyFrames(): void {
        // Initialize and hide all three frames
        const maxFrames = 3; // Assuming a maximum of 3 players
        this.allyMenuFrames = Array.from({length: maxFrames}).map((_, index) => {
            const frame = new ResizableFrame(this.scene, 348 + index * 226, 662, 210, 99);
            frame.hideFrame(); // Initially hide all frames
            return frame;
        });
        this.allyArmedActionsFrame = new ResizableFrame(this.scene, 558, 91, 693, 170);
        this.allyArmedActionsFrame.hideFrame();
    }

    public updateAllyFramesVisibility(param: ConflictSceneData): void {
        // Iterate over the playerMenuFrames and update visibility based on the allies array
        this.allyMenuFrames.forEach((frame, index) => {
            if (index < param.allies.length) {
                frame.showFrame(); // Show frame if there's a corresponding player
            } else {
                frame.hideFrame(); // Otherwise, ensure the frame remains hidden
            }
        });
    }

    public handleAllySelect(slotNumber: number): void {
        console.log(`[AllyManager.handleAllySelect] Handling ally selection for slot ${slotNumber}.`);
        this.scene.controller.handleAllyClick(slotNumber - 1);
    }
}
