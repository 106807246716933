import {Socket} from 'socket.io-client';
import {UIImageKey} from '../../../../../../types/assets/AssetKeys';
import {ServerSocketEvents} from '../../../../../../types/events/ServerSocketEvents';
import {BaseInteractionState} from '../../../../../../types/mechanics/InteractionState'; // Ensure correct import path
import {mainGameFont} from '../../../../GameConfig';
import ServerControlledConflictUIScene from '../../../../scenes/ServerControlledUIScenes/ServerControlledConflictUIScene';
import {ConflictMenuType} from '../../../../types/ConflictMenuType';
import {DepthLevel} from '../../../../types/DepthLevel';
import SocketManager from '../../../NetworkingAndChat/SocketManager';
import NonContainerUIActionButton from '../../ActionButtons/NonContainerUIActionButton';
import ResizableFrame from '../../UtilityComponents/ResizableFrame';
import {GameConflictActionMenu} from './GameConflictActionMenu';

export class AttackMenu extends GameConflictActionMenu {
    public readonly menuTag: ConflictMenuType = ConflictMenuType.AttackMenu;
    public relatedMenusToDismiss: Set<ConflictMenuType> = new Set([
        ConflictMenuType.AbilityMenu,
        ConflictMenuType.InventoryMenu,
        ConflictMenuType.HotkeyMenu,
        ConflictMenuType.ConflictCommandMenu,
        ConflictMenuType.ConflictMessageElement
    ]);
    public attackArmedCommandText: Phaser.GameObjects.Text;
    public cancelMenuFrame: ResizableFrame;
    public attackArmedCommandFrame: ResizableFrame;
    public attackArmedDetailFrame: ResizableFrame;
    public attackArmedDetailIcon: NonContainerUIActionButton;
    public cancelButton: NonContainerUIActionButton;
    private socket: Socket;

    public constructor(scene: ServerControlledConflictUIScene) {
        super();
        this.scene = scene;
        this.socket = SocketManager.getInstance().socket;
        // Setup UI components during construction
        this.setupFrames();
        this.setupButtons();
        this.setupIcons();
        this.setupTexts();
    }

    private setupTexts(): void {
        // Setup text elements for the attack menu
        console.log('Setting up texts for AttackMenu...');
        this.attackArmedCommandText = this.scene.add.text(
            244,
            510,
            'Choose A Target',
            {
                fontSize: '50px',
                color: '#fff',
                fontFamily: mainGameFont,
            }
        );
        this.attackArmedCommandText.setDepth(DepthLevel.UI_PRIMARY_TEXT);
        this.attackArmedCommandText.setResolution(3);
        this.attackArmedCommandText.setVisible(false);

    }

    private setupFrames(): void {
        console.log('Setting up frames for AttackMenu...');

        this.cancelMenuFrame = new ResizableFrame(this.scene, 805, 486, 200, 100);
        this.cancelMenuFrame.hideFrame();

        this.attackArmedCommandFrame = new ResizableFrame(this.scene, 465, 554, 445, 84);
        this.attackArmedCommandFrame.hideFrame();

        this.attackArmedDetailFrame = new ResizableFrame(this.scene, 465, 466, 445, 60);
        this.attackArmedDetailFrame.hideFrame();
    }

    private setupButtons(): void {
        // Setup buttons such as 'Attack', 'Cancel', or any other buttons within the attack menu
        console.log('Setting up buttons for AttackMenu...');
        this.cancelButton = new NonContainerUIActionButton(
            this.scene,
            730,
            465,
            UIImageKey.CrossButton,
            UIImageKey.CrossButton,
            'Cancel',
            () => {
            }
        );
        this.cancelButton.hideActionButton();
        this.cancelButton.setDepth(DepthLevel.UI_PRIMARY_GRAPHICS_SUB);

    }

    private setupIcons(): void {
        // Setup any icons or additional graphical elements for the attack menu
        console.log('Setting up icons for AttackMenu...');
        this.attackArmedDetailIcon = new NonContainerUIActionButton(
            this.scene,
            266,
            465,
            UIImageKey.AttackButton,
            UIImageKey.AttackButtonActive,
            'Attack',
            () => {
            }
        );
        this.attackArmedDetailIcon.setDepth(DepthLevel.UI_PRIMARY_GRAPHICS_SUB);
        this.attackArmedDetailIcon.select();
        this.attackArmedDetailIcon.hideActionButton();
    }

    public activate(params?: unknown): void {
        console.log(`[AttackMenu.activate] Activating AttackMenu with params: ${params}`);
        this.shown = true;

        console.log('[AttackMenu.activate] Setting shown to true.');
        this.dismissRelatedMenus();
        console.log('[AttackMenu.activate] Dismissed related menus.');

        // Show the frames
        console.log('[AttackMenu.activate] Showing attack armed command frame.');
        this.attackArmedCommandFrame.showFrame();
        // this.attackArmedCommandFrame.hideFrame(); // Hide the frame for now as a test
        console.log('[AttackMenu.activate] Showing attack armed detail frame.');
        this.attackArmedDetailFrame.showFrame();
        // this.attackArmedDetailFrame.hideFrame(); // Hide the frame for now as a test
        console.log('[AttackMenu.activate] Showing cancel menu frame.');
        this.cancelMenuFrame.showFrame();

        // Arm/Show the buttons
        console.log('[AttackMenu.activate] Setting cancel button callback.');
        this.cancelButton.targetCallback = this.handleCancelButton.bind(this);
        console.log('[AttackMenu.activate] Showing cancel action button.');
        this.cancelButton.showActionButton();

        // Show the text
        console.log('[AttackMenu.activate] Setting attack armed command text visible.');
        this.attackArmedCommandText.setVisible(true);

        // Show the icons
        console.log('[AttackMenu.activate] Showing attack armed detail icon action button.');
        this.attackArmedDetailIcon.showActionButton();
    }

    public dismiss(): void {
        console.log('Dismissing AttackMenu');
        this.shown = false;

        // Hide the frames
        this.attackArmedCommandFrame.hideFrame();
        this.attackArmedDetailFrame.hideFrame();
        this.cancelMenuFrame.hideFrame();

        // Disarm/Hide the buttons
        this.cancelButton.targetCallback = (): void => {};
        this.cancelButton.hideActionButton();

        // Hide the text
        this.attackArmedCommandText.setVisible(false);

        // Hide the icons
        this.attackArmedDetailIcon.hideActionButton();
    }

    public emitMenuActivationRequest(params?: unknown): void {
        console.log(`Emitting menu activation request for AttackMenu with params: ${params}`);
        if (!this.scene.canProceedWithMenuAction()) {
            console.log('Cannot proceed with menu action. Exiting early.');
            return;
        }
        console.log('setting waiting for server response to true');
        this.scene.waitingForServerResponse = true;
        this.socket.emit(ServerSocketEvents.AttemptConflictAttack);
    }

    public emitMenuDismissalRequest(): void {
        if (this.scene.conflictScene.interactionState === BaseInteractionState.MainSelect) {
            return;
        }
        if (!this.scene.canProceedWithMenuAction()) {
            console.log('Cannot proceed with menu action. Exiting early.');
            return;
        }
        this.scene.waitingForServerResponse = true;
        // Logic to cancel the attack
        console.log('Cancel attack button pressed');
        this.socket.emit(ServerSocketEvents.AttemptConflictCancel);
    }

    private handleCancelButton(): void {
        console.log('Handling cancel button press...');
        this.emitMenuDismissalRequest();
    }
}
