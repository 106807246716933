
import {Socket} from 'socket.io-client';
import {UIImageKey} from '../../../../types/assets/AssetKeys';
import {ServerSocketEvents} from '../../../../types/events/ServerSocketEvents';
import {mainGameFont} from '../../GameConfig';
import ServerControlledGameUIScene from '../../scenes/ServerControlledUIScenes/ServerControlledGameUIScene';
import {DepthLevel} from '../../types/DepthLevel';
import {FieldMenuType} from '../../types/FieldMenuType';
import NonContainerUIActionButton from '../UserInterface/ActionButtons/NonContainerUIActionButton';
import {GameFieldActionMenu} from '../UserInterface/MenusAndBars/FieldElements/GameFieldActionMenu';
import ResizableFrame from '../UserInterface/UtilityComponents/ResizableFrame';

export default class PartyInviteNotification extends GameFieldActionMenu {
    public shown: boolean = false;
    public menuTag = FieldMenuType.InviteNotification;
    public relatedMenusToDismiss: Set<FieldMenuType> = new Set([
        FieldMenuType.AbilityMenu,
        FieldMenuType.CharacterSheetMenu,
        FieldMenuType.TargetMenu,
        FieldMenuType.DuelNotification,
        FieldMenuType.DismissibleNotification,
        FieldMenuType.PartyOrderMenu,
        FieldMenuType.CommonerNPCMenu,
        FieldMenuType.InnkeeperNPCMenu,
        FieldMenuType.MerchantNPCMenu,
        FieldMenuType.MessageElement,
        FieldMenuType.InventoryMenu,
        FieldMenuType.TradeMenu,
        FieldMenuType.InteractableMessageDisplay,

        FieldMenuType.InteractionMenu,

        // Any other menu that should be dismissed when AbilityMenu is active
    ]);

    public canCoincideWithGamePad = true;

    public notificationFrame: ResizableFrame;
    public closeNotificationButton: Phaser.GameObjects.Image;
    public notificationText: Phaser.GameObjects.Text;
    private acceptButton: NonContainerUIActionButton;
    private declineButton: NonContainerUIActionButton;

    public constructor(public scene: ServerControlledGameUIScene, private socket: Socket, initialMessage: string) {
        super();
        const centerX = this.scene.cameras.main.centerX;
        const centerY = this.scene.cameras.main.centerY + 144;

        // Define frame dimensions
        const frameWidth = 600;
        const frameHeight = 200;
        this.notificationFrame = new ResizableFrame(this.scene, centerX, centerY, frameWidth, frameHeight);

        // Create the close button at (0, 0)
        this.closeNotificationButton = this.scene.add.image(0, 0, UIImageKey.CrossIcon).setInteractive().setDepth(DepthLevel.UI_PRIMARY_GRAPHICS_SUB).setScale(1.5);

        this.notificationText = this.scene.add.text(centerX - 300, centerY - 100, initialMessage, {
            fontFamily: mainGameFont,
            fontSize: '54px',
            metrics: {
                ascent: 43,
                descent: 11, // Increase the descent value
                fontSize: 54
            },
            color: '#ffffff',
            wordWrap: {
                width: 575,
                useAdvancedWrap: true
            }
        }).setDepth(DepthLevel.UI_PRIMARY_TEXT);
        this.notificationText.setLineSpacing(-15);
        this.notificationText.setOrigin(0); // center the text

        // Calculate the desired position of the close button
        const closeButtonX = centerX + frameWidth / 2 - this.closeNotificationButton.displayWidth / 2 - 5; // Move 2 pixels to the left
        const closeButtonY = centerY - frameHeight / 2 + this.closeNotificationButton.displayHeight / 2 + 5; // Move 2 pixels down

        // Set the position of the close button
        this.closeNotificationButton.setPosition(closeButtonX, closeButtonY);

        // Register click event for the close button
        this.closeNotificationButton.on('pointerdown', () => this.onDecline());
        this.closeNotificationButton.setVisible(false);

        // Create 'Accept' button
        this.acceptButton = new NonContainerUIActionButton(
            this.scene,
            centerX + 37, // adjust these values as needed for button placement
            centerY + 70,
            UIImageKey.CheckButton,
            UIImageKey.CheckButton,
            'Accept',
            this.onAccept.bind(this) // Bind this context
        );
        this.acceptButton.setDepth(DepthLevel.UI_PRIMARY_GRAPHICS_SUB);

        // Create 'Decline' button
        this.declineButton = new NonContainerUIActionButton(
            this.scene,
            centerX - 170, // adjust these values as needed for button placement
            centerY + 70,
            UIImageKey.CrossButton,
            UIImageKey.CrossButton,
            'Decline',
            this.onDecline.bind(this) // Bind this context
        );
        this.declineButton.setDepth(DepthLevel.UI_PRIMARY_GRAPHICS_SUB);

        // Initially hide the notification
        this.hideNotification();

    }

    public activate(message: string): void {
        this.shown = true;
        console.log('[PartyInviteNotification.activate] Activating Party Invite Notification Menu with message:', message);
        this.dismissRelatedMenus();
        this.notificationText.setText(message);
        this.notificationFrame.showFrame();
        this.notificationText.setVisible(true);
        this.closeNotificationButton.setVisible(true);

        // Show the buttons
        this.acceptButton.showActionButton();
        this.declineButton.showActionButton();
        console.log('[PartyInviteNotification.activate] Exiting method.');
    }

    public emitMenuActivationRequest(params?: {message: string}): void {
        if (params) {
            this.activate(params.message);
        }
    }

    public emitMenuDismissalRequest(): void{
        this.dismiss();
    }

    public dismiss(): void {
        console.log('[PartyInviteNotification.dismiss] Entering method Dismissing Party Invite Notification Menu');

        this.hideNotification();

        this.activateInteractionMenuIfNeeded();
        this.shown = false;
        console.log('[PartyInviteNotification.dismiss] Exiting method.');
    }

    private hideNotification(): void {
        this.notificationFrame.hideFrame();
        this.notificationText.setVisible(false);
        this.closeNotificationButton.setVisible(false);

        // Hide the buttons
        this.acceptButton.hideActionButton();
        this.declineButton.hideActionButton();
    }

    public onAccept(): void {
        // Code for when 'Accept' is clicked
        console.log('Accepted');
        // Send party join acceptance status to the server
        this.socket.emit(ServerSocketEvents.PartyInviteAcceptDecline, {status: 'accepted'});
    }

    public onDecline(): void {
        // Code for when 'Decline' is clicked
        console.log('Declined');
        // Send party join decline status to the server
        this.socket.emit(ServerSocketEvents.PartyInviteAcceptDecline, {status: 'declined'});
    }
}
