export const skinTones = [
    '0xFFDBAC', // Light Peach
    '0xF1C27D', // Wheat
    '0xDEBB9B', // Light Tan
    '0xE0AC69', // Gold
    '0xC68642', // Copper
    '0xAE8964', // Bronze
    '0x8D5524', // Brown
    '0x5C4033', // Umber
];
