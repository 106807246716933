import {Socket} from 'socket.io-client';
import {UIImageKey} from '../../../../../../types/assets/AssetKeys';
import {
    AttemptConflictActionSelectionConfirmViaHotkeyEvent
} from '../../../../../../types/conflict/AttemptConflictActionSelectionConfirmViaHotkeyEvent';
import {ConflictActionType} from '../../../../../../types/conflict/ConflictActionType';
import {PlayerConflictMode} from '../../../../../../types/conflict/PlayerConflictMode';
import {ServerSocketEvents} from '../../../../../../types/events/ServerSocketEvents';
import {BasicHotkeyAssignment} from '../../../../../../types/hotkeys/BasicHotkeyAssignment';
import {HotkeyAssignment} from '../../../../../../types/hotkeys/HotkeyAssignment';
import ServerControlledConflictUIScene from '../../../../scenes/ServerControlledUIScenes/ServerControlledConflictUIScene';
import {ConflictMenuType} from '../../../../types/ConflictMenuType';
import {DepthLevel} from '../../../../types/DepthLevel';
import SocketManager from '../../../NetworkingAndChat/SocketManager';
import NonContainerUIActionButton from '../../ActionButtons/NonContainerUIActionButton';
import ResizableFrame from '../../UtilityComponents/ResizableFrame';
import {GameConflictActionMenu} from './GameConflictActionMenu';

export class ConflictHotkeyMenu extends GameConflictActionMenu{
    public readonly menuTag: ConflictMenuType = ConflictMenuType.HotkeyMenu;
    public relatedMenusToDismiss: Set<ConflictMenuType> = new Set([
        ConflictMenuType.AbilityMenu,
        ConflictMenuType.InventoryMenu,
        ConflictMenuType.AttackMenu,
        ConflictMenuType.ConflictMessageElement,
    ]);
    private hotkeyMenuFrame: ResizableFrame;
    private socket: Socket;
    private hotkeyButtons: NonContainerUIActionButton[] = []; // Property to store button references
    private hotkeyNumberBadges: Phaser.GameObjects.Image[] = []; // Property to store badge references

    private selectedSlot: number | null = null; // Tracks the currently selected slot
    private slotAssignments: Map<number, HotkeyAssignment> = new Map(); // Maps slot number to HotkeyAssignment

    private badgeFlashTimeout: NodeJS.Timeout | null = null; // Timeout reference for badge flashing

    public constructor(scene: ServerControlledConflictUIScene) {
        super();
        this.scene = scene;
        this.socket = SocketManager.getInstance().socket;
        this.setupFrames();
        this.setupButtons(); // Ensure buttons are setup after the frame
        this.setupHotkeyNumberBadges();
        this.scene.events.on('shutdown', this.pauseFlashingBadges, this);
        this.scene.events.on('pause', this.pauseFlashingBadges, this);
    }

    public toggleHotkeyBindMode(toggle: boolean): void {
        console.log(`[${this.menuTag}.toggleHotkeyBindMode] Toggle mode: ${toggle}`);

        if (toggle) {
            this.startFlashingBadges(); // Start blinking the badges if toggling on
        } else {
            this.stopFlashingBadges(); // Stop blinking the badges if toggling off
        }
    }

    private flashBadges(): void {
        console.log(`[${this.menuTag}.flashBadges] Starting to flash badges.`);

        // Initially set all badges to visible (start of the 'on' phase)
        this.hotkeyNumberBadges.forEach(badge => badge.setVisible(true));

        // Set the timeout to hide the badges after 1000 ms ('on' phase duration)
        this.badgeFlashTimeout = setTimeout(() => {
            // Hide each badge (start of the 'off' phase)
            this.hotkeyNumberBadges.forEach(badge => badge.setVisible(false));

            // Schedule the next 'on' phase after 500 ms ('off' phase duration)
            this.badgeFlashTimeout = setTimeout(() => {
                this.flashBadges(); // Continue flashing recursively
            }, 500);
        }, 1000);
    }

    private pauseFlashingBadges(): void {
        console.log(`[${this.menuTag}.pauseFlashingBadges] Pausing badge flash.`);
        if (this.badgeFlashTimeout !== null) {
            clearTimeout(this.badgeFlashTimeout);
            this.badgeFlashTimeout = null;
        }
    }

    private startFlashingBadges(): void {
        if (this.badgeFlashTimeout !== null) {
            return; // Prevent starting multiple flash loops
        }

        this.flashBadges(); // Start the flashing loop
    }

    private stopFlashingBadges(): void {
        console.log(`[${this.menuTag}.stopFlashingBadges] Stopping badge flash.`);
        if (this.badgeFlashTimeout !== null) {
            clearTimeout(this.badgeFlashTimeout); // Clear the timeout to stop the loop
            this.badgeFlashTimeout = null;
        }

        // Ensure all badges are visible when stopping the flash
        if (this.shown) {
            this.hotkeyNumberBadges.forEach(badge => badge.setVisible(true));
        } else {
            this.hotkeyNumberBadges.forEach(badge => badge.setVisible(false));
        }
    }

    private setupFrames(): void {
        console.log('Setting up frames for HotkeyMenu...');

        this.hotkeyMenuFrame = new ResizableFrame(this.scene, 574, 574, 662, 44);
        this.hotkeyMenuFrame.hideFrame();

    }

    public activate(): void {
        console.log(`[${this.menuTag}.activate] Activating Hotkey Menu...`);
        this.shown = true;

        // Hide all enemy badges and disable targeting mode
        this.scene.enemyManager.hideAllEnemyBadges();
        this.scene.enemyManager.isTargetingModeActive = false;

        // Hide all ally badges and disable targeting mode
        this.scene.allyManager.hideAllAllyBadges();
        this.scene.allyManager.isTargetingModeActive = false;

        this.hotkeyMenuFrame.showFrame();
        for (const button of this.hotkeyButtons) {
            button.showActionButton();
        }
        for (const badge of this.hotkeyNumberBadges) {
            badge.setVisible(true);
        }
    }
    public dismiss(): void {
        console.log(`[${this.menuTag}.dismiss] Dismissing Hotkey Menu...`);
        this.shown = false;
        this.hotkeyMenuFrame.hideFrame();
        for (const button of this.hotkeyButtons) {
            button.hideActionButton();
        }
        for (const badge of this.hotkeyNumberBadges) {
            badge.setVisible(false);
        }

        this.toggleHotkeyBindMode(false);
    }

    public emitMenuActivationRequest(): void {
        this.activate();
    }
    public emitMenuDismissalRequest(): void {
        this.dismiss();
    }

    public handleButtonPress(slotNumber: number): void {
        console.log(`[${this.menuTag}.handleButtonPress] Button pressed for slot ${slotNumber}.`);
        if (!this.scene.canProceedWithMenuAction()) {
            console.log('Cannot proceed with menu action. Exiting early.');
            return;
        }
        if (this.scene.conflictScene.mode === PlayerConflictMode.BindHotkey) {
            // Handle hotkey binding logic here
            this.scene.waitingForServerResponse = true;
            console.log(`[${this.menuTag}.handleButtonPress] Hotkey binding mode active.`);
            const emitData: BasicHotkeyAssignment = {
                slotNumber,
                actionType: this.scene.conflictScene.selectedBindActionType!, // Placeholder, replace as needed
                actionIdentifier: this.scene.conflictScene.selectedBindActionIdentifier! // Placeholder, replace as needed
            };
            this.socket.emit(ServerSocketEvents.AttemptSelectHotkeyForBind, emitData);
            return;
        } else if (this.scene.conflictScene.mode === PlayerConflictMode.Normal) {
            // Handle normal conflict action logic here
            console.log(`[${this.menuTag}.handleButtonPress] Normal conflict action mode active.`);
            const assignment = this.slotAssignments.get(slotNumber);
            if (assignment) {
                console.log(`slot ${slotNumber}:`, JSON.stringify(assignment, null, 2));
                if (assignment.actionType === ConflictActionType.Attack) {
                    // attack
                    this.scene.controller.handleAttackButton();
                } else if (assignment.actionType === ConflictActionType.Flee) {
                    // flee
                    this.scene.controller.handleFleeButton();
                } else if (
                    assignment.actionType === ConflictActionType.Ability ||
                    assignment.actionType === ConflictActionType.Item
                ) {
                    // ability
                    // emit a signal to the server to handle the ability...
                    //  this will be slightly different from the attack and flee buttons and different from how we
                    //  usually handle ability usage in the conflict, since we are not arming the ability via
                    //  ability index, but rather via the action identifier itself. the server will need to validate
                    //  that the ability is available to the player and that the player has enough resources to use it.
                    //  first we need to build the emit object and then emit the signal to the server.
                    this.scene.waitingForServerResponse = true;
                    const emitData: AttemptConflictActionSelectionConfirmViaHotkeyEvent = {
                        actionType: assignment.actionType,
                        actionIdentifier: assignment.actionIdentifier
                    };
                    this.socket.emit(ServerSocketEvents.AttemptConflictActionSelectionConfirmViaHotkey, emitData);
                }
            } else {
                console.log(`slot ${slotNumber} which has no assignment.`);
            }
        } else if (this.scene.conflictScene.mode === PlayerConflictMode.BindAction) {
            // Handle action binding logic here
            console.log(`[${this.menuTag}.handleButtonPress] Action binding mode active.`);
            const assignment = this.slotAssignments.get(slotNumber);
            if (assignment) {
                if (assignment.actionType === ConflictActionType.Attack) {
                    this.scene.attackMenu.emitMenuActivationRequest();
                } else if (assignment.actionType === ConflictActionType.Flee) {
                    this.scene.waitingForServerResponse = true;
                    this.socket.emit(ServerSocketEvents.AttemptConflictFlee);
                } else if (
                    assignment.actionType === ConflictActionType.Ability ||
                    assignment.actionType === ConflictActionType.Item
                ) {
                    this.scene.waitingForServerResponse = true;
                    // select the ability for binding...
                    // this will be a little different from the ability selection... due to the fact that
                    // we are not selecting the ability by index, but rather by the action identifier itself.
                    const emitData = {
                        actionType: assignment.actionType,
                        actionIdentifier: assignment.actionIdentifier
                    };
                    this.socket.emit(ServerSocketEvents.AttemptConflictActionSelectionForBind, emitData);
                }
            }
        }
    }

    private setupButtons(): void {
        const buttonPositions = this.calculateButtonPositions();
        buttonPositions.forEach((pos, index) => {
            const slotNumber = index + 1; // Convert button index to slot number (1-based)

            const button = new NonContainerUIActionButton(
                this.scene,
                pos.x,
                pos.y,
                UIImageKey.EmptyButton, // Placeholder, replace with actual key
                UIImageKey.EmptyButton, // Placeholder for active state, replace as needed
                '', // Optionally, provide text or icon
                () => this.handleButtonPress(slotNumber) // Assign the handler method
            );
            button.setDepth(DepthLevel.UI_PRIMARY_GRAPHICS_SUB);
            button.hideActionButton(); // Initially hide the button until the menu is activated
            this.hotkeyButtons.push(button); // Store the button reference
        });
    }

    private calculateButtonPositions(): { x: number; y: number }[] {
        const positions: {x: number, y: number}[] = [];
        const startX = 272; // Starting X position
        const startY = 574; // Starting Y position
        const spacing = 55; // Horizontal spacing between buttons

        for (let i = 0; i < 12; i++) { // Now iterating 12 times
            positions.push({x: startX + (i * spacing), y: startY});
        }

        return positions;
    }

    private setupHotkeyNumberBadges(): void {
        const badgePositions = this.calculateBadgePositions();
        badgePositions.forEach((pos, index) => {
            // Assuming each badge image key is already loaded and available
            const badgeKey = this.getBadgeImageKey(index);
            const badge = this.scene.add.image(pos.x, pos.y, badgeKey).setScale(2); // Scale by 2 as specified
            badge.setDepth(DepthLevel.UI_PRIMARY_GRAPHICS_MID); // Ensure the badge is rendered above most UI elements
            badge.setVisible(false); // Hide the badge initially
            this.hotkeyNumberBadges.push(badge); // Store the badge reference
        });
    }

    private calculateBadgePositions(): { x: number; y: number }[] {
        const positions: {x: number, y: number}[] = [];
        const startX = 280; // Adjusted starting X position for badges
        const startY = 582; // Adjusted starting Y position for badges
        const spacing = 55; // Same spacing as buttons

        for (let i = 0; i < 12; i++) { // Now iterating 12 times for badges as well
            positions.push({x: startX + (i * spacing), y: startY});
        }

        return positions;
    }

    private getBadgeImageKey(index: number): UIImageKey {
        // Updated array to include BadgeMinus and BadgeEquals for the 11th and 12th positions
        const badgeKeys = [
            UIImageKey.BadgeOne, UIImageKey.BadgeTwo, UIImageKey.BadgeThree,
            UIImageKey.BadgeFour, UIImageKey.BadgeFive, UIImageKey.BadgeSix,
            UIImageKey.BadgeSeven, UIImageKey.BadgeEight, UIImageKey.BadgeNine,
            UIImageKey.BadgeZero, UIImageKey.BadgeMinus, UIImageKey.BadgeEquals
        ];
        return badgeKeys[index];
    }

    public populateHotkeyMenu(hotkeyAssignments: HotkeyAssignment[]): void {
        console.log(`[${this.menuTag}.populateHotkeyMenu] Populating Hotkey Menu...`);

        // Clear current assignments and reset all UI elements to default states
        this.slotAssignments.clear();
        this.hotkeyButtons.forEach(button => {
            button.changeButtonImage(UIImageKey.EmptyButton, UIImageKey.EmptyButton); // Assuming UIImageKey.EmptyButton is the default
        });

        // Log the assignments being populated
        console.log(`[${this.menuTag}.populateHotkeyMenu] Hotkey assignments:`, JSON.stringify(hotkeyAssignments, null, 2));

        if (hotkeyAssignments.length === 0) {
            console.log(`[${this.menuTag}.populateHotkeyMenu] No hotkey assignments provided!`);
            return;
        }

        // Apply new hotkey assignments
        hotkeyAssignments.forEach((assignment) => {
            const buttonIndex = assignment.slotNumber - 1;
            const button = this.hotkeyButtons[buttonIndex];
            const badge = this.hotkeyNumberBadges[buttonIndex];

            if (button && badge) {
                button.changeButtonImage(assignment.key, assignment.activeKey);
                this.slotAssignments.set(assignment.slotNumber, assignment); // Store the new assignment
            } else {
                console.warn(`[${this.menuTag}.populateHotkeyMenu] Button or badge not found for slotNumber ${assignment.slotNumber}`);
            }
        });
    }

    public selectSlot(slotNumber: number): void {
        if (slotNumber < 1 || slotNumber > 12) {
            console.warn(`[${this.menuTag}.selectSlot] Invalid slot number: ${slotNumber}`);
            return;
        }
        this.selectedSlot = slotNumber;
        this.hotkeyButtons[slotNumber - 1].select(); // Flash the selected button
        console.log(`[${this.menuTag}.selectSlot] Slot ${slotNumber} selected.`);
        // Additional logic to highlight the selected slot or display its information could be added here
    }

    public deselectSlot(): void {
        this.selectedSlot = null;
        console.log(`[${this.menuTag}.deselectSlot] Slot deselected.`);
        // Additional logic to remove highlighting from the previously selected slot
    }

    public getSlotAssignment(slotNumber: number): HotkeyAssignment | undefined {
        return this.slotAssignments.get(slotNumber);
    }
}
