import {ItemType} from '../items/ItemTypes';

export enum BaseInteractionState {
    MainSelect = 'mainSelect',
    Attack = 'attack',
    Processing = 'processing',
    SkipTurn = 'skipTurn',
    Init = 'init',
    ActionSelected = 'actionSelected',
    Game = 'game',
    ProcessingForfeiture = 'processingForfeiture',
}

type ActionNumber = '0' | '1' | '2' | '3' | '4' | '5' | '6' | '7';
type AbilityPageNumber = 'Page1' | 'Page2' | 'Page3';
export type ItemCategory = 'Bag' | 'Quest' | 'Equipment';
export type TargetType = 'Enemy' | 'Ally' | 'Self';
type CombinedTargetType = `${TargetType}` | `${TargetType}${TargetType}` | `${TargetType}${TargetType}${TargetType}`;
type EquipmentActionType = Exclude<ItemType, ItemType.Consumable>;

type DynamicMainSelectState =
    `AbilityMainSelect${AbilityPageNumber}` |
    `ItemMainSelect${ItemCategory}`;

type DynamicDetailState =
    | `Ability${ActionNumber}Detail${AbilityPageNumber}`
    | `Item${ActionNumber}Detail${Exclude<ItemCategory, 'Equipment'>}`
    | `Item${EquipmentActionType}DetailEquipment`; // Updated line for Equipment

type DynamicTargetingState =
    | `Ability${ActionNumber}Targeting${CombinedTargetType}${AbilityPageNumber}`
    | `Item${ActionNumber}Targeting${CombinedTargetType}${Exclude<ItemCategory, 'Equipment'>}`
    | `Item${EquipmentActionType}Targeting${CombinedTargetType}Equipment`; // Updated line for Equipment

export type InteractionState = BaseInteractionState | DynamicMainSelectState | DynamicDetailState | DynamicTargetingState;
