import Phaser from 'phaser';
import {SpriteSheet} from '../../../../types/assets/AssetKeys';
import {CharacterProfession} from '../../../../types/mechanics/CharacterProfessionsEnum';
import {Gender} from '../../../../types/mechanics/Gender';
import {Direction} from '../../../../types/physics/Direction';
import {heroWeaponColor} from '../../data/heroWeaponColor';
import {tertiaryColor} from '../../data/tertiaryColor';
import {mainGameFont, tileSize} from '../../GameConfig';
import ServerControlledGameScene from '../../scenes/ServerControlledGameplayScenes/ServerControlledGameScene';
import {DepthLevel} from '../../types/DepthLevel';
import {PlayerSpriteParameters} from '../../types/PlayerSpriteParameters';
import ResizableFrame from '../UserInterface/UtilityComponents/ResizableFrame';

const borderPadding = 3;

export default class PlayerSprite {
    private hideChatBubbleTimeoutId: null | NodeJS.Timeout = null;
    public chatFrame: ResizableFrame; // Property to hold the chat frame
    private chatText: Phaser.GameObjects.Text;
    private _targeted: boolean; // Flag for targeted state
    private height: number;
    private invisibleButton: Phaser.GameObjects.Rectangle;
    private redBorder: Phaser.GameObjects.Graphics;
    private width: number;
    public baseSprite: Phaser.GameObjects.Sprite; // The base character sprite for the body
    public blackBorder: Phaser.GameObjects.Graphics; // Black Border object
    public borderVisible: boolean;
    public facingDirection: Direction;
    public greenBorder: Phaser.GameObjects.Graphics;
    public hairSprite: Phaser.GameObjects.Sprite; // The hair sprite
    public inConflict: boolean = false;
    public inNPCDialogue: boolean = false;
    public isPartyFollower: boolean;
    public isSwitchingMaps: boolean = false;
    public isTargetedForDuel: boolean; // New property for checking if player is targeted for duel
    public isTargetedForInvite: boolean;
    public playerName: string; // New property for the player name
    public primaryColorSprite: Phaser.GameObjects.Sprite;
    public secondaryColorSprite: Phaser.GameObjects.Sprite;
    public sprites: Phaser.GameObjects.Sprite[] = []; // Array of sprites
    public tertiaryColorSprite: Phaser.GameObjects.Sprite;
    public whiteBorder: Phaser.GameObjects.Graphics; // White Border object
    public weaponBottomSprite: Phaser.GameObjects.Sprite;
    public weaponTopSprite: Phaser.GameObjects.Sprite;
    private scene: ServerControlledGameScene;
    public skinColor: number;
    public hairColor: number;
    public primaryColor: number;
    public secondaryColor: number;
    public gender: Gender;
    public className: CharacterProfession;
    public visible: boolean = true;
    public processingFieldAction: boolean = false;
    public isInteractingWithInteractable: boolean = false;

    public constructor(data: PlayerSpriteParameters) {
        this.scene = data.scene;
        this.skinColor = data.skinColor;
        this.hairColor = data.hairColor;
        this.primaryColor = data.primaryColor;
        this.secondaryColor = data.secondaryColor;
        this.gender = data.gender;
        this.className = data.className;
        this.inConflict = data.inConflict;
        this.visible = data.visible;

        this.width = tileSize;
        this.height = tileSize;

        let bottomWeaponTexture: SpriteSheet;
        switch (this.className) {
            case CharacterProfession.Runeblade:
                bottomWeaponTexture = SpriteSheet.RunebladeWeaponBottom;
                break;
            case CharacterProfession.Aethermancer:
                bottomWeaponTexture = SpriteSheet.AethermancerWeaponBottom;
                break;
            case CharacterProfession.Lifeweaver:
                bottomWeaponTexture = SpriteSheet.LifeweaverWeaponBottom;
                break;
            default:
                throw new Error(`Invalid class name: ${this.className}`);
        }

        this.weaponBottomSprite = new Phaser.GameObjects.Sprite(data.scene, data.x, data.y, bottomWeaponTexture, data.frame);
        this.setBottomWeaponTint(heroWeaponColor);
        this.sprites.push(this.weaponBottomSprite);
        this.scene.add.existing(this.weaponBottomSprite);

        // Create the base character sprite and add it to the container
        this.baseSprite = new Phaser.GameObjects.Sprite(data.scene, data.x, data.y, SpriteSheet.HeroBaseSprite, PlayerSprite.directionToFrame(data.facingDirection) + data.scene.currentFrame);
        this.setBaseTint(data.skinColor); // Set the tint for the base sprite
        this.sprites.push(this.baseSprite); // Add the base sprite to the sprites array
        this.scene.add.existing(this.baseSprite);
        this.primaryColorSprite = new Phaser.GameObjects.Sprite(data.scene, data.x, data.y, data.primaryColorTexture, data.frame);
        this.setPrimaryColorTint(data.primaryColor);
        this.sprites.push(this.primaryColorSprite);
        this.scene.add.existing(this.primaryColorSprite);

        this.secondaryColorSprite = new Phaser.GameObjects.Sprite(data.scene, data.x, data.y, data.secondaryColorTexture, data.frame);
        this.setSecondaryColorTint(data.secondaryColor);
        this.sprites.push(this.secondaryColorSprite);
        this.scene.add.existing(this.secondaryColorSprite);

        this.tertiaryColorSprite = new Phaser.GameObjects.Sprite(data.scene, data.x, data.y, SpriteSheet.HeroClothesTertiary, data.frame);
        this.setTertiaryColorTint(Number(tertiaryColor));
        this.sprites.push(this.tertiaryColorSprite);
        this.scene.add.existing(this.tertiaryColorSprite);

        let hairTexture: SpriteSheet;
        switch (this.gender) {
            case Gender.Male:
                hairTexture = SpriteSheet.HeroHairMale;
                break;
            case Gender.Female:
                hairTexture = SpriteSheet.HeroHairFemale;
                break;
        }

        // Create the hair sprite and add it to the container
        this.hairSprite = new Phaser.GameObjects.Sprite(data.scene, data.x, data.y, hairTexture, data.frame);
        this.setHairTint(data.hairColor); // Set the tint for the hair sprite
        this.sprites.push(this.hairSprite); // Add the hair sprite to the sprites array
        this.scene.add.existing(this.hairSprite);

        let topWeaponTexture: SpriteSheet;
        switch (this.className) {
            case CharacterProfession.Runeblade:
                topWeaponTexture = SpriteSheet.RunebladeWeaponTop;
                break;
            case CharacterProfession.Aethermancer:
                topWeaponTexture = SpriteSheet.AethermancerWeaponTop;
                break;
            case CharacterProfession.Lifeweaver:
                topWeaponTexture = SpriteSheet.LifeweaverWeaponTop;
                break;
            default:
                throw new Error(`Invalid class name: ${this.className}`);
        }

        this.weaponTopSprite = new Phaser.GameObjects.Sprite(data.scene, data.x, data.y, topWeaponTexture, data.frame);
        this.setTopWeaponTint(heroWeaponColor);
        this.sprites.push(this.weaponTopSprite);
        this.scene.add.existing(this.weaponTopSprite);

        this.facingDirection = data.facingDirection;
        this.targeted = false; // Initially not targeted
        console.log(`setting playername to ${data.playerName} from the player sprite constructor`);
        this.playerName = data.playerName; // Store the player name

        // Create a Graphics object for the sprite black border
        this.blackBorder = data.scene.add.graphics({lineStyle: {width: 5, color: 0x000000}}); // Notice the increased width
        this.blackBorder.strokeRect(
            data.x - (this.width / 2) - borderPadding,
            data.y - this.height - borderPadding,
            this.width + 2 * borderPadding,
            this.height + 2 * borderPadding
        );
        this.blackBorder.setVisible(false); // Initially invisible
        this.blackBorder.setDepth(DepthLevel.TARGET_RECT_SUB); // It should be behind the white border

        console.log('Before drawing the white border:');
        console.log(`x-coordinate: ${data.x}`);
        console.log(`y-coordinate: ${data.y}`);
        console.log(`Tile size: ${tileSize}`);
        console.log(`Border padding: ${borderPadding}`);

        // Define a scale factor
        const scaleFactor = 2; // Adjust this factor as needed

        // Calculate dimensions before drawing the white border
        const borderStartX = data.x - (tileSize / 2) - borderPadding * scaleFactor;
        const borderStartY = data.y - tileSize - borderPadding * scaleFactor;
        const borderWidth = (tileSize + 2 * borderPadding) * scaleFactor;
        const borderHeight = (tileSize + 2 * borderPadding) * scaleFactor;

        console.log('Calculated dimensions for the white border with scaling:');
        console.log(`Border starting X (scaled): ${borderStartX}`);
        console.log(`Border starting Y (scaled): ${borderStartY}`);
        console.log(`Border width (scaled): ${borderWidth}`);
        console.log(`Border height (scaled): ${borderHeight}`);

        // Draw the white border
        this.whiteBorder = data.scene.add.graphics({lineStyle: {width: 2, color: 0xffffff}});
        this.whiteBorder.strokeRect(borderStartX, borderStartY, borderWidth, borderHeight);

        console.log('After drawing the scaled white border:');
        console.log(`Graphics object X: ${this.whiteBorder.x}`);
        console.log(`Graphics object Y: ${this.whiteBorder.y}`);
        console.log(`Expected white border X (scaled): ${borderStartX}`);
        console.log(`Expected white border Y (scaled): ${borderStartY}`);
        console.log(`Expected white border Width (scaled): ${borderWidth}`);
        console.log(`Expected white border Height (scaled): ${borderHeight}`);

        this.whiteBorder.setVisible(false); // Initially invisible
        this.whiteBorder.setDepth(DepthLevel.TARGET_RECT_MID);

        this.redBorder = data.scene.add.graphics({lineStyle: {width: 2, color: 0xff0000}});
        this.redBorder.strokeRect(
            data.x - (this.width / 2) - borderPadding,
            data.y - this.height - borderPadding,
            this.width + 2 * borderPadding,
            this.height + 2 * borderPadding
        );
        this.redBorder.setVisible(false); // Initially invisible
        this.redBorder.setDepth(DepthLevel.TARGET_RECT_MID); // It should be on top of the other borders

        data.scene.add.existing(this.redBorder); // Adding the red border to the scene

        this.greenBorder = data.scene.add.graphics({lineStyle: {width: 2, color: 0x00ff00}});
        this.greenBorder.strokeRect(
            data.x - (this.width / 2) - borderPadding,
            data.y - this.height - borderPadding,
            this.width + 2 * borderPadding,
            this.height + 2 * borderPadding
        );
        this.greenBorder.setVisible(false); // Initially invisible
        this.greenBorder.setDepth(DepthLevel.TARGET_RECT_MID); // It should be on top of the other borders

        data.scene.add.existing(this.greenBorder);

        data.scene.add.existing(this.blackBorder); // Adding the black border to the scene
        data.scene.add.existing(this.whiteBorder); // Adding the white border to the scene

        // Instantiate the chat frame above the player
        this.createChatFrame();
        // this.createChatTextCenteredByScreenCameraCoordinates();
        this.createPlayerCenteredChatText();

        this.updateChatFrameSizeToFitText();

        this.invisibleButton = this.scene.add.rectangle(
            this.x,
            this.y,
            this.width,
            this.height,
            0xFF0000,
            0
        );
        this.setOrigin(0.5, 1);

        if (this.inConflict || !this.visible) {
            this.setVisible(false);
        }
    }

    // public updateChatTextPosition(): void {
    //     if (this.chatText) {
    //         const textOffsetY = -120; // Replica of the offloading to the launch
    //         this.chatText.setPosition(this.chatFrame.x, this.chatFrame.y + textOffsetY);
    //     }
    // }

    public updateChatFrameSizeToFitText(): void {

        if (this.chatText && this.chatFrame) {
            // Measure the text width and height
            const textWidth = this.chatText.width;
            const textHeight = this.chatText.height;

            // Define padding for the chat frame
            const paddingWidth = 20; // Total horizontal padding: 10px on each side
            const paddingHeight = 20; // Total vertical padding: 10px on top and bottom

            // Calculate the new dimensions for the chat frame to fit the text plus padding
            const newFrameWidth = textWidth + paddingWidth;
            const newFrameHeight = textHeight + paddingHeight;

            // Optional: Define minimum dimensions for the chat frame
            const frameWidth = newFrameWidth;
            const frameHeight = newFrameHeight;

            // Calculate the position based on the player's current position with offsets
            const chatFrameXOffset = 0;
            const chatFrameYOffset = -75 - (textHeight * 0.5); // Adjust according to desired position relative to the player

            const chatFrameX = this.x + chatFrameXOffset;
            const chatFrameY = this.y + chatFrameYOffset;

            // Update the chat frame's position
            this.chatFrame.setPosition(chatFrameX, chatFrameY);

            // Clear previously drawn graphics if necessary
            this.chatFrame.graphics.clear();
            this.chatFrame.frameGraphics.clear();

            // Redraw the chat frame with the new size and at the new position
            this.chatFrame.redrawGraphics(chatFrameX, chatFrameY, frameWidth, frameHeight);
        }
    }

    public createPlayerCenteredChatText(): void {
        console.log('[createPlayerCenteredChatText] Invocation begins. Preparing to create player-centered chat content.');

        const textContent = 'cool player test'; // This is the placeholder text content.

        // Assuming `this.sprite.x` and `this.sprite.y` represent the player's current position in the world.
        const playerX = this.x;
        const playerY = this.y;

        // Define an offset for where the chat text will appear relative to the player's position.
        // This could be adjusted so the text appears directly above the player, for instance.
        const offsetX = 0; // Horizontal offset from the player's center.
        const offsetY = -75; // Vertical offset from the player's position (above the player).

        // Calculate the position for the chat text directly based on the player's position plus any offsets.
        const chatTextX = playerX + offsetX;
        const chatTextY = playerY + offsetY;

        // Log the position for debugging purposes.
        console.log(`Creating chat text at player-relative position. Coords: X: ${chatTextX}, Y: ${chatTextY}`);

        // Create the text element at the position calculated based on the player's position.
        this.chatText = this.scene.add.text(chatTextX, chatTextY, textContent, {
            fontFamily: mainGameFont, // Make sure 'gameFont' is defined or replace it with the actual font name.
            fontSize: '36px', // Base text size.
            color: '#ffffff', // Set text color to white for visibility.
            align: 'center', // Ensure the text is centered.
            wordWrap: {width: 300, useAdvancedWrap: true} // Adjust the width as necessary.
        })
            .setDepth(DepthLevel.UI_PRIMARY_TEXT) // Ensure the text is placed at the intended depth.
            .setScrollFactor(1) // This ensures the text moves with the world (set to 1 to move with the player).
            .setOrigin(0.5, 1) // Center the origin of the text for accurate positioning.
            .setVisible(false); // Make sure the text is visible upon creation.

        console.log('[createPlayerCenteredChatText] Player-centered chat text created.');
    }

    public updateChatBubblePosition(): void {

        if (this.chatText) {
            // Define offsets if you need to adjust the position relative to the player
            const chatTextXOffset = 0; // Horizontal offset from the player's center
            const chatTextYOffset = -75; // Vertical offset from the player's position (above the player)

            // Calculate the new position based on the player's current position
            const chatTextX = this.x + chatTextXOffset;
            const chatTextY = this.y + chatTextYOffset;

            // Update the chat text's position
            this.chatText.setPosition(chatTextX, chatTextY);

        }
    }

    private createChatFrame(): void {
        const chatFrameWidth = 200; // Example width
        const chatFrameHeight = 50; // Example height
        const chatFrameXOffset = 0; // X offset from the player's center
        const chatFrameYOffset = -100; // Y offset from the player's top

        // Calculate the position based on the player's current position
        const chatFrameX = this.x + chatFrameXOffset;
        const chatFrameY = this.y + chatFrameYOffset;

        console.log(`[createChatFrame] Calculated chat frame position: X: ${chatFrameX}, Y: ${chatFrameY}`);
        // Create the chat frame
        this.chatFrame = new ResizableFrame(
            this.scene,
            chatFrameX,
            chatFrameY,
            chatFrameWidth,
            chatFrameHeight
        );

        // Initially, you might want to hide the chat frame until needed
        this.chatFrame.hideFrame();
    }

    public updateChatFramePositionAndSize(): void {
        if (this.chatText && this.chatFrame) {
            // Measure the text width and height
            const textWidth = this.chatText.width;
            const textHeight = this.chatText.height;

            // Define padding for the chat frame
            const paddingWidth = 20; // Total horizontal padding: 10px on each side
            const paddingHeight = 20; // Total vertical padding: 10px on top and bottom

            // Calculate the new dimensions for the chat frame to fit the text plus padding
            const newFrameWidth = textWidth + paddingWidth;
            const newFrameHeight = textHeight + paddingHeight;

            // Optional: Define minimum dimensions for the chat frame
            const frameWidth = newFrameWidth;
            const frameHeight = newFrameHeight;

            // Calculate the position based on the player's current position with offsets
            const chatFrameXOffset = 0;
            const chatFrameYOffset = -75 - (textHeight * 0.5); // Adjust according to desired position relative to the player

            const chatFrameX = this.x + chatFrameXOffset;
            const chatFrameY = this.y + chatFrameYOffset;

            // Update the chat frame's position
            this.chatFrame.setPosition(chatFrameX, chatFrameY);

            // Clear previously drawn graphics if necessary
            this.chatFrame.graphics.clear();
            this.chatFrame.frameGraphics.clear();

            // Redraw the chat frame with the new size and at the new position
            this.chatFrame.redrawGraphics(chatFrameX, chatFrameY, frameWidth, frameHeight);
        }
    }

    // Method to show the chat frame and turn it off after 5 seconds

    public showChatFrame(): void {
        // Increment the counter each time the chat frame is shown
        this.scene.chatFrameCounter++;

        this.chatFrame.showFrame();

    }

    // Method to hide the chat frame
    public hideChatFrame(): void {
        this.chatFrame.hideFrame();
    }

    // Getter for targeted
    public get targeted(): boolean {
        return this._targeted;
    }

    // Setter for targeted
    public set targeted(value: boolean) {

        // Set the new value
        this._targeted = value;
    }

    public static directionToFrame(direction: Direction): number {
        switch (direction) {
            case Direction.DOWN:
                return 0; // Base frame for DOWN is 0
            case Direction.LEFT:
                return 2; // Base frame for LEFT is 2
            case Direction.UP:
                return 6; // Base frame for UP is 6
            case Direction.RIGHT:
                return 4; // Base frame for RIGHT is 4
            default:
                throw new Error(`Invalid direction: ${direction}`);
        }
    }

    // Show the border
    public showBorder(): void {
        if (!this.visible) {
            this.hideBorder();
            return;
        }
        this.borderVisible = true;
        this.blackBorder.clear();
        this.blackBorder.strokeRect(
            this.x - (this.width / 2) - borderPadding,
            this.y - this.height - borderPadding,
            this.width + 2 * borderPadding,
            this.height + 2 * borderPadding
        );
        this.blackBorder.setVisible(true);

        if (this.isTargetedForInvite) {
            this.greenBorder.clear();
            this.greenBorder.strokeRect(
                this.x - (this.width / 2) - borderPadding,
                this.y - this.height - borderPadding,
                this.width + 2 * borderPadding,
                this.height + 2 * borderPadding
            );
            this.greenBorder.setVisible(true);
        } else if (this.isTargetedForDuel) {
            this.redBorder.clear();
            this.redBorder.strokeRect(
                this.x - (this.width / 2) - borderPadding,
                this.y - this.height - borderPadding,
                this.width + 2 * borderPadding,
                this.height + 2 * borderPadding
            );
            this.redBorder.setVisible(true);
        } else {
            this.whiteBorder.clear();
            this.whiteBorder.strokeRect(
                this.x - (this.width / 2) - borderPadding,
                this.y - this.height - borderPadding,
                this.width + 2 * borderPadding,
                this.height + 2 * borderPadding
            );
            this.whiteBorder.setVisible(true);
        }
    }

    // Hide the border
    public hideBorder(): void {
        this.borderVisible = false;

        this.blackBorder.clear();
        this.blackBorder.setVisible(false);

        this.whiteBorder.clear();
        this.whiteBorder.setVisible(false);

        this.redBorder.clear();
        this.redBorder.setVisible(false);

        this.greenBorder.clear();
        this.greenBorder.setVisible(false);
    }

    // Update the border
    public updateBorder(): void {
        this.blackBorder.clear(); // Clear the old black border
        this.blackBorder.strokeRect(
            this.x - (this.width / 2) - borderPadding,
            this.y - this.height - borderPadding,
            this.width + 2 * borderPadding,
            this.height + 2 * borderPadding
        );

        this.whiteBorder.clear(); // Clear the old white border
        this.whiteBorder.strokeRect(
            this.x - (this.width / 2) - borderPadding,
            this.y - this.height - borderPadding,
            this.width + 2 * borderPadding,
            this.height + 2 * borderPadding
        );

        this.redBorder.clear();
        this.redBorder.strokeRect(
            this.x - (this.width / 2) - borderPadding,
            this.y - this.height - borderPadding,
            this.width + 2 * borderPadding,
            this.height + 2 * borderPadding
        );

        this.greenBorder.clear();
        this.greenBorder.strokeRect(
            this.x - (this.width / 2) - borderPadding,
            this.y - this.height - borderPadding,
            this.width + 2 * borderPadding,
            this.height + 2 * borderPadding
        );
    }

    public toggleBorderVisibility(): void {
        if (!this.visible) {
            this.hideBorder();
            return;
        }
        if (this.targeted) {
            this.borderVisible = !this.borderVisible; // Flip the border visibility
            if (this.borderVisible) {
                this.showBorder();
            } else {
                this.hideBorder();
            }
        } else {
            this.hideBorder();
        }
    }

    // Method to set tint for the base sprite
    public setBaseTint(color: number): void {
        this.skinColor = color;
        this.baseSprite.setTint(color);
    }

    // Method to set tint for the hair sprite
    public setHairTint(color: number): void {
        this.hairSprite.setTint(color);
    }

    // Method to set the frame for both the base sprite and the hair sprite
    public setFrame(frame: number | string): void {
        // Assuming that both sprites have the same frame names or indices
        for (const sprite of this.sprites) {
            sprite.setFrame(frame);
        }
    }

    // Method to set the origin for both the base sprite and the hair sprite
    public setOrigin(x: number, y: number): void {
        for (const sprite of this.sprites) {
            sprite.setOrigin(x, y);
        }
        this.invisibleButton.setOrigin(x, y);
    }

    public destroy(): void {
        // Assuming this.chatFrame and this.chatText are the objects you want to destroy
        if (this.chatFrame) {
            this.chatFrame.hideFrame();
            this.chatFrame.destroy();
        }
        if (this.chatText) {
            this.chatText.destroy();
        }

        // Clear the timeout to prevent it from triggering after the object has been destroyed
        if (this.hideChatBubbleTimeoutId) {
            clearTimeout(this.hideChatBubbleTimeoutId);
        }

        this.blackBorder.destroy();
        this.whiteBorder.destroy();
        this.redBorder.destroy();
        this.greenBorder.destroy();
        // super.destroy();
        for (const sprite of this.sprites) {
            sprite.destroy();
        }
        this.invisibleButton.destroy();
    }

    public get x(): number {
        return this.baseSprite.x;
    }

    public set x(x: number) {
        for (const sprite of this.sprites) {
            sprite.x = x;
        }
        this.invisibleButton.x = x;
    }

    public get y(): number {
        return this.baseSprite.y;
    }

    public set y(y: number) {
        for (const sprite of this.sprites) {
            sprite.y = y;
        }
        this.invisibleButton.y = y;
    }

    public setPosition(x: number, y: number): void {
        for (const sprite of this.sprites) {
            sprite.setPosition(x, y);
        }
        this.invisibleButton.setPosition(x, y);
        this.updateChatFramePositionAndSize();
        this.updateChatBubblePosition();
    }

    public setDepth(depth: number): this {
        for (const sprite of this.sprites) {
            sprite.setDepth(depth);
        }
        this.invisibleButton.setDepth(depth);
        return this;
    }

    public setVisible(visible: boolean): this {
        this.visible = visible;
        for (const sprite of this.sprites) {
            sprite.setVisible(visible);
        }
        this.invisibleButton.setVisible(visible);
        return this;
    }

    public setInteractive(): this {
        this.invisibleButton.setInteractive();
        return this;
    }

    public on(event: string, callback: () => void): this {
        this.invisibleButton.on(event, callback);
        return this;
    }

    public off(event: string): this {
        this.invisibleButton.off(event);
        return this;
    }

    private setPrimaryColorTint(primaryColor: number): void {
        this.primaryColorSprite.setTint(primaryColor);
    }

    private setSecondaryColorTint(secondaryColor: number): void {
        this.secondaryColorSprite.setTint(secondaryColor);
    }

    private setTertiaryColorTint(tertiaryColor: number): void {
        this.tertiaryColorSprite.setTint(tertiaryColor);
    }

    private setBottomWeaponTint(heroWeaponColor: string): void {
        this.weaponBottomSprite.setTint(parseInt(heroWeaponColor));
    }

    private setTopWeaponTint(heroWeaponColor: string): void {
        this.weaponTopSprite.setTint(parseInt(heroWeaponColor));
    }

    public showChatBubble(message: string): void {
        const depthLevelIndex = (this.scene.chatFrameCounter - 1) % 10 + 1; // Maps counter to 1-10
        let textDepth;

        // Switch case to map depthLevelIndex to actual depth values
        switch (depthLevelIndex) {
            case 1:
                textDepth = DepthLevel.UI_PRIMARY_TEXT;
                break;
            case 2:
                textDepth = DepthLevel.UI_SECONDARY_TEXT;
                break;
            case 3:
                textDepth = DepthLevel.UI_TERTIARY_TEXT;
                break;
            case 4:
                textDepth = DepthLevel.UI_QUATERNARY_TEXT;
                break;
            case 5:
                textDepth = DepthLevel.UI_QUINARY_TEXT;
                break;
            case 6:
                textDepth = DepthLevel.UI_SENARY_TEXT;
                break;
            case 7:
                textDepth = DepthLevel.UI_SEPTENARY_TEXT;
                break;
            case 8:
                textDepth = DepthLevel.UI_OCTONARY_TEXT;
                break;
            case 9:
                textDepth = DepthLevel.UI_NONARY_TEXT;
                break;
            case 10:
                textDepth = DepthLevel.UI_DENARY_TEXT;
                break;
            default:
                // Optionally handle unexpected values
                textDepth = DepthLevel.UI_PRIMARY_TEXT;
                break;
        }

        console.log(`showChatBubble: Setting text depth to ${textDepth} for depthLevelIndex ${depthLevelIndex}`);

        // Assuming this.chatText is your text object
        this.chatText.setDepth(textDepth);
        this.chatText.setText(message);
        this.chatText.setVisible(true);

        this.chatFrame.setDepthAndRedraw(depthLevelIndex as 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10);

        this.showChatFrame();
        this.updateChatFramePositionAndSize();
        this.updateChatBubblePosition();

        // Clear the existing timeout if it exists
        if (this.hideChatBubbleTimeoutId !== null) {
            clearTimeout(this.hideChatBubbleTimeoutId);
        }

        // Use setTimeout to schedule the frame to be hidden after 5 seconds and store the timeout ID
        this.hideChatBubbleTimeoutId = setTimeout(() => {
            this.hideChatFrame();
            this.chatText.setVisible(false);
            this.hideChatBubbleTimeoutId = null; // Reset the timeout ID
        }, 5000); // 5000 milliseconds = 5 seconds
    }
}
