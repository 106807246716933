import ServerControlledConflictUIScene from '../../../../scenes/ServerControlledUIScenes/ServerControlledConflictUIScene';
import {ConflictMenuType} from '../../../../types/ConflictMenuType';

export abstract class GameConflictActionMenu {
    public abstract readonly menuTag: ConflictMenuType;
    public shown: boolean = false;
    public abstract relatedMenusToDismiss: Set<ConflictMenuType>;

    public abstract activate(params?: unknown): void;
    public abstract dismiss(): void;
    public scene: ServerControlledConflictUIScene;

    public abstract emitMenuActivationRequest(params?: unknown): void;
    public abstract emitMenuDismissalRequest(): void;

    public dismissRelatedMenus(): void {
        console.log(`[${this.menuTag}.dismissRelatedMenus] Starting to dismiss related menus.`);

        this.relatedMenusToDismiss.forEach(menuType => {
            console.log(`[${this.menuTag}.dismissRelatedMenus] Dismissing related menu of type ${menuType}.`);
            switch (menuType) {
                case ConflictMenuType.AbilityMenu:
                    console.log(`[${this.menuTag}.dismissRelatedMenus] Dismissing Ability Menu.`);
                    this.scene.abilityMenu.dismiss();
                    break;
                case ConflictMenuType.AttackMenu:
                    console.log(`[${this.menuTag}.dismissRelatedMenus] Dismissing Attack Menu.`);
                    this.scene.attackMenu.dismiss();
                    break;
                case ConflictMenuType.InventoryMenu:
                    console.log(`[${this.menuTag}.dismissRelatedMenus] Dismissing Inventory Menu.`);
                    this.scene.inventoryMenu.dismiss();
                    break;
                case ConflictMenuType.HotkeyMenu:
                    console.log(`[${this.menuTag}.dismissRelatedMenus] Dismissing Hotkey Menu.`);
                    this.scene.hotkeyMenu.dismiss();
                    break;
                case ConflictMenuType.ConflictMessageElement:
                    console.log(`[${this.menuTag}.dismissRelatedMenus] Dismissing Conflict Message Element.`);
                    this.scene.messageElement.dismiss();
                    break;
                case ConflictMenuType.ConflictCommandMenu:
                    console.log(`[${this.menuTag}.dismissRelatedMenus] Dismissing Conflict Command Menu.`);
                    this.scene.commandMenu.dismiss();
                    break;
                // Add additional case handling for other menus as necessary
            }
        });

        console.log(`[${this.menuTag}.dismissRelatedMenus] Completed dismissing related menus.`);
    }

    // Other methods remain unchanged
}
