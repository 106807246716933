import Phaser from 'phaser';
import {DepthLevel} from '../../../types/DepthLevel';

export default class FramedUIActionButton extends Phaser.GameObjects.Container {
    public button!: Phaser.GameObjects.Image;
    private graphics: Phaser.GameObjects.Graphics;
    private frameGraphics: Phaser.GameObjects.Graphics;
    private targetCallback: () => void;

    public constructor(
        scene: Phaser.Scene,
        x: number,
        y: number,
        private key: string,
        private activeKey: string,
        targetCallback: () => void
    ) {
        super(scene, x, y);

        this.targetCallback = targetCallback;

        const padding = 3; // padding for the white frame
        const frameThickness = 2; // thickness of the frame

        // Green Rectangle
        this.graphics = scene.add.graphics();
        this.graphics.fillStyle(0x333399, 1); // set fill color to green
        this.graphics.fillRoundedRect(x - 25, y - 25, 50, 50, 10);
        this.graphics.lineStyle(3, 0x000000); // black color for the border
        this.graphics.strokeRoundedRect(x - 25, y - 25, 50, 50, 10);
        this.graphics.setDepth(DepthLevel.UI_PRIMARY_BASE_MID);

        // White Frame
        this.frameGraphics = scene.add.graphics();
        this.frameGraphics.fillStyle(0xffffff, 1); // set fill color to white
        this.frameGraphics.fillRoundedRect(x - 25 - padding - frameThickness, y - 25 - padding - frameThickness, 50 + 2 * padding + 2 * frameThickness, 50 + 2 * padding + 2 * frameThickness, 10 + frameThickness);
        this.frameGraphics.lineStyle(3, 0x000000); // black color for the frame border
        this.frameGraphics.strokeRoundedRect(x - 25 - padding - frameThickness, y - 25 - padding - frameThickness, 50 + 2 * padding + 2 * frameThickness, 50 + 2 * padding + 2 * frameThickness, 10 + frameThickness);
        this.frameGraphics.setDepth(DepthLevel.UI_PRIMARY_BASE_SUB);

        this.button = this.scene.add.image(0, 0, key);
        this.button.setScale(2);
        this.button.setInteractive().on('pointerdown', this.targetCallback);

        this.add(this.button);

        this.setDepth(DepthLevel.UI_PRIMARY_BASE_TOP);

        scene.add.existing(this);
    }

    public showActionButton(): void {
        this.button.setVisible(true);
        this.graphics.setVisible(true);
        this.frameGraphics.setVisible(true);
    }

    public hideActionButton(): void {
        this.button.setVisible(false);
        this.graphics.setVisible(false);
        this.frameGraphics.setVisible(false);
    }

    public destroyUIActionButton(): void {
        this.button.destroy();
        this.graphics.destroy();
        this.frameGraphics.destroy();
    }

    public deselect(): void {
        this.button.setTexture(this.key);
    }

    public select(): void {
        this.button.setTexture(this.activeKey);
    }
}

