import {DialogueOption} from '../../../../../../types/dialogue/DialogueTypes';
import {mainGameFont} from '../../../../GameConfig';
import ServerControlledGameUIScene from '../../../../scenes/ServerControlledUIScenes/ServerControlledGameUIScene';
import {DepthLevel} from '../../../../types/DepthLevel';
import {FieldMenuType} from '../../../../types/FieldMenuType';
import ResizableFrame from '../../UtilityComponents/ResizableFrame';
import {GameFieldActionMenu} from './GameFieldActionMenu';

export class InnkeeperNPCMenuElements extends GameFieldActionMenu {
    public shown: boolean = false;
    public menuTag = FieldMenuType.InnkeeperNPCMenu;
    public relatedMenusToDismiss: Set<FieldMenuType> = new Set([
        FieldMenuType.AbilityMenu,
        FieldMenuType.CharacterSheetMenu,
        FieldMenuType.TargetMenu,
        FieldMenuType.DuelNotification,
        FieldMenuType.DismissibleNotification,
        FieldMenuType.InviteNotification,
        FieldMenuType.PartyOrderMenu,
        FieldMenuType.MessageElement,
        FieldMenuType.InventoryMenu,
        FieldMenuType.TradeMenu,
        FieldMenuType.InteractableMessageDisplay,

        FieldMenuType.InteractionMenu,
        // Any other menu that should be dismissed when AbilityMenu is active
    ]);
    public canCoincideWithGamePad = false;
    public roomPriceFrame: ResizableFrame;
    public roomPriceLabelText: Phaser.GameObjects.Text;
    public roomPriceValueText: Phaser.GameObjects.Text;
    public playerGoldFrame: ResizableFrame;
    public playerGoldLabelText: Phaser.GameObjects.Text;
    public playerGoldValueText: Phaser.GameObjects.Text;

    public constructor(scene: ServerControlledGameUIScene) {
        super();
        this.scene = scene;

        const centerX = scene.cameras.main.centerX;
        const centerY = scene.cameras.main.centerY;

        // Create frame for room price
        this.roomPriceFrame = new ResizableFrame(scene, centerX + 198, centerY - 195, 440, 50);
        this.roomPriceFrame.hideFrame();

        this.roomPriceLabelText = scene.add.text(
            centerX - 18, centerY - 217,
            'Room Price:',
            {
                fontFamily: mainGameFont,
                fontSize: '48px',
                color: '#ffffff'
            }
        )
            .setDepth(DepthLevel.UI_PRIMARY_TEXT)
            .setResolution(3)
            .setOrigin(0)
            .setVisible(false); // Set visibility to false

        this.roomPriceValueText = scene.add.text(
            // Assuming the X position to be at the right-most corner of the frame, modify as needed
            centerX + 410, centerY - 217,
            '',
            {
                fontFamily: mainGameFont,
                fontSize: '48px',
                color: '#ffffff'
            }
        )
            .setDepth(DepthLevel.UI_PRIMARY_TEXT)
            .setResolution(3)
            .setOrigin(1, 0)
            .setVisible(false); // Set visibility to false

        // Create frame for player's current gold
        this.playerGoldFrame = new ResizableFrame(scene, centerX + 198, centerY - 265, 440, 50);
        this.playerGoldFrame.hideFrame();

        this.playerGoldLabelText = scene.add.text(
            centerX - 18, centerY - 287,
            'Your Gold:',
            {
                fontFamily: mainGameFont,
                fontSize: '48px',
                color: '#ffffff'
            }
        )
            .setDepth(DepthLevel.UI_PRIMARY_TEXT)
            .setResolution(3)
            .setOrigin(0)
            .setVisible(false); // Set visibility to false

        this.playerGoldValueText = scene.add.text(
            // Assuming the X position to be at the right-most corner of the frame, modify as needed
            centerX + 410, centerY - 287,
            '',
            {
                fontFamily: mainGameFont,
                fontSize: '48px',
                color: '#ffffff'
            }
        )
            .setDepth(DepthLevel.UI_PRIMARY_TEXT)
            .setResolution(3)
            .setOrigin(1, 0)
            .setVisible(false); // Set visibility to false
    }

    public emitMenuDismissalRequest(): void{
        this.dismiss();
    }

    public emitMenuActivationRequest(params?: {npcName: string, dialogue: string, options: DialogueOption[], roomPrice: number, playerGold: number}): void {
        // Check if the player is currently processing a field ability
        if (
            this.scene.serverControlledGameScene.player.processingFieldAction
        ) {
            console.log('[InnkeeperNPCMenuElements.emitMenuActivationRequest] Player is processing a field action. Exiting early.');
            return; // Exit early
        }

        console.log('[InnkeeperNPCMenuElements.emitMenuActivationRequest] Emitting attempt to open commoner npc menu event with params: ', params);
        if (params) {
            this.activate(params);
        }
    }

    // Extend show() to include player's current gold and room price
    public activate(params: {npcName: string, dialogue: string, options: DialogueOption[], roomPrice: number, playerGold: number}): void {
        this.shown = true;
        console.log('[InnkeeperNPCMenuElements.activate] Entering method. Activating Commoner NPC Menu');
        console.log('[InnkeeperNPCMenuElements.activate] params:', JSON.stringify(params, null, 2));
        this.dismissRelatedMenus();
        this.scene.serverControlledGameScene.player.inNPCDialogue = true;

        this.scene.commonerNPCMenu.activate({npcName: params.npcName, dialogue: params.dialogue, options: params.options});

        // Show room price
        this.roomPriceFrame.showFrame();
        this.roomPriceLabelText.setVisible(true);
        this.roomPriceValueText.setText(`${params.roomPrice.toLocaleString()} gp`).setVisible(true);

        // Show player's current gold
        this.playerGoldFrame.showFrame();
        this.playerGoldLabelText.setVisible(true);
        this.playerGoldValueText.setText(`${params.playerGold.toLocaleString()} gp`).setVisible(true);
        console.log('[InnkeeperNPCMenuElements.activate] Exiting method.');
    }

    // Override hide() to also hide player's gold frame and text
    public dismiss(): void {
        console.log('[InnkeeperNPCMenuElements.dismiss] Entering method. Dismissing Innkeeper NPC Menu');
        this.scene.serverControlledGameScene.player.inNPCDialogue = false;

        this.scene.commonerNPCMenu.dismiss();
        this.roomPriceFrame.hideFrame();
        this.roomPriceLabelText.setVisible(false);
        this.roomPriceValueText.setVisible(false);
        this.playerGoldFrame.hideFrame();
        this.playerGoldLabelText.setVisible(false);
        this.playerGoldValueText.setVisible(false);

        this.activateInteractionMenuIfNeeded();
        this.shown = false;
        console.log('[InnkeeperNPCMenuElements.dismiss] Exiting method.');
    }
}
