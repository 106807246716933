import {Socket} from 'socket.io-client';
import {UIImageKey} from '../../../../../../types/assets/AssetKeys';
import {ServerSocketEvents} from '../../../../../../types/events/ServerSocketEvents';
import ServerControlledGameUIScene from '../../../../scenes/ServerControlledUIScenes/ServerControlledGameUIScene';
import {DepthLevel} from '../../../../types/DepthLevel';
import {FieldMenuType} from '../../../../types/FieldMenuType';
import NonContainerUIActionButton from '../../ActionButtons/NonContainerUIActionButton';
import ResizableFrame from '../../UtilityComponents/ResizableFrame';
import {GameFieldActionMenu} from './GameFieldActionMenu';

export default class InteractionMenu extends GameFieldActionMenu {
    public shown: boolean = false;
    private interactionFrame: ResizableFrame;
    private interactButton: NonContainerUIActionButton;
    public menuTag = FieldMenuType.InteractionMenu;
    public relatedMenusToDismiss: Set<FieldMenuType> = new Set();
    public canCoincideWithGamePad = true;
    public interactionMenuCanBeShown: boolean = false;

    public constructor(public scene: ServerControlledGameUIScene, private socket: Socket) {
        super();
        const frameX = Number(this.scene.game.config.width) / 2;
        const frameY = 560;
        const frameWidth = 213;
        // reducing frame height from 113 (113 - 40 = 73)
        const frameHeight = 73;

        // Create the frame for the interaction menu at the specified coordinates
        this.interactionFrame = new ResizableFrame(this.scene, frameX, frameY, frameWidth, frameHeight);

        this.interactionFrame.hideFrame();

        // Define 'Interact' button coordinates relative to the frame
        const buttonX = frameX - frameWidth / 2 + 35; // Adjust X as needed
        const buttonY = frameY; // Y is aligned with frame's Y

        // Create 'Interact' button at the specified coordinates
        this.interactButton = new NonContainerUIActionButton(
            this.scene,
            buttonX,
            buttonY,
            UIImageKey.CheckButton,
            UIImageKey.CheckButton,
            'Interact',
            this.onInteract.bind(this) // Bind this context for the interact handler
        );
        this.interactButton.setDepth(DepthLevel.UI_PRIMARY_GRAPHICS_SUB);
        this.interactButton.hideActionButton();

        // Initially hide the interaction menu
        // this.dismiss();
        this.shown = false;
    }

    public activate(): void {
        this.shown = true;
        console.log('[InteractionMenu.activate] Entering method. Activating Interaction Menu');
        this.dismissRelatedMenus();
        this.interactionFrame.showFrame();
        this.interactButton.showActionButton();
        console.log('[InteractionMenu.activate] Exiting method.');
    }

    public dismiss(): void {
        console.log('[InteractionMenu.dismiss] Entering method. Dismissing Interaction Menu');
        this.interactionFrame.hideFrame();
        this.interactButton.hideActionButton();

        this.activateInteractionMenuIfNeeded();
        this.shown = false;
        console.log('[InteractionMenu.dismiss] Exiting method.');
    }

    private onInteract(): void {
        // Code to handle 'Interact' action
        console.log('Interaction button clicked');
        // Send interaction status to the server or handle it directly here
        // Check if the player is currently processing a field ability
        if (!this.scene.canProceedWithMenuAction()) {
            // If false, exit early. Logging is already handled within `canProceedWithMenuAction`.
            return;
        }

        this.scene.waitingForServerResponse = true;
        this.socket.emit(ServerSocketEvents.AttemptInteract);
    }

    public emitMenuActivationRequest(): void {
        this.activate();
    }

    public emitMenuDismissalRequest(): void{
        this.dismiss();
    }
}
