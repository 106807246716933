import ServerControlledGameUIScene from '../../../../scenes/ServerControlledUIScenes/ServerControlledGameUIScene';
import {FieldMenuType} from '../../../../types/FieldMenuType';

export abstract class GameFieldActionMenu {
    public abstract readonly menuTag: FieldMenuType;
    public shown: boolean = false;
    public abstract relatedMenusToDismiss: Set<FieldMenuType>;

    public abstract canCoincideWithGamePad: boolean;
    public scene: ServerControlledGameUIScene;

    public abstract activate(params?: unknown): void;
    public abstract dismiss(): void;

    /**
     * Sends a request to the game server to attempt opening a menu, asking for the necessary permission.
     * Subclasses should implement the specifics of this request, including handling of any parameters
     * required by the server and processing the server's response to determine if the menu can be activated.
     *
     * This method abstracts the process of communicating with the server, allowing for flexible implementations
     * based on the menu's role and requirements in the game.
     *
     * @param params Optional parameters that might be needed for the server request.
     * @returns Promise<boolean> A promise that resolves with a boolean indicating whether the permission was granted.
     */
    public abstract emitMenuActivationRequest(params?: unknown): void;

    public abstract emitMenuDismissalRequest(): void;

    // Method to dismiss related menus
    public dismissRelatedMenus(): void {
        console.log(`[${this.menuTag}.dismissRelatedMenus] Starting to dismiss related menus.`);

        this.relatedMenusToDismiss.forEach(menuType => {
            console.log(`[${this.menuTag}.dismissRelatedMenus] Dismissing related menu of type ${menuType}.`);
            switch (menuType) {
                case FieldMenuType.CharacterSheetMenu:
                    console.log(`[${this.menuTag}.dismissRelatedMenus] Dismissing Character Sheet Menu.`);
                    this.scene.characterSheetMenu.dismiss();
                    console.log(`[${this.menuTag}.dismissRelatedMenus] Character Sheet Menu dismissed.`);
                    break;
                case FieldMenuType.AbilityMenu:
                    console.log(`[${this.menuTag}.dismissRelatedMenus] Dismissing Ability Menu.`);
                    this.scene.abilityMenu.dismiss();
                    console.log(`[${this.menuTag}.dismissRelatedMenus] Ability Menu dismissed.`);
                    break;
                case FieldMenuType.TargetMenu:
                    console.log(`[${this.menuTag}.dismissRelatedMenus] Dismissing Target Menu.`);
                    this.scene.targetMenu.dismiss();
                    console.log(`[${this.menuTag}.dismissRelatedMenus] Target Menu dismissed.`);
                    break;
                case FieldMenuType.DuelNotification:
                    console.log(`[${this.menuTag}.dismissRelatedMenus] Dismissing Duel Notification.`);
                    this.scene.duelNotification.dismiss();
                    console.log(`[${this.menuTag}.dismissRelatedMenus] Duel Notification dismissed.`);
                    break;
                case FieldMenuType.DismissibleNotification:
                    console.log(`[${this.menuTag}.dismissRelatedMenus] Dismissing Dismissible Notification.`);
                    this.scene.dismissibleNotification.dismiss();
                    console.log(`[${this.menuTag}.dismissRelatedMenus] Dismissible Notification dismissed.`);
                    break;
                case FieldMenuType.InviteNotification:
                    console.log(`[${this.menuTag}.dismissRelatedMenus] Dismissing Invite Notification.`);
                    this.scene.inviteNotification.dismiss();
                    console.log(`[${this.menuTag}.dismissRelatedMenus] Invite Notification dismissed.`);
                    break;
                case FieldMenuType.PartyOrderMenu:
                    console.log(`[${this.menuTag}.dismissRelatedMenus] Dismissing Party Order Menu.`);
                    this.scene.partyOrderMenu.dismiss();
                    console.log(`[${this.menuTag}.dismissRelatedMenus] Party Order Menu dismissed.`);
                    break;
                case FieldMenuType.CommonerNPCMenu:
                    console.log(`[${this.menuTag}.dismissRelatedMenus] Dismissing Commoner NPC Menu.`);
                    this.scene.commonerNPCMenu.dismiss();
                    console.log(`[${this.menuTag}.dismissRelatedMenus] Commoner NPC Menu dismissed.`);
                    break;
                case FieldMenuType.InnkeeperNPCMenu:
                    console.log(`[${this.menuTag}.dismissRelatedMenus] Dismissing Innkeeper NPC Menu.`);
                    this.scene.innkeeperNPCMenu.dismiss();
                    console.log(`[${this.menuTag}.dismissRelatedMenus] Innkeeper NPC Menu dismissed.`);
                    break;
                case FieldMenuType.MerchantNPCMenu:
                    console.log(`[${this.menuTag}.dismissRelatedMenus] Dismissing Merchant NPC Menu.`);
                    this.scene.merchantNPCMenu.dismiss();
                    console.log(`[${this.menuTag}.dismissRelatedMenus] Merchant NPC Menu dismissed.`);
                    break;
                case FieldMenuType.InteractionMenu:
                    console.log(`[${this.menuTag}.dismissRelatedMenus] Dismissing Interaction Menu.`);
                    this.scene.interactionMenu.dismiss();
                    console.log(`[${this.menuTag}.dismissRelatedMenus] Interaction Menu dismissed.`);
                    break;
                case FieldMenuType.MessageElement:
                    console.log(`[${this.menuTag}.dismissRelatedMenus] Dismissing Message Element.`);
                    this.scene.messageElement.dismiss();
                    console.log(`[${this.menuTag}.dismissRelatedMenus] Message Element dismissed.`);
                    break;
                case FieldMenuType.InventoryMenu:
                    console.log(`[${this.menuTag}.dismissRelatedMenus] Dismissing Inventory Menu.`);
                    this.scene.inventoryMenu.dismiss();
                    console.log(`[${this.menuTag}.dismissRelatedMenus] Inventory Menu dismissed.`);
                    break;
                case FieldMenuType.TradeMenu:
                    console.log(`[${this.menuTag}.dismissRelatedMenus] Dismissing Trade Menu.`);
                    this.scene.tradeMenu.dismiss();
                    console.log(`[${this.menuTag}.dismissRelatedMenus] Trade Menu dismissed.`);
                    break;
                case FieldMenuType.InteractableMessageDisplay:
                    console.log(`[${this.menuTag}.dismissRelatedMenus] Dismissing Interactable Message Display.`);
                    this.scene.interactableMessageDisplay.dismiss();
                    console.log(`[${this.menuTag}.dismissRelatedMenus] Interactable Message Display dismissed.`);
                    break;
                // Add logs for any additional menus as needed
            }
        });

        console.log(`[${this.menuTag}.dismissRelatedMenus] Completed dismissing related menus.`);
    }

    // Renamed method for clarity and direct action
    public activateInteractionMenuIfNeeded(): void {
        console.log(`[${this.menuTag}.activateInteractionMenuIfNeeded] Checking if Interaction Menu should be activated.`);
        console.log(`[${this.menuTag}.activateInteractionMenuIfNeeded] Player in NPC Dialogue: ${this.scene.serverControlledGameScene.player.inNPCDialogue}`);
        console.log(`[${this.menuTag}.activateInteractionMenuIfNeeded] Related menus contains Interaction Menu: ${this.relatedMenusToDismiss.has(FieldMenuType.InteractionMenu)}`);
        console.log(`[${this.menuTag}.activateInteractionMenuIfNeeded] Interaction Menu can be shown: ${this.scene.interactionMenu.interactionMenuCanBeShown}`);

        // Check if the player is in NPC dialogue
        if (this.scene.serverControlledGameScene.player.inNPCDialogue) {
            console.log(`[${this.menuTag}.activateInteractionMenuIfNeeded] Player is in NPC dialogue. Interaction Menu activation is not needed.`);
        } else if (this.scene.serverControlledGameScene.serverControlledGameUIScene.playerInNPCSequence) {
            console.log(`[${this.menuTag}.activateInteractionMenuIfNeeded] Player is in NPC sequence. Interaction Menu activation is not needed.`);
        } else if (this.relatedMenusToDismiss.has(FieldMenuType.InteractionMenu) && this.scene.interactionMenu.interactionMenuCanBeShown) {
            console.log(`[${this.menuTag}.activateInteractionMenuIfNeeded] inside if (this.relatedMenusToDismiss.has(FieldMenuType.InteractionMenu) && this.scene.interactionMenu.interactionMenuCanBeShown) branch.`);
            console.log(`[${this.menuTag}.activateInteractionMenuIfNeeded] calling this.scene.interactionMenu.activate().`);
            this.scene.interactionMenu.activate();
        } else {
            console.log(`[${this.menuTag}.activateInteractionMenuIfNeeded] Interaction Menu is not needed or cannot be shown due to other conditions.`);
        }
    }

}
