import numberToWords from 'number-to-words';
import {Socket} from 'socket.io-client';
import {UIImageKey} from '../../../../../../types/assets/AssetKeys';
import {
    AttemptConflictInventoryItemSelectionConfirmResponseEvent
} from '../../../../../../types/conflict/AttemptConflictInventoryItemSelectionConfirmResponseEvent';
import {ServerSocketEvents} from '../../../../../../types/events/ServerSocketEvents';
import {
    SuccessConflictEquipmentItemState
} from '../../../../../../types/inventory/AttemptSelectConflictEquipmentItemResponseEvent';
import {EquipmentItemForDisplay, InventoryItemForDisplay} from '../../../../../../types/inventory/ItemForDisplay';
import {ItemBindingType, ItemType} from '../../../../../../types/items/ItemTypes';
import {CharacterProfession} from '../../../../../../types/mechanics/CharacterProfessionsEnum';
import {BaseInteractionState, ItemCategory} from '../../../../../../types/mechanics/InteractionState';
import {statAbbreviations} from '../../../../../../types/mechanics/StatAbbreviations';
import {mainGameFont} from '../../../../GameConfig';
import ServerControlledConflictUIScene from '../../../../scenes/ServerControlledUIScenes/ServerControlledConflictUIScene';
import {ConflictMenuType} from '../../../../types/ConflictMenuType';
import {DepthLevel} from '../../../../types/DepthLevel';
import {ClientInteractionStateRegexHelper} from '../../../../utils/clientInteractionStateRegexHelper';
import SocketManager from '../../../NetworkingAndChat/SocketManager';
import NonContainerUIActionButton from '../../ActionButtons/NonContainerUIActionButton';
import ResizableFrame from '../../UtilityComponents/ResizableFrame';
import {GameConflictActionMenu} from './GameConflictActionMenu';

export class ConflictInventoryMenu extends GameConflictActionMenu{
    public readonly menuTag: ConflictMenuType = ConflictMenuType.InventoryMenu;
    public shown: boolean = false;
    public inventoryItemIsArmed: boolean = false;
    public relatedMenusToDismiss: Set<ConflictMenuType> = new Set([
        ConflictMenuType.AbilityMenu,
        ConflictMenuType.AttackMenu,
        ConflictMenuType.HotkeyMenu,
        ConflictMenuType.ConflictMessageElement,
        ConflictMenuType.ConflictCommandMenu,

    ]);
    private socket: Socket;
    private inventoryItemMainListFrame: ResizableFrame;
    private inventoryItemCategoryFrame: ResizableFrame;
    private inventoryItemDetailFrame: ResizableFrame;
    private inventoryItemMainListCancelFrame: ResizableFrame;
    private inventoryItemArmedCancelFrame: ResizableFrame;
    private inventoryItemArmedDetailFrame: ResizableFrame;
    private inventoryItemArmedCommandFrame: ResizableFrame;
    private inventoryItemArmedCancelButton: NonContainerUIActionButton;
    public inventoryItemMainCategoryButton: NonContainerUIActionButton;
    public inventoryItemSecondaryCategoryButton: NonContainerUIActionButton;
    private inventoryItemMainListCancelButton: NonContainerUIActionButton;

    // Lists
    public inventoryItemMainListButtonInfos: {
        button: NonContainerUIActionButton;
        exists: boolean;
        selected: boolean;
    }[] = [];

    public equipmentButtonInfos: Map<ItemType.Helmet | ItemType.BodyArmor | ItemType.Weapon | ItemType.OffHand, {
        button: NonContainerUIActionButton;
        exists: boolean;
        selected: boolean;
    }> = new Map();

    private inventoryItemDetailConfirmButton: NonContainerUIActionButton;
    public inventoryItemDetailCancelButton: NonContainerUIActionButton;
    private inventoryItemArmedDetailIcon: NonContainerUIActionButton;
    // private inventoryItemDetailText: Phaser.GameObjects.Text;
    private inventoryItemArmedCommandText: Phaser.GameObjects.Text;
    private selectedItemCategory: ItemCategory = 'Bag';
    private armedInventoryItemIndex: number;
    private armedEquipmentItemType: Exclude<ItemType, ItemType.Consumable>;
    private armedAbilityCategory: ItemCategory;
    public headText: Phaser.GameObjects.Text;
    public bodyText: Phaser.GameObjects.Text;
    public mainHandText: Phaser.GameObjects.Text;
    public offHandText: Phaser.GameObjects.Text;
    public mode: 'inventory' | 'equipment' = 'inventory';
    private selectedEquipmentType: Exclude<ItemType, ItemType.Consumable> | undefined;
    private equippedItemSelected: boolean = false;
    private inventoryVerticalDivider: Phaser.GameObjects.Graphics;
    private inventoryDetailNameText: Phaser.GameObjects.Text;
    private inventoryDetailTypeText: Phaser.GameObjects.Text;
    private inventoryDetailDescriptionText: Phaser.GameObjects.Text;
    private inventoryDetailMinimumLevelText: Phaser.GameObjects.Text;
    private bindingStatusText: Phaser.GameObjects.Text;
    private classesText: Phaser.GameObjects.Text;
    private stat1Label: Phaser.GameObjects.Text;
    private stat1Value: Phaser.GameObjects.Text;
    private stat2Label: Phaser.GameObjects.Text;
    private stat2Value: Phaser.GameObjects.Text;
    private stat3Label: Phaser.GameObjects.Text;
    private stat3Value: Phaser.GameObjects.Text;
    private stat4Label: Phaser.GameObjects.Text;
    private stat4Value: Phaser.GameObjects.Text;
    private stat5Label: Phaser.GameObjects.Text;
    private stat5Value: Phaser.GameObjects.Text;
    private stat6Label: Phaser.GameObjects.Text;
    private stat6Value: Phaser.GameObjects.Text;
    private statLabels: Phaser.GameObjects.Text[];
    private statValues: Phaser.GameObjects.Text[];
    private statsMap: Map<string, number> = new Map();

    public constructor(scene: ServerControlledConflictUIScene) {
        super();
        this.scene = scene;
        this.socket = SocketManager.getInstance().socket;
        this.setupFrames();
        this.setupButtons();
        this.setupTexts();
        this.setupGraphics();
    }

    public activate(inventoryData: {itemArray: {name: string; key: UIImageKey; activeKey: UIImageKey}[]}): void {
        this.shown = true;
        console.log(`[ConflictInventoryMenu.activate] Activating Inventory Menu with inventoryData: ${inventoryData}`);
        this.dismissRelatedMenus();

        // Hide all enemy badges and disable targeting mode
        this.scene.enemyManager.hideAllEnemyBadges();
        this.scene.enemyManager.isTargetingModeActive = false;

        // Hide all ally badges and disable targeting mode
        this.scene.allyManager.hideAllAllyBadges();
        this.scene.allyManager.isTargetingModeActive = false;

        this.populateInitialView(inventoryData.itemArray); // Set up the buttons based on abilities
        this.showInitialView(); // Then show the initial view components

        // Activate the game action bar logic
        console.log('[AbilityMenu] Exiting activate method.');
    }

    public dismiss(): void {
        console.log('[AbilityMenu.dismiss] Dismissing Ability Menu.');

        // Section: Hide Frames
        this.inventoryItemMainListFrame.hideFrame();
        this.inventoryItemMainListCancelFrame.hideFrame();
        this.inventoryItemCategoryFrame.hideFrame();
        this.inventoryItemDetailFrame.hideFrame();
        this.inventoryItemArmedCancelFrame.hideFrame();
        this.inventoryItemArmedDetailFrame.hideFrame();
        this.inventoryItemArmedCommandFrame.hideFrame();

        // Section: Process and Hide Buttons
        for (let index = 0; index < this.inventoryItemMainListButtonInfos.length; index++) {
            const inventoryItemButtonInfo = this.inventoryItemMainListButtonInfos[index];
            inventoryItemButtonInfo.button.hideActionButton();
            inventoryItemButtonInfo.selected = false;
        }

        this.equipmentButtonInfos.forEach((buttonInfo) => {
            buttonInfo.button.hideActionButton();
            buttonInfo.exists = false;
            buttonInfo.selected = false;
        });
        this.headText.setVisible(false);
        this.bodyText.setVisible(false);
        this.mainHandText.setVisible(false);
        this.offHandText.setVisible(false);

        this.inventoryItemMainListCancelButton.hideActionButton();
        this.inventoryItemMainCategoryButton.hideActionButton();
        this.inventoryItemSecondaryCategoryButton.hideActionButton();
        this.inventoryItemDetailCancelButton.hideActionButton();
        this.inventoryItemDetailConfirmButton.hideActionButton();
        this.inventoryItemArmedCancelButton.hideActionButton();
        this.inventoryItemArmedDetailIcon.hideActionButton();

        // Section: Hide Texts
        // this.inventoryItemDetailText.setVisible(false);
        this.inventoryDetailNameText.setVisible(false);
        this.inventoryDetailTypeText.setVisible(false);
        this.inventoryDetailDescriptionText.setVisible(false);
        this.inventoryDetailMinimumLevelText.setVisible(false);
        this.bindingStatusText.setVisible(false);
        this.classesText.setVisible(false);
        this.hideStats();
        this.inventoryVerticalDivider.setVisible(false);

        this.inventoryItemArmedCommandText.setVisible(false);

        this.inventoryItemIsArmed = false;

        // Additional Actions
        this.shown = false;
        console.log('[AbilityMenu.dismiss] Exiting method.');
    }

    public emitMenuActivationRequest(): void {
        // Emit the 'attemptConflictInventoryMainSelect' event to the server
        if (!this.scene.canProceedWithMenuAction()) {
            console.log('Cannot proceed with menu action. Exiting early.');
            return;
        }
        this.scene.waitingForServerResponse = true;
        this.socket.emit(ServerSocketEvents.AttemptConflictInventoryItemMainSelect);
    }

    public emitMenuDismissalRequest(): void {
    }

    private setupFrames(): void {
        console.log('Abilitymenu constructor called');
        this.inventoryItemMainListFrame = new ResizableFrame(this.scene, 720, 392, 371, 409);
        this.inventoryItemMainListFrame.hideFrame();

        this.inventoryItemCategoryFrame = new ResizableFrame(this.scene, 381, 516, 276, 160);
        this.inventoryItemCategoryFrame.hideFrame();

        this.inventoryItemDetailFrame = new ResizableFrame(this.scene, 263, 266, 512, 308, 2);
        this.inventoryItemDetailFrame.hideFrame();

        this.inventoryItemMainListCancelFrame = new ResizableFrame(this.scene, 419, 370, 200, 100);
        this.inventoryItemMainListCancelFrame.hideFrame();

        this.inventoryItemArmedCancelFrame = new ResizableFrame(this.scene, 805, 486, 200, 100);
        this.inventoryItemArmedCancelFrame.hideFrame();

        this.inventoryItemArmedDetailFrame = new ResizableFrame(this.scene, 465, 466, 445, 60);
        this.inventoryItemArmedDetailFrame.hideFrame();

        this.inventoryItemArmedCommandFrame = new ResizableFrame(this.scene, 465, 554, 445, 84);
        this.inventoryItemArmedCommandFrame.hideFrame();

    }

    private setupButtons(): void {
        this.inventoryItemArmedCancelButton = new NonContainerUIActionButton(
            this.scene,
            730,
            465,
            UIImageKey.CrossButton,
            UIImageKey.CrossButton,
            'Cancel',
            this.handleCancelButton.bind(this) // Replace with the actual armed cancel function
        );
        this.inventoryItemArmedCancelButton.setDepth(DepthLevel.UI_PRIMARY_GRAPHICS_SUB);
        this.inventoryItemArmedCancelButton.hideActionButton();

        this.inventoryItemMainCategoryButton = new NonContainerUIActionButton(
            this.scene,
            266,
            465,
            UIImageKey.BagButton,
            UIImageKey.BagButtonActive,
            'Inventory',
            this.attemptToSwitchToInventoryMode.bind(this) // Replace with the actual page one function
        );
        this.inventoryItemMainCategoryButton.hideActionButton();

        this.inventoryItemSecondaryCategoryButton = new NonContainerUIActionButton(
            this.scene,
            266,
            515,
            UIImageKey.ArmorButton,
            UIImageKey.ArmorButtonActive,
            'Equipment',
            this.attemptSwitchToEquipmentMode.bind(this) // Replace with the actual page two function
        );
        this.inventoryItemSecondaryCategoryButton.hideActionButton();

        this.inventoryItemMainListCancelButton = new NonContainerUIActionButton(
            this.scene,
            344,
            349,
            UIImageKey.CrossButton,
            UIImageKey.CrossButton,
            'Cancel',
            this.handleCancelButton.bind(this) // Replace with the actual main list cancel function

        );
        this.inventoryItemMainListCancelButton.hideActionButton();

        this.initializeInventoryItemButtonInfos();

        this.inventoryItemDetailConfirmButton = new NonContainerUIActionButton(
            this.scene,
            35,
            392,
            UIImageKey.CheckButton,
            UIImageKey.CheckButton,
            'Use',
            this.onDetailConfirm.bind(this) // Replace with the actual detail confirm function

        );
        this.inventoryItemDetailConfirmButton.setDepth(DepthLevel.UI_SECONDARY_GRAPHICS_SUB);
        this.inventoryItemDetailConfirmButton.hideActionButton();

        this.inventoryItemDetailCancelButton = new NonContainerUIActionButton(
            this.scene,
            185,
            392,
            UIImageKey.CrossButton,
            UIImageKey.CrossButton,
            'Cancel',
            this.handleCancelButton.bind(this) // Replace with the actual detail cancel function

        );
        this.inventoryItemDetailCancelButton.setDepth(DepthLevel.UI_SECONDARY_GRAPHICS_SUB);
        this.inventoryItemDetailCancelButton.hideActionButton();

        this.inventoryItemArmedDetailIcon = new NonContainerUIActionButton(
            this.scene,
            266,
            465,
            UIImageKey.CheckButton,
            UIImageKey.CheckButton,
            'Nothing',
            () => {
            }
        );
        this.inventoryItemArmedDetailIcon.setDepth(DepthLevel.UI_PRIMARY_GRAPHICS_SUB);
        this.inventoryItemArmedDetailIcon.hideActionButton();

        this.initializeEquipmentButtonInfos();
    }

    private setupGraphics(): void {
        // Create a new Graphics object and add it to the scene
        this.inventoryVerticalDivider = this.scene.add.graphics();

        // Set the style properties for the line
        this.inventoryVerticalDivider.lineStyle(2, 0xffffff, 1); // Width, color, and alpha

        // Draw the line
        this.inventoryVerticalDivider.lineBetween(321, 119, 321, 349); // startX, startY, endX, endY

        // Set the depth of the line
        this.inventoryVerticalDivider.setDepth(DepthLevel.UI_SECONDARY_OVERLAY);

        // Initially hide the line
        this.inventoryVerticalDivider.setVisible(false);
    }

    private setupTexts(): void {
        // this.inventoryItemDetailText = this.scene.add.text(
        //     15,
        //     110,
        //     '',
        //     {
        //         fontSize: '45px',
        //         color: '#fff',
        //         fontFamily: mainGameFont,
        //         wordWrap: {
        //             width: 500,
        //             useAdvancedWrap: true
        //         },
        //         metrics: {
        //             ascent: 38,
        //             descent: 10,
        //             fontSize: 48
        //         }
        //     }
        // );
        // this.inventoryItemDetailText.setLineSpacing(-16);
        // this.inventoryItemDetailText.setResolution(3);
        // this.inventoryItemDetailText.setDepth(DepthLevel.UI_SECONDARY_TEXT);
        // this.inventoryItemDetailText.setVisible(false);

        this.inventoryDetailNameText = this.scene.add.text(
            15,
            110, // Positioned above the detail text
            'Item Name', // Placeholder for the item name
            {
                fontSize: '36px', // Slightly smaller font size
                color: '#fff',
                fontFamily: mainGameFont,
            }
        )
            .setResolution(3)
            .setDepth(DepthLevel.UI_SECONDARY_TEXT)
            .setVisible(false);

        this.inventoryDetailTypeText = this.scene.add.text(
            15,
            137, // Increased y-coordinate to move the text down
            'Weapon', // Initial text content
            {
                fontSize: '36px', // Reduced font size
                color: '#fff',
                fontFamily: mainGameFont,
            }
        )
            .setResolution(3)
            .setDepth(DepthLevel.UI_SECONDARY_TEXT)
            .setVisible(false);

        this.inventoryDetailDescriptionText = this.scene.add.text(
            15,
            170, // Increased y-coordinate to move the text down
            '',
            {
                fontSize: '36px', // Reduced font size
                color: '#fff',
                fontFamily: mainGameFont,
                wordWrap: {
                    width: 310,
                    useAdvancedWrap: true
                },
                metrics: {
                    ascent: 25, // Adjusted to match the new font size
                    descent: 7, // Adjusted to match the new font size
                    fontSize: 32 // Adjusted to match the new font size
                }
            }
        )
            .setLineSpacing(-10) // Adjust line spacing as needed
            .setResolution(3)
            .setDepth(DepthLevel.UI_SECONDARY_TEXT)
            .setVisible(false);

        const rightColumnTextTopLeftCornerX = 329; // X coordinate for the right column text
        const rightColumnTextTopLeftCornerY = 110; // Y coordinate for the right column text

        this.inventoryDetailMinimumLevelText = this.scene.add.text(
            rightColumnTextTopLeftCornerX, // X coordinate (adjust as needed)
            rightColumnTextTopLeftCornerY, // Y coordinate (adjust as needed)
            'Level 1', // Initial text content
            {
                fontSize: '36px',
                color: '#fff',
                fontFamily: mainGameFont,
                align: 'left', // Aligns text to the right
            }
        )
            .setOrigin(0, 0) // Aligns text to the top-right
            .setResolution(3)
            .setDepth(DepthLevel.UI_SECONDARY_TEXT)
            .setVisible(false);

        const bindingStatusTextX = rightColumnTextTopLeftCornerX;
        const bindingStatusTextY = rightColumnTextTopLeftCornerY + 26;

        // Create the top line text
        this.bindingStatusText = this.scene.add.text(
            bindingStatusTextX,
            bindingStatusTextY,
            // 'Soulbound',
            'Bind on pickup',
            {
                fontSize: '36px', // Half the size
                color: '#fff',
                fontFamily: mainGameFont,
                align: 'left'
            }
        )
            .setOrigin(0, 0) // Aligns text to the top-right
            .setResolution(3)
            .setDepth(DepthLevel.UI_SECONDARY_TEXT)
            .setVisible(false);

        this.classesText = this.scene.add.text(
            rightColumnTextTopLeftCornerX,
            rightColumnTextTopLeftCornerY + 54,
            'Runeblade\nAethermancer\nLifeweaver',
            {
                fontSize: '36px',
                color: '#fff',
                fontFamily: mainGameFont,
                align: 'left'
            }
        )
            .setOrigin(0, 0)
            .setResolution(3)
            .setDepth(DepthLevel.UI_SECONDARY_TEXT)
            .setLineSpacing(-10)
            .setVisible(false);

        // First row
        let position = this.calculateStatPositions(0);
        this.stat1Label = this.scene.add.text(
            position.x, position.y,
            'STR:', // Strength
            {color: '#fff', fontFamily: mainGameFont, fontSize: '35px'}
        ).setResolution(3).setDepth(DepthLevel.UI_SECONDARY_TEXT).setVisible(false);

        this.stat1Value = this.scene.add.text(
            position.x, position.y + 20,
            '0', // Value for Strength
            {color: '#fff', fontFamily: mainGameFont, fontSize: '35px'}
        ).setResolution(3).setDepth(DepthLevel.UI_SECONDARY_TEXT).setVisible(false);

        position = this.calculateStatPositions(1);
        this.stat2Label = this.scene.add.text(
            position.x, position.y,
            'AGI:', // Agility
            {color: '#fff', fontFamily: mainGameFont, fontSize: '35px'}
        ).setResolution(3).setDepth(DepthLevel.UI_SECONDARY_TEXT).setVisible(false);

        this.stat2Value = this.scene.add.text(
            position.x, position.y + 20,
            '0', // Value for Agility
            {color: '#fff', fontFamily: mainGameFont, fontSize: '35px'}
        ).setResolution(3).setDepth(DepthLevel.UI_SECONDARY_TEXT).setVisible(false);

        position = this.calculateStatPositions(2);
        this.stat3Label = this.scene.add.text(
            position.x, position.y,
            'VIT:', // Vitality
            {color: '#fff', fontFamily: mainGameFont, fontSize: '35px'}
        ).setResolution(3).setDepth(DepthLevel.UI_SECONDARY_TEXT).setVisible(false);

        this.stat3Value = this.scene.add.text(
            position.x, position.y + 20,
            '0', // Value for Vitality
            {color: '#fff', fontFamily: mainGameFont, fontSize: '35px'}
        ).setResolution(3).setDepth(DepthLevel.UI_SECONDARY_TEXT).setVisible(false);

        // Second row
        position = this.calculateStatPositions(3);
        this.stat4Label = this.scene.add.text(
            position.x, position.y,
            'INT:', // Intellect
            {color: '#fff', fontFamily: mainGameFont, fontSize: '35px'}
        ).setResolution(3).setDepth(DepthLevel.UI_SECONDARY_TEXT).setVisible(false);

        this.stat4Value = this.scene.add.text(
            position.x, position.y + 20,
            '0', // Value for Intellect
            {color: '#fff', fontFamily: mainGameFont, fontSize: '35px'}
        ).setResolution(3).setDepth(DepthLevel.UI_SECONDARY_TEXT).setVisible(false);

        position = this.calculateStatPositions(4);
        this.stat5Label = this.scene.add.text(
            position.x, position.y,
            'LUK:', // Luck
            {color: '#fff', fontFamily: mainGameFont, fontSize: '35px'}
        ).setResolution(3).setDepth(DepthLevel.UI_SECONDARY_TEXT).setVisible(false);

        this.stat5Value = this.scene.add.text(
            position.x, position.y + 20,
            '0', // Value for Luck
            {color: '#fff', fontFamily: mainGameFont, fontSize: '35px'}
        ).setResolution(3).setDepth(DepthLevel.UI_SECONDARY_TEXT).setVisible(false);

        position = this.calculateStatPositions(5);
        this.stat6Label = this.scene.add.text(
            position.x, position.y,
            'DEF:', // Defense
            {color: '#fff', fontFamily: mainGameFont, fontSize: '35px'}
        ).setResolution(3).setDepth(DepthLevel.UI_SECONDARY_TEXT).setVisible(false);

        this.stat6Value = this.scene.add.text(
            position.x, position.y + 20,
            '0', // Value for Defense
            {color: '#fff', fontFamily: mainGameFont, fontSize: '35px'}
        ).setResolution(3).setDepth(DepthLevel.UI_SECONDARY_TEXT).setVisible(false);

        this.statLabels = [
            this.stat1Label,
            this.stat2Label,
            this.stat3Label,
            this.stat4Label,
            this.stat5Label,
            this.stat6Label
        ];
        this.statValues = [
            this.stat1Value,
            this.stat2Value,
            this.stat3Value,
            this.stat4Value,
            this.stat5Value,
            this.stat6Value
        ];

        this.inventoryItemArmedCommandText = this.scene.add.text(
            244,
            510,
            'Choose A Target',
            {
                fontSize: '50px',
                color: '#fff',
                fontFamily: mainGameFont,
            }
        );
        this.inventoryItemArmedCommandText.setDepth(DepthLevel.UI_PRIMARY_TEXT);
        this.inventoryItemArmedCommandText.setResolution(3);
        this.inventoryItemArmedCommandText.setVisible(false);

        this.headText = this.scene.add.text(540, 180, 'Head:', {
            fontSize: '50px',
            color: '#fff',
            fontFamily: mainGameFont
        })
            .setResolution(3)
            .setDepth(DepthLevel.UI_PRIMARY_TEXT)
            .setVisible(false);

        this.bodyText = this.scene.add.text(540, 275, 'Body:', {
            fontSize: '50px',
            color: '#fff',
            fontFamily: mainGameFont
        })
            .setResolution(3)
            .setDepth(DepthLevel.UI_PRIMARY_TEXT)
            .setVisible(false);

        this.mainHandText = this.scene.add.text(540, 370, 'Main Hand:', {
            fontSize: '50px',
            color: '#fff',
            fontFamily: mainGameFont
        })
            .setResolution(3)
            .setDepth(DepthLevel.UI_PRIMARY_TEXT)
            .setVisible(false);

        this.offHandText = this.scene.add.text(540, 465, 'Off Hand:', {
            fontSize: '50px',
            color: '#fff',
            fontFamily: mainGameFont
        })
            .setResolution(3)
            .setDepth(DepthLevel.UI_PRIMARY_TEXT)
            .setVisible(false);

    }

    private populateStats(stats: {[key: string]: number}): void {
        console.log('[populateStats] Starting population of stats:', JSON.stringify(stats, null, 2));
        this.statsMap = new Map(Object.entries(stats));

        for (let i = 0; i < this.statLabels.length; i++) {
            const statLabel = this.statLabels[i];
            const statValue = this.statValues[i];
            if (i < Object.keys(stats).length) {
                const statName = Object.keys(stats)[i];
                const statAbbreviation = statAbbreviations.get(statName) || statName;
                const statValueString = stats[statName].toString();

                // Use the new method to calculate position
                const {x, y} = this.calculateStatPositions(i);

                // Set positions and text for labels and values
                statLabel.setX(x).setY(y).setText(`${statAbbreviation}:`);
                statValue.setX(x).setY(y + 20).setText(statValueString); // Position value right below the label

                console.log(`[populateStats] Set ${statAbbreviation} to ${statValueString}`, JSON.stringify({statName, statValue: stats[statName]}, null, 2));
            } else {
                // Clear text and reposition off-screen or make invisible if not needed
                statLabel.setText('').setVisible(false);
                statValue.setText('').setVisible(false);
                console.log(`[populateStats] Cleared label and value at index ${i}`);
            }
        }
    }

    private depopulateStats(): void {
        console.log('[depopulateStats] Clearing all stat texts.');
        // Iterate through all stat labels and values to clear them
        this.statLabels.forEach((label) => {
            label.setText(''); // Clear the text and make the label invisible
        });
        this.statValues.forEach((value) => {
            value.setText(''); // Clear the text and make the value invisible
        });
    }

    private calculateStatPositions(index: number): { x: number, y: number } {
        // Constants for layout configuration
        const columnWidth = 100; // Width of each stat column
        const rowHeight = 45; // Height between rows of stats

        // Calculate the starting Y position based on the description text's bottom
        const startY = this.inventoryDetailDescriptionText.y + this.inventoryDetailDescriptionText.height - 10; // Additional 10 pixels for spacing

        // Calculate positions based on column and row determined by index
        const x = 15 + (index % 3) * columnWidth; // Calculate X position within the row
        const y = startY + Math.floor(index / 3) * rowHeight; // Calculate Y position for each row

        return {x, y};
    }

    private showStats(): void {
        console.log('[showStats] Updating visibility of stat labels and values', JSON.stringify(Array.from(this.statsMap.entries()), null, 2));
        const statCount = this.statsMap.size; // Determine the number of stats provided from the Map
        for (let i = 0; i < this.statLabels.length; i++) {
            if (i < statCount) {
                this.statLabels[i].setVisible(true); // Only make visible up to the number of provided stats
                this.statValues[i].setVisible(true);
                console.log(`[showStats] Made visible label and value at index ${i}`);
            } else {
                this.statLabels[i].setVisible(false); // Hide all other labels and values
                this.statValues[i].setVisible(false);
                console.log(`[showStats] Hid label and value at index ${i}`);
            }
        }
    }

    private hideStats(): void {
        for (let i = 0; i < this.statLabels.length; i++) {
            this.statLabels[i].setVisible(false);
            this.statValues[i].setVisible(false);
        }
    }

    public handleCancelButton(): void {
        if (this.scene.conflictScene.interactionState === BaseInteractionState.MainSelect) {
            return;
        }
        if (!this.scene.canProceedWithMenuAction()) {
            console.log('Cannot proceed with menu action. Exiting early.');
            return;
        }
        this.scene.waitingForServerResponse = true;
        console.log('Cancel abiliby button pressed');
        this.socket.emit(ServerSocketEvents.AttemptConflictCancel);
    }

    private attemptToSwitchToInventoryMode(): void {
        console.log('Main category button pressed');
        if (this.mode !== 'inventory') {
            if (!this.scene.canProceedWithMenuAction()) {
                console.log('Cannot proceed with menu action. Exiting early.');
                return;
            }
            this.scene.waitingForServerResponse = true;
            console.log('Switching to inventory mode');
            this.socket.emit(ServerSocketEvents.AttemptSwitchConflictInventoryMode, 'inventory');
        } else {
            console.log('Already in inventory mode');
        }
    }

    private attemptSwitchToEquipmentMode(): void {
        console.log('Secondary category button pressed');
        if (this.mode !== 'equipment') {
            if (!this.scene.canProceedWithMenuAction()) {
                console.log('Cannot proceed with menu action. Exiting early.');
                return;
            }
            this.scene.waitingForServerResponse = true;
            console.log('Switching to equipment mode');
            this.socket.emit(ServerSocketEvents.AttemptSwitchConflictInventoryMode, 'equipment');
        } else {
            console.log('Already in equipment mode');
        }
    }

    private initializeInventoryItemButtonInfos(): void {
        // Check if the initialization has already been done to prevent duplication
        if (this.inventoryItemMainListButtonInfos.length === 0) {
            for (let i = 0; i < 8; i++) {
                const inventoryItemButtonInfo = {
                    button: this.createInventoryItemButton(i),
                    exists: false, // Initialize as false; will be updated based on actual abilities
                    selected: false // Initialize as false; will be updated based on actual abilities
                };
                this.inventoryItemMainListButtonInfos.push(inventoryItemButtonInfo);
            }
        } else {
            // Optionally, log a message or handle cases where reinitialization might be intended but needs to be controlled
            console.log('Ability buttons have already been initialized.');
        }
    }

    private onDetailConfirm(): void {
        // Logic when detail confirm is triggered
    }

    private createInventoryItemButton(index: number): NonContainerUIActionButton {
        const numberWord = numberToWords.toWords(index + 1);
        const capitalizedWord = numberWord.charAt(0).toUpperCase() + numberWord.slice(1);
        const label = `Ability ${capitalizedWord}`;

        const inventoryItemButton = new NonContainerUIActionButton(
            this.scene,
            564, // X position
            216 + (index * 50), // Y position, incrementing for each button
            UIImageKey.BagButton, // The image key for the button
            UIImageKey.BagButtonActive, // The image key when the button is active or selected
            label,
            this.onInventoryItemButtonClicked.bind(this, index) // Binding the click event to a handler
        );

        inventoryItemButton.hideActionButton(); // Initially hiding the button
        inventoryItemButton.setDepth(DepthLevel.UI_PRIMARY_GRAPHICS_SUB); // Setting the visual depth level

        return inventoryItemButton;
    }

    // Placeholder function for the button click event handler
    private onInventoryItemButtonClicked(index: number): void {
        // Logic to handle inventoryItem button click
        console.log(`Inventory button ${index + 1} clicked`);
        // Update the inventoryItemMainListButtonInfos state as needed
    }

    private showInitialView(): void {
        // Show the main list frame, category frame, page one button, etc.
        this.inventoryItemMainListFrame.showFrame();
        this.inventoryItemCategoryFrame.showFrame();
        this.inventoryItemMainListCancelFrame.showFrame();
        this.inventoryItemMainCategoryButton.showActionButton();
        this.inventoryItemSecondaryCategoryButton.showActionButton();
        this.inventoryItemMainListCancelButton.showActionButton();

        this.inventoryItemMainCategoryButton.select();

        // Loop through and show only the existing inventory buttons
        this.inventoryItemMainListButtonInfos.forEach(buttonInfo => {
            if (buttonInfo.exists) {
                buttonInfo.button.showActionButton();
            } else {
                buttonInfo.button.hideActionButton();
            }
        });
    }
    private populateInitialView(items: {name: string, key: UIImageKey, activeKey: UIImageKey}[]): void {
        console.log(`[InventoryMenu.populateInitialView] Populating initial view with items: ${JSON.stringify(items, null, 2)}`);
        // Ensure all buttons are reset to not exist before updating based on new abilities
        this.inventoryItemMainListButtonInfos.forEach(info => {
            info.exists = false;
            info.button.hideActionButton();
        });

        // Update buttons based on the abilities provided
        items.forEach((inventoryItem, index) => {
            if (index < this.inventoryItemMainListButtonInfos.length) {
                const buttonInfo = this.inventoryItemMainListButtonInfos[index];
                buttonInfo.exists = true;
                buttonInfo.button.changeButtonImage(inventoryItem.key, inventoryItem.activeKey);
                buttonInfo.button.changeButtonText(inventoryItem.name);
                buttonInfo.button.deselect();
                buttonInfo.button.showActionButton();

                // Update button target callback to emit event when inventory item is selected
                buttonInfo.button.targetCallback = (): void => {
                    console.log(`[inventory item callback] Item ${index} clicked.`);
                    if (!this.scene.canProceedWithMenuAction()) {
                        console.log('Cannot proceed with menu action. Exiting early.');
                        return;
                    }
                    this.scene.waitingForServerResponse = true;
                    const eventData = {inventoryItemIndex: index, inventoryCategory: 'Bag'}; // Assuming pageIndex is always 0 for this setup
                    this.socket.emit(ServerSocketEvents.AttemptConflictInventoryItemSelection, eventData);
                };
            }
        });
    }

    public populateDetailView(param: {
        inventoryItemCategory: ItemCategory;
        inventoryItemDescription: string;
        inventoryItemIndex: number;
        inventoryItemName: string;
        inventoryItemType: ItemType;
        inventoryItemClasses?: CharacterProfession[];
        inventoryItemMinimumLevel?: number;
        inventoryItemStats?: {
            strength?: number;
            agility?: number;
            vitality?: number;
            intellect?: number;
            luck?: number;
            defense?: number;
        };
        inventoryItemBindingType: ItemBindingType;
    }): void {
        this.inventoryItemMainListButtonInfos.forEach((buttonInfo, index) => {
            if (index === param.inventoryItemIndex) {
                buttonInfo.button.select();
                buttonInfo.selected = true;
            } else {
                buttonInfo.button.deselect();
                buttonInfo.selected = false;
            }

        });
        // this.inventoryItemDetailText.setText(param.inventoryItemDescription);
        this.inventoryDetailNameText.setText(param.inventoryItemName);
        this.inventoryDetailDescriptionText.setText(param.inventoryItemDescription);
        this.bindingStatusText.setText(param.inventoryItemBindingType.charAt(0).toUpperCase() + param.inventoryItemBindingType.slice(1));
        this.inventoryDetailMinimumLevelText.setText(`Level ${param.inventoryItemMinimumLevel ?? 1}`);
        if (param.inventoryItemClasses && param.inventoryItemClasses.length > 0) {
            const classesTextContent = param.inventoryItemClasses.join('\n');
            this.classesText.setText(classesTextContent);
        } else {
            this.classesText.setText('');
        }

        this.inventoryDetailTypeText.setText(param.inventoryItemType.charAt(0).toUpperCase() + param.inventoryItemType.slice(1));

        if (param.inventoryItemStats) {
            this.populateStats(param.inventoryItemStats);
        } else {
            this.depopulateStats();
        }

        this.selectedItemCategory = param.inventoryItemCategory;

        this.inventoryItemDetailConfirmButton.targetCallback = (): void => {
            console.log(`[InventoryMenu.populateDetailView] Starting process to confirm inventory item detail for item index: ${param.inventoryItemIndex}`);

            if (!this.scene.canProceedWithMenuAction()) {
                console.log('[InventoryMenu.populateDetailView] Check if can proceed with menu action: false');
                console.log('Cannot proceed with menu action. Exiting early.');
                return;
            } else {
                console.log('[InventoryMenu.populateDetailView] Check if can proceed with menu action: true');
            }

            console.log('[InventoryMenu.populateDetailView] Setting waitingForServerResponse to true');
            this.scene.waitingForServerResponse = true;
            const attemptConflictInventoryItemSelectionConfirmEvent = {
                inventoryItemIndex: param.inventoryItemIndex,
                inventoryItemCategory: param.inventoryItemCategory
            };
            console.log(`[InventoryMenu.populateDetailView] Prepared event data: ${JSON.stringify(attemptConflictInventoryItemSelectionConfirmEvent, null, 2)}`);

            console.log(`[InventoryMenu.populateDetailView] Emitting event: AttemptConflictInventoryItemSelectionConfirm with data: ${JSON.stringify(attemptConflictInventoryItemSelectionConfirmEvent, null, 2)}`);
            this.socket.emit(ServerSocketEvents.AttemptConflictInventoryItemSelectionConfirm, attemptConflictInventoryItemSelectionConfirmEvent);

            console.log('[InventoryMenu.populateDetailView] Process complete.');
        };
    }

    public populateArmedView(data: AttemptConflictInventoryItemSelectionConfirmResponseEvent): void {
        if (data.success) {
            // Common setup actions for all items
            this.inventoryItemArmedCommandText.setText('Choose A Target');
            this.inventoryItemArmedDetailIcon.changeButtonImage(data.inventoryItemKey, data.inventoryItemActiveKey);
            this.inventoryItemArmedDetailIcon.select();
            this.inventoryItemArmedDetailIcon.changeButtonText(data.inventoryItemName);

            // Set the cancel button callback
            this.inventoryItemArmedCancelButton.targetCallback = this.handleCancelButton.bind(this);

            // Mark the item as armed
            this.inventoryItemIsArmed = true;
            this.armedAbilityCategory = data.inventoryItemCategory;

            // Specific setup for indexed or typed inventory items
            if ('inventoryItemIndex' in data) {
                this.armedInventoryItemIndex = data.inventoryItemIndex;
            } else if ('inventoryItemType' in data) {
                this.armedEquipmentItemType = data.inventoryItemType;
            }
        }
    }

    public showArmedView(): void {
        this.hideInitialView();
        this.hideDetailView();

        this.inventoryItemArmedCancelFrame.showFrame();
        this.inventoryItemArmedCancelButton.showActionButton();
        this.inventoryItemArmedDetailFrame.showFrame();
        this.inventoryItemArmedDetailIcon.showActionButton();
        this.inventoryItemArmedCommandFrame.showFrame();
        this.inventoryItemArmedCommandText.setVisible(true);
    }

    public hideInitialView(): void {
        // Hide the components specific to the initial view
        this.inventoryItemMainListFrame.hideFrame();
        this.inventoryItemCategoryFrame.hideFrame();
        this.inventoryItemMainListCancelFrame.hideFrame();
        this.inventoryItemMainCategoryButton.hideActionButton();
        this.inventoryItemSecondaryCategoryButton.hideActionButton();
        this.inventoryItemMainListCancelButton.hideActionButton();
        // Hide all inventoryItem buttons, regardless of their existing state
        this.inventoryItemMainListButtonInfos.forEach(buttonInfo => buttonInfo.button.hideActionButton());
        this.equipmentButtonInfos.forEach(buttonInfo => buttonInfo.button.hideActionButton());
        this.headText.setVisible(false);
        this.bodyText.setVisible(false);
        this.mainHandText.setVisible(false);
        this.offHandText.setVisible(false);
    }

    public hideDetailView(): void {
        // Hide the components specific to the detail view
        this.inventoryItemDetailFrame.hideFrame();
        // this.inventoryItemDetailText.setVisible(false);
        this.inventoryDetailNameText.setVisible(false);
        this.inventoryDetailTypeText.setVisible(false);
        this.inventoryDetailDescriptionText.setVisible(false);
        this.inventoryDetailMinimumLevelText.setVisible(false);
        this.bindingStatusText.setVisible(false);
        this.classesText.setVisible(false);
        this.hideStats();
        this.inventoryVerticalDivider.setVisible(false);
        this.inventoryItemDetailConfirmButton.hideActionButton();
        this.inventoryItemDetailCancelButton.hideActionButton();
    }

    public showDetailView(): void {
        // Show the detail frame, inventoryItem text, resource cost text, and detail action buttons
        this.inventoryItemDetailFrame.showFrame();
        // this.inventoryItemDetailText.setVisible(true);
        this.inventoryDetailNameText.setVisible(true);
        this.inventoryDetailTypeText.setVisible(true);
        this.inventoryDetailDescriptionText.setVisible(true);
        this.inventoryDetailMinimumLevelText.setVisible(true);
        this.bindingStatusText.setVisible(true);
        this.classesText.setVisible(true);
        this.showStats();
        this.inventoryVerticalDivider.setVisible(true);
        this.inventoryItemDetailConfirmButton.showActionButton();
        this.inventoryItemDetailCancelButton.showActionButton();

        // Hide the components from the initial view as needed
        this.inventoryItemMainListCancelFrame.hideFrame();
        this.inventoryItemMainListCancelButton.hideActionButton();
    }

    public switchToMode(
        mode: 'equipment' | 'inventory',
        inventoryOrEquipment: {
            helmet?: EquipmentItemForDisplay;
            bodyArmor?: EquipmentItemForDisplay;
            offHand?: EquipmentItemForDisplay;
            weapon?: EquipmentItemForDisplay;
        } | InventoryItemForDisplay[]
    ): void {
        console.log(`[ConflictInventoryMenu.switchToMode] Switching to ${mode} mode with data: ${JSON.stringify(inventoryOrEquipment, null, 2)}`);

        if (mode === 'equipment') {
            this.mode = 'equipment';

            this.hideDetailView();

            // Hide inventory buttons and reset their states
            this.inventoryItemMainListButtonInfos.forEach(buttonInfo => {
                buttonInfo.button.hideActionButton();
                buttonInfo.exists = false;
                buttonInfo.selected = false;
            });
            this.inventoryItemMainCategoryButton.deselect();
            this.inventoryItemSecondaryCategoryButton.select();

            // Set visibility for the equipment texts (adjust these as per your actual UI component names)
            this.headText.setVisible(true);
            this.bodyText.setVisible(true);
            this.mainHandText.setVisible(true);
            this.offHandText.setVisible(true);

            // Assuming `inventoryOrEquipment` is cast to the correct type
            const equipment = inventoryOrEquipment as {
                helmet?: EquipmentItemForDisplay;
                bodyArmor?: EquipmentItemForDisplay;
                offHand?: EquipmentItemForDisplay;
                weapon?: EquipmentItemForDisplay;
            };

            // Set and show equipment buttons using the correct keys
            if (equipment.helmet) {
                const helmetInfo = this.equipmentButtonInfos.get(ItemType.Helmet);
                if (helmetInfo) {
                    this.updateButtonInfo(helmetInfo, equipment.helmet);
                }
            }
            if (equipment.bodyArmor) {
                const bodyArmorInfo = this.equipmentButtonInfos.get(ItemType.BodyArmor);
                if (bodyArmorInfo) {
                    this.updateButtonInfo(bodyArmorInfo, equipment.bodyArmor);
                }
            }
            if (equipment.offHand) {
                const offHandInfo = this.equipmentButtonInfos.get(ItemType.OffHand);
                if (offHandInfo) {
                    this.updateButtonInfo(offHandInfo, equipment.offHand);
                }
            }
            if (equipment.weapon) {
                const weaponInfo = this.equipmentButtonInfos.get(ItemType.Weapon);
                if (weaponInfo) {
                    this.updateButtonInfo(weaponInfo, equipment.weapon);
                }
            }
        } else if (mode === 'inventory') {
            this.mode = 'inventory';
            // Hide equipment buttons and reset their states
            this.hideDetailView();

            this.inventoryItemSecondaryCategoryButton.deselect();
            this.inventoryItemMainCategoryButton.select();
            //
            // // Hide equipment texts
            this.headText.setVisible(false);
            this.bodyText.setVisible(false);
            this.mainHandText.setVisible(false);
            this.offHandText.setVisible(false);

            this.equipmentButtonInfos.forEach((buttonInfo) => {
                buttonInfo.button.hideActionButton();
                buttonInfo.exists = false;
                buttonInfo.selected = false;
            });

            this.updateMainInventoryList({inventory: inventoryOrEquipment as InventoryItemForDisplay[]});

        }
        // Add logic for 'inventory' mode if needed
    }

    public updateButtonInfo(buttonInfo: { button: NonContainerUIActionButton; exists: boolean; selected: boolean }, item: EquipmentItemForDisplay): void {
        buttonInfo.button.changeButtonText(item.name); // Only display the item's name
        buttonInfo.button.changeButtonImage(item.key, item.activeKey);
        buttonInfo.button.showActionButton();
        buttonInfo.exists = true;
        buttonInfo.selected = false; // Reset or set based on your logic
    }

    private initializeEquipmentButtonInfos(): void {
        // Check if the initialization has already been done to prevent duplication
        if (this.equipmentButtonInfos.size === 0) {
            this.equipmentButtonInfos.set(ItemType.Helmet, {
                button: this.createEquipmentItemButton(ItemType.Helmet),
                exists: false,
                selected: false
            });
            this.equipmentButtonInfos.set(ItemType.BodyArmor, {
                button: this.createEquipmentItemButton(ItemType.BodyArmor),
                exists: false,
                selected: false
            });
            this.equipmentButtonInfos.set(ItemType.Weapon, {
                button: this.createEquipmentItemButton(ItemType.Weapon),
                exists: false,
                selected: false
            });
            this.equipmentButtonInfos.set(ItemType.OffHand, {
                button: this.createEquipmentItemButton(ItemType.OffHand),
                exists: false,
                selected: false
            });
        } else {
            console.log('Equipment buttons have already been initialized.');
        }
    }

    private createEquipmentItemButton(type: ItemType.Helmet | ItemType.BodyArmor | ItemType.Weapon | ItemType.OffHand): NonContainerUIActionButton {
        let yPos: number;

        switch (type) {
            case ItemType.Helmet:
                yPos = 250; // Y position for head equipment
                break;
            case ItemType.BodyArmor:
                yPos = 345; // Y position for body equipment
                break;
            case ItemType.Weapon:
                yPos = 440; // Y position for main hand equipment
                break;
            case ItemType.OffHand:
                yPos = 535; // Y position for offhand equipment
                break;
            default:
                yPos = 216; // Default position if none of the types match
        }

        const label = `Equipment ${type.charAt(0).toUpperCase() + type.slice(1)}`;
        const equipmentItemButton = new NonContainerUIActionButton(
            this.scene,
            564, // X position is the same for all
            yPos,
            UIImageKey.BagButton,
            UIImageKey.BagButtonActive,
            label,
            () => {
                console.log(`[Equipment item callback] ${type} equipment clicked.`);
                if (!this.scene.canProceedWithMenuAction()) {
                    console.log('Cannot proceed with menu action. Exiting early.');
                    return;
                }
                const acceptableInteractionStates = ClientInteractionStateRegexHelper.getDynamicStateRegex({
                    itemMainSelectEquipment: true,
                    equipmentItemDetail: true,
                });
                console.log(`[Equipment item callback] interaction state: ${this.scene.conflictScene.interactionState}`);
                if (!acceptableInteractionStates.test(this.scene.conflictScene.interactionState)) {
                    console.log('Interaction state does not match expected states. Exiting early.');
                    return;
                }
                this.scene.waitingForServerResponse = true;
                const eventData = {
                    equipmentType: type
                };
                this.socket.emit(ServerSocketEvents.AttemptSelectConflictEquipmentItem, eventData);
            }
        );

        equipmentItemButton.hideActionButton();
        equipmentItemButton.setDepth(DepthLevel.UI_PRIMARY_GRAPHICS_SUB);

        return equipmentItemButton;
    }

    public updateMainInventoryList(data: {
        inventory: {
            name: string;
            key: UIImageKey;
            activeKey: UIImageKey;
        }[];
    }): void {
        // Clear the existing buttons
        this.inventoryItemMainListButtonInfos.forEach(buttonInfo => {
            buttonInfo.button.hideActionButton();
            buttonInfo.exists = false;
        });

        // Repopulate the buttons with the new inventory data
        data.inventory.forEach((item, index) => {
            if (index < this.inventoryItemMainListButtonInfos.length) {
                const buttonInfo = this.inventoryItemMainListButtonInfos[index];
                buttonInfo.exists = true;
                buttonInfo.button.changeButtonImage(item.key, item.activeKey);
                buttonInfo.button.changeButtonText(item.name);
                buttonInfo.button.showActionButton();

                // Set the new callback for selecting the inventory item
                buttonInfo.button.targetCallback = (): void => {
                    console.log(`[inventory item callback] Item ${index} clicked.`);
                    if (!this.scene.canProceedWithMenuAction()) {
                        console.log('Cannot proceed with menu action. Exiting early.');
                        return;
                    }
                    this.scene.waitingForServerResponse = true;
                    const eventData = {
                        inventoryItemIndex: index,
                        inventoryCategory: 'Bag'
                    };
                    this.socket.emit(ServerSocketEvents.AttemptConflictInventoryItemSelection, eventData);
                };
            }
        });

        this.hideDetailView();
    }

    public populateDetailViewForEquippedItems(data: SuccessConflictEquipmentItemState): void {
        console.log(`[InventoryDetailElements.populateDetailViewForEquippedItems] Populating detail view with data: ${JSON.stringify(data, null, 2)}`);
        // Select the selected equipment button and deselect the others
        this.equipmentButtonInfos.forEach((buttonInfo, type) => {
            if (type === data.equipmentItemType) {
                buttonInfo.button.select();
                buttonInfo.selected = true;
            } else {
                buttonInfo.button.deselect();
                buttonInfo.selected = false;
            }
        });

        this.inventoryDetailNameText.setText(data.equipmentItemName);
        this.inventoryDetailDescriptionText.setText(data.equipmentItemDescription);
        this.inventoryDetailTypeText.setText(data.equipmentItemType.charAt(0).toUpperCase() + data.equipmentItemType.slice(1));
        this.inventoryDetailMinimumLevelText.setText(`Level ${data.equipmentItemMinimumLevel}`);
        this.bindingStatusText.setText(data.equipmentItemBindingType.charAt(0).toUpperCase() + data.equipmentItemBindingType.slice(1));
        if (data.equipmentItemClasses && data.equipmentItemClasses.length > 0) {
            const classesTextContent = data.equipmentItemClasses.join('\n');
            this.classesText.setText(classesTextContent);
        } else {
            this.classesText.setText('');
        }
        if (data.equipmentItemStats) {
            this.populateStats(data.equipmentItemStats);
        } else {
            this.depopulateStats();
        }

        this.selectedEquipmentType = data.equipmentItemType;

        this.equippedItemSelected = true;

        this.selectedItemCategory = 'Equipment';

        this.inventoryItemDetailConfirmButton.targetCallback = (): void => {
            console.log(`[InventoryMenu.populateDetailViewForEquippedItems] Starting process to confirm inventory item detail for item type: ${data.equipmentItemType}`);

            if (!this.scene.canProceedWithMenuAction()) {
                console.log('[InventoryMenu.populateDetailViewForEquippedItems] Check if can proceed with menu action: false');
                console.log('Cannot proceed with menu action. Exiting early.');
                return;
            } else {
                console.log('[InventoryMenu.populateDetailViewForEquippedItems] Check if can proceed with menu action: true');
            }

            console.log('[InventoryMenu.populateDetailViewForEquippedItems] Setting waitingForServerResponse to true');
            this.scene.waitingForServerResponse = true;
            const attemptConflictInventoryItemSelectionConfirmEvent = {
                inventoryItemType: data.equipmentItemType,
                inventoryItemCategory: 'Equipment'
            };
            console.log(`[InventoryMenu.populateDetailViewForEquippedItems] Prepared event data: ${JSON.stringify(attemptConflictInventoryItemSelectionConfirmEvent, null, 2)}`);

            console.log(`[InventoryMenu.populateDetailViewForEquippedItems] Emitting event: AttemptConflictInventoryItemSelectionConfirm with data: ${JSON.stringify(attemptConflictInventoryItemSelectionConfirmEvent, null, 2)}`);
            this.socket.emit(ServerSocketEvents.AttemptConflictInventoryItemSelectionConfirm, attemptConflictInventoryItemSelectionConfirmEvent);

            console.log('[InventoryMenu.populateDetailViewForEquippedItems] Process complete.');
        };
    }

}
