import VirtualJoyStick from 'phaser3-rex-plugins/plugins/input/virtualjoystick/VirtualJoyStick';
import {Direction} from '../../../../types/physics/Direction';
import {SceneNames} from '../../types/SceneNames';
import ServerControlledGameScene from '../ServerControlledGameplayScenes/ServerControlledGameScene';

export default class GamePadScene extends Phaser.Scene {
    // public joyStick: {
    //     createCursorKeys(): { [name: string]: { isDown: boolean } };
    // };
    public joyStick: VirtualJoyStick;

    private text: Phaser.GameObjects.Text;
    private serverControlledGameScene!: ServerControlledGameScene;
    private keyStates: { [name: string]: boolean; } = {up: false, right: false, down: false, left: false};

    public constructor() {
        super(SceneNames.GamePad);
    }

    public create(): void {
        console.log('[GamePadScene] create method called');
        this.serverControlledGameScene = <ServerControlledGameScene>this.scene.get(SceneNames.ServerControlledGame);

        // const plugin = this.plugins.get('rexVirtualJoyStick');
        // this.joyStick = ((plugin as unknown) as {
        //     add(scene: Phaser.Scene, config: object): {
        //         createCursorKeys(): { [name: string]: { isDown: boolean } };
        //     };
        // }).add(this, {
        //     x: 90,
        //     y: 510,
        //     radius: 100,
        //     base: this.add.circle(0, 0, 60, 0x888888, 0.8),
        //     thumb: this.add.circle(0, 0, 25, 0xcccccc, 0.9),
        //     dir: '4dir',
        //     forceMin: 16,
        //     enable: true
        // });
        this.joyStick = new VirtualJoyStick(this, {
            x: 90,
            y: 510,
            radius: 100,
            base: this.add.circle(0, 0, 60, 0x888888, 0.8),
            thumb: this.add.circle(0, 0, 25, 0xcccccc, 0.9),
            dir: '4dir', // Ensure dir is a valid type from DirTypes, '4dir' is just a string here, might need verification
            forceMin: 16,
            enable: true
        });

        this.text = this.add.text(0, 0, '');
        this.text.setVisible(false);
        this.dumpJoyStickState();
        this.scene.bringToTop();
    }

    public dumpJoyStickState(): void {
        const cursorKeys = this.joyStick.createCursorKeys();

        // Explicitly handle each key
        if (cursorKeys.up.isDown !== this.keyStates['up']) {
            this.handleKeyStateChange('up', cursorKeys.up.isDown);
        }
        if (cursorKeys.down.isDown !== this.keyStates['down']) {
            this.handleKeyStateChange('down', cursorKeys.down.isDown);
        }
        if (cursorKeys.left.isDown !== this.keyStates['left']) {
            this.handleKeyStateChange('left', cursorKeys.left.isDown);
        }
        if (cursorKeys.right.isDown !== this.keyStates['right']) {
            this.handleKeyStateChange('right', cursorKeys.right.isDown);
        }
    }

    private handleKeyStateChange(keyName: string, isDown: boolean): void {
        if (isDown) {
            this.keyDown(keyName);
        } else {
            this.keyUp(keyName);
        }
        this.keyStates[keyName] = isDown;
    }

    public update(): void {
        this.dumpJoyStickState();
        this.serverControlledGameScene.playerInputController.update();
    }

    private keyDown(name: string): void {
        const direction = this.nameToDirection(name);
        const event = this.directionToEvent(direction);

        this.serverControlledGameScene.playerInputController.handleKeyDown(event);
    }

    private keyUp(name: string): void {
        const direction = this.nameToDirection(name);
        const event = this.directionToEvent(direction);

        this.serverControlledGameScene.playerInputController.handleKeyUp(event);
    }

    private nameToDirection(name: string): Direction {
        switch (name) {
            case 'up':
                return Direction.UP;
            case 'right':
                return Direction.RIGHT;
            case 'down':
                return Direction.DOWN;
            case 'left':
                return Direction.LEFT;
            default:
                return Direction.NONE;
        }
    }

    private directionToEvent(direction: Direction): KeyboardEvent {
        // Convert a Direction to a keyboard key
        let key: string;
        switch (direction) {
            case Direction.UP:
                key = 'ArrowUp';
                break;
            case Direction.RIGHT:
                key = 'ArrowRight';
                break;
            case Direction.DOWN:
                key = 'ArrowDown';
                break;
            case Direction.LEFT:
                key = 'ArrowLeft';
                break;
            default:
                key = '';
                break;
        }

        // Create a new keyboard event
        return new KeyboardEvent('', {key: key});
    }

    public resetJoyStickState(): void {
        console.log('[resetJoyStickState] Starting joystick state reset.');

        // Reset the key states
        Object.keys(this.keyStates).forEach(key => {
            if (this.keyStates[key]) {
                console.log(`[resetJoyStickState] Key '${key}' is down. Sending key up event.`);
                this.keyUp(key); // Emulate key up for all keys that are down
            } else {
                console.log(`[resetJoyStickState] Key '${key}' is already up. No action needed.`);
            }
        });

        // Reset the visual and position of the joystick thumb to the center
        if (this.joyStick.base instanceof Phaser.GameObjects.Arc && this.joyStick.thumb instanceof Phaser.GameObjects.Arc) {
            console.log('[resetJoyStickState] Both base and thumb are Arc instances. Resetting position.');
            this.joyStick.thumb.setPosition(this.joyStick.base.x, this.joyStick.base.y);
        } else {
            if (!(this.joyStick.base instanceof Phaser.GameObjects.Arc)) {
                console.log('[resetJoyStickState] Base is not an instance of Arc. Position reset skipped.');
            }
            if (!(this.joyStick.thumb instanceof Phaser.GameObjects.Arc)) {
                console.log('[resetJoyStickState] Thumb is not an instance of Arc. Position reset skipped.');
            }
        }

        // Resetting enable state if needed
        if (!this.joyStick.enable) {
            console.log('[resetJoyStickState] Joystick is disabled. Enabling now.');
            this.joyStick.setEnable(true);
        } else {
            console.log('[resetJoyStickState] Joystick is already enabled. No action needed.');
        }

        // Optionally, reset any additional visual or state parameters
        console.log('[resetJoyStickState] Joystick state reset to initial, including visual components.');
    }

}
