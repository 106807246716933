import {Direction} from '../../../../types/physics/Direction';
import {tileSize} from '../../GameConfig';
import ServerControlledGameScene from '../../scenes/ServerControlledGameplayScenes/ServerControlledGameScene';
import {DepthLevel} from '../../types/DepthLevel';

export default class NPCSprite extends Phaser.GameObjects.Sprite {
    private gameScene: ServerControlledGameScene;
    public id: string;
    public facingDirection: Direction;

    public constructor(
        scene: ServerControlledGameScene,
        id: string,
        posX: number,
        posY: number,
        texture: string,
        facingDirection: Direction
    ) {
        // Call the parent class constructor with the initial frame index set to 0
        super(scene, posX, posY - (tileSize / 2), texture, 0);
        this.setVisible(false); // Make the sprite invisible right off the bat

        // Set initial properties
        this.id = id;
        this.gameScene = scene;
        this.facingDirection = facingDirection;

        // Determine the correct frame based on the NPC's facing direction
        let frame: number;
        switch (facingDirection) {
            case Direction.DOWN:
                frame = 0;
                break;
            case Direction.RIGHT:
                frame = 4;
                break;
            case Direction.LEFT:
                frame = 2;
                break;
            case Direction.UP:
                frame = 6;
                break;
            default:
                frame = 0;
                break;
        }

        // Set the sprite's frame and depth level
        this.setFrame(frame);
        this.setDepth(DepthLevel.NPC);

        // Add the sprite to the scene
        this.scene.add.existing(this);

        // Delay the visibility by 100ms
        this.setVisible(true);
    }
}
