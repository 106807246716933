// depthMappings.ts

// Map layer names to specific depth levels
import {DepthLevel} from '../types/DepthLevel';

export const layerNameToDepth: { [key: string]: DepthLevel } = {
    'overlay': DepthLevel.FOREGROUND_OBJECTS,
    // Add any other specific layer name mappings here
};

// Default depth settings for layers if specific mappings are not found
export const defaultDepths = [
    DepthLevel.GROUND_FAR,
    DepthLevel.GROUND_MID,
    DepthLevel.GROUND_NEAR
];
