export enum ClientSocketEvents {
    // this enum represents event names that the server emits to the client
    RegisterResponse = 'registerResponse',
    LoginResponse = 'loginResponse',
    RequestPasswordResetResponse = 'requestPasswordResetResponse',

    RequestCharacterSelectionSceneDetailsResponse = 'requestCharacterSelectionSceneDetailsResponse',
    RequestCharacterCreationResponse = 'requestCharacterCreationResponse',
    SendNewCharacterDetailsResponse = 'sendNewCharacterDetailsResponse',

    RequestCharacterLoadResponse = 'requestCharacterLoadResponse',

    DeleteCharacterResponse = 'deleteCharacterResponse',

    // Conflict-Specific Events
    CriticalStrike = 'criticalStrike',
    LevelUp = 'levelUp',
    ConflictVictoryExperienceReward = 'conflictVictoryExperienceReward',
    ConflictVictoryGoldReward = 'conflictVictoryGoldReward',
    PvPGoldTaken = 'pvpGoldTaken',
    InCombat = 'inCombat',
    ConflictInitiated = 'conflictInitiated', // starts the conflict scene...
    ConflictInitiatedBroadcast = 'conflictInitiatedBroadcast',
    OngoingConflictReinsertion = 'ongoingConflictReinsertion',
    ConflictWinners = 'conflictWinners',
    ConflictLosers = 'conflictLosers',

    // Player actions and states
    PlayerTurned = 'playerTurned',
    PlayerDisconnected = 'playerDisconnected',
    PlayerMoved = 'playerMoved',
    InitialPlayerNPCAndMapData = 'initialPlayerNPCAndMapData',
    AttemptToggleCharacterSheetResponse = 'attemptToggleCharacterSheetResponse',
    AttemptToggleAbilityMenuResponse = 'attemptToggleAbilityMenuResponse',
    AttemptSelectFieldAbilityResponse = 'attemptSelectFieldAbilityResponse',
    AttemptArmFieldAbilityResponse = 'attemptArmFieldAbilityResponse',
    AttemptUseFieldAbilityOnTargetResponse = 'attemptUseFieldAbilityOnTargetResponse',
    FieldPreTurnAction = 'fieldPreTurnAction',
    FieldMainTurnAction = 'fieldMainTurnAction',
    FieldPostTurnAction = 'fieldPostTurnAction',
    AttemptToggleInventoryMenuResponse = 'attemptToggleInventoryMenuResponse',
    AttemptSelectFieldInventoryItemResponse = 'attemptSelectFieldInventoryItemResponse',
    AttemptArmOrEquipFieldInventoryItemResponse = 'attemptArmOrEquipFieldInventoryItemResponse',
    AttemptUseFieldInventoryItemOnTargetResponse = 'attemptUseFieldInventoryItemOnTargetResponse',
    FieldCriticalEffect = 'fieldCriticalEffect',
    AttemptDropFieldInventoryItemResponse = 'attemptDropFieldInventoryItemResponse',
    AttemptSwitchFieldInventoryModeResponse = 'attemptSwitchFieldInventoryModeResponse',
    AttemptUnequipFieldEquipmentItemResponse = 'attemptUnequipFieldEquipmentItemResponse',

    // Party actions and states
    PartyMoved = 'partyMoved',

    // New and existing players
    NewPlayer = 'newPlayer',
    TargetPlayer = 'targetPlayer',
    ClearTarget = 'clearTarget',

    // Duel-related events
    DuelResponse = 'duelResponse',
    DuelChallengeReceived = 'duelChallengeReceived',
    DuelDeclined = 'duelDeclined',
    DuelCancelled = 'duelCancelled',

    // Trade-related events
    OpenTradeMenu = 'openTradeMenu',
    AttemptSelectClientInventoryItemForTradeResponse = 'attemptSelectClientInventoryItemForTradeResponse',
    AttemptSelectRemoteInventoryItemForTradeResponse = 'attemptSelectRemoteInventoryItemForTradeResponse',
    AttemptOfferSelectedInventoryItemForTradeResponse = 'attemptOfferSelectedInventoryItemForTradeResponse',
    UpdateRemoteTradePartnerOfferedItems = 'updateRemoteTradePartnerOfferedItems',
    AttemptRescindOfferedItemResponse = 'attemptRescindOfferedItemResponse',
    AttemptOfferGoldForTradeResponse = 'attemptOfferGoldForTradeResponse',
    UpdateRemoteTradePartnerGoldOffered = 'updateRemoteTradePartnerGoldOffered',
    AttemptAcceptTradeOfferResponse = 'attemptAcceptTradeOfferResponse',
    UpdateRemoteTradePartnerTradeAccepted = 'updateRemoteTradePartnerTradeAccepted',
    AttemptRevertTradeOfferToPendingResponse = 'attemptRevertTradeOfferToPendingResponse',
    UpdateRemoteTradeStatusToPending = 'updateRemoteTradeStatusToPending',
    TradeComplete = 'tradeComplete',
    AttemptCancelTradeOfferResponse = 'attemptCancelTradeOfferResponse',
    CancelRemoteTradeOffer = 'cancelRemoteTradeOffer',
    TradeFailure = 'tradeFailure',

    // Invitation-related events
    InviteResponse = 'inviteResponse',
    InviteReceived = 'inviteReceived',
    InviteCancelled = 'inviteCancelled',
    InvitationDeclined = 'invitationDeclined',

    // Party management
    PartyJoinSuccess = 'partyJoinSuccess',
    PartyLeaveSuccess = 'partyLeaveSuccess',
    PartyPromotion = 'partyPromotion',
    UpdatePartyMembers = 'updatePartyMembers',
    AttemptTogglePartyOrderMenuResponse = 'openPartyMenuSuccess',
    PartyReorderSubmitSuccess = 'partyReorderSubmitSuccess',
    HidePartyOrderMenu = 'hidePartyOrderMenu',

    // Map and level events
    PlayersLeavingMap = 'playersLeavingMap',
    EnterNewMap = 'enterNewMap',
    PlayersEnteredLevel = 'playersEnteredLevel',

    // Connection events
    ConnectError = 'connect_error',
    ReconnectFailed = 'reconnect_failed',
    Disconnect = 'disconnect',

    // Server events
    ServerShutdown = 'serverShutdown',

    // Game State Events
    NewRound = 'newRound',
    NewRoundSkipTurn = 'newRoundSkipTurn',
    GameOver = 'gameOver',
    Victory = 'victory',

    // UI Events
    InitializeConflictEngagement = 'initializeConflictEngagement',
    RoundTimer = 'roundTimer',

    // Conflict Action Responses
    AttemptConflictAbilityMainSelectResponse = 'attemptConflictAbilityMainSelectResponse',
    AttemptConflictAbilitySelectionConfirmResponse = 'attemptConflictAbilitySelectionConfirmResponse',
    AttemptConflictAbilitySelectionResponse = 'attemptConflictAbilitySelectionResponse',
    AttemptConflictActionResponse = 'attemptConflictActionResponse',
    AttemptConflictAttackResponse = 'attemptConflictAttackResponse',
    AttemptConflictCancelArmedActionResponse = 'attemptConflictCancelArmedActionResponse',
    AttemptConflictCancelResponse = 'attemptConflictCancelResponse',
    AttemptConflictInventoryItemMainSelectResponse = 'attemptConflictInventoryItemMainSelectResponse',
    AttemptSwitchConflictInventoryModeResponse = 'attemptSwitchConflictInventoryModeResponse',
    AttemptSelectConflictEquipmentItemResponse = 'attemptSelectConflictEquipmentItemResponse',
    AttemptConflictInventoryItemSelectionConfirmResponse = 'attemptConflictInventoryItemSelectionConfirmResponse',
    AttemptConflictInventoryItemSelectionResponse = 'attemptConflictInventoryItemSelectionResponse',
    AttemptFleeSuccess = 'attemptFleeSuccess',
    AttemptToggleConflictBindModeResponse = 'attemptToggleConflictBindModeResponse',
    AttemptBindActionResponse = 'attemptBindActionResponse',
    AttemptSelectHotkeyForBindResponse = 'attemptSelectHotkeyForBindResponse',

    // Ally Actions
    AllyActionUpdate = 'allyActionUpdate',
    AllyCancelAction = 'allyCancelAction',
    AllyActionCancelUpdate = 'allyActionCancelUpdate',

    // Turn Events
    PreTurnAction = 'preTurnAction',
    PreTurnBuffTrigger = 'preTurnBuffTrigger',
    MainTurnAction = 'mainTurnAction',
    PostTurnAction = 'postTurnAction',

    // Respawn and Scene Transition
    Respawn = 'respawn',
    ReturnToGameScene = 'returnToGameScene',

    // Messaging
    ChatMessage = 'chatMessage',
    ChatBubble = 'chatBubble',
    SystemMessage = 'systemMessage',
    StyledMessage = 'styledMessage',

    // NPC Events
    NPCFacingDirectionChanged = 'npcFacingDirectionChanged',
    InteractWithNPC = 'interactWithNPC',

    // Dialogue Events
    TerminateDialogue = 'terminateDialogue',
    NPCDialogueOptionResponse = 'npcDialogueOptionResponse',
    TogglePlayerVisibility = 'togglePlayerVisibility',
    ScreenState = 'screenState',
    PlayersTeleportingWithinSameMap = 'playersTeleportingWithinSameMap',
    PlayersLeavingMapViaTeleport = 'playersLeavingMapViaTeleport',
    PlayersJoiningMapViaTeleport = 'playersJoiningMapViaTeleport',
    TeleportingPlayersNewMapData = 'teleportingPlayersNewMapData',
    OpenMerchantNPCInventory = 'openMerchantNPCInventory',
    AttemptSelectNPCMerchantInventoryItemForPurchaseResponse = 'attemptSelectNPCMerchantInventoryItemForPurchaseResponse',
    AttemptSelectPlayerInventoryItemForSaleResponse = 'attemptSelectPlayerInventoryItemForSaleResponse',
    AttemptPurchaseNPCMerchantInventoryItemResponse = 'attemptPurchaseNPCMerchantInventoryItemResponse',
    AttemptSellNPCMerchantInventoryItemResponse = 'attemptSellNPCMerchantInventoryItemResponse',
    AttemptSwitchMerchantMenuToSellModeResponse = 'attemptSwitchMerchantMenuToSellModeResponse',
    AttemptSwitchMerchantMenuToBuyModeResponse = 'attemptSwitchMerchantMenuToBuyModeResponse',
    CloseDialogue = 'closeDialogue',

    UpdateGold = 'updateGold',
    UpdateHP = 'updateHP',
    UpdateResource = 'updateResource',
    UpdateHPAndResource = 'updateHPAndResource',

    // Interaction Events
    AttemptInteractResponse = 'attemptInteractResponse',
    UpdateInteractionMenu = 'updateInteractionMenu',

    // Chat Events
    ClearChat = 'clearChat',

    // Doors
    DoorStateChanged = 'doorStateChanged',

    // Interactables
    AttemptDismissInteractableMessageResponse = 'attemptDismissInteractableMessageResponse',
}
