// src/client/app/utils/phaserTextUtils.ts

export function adjustFontSizeByPixel(phaserText: Phaser.GameObjects.Text, maxPixel: number, startFontSize: number): Phaser.GameObjects.Text {
    // Apply the starting font size immediately.
    let fontSize = startFontSize;
    phaserText.setFontSize(fontSize);

    // Reduce font size until the text fits within the maxPixel width or reaches 0.
    while (phaserText.width > maxPixel && fontSize > 0) {
        fontSize--;
        phaserText.setFontSize(fontSize);
    }

    return phaserText;
}
