// src/client/main.ts
import '../output.css';

import {myGameConfig} from './app/GameConfig';
// import {adjustLayout} from './app/utils/adjustLayout';
import {adjustLayout} from './app/utils/adjustLayout';
import GameConfig = Phaser.Types.Core.GameConfig;

console.log(`process.env.NODE_ENV = ${process.env.NODE_ENV}`);
if (process.env.NODE_ENV === 'development') {
    // console.log('Disabling console logs in dev mode');
    // console.log = (): void => {
    // };
    // console.debug = (): void => {
    // };
    // console.info = (): void => {
    // };
    // console.warn = (): void => {
    // };
} else if (process.env.NODE_ENV === 'production') {
    console.log('Disabling console logs in production mode');
    console.log = (): void => {
    };
    console.debug = (): void => {
    };
    console.info = (): void => {
    };
    console.warn = (): void => {
    };
}

/** The Phaser game instance. */
let game: Phaser.Game;

/**
 * Initializes and starts the game when the window is loaded.
 */
window.addEventListener('load', () => {
    game = new Phaser.Game(myGameConfig() as GameConfig);
});

/**
 * Destroys the game instance before the window is unloaded.
 */
window.addEventListener('beforeunload', () => {
    game.destroy(true, false);
});

/**
 * Listen for orientation changes
 */
window.addEventListener('resize', () => {
    setTimeout(() => {
        adjustLayout(true);
    }, 1000);
});
