export const hairColors = [
    '0xFFFFFF', // Pure White, for an aged or mystical look, contrasting all skin tones
    '0xF6E08E', // Sunny Yellow Blonde, a bright and vibrant shade that stands out
    '0xFFA07A', // Strawberry Blonde, a light reddish blonde that's unique and eye-catching
    '0xA52A2A', // Auburn, a reddish-brown that provides a rich, warm option
    '0x6A4E42', // Dark Chocolate, a deep brown that's darker than any skin tone
    '0x4F301F', // Espresso, a very dark brown, almost black
    '0x333333', // Dark Grey, a deep grey that provides contrast without being black
    '0x000000', // Black, a versatile color that stands out against lighter skin tones
];
