import {Socket} from 'socket.io-client';
import {
    AttemptConflictAbilityMainSelectResponseEvent
} from '../../../../types/conflict/AttemptConflictAbilityMainSelectResponseEvent';
import {
    AttemptConflictAbilitySelectionConfirmResponseEvent
} from '../../../../types/conflict/AttemptConflictAbilitySelectionConfirmResponseEvent';
import {
    AttemptConflictAbilitySelectionResponseEvent
} from '../../../../types/conflict/AttemptConflictAbilitySelectionResponseEvent';
import {AttemptConflictActionResponseEvent} from '../../../../types/conflict/AttemptConflictActionResponseEvent';
import {
    AttemptConflictInventoryItemMainSelectResponseEvent
} from '../../../../types/conflict/AttemptConflictInventoryItemMainSelectResponseEvent';
import {
    AttemptConflictInventoryItemSelectionConfirmResponseEvent
} from '../../../../types/conflict/AttemptConflictInventoryItemSelectionConfirmResponseEvent';
import {
    AttemptConflictInventoryItemSelectionResponseEvent
} from '../../../../types/conflict/AttemptConflictInventoryItemSelectionResponseEvent';
import {CancelActionEvent} from '../../../../types/conflict/CancelActionEvent';
import {LevelUpEvent} from '../../../../types/conflict/LevelUpEvent';
import {NewRoundPayload} from '../../../../types/conflict/NewRoundPayload';
import {PreTurnActionData} from '../../../../types/conflict/PreTurnActionData';
import {ConflictTurnActionData} from '../../../../types/conflict/TurnActionData';
import {AttemptBindActionResponse} from '../../../../types/events/AttemptBindActionResponse';
import {AttemptHotkeyBindResponse} from '../../../../types/events/AttemptHotkeyBindResponse';
import {AttemptToggleConflictBindModeResponse} from '../../../../types/events/AttemptToggleConflictBindModeResponse';
import {ClientSocketEvents} from '../../../../types/events/ClientSocketEvents';
import {
    AttemptSelectConflictEquipmentItemResponseEvent
} from '../../../../types/inventory/AttemptSelectConflictEquipmentItemResponseEvent';
import {
    AttemptSwitchConflictInventoryModeResponse
} from '../../../../types/inventory/AttemptSwitchConflictInventoryModeResponseEvent';
import {InteractionState} from '../../../../types/mechanics/InteractionState';
import {PlayerDataArray} from '../../../../types/playerInfo/PlayerDataArray';
import ServerControlledConflictScene from '../../scenes/ServerControlledGameplayScenes/ServerControlledConflictScene';
import ServerControlledConflictUISceneController from '../GameplayAndControl/ServerControlledConflictUISceneController';
import SocketManager from './SocketManager';

export default class ConflictSocketEventHandler {
    private conflictScene: ServerControlledConflictScene;
    private socket: Socket;
    private disconnectionSceneRunning: boolean = false;
    private conflictUISceneController: ServerControlledConflictUISceneController;

    public constructor(conflictScene: ServerControlledConflictScene) {
        this.conflictScene = conflictScene;
        this.conflictUISceneController = conflictScene.conflictUISceneController;
        this.socket = SocketManager.getInstance().socket;

        // Setup conflict-related socket event listeners
        this.setUpSocketListeners();
    }

    private setUpSocketListeners(): void {
        console.log(`[setUpSocketListeners] Setting up socket listeners for ${this.socket.id}.`);

        this.socket.on(ClientSocketEvents.AllyActionCancelUpdate, (data: { actorIndex: number }) => {
            this.conflictUISceneController.handleAllyActionCancelUpdate(data);
        });

        this.socket.on(ClientSocketEvents.AllyActionUpdate, (data: AttemptConflictActionResponseEvent) => {
            this.conflictUISceneController.handleAllyActionUpdate(data);
        });

        this.socket.on(ClientSocketEvents.AllyCancelAction, (data: CancelActionEvent) => {
            this.conflictUISceneController.handleAllyCancelAction(data);
        });

        this.socket.on(ClientSocketEvents.AttemptConflictActionResponse, (data: AttemptConflictActionResponseEvent) => {
            this.conflictUISceneController.handleAttemptConflictActionResponse(data);
        });

        // ClientSocketEvents.AttemptBindActionResponse
        this.socket.on(ClientSocketEvents.AttemptBindActionResponse, (data: AttemptBindActionResponse) => {
            this.conflictUISceneController.handleAttemptBindActionResponse(data);
        });

        this.socket.on(ClientSocketEvents.AttemptConflictAttackResponse, (data: { success: boolean }) => {
            this.conflictUISceneController.handleAttemptAttackResponse(data);
        });

        this.socket.on(ClientSocketEvents.AttemptConflictAbilityMainSelectResponse, (data: AttemptConflictAbilityMainSelectResponseEvent) => {
            this.conflictUISceneController.handleAttemptConflictAbilityMainSelectResponse(data);
        });

        this.socket.on(ClientSocketEvents.AttemptConflictAbilitySelectionResponse, (data: AttemptConflictAbilitySelectionResponseEvent) => {
            this.conflictUISceneController.handleAttemptConflictAbilitySelectionResponse(data);
        });

        this.socket.on(ClientSocketEvents.AttemptConflictAbilitySelectionConfirmResponse, (data: AttemptConflictAbilitySelectionConfirmResponseEvent) => {
            this.conflictUISceneController.handleAttemptConflictAbilitySelectionConfirmResponse(data);
        });

        this.socket.on(ClientSocketEvents.AttemptConflictInventoryItemMainSelectResponse, (data: AttemptConflictInventoryItemMainSelectResponseEvent) => {
            this.conflictUISceneController.handleAttemptConflictInventoryItemMainSelectResponse(data);
        });

        this.socket.on(ClientSocketEvents.AttemptSwitchConflictInventoryModeResponse, (data: AttemptSwitchConflictInventoryModeResponse) => {
            this.conflictUISceneController.handleAttemptSwitchConflictInventoryModeResponse(data);
        });

        this.socket.on(ClientSocketEvents.AttemptSelectConflictEquipmentItemResponse, (data: AttemptSelectConflictEquipmentItemResponseEvent) => {
            this.conflictUISceneController.handleAttemptSelectConflictEquipmentItemResponse(data);
        });

        this.socket.on(ClientSocketEvents.AttemptConflictInventoryItemSelectionResponse, (data: AttemptConflictInventoryItemSelectionResponseEvent) => {
            this.conflictUISceneController.handleAttemptConflictInventoryItemSelectionResponse(data);
        });

        this.socket.on(ClientSocketEvents.AttemptConflictInventoryItemSelectionConfirmResponse, (data: AttemptConflictInventoryItemSelectionConfirmResponseEvent) => {
            this.conflictUISceneController.handleAttemptConflictInventoryItemSelectionConfirmResponse(data);
        });

        this.socket.on(ClientSocketEvents.AttemptFleeSuccess, () => {
            this.conflictUISceneController.handleAttemptFleeSuccess();
        });

        this.socket.on(ClientSocketEvents.AttemptToggleConflictBindModeResponse, (data: AttemptToggleConflictBindModeResponse) => {
            this.conflictUISceneController.handleAttemptToggleConflictBindModeResponse(data);
        });

        this.socket.on(ClientSocketEvents.AttemptSelectHotkeyForBindResponse, (data: AttemptHotkeyBindResponse) => {
            this.conflictUISceneController.handleAttemptHotkeyBindResponse(data);
        });

        this.socket.on(ClientSocketEvents.AttemptConflictCancelResponse, (data: { success: boolean }) => {
            this.conflictUISceneController.handleAttemptCancelResponse(data);
        });

        this.socket.on(ClientSocketEvents.AttemptConflictCancelArmedActionResponse, (data: CancelActionEvent) => {
            this.conflictUISceneController.handleAttemptCancelArmedActionResponse(data);
        });

        this.socket.on(ClientSocketEvents.ConflictVictoryExperienceReward, (data: { experience: number }) => {
            this.conflictUISceneController.handleConflictVictoryExperienceReward(data);
        });

        this.socket.on(ClientSocketEvents.ConflictVictoryGoldReward, (data: { gold: number }) => {
            this.conflictUISceneController.handleConflictVictoryGoldReward(data);
        });

        this.socket.on(ClientSocketEvents.PvPGoldTaken, (data: { goldTaken: number }) => {
            this.conflictUISceneController.handlePvPGoldTaken(data);
        });

        this.socket.on(ClientSocketEvents.GameOver, () => {
            this.conflictUISceneController.handleGameOver();
        });

        this.socket.on(ClientSocketEvents.NewRound, (newRoundPayload: NewRoundPayload) => {
            this.conflictUISceneController.handleNewRound(newRoundPayload);
        });

        this.socket.on(ClientSocketEvents.NewRoundSkipTurn, (newRoundPayload: NewRoundPayload) => {
            this.conflictUISceneController.handleNewRoundSkipTurn(newRoundPayload);
        });

        this.socket.on(ClientSocketEvents.Respawn,
            (dataArray: PlayerDataArray) => {
                this.conflictUISceneController.handleRespawn(dataArray);
            }
        );

        this.socket.on(ClientSocketEvents.ReturnToGameScene,
            (eventData: { playerDataArray: PlayerDataArray, facingInteractable: boolean }) => {
                this.conflictUISceneController.returnToGameScene(eventData.playerDataArray, eventData.facingInteractable);
            }
        );

        this.socket.on(ClientSocketEvents.RoundTimer, (remainingTime: number) => {
            this.conflictUISceneController.handleRoundTimer(remainingTime);
        });

        this.socket.on(ClientSocketEvents.InitializeConflictEngagement, (interactionState: InteractionState) => {
            console.log('[ConflictSocketEventHandler.setUpSocketListeners] Received InitializeConflictEngagement event.');
            console.log(`[ConflictSocketEventHandler.setUpSocketListeners] InteractionState: ${interactionState}`);

            this.conflictUISceneController.conflictUIScene.waitingForServerResponse = false;

            // Log `this` to see what it is bound to
            console.log('this is bound to:', this);

            // Log whether this.conflictUISceneController is defined
            console.log(`[ConflictSocketEventHandler.setUpSocketListeners] this.conflictUISceneController defined: ${!!this.conflictUISceneController}`);
            // Log whether this.conflictUISceneController.handleInitializeConflictEngagement is defined
            console.log(`[ConflictSocketEventHandler.setUpSocketListeners] this.conflictUISceneController.handleInitializeConflictEngagement defined: ${!!this.conflictUISceneController.handleInitializeConflictEngagement}`);

            // Attempt to call the method
            if (this.conflictUISceneController && this.conflictUISceneController.handleInitializeConflictEngagement) {
                this.conflictUISceneController.handleInitializeConflictEngagement(interactionState);
            } else {
                console.error('Cannot call handleInitializeConflictEngagement - conflictUISceneController or method not defined');
            }
        });

        this.socket.on(ClientSocketEvents.PreTurnAction, (data: PreTurnActionData) => {
            this.conflictUISceneController.handlePreTurnAction(data);
        });

        this.socket.on(ClientSocketEvents.MainTurnAction, (data: ConflictTurnActionData) => {
            this.conflictUISceneController.handleMainTurnAction(data);
        });

        this.socket.on(ClientSocketEvents.PostTurnAction, (data: ConflictTurnActionData) => {
            this.conflictUISceneController.handlePostTurnAction(data);
        });

        this.socket.on(ClientSocketEvents.Victory, () => {
            this.conflictUISceneController.handleVictory();
        });

        this.socket.on(ClientSocketEvents.LevelUp, (data: LevelUpEvent) => {
            this.conflictUISceneController.handleLevelUP(data);
        });

        this.socket.on(ClientSocketEvents.CriticalStrike, () => {
            this.conflictUISceneController.handleCriticalEffect();
        });

        this.socket.on(ClientSocketEvents.PreTurnBuffTrigger, (data: string) => {
            this.conflictUISceneController.handlePreTurnBuffTrigger(data);
        });
    }

    // More handler methods...
}
